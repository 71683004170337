import React, { useCallback, useEffect, useMemo } from 'react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { capitalizeFirstLetter } from 'utils/strings';

import { Icon } from '../../Product/Icon';
import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import Error from '../Error';
import * as ST from './styles';

import { inputStyle, Row, Select } from 'ui';

const CalendarNextDay = ({
  register,
  values,
  errors,
  setValue,
  remove,
  clearErrors,
  index,
  el,
}) => {
  const {
    form,
    common: { calendar },
  } = Lang();

  const localizedWeekDays = useMemo(() => {
    return Object.keys(calendar.weekDays).map(el => ({
      slug: capitalizeFirstLetter(el),
      name: calendar.weekDays[el],
    }));
  }, [calendar.weekDays]);

  const handleOptionClick = useCallback(
    (slug, index) => {
      setValue(`blockedWeekDays[${index}].weekDay`, slug);
      clearErrors(`blockedWeekDays[${index}].weekDay`);
    },
    [clearErrors, setValue],
  );

  useEffect(() => {
    register(`blockedWeekDays[${index}].weekDay`, {
      required: form.notification.theFieldIsRequired,
    });

    setValue(`blockedWeekDays[${index}].weekDay`, el.weekDay || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.notification.theFieldIsRequired, index, register, setValue]);

  return (
    <Row align="center">
      <ST.FieldsWrapper>
        <ST.Line>
          <FieldStyle
            as={FieldStyle2}
            legend={calendar.dayOfWeek}
            withBorder={true}
            width="160px"
          >
            <Select
              options={localizedWeekDays}
              selected={
                calendar.weekDays[
                  values.blockedWeekDays[index]?.weekDay?.toLowerCase()
                ]
              }
              defaultText={calendar.selectDay}
              className={inputStyle}
              onClickOption={slug => handleOptionClick(slug, index)}
              maxHeight="30rem"
              placement="bottom-start"
            />
          </FieldStyle>
          <Error
            message={get(errors, [
              'blockedWeekDays',
              index,
              'weekDay',
              'message',
            ])}
          />
        </ST.Line>
        <ST.Line>
          <FieldStyle2 legend={calendar.offset}>
            <InputStyled
              name={`blockedWeekDays[${index}].offset`}
              width="120px"
              type="number"
              ref={register({
                required: form.notification.theFieldIsRequired,
                valueAsNumber: true,
                validate: {
                  positive: value => {
                    return value > 0 || form.notification.valueShouldBePositive;
                  },
                },
              })}
              placeholder={calendar.enterNumber}
              defaultValue={values.blockedWeekDays[index]?.offset}
            />
          </FieldStyle2>
          <Error
            message={get(errors, [
              'blockedWeekDays',
              index,
              'offset',
              'message',
            ])}
          />
        </ST.Line>
      </ST.FieldsWrapper>
      <ST.DeleteIconWrapper>
        <Icon color="#fff" type="delete" handleClick={() => remove(index)} />
      </ST.DeleteIconWrapper>
    </Row>
  );
};

export default CalendarNextDay;
