import React, { useCallback, useMemo } from 'react';
import { languagesOverridesMapLocales } from 'consts';
import { format, isToday } from 'date-fns';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { getDateInFormat } from 'utils/dates';

import * as ST from './styles';

import { ReactComponent as Calendar } from 'assets/images/icon/Calendar.svg';
import { ReactComponent as LeftArrow } from 'assets/images/icon/CalendarLeftArrow.svg';
import { ReactComponent as RightArrow } from 'assets/images/icon/CalendarRightArrow.svg';

const SmallDatePicker = ({
  className,
  defaultValue = null,
  handleDateChange,
  isPastDatesDisabled,
  isCurrentDateNotDisabled,
  minDate,
  maxDate,
  dateFormat = "yyyy-MM-dd'T'HH:mm:ss",
}) => {
  const {
    form: { calendar },
  } = Lang();

  const currentLangState = useStore(currentLang$);

  const tileDisabled = useCallback(
    ({ date, view }) => {
      switch (view) {
        case 'month':
          return (
            !(isCurrentDateNotDisabled && isToday(date)) && date <= new Date()
          );
        case 'year':
          return date.getMonth() < new Date().getMonth();
        case 'decade':
          return date.getFullYear() < new Date().getFullYear();
        default:
          return date.getFullYear() + 9 < new Date().getFullYear();
      }
    },
    [isCurrentDateNotDisabled],
  );

  const formattedDate = useMemo(
    () => (defaultValue ? new Date(defaultValue) : null),
    [defaultValue],
  );

  const handleChange = useCallback(
    date => {
      if (date && handleDateChange) {
        handleDateChange(getDateInFormat(date, dateFormat));
        return;
      }

      handleDateChange(null);
    },
    [dateFormat, handleDateChange],
  );

  const getWeekdayLabel = useCallback(
    date => {
      return format(date, 'EEEEEE', {
        locale: languagesOverridesMapLocales[currentLangState],
      });
    },
    [currentLangState],
  );

  return (
    <ST.PickerStyled
      locale={currentLangState}
      value={formattedDate}
      onChange={handleChange}
      className={className}
      nextLabel={<RightArrow />}
      minDate={minDate}
      tileDisabled={isPastDatesDisabled && tileDisabled}
      maxDate={maxDate}
      prevLabel={<LeftArrow />}
      calendarIcon={<Calendar />}
      format="dd/MM/yyyy"
      locate="it-HU"
      clearIcon={null}
      prev2Label={null}
      next2Label={null}
      dayPlaceholder={calendar.dayPlaceholder}
      monthPlaceholder={calendar.monthPlaceholder}
      yearPlaceholder={calendar.yearPlaceholder}
      showFixedNumberOfWeeks
      formatShortWeekday={(locale, date) => getWeekdayLabel(date)}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default SmallDatePicker;
