import React from 'react';

import * as ST from './styles';

import { Spinner } from 'ui';

const SpinnerOverlay = () => {
  return (
    <ST.SpinnerWrapper>
      <Spinner dark size={5} />
    </ST.SpinnerWrapper>
  );
};

export default SpinnerOverlay;
