import { Scrollbars } from 'react-custom-scrollbars';
import { Checkbox } from 'reakit/Checkbox';

import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';

export const TableHeader = styled.div`
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  height: 6rem;
  background: #fff;
`;

export const TableHeaderCheckbox = styled.div`
  padding-left: 1.3rem;
`;

export const TableHeaderTitle = styled.div`
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  font-weight: 600;
  color: #555555;
`;

export const CheckboxStyled = styled(Checkbox)`
  width: 1.8rem;
  height: 1.8rem;
  background: #fff;
  margin: 0;
  appearance: none;
  border: 2px solid #bbbbbb;
  border-radius: 0.2rem;
  position: relative;
  top: 0.2rem;
  outline: none;
  cursor: pointer;

  &:checked {
    top: 0.1rem;

    &:after {
      display: flex;
      content: '✓';
      align-items: center;
      justify-content: center;
      color: ${colors.darkBlue};
      font-weight: bold;
      position: relative;
      top: -0.1rem;
    }
  }
`;

export const TableRowsWrapper = styled(Scrollbars)`
  height: calc(100vh - 55rem);
  overflow-y: auto;
  margin-bottom: 4.3rem;
`;

export const SaveBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
