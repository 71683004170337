export const leads = {
  subTitle: 'Monitore todos os leads do site em tempo reale',
  backToAllLeads: 'Voltar à lista de todos os leads',
  sourcePage: 'Página de origem',
  leadsAvailable: '{0} leads disponíveis',
  hideTestLeads: 'Ocultar leads de prova',
  viewSourcePage: 'Ver página de origem',
  comment: 'Comentar',
  message: 'Mensagem',
  crmDeal: 'Acordo de CRM',
  crmContact: 'Contato de CRM',
  callTo: 'Ligar para',
  customerTax: 'Imposto do cliente',
  billingAddress: 'Billing Address',
  press: 'Pressionar',
  emailIsCopied: 'O e-mail é copiado para sua área de transferência',
  shuftiVerificationStatus: 'Shufti Pro verification status',
  shuftiVerificationLink: 'Shufti Pro verification link',
  yes: 'Sim',
  no: 'Não',
  stripePaymentLink: 'Stripe link de pagamento',

  userTypes: {
    individual: 'Individual',
    company: 'Empresa',
  },
  leadInfoText: {
    enquiry: '{0} fez esta consulta e está esperando ser contatado.',
    callback:
      '{0} solicitou este retorno de chamada e está esperando ser contatado.',
    trainingEnquiry:
      '{0} fez esta consulta sobre um curso de formação e está esperando ser contactado.',
  },
  tableHeaders: {
    dateCreated: 'Data Criada',
    leadType: 'Tipo de lead',
    leadSource: 'Origem do lead',
    fullName: 'Nome completo',
    companyName: 'Nome da empresa',
    phoneNumber: 'Número de telefone',
    emailAddress: 'Endereço de e-mail',
    CRMDealStage: 'CRM Deal Stage',
  },
  types: {
    all: 'Todos',
    enquiry: 'Consulta',
    callback: 'Ligar de volta',
    trainingEnquiry: 'Consulta de Treinamento',
    product: 'Produto',
    quote: 'Cotação',
    trainingOrder: 'Pedido de treinamento',
    training: 'Pedido de treinamento',
    checkoutStarted: 'Pagamento iniciado',
    newsletterSubscribe: 'Subscreveu a newsletter',
  },
};
