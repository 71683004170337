import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import DeliveryMileItem from '../DeliveryMileItem';
import * as ST from './styles';

const DeliveryMileList = ({
  deliveryItem,
  register,
  control,
  deliveryIndex,
  values,
  setValue,
  errors,
  FIELDS_NAMES_LIST,
  trigger,
}) => {
  const { settings: settingsLocalization, form } = Lang();

  const { fields } = useFieldArray({
    control,
    name: `deliveries[${deliveryIndex}].deliveryPerMile`,
  });

  return (
    <ST.PriceKmWrapper>
      <ST.DeliveryMileTitles>
        <ST.DepotLabel as="span" color="gray">
          {settingsLocalization.depot}
        </ST.DepotLabel>
        <ST.InputLabel as="span" color="gray" align="center">
          {form.fields.startPrice}
          <br />
          {form.fields.pricePerWay}
        </ST.InputLabel>
        <ST.InputLabel as="span" color="gray" align="center">
          {form.fields.minPrice}
          <br />
          {form.fields.pricePerWay}
        </ST.InputLabel>
        <ST.InputLabel as="span" color="gray" align="center">
          {form.fields.pricePerKm}
        </ST.InputLabel>
        <ST.CheckboxLabel as="span" color="gray" align="center">
          {settingsLocalization.depotToLocation}
        </ST.CheckboxLabel>
        <ST.CheckboxLabel as="span" color="gray" align="center">
          {settingsLocalization.locationToDepot}
        </ST.CheckboxLabel>
        <ST.CheckboxLabel as="span" color="gray" align="center">
          {settingsLocalization.bothWaysDelivery}
        </ST.CheckboxLabel>
      </ST.DeliveryMileTitles>
      {fields.map((el, mileIndex) => (
        <DeliveryMileItem
          deliveryItem={deliveryItem}
          register={register}
          control={control}
          deliveryIndex={deliveryIndex}
          values={values}
          setValue={setValue}
          item={el}
          itemIndex={mileIndex}
          errors={errors}
          key={mileIndex}
          FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
          trigger={trigger}
        />
      ))}
    </ST.PriceKmWrapper>
  );
};

export default DeliveryMileList;
