import styled from 'styled-components';

import { Row, T8y } from 'ui';

export const AddressItem = styled(Row)`
  flex-basis: calc(50% - 15px);
  min-height: 100px;
  margin-bottom: 20px;
  padding: 18px 32px;
  overflow: hidden;
  border: 1px solid #dddddd;
  border-radius: 3px;
`;

export const AddressTitle = styled(T8y)`
  font-size: 16px;
  margin-bottom: 12px;
`;

export const AddressText = styled(T8y)`
  color: #666666;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
`;
