import styled from 'styled-components';

export const ShowSectionWrapper = styled.div`
  height: 73px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RadioWrapper = styled.div`
  display: flex;
  height: 44px;

  & label:first-child {
    margin-right: 22px;
  }
`;
