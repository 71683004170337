import React from 'react';

import { StyledError } from './styles';

const Error = ({ fontSize, whiteSpace, message, height, className }) => {
  return (
    <StyledError
      visibility={message ? 'visible' : 'hidden'}
      fontSize={fontSize}
      height={height}
      white-space={whiteSpace || 'normal'}
      className={className}
    >
      {message}
    </StyledError>
  );
};

export default Error;
