import { PATHS } from 'AppPaths';
import Analytics from 'pages/Analytics';
import Leads from 'pages/Leads';
import Marketing from 'pages/Marketing';
// import Offers from 'pages/Offers';
import Product from 'pages/Product';
import { Products } from 'pages/Products';
import Settings from 'pages/Settings';
import TradeAccount from 'pages/TradeAccount';
import TradeAccounts from 'pages/TradeAccounts';
import Trainings from 'pages/Trainings';
import User from 'pages/User';
import Users from 'pages/Users';

import CategoryEditor from 'components/organisms/CategoryEditor';
import JobTypeEditor from 'components/organisms/JobTypeEditor';
import LeadProfile from 'components/organisms/LeadProfile';
// import NewOffer from 'components/organisms/NewOffer';
// import Offer from 'components/organisms/Offer';
import TrainingEditor from 'components/organisms/TrainingEditor';
import TrainingIndex from 'components/organisms/TrainingIndex';

export function generateURLS() {
  return [
    {
      path: [PATHS.MARKETING, PATHS.HERO_IMAGES],
      exact: true,
      component: Marketing,
    },
    {
      path: [
        PATHS.MARKETING,
        PATHS.POPULAR_PRODUCTS,
        PATHS.ENQUIRY,
        PATHS.POPULAR_CATEGORIES,
        PATHS.PROMO_CODES,
        PATHS.JOB_TYPES,
        PATHS.TA_FAQS,
      ],
      exact: true,
      component: Marketing,
    },
    {
      path: [PATHS.ANALYTICS, PATHS.CHARTS, PATHS.REPORTS],
      exact: true,
      component: Analytics,
    },
    {
      path: [PATHS.SETTINGS, PATHS.TRANSPORTATION],
      exact: true,
      component: Settings,
    },
    {
      path: [
        PATHS.SETTINGS,
        PATHS.ADDITIONAL_CHARGES,
        PATHS.REGIONAL_SETTINGS,
        PATHS.DATES_SETTINGS,
        PATHS.DEPOTS,
      ],
      exact: true,
      component: Settings,
    },
    {
      path: PATHS.TRAININGS,
      exact: true,
      component: Trainings,
    },
    {
      path: PATHS.TRAININGS_INDEX,
      exact: true,
      component: TrainingIndex,
    },
    {
      path: PATHS.SEE_JOB_TYPE,
      exact: true,
      component: JobTypeEditor,
    },
    {
      path: PATHS.EDIT_TRAININGS(),
      exact: true,
      component: TrainingEditor,
    },
    {
      path: PATHS.CREATE_PRODUCT,
      exact: true,
      component: Product,
    },
    {
      path: PATHS.EDIT_PRODUCT(),
      exact: true,
      component: Product,
    },
    {
      path: PATHS.CREATE_CATEGORY(),
      exact: true,
      component: CategoryEditor,
    },
    {
      path: PATHS.EDIT_CATEGORY(),
      exact: true,
      component: CategoryEditor,
    },
    {
      path: [
        PATHS.USER,
        PATHS.USER_PROFILE_TEMPLATE,
        PATHS.USER_ORDERS_TEMPLATE,
        PATHS.ORDER_PROFILE_TEMPLATE,
      ],
      exact: true,
      component: User,
    },
    {
      path: PATHS.USERS,
      exact: true,
      component: Users,
    },
    {
      path: [
        PATHS.TRADE_ACCOUNT_PROFILE_TEMPLATE,
        PATHS.TRADE_ACCOUNT_ORDERS_TEMPLATE,
        PATHS.TRADE_ACCOUNT_ORDER_PROFILE_TEMPLATE,
      ],
      exact: true,
      component: TradeAccount,
    },
    {
      path: [PATHS.TRADE_ACCOUNTS_TEMPLATE, PATHS.TRADE_ACCOUNTS('')],
      exact: true,
      component: TradeAccounts,
    },
    {
      path: PATHS.LEAD_TEMPLATE,
      exact: true,
      component: LeadProfile,
    },
    {
      path: PATHS.LEADS,
      exact: true,
      component: Leads,
    },
    {
      path: PATHS.PRODUCTS,
      exact: true,
      component: Products,
    },
    // {
    //   path: PATHS.CREATE_OFFER(),
    //   exact: true,
    //   component: NewOffer,
    // },
    // {
    //   path: PATHS.SEE_OFFER(),
    //   exact: true,
    //   component: Offer,
    // },
    // {
    //   path: PATHS.OFFERS,
    //   exact: true,
    //   component: Offers,
    // },
  ];
}
