export const orders = {
  order: 'Order',
  title: 'Recent orders',
  total: 'Grand Total',
  documents: 'Documents',
  invoice: 'Invoice',
  uploadDocument: 'Upload document',
  requestToCancelThisOrder: 'User sent a request to cancel this order',
  approve: 'Approve',
  decline: 'Decline',
  youWantToCancelThisOrder: 'Are you sure you want to cancel this order?',
  provideCancellationReason: 'If yes, please provide cancellation reason',
  cancellationReason: 'Cancellation Reason',
  cancelOrder: 'Cancel Order',
  submitRequest: 'Submit Request',
  viewOrder: 'View order',
  ordersAvailable: '{0} orders available',
  startTypingToFilter: 'Start typing to filter',
  paymentStatus: 'Payment Status',
  productList: 'Product List',

  orderTableHeaders: {
    orderId: 'Order ID',
    createdBy: 'Created By',
    orderType: 'Order Type',
    dateCreated: 'Date Created',
    dates: 'Dates',
    status: 'Status',
    deliveryMethod: 'Delivery Method',
    paymentMethod: 'Payment Method',
    deposit: 'Deposit',
    total: 'Grand Total',
    author: 'Author',
    purchaseOrderNumber: 'Purchase Order Number',
  },

  productsTableHeaders: {
    sku: 'Sku',
    visual: 'Visual',
    productTitle: 'Product Title',
    hirePeriod: 'Hire Period',
    hirePerItem: 'Hire per Item',
    qty: 'Qty',
    hirePrice: 'Hire Price',
    courseId: 'Course ID',
    courseTitle: 'Course Title',
    courseDates: 'Course Dates',
    coursePrice: 'Course Price',
    seats: 'Seats',
    totalCost: 'Total Cost',
  },

  item: {
    orderStatus: 'Order Status',
    date: 'Date Created',
    transport: 'Transport',
    paymentType: 'Payment Type',
    productList: 'Product List',
    cardEndingIn: 'Ending in',
  },

  paymentType: {
    card: 'Credit Card',
    cash: 'Cash',
    later: 'Pay Later',
    bank: 'Bank Transfer',
  },

  statusType: {
    paid: 'Paid',
    not_paid: 'Not Paid',
    new: 'New',
    in_progress: 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled',
    all: 'All Orders',
    quote: 'Quote',
  },

  orderType: {
    product: 'Product',
    quote: 'Quote',
    training: 'Training',
  },

  deliveryMethod: {
    selfCollection: 'Self-collection',
    delivery: 'Delivery',
  },

  costSummary: {
    title: 'Cost Summary',
    subtotal: 'Subtotal',
    tbd: 'TBC',
    slightDamageWaiverFee: 'Slight Damage Waiver',
    adminFee: 'Admin Fee',
    total: 'Total',
    free: 'FREE',
    depositAdditionalText:
      'The loss & damage deposit will be returned \n ' +
      'to your card upon safe return of the inventory',
    discount: 'Discount',
    itemsToHire: 'Items to hire',
    tradeAccountDiscount: 'Trade Account Discount',
  },

  offers: {
    offer: 'Offer',
    offers: 'Offers',
    newOffer: 'New Offer',
    createNewOffer: 'Create new offer',
    viewOffer: 'View Offer',
    urlFromCheckout: 'Url from checkout',
    enterUrl: 'Enter url here',
    backToList: 'Back to list of all offers',
    offersAvailable: '{0} offers available',
    saveOffer: 'Save offer and generate PDF',
    quotePDF: 'Quote PDF',
  },

  noDocuments: 'No documents yet',
};
