import React, { useCallback, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import { trainingIndexLocalized$ } from 'models/trainings';

import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';
import ShowSection from 'components/atoms/ShowSection';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import { FieldStyle2, InputStyled } from '../../Product/shared';

import { T8y } from 'ui';

const INTRODUCTORY_TITLE_MAX_LENGTH = 50;

const TrainingIndexFormIntroductory = ({
  watch,
  register,
  setValue,
  trigger,
  getIsError,
  errors,
}) => {
  const { formatString, trainings, form } = Lang();

  const branding = useStore(branding$);
  const trainingIndexLocalized = useStore(trainingIndexLocalized$);

  const watchIntroductoryShow = watch('form.introductoryShow');

  const introductoryRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'form.introductoryTitle',
    INTRODUCTORY_TITLE_MAX_LENGTH,
  );

  useEffect(() => {
    register(
      { name: 'form.introductoryText' },
      {
        required: watchIntroductoryShow && form.notification.theFieldIsRequired,
      },
    );
  }, [form.notification.theFieldIsRequired, register, watchIntroductoryShow]);

  const handleIntroductoryText = useCallback(
    info => {
      setValue('form.introductoryText', info.text);
      trigger('form.introductoryText');
    },
    [setValue, trigger],
  );

  const handleIntroductoryShow = useCallback(
    value => {
      setValue('form.introductoryShow', value);
      trigger(['form.introductoryTitle', 'form.introductoryText']);
    },
    [setValue, trigger],
  );

  return (
    <GeneralExpandableSection
      index={2}
      header={trainings.indexPage.introductorySection}
      listOfFields={['introductoryTitle', 'introductoryText']}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={trainingIndexLocalized.introductoryShow}
          handleChange={handleIntroductoryShow}
        />
      }
      errors={errors}
      initialIsOpen
    >
      <TrainingFormLine>
        <FieldStyle2
          legend={formatString(
            trainings.indexPage.sectionTitle,
            branding?.companyName || 'easyHire',
          )}
        >
          <InputStyled
            name="form.introductoryTitle"
            width="410px"
            maxLength={INTRODUCTORY_TITLE_MAX_LENGTH}
            ref={register({
              maxLength: INTRODUCTORY_TITLE_MAX_LENGTH,
              validate: watchIntroductoryShow && {
                required: value =>
                  !!value?.trim() || form.notification.theFieldIsRequired,
              },
            })}
            defaultValue={trainingIndexLocalized.introductoryTitle}
          />
        </FieldStyle2>
        <Error message={getIsError('introductoryTitle')} />
        <T8y color="gray" fontSize="12px">
          {formatString(
            trainings.remainingCharacters,
            introductoryRemainingSymbols,
          )}
        </T8y>
      </TrainingFormLine>

      <TrainingFormLine>
        <ReachEditor
          initialValue={trainingIndexLocalized.introductoryText}
          handleChange={handleIntroductoryText}
        />
        <Error message={getIsError('introductoryText')} />
      </TrainingFormLine>
    </GeneralExpandableSection>
  );
};

export default TrainingIndexFormIntroductory;
