import React from 'react';
import { Lang } from 'lang';

import HomePageBanner from 'components/organisms/HomePageBanner';

import * as ST from './styles';

import { T8y } from 'ui';

const HeroImages = () => {
  const {
    marketing: { titles },
  } = Lang();

  return (
    <>
      <T8y color="darkBlue" variant="t1" bold>
        {titles.content}
      </T8y>
      <ST.RowInfoStyled />
      <ST.SubTitleWrapper>
        <T8y color="darkBlue" variant="t2" bold>
          {titles.homePage}
        </T8y>
      </ST.SubTitleWrapper>
      <HomePageBanner />
    </>
  );
};

export default HeroImages;
