import styled from 'styled-components';

import { colors } from 'styleGuide';

export const PostcoderInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const PostcodeListBlock = styled.div`
  position: absolute;
  top: 44px;
  z-index: 10;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #dddddd;
`;

export const PostcodeList = styled.div`
  width: 100%;
  max-height: 380px;
  overflow-x: auto;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-top: 0;
`;

export const PostcodeItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 20px 8px 12px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;

  &:hover {
    background-color: #f0eded;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const SummaryText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const LocationTitle = styled.span`
  color: ${colors.negative} !important;
  font-weight: bold !important;
  font-size: 16px !important;
  margin-bottom: 10px;
`;

export const LocationText = styled.span`
  color: ${colors.gray};
  font-size: 16px !important;
`;

export const BackResults = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 12px 13px;
  font-size: 15px;
  background: #fbfbfb;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;

  svg {
    margin-right: 11px;
  }
`;

export const PostcodeInputLoading = styled.div`
  color: $gray;
`;
