import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { memberships$ } from 'models/memberships';
import { getUser, isUserLoading$, user$ } from 'models/users';

import { USERS } from 'api';

import { FieldStyle } from '../../Product/shared';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { inputStyle, Select } from 'ui';

const UserMembership = () => {
  const {
    users: usersLocalization,
    form: { toastTitle },
  } = Lang();

  const memberships = useStore(memberships$);
  const user = useStore(user$);
  const isUserLoading = useStore(isUserLoading$);

  const [isLoading, setIsLoading] = useState(false);

  const [currentMembershipLevel, setCurrentMembershipLevel] = useState(null);

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    if (user?.membership) {
      setCurrentMembershipLevel(user?.membership);
      return;
    }

    setCurrentMembershipLevel(memberships[0]);
  }, [isUserLoading, memberships, user]);

  const handleChange = useCallback(
    async option => {
      const membershipLevel = memberships.find(el => el.slug === option);
      setCurrentMembershipLevel(membershipLevel);
      setIsLoading(true);
      const membershipLevelId = membershipLevel?.id;

      try {
        await USERS.updateUser(user.id, { membershipLevelId });
        getUser(user.id);
        toast(`${usersLocalization.membershipLevel}  ${toastTitle.wasUpdated}`);
      } catch (e) {
        setCurrentMembershipLevel(user?.membership);
        toast.error(toastTitle.updateError);
      } finally {
        setIsLoading(false);
      }
    },
    [memberships, user, usersLocalization, toastTitle],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={usersLocalization.membershipLevel}>
      <FieldStyle as={ST.InputsWrapperFields} withBorder>
        <Select
          options={memberships}
          selected={currentMembershipLevel?.name}
          onClickOption={e => handleChange(e)}
          className={inputStyle}
          disabled={isLoading || isUserLoading}
          isSelectDisable={isLoading || isUserLoading}
        />
      </FieldStyle>
    </ProductEditorBlockWrapper>
  );
};

export default UserMembership;
