import React, { useCallback, useEffect, useMemo } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { trainingLocalized$ } from 'models/trainings';

import Error from 'components/atoms/Error';
import LocationSearchInput from 'components/atoms/LocationSearchInput';
import TrainingAvailableLocationPricesOnly from 'components/molecules/TrainingAvailableLocationPricesOnly';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
} from 'components/Product/shared';

import TrainingAvailableLocationDates from '../TrainingAvailableLocationDates';
import * as ST from './styles';

import { Radio, T8y } from 'ui';

const TrainingAvailableLocation = ({
  scheduleIndex,
  register,
  values,
  setValue,
  control,
  EMPTY_DATE,
  errors,
  removeSchedule,
  trigger,
  watch,
  unregister,
  clearErrors,
  setError,
}) => {
  const { trainings, form } = Lang();

  const radio = useRadioState({ state: 0 });

  const locationPlaceIdWatcher = watch(
    `form.schedules[${scheduleIndex}].location.placeId`,
  );

  const trainingLocalized = useStore(trainingLocalized$);

  useEffect(() => {
    radio.setState(
      trainingLocalized?.schedules[scheduleIndex]?.variationsType || 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingLocalized]);

  useEffect(() => {
    setValue(
      `form.schedules[${scheduleIndex}].location`,
      actualFieldInfo?.location || null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, scheduleIndex]);

  useEffect(() => {
    setValue(`form.schedules[${scheduleIndex}].variationsType`, radio.state);
    clearErrors(
      radio.state === 0
        ? `form.schedules[${scheduleIndex}].variations`
        : `form.schedules[${scheduleIndex}].variationsPricesOnly`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio.state]);

  const actualFieldInfo = useMemo(() => {
    return values?.form?.schedules[scheduleIndex];
  }, [scheduleIndex, values]);

  const handleLocationChange = useCallback(
    value => {
      setValue(
        `form.schedules[${scheduleIndex}].location`,
        value.placeId ? value : null,
      );
    },
    [scheduleIndex, setValue],
  );

  useEffect(
    () => {
      if (locationPlaceIdWatcher) {
        clearErrors(`form.schedules[${scheduleIndex}].location.name`);
      } else {
        if (
          get(values, ['form', 'schedules', scheduleIndex, 'location', 'name'])
        ) {
          setError(`form.schedules[${scheduleIndex}].location.name`, {
            message: form.notification.chooseCorrectAddress,
          });
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearErrors, locationPlaceIdWatcher, form],
  );

  return (
    <ST.LocationWrapper>
      <ST.LocationControls>
        <T8y>
          {form.fields.location} {scheduleIndex + 1}
        </T8y>
        {scheduleIndex > 0 && (
          <ST.LocationRemove color="gray" onClick={removeSchedule}>
            {form.buttons.delete}
          </ST.LocationRemove>
        )}
      </ST.LocationControls>
      <ST.OrganizerLocationWrapper>
        <ST.LocationInputWrapper>
          <FieldStyle as={FieldStyle2} legend={trainings.courseLocation}>
            <LocationSearchInput
              handleChange={handleLocationChange}
              address={actualFieldInfo?.location || null}
              innerRef={register({
                required: form.notification.theFieldIsRequired,
                validate: value =>
                  (value && !!locationPlaceIdWatcher) ||
                  form.notification.chooseCorrectAddress,
              })}
              inputName={`form.schedules[${scheduleIndex}].location.name`}
            />
          </FieldStyle>
          <Error
            message={get(errors, [
              'form',
              'schedules',
              scheduleIndex,
              'location',
              'name',
              'message',
            ])}
          />
        </ST.LocationInputWrapper>
        <ST.LocationInputWrapper>
          <FieldStyle as={FieldStyle2} legend={trainings.courseOrganizer}>
            <InputStyled
              name={`form.schedules[${scheduleIndex}].organizer`}
              type="text"
              ref={register}
              defaultValue={actualFieldInfo?.organizer || null}
            />
          </FieldStyle>
          <Error
            message={
              <Error
                message={get(errors, [
                  'form',
                  'schedules',
                  scheduleIndex,
                  'organizer',
                ])}
              />
            }
          />
        </ST.LocationInputWrapper>
      </ST.OrganizerLocationWrapper>
      <RadioGroup as={ST.RadioWrapper} {...radio}>
        <ST.FirstRadioWrapper>
          <Radio {...radio} value={0}>
            <ST.AvailableOption>{trainings.schedule}</ST.AvailableOption>
          </Radio>
        </ST.FirstRadioWrapper>
        <div>
          <Radio {...radio} value={1}>
            <ST.AvailableOption>{trainings.noSchedule}</ST.AvailableOption>
          </Radio>
        </div>
      </RadioGroup>
      {values?.form?.schedules?.[scheduleIndex]?.variationsType === 0 ? (
        <TrainingAvailableLocationDates
          scheduleIndex={scheduleIndex}
          register={register}
          values={values}
          setValue={setValue}
          control={control}
          EMPTY_DATE={EMPTY_DATE}
          errors={errors}
          trigger={trigger}
          watch={watch}
          unregister={unregister}
        />
      ) : (
        <TrainingAvailableLocationPricesOnly
          scheduleIndex={scheduleIndex}
          register={register}
          control={control}
          errors={errors}
          values={values}
        />
      )}
    </ST.LocationWrapper>
  );
};

export default TrainingAvailableLocation;
