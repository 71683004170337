import React from 'react';
import { Lang } from 'lang';

import { LinkBack } from 'components/Product/shared';

import { T8y } from 'ui';

import { ReactComponent as BackArrow } from 'assets/icons/backArrow.svg';

const PageHeaderWithBackLink = ({ header, link, linkText }) => {
  const { users } = Lang();

  return (
    <div>
      <T8y color="darkBlue" fontSize="2.4rem" bold>
        {header}
      </T8y>
      <LinkBack to={link}>
        <BackArrow />
        {` ${linkText || users.backToPreviousScreen}`}
      </LinkBack>
    </div>
  );
};

export default PageHeaderWithBackLink;
