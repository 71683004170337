import React from 'react';

import UploadTrainingPicture from 'components/molecules/UploadTrainingPicture';
import { Icon } from 'components/Product/Icon';

import * as ST from './styles';

const TrainingImageItem = ({
  el,
  index,
  handleUpload,
  handleDeleteMainImage,
  accept,
}) => {
  return el?.src ? (
    <ST.ImageWrapper>
      <img src={el.src} alt={`Number ${index + 1}`} />
      <ST.IconWrapper>
        <Icon
          shadow
          color="#fff"
          type="lightDelete"
          handleClick={handleDeleteMainImage}
        />
      </ST.IconWrapper>
    </ST.ImageWrapper>
  ) : (
    <ST.ImageWrapper>
      <UploadTrainingPicture handleUploadEnd={handleUpload} accept={accept} />
    </ST.ImageWrapper>
  );
};

export default TrainingImageItem;
