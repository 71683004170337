import styled from 'styled-components';

export const MessageWrapper = styled.div`
  width: 100%;
  background: #fffdfd;
  border: 1px solid #e28787;
  border-radius: 5px;
  padding: 14px 24px 18px;
  margin-bottom: 30px;
`;
