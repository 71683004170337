import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';

import { StyledIconWrapper } from '../styles';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';

export const EditProductBtn = ({ id }) => {
  return (
    <Link to={PATHS.PRODUCT(id)}>
      <StyledIconWrapper $marginRight="40px">
        <Edit />
      </StyledIconWrapper>
    </Link>
  );
};
