import { analytics } from './it/analytics';
import { categories } from './it/categories';
import { common } from './it/common';
import { form } from './it/form';
import { header } from './it/header';
import { leads } from './it/leads';
import { marketing } from './it/marketing';
import { orders } from './it/orders';
import { product } from './it/product';
import { promoCodes } from './it/promoCodes';
import { settings } from './it/settings';
import { tradeAccounts } from './it/tradeAccounts';
import { trainings } from './it/trainings';
import { users } from './it/users';

export const it = {
  header,
  product,
  marketing,
  form,
  categories,
  analytics,
  users,
  tradeAccounts,
  settings,
  trainings,
  common,
  orders,
  leads,
  promoCodes,
};
