import CustomButton from 'components/atoms/CustomButton';
import SmallDatePicker from 'components/atoms/SmallDatePicker';
import styled from 'styled-components';

import { Radio, Row } from 'ui';

export const PromoCodeRadioWrapper = styled.div`
  display: flex;
  align-self: stretch;

  & label {
    margin-right: 22px;
  }
`;

export const DiscountWrapper = styled.div`
  width: 240px;
`;

export const RowWithMarginTop = styled(Row)`
  margin-top: 20px;
`;

export const DatePickerStyled = styled(SmallDatePicker)`
  height: 44px;
  width: 100%;
  border: 1px solid #e4e8ed;
  border-radius: 3px;

  &:first-child {
    margin-right: 22px;
  }

  span {
    color: #424242;
  }

  .react-date-picker__calendar .react-calendar {
    top: 2px;
  }

  .react-date-picker__inputGroup {
    display: flex;
    align-items: baseline;
  }
`;

export const PromoCodeRadioGroup = styled.div`
  width: 100%;
`;

export const RadioInputWrapper = styled.div`
  display: ${p => (p.show ? 'block' : 'none')};
  width: 100%;
`;

export const ApplyToRadioWrapper = styled(Row)`
  height: 66px;
`;

export const ApplyToRadio = styled(Radio)`
  flex-basis: 30%;
  min-width: 160px;
  margin-top: 10px;
`;

export const ButtonsPanel = styled(Row)`
  position: sticky;
  bottom: 0;
  padding: 10px 0 20px;
  background-color: #fff;
`;

export const PromoCodeButton = styled(CustomButton)`
  &:disabled {
    color: #fff;
  }
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
