import styled from 'styled-components';

import { T8y } from 'ui';

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & label > div {
    margin-right: 12px;
  }
`;

export const FirstRadioWrapper = styled.div`
  margin-bottom: 21px;
`;

export const OptionsWrapper = styled.div`
  margin-bottom: 30px;
`;

export const AvailableOption = styled(T8y)`
  line-height: 22px;
  font-size: 16px;
`;

export const AvailableOptionText = styled(T8y)`
  max-height: 600px;
  line-height: 19px;
  margin-left: 33px;
  font-size: 14px;
  margin-top: 4px;
`;
