import React from 'react';

import * as ST from './styles';

import { Center, T8y } from 'ui';

const ModalForm = ({ title, children }) => {
  return (
    <Center>
      <ST.HeaderWrapper>
        {title && (
          <ST.Header>
            <T8y variant="h2">{title}</T8y>
          </ST.Header>
        )}
        <ST.ContentWrapper>{children}</ST.ContentWrapper>
      </ST.HeaderWrapper>
    </Center>
  );
};

export default ModalForm;
