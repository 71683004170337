import React, { Suspense, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';
import {
  setDefaultTACompaniesFilters,
  setDefaultTACustomersFilters,
} from 'models/tradeAccounts';
import TradeAccount from 'pages/TradeAccount';

import Stepper from 'components/molecules/Tabs';
import CompanyTradeAccounts from 'components/organisms/CompanyTradeAccounts';
import PrivateTradeAccounts from 'components/organisms/PrivateTradeAccounts';

import { HeadersWrapper, SubHeader, TabsWrapper } from './styles';

import { T8y } from 'ui';

const TradeAccounts = () => {
  const { header, tradeAccounts } = Lang();

  const steps = useMemo(
    () => [
      {
        label: tradeAccounts.types.private,
        path: PATHS.TRADE_ACCOUNTS('private'),
      },
      {
        label: tradeAccounts.types.companies,
        path: PATHS.TRADE_ACCOUNTS('companies'),
      },
    ],
    [tradeAccounts],
  );

  useEffect(() => {
    setDefaultTACompaniesFilters();
    setDefaultTACustomersFilters();
  }, []);

  return (
    <>
      <HeadersWrapper>
        <SubHeader>
          <T8y color="darkBlue" fontSize="2.4rem" bold>
            {header.menu.tradeAccounts}
          </T8y>
          <T8y fontSize="1.6rem" color="gray2" fontWeight="600">
            {tradeAccounts.subTitle}
          </T8y>
        </SubHeader>
        <TabsWrapper>
          <Stepper steps={steps} />
        </TabsWrapper>
      </HeadersWrapper>
      <Suspense fallback={<div> Loading ...</div>}>
        <Switch>
          <Route
            path={PATHS.TRADE_ACCOUNTS('private')}
            component={PrivateTradeAccounts}
          />
          <Route
            path={PATHS.TRADE_ACCOUNTS('companies')}
            component={CompanyTradeAccounts}
          />
          <Route
            path={PATHS.TRADE_ACCOUNT_PROFILE('private')}
            component={TradeAccount}
          />
          <Route
            path={PATHS.TRADE_ACCOUNT_PROFILE('companies')}
            component={TradeAccount}
          />
          <Redirect to={PATHS.TRADE_ACCOUNTS('private')} />
        </Switch>
      </Suspense>
    </>
  );
};

export default TradeAccounts;
