import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { categoriesTreeStore } from 'models/categories';
import { settings$ } from 'models/settings';
import { getCategoryName } from 'utils/getCategoryName';

export const useGetProductsListForModal = (products = []) => {
  const settings = useStore(settings$);
  const currentLangState = useStore(currentLang$);
  const categories = useStore(categoriesTreeStore);

  return useMemo(
    () =>
      products.map(product => {
        const currentCategories = getCategoryName(
          product.category?.data,
          categories,
          currentLangState,
          settings?.defaultLanguage,
        );

        const category =
          currentCategories.level1 && currentCategories.level1.name;
        const sub_category =
          currentCategories.level2 && currentCategories.level2.name;
        const sub_sub_category =
          currentCategories.level3 && currentCategories.level3.name;
        return [
          { value: product.id, type: 'checkbox', field: 'id' },
          { value: product.sku, type: 'initial', field: 'sku' },
          {
            value: category,
            type: 'initial',
            field: 'category',
          },
          {
            value: sub_category,
            type: 'initial',
            field: 'sub_category',
          },
          {
            value: sub_sub_category,
            type: 'initial',
            field: 'sub_sub_category',
          },
          {
            value: product.image?.data?.thumb || product.image?.thumb,
            type: 'image',
            field: 'image',
          },
          { value: product.name, type: 'initial', field: 'name' },
        ];
      }),
    [categories, currentLangState, products, settings],
  );
};
