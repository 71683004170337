import React, { useEffect, useMemo } from 'react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { capitalizeFirstLetter } from 'utils/strings';

import Error from 'components/atoms/Error';

import { Icon } from '../../Product/Icon';
import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import * as ST from './styles';

import { inputStyle, Row, Select } from 'ui';

const DAYS = [...Array(31).keys()];

const CalendarHoliday = ({
  register,
  index,
  setValue,
  errors,
  values,
  remove,
  el,
}) => {
  const {
    form,
    analytics,
    product,
    common: { calendar },
  } = Lang();

  const daysList = useMemo(() => {
    return DAYS.map(el => ({ slug: el + 1, name: el + 1 }));
  }, []);

  const monthsList = useMemo(() => {
    return Object.keys(analytics.months).map((el, index) => ({
      slug: el,
      name: analytics.months[el],
      number: index + 1,
    }));
  }, [analytics.months]);

  useEffect(() => {
    register(`blockedDatesList[${index}].day`, {
      required: form.notification.theFieldIsRequired,
    });
    register(`blockedDatesList[${index}].month`, {
      required: form.notification.theFieldIsRequired,
    });

    setValue(`blockedDatesList[${index}].day`, el.day || '');

    setValue(`blockedDatesList[${index}].month`, el.month || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.notification.theFieldIsRequired, index, register, setValue]);

  return (
    <Row align="center">
      <ST.Line>
        <FieldStyle
          as={FieldStyle2}
          legend={capitalizeFirstLetter(product.day)}
          withBorder={true}
          width="160px"
        >
          <Select
            options={daysList}
            selected={values.blockedDatesList[index]?.day}
            defaultText={calendar.selectDay}
            className={inputStyle}
            onClickOption={slug =>
              setValue(`blockedDatesList[${index}].day`, slug)
            }
            maxHeight="30rem"
            placement="bottom-start"
          />
        </FieldStyle>
        <Error
          message={get(errors, ['blockedDatesList', index, 'day', 'message'])}
        />
      </ST.Line>
      <ST.Line>
        <FieldStyle
          as={FieldStyle2}
          legend={calendar.month}
          withBorder={true}
          width="160px"
        >
          <Select
            options={monthsList}
            selected={analytics.months[values.blockedDatesList[index]?.month]}
            defaultText={calendar.selectMonth}
            className={inputStyle}
            onClickOption={slug =>
              setValue(`blockedDatesList[${index}].month`, slug)
            }
            maxHeight="30rem"
            placement="bottom-start"
          />
        </FieldStyle>
        <Error
          message={get(errors, ['blockedDatesList', index, 'month', 'message'])}
        />
      </ST.Line>
      <ST.Line>
        <FieldStyle2 legend={calendar.comment}>
          <InputStyled
            name={`blockedDatesList[${index}].comment`}
            width="425px"
            ref={register}
            defaultValue={values.blockedDatesList[index]?.comment}
          />
        </FieldStyle2>
        <Error />
      </ST.Line>
      <ST.DeleteIconWrapper>
        <Icon color="#fff" type="delete" handleClick={() => remove(index)} />
      </ST.DeleteIconWrapper>
    </Row>
  );
};

export default CalendarHoliday;
