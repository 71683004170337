import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import {
  FieldStyle,
  FieldStyle2,
  TextareaStyled,
} from 'components/Product/shared';

import TrainingAvailableLocation from '../TrainingAvailableLocation';

const TrainingCheckoutAvailable = ({
  watch,
  register,
  values,
  getIsError,
  setValue,
  errors,
  setDeletedFile,
  control,
  trigger,
  EMPTY_SCHEDULE,
  EMPTY_DATE,
  clearErrors,
  unregister,
  setError,
}) => {
  const { trainings, form } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.schedules',
    keyName: 'formId',
  });

  const handleAddSchedule = useCallback(() => {
    append({ ...EMPTY_SCHEDULE });
  }, [EMPTY_SCHEDULE, append]);

  return (
    <>
      {fields.map((el, index) => (
        <TrainingAvailableLocation
          key={el.id}
          watch={watch}
          register={register}
          values={values}
          setValue={setValue}
          setDeletedFile={setDeletedFile}
          getIsError={getIsError}
          errors={errors}
          control={control}
          scheduleIndex={index}
          EMPTY_DATE={EMPTY_DATE}
          removeSchedule={() => remove(index)}
          trigger={trigger}
          unregister={unregister}
          clearErrors={clearErrors}
          setError={setError}
        />
      ))}
      <AddMore text={trainings.addNewLocation} onClick={handleAddSchedule} />
      <FieldStyle
        as={FieldStyle2}
        legend={form.fields.sectionDescription}
        marginTop="30px"
      >
        <TextareaStyled
          name="form.scheduleDescription"
          height="160px"
          ref={register}
        />
      </FieldStyle>
    </>
  );
};

export default TrainingCheckoutAvailable;
