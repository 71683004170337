import { InputStyled } from 'components/Product/shared';
import styled from 'styled-components';

export const DepotFormWrapper = styled.div`
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
`;

export const LineWrapper = styled.div`
  display: flex;
  & > div {
    flex-basis: 50%;

    &:first-child {
      margin-right: 30px;
    }
  }
`;

export const CompanyNamePrefix = styled.span`
  color: #424242 !important;
  padding-left: 15px;
  white-space: nowrap;
`;

export const InputWIthCompanyNameStyled = styled(InputStyled)`
  padding: 0 15px 0 0;
  border: 0;
`;
