import React, { useMemo, useState } from 'react';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { owners$ } from 'models/owners';

import CustomButton from 'components/atoms/CustomButton';
import PageHeaderWithBackLink from 'components/atoms/PageHeaderWithBackLink';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import { FieldStyle, FieldStyle2 } from 'components/Product/shared';
import { history } from 'libs/history';

import * as ST from './styles';

import { inputStyle, Select } from 'ui';

const SelectTrainingOwner = ({ setValue }) => {
  const { trainings, product, form } = Lang();

  const owners = useStore(owners$);

  const [selected, setSelected] = useState(null);

  const formattedOwnersList = useMemo(
    () => owners.map(el => ({ ...el, slug: el.id })),
    [owners],
  );

  const selectedOwner = useMemo(
    () =>
      selected
        ? owners.find(el => el.id.toString() === selected.toString())
        : null,
    [owners, selected],
  );

  return (
    <div>
      <ST.HeaderWrapper>
        <PageHeaderWithBackLink
          header={trainings.addNewCourse}
          link={PATHS.TRAININGS}
        />
      </ST.HeaderWrapper>
      <ST.SectionWrapper>
        <GeneralExpandableSection
          index={0}
          header={product.productOwner}
          listOfFields={['owner']}
          initialIsOpen
        >
          <FieldStyle as={FieldStyle2} width="400px" withBorder={true}>
            <Select
              options={formattedOwnersList}
              selected={selectedOwner?.name}
              defaultText={product.selectFranchisee}
              aLabel="Category"
              onClickOption={setSelected}
              className={inputStyle}
              maxHeight="200px"
            />
          </FieldStyle>
        </GeneralExpandableSection>

        <ST.ButtonsWrapper justify="center">
          <CustomButton
            text={form.buttons.cancel}
            onClick={() => history.push(PATHS.TRAININGS)}
            inverse
          />
          <CustomButton
            text={form.buttons.continue}
            onClick={() => setValue('form.owner', selectedOwner)}
          />
        </ST.ButtonsWrapper>
      </ST.SectionWrapper>
    </div>
  );
};

export default SelectTrainingOwner;
