import React, { useEffect, useState } from 'react';
import * as PRODUCT from 'models/product';

import { Lang } from '../../../lang';
import { FieldStyle, FieldStyle2, InputStyled } from '../shared';
import { SubmitBtn } from '../SubmitBtn';
import { StyledForm } from './styles';

export const CustomModal = ({ dialog, btnText, itemIndex, altTag }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    setInputValue(altTag);
  }, [altTag]);

  const handleClick = async () => {
    setIsLoading(true);
    await PRODUCT.editTag({ alt_tag: inputValue, itemIndex });
    setIsLoading(false);
    dialog.hide();
  };

  const {
    form: { buttons, fields },
  } = Lang();

  return (
    <StyledForm>
      <FieldStyle as={FieldStyle2}>
        <InputStyled
          type="Tag"
          placeholder={fields.tag}
          disabled={isLoading}
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value);
          }}
        />
      </FieldStyle>
      <SubmitBtn
        handleClick={handleClick}
        btnText={btnText}
        btnTextOnLoading={
          btnText === 'Adding' ? buttons.adding : buttons.editing
        }
        isLoading={isLoading}
      />
    </StyledForm>
  );
};
