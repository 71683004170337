import styled from 'styled-components';

export const SeoBody = styled.div`
  width: 100%;
  background: #fff;
  padding: 2.5rem 3rem 3rem;
  border-radius: 0.2rem;
  margin-bottom: 4.7rem;
`;

export const RobotsTagWrapper = styled.div`
  margin-top: 30px;
`;
