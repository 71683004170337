import React from 'react';
import { Lang } from 'lang';

import BigAddMore from 'components/atoms/BigAddMore';
import CustomButton from 'components/atoms/CustomButton';

import * as ST from './styles';

const AddNewAndSaveCancelFooter = ({
  handleAdd,
  handleReset,
  handleSubmit,
  onSubmit,
  isDisabled,
  text,
  isAddBtn,
  submitButtonText,
}) => {
  const {
    form: { buttons },
  } = Lang();

  return (
    <>
      {isAddBtn && (
        <ST.AddMoreWrapper>
          <BigAddMore text={text} onClick={handleAdd} isDisabled={isDisabled} />
        </ST.AddMoreWrapper>
      )}
      <ST.FormButtonsWrapper>
        <CustomButton
          text={buttons.cancel}
          backgroundColor="positive"
          color="gray"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          onClick={handleReset}
        />
        <CustomButton
          text={submitButtonText || buttons.saveChanges}
          onClick={handleSubmit(onSubmit)}
        />
      </ST.FormButtonsWrapper>
    </>
  );
};

export default AddNewAndSaveCancelFooter;
