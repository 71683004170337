import React from 'react';
import { Lang } from 'lang';

import CustomButton from 'components/atoms/CustomButton';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, T8y } from 'ui';

const DepotDeleteConfirmationModal = ({
  disclosure,
  dialogState,
  depotName,
  isDeleting,
  handleDeletingDepot,
  handleModalClose,
}) => {
  const {
    formatString,
    form: { buttons },
    settings,
  } = Lang();

  return (
    <Modal
      disclosure={disclosure}
      title={settings.modals.deleteConfirmationTitle}
      dialogState={dialogState}
      width="420px"
      overflow="hidden"
      backgroundColor={colors.primary}
      lazyRender
    >
      <T8y lineHeight="22px" marginTop="30px">
        {formatString(
          settings.modals.depotDeleteConfirmationText,
          <T8y lineHeight="22px" display="inline-block" bold>
            {depotName}
          </T8y>,
        )}
      </T8y>
      <ST.DepotConfirmationModalControlsWrapper>
        <CustomButton
          text={buttons.cancel}
          backgroundColor="positive"
          color="gray3"
          width="120px"
          height="29px"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          disabled={isDeleting}
          onClick={handleModalClose}
        />
        <CustomButton
          text={buttons.delete}
          backgroundColor="positive"
          color="gray3"
          border="1px solid #F89739"
          colorHovered="gray"
          width="120px"
          height="29px"
          darkSpinner
          isLoading={isDeleting}
          onClick={handleDeletingDepot}
        />
      </ST.DepotConfirmationModalControlsWrapper>
    </Modal>
  );
};

export default DepotDeleteConfirmationModal;
