import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { aboutUs, sectorOfActivity } from 'consts';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { memberships$, membershipsGate } from 'models/memberships';
import { settings$ } from 'models/settings';
import { website$ } from 'models/website';

import Error from 'components/atoms/Error';
import PhoneInputWithCountryInForm from 'components/atoms/PhoneInputWithCountryInForm';
import { PostcoderInput } from 'components/atoms/PostcoderInput';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import UserDeposit from 'components/molecules/UserDeposit';
import * as ST from 'components/molecules/UserMembership/styles';
import UserSpecialDiscount from 'components/molecules/UserSpecialDiscount';
import { USERS } from 'api';

import {
  FieldStyle,
  FieldWithMargin,
  HalfField,
  InputStyled,
} from '../../Product/shared';

import { Checkbox, Grid, inputStyle, Row, Select } from 'ui';

const DEFAULT_USER = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  idVerified: 'no',
  type: 'individual',
  company_details: {
    business_name: null,
    vat_number: null,
  },
  fiscalCode: null,
  billingAddress: null,
  address: {
    address: null,
    postcode: null,
    city: null,
    province: null,
    country: null,
  },
  membershipLevelId: 1,
  sector_of_activity: null,
  know_about_us: null,
};

const UserEditorNew = ({ history }) => {
  const strings = Lang();
  useGate(membershipsGate);
  const settings = useStore(settings$);
  const website = useStore(website$);
  const memberships = useStore(memberships$);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    getValues,
    watch,
    reset,
    setValue,
    errors,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      ...DEFAULT_USER,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const fiscalCode = useMemo(
    () =>
      settings.extraFieldsTradeProfile?.find(
        el =>
          el.name === 'fiscalCode' && (!el.type || el.type === 'individual'),
      ),
    [settings],
  );

  const isDiscountsSectionAvailable = settings?.showDiscountsSectionInCP;

  const onSubmit = useCallback(
    async formData => {
      try {
        setIsLoading(true);

        for (let key in formData) {
          if (formData[key] === '') {
            formData[key] = null;
          }

          if (key === 'address') {
            for (let key in formData.address) {
              if (formData.address[key] === '') {
                formData.address[key] = null;
              }
            }
          }
        }

        const formattedData = {
          ...formData,
          idVerified: formData.idVerified === 'yes',
          membershipLevelId: +formData.membershipLevelId,
        };
        await USERS.createUser(formattedData);
        toast.success(strings.form.toastTitle.successfullyCreated);
        history.push(PATHS.USERS);
      } catch (e) {
        toast.error(
          e.response?.data?.message?.email?.[0] ||
            strings.form.toastTitle.createError,
        );
      } finally {
        setIsLoading(false);
      }
    },
    [history, strings],
  );

  const handleReset = useCallback(() => {
    reset({ ...DEFAULT_USER });
  }, [reset]);

  const modifyOptions = useCallback(
    (options, key) => {
      return options.map(el => {
        el.name = strings.users[key][el.slug];
        return el;
      });
    },
    [strings],
  );

  const modifySectorOfActivity = useMemo(() => {
    return modifyOptions(sectorOfActivity, 'sectorOfActivity');
  }, [modifyOptions]);

  const selectedSectorOfActivity = useMemo(() => {
    return modifySectorOfActivity.find(
      el => el.slug === values.sector_of_activity,
    );
  }, [modifySectorOfActivity, values]);

  const modifyAboutUs = useMemo(() => {
    return modifyOptions(aboutUs, 'aboutUs');
  }, [modifyOptions]);

  const selectedAboutUs = useMemo(() => {
    return modifyAboutUs.find(el => el.slug === values.know_about_us);
  }, [modifyAboutUs, values]);

  const selectedMembership = useMemo(() => {
    return memberships.find(el => el.id == values.membershipLevelId)?.name;
  }, [memberships, values.membershipLevelId]);

  return (
    <>
      <Grid cols="3fr 1.5fr" gaps="0 3rem">
        <div>
          <ProductEditorBlockWrapper
            blockTitle={strings.users.info.generalInformation}
          >
            <HalfField>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={strings.users.info.firstName}
                >
                  <InputStyled
                    name="first_name"
                    ref={register({
                      required: strings.form.notification.theFieldIsRequired,
                    })}
                    defaultValue={values?.first_name}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.first_name?.message} />
              </Row>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={strings.users.info.lastName}
                >
                  <InputStyled
                    name="last_name"
                    ref={register({
                      required: strings.form.notification.theFieldIsRequired,
                    })}
                    defaultValue={values?.last_name}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.last_name?.message} />
              </Row>
            </HalfField>
            <HalfField>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={strings.users.info.emailAddress}
                >
                  <InputStyled
                    name="email"
                    ref={register({
                      required: strings.form.notification.theFieldIsRequired,
                    })}
                    defaultValue={values?.email}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.email?.message} />
              </Row>
              <Row direction="column" fullWidth>
                <FieldStyle
                  legend={strings.users.info.idVerificationStatus}
                  as={FieldWithMargin}
                  withBorder
                >
                  <input
                    type="hidden"
                    name="idVerified"
                    ref={register({
                      required: strings.form.notification.theFieldIsRequired,
                    })}
                    value={values.idVerified}
                  />
                  <Select
                    options={[
                      { slug: 'yes', name: 'Yes' },
                      { slug: 'no', name: 'No' },
                    ]}
                    selected={values.idVerified === 'yes' ? 'Yes' : 'No'}
                    onClickOption={slug => {
                      setValue('idVerified', slug);
                    }}
                    className={inputStyle}
                    disabled={isLoading}
                    isSelectDisable={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.idVerified?.message} />
              </Row>
            </HalfField>
            <HalfField>
              <input
                type="hidden"
                name="type"
                ref={register({
                  required: strings.form.notification.theFieldIsRequired,
                })}
                value={values.type}
              />
              <Checkbox
                label={strings.users.imCompany}
                onChange={e => {
                  setValue('type', e.target.checked ? 'company' : 'individual');
                }}
                checked={values.type === 'company'}
                disabled={isLoading}
              />
            </HalfField>
            {values.type === 'company' && (
              <HalfField>
                <Row direction="column" fullWidth>
                  <FieldStyle
                    as={FieldWithMargin}
                    legend={strings.users.info.businessName}
                  >
                    <InputStyled
                      name="company_details.business_name"
                      ref={register({
                        required: strings.form.notification.theFieldIsRequired,
                      })}
                      defaultValue={values?.company_details?.business_name}
                      disabled={isLoading}
                    />
                  </FieldStyle>
                  <Error
                    message={errors?.company_details?.business_name?.message}
                  />
                </Row>
                <Row direction="column" fullWidth>
                  <FieldStyle
                    as={FieldWithMargin}
                    legend={strings.users.info.vatNumber}
                  >
                    <InputStyled
                      name="company_details.vat_number"
                      ref={register({
                        required: strings.form.notification.theFieldIsRequired,
                      })}
                      defaultValue={values?.company_details?.vat_number}
                      disabled={isLoading}
                    />
                  </FieldStyle>
                  <Error
                    message={errors?.company_details?.vat_number?.message}
                  />
                </Row>
              </HalfField>
            )}
            {fiscalCode && (
              <HalfField>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={strings.users.info.fiscalCodeFieldTitle}
                >
                  <InputStyled
                    name="fiscal_code"
                    ref={register}
                    defaultValue={values?.fiscal_code}
                    disabled={isLoading}
                  />
                </FieldStyle>
              </HalfField>
            )}
            <HalfField>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.users.info.address}
              >
                <InputStyled
                  name="address.address"
                  ref={register}
                  defaultValue={values?.address?.address}
                  disabled={isLoading}
                />
              </FieldStyle>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.leads.billingAddress}
              >
                <InputStyled
                  name="defaultBillingAddress"
                  ref={register}
                  defaultValue={values?.defaultBillingAddress}
                  disabled={isLoading}
                />
              </FieldStyle>
            </HalfField>
            <HalfField>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.users.info.postalCodeFieldTitle}
              >
                {website.shortCompanyName === 'ETH' ? (
                  <PostcoderInput
                    name="address.postcode"
                    register={register}
                    values={values}
                    setEmptyAddress={() => {}}
                    changeDeliveryPostcodeAddress={value => {
                      setValue('address.postcode', value.postcode);
                      setValue('address.address', value.name);
                      setValue('address.city', value.town);
                      setValue('address.country', value.country);
                    }}
                    disabled={isLoading}
                  />
                ) : (
                  <InputStyled
                    name="address.postcode"
                    ref={register}
                    defaultValue={values?.address?.postcode}
                    disabled={isLoading}
                  />
                )}
              </FieldStyle>
              <FieldStyle as={FieldWithMargin} legend={strings.users.info.city}>
                <InputStyled
                  name="address.city"
                  ref={register}
                  defaultValue={values?.city}
                  disabled={isLoading}
                />
              </FieldStyle>
            </HalfField>
            <HalfField>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.users.info.provinceFieldTitle}
              >
                <InputStyled
                  name="address.province"
                  ref={register}
                  defaultValue={values?.province}
                  disabled={isLoading}
                />
              </FieldStyle>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.users.info.country}
              >
                <InputStyled
                  name="address.country"
                  ref={register}
                  defaultValue={values?.country}
                  disabled={isLoading}
                />
              </FieldStyle>
            </HalfField>
            <HalfField>
              <Row direction="column">
                <FieldStyle
                  as={FieldWithMargin}
                  legend={strings.users.info.phoneNumber}
                  withBorder
                >
                  <input
                    type="hidden"
                    name="address.phone"
                    ref={register}
                    defaultValue={values?.address?.phone}
                  />
                  <PhoneInputWithCountryInForm
                    value={values?.address?.phone || ''}
                    setValue={value => setValue('address.phone', value)}
                    control={control}
                    disabled={isLoading}
                    name="address.phone"
                  />
                </FieldStyle>
                <Error message={errors?.address?.phone?.message} />
              </Row>
            </HalfField>
            <HalfField>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.users.info.sectorOfActivity}
                withBorder
              >
                <input
                  type="hidden"
                  name="sector_of_activity"
                  ref={register}
                  defaultValue={values?.sector_of_activity}
                />
                <Select
                  options={sectorOfActivity}
                  selected={selectedSectorOfActivity?.name}
                  onClickOption={slug => {
                    setValue('sector_of_activity', slug);
                  }}
                  className={inputStyle}
                  disabled={isLoading}
                  isSelectDisable={isLoading}
                />
              </FieldStyle>
              <FieldStyle
                as={FieldWithMargin}
                legend={strings.users.info.howDidYouKnowAboutUs}
                withBorder
              >
                <input
                  type="hidden"
                  name="know_about_us"
                  ref={register}
                  defaultValue={values?.know_about_us}
                />
                <Select
                  options={aboutUs}
                  selected={selectedAboutUs?.name}
                  onClickOption={slug => {
                    setValue('know_about_us', slug);
                  }}
                  className={inputStyle}
                  disabled={isLoading}
                  isSelectDisable={isLoading}
                />
              </FieldStyle>
            </HalfField>
          </ProductEditorBlockWrapper>
        </div>
        <div>
          <ProductEditorBlockWrapper blockTitle={strings.users.membershipLevel}>
            <FieldStyle as={ST.InputsWrapperFields} withBorder>
              <input
                type="hidden"
                name="membershipLevelId"
                ref={register}
                defaultValue={values?.membershipLevelId}
              />
              <Select
                options={memberships}
                selected={selectedMembership}
                onClickOption={slug => {
                  const id = memberships.find(el => el.slug === slug)?.id;
                  setValue('membershipLevelId', id);
                }}
                className={inputStyle}
                disabled={isLoading}
                isSelectDisable={isLoading}
              />
            </FieldStyle>
          </ProductEditorBlockWrapper>
          {isDiscountsSectionAvailable && <UserSpecialDiscount isDisabled />}
          <UserDeposit isDisabled />
        </div>
      </Grid>
      <AddNewAndSaveCancelFooter
        onSubmit={onSubmit}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default withRouter(UserEditorNew);
