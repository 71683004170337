import React from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { jobType$ } from 'models/jobTypes';

import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import { Full } from 'components/Product/shared';
import { FILES } from 'api';

import { Cell, Row } from 'ui';

const JobTypeText = ({ errors, setValue }) => {
  const { form, marketing } = Lang();
  const jobType = useStore(jobType$);

  return (
    <GeneralExpandableSection
      index={1}
      header={marketing.jobTypes.marketingText}
      listOfFields={['marketingText']}
      errors={errors}
      initialIsOpen
    >
      <Cell as={Full} area="/ span 3">
        <Row direction="column" fullWidth justify="start">
          <ReachEditor
            initialValue={jobType?.marketingText || ''}
            handleChange={html => setValue('marketingText', html.text)}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'list',
                'textAlign',
                'link',
                'emoji',
                'remove',
                'history',
                'image',
              ],
              list: {
                options: ['unordered'],
              },
              inline: {
                options: ['bold', 'italic'],
              },
              image: {
                className: undefined,
                component: undefined,
                popupClassName: undefined,
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: false,
                uploadCallback: file => {
                  return new Promise(resolve => {
                    const data = new FormData();
                    data.append('file', file);

                    FILES.upload(data)
                      .then(dataFromServer => {
                        if (dataFromServer && dataFromServer.data) {
                          resolve({
                            data: { link: dataFromServer.data.url },
                          });
                        }
                      })
                      .catch(() => {
                        toast.error(form.toastTitle.wentWrong);
                      });
                  });
                },
                previewImage: true,
                defaultSize: {
                  height: 'unset',
                  width: '100%',
                },
                inputAccept: 'image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: true, mandatory: true },
              },
            }}
          />
          <Error message={get(errors, ['marketingText', 'message'])} />
        </Row>
      </Cell>
    </GeneralExpandableSection>
  );
};

export default JobTypeText;
