import { DEFAULT_SELECTOR_STATE } from 'consts';
import { combine, createEffect, createEvent, forward, restore } from 'effector';
import orderBy from 'lodash/orderBy';
import { settings$ } from 'models/settings';

import { TRAININGS } from 'api';

export const setSelectedOwner = createEvent();
export const selectedOwner$ = restore(setSelectedOwner, DEFAULT_SELECTOR_STATE);

export const loadTrainingsFx = createEffect({
  handler: async () => {
    const { data } = await TRAININGS.getAllTrainings();

    return data.data;
  },
});

export const reorderTrainingsFx = createEffect({
  handler: async params => {
    const { data } = await TRAININGS.updateTrainingOrderId(params.id, {
      orderId: params.orderId,
    });

    return data.data;
  },
});

export const getTrainings = createEvent();
export const updateTrainingsOrder = createEvent();

export const trainings$ = restore(loadTrainingsFx.doneData, []).on(
  reorderTrainingsFx.doneData,
  (s, p) => {
    return s.map(item => {
      const reorderedItem = p.find(
        reorderedEl => reorderedEl.id === item.id && reorderedEl,
      );

      if (reorderedItem) {
        return { ...item, orderId: reorderedItem.orderId };
      }

      return { ...item };
    });
  },
);

export const trainingsLoading$ = combine(
  loadTrainingsFx.pending,
  reorderTrainingsFx.pending,
  (isLoadTrainings, isReorderTrainings) => {
    return isLoadTrainings || isReorderTrainings;
  },
);

export const trainingsLocalized$ = combine(
  trainings$,
  settings$,
  (trainings, { defaultLanguage }) => {
    const localizedList = trainings.map(el => {
      return {
        ...el,
        text: el.description[defaultLanguage],
        title: el.title[defaultLanguage],
        mainImage: el.mainImage && {
          id: el.mainImage.data.id,
          src: el.mainImage.data.main,
        },
        schedules: el.schedules.data.map(schedule => ({
          ...schedule,
          variations: schedule.variations.data,
          location: schedule.location.data,
          organizer: schedule.organizer[defaultLanguage],
        })),
        noSchedule: el.noSchedule && {
          ...el.noSchedule,
          organizer: el.noSchedule.organizer?.[defaultLanguage],
        },
      };
    });

    return orderBy(localizedList, item => item.orderId, ['asc']);
  },
);

export const filteredTrainingsLocalized$ = combine(
  trainingsLocalized$,
  selectedOwner$,
  (trainings, owner) => {
    return owner === DEFAULT_SELECTOR_STATE
      ? trainings
      : trainings.filter(
          el => el.owner?.data?.id.toString() === owner.toString(),
        ) || [];
  },
);

forward({
  from: getTrainings,
  to: loadTrainingsFx,
});

forward({
  from: updateTrainingsOrder,
  to: loadTrainingsFx,
});

export const getTrainingIndex = createEvent();

export const loadTrainingIndexFx = createEffect({
  handler: async () => {
    const { data } = await TRAININGS.getTrainingIndex();

    return {
      ...data.data,
      ...(data.data.image && {
        mainImage: {
          id: data.data.image.data.id,
          src: data.data.image.data.main,
        },
      }),
    };
  },
});

forward({
  from: getTrainingIndex,
  to: loadTrainingIndexFx,
});

export const updateTrainingIndex = createEvent();

export const updateTrainingIndexFx = createEffect({
  handler: async params => {
    const { data } = await TRAININGS.updateTrainingIndex(params);

    return {
      ...data.data,
      ...(data.data.image && {
        mainImage: {
          id: data.data.image.data.id,
          src: data.data.image.data.main,
        },
      }),
    };
  },
});

forward({
  from: updateTrainingIndex,
  to: updateTrainingIndexFx,
});

export const trainingIndexLoading$ = combine(
  loadTrainingIndexFx.pending,
  updateTrainingIndexFx.pending,
  (loadingPending, updatePending) => loadingPending || updatePending,
);

export const trainingIndex$ = restore(loadTrainingIndexFx.doneData, null).on(
  updateTrainingIndexFx.doneData,
  (_, p) => p,
);

export const trainingIndexLocalized$ = combine(
  trainingIndex$,
  settings$,
  (training, { defaultLanguage }) => {
    if (!training) {
      return null;
    }

    return {
      ...training,
      title: training.title[defaultLanguage],
      text: training.text[defaultLanguage],
      introductoryTitle: training.introductoryTitle[defaultLanguage],
      introductoryText: training.introductoryText[defaultLanguage],
    };
  },
);

export const getTraining = createEvent();
export const clearTraining = createEvent();

export const loadTrainingFx = createEffect({
  handler: async id => {
    const { data } = await TRAININGS.getTraining(id);

    return data.data;
  },
});

export const createTrainingFx = createEffect({
  handler: async params => {
    const { data } = await TRAININGS.createTraining(params);

    return data.data;
  },
});

export const updateTrainingFx = createEffect({
  handler: async params => {
    const { data } = await TRAININGS.updateTraining(params.id, {
      ...params.data,
      id: undefined,
    });

    return data.data;
  },
});

forward({
  from: getTraining,
  to: loadTrainingFx,
});

export const trainingLoading$ = restore(loadTrainingFx.pending, false);

export const training$ = restore(loadTrainingFx.doneData, null).on(
  clearTraining,
  (_, p) => null,
);

export const trainingLocalized$ = combine(
  training$,
  settings$,
  (training, { defaultLanguage }) => {
    if (!training) {
      return null;
    }

    return {
      ...training,
      description: training.description[defaultLanguage],
      title: training.title[defaultLanguage],
      shortDescription: training.shortDescription[defaultLanguage],
      theory: training.theory[defaultLanguage],
      learningText: training.learningText[defaultLanguage],
      certificateText: training.certificateText[defaultLanguage],
      certificateImage: training.certificateImage && {
        id: training.certificateImage.data.id,
        src: training.certificateImage.data.main,
      },
      galleryImages: training.galleryImages.data.map(el => ({
        id: el.id,
        src: el.main,
      })),
      partnerImages: training.partnerImages.data.map(el => ({
        id: el.id,
        src: el.main,
      })),
      reviews: training.reviews.data.map(review => ({
        ...review,
        photo: review.photo?.data && {
          id: review.photo.data.id,
          src: review.photo.data.main,
        },
        name: review.name[defaultLanguage],
        text: review.text[defaultLanguage],
        position: review.position[defaultLanguage],
      })),
      faqs: training.faqs.data.map(faq => ({
        ...faq,
        question: faq.question[defaultLanguage],
        answer: faq.answer[defaultLanguage],
      })),
      benefits: training.benefits.data.map(benefit => ({
        ...benefit,
        iconImage: benefit.iconImage?.data && {
          id: benefit.iconImage.data.id,
          src: benefit.iconImage.data.main,
        },
        title: benefit.title[defaultLanguage],
        text: benefit.text[defaultLanguage],
      })),
      mainImage: training.mainImage && {
        id: training.mainImage.data.id,
        src: training.mainImage.data.main,
      },
      mainImageThumb: training.mainImageThumb && {
        id: training.mainImageThumb.data.id,
        src: training.mainImageThumb.data.main,
      },
      schedules: training.schedules.data.map(schedule => ({
        ...schedule,
        variations:
          schedule.variationsType === 1
            ? [{ start: null, end: null, price: null, title: '' }]
            : schedule.variations.data,
        variationsPricesOnly:
          schedule.variationsType === 0
            ? [{ price: '', title: '' }]
            : schedule.variations.data,
        location: schedule.location.data,
        organizer: schedule.organizer[defaultLanguage],
      })),
      scheduleDescription: training.scheduleDescription?.[defaultLanguage],
      noSchedule: training.noSchedule?.data && {
        ...training.noSchedule.data,
        variations: training.noSchedule.data.variations.data,
        locations: training.noSchedule.data.locations.data,
        description: training.noSchedule.data.description[defaultLanguage],
        organizer: training.noSchedule.data.organizer?.[defaultLanguage],
      },
      recommendedProducts: training.recommendedProducts?.map(el => el.id) || [],
    };
  },
);
