import Error from 'components/atoms/Error';
import styled from 'styled-components';

import { Row } from 'ui';

export const WhyChooseUsWrapper = styled(Row)`
  padding: 0 30px 15px;
  background-color: #ffffff;
  border-radius: 5px;
`;

export const FieldsWrapper = styled(Row)`
  width: calc(100% - 150px);
`;

export const ErrorStyled = styled(Error)`
  margin-top: 5px;
`;
