export const product = {
  titles: {
    createProduct: 'Create a new product',
    goToAll: 'Go to all products',
    editProduct: 'Edit product',
    addToArchive: 'Add to archive',
    variations: 'Variations',
    uploadPhotos: 'Upload photos',
    dropTheFiles: 'Drop the files here',
    dragFileHere: 'Drag File here or',
    chooseFromYourComputer: 'choose from your computer',
    createVariation: 'Create a variation of an existing product',
    generalInformation: 'General information',
    pricing: 'Pricing',
    additionalCharges: 'Additional Charges',
    seo: 'SEO',
    specifications: 'Specifications',
    faq: 'FAQ',
    reviews: 'Reviews',
    frequentlyHiredTogether: 'Frequently Hired Together',
    requiredExtra: 'Required Extra',
    optionalExtra: 'Optional Extra',
    noRelatedProducts: 'No related products',
    noRequiredExtra: 'No required extra',
    noOptionalExtra: 'No optional extra',
    createRequiredExtra: 'Create required extra',
    createOptionalExtra: 'Create optional extra',
    uploadUserGuides: 'Upload user guides',
    fileDownloaded: 'File downloaded',
    editAltTags: 'Edit alt tags',
    typicalUse: 'Typical Use',
    eventTypes: 'Event Types',
    thelastImage: "The last image can't be deleted",
    editRequiredExtra: 'Edit required extra',
    chooseFrequently: 'Choose Frequently Hired Together',
    chooseProduct: 'Choose Product',
    warehouses: 'Product-Depot Availability',
    warehouseAvailability: 'Available quantity',
    consumables: 'Consumables',
    noConsumables: 'No consumables',
    otherCharges: 'Other Charges',
    chooseOptionalExtra: 'Choose Optional Extra',
    photo: 'Photo',
    extraCode: 'Extra code',
    name: 'Name',
    price: 'Precio',
    saleItem: 'Sale Item',
    chooseRequiredExtra: 'Choose Required Extra',
    videos: 'Videos',
    safetyRecommendations: 'Safety Recommendations',
    thirdParty: 'Third Party Integrations',
    downloads: 'Downloads',
    productProfile: 'Product Profile',
    defaultDisplayedRange: 'Default displayed range',
    platformProduct: 'Platform Product',
    linkProductWithPlatform: 'Link this product with a platform product',
  },

  notifications: {
    skuAlreadyBeenTaken: 'The sku has already been taken',
  },

  safetyRecommendations: {
    selectSafetyRecommendation: 'Select Safety Recommendation',
    listOfSafetyRecommendations: 'List of Safety Recommendations',
  },
  thirdPartyIntegrations: {
    systemName: 'System Name',
    integrationStatus: 'Integration Status',
    externalProductId: 'External Product ID',
    thirdPartiesNote:
      'Note that connecting at least one external product for any active system will disable settings in the Product-Depot Availability section.',
    successConnectModalTitle: 'Connect external product by ID',
    response: 'Response',
    productTitle: 'Product Title',
    productDescription: 'Product Description',
    okConnect: 'OK, Connect this product',
    noIntegration: 'No integrations',
    idIsNotConnected: 'Id is not connected yet',
    idIsAlreadyConnected: 'Id already exists',
    fieldIsEmpty: 'Field is empty',
    locationCode: 'Location code',
    error: 'Error',
  },
  selfCollectionOnly: 'Self Collection only',
  deliveryOnly: 'Delivery only',
  selfCollectionAndDelivery: 'Self Collection and Delivery',
  changesNotSaved:
    'Changes were not saved. Do you really want to leave this page?',
  day: 'day',
  draftMessage:
    'This product profile is in Draft state and is not accessible on the public website. To make this product page available on the public website click Publish button. This action is irreversible.',
  selectFranchisee: 'Select franchisee',
  productOwner: 'Product Owner',
  owner: 'Owner',
};
