import React, { useCallback, useMemo } from 'react';
import { DialogDisclosure } from 'reakit';
import { useGate } from 'effector-react';
import { useGetProductsListForModal } from 'hooks';
import { Lang } from 'lang';
import { productsGate } from 'pages/model';

import ModalWrapper from 'components/molecules/ModalWrapper';
import { IconStyled } from 'components/Product/shared';

import * as ST from './styles';

import { ReactComponent as Plus } from 'assets/images/icon/plus.svg';

export const OpenModalButton = ({
  dialog,
  customButtonText = null,
  icon = null,
  linkColor = null,
  inverseUnderline = false,
}) => {
  const {
    form: { links },
  } = Lang();

  return (
    <DialogDisclosure {...dialog} as="div">
      <ST.AddMoreLink linkColor={linkColor} inverseUnderline={inverseUnderline}>
        <IconStyled as={icon ? icon : Plus} />
        {customButtonText ? customButtonText : links.addMore}
      </ST.AddMoreLink>
    </DialogDisclosure>
  );
};

export const ProductsModal = ({
  dialog,
  currentItems,
  handleAdd,
  handleRemove,
  filteredItems,
  searchStr,
  handleSearch,
  isDisabled,
}) => {
  const {
    product: { titles },
    form: { fields },
  } = Lang();

  useGate(productsGate);

  const newProducts = useGetProductsListForModal(filteredItems);

  const onChangeCheckbox = useCallback(
    id => {
      currentItems.includes(id) ? handleRemove(id) : handleAdd(id);
    },
    [currentItems, handleAdd, handleRemove],
  );

  const fieldsForTable = useMemo(
    () => [
      fields.productCode,
      fields.category,
      fields.subCategory,
      fields.subSubCategory,
      fields.photo,
      fields.name,
    ],
    [fields],
  );

  return (
    <ModalWrapper
      title={titles.chooseProduct}
      onChangeCheckbox={onChangeCheckbox}
      searchStr={searchStr}
      onSearch={handleSearch}
      fieldsForTable={fieldsForTable}
      newProducts={newProducts}
      cols="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr"
      selected={currentItems}
      needDisclosure={false}
      dialogState={dialog}
      isSelect={true}
      isDisabled={isDisabled}
    />
  );
};
