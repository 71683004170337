import { useCallback, useEffect, useMemo } from 'react';
import { useRadioState } from 'reakit/Radio';
import {
  AVAILABLE,
  NOT_AVAILABLE,
  SELF_COLLECTION,
  SELF_COLLECTION_ONLY,
} from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { shortDeliveries$ } from 'models/deliveries';
import * as productModel from 'models/product';
import { warehouses$ } from 'models/warehouses';
import { currentProductOwner$ } from 'pages/model';

export const useSelectorsState = (
  setValue,
  watch,
  clearErrors,
  isNewProduct,
) => {
  const { product: productLocalization } = Lang();

  const product = useStore(productModel.productStore);
  const shortDeliveries = useStore(shortDeliveries$);
  const currentProductOwner = useStore(currentProductOwner$);
  const warehouses = useStore(warehouses$);

  const radio = useRadioState({
    state: !currentProductOwner?.checkoutAvailability
      ? NOT_AVAILABLE
      : AVAILABLE,
  });

  const deliveryOptionWatcher = watch('deliveryOption');
  const deliveryIdWatcher = watch('delivery_id');

  const optionsForSelect = useMemo(() => {
    return shortDeliveries.filter(
      el => el.slug !== NOT_AVAILABLE && el.slug !== SELF_COLLECTION,
    );
  }, [shortDeliveries]);

  const optionsForOptionSection = useMemo(() => {
    let allOptions = [
      {
        slug: SELF_COLLECTION_ONLY,
        name: productLocalization.selfCollectionOnly,
      },
      { slug: 'deliveryOnly', name: productLocalization.deliveryOnly },
      { slug: 'both', name: productLocalization.selfCollectionAndDelivery },
    ];

    if (
      !warehouses.length &&
      (isNewProduct ||
        (product.deliveryOption !== 'selfCollectionOnly' &&
          product.deliveryOption !== 'both'))
    ) {
      allOptions = [
        { slug: 'deliveryOnly', name: productLocalization.deliveryOnly },
      ];
    }

    return allOptions;
  }, [product, isNewProduct, productLocalization, warehouses.length]);

  const selectedDeliveryType = useMemo(() => {
    return optionsForSelect.find(el => el.id === deliveryIdWatcher);
  }, [optionsForSelect, deliveryIdWatcher]);

  const selectedDeliveryOption = useMemo(() => {
    return optionsForOptionSection.find(
      el => el.slug === deliveryOptionWatcher,
    );
  }, [optionsForOptionSection, deliveryOptionWatcher]);

  useEffect(() => {
    const initialDelivery = product?.delivery_id
      ? shortDeliveries.find(el => el.id === product.delivery_id)
      : null;

    radio.setState(
      initialDelivery?.slug === NOT_AVAILABLE ||
        !currentProductOwner?.checkoutAvailability
        ? NOT_AVAILABLE
        : AVAILABLE,
    );

    if (currentProductOwner && !currentProductOwner.checkoutAvailability) {
      const selectedDelivery = shortDeliveries.find(
        el => el.slug === NOT_AVAILABLE,
      );

      setValue('deliveryOption', null);
      setValue('delivery_id', selectedDelivery?.id || 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, shortDeliveries, currentProductOwner]);

  const selectDeliveryType = useCallback(
    slug => {
      radio.setState(slug === NOT_AVAILABLE ? NOT_AVAILABLE : AVAILABLE);

      const selectedDelivery = shortDeliveries.find(el => el.slug === slug);

      setValue('delivery_id', selectedDelivery?.id || 0, {
        shouldDirty: true,
      });

      if (selectedDelivery || slug === NOT_AVAILABLE) {
        clearErrors('delivery_id');
        clearErrors('deliveryOption');
      }
    },
    [radio, shortDeliveries, setValue, clearErrors],
  );

  const handleRadioClick = useCallback(
    (e, value) => {
      e.preventDefault();
      e.stopPropagation();

      if (value !== undefined) {
        radio.setState(value);
        selectDeliveryType(value);
        setValue('deliveryOption', null, {
          shouldDirty: true,
        });
      }
    },
    [radio, selectDeliveryType, setValue],
  );

  const selectDeliveryOption = useCallback(
    slug => {
      setValue('deliveryOption', slug, {
        shouldDirty: true,
      });
      setValue('delivery_id', null, {
        shouldDirty: true,
      });

      if (slug === SELF_COLLECTION_ONLY) {
        clearErrors('delivery_id');
      }
    },
    [clearErrors, setValue],
  );

  return useMemo(
    () => ({
      selectedDeliveryType,
      selectedDeliveryOption,
      handleRadioClick,
      selectDeliveryOption,
      selectDeliveryType,
      optionsForOptionSection,
      optionsForSelect,
      deliveryOptionWatcher,
      radio,
    }),
    [
      handleRadioClick,
      selectDeliveryOption,
      selectDeliveryType,
      selectedDeliveryOption,
      selectedDeliveryType,
      optionsForOptionSection,
      optionsForSelect,
      deliveryOptionWatcher,
      radio,
    ],
  );
};
