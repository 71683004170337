export const getFlatCategoriesList = (
  item,
  key,
  collection,
  level,
  parent = null,
) => {
  collection.push({
    ...item,
    level,
    parent,
    group: level.toString() + (parent ? parent.toString() : ''),
  });

  for (let i = 0; i < item[key].data.length; i++) {
    let child = item[key].data[i];
    getFlatCategoriesList(child, key, collection, level + 1, item.id);
  }
};
