export const promoCodes = {
  searchPlaceholder: 'Comienza a escribir para filtrar los resultados',
  promoCodesFound: '{0} códigos promocionales disponibles',
  addNewPromoCode: 'Agregar nuevo código promocional',
  addPromoCode: 'Agregar código promocional',
  editPromoCode: 'Editar código promocional',
  taUsersOnly: 'Sólo usuario de las Cuentas de Confianza',
  allCatalogue: 'Todo el catálogo',
  specificCategories: 'Categorías específicas',
  specificProducts: 'Productos específicos',
  pause: 'Pausar',
  resume: 'Reanudar',
  enterProductSKU: 'Ingresar el SKU del producto',
  useCommaToSeparate: 'Usa una coma para separar ítems',
  cat: 'CAT',
  maxDiscount: 'Descuento máximo',

  statuses: {
    active: 'Activo',
    past: 'Antiguo',
    paused: 'Pausado',
  },

  types: {
    multiple: 'Múltiple',
    onTime: 'Un solo uso',
  },

  userTypes: {
    all: 'Todos los usuarios',
    taOnly: 'Sólo usuarios de las Cuentas de Confianza',
    notTaOnly: 'Sólo usuarios invitados',
  },

  tableHeaders: {
    promoTextCode: 'Texto del código promocional',
    discountValue: 'Valor del descuento',
    status: 'Estado',
    type: 'Tipo',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    applyPromoCodeTo: 'Aplicar código promocional a',
  },

  filterSelect: {
    all: 'Todos',
    active: 'Activar códigos promocionales',
    paused: 'Pausar códigos promocionales',
    past: 'Códigos promocionales antiguos',
  },
};
