import React from 'react';
import { Lang } from 'lang';

import * as ST from './styles';

import { Checkbox } from 'ui';

const ProductCarbonCheckbox = ({ values, setValue }) => {
  const { form } = Lang();

  return (
    <ST.Wrapper>
      <Checkbox
        label={form.checkbox.showCarbon}
        onChange={e =>
          setValue('carbonElementsShow', e.target.checked, {
            shouldDirty: true,
          })
        }
        checked={values && values.carbonElementsShow}
      />
    </ST.Wrapper>
  );
};

export default ProductCarbonCheckbox;
