export const trainings = {
  title: 'Gestão de treinamento',
  subTitle: 'Gerenciar página de índice de treinamento e cursos',
  trainingIndexPage: 'Página Índice de Treinamento',
  trainingsCourses: 'Cursos de Treinamento',
  addNewCourse: 'Adicionar novo curso',
  closestDate: 'Data mais próxima',
  showSection: 'Mostrar seção',
  remainingCharacters: '{0} caracteres restantes',
  heroImage: 'Hero Imagem',
  heroImageOrientation: 'Imagem JPG {0} x {1} pixels, orientação paisagem',
  thumbnailImage: 'Imagem em miniatura',
  thumbnailImageOrientation: 'Imagem JPG 600 x 450 pixels, orientação paisagem',
  yes: 'Sim',
  no: 'Não',
  indexPage: {
    title: 'Página Índice de Treinamento',
    generalInformation: 'Informações gerais',
    introductorySection: 'Seção introdutória',
    exploreOurTrainingCourses: 'Explore nossos cursos de treinamento',
    pageTitle: 'Título da página',
    introductoryText: 'Texto introdutório',
    automaticallyGeneratedSection:
      'Esta seção é gerada automaticamente usando os cursos que você cria',
    sectionTitle: 'Título da Seção (por exemplo: Treinamento em {0}, etc.)',
  },
  courseStatus: 'Status do curso',
  courseTitle: 'Título do curso',
  courseShortDescription: 'Breve descrição do curso',
  courseDescription: 'Descrição do Curso',
  courseLearning: 'O que você vai aprender',
  courseWhyChooseUs: 'Porque escolher-nos',
  courseReviews: 'Avaliações do Curso',
  frequentlyAskedQuestions: 'Perguntas frequentes',
  theoryAndPractice: 'Horas Teóricas e Práticas (ex: 4h Teoria, 8h Prática)',
  courseGallery: 'Galeria do curso',
  galleryImageRequirements:
    'Requisitos de arquivo: imagem JPG 800 x 600 pixels, orientação paisagem',
  benefitImageRequirements: 'Imagem JPG ou PNG, 120 x 120 pixels',
  scheduleCourses: 'Cursos agendados',
  availabilityAndSchedule: 'Verificar disponibilidade e horários',
  courseIsAvailable: 'O curso está disponível para checkout',
  courseIsAvailableText:
    'Os usuários poderão fazer uma reserva online usando um processo de checkout simples.',
  courseIsNotAvailable: 'CO curso não está disponível para checkout',
  courseIsNotAvailableText:
    'Os usuários poderão enviar uma solicitação usando um formulário de contato simples.',
  courseLocation: 'Local do curso',
  courseOrganizer: 'Organizador do curso (opcional)',
  datesAndPrice: 'Datas e preços dos cursos programados',
  startDate: 'Data de início',
  endDate: 'Data de finalização (opcional)',
  coursePrice: 'Preço do Curso',
  addNewDate: 'Adicionar nova data',
  addNewLocation: 'Adicionar novo local',
  addNewVariation: 'Adicionar Nova Variação',
  addNewBenefit: 'Adicionar novo benefício',
  addNewReview: 'Adicionar nova avaliação',
  addNewFAQ: 'Adicionar Nova Pergunta Frequente',
  addNewProduct: 'Adicionar produto',
  scheduleDescription:
    'Descrição da Programação (por exemplo: a Programação está disponível mediante solicitação, etc.)',
  courseLocations: 'Local(is) do curso',
  priceExample: '(ex.: 200,00€ + IVA)',
  partnership: 'O curso está disponível em colaboração com',
  partnershipImageRequirements:
    'Requisitos de arquivo: imagem JPG, PNG ou SVG, NN x NN pixels',
  closestDateAutomaticallyGenerated:
    'Esta seção é criada automaticamente usando os dados do curso que você fornece em outras seções',
  aboutCertificate: 'Sobre o Certificado',
  certificateImage:
    'Imagem do certificado (imagem JPG 600 x 400 pixels, orientação paisagem)',
  emptyDates: 'Datas a confirmar',
  emptyLocation: 'Organizador do curso e local(is) a confirmar',
  emptyPrice: 'Preço a confirmar',
  emptyTheory: 'Horas de Teoria e Prática a confirmar',
  schedule: 'Programação (datas e preços)',
  noSchedule: 'Sem horário (apenas preço)',
  courseVariationTitle: 'Título da variação do curso (opcional)',
  recommendedProducts: 'Produtos Recomendados',
  benefits: {
    enterBenefitTitle: 'Insira o título do benefício',
    icon: 'Ícone',
    shortTitle: 'Título curto',
    details: 'Detalhes do benefício',
  },
  reviews: {
    enterReviewFields: 'Insira os campos de revisão',
    photo: 'Foto',
    review: 'Avaliação',
    fullName: 'Nome Completo (por exemplo: John Smith)',
    position: 'Posição (por exemplo: Stage Engineer em SomeWhere) (opcional)',
  },
  faq: {
    enterFaq: 'Digite a pergunta',
    question: 'Pergunta',
    answer: 'Resposta',
  },
  enterCourseTitle: 'Insira o título do curso',
};
