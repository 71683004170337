import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';

import * as ST from './styles';

import { T8y } from 'ui';

const OrderContactSection = ({ order, isTraining }) => {
  const { users, leads, orders } = Lang();

  const settings = useStore(settings$);

  const fiscalCode = useMemo(
    () =>
      settings.extraFieldsTradeProfile?.find(
        el =>
          el.name === 'fiscalCode' && (!el.type || el.type === 'individual'),
      ),
    [settings],
  );

  if (isTraining) {
    return (
      <div>
        <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
          {users.info.contactInformation}
        </T8y>
        <ST.ContactItem fontSize="16px">
          {leads.tableHeaders.fullName}: {order.name}
        </ST.ContactItem>
        <ST.ContactItem fontSize="16px">
          {leads.tableHeaders.phoneNumber}: {order.phone}
        </ST.ContactItem>
        <ST.ContactItem fontSize="16px">
          {leads.tableHeaders.emailAddress}: {order.email}
        </ST.ContactItem>
      </div>
    );
  }

  return (
    <div>
      <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
        {users.info.contactInformation}
      </T8y>

      {order.user?.type === 'company' ? (
        <>
          <ST.ContactItem fontSize="16px">
            {users.info.userType}: {leads.userTypes[order.user?.type]}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.companyName}: {order.user?.company}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {users.info.vatNumber}: {order.user?.vat}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.fullName}: {order.user?.name}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.phoneNumber}: {order.user?.phone}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.emailAddress}: {order.user?.email}
          </ST.ContactItem>

          {order.billingAddress && (
            <ST.ContactItem fontSize="16px">
              {leads.billingAddress}: {order.billingAddress}
            </ST.ContactItem>
          )}
        </>
      ) : (
        <>
          <ST.ContactItem fontSize="16px">
            {users.info.userType}: {leads.userTypes[order.user?.type]}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.fullName}: {order.user?.name}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.phoneNumber}: {order.user?.phone}
          </ST.ContactItem>
          <ST.ContactItem fontSize="16px">
            {leads.tableHeaders.emailAddress}: {order.user?.email}
          </ST.ContactItem>

          {order.billingAddress && (
            <ST.ContactItem fontSize="16px">
              {leads.billingAddress}: {order.billingAddress}
            </ST.ContactItem>
          )}

          {fiscalCode && (
            <ST.ContactItem fontSize="16px">
              {leads.customerTax}: {order.user?.tax}
            </ST.ContactItem>
          )}
        </>
      )}
      {order.referenceNumber && (
        <ST.ContactItem fontSize="16px">
          {orders.orderTableHeaders.purchaseOrderNumber}:{' '}
          {order.referenceNumber}
        </ST.ContactItem>
      )}
    </div>
  );
};

export default OrderContactSection;
