import styled from 'styled-components';

import { colors } from 'styleGuide';

export const TwoFieldWrapper = styled.div`
  position: relative;
`;

export const CompanyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 20px 8px 12px;
  border: 1px solid #eeeeee;
  border-top: 0;
  cursor: pointer;

  &:hover {
    background-color: #f0eded;
  }
`;

export const ListFooter = styled(CompanyItem)`
  color: ${colors.primary};
  background-color: white;
  border: 1px solid #eeeeee;
`;

export const CompanyInput = styled.div`
  position: relative;
`;

export const CompanyListBlock = styled.div`
  position: absolute;
  top: calc(100% - 26px);
  z-index: 10;
  width: 100%;
  max-height: 500px;
`;

export const CompanyListWrapper = styled.div`
  @media screen and (max-width: 991px) {
    margin-bottom: 120px;
  }
`;

export const CompanyName = styled.span`
  @include crop;
  max-width: 600px;

  @media screen and (max-width: 575px) {
    max-width: 200px;
  }
`;

export const CompanyList = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-x: auto;
  background: #ffffff;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const SummaryText = styled.div`
  display: flex;
  margin-right: 10px;
  font-size: 15px;

  mark {
    background-color: transparent;
  }
`;

export const AdditionalTwoInputClass = styled.div`
  position: relative;
`;

export const OptionId = styled.span`
  display: flex;
  align-items: center;
`;
