import React from 'react';

import { Icon } from '../../Product/Icon';
import * as ST from './styles';

const TrainingHeroImageWrapper = ({ src, handleDeleteMainImage }) => {
  return (
    <ST.HeroImageWrapper>
      <img src={src} alt="Main" />
      <ST.IconWrapper>
        <Icon
          type="lightDelete"
          color="white"
          handleClick={handleDeleteMainImage}
          shadow
        />
      </ST.IconWrapper>
    </ST.HeroImageWrapper>
  );
};

export default TrainingHeroImageWrapper;
