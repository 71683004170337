import styled from 'styled-components';

import { Radio, Row, T8y } from 'ui';

export const EnquiryFormWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const RadioOptionStyled = styled(Radio)`
  margin-top: 10px;
  flex-shrink: 0;

  &:first-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 1500px) {
    margin-top: 0;
    flex-shrink: 1;
  }
`;

export const NotAvailableRadio = styled(RadioOptionStyled)`
  flex-basis: 30%;

  @media screen and (max-width: 1500px) {
    flex-basis: 100%;
    margin-top: 15px;
  }
`;

export const AvailableCheckoutOptions = styled(Row)`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-basis: 70%;

  @media screen and (max-width: 1500px) {
    flex-basis: 100%;
  }
`;

export const QuestionPartWrapper = styled.div`
  margin-top: 30px;
`;

export const RadioWrapper = styled.div`
  &:first-child {
    margin-bottom: 21px;
  }
`;

export const AvailableOption = styled(T8y)`
  line-height: 22px;
  font-size: 16px;
`;

export const AvailableOptionText = styled(T8y)`
  max-height: 600px;
  line-height: 19px;
  margin-left: 26px;
  font-size: 14px;
  margin-top: 4px;
`;
