import styled from 'styled-components';

import { T8y } from 'ui';

export const PriceFieldWrapper = styled.div`
  width: 200px;
  margin-right: 20px;
`;

export const DatePriceFieldTitle = styled(T8y)`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 12px;
`;

export const DatePriceVATWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const VatWrapper = styled(T8y)`
  margin-left: 5px;
`;
