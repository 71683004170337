import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';
import { copyProduct } from 'models/product';

import CustomButton from 'components/atoms/CustomButton';
import ProductEditorTitle from 'components/atoms/ProductEditorTitle';
import styled from 'styled-components';

import { doNothing, LinkBack } from './shared';

import { colors } from 'styleGuide';
import { Grid } from 'ui';

import { ReactComponent as BackArrow } from 'assets/icons/backArrow.svg';

const RowInfoStyled = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid ${colors.lightGray};
  margin-bottom: 4.5rem;
`;

const CustomButtonStyled = styled(CustomButton)`
  min-width: 20rem;
  height: 5rem;
  border-radius: 0.5rem;
  color: ${p => (p.color ? colors[p.color] : colors.gray3)};

  &:disabled,
  &[disabled] {
    background-color: ${colors.lightGray};
    border-color: ${colors.lightGray};
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

const HeaderPart = styled.div`
  place-self: start;
`;

const ManagePanel = ({
  loading,
  isNewProduct,
  id,
  history,
  values,
  setValue,
  register,
  trigger,
  isNoPhotos,
}) => {
  const {
    product: { titles },
    form: { buttons },
  } = Lang();

  const handleSubmit = useCallback(
    async (e, isPublish) => {
      if (loading) {
        doNothing(e);
        return;
      }

      const result = await trigger();

      if (!result || isNoPhotos) {
        return;
      }

      if (isPublish) {
        register('publish');
        setValue('publish', true);
      }
    },
    [isNoPhotos, loading, register, setValue, trigger],
  );

  const gridColumns = useMemo(() => {
    if (!isNewProduct && (values.hidden || loading)) {
      return '3fr 1fr 1fr 1fr 1fr';
    }

    if (!isNewProduct || values.hidden || loading) {
      return '3fr 1fr 1fr 1fr';
    }

    return '3fr 1fr 1fr';
  }, [isNewProduct, loading, values.hidden]);

  return (
    <Grid as={RowInfoStyled} gaps="3rem" cols={gridColumns} places="center end">
      <HeaderPart>
        <ProductEditorTitle values={values} />
        <div>
          <BackArrow />{' '}
          <LinkBack to={PATHS.PRODUCTS}>{titles.goToAll}</LinkBack>
        </div>
      </HeaderPart>
      {!isNewProduct && (
        <CustomButtonStyled
          text={buttons.createCopy}
          backgroundColor="positive"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          disabled={loading}
          onClick={() => {
            copyProduct(id);
            history.push(PATHS.CREATE_PRODUCT);
          }}
        />
      )}

      <CustomButtonStyled
        text={buttons.cancel}
        backgroundColor="positive"
        border="1px solid #D0D0D0"
        colorHovered="gray"
        disabled={loading}
        onClick={() => {
          history.push(PATHS.PRODUCTS);
        }}
      />

      <CustomButtonStyled
        text={buttons.save}
        backgroundColor="primary"
        color="positive"
        border="1px solid #F89739"
        colorHovered="gray"
        disabled={loading}
        type="submit"
        onClick={handleSubmit}
      />

      {(values.hidden || loading) && (
        <CustomButtonStyled
          text={buttons.publish}
          backgroundColor="positive"
          border="1px solid #F89739"
          colorHovered="gray"
          disabled={loading}
          type="submit"
          onClick={e => {
            handleSubmit(e, true);
          }}
        />
      )}
    </Grid>
  );
};

export default withRouter(ManagePanel);
