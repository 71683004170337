import React, { useEffect, useMemo } from 'react';
import { Popover, useDialogState, usePopoverState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  categoriesTreeStore,
  getCategoriesTree,
  isLoadingCategories$,
} from 'models/categories';
import {
  allAvailableProducts$,
  allProductsLoading$,
  allProductsWithoutFiltersLocalized$,
  getAllProductsWithoutFiltersFx,
  isAllProductsWithoutFiltersLoading$,
} from 'pages/model';

import AddMore from 'components/atoms/AddMore';
import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import ChosenProductsList from 'components/molecules/ChosenProductsList';
import { ProductsModal } from 'components/molecules/VariationalsModal';

import * as ST from './styles';
import { useDndPopularProducts } from './useDndPopularProducts';
import { useManagePopularProducts } from './useManagePopularProducts';
import { usePopularModalFilterAndSearch } from './usePopularModalFilterAndSearch';

import { T8y } from 'ui';

const PopularProducts = () => {
  const {
    marketing: { titles },
    trainings,
    form,
  } = Lang();

  const dialog = useDialogState();
  const allProductsLoading = useStore(allProductsLoading$);
  const allProducts = useStore(allProductsWithoutFiltersLocalized$);
  const allAvailableProducts = useStore(allAvailableProducts$);
  const isAllProductsWithoutFiltersLoading = useStore(
    isAllProductsWithoutFiltersLoading$,
  );
  const categories = useStore(categoriesTreeStore);
  const isCategoriesLoading = useStore(isLoadingCategories$);

  const popover = usePopoverState({
    gutter: 3,
  });

  const {
    popularProducts,
    isLoadingList,
    handleAddProduct,
    handleRemoveProduct,
    idsOfPopularProducts,
    handleRemoveFromModal,
  } = useManagePopularProducts();

  const { popularDndProducts, onDragEnd } = useDndPopularProducts(
    popularProducts,
    true,
  );

  const {
    filteredItems,
    searchStr,
    setSearchStr,
  } = usePopularModalFilterAndSearch(allAvailableProducts);

  const isAddProductsBlocked = useMemo(() => {
    return popularDndProducts.length >= 10;
  }, [popularDndProducts.length]);

  useEffect(() => {
    if (!isAllProductsWithoutFiltersLoading && !allProducts.length) {
      getAllProductsWithoutFiltersFx();
    }

    if (!isCategoriesLoading && !categories?.length) {
      getCategoriesTree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {(isLoadingList ||
        allProductsLoading ||
        isAllProductsWithoutFiltersLoading) && <SpinnerOverlay />}
      <ProductsModal
        dialog={dialog}
        currentItems={idsOfPopularProducts}
        handleAdd={handleAddProduct}
        handleRemove={handleRemoveFromModal}
        filteredItems={filteredItems}
        searchStr={searchStr}
        handleSearch={e => {
          setSearchStr(e.target?.value || '');
        }}
        isDisabled={isLoadingList}
      />
      <T8y color="darkBlue" fontSize="24px" bold>
        {titles.popularProducts}
      </T8y>
      <T8y color="gray" fontSize="18px" marginBottom="30px">
        {titles.popularProductsNote}
      </T8y>
      <ST.PopularProductsBody>
        <ChosenProductsList
          allProductsList={allProducts}
          arrayName="popularProducts"
          chosenProductsIdsList={popularDndProducts}
          register={() => {}}
          remove={handleRemoveProduct}
          onDragEnd={onDragEnd}
          isDraggable
        />
        <ST.AddProductsWrapper
          onMouseEnter={() => {
            if (isAddProductsBlocked) {
              popover.show();
            }
          }}
          onMouseLeave={popover.hide}
        >
          <AddMore
            fontSize="1.4rem"
            text={trainings.addNewProduct}
            disabled={isAddProductsBlocked}
            onClick={() => dialog.show()}
          />
          <Popover
            {...popover}
            tabIndex={0}
            style={{ background: 'none', padding: 0 }}
          >
            <ST.LimitedProductsWrapper>
              <T8y fontSize="1.2rem" color="positive">
                {form.descriptions.popularMaxReached}
              </T8y>
            </ST.LimitedProductsWrapper>
          </Popover>
        </ST.AddProductsWrapper>
      </ST.PopularProductsBody>
    </div>
  );
};

export default PopularProducts;
