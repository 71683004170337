import React from 'react';
import { Tooltip, TooltipReference, useTooltipState } from 'reakit';

import * as ST from './styles';

const TooltipWrapper = ({
  TooltipReferenceComponent,
  tooltipText,
  disabled,
}) => {
  const tooltip = useTooltipState({
    unstable_offset: [100, -85],
  });

  if (disabled) {
    return <TooltipReferenceComponent />;
  }

  return (
    <>
      <TooltipReference {...tooltip}>
        <TooltipReferenceComponent />
      </TooltipReference>
      <Tooltip {...tooltip}>
        <ST.TooltipTextWrapper>{tooltipText}</ST.TooltipTextWrapper>
      </Tooltip>
    </>
  );
};

export default TooltipWrapper;
