import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import { POPULAR_PRODUCTS } from 'api';

export const useManagePopularProducts = () => {
  const { form } = Lang();

  const [popularProducts, setPopularProducts] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const handleAddProduct = useCallback(
    async id => {
      try {
        setIsLoadingList(true);
        const { data } = await POPULAR_PRODUCTS.addPopularProduct({
          productId: id,
        });
        if (Array.isArray(data.data)) {
          setPopularProducts(data.data);
          toast.success(form.toastTitle.successfullyUpdated);
        } else {
          if (data.data === 'limitReached') {
            toast.error(form.descriptions.popularMaxReached);
          }
        }
      } catch (e) {
        toast.error(form.toastTitle.updateError);
      } finally {
        setIsLoadingList(false);
      }
    },
    [form],
  );

  const handleRemoveProduct = useCallback(
    async id => {
      try {
        setIsLoadingList(true);
        const { data } = await POPULAR_PRODUCTS.removePopularProduct(id);
        setPopularProducts(data.data);
        toast.success(form.toastTitle.successfullyDeleted);
      } catch (e) {
        toast.error(form.toastTitle.deleteError);
      } finally {
        setIsLoadingList(false);
      }
    },
    [form.toastTitle.deleteError, form.toastTitle.successfullyDeleted],
  );

  const getAllProducts = useCallback(async () => {
    try {
      setIsLoadingList(true);
      const { data } = await POPULAR_PRODUCTS.getAllPopularProducts();
      setPopularProducts(data.data);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoadingList(false);
    }
  }, []);

  const handleRemoveFromModal = useCallback(
    productId => {
      const popularId = popularProducts.find(el => el.productId === productId)
        .id;
      handleRemoveProduct(popularId);
    },
    [handleRemoveProduct, popularProducts],
  );

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idsOfPopularProducts = useMemo(() => {
    return popularProducts.map(el => el.productId);
  }, [popularProducts]);

  return useMemo(
    () => ({
      popularProducts,
      isLoadingList,
      handleAddProduct,
      handleRemoveProduct,
      idsOfPopularProducts,
      handleRemoveFromModal,
    }),
    [
      handleAddProduct,
      handleRemoveProduct,
      idsOfPopularProducts,
      isLoadingList,
      popularProducts,
      handleRemoveFromModal,
    ],
  );
};
