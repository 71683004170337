import React from 'react';

import * as ST from './styles';

const TrainingFormLine = ({ className, children }) => {
  return (
    <ST.TrainingFormLineWrapper className={className}>
      {children}
    </ST.TrainingFormLineWrapper>
  );
};

export default TrainingFormLine;
