export const product = {
  titles: {
    createProduct: 'Criar um novo produto',
    goToAll: 'Ir para todos os produtos',
    editProduct: 'Editar produto',
    addToArchive: 'Adicionar ao arquivo',
    variations: 'Variações',
    uploadPhotos: 'Upload fotos',
    dropTheFiles: 'Solte os arquivos aqui',
    dragFileHere: 'Arraste o arquivo aqui ou',
    chooseFromYourComputer: 'escolha no seu computador',
    createVariation: 'Criar uma variação de um produto existente',
    generalInformation: 'Informação geral',
    pricing: 'Preços',
    additionalCharges: 'Despesas adicionais',
    seo: 'SEO',
    specifications: 'Especificações',
    faq: 'Perguntas frequentes',
    reviews: 'Avaliações',
    frequentlyHiredTogether: 'Frequentemente alugados juntos',
    requiredExtra: 'Extra Necessário',
    optionalExtra: 'Adicional opcional',
    noRelatedProducts: 'Nenhum produto relacionado',
    noRequiredExtra: 'Não requer extras',
    noOptionalExtra: 'Sem extras opcionais',
    createRequiredExtra: 'Criar extra necessário',
    createOptionalExtra: 'Criar extra opcional',
    uploadUserGuides: 'Upload guias do usuário',
    fileDownloaded: 'Arquivo baixado',
    editAltTags: 'Editar tags alternativas',
    typicalUse: 'Uso Típico',
    eventTypes: 'Tipos de evento',
    thelastImage: 'A última imagem não pode ser excluída',
    editRequiredExtra: 'Editar extra necessário',
    chooseFrequently: 'Escolha Frequentemente Alugados Juntos',
    chooseProduct: 'Escolha o produto',
    warehouses: 'Disponibilidade de produtos em estoque',
    warehouseAvailability: 'Quantidade disponível',
    consumables: 'Consumíveis',
    noConsumables: 'Sem consumíveis',
    otherCharges: 'Outros gastos',
    chooseOptionalExtra: 'Escolha Extra Opcional',
    photo: 'Foto',
    extraCode: 'Código Extra',
    name: 'Nome',
    price: 'Preço',
    saleItem: 'Item de oferta',
    chooseRequiredExtra: 'Escolha o Extra Necessário',
    videos: 'Vídeos',
    safetyRecommendations: 'Recomendações de segurança',
    thirdParty: 'Integrações de terceiros',
    downloads: 'Downloads',
    productProfile: 'Perfil do produto',
    defaultDisplayedRange: 'Intervalo exibido padrão',
    platformProduct: 'Platform Product',
    linkProductWithPlatform: 'Link this product with a platform product',
  },
  notifications: {
    skuAlreadyBeenTaken: 'O sku já foi usado',
  },
  safetyRecommendations: {
    selectSafetyRecommendation: 'Selecione Recomendação de Segurança',
    listOfSafetyRecommendations: 'Lista de Recomendações de Segurança',
  },
  thirdPartyIntegrations: {
    systemName: 'Nome do sistema',
    integrationStatus: 'Status de Integração',
    externalProductId: 'ID do produto externo',
    thirdPartiesNote:
      'Observe que conectar pelo menos um produto externo para qualquer sistema ativo desativará as configurações na seção Disponibilidade de produtos em estoque.',
    successConnectModalTitle: 'Conectar produto externo por ID',
    response: 'Resposta',
    productTitle: 'Product Title',
    productDescription: 'Descrição do produto',
    okConnect: 'OK, conecte este produto',
    noIntegration: 'Sem integrações',
    idIsNotConnected: 'Id ainda não está conectado',
    idIsAlreadyConnected: 'ID já existe',
    fieldIsEmpty: 'O campo está vazio',
    locationCode: 'Código de localização',
    error: 'Erro',
  },
  selfCollectionOnly: 'Somente Auto-coleta',
  deliveryOnly: 'Somente entrega',
  selfCollectionAndDelivery: 'Entrega e coleta',
  changesNotSaved:
    'As alterações não foram salvas. Você realmente deseja sair desta página?',
  day: 'dia',
  draftMessage:
    'Este perfil de produto está no status Rascunho e não pode ser acessado no site público. Para disponibilizar este produto no site público, clique no botão Publicar. Esta ação é irreversível.',
  selectFranchisee: 'Selecione Franquia',
  productOwner: 'Proprietário do produto',
  owner: 'Proprietário',
};
