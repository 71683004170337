import React, { useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { areas$ } from 'models/areas';

import DeliveryAreaItem from 'components/atoms/DeliveryAreaItem';

import * as ST from './styles';

import { T8y } from 'ui';

const DeliveryArea = ({
  el,
  deliveryIndex,
  nestIndex,
  register,
  setValue,
  watch,
  values,
  FIELDS_NAMES_LIST,
  isPostcode,
}) => {
  const { form } = Lang();

  const areas = useStore(areas$);
  const [isDeliveryAreaFields, setIsDeliveryAreaFields] = useState([]);

  const areaName = useMemo(() => {
    if (isPostcode) {
      return el.postcodePrefix;
    }

    const area = areas.find(area => area.id === el.areaId);
    return area ? area.name : 'Unknown area name';
  }, [areas, el.areaId, el.postcodePrefix, isPostcode]);

  const deliveryMethodName = isPostcode
    ? 'deliveryPerPostcode'
    : 'deliveryPerArea';

  // const errorMsg = useMemo(() => {
  //   if (isDeliveryAreaFields.includes(true)) {
  //     return false;
  //   }
  //
  //   return form.notification.notAvailableArea;
  // }, [form.notification.notAvailableArea, isDeliveryAreaFields]);

  return (
    <ST.DeliveryAreaWrapper>
      <ST.DeliveryAreaName>{areaName}</ST.DeliveryAreaName>
      {FIELDS_NAMES_LIST.map((field, index) => (
        <DeliveryAreaItem
          deliveryIndex={deliveryIndex}
          areaIndex={nestIndex}
          fieldIndex={index}
          register={register}
          setValue={setValue}
          watch={watch}
          checkboxName={field.checkboxName}
          inputName={field.inputName}
          isChecked={
            values.deliveries[deliveryIndex][deliveryMethodName]?.[nestIndex]?.[
              field.checkboxName
            ]
          }
          defaultInputValue={
            values.deliveries[deliveryIndex][deliveryMethodName]?.[nestIndex]?.[
              field.inputName
            ]
          }
          key={index}
          setIsDeliveryAreaFields={setIsDeliveryAreaFields}
          deliveryMethodName={deliveryMethodName}
        />
      ))}
      {/*{errorMsg && (*/}
      {/*  <ST.ErrorWrapper>*/}
      {/*    <T8y as="span" variant="t3" color="danger" align="center">*/}
      {/*      {errorMsg}*/}
      {/*    </T8y>*/}
      {/*  </ST.ErrorWrapper>*/}
      {/*)}*/}
    </ST.DeliveryAreaWrapper>
  );
};

export default DeliveryArea;
