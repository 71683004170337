import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getSettings } from 'models/settings';

import { Layout } from './layout/Layout';
import { Products } from './pages/Products';
import { useRoutesList } from './routes';

import { Row, Spinner } from 'ui';

const Fallback = (
  <Row
    align="center"
    justify="center"
    style={{ width: '100vw', height: '100vh' }}
  >
    <Spinner dark size={5} />
  </Row>
);

export function AdminApp() {
  const routes = useRoutesList();

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <React.Suspense fallback={Fallback}>
      <Layout>
        <Switch>
          {routes.map((route, index) => {
            return <Route key={index} {...route} />;
          })}
          <Route component={Products} />
        </Switch>
      </Layout>
    </React.Suspense>
  );
}
