import React from 'react';
import { Lang } from 'lang';

import * as ST from './styles';

import { T8y } from 'ui';

const DraftMessage = () => {
  const { product } = Lang();

  return (
    <ST.MessageWrapper>
      <T8y fontSize="16px" bold>
        {product.draftMessage}
      </T8y>
    </ST.MessageWrapper>
  );
};

export default DraftMessage;
