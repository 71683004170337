import { DEFAULT_SELECTOR_STATE } from 'consts';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { allProductsWithoutFiltersLocalized$ } from 'pages/model';
import { createDebounce } from 'utils/helpers/timeouts';

import { JOB_TYPES } from 'api';

import { categoriesTreeStoreLocalized$ } from './categories';

const defaultFilters = {
  limit: 30,
  page: 1,
  selector: DEFAULT_SELECTOR_STATE,
  search: '',
  orderBy: 'id',
  sortedBy: 'desc',
};

export const loadJobTypesFx = createEffect({
  handler: async params => {
    const res = await JOB_TYPES.getJobTypes(params);

    const pagination = res.data.meta?.pagination;

    return {
      leads: res.data.data,
      pagination: {
        lastPage: pagination.total_pages,
        total: pagination.total,
      },
    };
  },
});

export const isJobTypesLoading$ = loadJobTypesFx.pending.map(state => !!state);

export const clearJobTypes = createEvent();
export const setJobTypesFilter = createEvent();
export const setDefaultJobTypesFilters = createEvent();
export const setJobTypesSearchStr = createEvent();
export const changeJobTypesTablePage = createEvent();
export const getJobTypes = createEvent();

export const setLeadsSearchStrDebounced = createDebounce(
  setJobTypesSearchStr,
  100,
);

export const jobTypes$ = createStore([])
  .on(loadJobTypesFx.doneData, (_, p) => p.leads || [])
  .on(clearJobTypes, () => []);

export const jobTypesExpanded$ = combine(
  jobTypes$,
  allProductsWithoutFiltersLocalized$,
  categoriesTreeStoreLocalized$,
  (jobTypes, allProducts, allCategories) => {
    if (!jobTypes.length) {
      return [];
    }

    return jobTypes.map(jobType => {
      let currProducts = jobType.products;
      let currCategories = jobType.categories;

      if (allProducts?.length) {
        currProducts = currProducts.map(el => {
          const initialEl = allProducts.find(product => product.id === el.id);

          return initialEl?.name || '-';
        });
      }

      if (allCategories?.length) {
        currCategories = currCategories.map(el => {
          const initialEl = allCategories.find(
            category => category.id === el.id,
          );

          return initialEl?.name || '-';
        });
      }

      return {
        ...jobType,
        products: currProducts,
        categories: currCategories,
      };
    });
  },
);

export const jobTypesPagination$ = createStore({}).on(
  loadJobTypesFx.doneData,
  (s, p) => {
    return p.pagination;
  },
);

export const jobTypesGate = createGate('offersGate');

export const jobTypesFilters$ = createStore(defaultFilters)
  .on(setDefaultJobTypesFilters, () => ({ ...defaultFilters }))
  .on(changeJobTypesTablePage, (s, p) => ({ ...s, page: p }))
  .on(setLeadsSearchStrDebounced, (s, p) => ({ ...s, search: p, page: 1 }))
  .on(setJobTypesFilter, (s, p) => ({ ...s, selector: p, page: 1 }));

forward({
  from: jobTypesFilters$.updates,
  to: loadJobTypesFx,
});

forward({
  from: getJobTypes,
  to: loadJobTypesFx,
});

guard({
  source: jobTypesGate.open,
  filter: jobTypes$,
  target: loadJobTypesFx,
});

export const getJobType = createEvent();
export const clearJobType = createEvent();

export const loadJobTypeFx = createEffect({
  handler: async id => {
    const { data } = await JOB_TYPES.getJobType(id);

    return data.data;
  },
});

export const createJobTypeFx = createEffect({
  handler: async params => {
    const { data } = await JOB_TYPES.createJobType(params);

    return data.data;
  },
});

export const updateJobTypeFx = createEffect({
  handler: async params => {
    const { data } = await JOB_TYPES.updateJobType(params.id, {
      ...params.data,
      id: undefined,
    });

    return data.data;
  },
});

forward({
  from: getJobType,
  to: loadJobTypeFx,
});

export const jobTypeLoading$ = restore(loadJobTypeFx.pending, false);

export const jobType$ = restore(loadJobTypeFx.doneData, null).on(
  clearJobType,
  (_, p) => null,
);

export const jobTypeExpanded$ = combine(
  jobType$,
  allProductsWithoutFiltersLocalized$,
  categoriesTreeStoreLocalized$,
  (jobType, allProducts, allCategories) => {
    if (!jobType) {
      return null;
    }

    let currProducts = jobType.products;
    let currCategories = jobType.categories;

    if (allProducts?.length) {
      currProducts = currProducts.map(el => {
        const initialEl = allProducts.find(product => product.id === el.id);
        return { ...el, productId: el.id, ...(initialEl && { ...initialEl }) };
      });
    }

    if (allCategories?.length) {
      currCategories = currCategories.map(el => {
        const initialEl = allCategories.find(category => category.id === el.id);
        return { ...el, ...(initialEl && { ...initialEl }) };
      });
    }

    return {
      ...jobType,
      prerenderStatusCode: jobType.prerenderStatusCode
        ? jobType.prerenderStatusCode.toString()
        : null,
      products: currProducts,
      categories: currCategories,
    };
  },
);
