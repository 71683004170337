import { DEFAULT_SELECTOR_STATE } from 'consts';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { createDebounce } from 'utils/helpers/timeouts';

import { TRADE_ACCOUNTS } from 'api';

const defaultFilters = {
  limit: 50,
  page: 1,
  selector: DEFAULT_SELECTOR_STATE,
  search: '',
  hideTestUsers: true,
};

export const clearTradeAccounts = createEvent();

export const customersGate = createGate('customersGate');
export const companiesGate = createGate('companiesGate');

//COMPANIES

export const loadTradeAccountsCompaniesFx = createEffect({
  handler: async params => {
    const res = await TRADE_ACCOUNTS.getAllCompanies(params);

    const { data, meta } = res.data;

    return { users: data, meta };
  },
});

export const setTACompaniesFilter = createEvent();
export const setDefaultTACompaniesFilters = createEvent();
export const setTACompaniesSearchStr = createEvent();
export const changeTACompaniesTablePage = createEvent();
export const changeSelectedTACompaniesLimit = createEvent();
export const changeHideTestTACompanies = createEvent();
export const setTACompaniesSearchStrDebounced = createDebounce(
  setTACompaniesSearchStr,
  100,
);

export const tradeAccountsCompanies$ = createStore([])
  .on(loadTradeAccountsCompaniesFx.doneData, (s, p) => {
    return p.users;
  })
  .on(clearTradeAccounts, () => []);

export const tradeAccountsCompaniesMeta$ = createStore({}).on(
  loadTradeAccountsCompaniesFx.doneData,
  (s, p) => {
    return p.meta?.pagination;
  },
);

export const tradeAccountsCompaniesLoading$ = restore(
  loadTradeAccountsCompaniesFx.pending,
  false,
);

export const TACompaniesFilters$ = createStore(defaultFilters)
  .on(setDefaultTACompaniesFilters, () => ({ ...defaultFilters }))
  .on(changeSelectedTACompaniesLimit, (s, p) => ({ ...s, limit: p, page: 1 }))
  .on(changeTACompaniesTablePage, (s, p) => ({ ...s, page: p }))
  .on(setTACompaniesSearchStrDebounced, (s, p) => ({
    ...s,
    search: p,
    page: 1,
  }))
  .on(setTACompaniesFilter, (s, p) => ({ ...s, selector: p, page: 1 }))
  .on(changeHideTestTACompanies, (s, p) => ({
    ...s,
    hideTestUsers: p,
    page: 1,
  }));

guard({
  source: combine(
    companiesGate.state,
    companiesGate.status,
    (state, status) => ({
      ...state,
      ...(!status && { closed: true }),
    }),
  ),
  filter: state => !state.closed,
  target: loadTradeAccountsCompaniesFx,
});

//CUSTOMERS

export const loadTradeAccountsCustomersFx = createEffect({
  handler: async params => {
    const res = await TRADE_ACCOUNTS.getAllCustomers(params);
    const { data, meta } = res.data;

    return { users: data, meta };
  },
});

export const setTACustomersFilter = createEvent();
export const setDefaultTACustomersFilters = createEvent();
export const setTACustomersSearchStr = createEvent();
export const changeTACustomersTablePage = createEvent();
export const changeSelectedTACustomersLimit = createEvent();
export const changeHideTestTACustomers = createEvent();
export const setTACustomersSearchStrDebounced = createDebounce(
  setTACustomersSearchStr,
  100,
);

export const tradeAccountsCustomers$ = createStore([])
  .on(loadTradeAccountsCustomersFx.doneData, (s, p) => {
    return p.users;
  })
  .on(clearTradeAccounts, () => []);

export const tradeAccountsCustomersMeta$ = createStore({}).on(
  loadTradeAccountsCustomersFx.doneData,
  (s, p) => {
    return p.meta?.pagination;
  },
);

export const tradeAccountsCustomersLoading$ = restore(
  loadTradeAccountsCustomersFx.pending,
  false,
);

export const TACustomersFilters$ = createStore(defaultFilters)
  .on(setDefaultTACustomersFilters, () => ({ ...defaultFilters }))
  .on(changeSelectedTACustomersLimit, (s, p) => ({ ...s, limit: p, page: 1 }))
  .on(changeTACustomersTablePage, (s, p) => ({ ...s, page: p }))
  .on(setTACustomersSearchStrDebounced, (s, p) => ({
    ...s,
    search: p,
    page: 1,
  }))
  .on(setTACustomersFilter, (s, p) => ({ ...s, selector: p, page: 1 }))
  .on(changeHideTestTACustomers, (s, p) => ({
    ...s,
    hideTestUsers: p,
    page: 1,
  }));

guard({
  source: combine(
    customersGate.state,
    customersGate.status,
    (state, status) => ({
      ...state,
      ...(!status && { closed: true }),
    }),
  ),
  filter: state => !state.closed,
  target: loadTradeAccountsCustomersFx,
});

//TRADE_ACCOUNT

export const loadTradeAccountFx = createEffect({
  handler: async ({ type, id }) => {
    let formattedUser = null;

    if (type === 'private') {
      const { data } = await TRADE_ACCOUNTS.getCustomer(id);

      if (data.data) {
        formattedUser = {
          ...data.data,
          vat_number: data.data.company_details?.vat_number,
          business_name: data.data.company_details?.business_name,
          contact_phone: data.data.company_details?.contact_phone,
          contact_email: data.data.company_details?.contact_email,
          phone: data.data.phone || data.data.address?.phone,
          country: data.data.address?.country,
          city: data.data.address?.city,
          province: data.data.address?.province,
          postcode: data.data.address?.postcode,
          address: data.data.address?.address,
          orders: data.data.orders,
          deliveryAddresses: data.data.deliveryAddresses?.data,
          type,
        };
      }
      return formattedUser;
    }

    const { data } = await TRADE_ACCOUNTS.getCompany(id);

    if (data.data) {
      formattedUser = {
        ...data.data,
        vat_number: data.data.company_details?.vat_number,
        business_name: data.data.company_details?.business_name,
        contact_phone: data.data.company_details?.contact_phone,
        contact_email: data.data.company_details?.contact_email,
        phone: data.data.phone || data.data.address?.phone,
        country: data.data.address?.country,
        city: data.data.address?.city,
        province: data.data.address?.province,
        postcode: data.data.address?.postcode,
        address: data.data.address?.address,
        orders: data.data.orders,
        deliveryAddresses: data.data.deliveryAddresses?.data,
        type,
      };
    }
    return formattedUser;
  },
});

export const getTradeAccount = createEvent();
export const clearTradeAccount = createEvent();
export const setActualDocsToOrderTradeAccount = createEvent();

export const tradeAccount$ = restore(loadTradeAccountFx, null)
  .on(clearTradeAccount, (s, p) => {
    return null;
  })
  .on(setActualDocsToOrderTradeAccount, (s, { id, newDocs, type }) => {
    let newOrders = null;

    if (s.orders && newDocs) {
      newOrders = s.orders.map(el => {
        if (el.orderId === id) {
          return { ...el, [type]: newDocs };
        }

        return el;
      });
    }

    return {
      ...s,
      orders: newOrders,
    };
  });

forward({
  from: getTradeAccount,
  to: loadTradeAccountFx,
});

export const isTradeAccountLoading$ = restore(
  loadTradeAccountFx.pending,
  false,
);
