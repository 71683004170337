import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useKeyUpEsc, useOnClickOutside } from 'hooks';

import { Row, T8y } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/icon/cross.svg';

import style from './modal.module.scss';

const Modal = ({ setIsOpen, header, children }) => {
  const modalRef = useRef(null);

  useKeyUpEsc(() => setIsOpen(false));

  useOnClickOutside(modalRef, () => setIsOpen(false));

  return ReactDOM.createPortal(
    <div className={style.modalOverlay}>
      <div className={style.modal} ref={modalRef}>
        {header && (
          <Row justify="stretch" align="center" className={style.modalHeader}>
            <T8y fontSize="18px" bold>
              {header}
            </T8y>
            <button
              type="button"
              className={style.closeButton}
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </button>
          </Row>
        )}
        <div className={style.modalContent}>{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
