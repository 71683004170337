import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';

import styled from 'styled-components';

import { doNothing, IconStyled, submitForm } from '../Product/shared';
import { SaveAndViewButton, SubmitButton } from './Buttons';

import { colors } from 'styleGuide';
import { Cell, Grid } from 'ui';

import { ReactComponent as Eye } from 'assets/images/icon/eye.svg';

const LinkBack = styled(Link)`
  display: block;

  &:active,
  &:visited,
  &:link {
    font-size: 1.2rem;
    font-weight: normal;
    color: ${colors.primary};
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
  }
`;

const RowInfoStyled = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid ${colors.lightGray};
  margin-bottom: 4.5rem;
`;

const RowInfoTitle = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${colors.darkBlue};
  margin-top: 1rem;
  display: block;
`;

export const RowInfo = ({
  loading,
  title,
  submitButtonTitle,
  loadingTitle,
}) => {
  const {
    form: { buttons, links },
  } = Lang();

  return (
    <Fragment>
      <LinkBack to={PATHS.MANAGE}>{links.goToAllCategories}</LinkBack>
      <Grid as={RowInfoStyled} gaps="0" cols="1fr 26rem 23rem" places="center">
        <Cell place="center start" as={RowInfoTitle}>
          {loading ? loadingTitle : title}
        </Cell>

        <Cell
          place="center end"
          as={SaveAndViewButton}
          type="button"
          disabled={loading}
          onClick={e => (loading ? doNothing(e) : submitForm())}
        >
          <IconStyled as={Eye} />
          {buttons.saveAndView}
        </Cell>

        <Cell
          place="center end"
          as={SubmitButton}
          type="submit"
          disabled={loading}
          onClick={e => (loading ? doNothing(e) : submitForm())}
        >
          {submitButtonTitle}
        </Cell>
      </Grid>
    </Fragment>
  );
};
