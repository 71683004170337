import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';

import Error from 'components/atoms/Error';
import {
  BlockRounded,
  FieldStyle,
  FieldStyle2,
  IconStyled,
  InputStyled,
  MarginTop,
  TextareaStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { Cell, Grid, Row, T8y } from 'ui';
import { Rating } from 'ui/Rating';

import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as Delete } from 'assets/images/icon/delete.svg';

export const ProductReviewForm = ({
  review,
  trigger,
  register,
  setValue,
  values,
  errors,
  watch,
  index,
  remove,
}) => {
  const {
    form: { fields, notification },
    trainings,
    formatString,
  } = Lang();

  const currentLangState = useStore(currentLang$);

  const actualFieldsInfo = useMemo(() => {
    return values?.reviews[index];
  }, [index, values]);

  useEffect(() => {
    register(`reviews[${index}].added_on`, {
      required: notification.theFieldIsRequired,
    });
    setValue(`reviews[${index}].added_on`, actualFieldsInfo?.added_on || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, currentLangState]);

  const getIsError = useCallback(
    field => {
      return errors?.reviews?.[index]?.[field]?.message;
    },
    [index, errors],
  );

  const handleRemove = useCallback(
    e => {
      e.preventDefault();
      remove(index);
    },
    [remove, index],
  );

  const titleRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    `reviews[${index}].title`,
    64,
  );

  const reviewRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    `reviews[${index}].message`,
    240,
  );

  return (
    <Cell key={index} as={BlockRounded}>
      <FieldStyle as={FieldStyle2} legend={fields.name}>
        <InputStyled
          type="text"
          name={`reviews[${index}].name`}
          ref={register({
            validate: value => {
              return !!value.trim() || notification.theFieldIsRequired;
            },
          })}
          defaultValue={review.name}
        />
      </FieldStyle>
      <Error message={getIsError('name')} />

      <MarginTop as={Grid} cols="1fr 1fr" gaps="0 2rem">
        <FieldStyle as={FieldStyle2} legend={fields.location}>
          <InputStyled
            type="text"
            paddingLeft="4rem"
            name={`reviews[${index}].city`}
            ref={register({
              validate: value => {
                return !!value.trim() || notification.theFieldIsRequired;
              },
            })}
            defaultValue={review.city}
          />
          <ST.LocationIcon as={Location} />
        </FieldStyle>

        <Cell place="center">
          <FieldStyle as={FieldStyle2} legend={fields.rating} noBorder>
            <Rating
              stars={actualFieldsInfo?.rating}
              onChange={value => setValue(`reviews[${index}].rating`, value)}
            />
          </FieldStyle>
        </Cell>
      </MarginTop>
      <Error message={getIsError('city')} />

      <MarginTop as={FieldStyle2} legend={fields.reviewTitle}>
        <InputStyled
          type="text"
          defaultValue={review.title}
          name={`reviews[${index}].title`}
          ref={register({
            validate: value => {
              return !!value.trim() || notification.theFieldIsRequired;
            },
          })}
          maxLength={64}
        />
      </MarginTop>
      <Error message={getIsError('title')} />
      <T8y color="gray" fontSize="12px">
        {formatString(trainings.remainingCharacters, titleRemainingSymbols)}
      </T8y>
      <MarginTop as={FieldStyle2} legend={fields.review}>
        <TextareaStyled
          height="12rem"
          name={`reviews[${index}].message`}
          ref={register({
            validate: value => {
              return !!value.trim() || notification.theFieldIsRequired;
            },
          })}
          maxLength={240}
          defaultValue={review.message}
        />
      </MarginTop>
      <Error message={getIsError('message')} />
      <T8y color="gray" fontSize="12px">
        {formatString(trainings.remainingCharacters, reviewRemainingSymbols)}
      </T8y>
      <Row align="end" justify="stretch">
        <ST.DateFieldWrapper>
          <MarginTop as={FieldStyle2} legend={fields.date}>
            <ST.DatePickerStyled
              defaultValue={actualFieldsInfo?.added_on}
              handleDateChange={date => {
                setValue(`reviews[${index}].added_on`, date);
                trigger(`reviews[${index}].added_on`);
              }}
              maxDate={new Date()}
              minDate={new Date('01/01/2019')}
            />
          </MarginTop>
          <Error message={getIsError('added_on')} />
        </ST.DateFieldWrapper>

        <ST.DeleteButton href="#" onClick={e => handleRemove(e)}>
          <IconStyled as={Delete} />
          {fields.delete}
        </ST.DeleteButton>
      </Row>
    </Cell>
  );
};
