import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';
import { handleUploadEnd } from 'utils/filesDownload';

import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';
import ShowSection from 'components/atoms/ShowSection';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import TrainingHeroImageWrapper from 'components/atoms/TrainingHeroImageWrapper';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import UploadTrainingPicture from '../UploadTrainingPicture';
import * as ST from './styles';

const TrainingAboutCertificate = ({
  errors,
  getIsError,
  setValue,
  trigger,
  values,
  watch,
  setDeletedFiles,
  control,
  clearErrors,
  isNew,
}) => {
  const { trainings, form } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const watchSectionShow = watch('form.certificateShow');
  const watchImageId = watch('form.certificateImage.id');

  const handleUploaded = useCallback(
    file => {
      setValue('form.certificateImage.src', file.src);
      clearErrors('form.certificateImage.src');
    },
    [clearErrors, setValue],
  );

  const handleDeleteImage = useCallback(() => {
    if (watchImageId) {
      setDeletedFiles(files => [...files, watchImageId]);
    }
    setValue('form.certificateImage.src', null);
    setValue(`form.certificateImage.id`, null);
    trigger('form.certificateImage.src');
  }, [setDeletedFiles, setValue, trigger, watchImageId]);

  const handleUpload = useCallback(
    async acceptedFiles => {
      await handleUploadEnd({
        acceptedFiles,
        handleUploaded,
        maxAmountOfFiles: 1,
      });
    },
    [handleUploaded],
  );

  const handleCertificateShow = useCallback(
    value => {
      setValue('form.certificateShow', value);

      if (!value) {
        clearErrors('form.certificateText');
        clearErrors('form.certificateImage.src');
      } else {
        trigger('form.certificateText');
        trigger('form.certificateImage.src');
      }
    },
    [clearErrors, setValue, trigger],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.aboutCertificate}
      listOfFields={['certificateText', 'certificateImage']}
      errors={errors}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={isNew || trainingLocalized?.certificateShow}
          handleChange={handleCertificateShow}
        />
      }
    >
      <Controller
        as={<ST.HiddenInput />}
        name="form.certificateImage.src"
        control={control}
        rules={
          watchSectionShow && {
            required: form.notification.theFieldIsRequired,
          }
        }
        defaultValue={trainingLocalized?.certificateImage?.src || null}
      />
      <TrainingFormLine>
        <ST.AboutImageTitle color="gray">
          {trainings.certificateImage}
        </ST.AboutImageTitle>
        <ST.ThumbImageWrapper>
          {values.form?.certificateImage?.src ? (
            <TrainingHeroImageWrapper
              src={values.form.certificateImage.src}
              handleDeleteMainImage={handleDeleteImage}
              isDisplayThumbnail
            />
          ) : (
            <>
              <UploadTrainingPicture handleUploadEnd={handleUpload} />
              <Error message={errors?.form?.certificateImage?.src?.message} />
            </>
          )}
        </ST.ThumbImageWrapper>
      </TrainingFormLine>
      <TrainingFormLine>
        <Controller
          as={<ReachEditor />}
          name="form.certificateText"
          control={control}
          defaultValue={trainingLocalized?.certificateText || null}
          initialValue={trainingLocalized?.certificateText || null}
          rules={
            watchSectionShow && {
              required: form.notification.theFieldIsRequired,
            }
          }
        />
        <Error message={getIsError('certificateText')} />
      </TrainingFormLine>
    </GeneralExpandableSection>
  );
};

export default TrainingAboutCertificate;
