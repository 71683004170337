import styled from 'styled-components';

export const FeatureDeleteIconWrapper = styled.div`
  position: relative;
  top: -10px;
  margin-left: 25px;
`;

export const AnswerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
