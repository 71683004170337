import { createEffect, createEvent, forward, restore } from 'effector';

import { TRANSPORTATION } from 'api';

export const getAllAreasFx = createEffect({
  handler: async () => {
    const { data } = await TRANSPORTATION.getAreas();

    return data.data;
  },
});

export const getAreas = createEvent();

export const areas$ = restore(getAllAreasFx.doneData, []);

forward({
  from: getAreas,
  to: getAllAreasFx,
});
