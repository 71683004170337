import React, { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import LocationPriceOnlyItem from 'components/atoms/LocationPriceOnlyItem';

const TrainingAvailableLocationPricesOnly = ({
  scheduleIndex,
  register,
  control,
  errors,
  values,
}) => {
  const { trainings } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `form.schedules[${scheduleIndex}].variationsPricesOnly`,
    keyName: 'formId',
  });

  const handleAddPrice = useCallback(() => {
    append({
      title: '',
      price: '',
    });
  }, [append]);

  const actualFieldInfo = useMemo(() => {
    return values?.form?.schedules[scheduleIndex];
  }, [scheduleIndex, values]);

  return (
    <>
      {fields.map((el, index) => (
        <LocationPriceOnlyItem
          key={el.formId}
          scheduleIndex={scheduleIndex}
          register={register}
          remove={remove}
          errors={errors}
          priceOnlyIndex={index}
          actualFieldInfo={actualFieldInfo}
        />
      ))}
      <AddMore text={trainings.addNewVariation} onClick={handleAddPrice} />
    </>
  );
};

export default TrainingAvailableLocationPricesOnly;
