import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { tiers$, tiersGate } from 'models/tiers';
import { getUser } from 'models/users';

import { USERS } from 'api';

import { FieldStyle } from '../../Product/shared';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { Checkbox, inputStyle, Select, T8y } from 'ui';

const UserSpecialDiscount = ({ userId, userTier, isDisabled }) => {
  const [isTier, setIsTier] = useState(!!userTier);
  const [isLoading, setIsLoading] = useState(false);
  useGate(tiersGate);
  const tiers = useStore(tiers$);

  const {
    users: usersLocalization,
    form: { fields, toastTitle },
  } = Lang();

  const currentTier = useMemo(() => userTier?.data || tiers[0], [
    userTier,
    tiers,
  ]);

  const handleChange = useCallback(
    (option, isToggleEnabled) => {
      setIsLoading(true);
      let tierId = null;
      if (option) {
        tierId = isToggleEnabled
          ? tiers[0].id
          : tiers.find(el => el.slug === option)?.id;
      }

      USERS.updateUser(userId, { tierId })
        .then(
          () => {
            getUser(userId);
            toast(
              `${usersLocalization.discount.title}  ${toastTitle.wasUpdated}`,
            );
          },
          () => {
            toast.error(toastTitle.updateError);
            if (isToggleEnabled) {
              setIsTier(!!userTier);
            }
          },
        )
        .finally(() => setIsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tiers, userId, toastTitle, fields],
  );

  const handleToggleDiscount = useCallback(
    value => {
      setIsTier(value);
      const newDiscount = value ? currentTier?.name : null;
      handleChange(newDiscount, true);
    },
    [currentTier, handleChange],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={usersLocalization.discount.title}>
      <Checkbox
        label={usersLocalization.discount.option}
        onChange={e => !isLoading && handleToggleDiscount(e.target.checked)}
        checked={isTier}
        disabled={isLoading || isDisabled}
      />
      <ST.InputsBlockWrapper align="end">
        <FieldStyle
          as={ST.InputsWrapperFields}
          legend={usersLocalization.discount.discount}
          withBorder
        >
          <Select
            options={tiers}
            selected={currentTier?.name}
            onClickOption={e => handleChange(e)}
            className={inputStyle}
            disabled={!isTier || isLoading || isDisabled}
            isSelectDisable={!isTier || isLoading}
          />
        </FieldStyle>
        <FieldStyle
          disabled={!isTier || isLoading}
          as={ST.InputsWrapperFields}
          withBorder
        >
          <input disabled name="discount" value={currentTier?.discount + '%'} />
        </FieldStyle>
      </ST.InputsBlockWrapper>
      <T8y fontSize="1.6rem">{usersLocalization.discount.description} </T8y>
    </ProductEditorBlockWrapper>
  );
};

export default UserSpecialDiscount;
