import { DEFAULT_SELECTOR_STATE } from 'consts';
import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { createDebounce } from 'utils/helpers/timeouts';

import { USERS } from 'api';

export const loadUsersFx = createEffect({
  handler: async params => {
    const res = await USERS.getAllUsers({ ...params });
    const { data, meta } = res.data;
    return { users: data, meta };
  },
});

export const getUsers = createEvent();
export const clearUsers = createEvent();
export const setUsersFilter = createEvent();
export const setDefaultUsersFilters = createEvent();
export const setUsersSearchStr = createEvent();
export const changeUsersTablePage = createEvent();
export const changeSelectedUsersLimit = createEvent();
export const changeHideTestUsers = createEvent();

export const setUsersSearchStrDebounced = createDebounce(
  setUsersSearchStr,
  100,
);

export const users$ = createStore([])
  .on(loadUsersFx.doneData, (s, p) => {
    return p.users;
  })
  .on(clearUsers, () => []);

export const usersMeta$ = createStore({}).on(loadUsersFx.doneData, (s, p) => {
  return p.meta?.pagination;
});

export const usersLoading$ = restore(loadUsersFx.pending, false);

const defaultFilters = {
  limit: 50,
  page: 1,
  selector: DEFAULT_SELECTOR_STATE,
  search: '',
  hideTestUsers: true,
};

export const filters$ = createStore(defaultFilters)
  .on(setDefaultUsersFilters, () => ({ ...defaultFilters }))
  .on(changeSelectedUsersLimit, (s, p) => ({ ...s, limit: p, page: 1 }))
  .on(changeUsersTablePage, (s, p) => ({ ...s, page: p }))
  .on(setUsersSearchStrDebounced, (s, p) => ({ ...s, search: p, page: 1 }))
  .on(setUsersFilter, (s, p) => ({ ...s, selector: p, page: 1 }))
  .on(changeHideTestUsers, (s, p) => ({ ...s, hideTestUsers: p, page: 1 }));

forward({
  from: getUsers,
  to: loadUsersFx,
});

forward({
  from: filters$.updates,
  to: loadUsersFx,
});

export const loadUserFx = createEffect({
  handler: async id => {
    const { data } = await USERS.getUser(id);

    let formattedUser = null;
    if (data.data) {
      formattedUser = {
        ...data.data,
        vat_number: data.data.company_details?.vat_number,
        business_name: data.data.company_details?.business_name,
        contact_phone: data.data.company_details?.contact_phone,
        contact_email: data.data.company_details?.contact_email,
        phone: data.data.phone || data.data.address?.phone,
        country: data.data.address?.country,
        city: data.data.address?.city,
        province: data.data.address?.province,
        postcode: data.data.address?.postcode,
        address: data.data.address?.address,
        type: data.data.type === 'individual' ? 'private' : data.data.type,
        orders: data.data.orders,
        deliveryAddresses: data.data.deliveryAddresses?.data,
      };
    }

    return formattedUser;
  },
});

export const getUser = createEvent();
export const clearUser = createEvent();
export const setActualDocsToOrderUser = createEvent();

export const user$ = restore(loadUserFx, null)
  .on(clearUser, (s, p) => {
    return null;
  })
  .on(setActualDocsToOrderUser, (s, { id, newDocs, type }) => {
    let newOrders = null;

    if (s.orders && newDocs) {
      newOrders = s.orders.map(el => {
        if (el.orderId === id) {
          return { ...el, [type]: newDocs };
        }

        return el;
      });
    }

    return {
      ...s,
      orders: newOrders,
    };
  });

forward({
  from: getUser,
  to: loadUserFx,
});

export const isUserLoading$ = restore(loadUserFx.pending, false);
