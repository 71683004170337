import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row } from 'ui';

export const UploadUserGuidesTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

export const UploadUserGuidesBody = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  padding: 3rem;
  margin-bottom: 5rem;
`;

export const FileWrapper = styled(Row)`
  position: relative;
  border: 1px solid ${colors.lightGray};
  width: calc(33.2% - 13px);
  height: 100%;
  padding 0 5px;
  color: ${colors.primary};
  height: 21.5rem;
  &:not(:last-child) {
    margin-right:20px;
  }

  p {
    text-align: center;
    max-width: calc(100% - 20px);
    max-height:100%;
    margin:0 auto;
    padding 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UploadUserGuidesBlock = styled.div`
  padding: 7rem 0 0;
  height: 21.5rem;
  text-align: center;
  font-size: 1.2rem;
  color: #787878;
  cursor: pointer;

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.3rem;
  }
`;
