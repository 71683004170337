import styled from 'styled-components';

import { colors } from 'styleGuide';

export const EmailField = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
  svg:hover path {
    fill: ${colors.primary};
  }
`;

export const TooltipTextWrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: #edf7e6;
  border: 1px solid #c2e3a8;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
`;
