import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import {
  filters$,
  isPromoCodesLoading$,
  promoCodesFiltered$,
  promoCodesGate,
  setPromoCodesFilter,
  setPromoCodesSearchStrDebounced,
} from 'models/promoCodes';

import CustomButton from 'components/atoms/CustomButton';
import NewTable from 'components/molecules/NewTable';
import PromoCodeForm from 'components/molecules/PromoCodeForm';

import Modal from './Modal';
import * as ST from './styles';

import { Row, Spinner, T8y } from 'ui';

const keyFields = [
  { name: 'code', type: 'link' },
  { name: 'value', type: 'text' },
  { name: 'status', type: 'text' },
  { name: 'type', type: 'text' },
  { name: 'startDate', type: 'text' },
  { name: 'endDate', type: 'text' },
  { name: 'items', type: 'text' },
];

const PromoCodes = () => {
  const { formatString, promoCodes } = Lang();

  const branding = useStore(branding$);
  const promoCodesFilters = useStore(filters$);
  const promoCodesFiltered = useStore(promoCodesFiltered$);

  const isPromoCodesLoading = useStore(isPromoCodesLoading$);

  const [selectedPromo, setSelectedPromo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useGate(promoCodesGate);

  const filterOptions = useMemo(() => {
    return Object.keys(promoCodes.filterSelect).map(el => ({
      name: promoCodes.filterSelect[el],
      slug: el,
    }));
  }, [promoCodes.filterSelect]);

  const selected = useMemo(() => {
    return (
      filterOptions.find(el => el.slug === promoCodesFilters.selector)?.name ||
      ''
    );
  }, [filterOptions, promoCodesFilters.selector]);

  const headers = useMemo(() => Object.values(promoCodes.tableHeaders), [
    promoCodes.tableHeaders,
  ]);

  const modifiedDisplayedPromoCodes = useMemo(
    () =>
      promoCodesFiltered.map(el => ({
        code: (
          <ST.PromoCodeName
            color="primary"
            fontSize="16px"
            onClick={() => {
              setSelectedPromo(el);
              setIsModalOpen(true);
            }}
            title={el.code}
            cursor="pointer"
          >
            {el.code}
          </ST.PromoCodeName>
        ),
        value: el.value,
        status: promoCodes.statuses[el.status?.toLowerCase()] || el.status,
        type: promoCodes.types[el.isOnetime ? 'onTime' : 'multiple'],
        startDate: el.startDate,
        endDate: el.endDate,
        items:
          el.type !== DEFAULT_SELECTOR_STATE ? (
            <div title="">
              {el.type === 'category' ? `${promoCodes.cat}: ` : 'SKU: '}
              {el.items.map((item, index) => (
                <T8y
                  key={index}
                  as="a"
                  href={`${branding.webSiteUrl}/${item.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  fontSize="16px"
                >
                  {el.type === 'category' ? item.name : item.sku}
                  {index !== el.items.length - 1 && ', '}
                </T8y>
              ))}
            </div>
          ) : (
            <>
              <span title="">{promoCodes.filterSelect.all}: </span>{' '}
              <T8y
                as="a"
                href={branding.webSiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                fontSize="16px"
                title=""
              >
                {promoCodes.allCatalogue}
              </T8y>
            </>
          ),
      })),
    [branding.webSiteUrl, promoCodes, promoCodesFiltered],
  );

  const cols = useMemo(
    () => (
      <colgroup>
        <col span="1" width="16%" />
        <col span="1" width="10%" />
        <col span="1" width="8%" />
        <col span="1" />
        <col span="1" />
        <col span="1" />
        <col span="1" width="30%" />
      </colgroup>
    ),
    [],
  );

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedPromo(null);
    }
  }, [isModalOpen]);

  return (
    <>
      <Row justify="stretch" noWrap>
        <ST.PromoCodesFiltersPanel
          selectOptions={filterOptions}
          selected={selected}
          onClickOption={setPromoCodesFilter}
          onChangeSearch={setPromoCodesSearchStrDebounced}
          searchStr={promoCodesFilters.search}
          searchPlaceholder={promoCodes.searchPlaceholder}
          foundStr={formatString(
            promoCodes.promoCodesFound,
            promoCodesFiltered.length || 0,
          )}
        />
        <CustomButton
          text={promoCodes.addNewPromoCode}
          width="auto"
          padding="0 10px"
          onClick={() => setIsModalOpen(true)}
        />
      </Row>
      <ST.TableWrapper>
        {isPromoCodesLoading && (
          <ST.SpinnerWrapper>
            <Spinner dark size={4} />
          </ST.SpinnerWrapper>
        )}
        <NewTable
          headers={headers}
          keyFields={keyFields}
          items={modifiedDisplayedPromoCodes}
          cols={cols}
          isFixedLayout
        />
      </ST.TableWrapper>
      {isModalOpen && (
        <Modal
          header={
            selectedPromo
              ? promoCodes.editPromoCode
              : promoCodes.addNewPromoCode
          }
          setIsOpen={setIsModalOpen}
        >
          <PromoCodeForm
            promoCode={selectedPromo || undefined}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>
      )}
    </>
  );
};

export default PromoCodes;
