export const common = {
  dropZoneErrors: {
    'file-too-large': 'Image size should be less than {0} MB',
  },
  enabledRobotsTag: 'Enable editing of Robots Tag field',

  forgotPassword: {
    header: 'Forgot your password?',
    customerHeader: 'Admin Panel Forgot Password',
    subHeader:
      'Enter email address you used for signing up and we’ll send you a password reset link',
    emailFieldTitle: 'Email Address',
    emailFieldPlaceholder: 'Enter your email address',
    goToLogin: 'Go to Log In page, I’ve remembered my password',
    submit: 'Submit',
    sent: 'The message was sent. Please, check your email.',
  },

  resetPassword: {
    header: 'Password Reset',
    customerHeader: 'Admin Panel Password Reset',
    subHeader: 'Please enter your new password below',
    passwordFieldTitle: 'New Password',
    passwordFieldPlaceholder: 'Enter your password',
    repeatPasswordFieldTitle: 'Repeat New Password',
    repeatPasswordFieldPlaceholder: 'Re-enter your password',
  },

  historyTable: {
    title: 'History',
    revisionNo: 'Revision No',
    modifiedAt: 'Modified At',
    modifiedBy: 'Modified By',
    viewChanges: 'View changes',
    changes: 'Changes',
    previousVersion: 'Previous version',
    currentVersion: 'Current version',
    expandLines: 'Expand {0} lines',
  },

  draft: 'Draft',
  new: 'New',

  calendar: {
    weekDays: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    general: 'General',
    timezone: 'Timezone',
    defaultHirePeriod: 'Default Hire Period (days)',
    sameDayDelivery: 'Same day delivery',
    disableSameDayDelivery: 'Disable same day delivery',
    holidays: 'Holidays',
    month: 'Month',
    comment: 'Comment (optional)',
    selectDay: 'Select day',
    selectMonth: 'Select month',
    addNewHoliday: 'Add New Holiday',
    weekends: 'Weekends',
    deliverySelfCollection: 'For Delivery and Self-Collection',
    tickDays:
      'Tick days that will be marked in the calendar as not available for start or end hire dates',
    nextDayBehaviour: 'Next Day Behaviour',
    dayOfWeek: 'Day of Week',
    offset: 'Offset (days)',
    enterNumber: 'Enter number',
    addNewBehaviour: 'Add New Behaviour',
    sameDayHire: 'Same day hire',
    allowSetToday: 'Allow to set “today“ as a hire start date',
    till: 'till',
  },
};
