import { toast } from 'react-toastify';

import { FILES } from '../api';

import { deleteUploadedPhotoFx, uploadPhotoFx } from '../models/product';

export const handleUploadEnd = async ({
  acceptedFiles,
  handleUploaded,
  maxAmountOfFiles,
}) => {
  if (acceptedFiles.length > maxAmountOfFiles) {
    toast(`Only ${maxAmountOfFiles} files can be added`);
  }

  if (maxAmountOfFiles === 1) {
    await new Promise(resolve => {
      const data = new FormData();
      data.append('file', acceptedFiles[0]);
      FILES.upload(data).then(value => {
        if (value && value.data) {
          resolve(value.data);
        }
      });
    }).then(value => {
      if (value.url) {
        handleUploaded({ src: value.url });
      }
    });
  } else {
    await Promise.all(
      acceptedFiles.map(file => {
        return new Promise(resolve => {
          const data = new FormData();
          data.append('file', file);
          FILES.upload(data).then(dataFromServer => {
            if (dataFromServer && dataFromServer.data) {
              resolve(dataFromServer.data);
            }
          });
        });
      }),
    ).then(values => {
      const srcs = values.map(el => ({ src: el.url }));
      handleUploaded(srcs);
    });
  }
};

export const attachFile = async ({ object_id, src, type }) => {
  await uploadPhotoFx({
    src: src,
    object_id: object_id,
    type: type,
  });
};

export const deleteAttached = async id => {
  await deleteUploadedPhotoFx(id);
};
