import React from 'react';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { isProdEnv } from 'consts';
// import { useStore } from 'effector-react';
import { Lang } from 'lang';

// import { userInfo } from 'models/user';
import { clearCategories } from 'features/products/productsModel';

import { logout } from 'api';

import {
  LogoutStyled,
  NavbarLinkWrapper,
  NavbarStyled,
  NavLinkCustomStyled,
} from './styles';

import { Icon, T8y } from 'ui';

import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as Humburger } from 'assets/icons/humburger.svg';
import { ReactComponent as LeadsIcon } from 'assets/icons/leadsIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as ManageIcon } from 'assets/icons/manage.svg';
import { ReactComponent as MarketingIcon } from 'assets/icons/marketing.svg';
// import { ReactComponent as OffersIcon } from 'assets/icons/offers.svg';
import { ReactComponent as ProductsIcon } from 'assets/icons/products.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as TrainingsIcon } from 'assets/icons/trainings.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';

export function Navbar() {
  const {
    header: { menu },
  } = Lang();

  // const user = useStore(userInfo);

  const handleLogOut = () => {
    clearCategories();
    logout();
  };

  return (
    <NavbarStyled>
      <NavLinkCustomStyled>
        <Icon as={Humburger} $light $noHover />
      </NavLinkCustomStyled>

      <NavbarLinkWrapper>
        <NavLinkCustom to={PATHS.MANAGE} icon={ManageIcon}>
          {menu.categories}
        </NavLinkCustom>

        <NavLinkCustom to={PATHS.PRODUCTS} icon={ProductsIcon}>
          {menu.products}
        </NavLinkCustom>

        <NavLinkCustom to={PATHS.MARKETING} icon={MarketingIcon}>
          {menu.marketing}
        </NavLinkCustom>

        <NavLinkCustom to={PATHS.ANALYTICS} icon={AnalyticsIcon}>
          {menu.analytics}
        </NavLinkCustom>

        <NavLinkCustom to={PATHS.LEADS} icon={LeadsIcon}>
          {menu.leads}
        </NavLinkCustom>

        {/*<NavLinkCustom to={PATHS.OFFERS} icon={OffersIcon}>*/}
        {/*  {menu.offers}*/}
        {/*</NavLinkCustom>*/}

        <NavLinkCustom to={PATHS.USERS} icon={UsersIcon}>
          {menu.users}
        </NavLinkCustom>

        {!isProdEnv && (
          <NavLinkCustom to={PATHS.TRADE_ACCOUNTS('')} icon={UsersIcon}>
            {menu.tradeAccounts}
          </NavLinkCustom>
        )}

        <NavLinkCustom to={PATHS.TRAININGS} icon={TrainingsIcon}>
          {menu.trainings}
        </NavLinkCustom>

        <NavLinkCustom to={PATHS.SETTINGS} icon={SettingsIcon}>
          {menu.settings}
        </NavLinkCustom>
      </NavbarLinkWrapper>

      <LogoutStyled onClick={handleLogOut}>
        <Icon as={LogoutIcon} $light $noHover />
        <T8y color="positive" bold>
          {menu.logOut}
        </T8y>
      </LogoutStyled>
    </NavbarStyled>
  );
}

function NavLinkCustom({ icon: Icon, to, children }) {
  return (
    <NavLinkCustomStyled as={NavLink} to={to} light="true">
      <Icon />
      <T8y color="positive" bold variant="t3">
        {children}
      </T8y>
    </NavLinkCustomStyled>
  );
}
