import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { getLead } from 'models/lead';
import {
  getAllSuppliersFx,
  isOrderUpdating$,
  setIsOrderUpdating,
  suppliers$,
  suppliersLoading$,
} from 'models/order';

import { FieldStyle, FieldStyle3 } from 'components/Product/shared';
import { SUPPLIERS } from 'api';

import { SectionWrapper, StyledButton } from './styles';

import { inputStyle, Select, Spinner, T8y } from 'ui';

export const OrderSupplier = ({ orderId, orderType, orderSupplier }) => {
  const { form } = Lang();

  const suppliers = useStore(suppliers$);
  const suppliersLoading = useStore(suppliersLoading$);
  const isOrderUpdating = useStore(isOrderUpdating$);

  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isAdded, setIsAdded] = useState(false);

  const getSuppliers = useCallback(async () => {
    if (!orderSupplier && !suppliers.length) {
      try {
        await getAllSuppliersFx();
      } catch (e) {
        console.warn(e);
      }
    }
  }, [orderSupplier, suppliers.length]);

  const onUpdateCallback = useCallback(() => {
    getLead({ id: orderId, type: orderType });
  }, [orderId, orderType]);

  useEffect(
    () => {
      getSuppliers();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const addSupplierToOrder = useCallback(async () => {
    try {
      setIsOrderUpdating(true);
      await SUPPLIERS.addSupplier(orderId, selectedSupplier.id);
      setIsAdded(true);
      toast.success(form.toastTitle.successfullyUpdated);
      onUpdateCallback();
    } catch (e) {
      toast.error(form.toastTitle.wentWrong);
    } finally {
      setIsOrderUpdating(false);
    }
  }, [form, onUpdateCallback, orderId, selectedSupplier]);

  return (
    <div>
      <T8y fontSize="18px" color="darkBlue" marginBottom="16px" bold>
        Supplier
      </T8y>
      <SectionWrapper $align={orderSupplier ? 'flex-start' : 'center'}>
        {suppliersLoading ? (
          <Spinner dark />
        ) : (
          <>
            {orderSupplier || isAdded ? (
              <T8y fontSize="16px">
                {orderSupplier || selectedSupplier.name}
              </T8y>
            ) : (
              <>
                <FieldStyle as={FieldStyle3} withBorder={true}>
                  <Select
                    options={suppliers}
                    selected={selectedSupplier?.name}
                    onClickOption={value => {
                      setSelectedSupplier(
                        suppliers.find(el => el.id === value),
                      );
                    }}
                    defaultText="Select a Supplier"
                    className={inputStyle}
                    maxHeight="30rem"
                  />
                </FieldStyle>
                <StyledButton
                  onClick={addSupplierToOrder}
                  disabled={
                    suppliersLoading || isOrderUpdating || !selectedSupplier
                  }
                >
                  Assign supplier for the order
                </StyledButton>
              </>
            )}
          </>
        )}
      </SectionWrapper>
    </div>
  );
};
