import React from 'react';

import { Icon } from 'components/Product/Icon';
import { FieldStyle, FieldStyle2 } from 'components/Product/shared';

import * as ST from './styles';

import { DropZoneBlockPlus, T8y } from 'ui';

const TrainingAddImage = ({
  legend,
  src,
  handleUpload,
  handleDelete,
  width,
  height,
  error,
  ImageRequirements,
}) => {
  return (
    <ST.UploadImageWrapper width={width} direction="column">
      <FieldStyle as={FieldStyle2} legend={legend}>
        {src ? (
          <ST.ImageWrapper width={width} height={height}>
            <img src={src} alt="icon" />
            <ST.RemoveIconWrapper>
              <Icon type="lightDelete" handleClick={handleDelete} />
            </ST.RemoveIconWrapper>
          </ST.ImageWrapper>
        ) : (
          <ST.DropzoneWrapper width={width} height={height}>
            <DropZoneBlockPlus
              accept="image/jpeg, image/jpg, image/png"
              handleUpload={handleUpload}
              multiple={false}
            />
          </ST.DropzoneWrapper>
        )}
      </FieldStyle>
      <ST.ErrorStyled message={error} />
      {!!ImageRequirements && (
        <T8y marginTop="10px" align="center" fontSize="12px" color={'gray2'}>
          {ImageRequirements}
        </T8y>
      )}
    </ST.UploadImageWrapper>
  );
};

export default TrainingAddImage;
