import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { Route, Router, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NEWPaths } from 'AppPaths';
import {
  API_BASENAME,
  COUNTRY_SLUG_FROM_REGION,
  getApiVersion,
  getShortDisplayedCompanyName,
  LANGUAGES,
  REGIONS,
} from 'consts';
import { useStore } from 'effector-react';
import { getUserFx } from 'models/user';
import { setWebsite, website$ } from 'models/website';

import { history } from 'libs/history';
import { getToken, haveToken, logout } from 'api';

import { AdminApp } from './AdminApp';
import { ErrorBoundary } from './AppError';
import { selectLang } from './layout/Header/SelectLanguage';
import commonRoutes from './routes/commonRoutes';

import { Row, Spinner } from './ui';

import './assets/styles/index.scss';

const Fallback = (
  <Row
    align="center"
    justify="center"
    style={{ width: '100vw', height: '100vh' }}
  >
    <Spinner dark size={5} />
  </Row>
);

const App = () => {
  const website = useStore(website$);

  const [isLoading, setIsLoading] = useState(true);

  const checkIfUserHasRights = async regionSlug => {
    try {
      const country = COUNTRY_SLUG_FROM_REGION[regionSlug];

      if (!country) {
        logout();
        toast.error('You have entered non existing region');
        return;
      }

      const apiVersion = getApiVersion(country);
      const token = getToken();
      const userInfoData = await getUserFx({
        country,
        token,
        apiVersion,
      });

      if (!userInfoData.regions.includes(regionSlug)) {
        logout();
        toast.error('You have no admin rights for selected region');
        return;
      }

      NEWPaths.setROOT(regionSlug);

      setWebsite({
        shortCompanyName: getShortDisplayedCompanyName(country),
        country,
        regionName: REGIONS[country]?.find(el => el.apiSlug === regionSlug)
          ?.name,
        regionSlug,
        api: `https://${API_BASENAME}.${country}/api/${apiVersion}/${regionSlug}`,
      });

      selectLang(LANGUAGES[country]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const region = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');

    if (
      !window.location.href.endsWith('/login') &&
      haveToken() &&
      (!website.country || website.regionSlug !== region)
    ) {
      checkIfUserHasRights(region);
    } else {
      setIsLoading(false);
    }
  }, [website]);

  return (
    <ErrorBoundary>
      <Router history={history}>
        <React.Suspense fallback={Fallback}>
          <Switch>
            {commonRoutes.map((route, index) => {
              return <Route key={index} {...route} />;
            })}
            {!isLoading && <AdminApp />}
          </Switch>
        </React.Suspense>
      </Router>
    </ErrorBoundary>
  );
};

// eslint-disable-next-line import/no-default-export
export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
