import React from 'react';
import { Lang } from 'lang';

import {
  FieldStyle,
  FieldWithMargin,
  HalfField,
  InputStyled,
} from '../../Product/shared';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';

const UserCompanyContactInfo = ({ values, register }) => {
  const strings = Lang();

  return (
    <ProductEditorBlockWrapper
      blockTitle={strings.users.info.contactInformation}
    >
      <HalfField>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.firstName}>
          <InputStyled
            disabled
            name="first_name"
            ref={register}
            defaultValue={values?.first_name}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.lastName}>
          <InputStyled
            disabled
            name="last_name"
            ref={register}
            defaultValue={values?.last_name}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.contactPhoneNumber}
        >
          <InputStyled
            disabled
            name="contact_phone"
            ref={register}
            defaultValue={values?.contact_phone}
          />
        </FieldStyle>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.contactEmailAddress}
        >
          <InputStyled
            disabled
            name="contact_email"
            ref={register}
            defaultValue={values?.contact_email}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.howDidYouKnowAboutUs}
        >
          <InputStyled
            disabled
            name="know_about_us"
            ref={register}
            defaultValue={values?.know_about_us}
          />
        </FieldStyle>
      </HalfField>
    </ProductEditorBlockWrapper>
  );
};

export default UserCompanyContactInfo;
