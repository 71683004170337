import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { branding$ } from 'models/branding';
import { settings$ } from 'models/settings';
import { handleChangePriceField } from 'utils/helpers/numbers';

import Error from 'components/atoms/Error';
import { Icon } from 'components/Product/Icon';
import { InputNumberStyled } from 'components/Product/shared';

import * as ST from './styles';

const RangePriceItem = ({
  index,
  control,
  item,
  handleRemove,
  errors,
  register,
  validateFields,
  isPricesRequired,
  biggestRangePossible,
  checkIsPriceRequired,
}) => {
  const {
    form: { notification },
  } = Lang();

  const settings = useStore(settings$);
  const branding = useStore(branding$);

  return (
    <ST.Wrapper key={item.id}>
      <ST.RangeInputsWrapper>
        <ST.SmallInputWrapper>
          <InputNumberStyled
            name={`prices[${index}].period_from`}
            type="number"
            width="60px"
            ref={register({
              required: isPricesRequired && notification.theFieldIsRequired,
              validate: biggestRangePossible && {
                positive: value =>
                  value <= biggestRangePossible || notification.theBiggestRange,
              },
            })}
            defaultValue={item.period_from || ''}
            control={control}
            onChange={() => {
              validateFields();
              checkIsPriceRequired();
            }}
            asDisabled={item.isDisable}
          />
          <Error
            message={get(errors, ['prices', index, 'period_from', 'message'])}
          />
        </ST.SmallInputWrapper>
        <ST.DividerWrapper>
          <ST.DivideSymbol color="lightGray" align="center">
            &mdash;
          </ST.DivideSymbol>
        </ST.DividerWrapper>
        <ST.SmallInputWrapper>
          <InputNumberStyled
            name={`prices[${index}].period_to`}
            type="number"
            width="60px"
            ref={register({
              required: isPricesRequired && notification.theFieldIsRequired,
              validate: {
                ...(biggestRangePossible && {
                  positive: value =>
                    value <= biggestRangePossible ||
                    notification.theBiggestRange,
                }),
                ...(settings.fixedRangesPrice &&
                  index === 0 && {
                    rangeOfValues: value =>
                      Number(value) === 7 || notification.rangeDaysMustBe,
                  }),
              },
            })}
            defaultValue={item.period_to || ''}
            control={control}
            onChange={() => {
              validateFields();
              checkIsPriceRequired();
            }}
            asDisabled={item.isDisable}
          />
          <Error
            message={get(errors, ['prices', index, 'period_to', 'message'])}
          />
        </ST.SmallInputWrapper>
      </ST.RangeInputsWrapper>
      <ST.PriceInputWrapper>
        <ST.EuroIcon>{branding.currencySymbol}</ST.EuroIcon>
        <InputNumberStyled
          name={`prices[${index}].price`}
          type="number"
          step="0.01"
          paddingLeft="3rem"
          width="110px"
          ref={register({
            required: isPricesRequired && notification.theFieldIsRequired,
          })}
          onChange={e => {
            handleChangePriceField(e);
            checkIsPriceRequired();
          }}
          defaultValue={item.price || ''}
          control={control}
        />
        <Error message={get(errors, ['prices', index, 'price', 'message'])} />
      </ST.PriceInputWrapper>
      {index > 0 && !item.isDisable && (
        <ST.IconWrapper>
          <Icon
            type="delete"
            color="gray"
            handleClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove(index);
            }}
          />
        </ST.IconWrapper>
      )}
    </ST.Wrapper>
  );
};

export default RangePriceItem;
