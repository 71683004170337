import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { branding$, getBranding } from 'models/branding';

import styled from 'styled-components';
import { haveToken } from 'api';

import { PATHS } from '../AppPaths';
import { PageTitle } from '../PageTitle';
import { Header } from './Header/Header';
import { Navbar } from './Navbar';

import { colors, fonts } from 'styleGuide';
import { Cell, Grid } from 'ui';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 3000,
  position: 'bottom-right',
  style: { font: fonts.bold, color: colors.positive },
  progressStyle: { background: colors.primary },
});

export const Layout = ({ children }) => {
  const branding = useStore(branding$);

  useEffect(() => {
    getBranding();
  }, []);

  if (!haveToken()) {
    return <Redirect to={PATHS.LOGIN} />;
  }

  return (
    <>
      <PageTitle
        displayedRegionName={`${branding?.companyName || 'easyHire'} - Admin`}
      />
      <Navbar />
      <Grid rows="7.8rem 1fr">
        <Header />
        <Cell as={MainStyled}>{children}</Cell>
      </Grid>
    </>
  );
};

const MainStyled = styled.div`
  padding: 4.5rem 3rem 10rem 12.5rem;
  background-color: #f7f9fc;
  min-height: calc(100vh - 80px);
`;
