import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { banners$, getBanners, updateBanners } from 'models/marketing';

import LoadingSpinner from 'components/atoms/LoadingSpinner';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import HomePageBannerForm from 'components/molecules/HomePageBannerForm';

const HomePageBanner = () => {
  const {
    form: { toastTitle },
  } = Lang();

  const banners = useStore(banners$);
  const [isBannersLoading, setIsBannersLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    control,
    errors,
    setError,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      file_id_mobile: '',
      file_id_web: '',
      srcWeb: '',
      srcMobile: '',
      slideIndex: '',
      cta: '',
      btnUrl: '',
      serverId: '',
      target_blank: '',
      btnText: '',
      h1Text: '',
      pText: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const onSubmit = useCallback(
    async (formData, e) => {
      e.preventDefault();
      setIsBannersLoading(true);

      const res = await updateBanners(
        formData && [{ ...formData, target_blank: false }],
      );
      setIsBannersLoading(false);
      if (res) {
        toast.success(toastTitle.successfullyUpdated);
      } else {
        toast.success(toastTitle.updateError);
      }
    },
    [toastTitle.successfullyUpdated, toastTitle.updateError],
  );

  const handleReset = useCallback(() => {
    if (banners.length) {
      reset({
        ...getValues(),
        ...(banners.find(el => Number(el.slideIndex) === 1) || banners[0]),
      });
    }
  }, [banners, getValues, reset]);

  const getBannersData = useCallback(async () => {
    try {
      await getBanners();
    } catch (e) {
      console.warn(e);
    }
  }, []);

  useEffect(() => {
    setIsBannersLoading(true);

    getBannersData().then(() => {
      setIsBannersLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (banners.length) {
        reset({
          ...getValues(),
          ...(banners.find(el => Number(el.slideIndex) === 1) || banners[0]),
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [banners, reset],
  );

  return isBannersLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <HomePageBannerForm
        register={register}
        control={control}
        values={values}
        errors={errors}
        setValue={setValue}
        setError={setError}
        trigger={trigger}
        watch={watch}
      />

      <AddNewAndSaveCancelFooter
        onSubmit={onSubmit}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default HomePageBanner;
