import { transparentize } from 'polished';

import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Cell, Grid, T8y } from 'ui';

export const HeaderStyled = styled(Grid)`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 40px 0 125px;
  background-color: ${colors.positive};
  box-shadow: 0 5px 25px ${transparentize(0.8, colors.gray)};
`;

export const LogoCell = styled(Cell)`
  display: flex;
  align-items: center;
`;

export const RegionName = styled(T8y)`
  white-space: nowrap;
`;
