import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Button } from 'ui';

export const Note = styled.span`
  display: block;
  font-size: 16px;
  margin-top: 24px;
`;

export const IdFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ConnectBtn = styled(Button)`
  min-width: 200px;
  height: 44px;
  margin-left: 10px;
  background: ${colors.primary};
  border-color: ${colors.primary};
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #ffffff;

  &:hover {
    background-color: #c46407;
  }
`;

export const CancelBtn = styled(Button)`
  min-width: 120px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  color: #666666;

  &:hover {
    color: #666666;
    background: #fbfbfb;
  }
`;

export const ProductInfoRowWrapper = styled.div`
  display: flex;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const ProductInfoTitle = styled.span`
  font-weight: bold;
  color: #b3b7bb;
`;

export const ModalControlsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const ResponseWrapper = styled.p`
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 16px;
`;

export const ErrorWrapper = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #ee0000;
`;
