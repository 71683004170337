import React, { useEffect } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';

import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import TrainingCheckoutAvailable from 'components/molecules/TrainingCheckoutAvailable';
import TrainingNotCheckoutAvailable from 'components/molecules/TrainingNotCheckoutAvailable';

import * as ST from './styles';

import { Radio, T8y } from 'ui';

const TrainingSchedule = ({
  watch,
  register,
  values,
  getIsError,
  setValue,
  errors,
  setDeletedFile,
  trigger,
  control,
  EMPTY_SCHEDULE,
  EMPTY_LOCATION,
  EMPTY_DATE,
  clearErrors,
  unregister,
  setError,
}) => {
  const { trainings } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);
  const radio = useRadioState({ state: 0 });

  const ownerCheckoutAvailabilityWatcher = watch(
    'form.owner.checkoutAvailability',
  );

  useEffect(() => {
    register('form.scheduleType');
  }, [register]);

  useEffect(() => {
    setValue('form.scheduleType', radio.state);
    clearErrors(radio.state === 0 ? 'form.noSchedule' : 'form.schedules');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio.state]);

  useEffect(() => {
    if (values.form.owner && !ownerCheckoutAvailabilityWatcher) {
      radio.setState(1);
      return;
    }

    radio.setState(trainingLocalized?.scheduleType || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingLocalized, ownerCheckoutAvailabilityWatcher]);

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.scheduleCourses}
      listOfFields={['title', 'text', 'mainImage']}
      errors={errors}
      initialIsOpen
    >
      <ST.OptionsWrapper>
        <T8y fontSize="14px" marginBottom="21px" color="gray">
          {trainings.availabilityAndSchedule}
        </T8y>
        <RadioGroup as={ST.RadioWrapper} {...radio}>
          <ST.FirstRadioWrapper>
            <Radio
              {...radio}
              value={0}
              disabled={!values.form.owner?.checkoutAvailability}
            >
              <ST.AvailableOption
                disabled={!values.form.owner?.checkoutAvailability}
              >
                {trainings.courseIsAvailable}
              </ST.AvailableOption>
            </Radio>
            <ST.AvailableOptionText color="gray">
              {trainings.courseIsAvailableText}
            </ST.AvailableOptionText>
          </ST.FirstRadioWrapper>
          <div>
            <Radio {...radio} value={1}>
              <ST.AvailableOption>
                {trainings.courseIsNotAvailable}
              </ST.AvailableOption>
            </Radio>
            <ST.AvailableOptionText color="gray">
              {trainings.courseIsNotAvailableText}
            </ST.AvailableOptionText>
          </div>
        </RadioGroup>
      </ST.OptionsWrapper>
      {values?.form?.scheduleType === 0 ? (
        <TrainingCheckoutAvailable
          watch={watch}
          register={register}
          values={values}
          setValue={setValue}
          setDeletedFile={setDeletedFile}
          getIsError={getIsError}
          errors={errors}
          trigger={trigger}
          control={control}
          EMPTY_SCHEDULE={EMPTY_SCHEDULE}
          EMPTY_DATE={EMPTY_DATE}
          clearErrors={clearErrors}
          unregister={unregister}
          setError={setError}
        />
      ) : (
        <TrainingNotCheckoutAvailable
          register={register}
          values={values}
          setValue={setValue}
          getIsError={getIsError}
          control={control}
          errors={errors}
          trigger={trigger}
          EMPTY_LOCATION={EMPTY_LOCATION}
        />
      )}
    </GeneralExpandableSection>
  );
};

export default TrainingSchedule;
