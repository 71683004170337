import styled from 'styled-components';

import { colors } from 'styleGuide';

export const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Promt = styled.div`
  color: ${colors.gray};
`;
