import React, { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  allAvailableProducts$,
  allProductsLoading$,
  allProductsWithoutFiltersLocalized$,
  isAllProductsWithoutFiltersLoading$,
} from 'pages/model';

import AddMore from 'components/atoms/AddMore';
import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import { usePopularModalFilterAndSearch } from '../../organisms/PopularProducts/usePopularModalFilterAndSearch';
import ChosenProductsList from '../ChosenProductsList';
import { ProductsModal } from '../VariationalsModal';
import * as ST from './styles';

const JobTypeProducts = ({ errors, control, values, setValue }) => {
  const { marketing, form } = Lang();

  const dialog = useDialogState();
  const allProductsLoading = useStore(allProductsLoading$);
  const allProducts = useStore(allProductsWithoutFiltersLocalized$);
  const allAvailableProducts = useStore(allAvailableProducts$);
  const isAllProductsWithoutFiltersLoading = useStore(
    isAllProductsWithoutFiltersLoading$,
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
    keyName: 'formId',
  });

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) {
        return;
      }

      const oldItems = [...values.products];

      try {
        const newItems = [...values.products];
        const removedIndex = newItems.findIndex(
          el => el.orderId === result.source.index,
        );
        const newIndex = newItems.findIndex(
          el => el.orderId === result.destination.index,
        );
        const [removed] = newItems.splice(removedIndex, 1);
        newItems.splice(newIndex, 0, removed);

        setValue(
          'products',
          newItems.map((el, index) => ({ ...el, orderId: index })),
        );
      } catch (e) {
        console.warn(e);
        values.products(oldItems);
        toast.error(form.toastTitle.wentWrong);
      }
    },
    [form.toastTitle.wentWrong, setValue, values],
  );

  const listOfProductsIds = useMemo(() => {
    return values.products.map(el => el.productId);
  }, [values.products]);

  const {
    filteredItems,
    searchStr,
    setSearchStr,
  } = usePopularModalFilterAndSearch(allAvailableProducts);

  const handleAddProduct = useCallback(
    id => {
      const el = allProducts.find(el => el.id === id);
      append({ ...el, productId: el.id, orderId: values.products.length });
    },
    [allProducts, append, values.products.length],
  );

  const handleRemoveProduct = useCallback(
    id => {
      const elIndex = fields.findIndex(el => el.id === id);
      remove(elIndex);
    },
    [fields, remove],
  );

  return (
    <GeneralExpandableSection
      index={1}
      header={marketing.jobTypes.relatedProducts}
      listOfFields={[]}
      errors={errors}
      initialIsOpen
    >
      {(allProductsLoading || isAllProductsWithoutFiltersLoading) && (
        <SpinnerOverlay />
      )}
      <ProductsModal
        dialog={dialog}
        currentItems={listOfProductsIds}
        handleAdd={handleAddProduct}
        handleRemove={handleRemoveProduct}
        filteredItems={filteredItems}
        searchStr={searchStr}
        handleSearch={e => {
          setSearchStr(e.target?.value || '');
        }}
      />
      <ST.PopularProductsBody>
        <ChosenProductsList
          allProductsList={allProducts}
          arrayName="products"
          chosenProductsIdsList={values.products}
          register={() => {}}
          remove={handleRemoveProduct}
          onDragEnd={onDragEnd}
          isDraggable
        />
        <ST.AddProductsWrapper>
          <AddMore
            fontSize="14px"
            text={marketing.jobTypes.addProduct}
            onClick={dialog.show}
          />
        </ST.AddProductsWrapper>
      </ST.PopularProductsBody>
    </GeneralExpandableSection>
  );
};

export default JobTypeProducts;
