import styled from 'styled-components';

import { colors, colorsHovered } from 'styleGuide';

export const SubmitButton = styled.button`
  display: block;
  width: ${props => props.width || '15rem'};
  height: ${props => props.height || '4rem'};
  background-color: ${props =>
    props.backgroundColor ? colors[props.backgroundColor] : colors.primary};
  outline: none;
  line-height: ${props => props.height || '4rem'};
  text-align: center;
  border-radius: ${props => props.borderRadius || '0.5rem'};
  font-weight: 500;
  font-size: 1.6rem;
  color: ${props => (props.color ? colors[props.color] : colors.positive)};
  ${props => props.padding && `padding:${props.padding}`};
  border: ${props => props.border || 'none'};
  transition: background-color 0.5s ease;

  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.colorHovered
        ? colorsHovered[props.colorHovered]
        : colorsHovered.primaryHovered};

    ${props =>
      props.textColorHovered && `color:${colors[props.textColorHovered]}`};
    &:disabled,
    &[disabled] {
      background-color: ${colors.gray};
      border-color: ${colors.gray};
      cursor: not-allowed;
      pointer-events: all !important;
    }
  }

  ${props =>
    props.inverse &&
    `color:${colors.gray3}; 
    background-color:${colors.positive};
    border: 1px solid #D0D0D0;

    &:hover {
      color:${colors.positive};
      background-color: ${colors.darkPrimary};
      border-color: ${colors.darkPrimary};
    }; 
   `};

  &:disabled,
  &[disabled] {
    background-color: ${colors.gray};
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;
