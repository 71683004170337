import {
  combine,
  createEffect,
  createEvent,
  forward,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import FuzzySearch from 'fuzzy-search';
import { createDebounce } from 'utils/helpers/searchDebounce';

import { PRODUCTS } from '../api';

const getConsumablesFx = createEffect({
  handler: async () => {
    const { data } = await PRODUCTS.getConsumables();
    return data.data;
  },
});

export const ConsumablesGate = createGate('');
export const consumables$ = restore(getConsumablesFx, []);
const isIdleConsumables = getConsumablesFx.pending.map(state => state);

guard({
  source: ConsumablesGate.open,
  filter: combine(
    isIdleConsumables,
    consumables$,
    (isLoading, consumables) => !isLoading && !consumables.length,
  ),
  target: getConsumablesFx,
});

export const reloadConsumables = createEvent();

forward({
  from: reloadConsumables,
  to: getConsumablesFx,
});

const consumablesSearcher = state =>
  new FuzzySearch(state, ['sku', 'value'], { sort: true });

export const changeConsumablesSearchStr = createEvent();

export const consumablesSearchStr$ = restore(changeConsumablesSearchStr, '');

const changeConsumablesSearchStrDebounced = createDebounce(
  changeConsumablesSearchStr,
  100,
);
const optionalExtraSearchStrDebounced = restore(
  changeConsumablesSearchStrDebounced,
  '',
);

export const consumablesFiltered$ = combine(
  consumables$,
  optionalExtraSearchStrDebounced,
  (e, s) => {
    if (!s.length) {
      return e;
    }

    return consumablesSearcher(e).search(s);
  },
);
