import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';

import { Icon } from 'components/Product/Icon';
import {
  FieldStyle,
  FieldStyle2,
  Full,
  InputStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { Cell, Row } from 'ui';

const OtherChargesItem = ({ index, control, item, handleRemove, register }) => {
  const settings = useStore(settings$);
  const {
    product: { titles },
  } = Lang();

  return (
    <>
      {settings.supportedLanguages.map(el => (
        <Cell as={Full} key={el}>
          <Row direction="column" fullWidth justify="start">
            <FieldStyle
              as={FieldStyle2}
              legend={`${titles.otherCharges} ${el}`}
            >
              <InputStyled
                name={`other_charges[${index}].${el}`}
                type="text"
                ref={register()}
                control={control}
                defaultValue={item[el]}
              />
            </FieldStyle>
          </Row>
        </Cell>
      ))}
      <ST.IconWrapper>
        <Icon
          type="delete"
          color="gray"
          handleClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleRemove(index);
          }}
        />
      </ST.IconWrapper>
    </>
  );
};

export default OtherChargesItem;
