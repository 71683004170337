import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { faqs$, faqsGate, isFaqsLoading$, updateFaqs } from 'models/faqs';

import Error from 'components/atoms/Error';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';

import { Icon } from '../../Product/Icon';
import {
  BlockRounded,
  FieldStyle,
  FieldStyle2,
  Full,
  InputStyled,
  MarginTop,
  More,
  TextareaStyled,
} from '../../Product/shared';
import * as ST from './styles';

import { Cell, Grid, Row, Spinner } from 'ui';

const Faqs = () => {
  const { form } = Lang();

  useGate(faqsGate);
  const faqs = useStore(faqs$);
  const isFaqsLoading = useStore(isFaqsLoading$);

  const [isFaqsUpdating, setIsFaqsUpdating] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    reset,
    errors,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      faqs: [{ question: '', answer: '' }],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'faqs',
    keyName: 'formId',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  useEffect(() => {
    if (faqs.length) {
      reset({ faqs });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs]);

  const onSubmit = useCallback(
    async (formData, e) => {
      e.preventDefault();
      setIsFaqsUpdating(true);

      try {
        await updateFaqs(formData.faqs);
        toast.success(form.toastTitle.successfullyUpdated);
      } catch (error) {
        toast.success(form.toastTitle.updateError);
      } finally {
        setIsFaqsUpdating(false);
      }
    },
    [form.toastTitle],
  );

  const handleReset = useCallback(() => {
    reset({ faqs: faqs });
  }, [faqs, reset]);

  return (
    <>
      {isFaqsLoading || isFaqsUpdating ? (
        <Row align="center" justify="center">
          <Spinner dark size={3} />
        </Row>
      ) : (
        <>
          <ST.FaqsWrapper>
            <Grid as={Full} marginBottom gaps="2rem 2rem" cols="repeat(3, 1fr)">
              {fields.map((faq, index) => (
                <Cell key={faq.formId} as={BlockRounded}>
                  <FieldStyle as={FieldStyle2} legend={form.fields.question}>
                    <InputStyled
                      name={`faqs[${index}].question`}
                      type="text"
                      ref={register({
                        required: form.notification.theFieldIsRequired,
                        validate: value => {
                          return (
                            !!value.trim() ||
                            form.notification.theFieldIsRequired
                          );
                        },
                      })}
                      defaultValue={values?.faqs?.[index]?.question}
                      paddingRight={fields.length > 5 ? '4rem' : '1.5rem'}
                    />
                    <ST.IconWrapper>
                      <Icon
                        type="delete"
                        color="gray"
                        handleClick={() => remove(index)}
                      />
                    </ST.IconWrapper>
                  </FieldStyle>
                  <Error message={errors?.faqs?.[index]?.question?.message} />

                  <MarginTop as={FieldStyle2} legend={form.fields.answer}>
                    <TextareaStyled
                      name={`faqs[${index}].answer`}
                      height="12rem"
                      ref={register({
                        required: form.notification.theFieldIsRequired,
                        validate: value => {
                          return (
                            !!value.trim() ||
                            form.notification.theFieldIsRequired
                          );
                        },
                      })}
                      defaultValue={values?.faqs?.[index]?.answer}
                    />
                  </MarginTop>
                  <Error message={errors?.faqs?.[index]?.answer?.message} />
                </Cell>
              ))}
            </Grid>

            <Row justify="center">
              <More onClick={() => append({ question: '', answer: '' })} />
            </Row>
          </ST.FaqsWrapper>
          <AddNewAndSaveCancelFooter
            onSubmit={onSubmit}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default Faqs;
