import { Menu, MenuButton, MenuItem } from 'reakit';

import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';

export const CustomSelectSelected = styled(MenuButton)`
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  color: #999999;
  outline: none;
  background: #fff;
  width: 24rem;
  height: 3.2rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 0.2rem;
  margin: 0 0 0 1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  transition: border-color 0.5s ease;
  position: relative;

  svg {
    display: block;
    position: absolute;
    top: 1.4rem;
    right: 1.3rem;
    width: 1rem;
    height: 0.5rem;
    transition: transform 0.2s ease;
    content: '';

    path {
      fill: #999999;
    }
  }

  ${p =>
    p.selected &&
    `
    color: ${colors.darkBlue};
  `}

  &:hover {
    border-color: #999999;
    cursor: pointer;
  }

  &[aria-expanded='true'] {
    border-color: #999999;

    svg {
      transform: rotate(180deg);
    }
  }
`;

export const CustomSelectOption = styled(MenuItem)`
  display: flex;
  outline: none;
  width: 100%;
  height: 3.2rem;
  padding: 0 1rem;
  background: #fff;
  border-top: 1px solid #999999;
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  color: ${colors.darkBlue};
  align-items: center;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: ${colors.lightGray};
    cursor: pointer;
  }

  ${p =>
    p.selected &&
    `
    background-color: ${colors.lightGray};
  `}
`;

export const CustomSelectDropdown = styled(Menu)`
  z-index: 1;
  width: 24rem;
  outline: none;
  border-radius: 0 0 0.2rem 0.2rem;
  border: 1px solid #999999;
  border-top: none;
  overflow: hidden;
  margin-left: 1px;

  ${CustomSelectOption}:first-child {
    border-top: none;
  }
`;
