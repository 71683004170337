import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';

import Error from 'components/atoms/Error';
import {
  FieldStyle,
  FieldStyle2,
  InputNumberStyled,
} from 'components/Product/shared';

import { EuroIcon, StyledCheckbox } from './styles';

import { Row, T8y } from 'ui';

export const WeekendRatePrice = ({
  register,
  setValue,
  watch,
  trigger,
  error,
}) => {
  const { form } = Lang();

  const branding = useStore(branding$);

  const watchWeekendRateEnabled = watch('weekendRateEnabled');

  useEffect(() => {
    if (!watchWeekendRateEnabled) {
      trigger('weekendRatePrice');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchWeekendRateEnabled]);

  return (
    <Row align="center">
      <StyledCheckbox
        fontSize="14px"
        label="Friday - Monday price"
        checked={watchWeekendRateEnabled}
        onChange={e => {
          setValue('weekendRateEnabled', e.target.checked, {
            shouldDirty: true,
          });
        }}
      />
      <div>
        <FieldStyle as={FieldStyle2} width="auto">
          <EuroIcon>{branding.currencySymbol}</EuroIcon>
          <InputNumberStyled
            name="weekendRatePrice"
            type="number"
            width="80px"
            paddingLeft="30px"
            ref={register({
              validate: {
                ...(watchWeekendRateEnabled && {
                  required: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                }),
                noNegative: value =>
                  value >= 0 || form.notification.valueShouldBePositive,
              },
            })}
          />
          <T8y color="gray" fontSize="14px" marginLeft="10px">
            per range
          </T8y>
        </FieldStyle>
        <Error message={error} />
      </div>
    </Row>
  );
};
