import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import CalendarNextDay from 'components/atoms/CalendarNextDay';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

const CalendarNextDays = ({
  control,
  errors,
  register,
  setValue,
  values,
  clearErrors,
}) => {
  const {
    common: { calendar },
  } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'blockedWeekDays',
    keyName: 'formId',
  });

  return (
    <GeneralExpandableSection
      index={0}
      header={calendar.nextDayBehaviour}
      listOfFields={['blockedWeekDays']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      {fields.map((el, index) => (
        <CalendarNextDay
          register={register}
          values={values}
          errors={errors}
          setValue={setValue}
          remove={remove}
          clearErrors={clearErrors}
          index={index}
          key={el.formId}
          el={el}
        />
      ))}
      <AddMore
        marginTop={fields.length > 0}
        fontSize="16px"
        onClick={() =>
          append({
            weekDay: '',
            offset: '',
          })
        }
        text={calendar.addNewBehaviour}
      />
    </GeneralExpandableSection>
  );
};

export default CalendarNextDays;
