export const promoCodes = {
  searchPlaceholder: 'Comece a digitar para filtrar os registros',
  promoCodesFound: '{0} códigos promocionais disponíveis',
  addNewPromoCode: 'Adicionar novo código promocional',
  addPromoCode: 'Adicionar código promocional',
  editPromoCode: 'Editar código promocional',
  taUsersOnly: 'Somente usuários de conta comercial',
  allCatalogue: 'Todo o Catálogo',
  specificCategories: 'Categorias específicas',
  specificProducts: 'Produtos Específicos',
  pause: 'Pausa',
  resume: 'Prosseguir',
  enterProductSKU: 'Insira o SKU do produto',
  useCommaToSeparate: 'Use vírgula para separar vários itens',
  cat: 'Categoria',
  maxDiscount: 'Desconto máximo',
  statuses: {
    active: 'Ativo',
    past: 'Anterior',
    paused: 'Pausado',
  },
  types: {
    multiple: 'Múltiplo',
    onTime: 'Uma vez',
  },

  userTypes: {
    all: 'Todos os usuários',
    taOnly: 'Somente usuários de conta comercial',
    notTaOnly: 'Somente usuários convidados',
  },

  tableHeaders: {
    promoTextCode: 'Código de texto promocional',
    discountValue: 'Valor do desconto',
    status: 'Status',
    type: 'Tipo',
    startDate: 'Data de início',
    endDate: 'Data de finalização',
    applyPromoCodeTo: 'Aplicar código promocional para',
  },
  filterSelect: {
    all: 'Todos',
    active: 'Códigos promocionais ativos',
    paused: 'Códigos promocionais pausados',
    past: 'Códigos promocionais anteriores',
  },
};
