export const categories = {
  titles: {
    manageCategories: 'Gestionar categorías',
    categoriesFound: 'Categorías encontradas en el catálogo',
    startTyping: 'Comienza a escribir para filtrar categorías por título',
    allCategories: 'Todas las categorías',
    archievedCategories: 'Categorías archivadas',
    visual: 'Visual',
    categoryTitle: 'Título de la categoría',
    products: 'Productos',
    createCategory: 'Crear categoría',
    editCategory: 'Editar categoría',
    categoryVisual: 'Imagen de categoría',
    updating: 'Actualizando',
    creating: 'Creando',
    show: 'Mostrar',
    selectFilter: 'Seleccionar filtro',
    deleteSelected: 'Borrar selección',
    deletionConfirmation: 'Confirmación de eliminación',
    prerenderInfo: 'Información pre-render',
    hiddenCategories: 'Categorías ocultas',
    visibleCategories: 'Categorías visibles',
  },
  buttons: {
    cancel: 'Cancelar',
    confirm: 'Confirmar',
  },
  areYouSureYouWantToDeleteCategories:
    '¿Está seguro de que desea eliminar las siguientes categorías:',

  hideCategory: 'Esconder categoría',
  prerenderStatusCode: 'Código de estado pre-render',
  prerenderHeader: 'Encabezado pre-render - Ubicación:',
  prerenderStatusNote: `Código '301' para redirección permanente. Código '302' para redirección temporal`,
  contactToCreateCategory:
    'Comuníquese con el equipo de desarrollo para crear una nueva categoría',
};
