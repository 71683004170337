import { Link } from 'react-router-dom';
import { Checkbox } from 'reakit/Checkbox';

import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';

import { ReactComponent as CategoryTree } from 'assets/images/category_tree.svg';

export const CategoryLink = styled(Link)`
  &:active,
  &:visited,
  &:link {
    color: ${colors.darkBlue};
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;

export const TableRow = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 70px;
  background: #fff;
  cursor: pointer;

  ${p =>
    p.index % 2 === 0 &&
    `
    background: rgba(255, 255, 255, 0.5);
  `}

  ${p =>
    p.selected &&
    `
    background: #fefaed;
  `}
  ${p => p.isLoading && 'background: rgb(224 224 224 / 20%)'}
`;

export const CategoryTreeIcon = styled(CategoryTree)`
  margin-right: 1rem;
`;

export const TableHeaderCheckbox = styled.div`
  padding-left: 1.3rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  width: 1.8rem;
  height: 1.8rem;
  background: #fff;
  margin: 0;
  appearance: none;
  border: 2px solid #bbbbbb;
  border-radius: 0.2rem;
  position: relative;
  top: 0.2rem;
  outline: none;
  cursor: pointer;

  &:checked {
    top: 0.1rem;

    &:after {
      display: flex;
      content: '✓';
      align-items: center;
      justify-content: center;
      color: ${colors.darkBlue};
      font-weight: bold;
      position: relative;
      top: -0.1rem;
    }
  }
`;

export const TableRowTitle = styled.div`
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  color: ${colors.darkBlue};
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TableRowStatus = styled(TableRowTitle)`
  justify-content: center;
`;

export const CheckboxField = styled(TableRowTitle)`
  justify-content: center;
`;

export const EditableFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
`;

export const LocationFieldWrapper = styled(EditableFieldWrapper)`
  width: 100%;
`;

export const BtnField = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-top: 7px;
`;

export const TableRowTitleTree = styled(TableRowTitle)`
  display: flex;
  align-items: center;

  svg {
    position: relative;
    top: -0.4rem;
  }

  ${p =>
    p.level === 1 &&
    `
    svg {
      display: none;
    }
  `}
  ${p =>
    p.level === 2 &&
    `
    padding-left: 4rem;
  `}
  ${p =>
    p.level === 3 &&
    `
    padding-left: 10rem;
  `}

  img {
    height: 3rem;
    display: block;
    margin-right: 1rem;
  }
`;
