import React from 'react';
import { usePagination } from 'hooks';

import Pagination from 'ui/Pagination';

const DeliveryPagination = ({
  handlePageChange,
  totalPages,
  currPage,
  isScrollToTop,
}) => {
  const {
    handleClickPreviousArrow,
    handleClickNextArrow,
    handleClickOnPage,
    visiblePages,
  } = usePagination({
    totalPages,
    currPage,
    handlePageChange,
    isScrollToTop,
  });

  return (
    <Pagination
      handleClickPreviousArrow={handleClickPreviousArrow}
      handleClickNextArrow={handleClickNextArrow}
      handleClickOnPage={handleClickOnPage}
      visiblePages={visiblePages}
      range={5}
      currPage={currPage}
      totalPages={totalPages}
    />
  );
};

export default DeliveryPagination;
