export const promoCodes = {
  searchPlaceholder: 'Start typing to filter the records',
  promoCodesFound: '{0} promo codes available',
  addNewPromoCode: 'Add New Promo Code',
  addPromoCode: 'Add Promo Code',
  editPromoCode: 'Edit Promo Code',
  taUsersOnly: 'TA users only',
  allCatalogue: 'All Catalogue',
  specificCategories: 'Specific Categories',
  specificProducts: 'Specific Products',
  pause: 'Pause',
  resume: 'Resume',
  enterProductSKU: 'Enter Product SKU',
  useCommaToSeparate: 'Use comma to separate several items',
  cat: 'CAT',
  maxDiscount: 'Max Discount',

  statuses: {
    active: 'Active',
    past: 'Past',
    paused: 'Paused',
  },

  types: {
    multiple: 'Multiple',
    onTime: 'One-time',
  },

  userTypes: {
    all: 'All users',
    taOnly: 'TA only',
    notTaOnly: 'Guest users only',
  },

  tableHeaders: {
    promoTextCode: 'Promo Text Code',
    discountValue: 'Discount Value',
    status: 'Status',
    type: 'Type',
    startDate: 'Start Date',
    endDate: 'End Date',
    applyPromoCodeTo: 'Apply Promo Code To',
  },

  filterSelect: {
    all: 'All',
    active: 'Active Promo Codes',
    paused: 'Paused Promo Codes',
    past: 'Past Promo Codes',
  },
};
