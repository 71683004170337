import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';

import Stepper from 'components/molecules/Tabs';
import AdditionalCharges from 'components/organisms/AdditionalCharges';
import DatesSetup from 'components/organisms/DatesSetup';
import DepotsEditor from 'components/organisms/DepotsEditor';
import RegionalSettings from 'components/organisms/RegionalSettings';
import SettingsTransportation from 'components/organisms/SettingsTransportation';

import * as ST from './styles';

import { T8y } from 'ui';

const Settings = () => {
  const {
    settings: { titles },
  } = Lang();

  const settings = useStore(settings$);

  const steps = useMemo(
    () => [
      {
        label: titles.transportation,
        path: PATHS.TRANSPORTATION,
        isDisabled: !settings?.showTransportationSection,
      },
      {
        label: titles.additionalCharges,
        path: PATHS.ADDITIONAL_CHARGES,
      },
      {
        label: titles.regionalSettings,
        path: PATHS.REGIONAL_SETTINGS,
      },
      {
        label: titles.calendarSetup,
        path: PATHS.DATES_SETTINGS,
      },
      {
        label: titles.depots,
        path: PATHS.DEPOTS,
      },
    ],
    [settings, titles],
  );

  return (
    <ST.SettingsPageWrapper>
      <T8y color="darkBlue" variant="t1" bold>
        {titles.content}
      </T8y>
      <Stepper steps={steps} />
      <React.Suspense fallback={<div> Loading ...</div>}>
        <Switch>
          <Route
            path={PATHS.TRANSPORTATION}
            component={SettingsTransportation}
          />
          <Route
            path={PATHS.ADDITIONAL_CHARGES}
            component={AdditionalCharges}
          />
          <Route path={PATHS.DATES_SETTINGS} component={DatesSetup} />
          <Route path={PATHS.REGIONAL_SETTINGS} component={RegionalSettings} />
          <Route path={PATHS.DEPOTS} component={DepotsEditor} />
          <Redirect
            to={
              settings?.showTransportationSection
                ? PATHS.TRANSPORTATION
                : PATHS.REGIONAL_SETTINGS
            }
          />
        </Switch>
      </React.Suspense>
    </ST.SettingsPageWrapper>
  );
};

export default Settings;
