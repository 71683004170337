import React, { useMemo } from 'react';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { isEmpty } from 'lodash';
import { groupSettings$, groupSettingsGate } from 'models/settingsGroup';

import Table from 'components/atoms/Table';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';

const RegionalSettings = () => {
  useGate(groupSettingsGate);
  const groupSettings = useStore(groupSettings$);

  const modifiedDisplayedUsers = useMemo(() => {
    return groupSettings.map((el, index) => {
      const payload =
        typeof el.payload === 'object' && !isEmpty(el.payload)
          ? Object.values(el.payload)
          : el.payload;

      return {
        id: index + 1,
        name: el.name,
        group: el.group,
        payload: JSON.stringify(payload),
        description: el.description,
      };
    });
  }, [groupSettings]);

  const {
    settings: { titles },
  } = Lang();

  const headers = useMemo(() => {
    return ['id', 'name', 'group', 'payload', 'description'];
  }, []);

  const keyFields = [
    { name: 'id', type: 'text' },
    { name: 'name', type: 'text' },
    { name: 'group', type: 'text' },
    { name: 'payload', type: 'text' },
    { name: 'description', type: 'text' },
  ];

  return (
    <ProductEditorBlockWrapper blockTitle={titles.regionalSettings}>
      <Table
        headers={headers}
        keyFields={keyFields}
        items={modifiedDisplayedUsers}
        cols="0.5fr 2fr 1fr 2fr 2fr"
      />
    </ProductEditorBlockWrapper>
  );
};

export default RegionalSettings;
