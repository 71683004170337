import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';
import useSettings from 'utils/useSettings';

import DraftMessage from 'components/atoms/DraftMessage';
import Charges from 'components/molecules/Charges';
import Consumables from 'components/molecules/Consumables';
import HistoryTable from 'components/molecules/HistoryTable';
import OptionalExtra from 'components/molecules/OptionalExtra';
import Pricing from 'components/molecules/Pricing';
import PricingByRange from 'components/molecules/PricingByRange';
import { ProductCategoriesBlock } from 'components/molecules/ProductCategoriesBlock';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import { ProductGeneralInfoBlock } from 'components/molecules/ProductGeneralInfoBlock';
import { ProductOtherCharges } from 'components/molecules/ProductOtherCharges';
import ProductSafetyRecommendations from 'components/molecules/ProductSafetyRecommendations';
import RequiredExtra from 'components/molecules/RequiredExtra';
import ThirdParties from 'components/molecules/ThirdParties';
import VideoBlock from 'components/molecules/VideoBlock';
import Warehouses from 'components/molecules/Warehouses';
import { Faq } from 'components/Product/Faq';
import { FrequentlyHiredTogether } from 'components/Product/FrequentlyHiredTogether';
import ManagePanel from 'components/Product/ManagePanel';
import { PlatformProduct } from 'components/Product/PlatformProduct';
import { Reviews } from 'components/Product/Reviews';
import { Seo } from 'components/Product/Seo';
import { Specifications } from 'components/Product/Specifications';
import { UploadPhotos } from 'components/Product/UploadPhotos';
import { UploadUserGuides } from 'components/Product/UploadUserGuides';
import { Variations } from 'components/Product/Variations';

import { Cell, Grid, T8y } from 'ui';

const ProductEditor = ({
  register,
  handleSubmit,
  onSubmit,
  onError,
  loading,
  setValue,
  values,
  getValues,
  isNewProduct,
  control,
  errors,
  watch,
  setError,
  clearErrors,
  trigger,
  fields,
  isValidPhotos,
  setIsValidPhotos,
  productCharges,
  isNoPhotos,
}) => {
  const {
    product,
    form: { buttons, toastTitle },
  } = Lang();

  const settings = useStore(settings$);
  const { videoInProductInfo } = useSettings();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <ManagePanel
          loading={loading}
          title={
            isNewProduct
              ? product.titles.createProduct
              : product.titles.editProduct
          }
          submitButtonTitle={
            isNewProduct || values.hidden ? buttons.publish : buttons.update
          }
          loadingTitle={
            isNewProduct
              ? `${toastTitle.creating}...`
              : `${toastTitle.updating}...`
          }
          isNewProduct={isNewProduct}
          id={values.id}
          values={values}
          setValue={setValue}
          register={register}
          trigger={trigger}
          isNoPhotos={isNoPhotos}
        />
        <Grid cols="3fr 1.5fr" gaps="0 3rem">
          <div>
            {values.hidden && <DraftMessage />}
            <ProductEditorBlockWrapper blockTitle={product.productOwner}>
              <T8y fontSize="16px" color="negative" bold>
                {values.owner?.name || '-'}
              </T8y>
            </ProductEditorBlockWrapper>
            <ProductCategoriesBlock
              register={register}
              setValue={setValue}
              isNewProduct={isNewProduct}
              errors={errors}
              values={values}
              trigger={trigger}
              clearErrors={clearErrors}
              watch={watch}
            />
            <ProductGeneralInfoBlock
              register={register}
              setValue={setValue}
              errors={errors}
              values={values}
              trigger={trigger}
              control={control}
              isNewProduct={isNewProduct}
            />
            <Variations register={register} />
            <ThirdParties
              register={register}
              setValue={setValue}
              isNewProduct={isNewProduct}
              errors={errors}
              values={values}
              trigger={trigger}
              control={control}
            />
            {settings.fixedRangesPrice ? (
              <PricingByRange
                control={control}
                register={register}
                values={values}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
                watch={watch}
              />
            ) : (
              <Pricing
                control={control}
                register={register}
                values={values}
                setValue={setValue}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                isNewProduct={isNewProduct}
                trigger={trigger}
              />
            )}
            <Charges
              errors={errors}
              register={register}
              values={values}
              setValue={setValue}
              control={control}
              watch={watch}
              trigger={trigger}
              productCharges={productCharges}
            />
            <ProductOtherCharges register={register} control={control} />
            <Seo
              register={register}
              values={values}
              setValue={setValue}
              isNewProduct={isNewProduct}
            />
          </div>

          <div>
            <UploadPhotos
              register={register}
              setValue={setValue}
              getValues={getValues}
              isValidPhotos={isValidPhotos}
              setIsValidPhotos={setIsValidPhotos}
            />
            {videoInProductInfo && (
              <VideoBlock
                register={register}
                values={values}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
              />
            )}
            <Warehouses
              register={register}
              values={values}
              setValue={setValue}
              control={control}
              watch={watch}
              fields={fields}
              errors={errors}
              clearErrors={clearErrors}
              isDisabled={values?.isActiveIntegration}
            />
            <ProductSafetyRecommendations
              register={register}
              setValues={setValue}
              currentSafetyRecommendations={values.safetyRecommendations}
            />
            <UploadUserGuides setValue={setValue} getValues={getValues} />
          </div>
        </Grid>

        <Specifications
          register={register}
          control={control}
          errors={errors}
          values={values}
        />
        {/*<EventTypes register={register} />*/}
        <Faq register={register} control={control} />

        <Reviews
          register={register}
          trigger={trigger}
          errors={errors}
          values={values}
          setValue={setValue}
          watch={watch}
          control={control}
        />
        <FrequentlyHiredTogether register={register} />

        <Grid cols="1fr 1fr" gaps="0 3rem">
          <Cell>
            <RequiredExtra register={register} control={control} />
          </Cell>
          <Cell>
            <OptionalExtra register={register} control={control} />
          </Cell>
        </Grid>
        {settings?.extraConsumablesEnabled && (
          <Consumables register={register} control={control} />
        )}
        {(window.location.href.includes('stage.') ||
          window.location.href.includes('dev.')) && (
          <PlatformProduct
            register={register}
            setValue={setValue}
            platformProductId={values.foreign_id}
          />
        )}
      </form>
      <HistoryTable id={values.id} type="products" />
    </>
  );
};

export default ProductEditor;
