import React, { useCallback, useMemo } from 'react';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  allProductsWithoutFiltersLocalized$,
  isAllProductsWithoutFiltersLoading$,
} from 'pages/model';

import AddMore from 'components/atoms/AddMore';
import ShowSection from 'components/atoms/ShowSection';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import { TrainingRecommendedProductsModal } from 'components/molecules/TrainingRecommendedProductsModal';
import { Icon } from 'components/Product/Icon';

import * as ST from './styles';

import { Spinner, T8y } from 'ui';

const TrainingRecommendedProducts = ({ setValue, watch, values }) => {
  const { trainings, form } = Lang();
  const dialog = useDialogState();

  const allProducts = useStore(allProductsWithoutFiltersLocalized$);
  const productsLoading = useStore(isAllProductsWithoutFiltersLoading$);

  const watchRecommendedProducts = watch('form.recommendedProducts');

  const handleChange = useCallback(
    (value, action) => {
      const newRecommendedProducts = [...watchRecommendedProducts];
      if (action === 'add') {
        newRecommendedProducts.push(value);
      } else {
        newRecommendedProducts.splice(value, 1);
      }
      setValue('form.recommendedProducts', newRecommendedProducts);
    },
    [setValue, watchRecommendedProducts],
  );

  const recommendedProducts = useMemo(
    () =>
      watchRecommendedProducts?.reduce((acc, el) => {
        const product = allProducts.find(p => p.id === el);
        if (product) {
          acc.push({
            id: product.id,
            sku: product.sku,
            image: product.image?.data.thumb,
            price: product.prices?.data[0]?.price || 0,
            name: (
              <span>
                {product.name}
                {product.isOutOfStock && (
                  <T8y as="span" fontSize="14px" color="danger" bold>
                    {' '}
                    ({form.fields.outOfStock})
                  </T8y>
                )}
              </span>
            ),
          });
        }
        return acc;
      }, []),
    [allProducts, form.fields.outOfStock, watchRecommendedProducts],
  );

  const handleShow = useCallback(
    value => {
      setValue('form.recommendationsShow', value);
    },
    [setValue],
  );

  const tableTitles = useMemo(
    () => [
      form.fields.productCode,
      form.fields.visual,
      form.fields.productTitle,
      form.fields.price,
      '',
    ],
    [form],
  );
  const tableFields = useMemo(
    () => ['sku', 'image', 'name', 'price', 'delete'],
    [],
  );

  const tableElements = useMemo(
    () =>
      recommendedProducts.map((el, index) =>
        tableFields.map((field, i) => (
          <ST.TableItem key={i}>
            {field === 'image' && el.image && (
              <img width="40px" height="40px" src={el.image} alt="product" />
            )}
            {field === 'delete' && (
              <Icon
                type="delete"
                color="gray"
                handleClick={() => handleChange(index)}
              />
            )}
            {field !== 'image' && field !== 'delete' && (
              <T8y fontSize="14px">{el[field]}</T8y>
            )}
          </ST.TableItem>
        )),
      ),
    [recommendedProducts, handleChange, tableFields],
  );

  return (
    <>
      <TrainingRecommendedProductsModal
        items={watchRecommendedProducts}
        dialog={dialog}
        handleChange={handleChange}
      />
      <GeneralExpandableSection
        index={0}
        header={trainings.recommendedProducts}
        additionalHeaderComponent={
          <ShowSection
            defaultValue={values?.form?.recommendationsShow}
            handleChange={handleShow}
            fields={watchRecommendedProducts}
          />
        }
        initialIsOpen
      >
        {productsLoading && <Spinner dark size={3} />}
        {!productsLoading && watchRecommendedProducts.length > 0 && (
          <ST.CustomGrid cols="2fr 100px 7fr 1fr 40px" rows="40px">
            {tableTitles.map((el, i) => (
              <ST.HeaderItem key={i} fontSize="14px" color="gray">
                {el}
              </ST.HeaderItem>
            ))}
            {tableElements}
          </ST.CustomGrid>
        )}
        <AddMore
          marginTop={watchRecommendedProducts.length > 0}
          fontSize="16px"
          onClick={dialog.show}
          text={trainings.addNewProduct}
        />
      </GeneralExpandableSection>
    </>
  );
};

export default TrainingRecommendedProducts;
