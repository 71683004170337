import React, { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { formatISO, parse } from 'date-fns';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  createReportFx,
  editedReport$,
  getReports,
  setEditedReport,
  updateReportFx,
} from 'models/analytics';
import { getYears } from 'utils/dates';
import { handleUploadEnd } from 'utils/filesDownload';
import { isUrl } from 'utils/patterns';

import Error from 'components/atoms/Error';

import CustomButton from '../../atoms/CustomButton';
import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import * as ST from './styles';

import { inputStyle, Radio, Row, Select } from 'ui';

const LAST_FIVE_YEARS = getYears(5);

const ReportForm = ({ onSuccess }) => {
  const editedReport = useStore(editedReport$);

  const {
    form: {
      buttons,
      notification,
      toastTitle,
      fields,
      radio: radioLocalization,
    },
    analytics: { months: monthsLocalization },
  } = Lang();

  const months = [
    {
      name: monthsLocalization.january,
      slug: 'january',
    },
    {
      name: monthsLocalization.february,
      slug: 'february',
    },
    {
      name: monthsLocalization.march,
      slug: 'march',
    },
    {
      name: monthsLocalization.april,
      slug: 'april',
    },
    {
      name: monthsLocalization.may,
      slug: 'may',
    },
    {
      name: monthsLocalization.june,
      slug: 'june',
    },
    {
      name: monthsLocalization.july,
      slug: 'july',
    },
    {
      name: monthsLocalization.august,
      slug: 'august',
    },
    {
      name: monthsLocalization.september,
      slug: 'september',
    },
    {
      name: monthsLocalization.october,
      slug: 'october',
    },
    {
      name: monthsLocalization.november,
      slug: 'november',
    },
    {
      name: monthsLocalization.december,
      slug: 'december',
    },
  ];

  const radio = useRadioState({ state: 'file' });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    errors,
    reset,
  } = useForm({
    defaultValues: {
      year: editedReport ? editedReport.year : LAST_FIVE_YEARS[0].name,
      month: editedReport
        ? editedReport.month
        : months[new Date().getMonth()].slug,
      fileName: editedReport ? editedReport.fileName : '',
      title: editedReport ? editedReport.title : '',
      media: editedReport ? editedReport.media : '',
      uploadedFileLink: editedReport ? editedReport.media : '',
      publicLink: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const watchAll = watch();

  useEffect(() => {
    register({ name: 'uploadedFileLink' });
  }, [register]);

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll]);

  const onDrop = useCallback(
    acceptedFiles => {
      handleUploadEnd({
        acceptedFiles,
        handleUploaded: filePath => {
          const fileName =
            (acceptedFiles && acceptedFiles[0] && acceptedFiles[0].name) ||
            null;
          setValue('fileName', fileName);
          setValue('uploadedFileLink', filePath.src);
        },
        maxAmountOfFiles: 1,
      });
    },
    [setValue],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleCreateReport = (data, result) => {
    return createReportFx({
      name: data.title || data.publicLink,
      media: radio.state === 'file' ? data.uploadedFileLink : data.publicLink,
      date: result,
    });
  };

  const handleUpdateReport = (data, result) => {
    return updateReportFx({
      id: editedReport.id,
      report: {
        name: data.title || data.publicLink,
        media: radio.state === 'file' ? data.uploadedFileLink : data.publicLink,
        date: result,
      },
    });
  };

  const onSubmit = async data => {
    const result = formatISO(
      parse(`${data.month}/${data.year}`, 'MMMM/yyyy', new Date()),
    );

    const isEdit = !!editedReport;

    const res = isEdit
      ? await handleUpdateReport(data, result)
      : await handleCreateReport(data, result);

    if (res) {
      toast.success(
        toastTitle[isEdit ? 'successfullyUpdated' : 'successfullyCreated'],
      );
      getReports();
      setEditedReport(null);
      reset();
      onSuccess();
    } else {
      toast.success(toastTitle[isEdit ? 'updateError' : 'createError']);
    }
  };

  const monthData = useMemo(() => {
    if (!values?.month) {
      return notification.loading;
    }

    return months.find(({ slug }) => slug === values.month.toLowerCase())?.name;
  }, [months, notification.loading, values]);

  return (
    <>
      <ST.FormFieldsWrapper>
        <ST.DateWrapper>
          <ST.LocalFieldWrapper>
            <FieldStyle2 legend={fields.ReportYear} withBorder={true}>
              <InputStyled
                name="year"
                type="hidden"
                ref={register({ required: notification.theFieldIsRequired })}
                defaultValue={values && values.year}
              />
              <Select
                options={LAST_FIVE_YEARS}
                selected={values && values.year}
                defaultText={values && values.year}
                onClickOption={option => {
                  setValue('year', option);
                }}
                className={inputStyle}
                maxHeight="300px"
              />
            </FieldStyle2>
            <Error message={errors && errors.year && errors.year.message} />
          </ST.LocalFieldWrapper>
          <ST.LocalFieldWrapper>
            <FieldStyle as={ST.MonthSelector} withBorder={true}>
              <InputStyled
                name="month"
                type="hidden"
                ref={register({ required: notification.theFieldIsRequired })}
                defaultValue={values && values.month}
              />
              <Select
                options={months}
                selected={monthData}
                defaultText={monthData}
                onClickOption={option => {
                  setValue('month', option);
                }}
                className={inputStyle}
                maxHeight="300px"
              />
            </FieldStyle>
            <Error message={errors && errors.month && errors.month.message} />
          </ST.LocalFieldWrapper>
        </ST.DateWrapper>
        <FieldStyle2 legend={fields.reportTitle}>
          <ST.LocalFieldWrapper>
            <InputStyled
              name="title"
              ref={register({
                validate: value =>
                  !!value?.trim() || notification.theFieldIsRequired,
              })}
            />
            <Error message={errors && errors.title && errors.title.message} />
          </ST.LocalFieldWrapper>
        </FieldStyle2>
        <RadioGroup {...radio}>
          <ST.FileUploadWrapper>
            <Radio {...radio} text={radioLocalization.file} value="file" />
            <ST.FileNameWrapper>
              <InputStyled
                name="fileName"
                step="0.01"
                paddingLeft="3rem"
                ref={register(
                  radio.state === 'file' && {
                    validate: value =>
                      !!value?.trim() || notification.theFieldIsRequired,
                  },
                )}
                disabled={radio.state === 'url'}
              />
            </ST.FileNameWrapper>
            <CustomButton
              text={buttons.browse}
              backgroundColor="positive"
              color="negative"
              border="1px solid #D0D0D0"
              colorHovered="gray"
              disabled={radio.state === 'url'}
              {...getRootProps()}
            />
            <input {...getInputProps()} />
          </ST.FileUploadWrapper>
          <Row align="center">
            <Radio {...radio} text="Url" value="url" />
            <ST.FileNameWrapper removeLastMargin>
              <InputStyled
                name="publicLink"
                paddingLeft="3rem"
                ref={register({
                  required:
                    radio.state === 'url' && notification.theFieldIsRequired,
                  validate: radio.state === 'url' && {
                    url: value =>
                      isUrl(value) || notification.valueShouldBeValid,
                  },
                })}
                disabled={radio.state === 'file'}
              />
            </ST.FileNameWrapper>
          </Row>
          {radio.state === 'file' ? (
            <Error
              message={errors && errors.fileName && errors.fileName.message}
            />
          ) : (
            <Error
              message={errors && errors.publicLink && errors.publicLink.message}
            />
          )}
        </RadioGroup>
      </ST.FormFieldsWrapper>
      <ST.FormButtonsWrapper>
        <CustomButton
          text={buttons.cancel}
          backgroundColor="positive"
          color="gray"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          // onClick={handleReset}
        />
        <CustomButton
          text={buttons.saveChanges}
          onClick={handleSubmit(onSubmit)}
        />
      </ST.FormButtonsWrapper>
    </>
  );
};

export default ReportForm;
