import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Checkbox } from 'ui';

export const EuroIcon = styled.span`
  position: absolute;
  color: ${colors.negative}!important;
  top: 12px;
  left: 20px;
  line-height: 1.5 !important;

  ${p =>
    p.disabled &&
    `
    color: ${colors.gray};
  `}
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 12px;
  padding-bottom: 16px;
`;
