import React, { useCallback, useMemo, useState } from 'react';
import { Lang } from 'lang';

import GoogleMapsWrapper from 'components/atoms/GoogleMapsWrapper';
import { InputStyled } from 'components/Product/shared';

import * as ST from './styles';

const FIELDS = [
  'place_id',
  'geometry',
  'name',
  'formatted_address',
  'address_components',
];

const LocationSearchInput = ({
  handleChange,
  address,
  innerRef,
  inputName,
}) => {
  const { form } = Lang();

  const [autocomplete, setAutocomplete] = useState();

  const name = useMemo(() => {
    return (address && address.name) || '';
  }, [address]);

  const getPostcode = useCallback(address_components => {
    for (let i = 0; i < address_components.length; i++) {
      for (let j = 0; j < address_components[i].types.length; j++) {
        if (address_components[i].types[j] === 'postal_code') {
          return address_components[i].long_name;
        }
      }
    }
    return null;
  }, []);

  const getCity = useCallback(address_components => {
    for (let i = address_components.length - 1; i >= 0; i--) {
      for (let j = 0; j < address_components[i].types.length; j++) {
        if (
          address_components[i].types[j] === 'postal_town' ||
          address_components[i].types[j] === 'locality' ||
          address_components[i].types[j] === 'sublocality'
        ) {
          return address_components[i].long_name;
        }
      }
    }
    return null;
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (!autocomplete) {
      handleChange({
        placeId: null,
        name: null,
        lat: null,
        lng: null,
        postcode: null,
        city: null,
      });

      return;
    }

    const currPlace = autocomplete.getPlace();

    let postcode = null;
    let city = null;

    if (currPlace.address_components) {
      postcode = getPostcode(currPlace.address_components);
      city = getCity(currPlace.address_components);
    }

    if (currPlace.geometry !== undefined) {
      handleChange({
        placeId: currPlace.place_id,
        name: currPlace.formatted_address || currPlace.name,
        businessName: currPlace.name,
        lat: currPlace.geometry.location.lat(),
        lng: currPlace.geometry.location.lng(),
        postcode,
        city,
      });
    }
  }, [autocomplete, getCity, getPostcode, handleChange]);

  const onLoad = useCallback(autocomplete => {
    setAutocomplete(autocomplete);
  }, []);

  const handleChangeInput = useCallback(() => {
    if (name) {
      handleChange({
        placeId: null,
        lat: null,
        lng: null,
        postcode: null,
      });
    }
  }, [handleChange, name]);

  return (
    <GoogleMapsWrapper>
      <ST.AutocompleteStyled
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        fields={FIELDS}
      >
        <InputStyled
          placeholder={form.placeholders.startTyping}
          onChange={handleChangeInput}
          defaultValue={name}
          name={inputName}
          ref={innerRef}
        />
      </ST.AutocompleteStyled>
    </GoogleMapsWrapper>
  );
};

export default LocationSearchInput;
