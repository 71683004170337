import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import {
  changeOrdersTablePage,
  filters$,
  getUserOrders,
  orders$,
  ordersPagination$,
  setOrdersFilter,
  setOrdersSearchStr,
} from 'models/orders';
import { user$ } from 'models/users';
import { getFormattedStringDate } from 'utils/dates';
import { getDisplayedOrderDates } from 'utils/getDisplayedOrderDates';

import FiltersPanel from 'components/molecules/FiltersPanel';
import NewTable from 'components/molecules/NewTable';

import { TablePaging } from 'ui';

const keyFields = [
  { name: 'id', type: 'link' },
  { name: 'type', type: 'text' },
  { name: 'dateCreated', type: 'text' },
  { name: 'dates', type: 'text' },
  { name: 'status', type: 'text' },
  { name: 'deliveryMethod', type: 'text' },
  { name: 'paymentMethod', type: 'text' },
  { name: 'deposit', type: 'amount' },
  { name: 'grandTotal', type: 'amount' },
  { name: 'link', type: 'link' },
];

const UserOrders = () => {
  const { orders: ordersLocalized, formatString } = Lang();

  const branding = useStore(branding$);
  const user = useStore(user$);
  const orders = useStore(orders$);
  const ordersPagination = useStore(ordersPagination$);
  const ordersFilters = useStore(filters$);

  useEffect(
    () => {
      if (user) {
        getUserOrders(ordersFilters);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const filterOptions = useMemo(
    () => [
      { name: ordersLocalized.statusType.all, slug: DEFAULT_SELECTOR_STATE },
      { name: ordersLocalized.statusType.paid, slug: 'paid' },
      { name: ordersLocalized.statusType.not_paid, slug: 'not_paid' },
      { name: ordersLocalized.statusType.in_progress, slug: 'in_progress' },
      { name: ordersLocalized.statusType.new, slug: 'new' },
      { name: ordersLocalized.statusType.completed, slug: 'completed' },
      { name: ordersLocalized.statusType.cancelled, slug: 'cancelled' },
    ],
    [ordersLocalized],
  );

  const selected = useMemo(() => {
    return (
      filterOptions.find(el => el.slug === ordersFilters.selector)?.name || ''
    );
  }, [filterOptions, ordersFilters]);

  const headers = useMemo(
    () => [
      {
        name: ordersLocalized.orderTableHeaders.orderId,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.orderType,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.dateCreated,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.dates,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.status,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.deliveryMethod,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.paymentMethod,
        type: 'text',
      },
      {
        name: ordersLocalized.orderTableHeaders.deposit,
        type: 'amount',
      },
      {
        name: ordersLocalized.orderTableHeaders.total,
        type: 'amount',
      },
      { name: '' },
    ],
    [ordersLocalized],
  );

  const modifiedDisplayedOrders = useMemo(() => {
    return orders.map(el => {
      return {
        id: (
          <Link to={PATHS.ORDER_PROFILE(user?.id, el.orderId, el.type)}>
            {el.orderId}
          </Link>
        ),
        type: ordersLocalized.orderType[el.type],
        dateCreated: getFormattedStringDate(el.addedOn),
        dates: getDisplayedOrderDates(el.products),
        status: ordersLocalized.statusType[el.status],
        deliveryMethod: ordersLocalized.deliveryMethod[el.deliveryMethod],
        paymentMethod:
          ordersLocalized.paymentType[el.paymentMethod] || el.paymentMethod,
        deposit: el.deposit ? `${branding.currencySymbol}${el.deposit}` : '',
        grandTotal:
          el.type === 'training'
            ? el.total && `${branding.currencySymbol}${el.total}`
            : el.grandTotal && `${branding.currencySymbol}${el.grandTotal}`,
        link: (
          <Link to={PATHS.ORDER_PROFILE(user?.id, el.orderId, el.type)}>
            {ordersLocalized.viewOrder}
          </Link>
        ),
        isActive:
          el.status === 'new' &&
          el.requests?.some(el => el.status === 'pending'),
      };
    });
  }, [branding, orders, ordersLocalized, user]);

  return (
    <>
      <FiltersPanel
        selectOptions={filterOptions}
        selected={selected}
        onClickOption={setOrdersFilter}
        onChangeSearch={setOrdersSearchStr}
        searchStr={ordersFilters.search}
        searchPlaceholder={ordersLocalized.startTypingToFilter}
        foundStr={formatString(
          ordersLocalized.ordersAvailable,
          ordersPagination.total || 0,
        )}
      />
      <NewTable
        headers={headers}
        keyFields={keyFields}
        items={modifiedDisplayedOrders}
        isSortByActive
      />

      {ordersPagination.lastPage > 1 && (
        <TablePaging
          handlePageChange={el => changeOrdersTablePage(el)}
          totalPages={ordersPagination.lastPage}
          currPage={ordersFilters.page}
          isScrollToTop
        />
      )}
    </>
  );
};

export default UserOrders;
