import SmallDatePicker from 'components/atoms/SmallDatePicker';
import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Icon } from 'ui/Icon';

export const LocationIcon = styled(Icon)`
  position: absolute;
  top: 1.5rem;
  left: 2.2rem;

  path {
    fill: ${colors.gray};
  }
`;

export const DateFieldWrapper = styled.div`
  width: 160px;
  margin-right: 20px;
`;

export const DatePickerStyled = styled(SmallDatePicker)`
  border: 1px solid #e4e8ed !important;
  border-radius: 3px;
  height: 44px;
  width: 100%;

  span {
    color: #424242;
    line-height: 44px;
  }
`;

export const DeleteButton = styled.a`
  margin-bottom: 36px;
  &:active,
  &:visited,
  &:link {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: ${colors.gray};

    svg {
      position: relative;
      top: 0.2rem;

      path {
        fill: ${colors.gray};
      }
    }
  }

  &:hover {
    cursor: pointer;
    color: ${colors.primary};

    svg {
      path {
        fill: ${colors.primary};
      }
    }
  }
`;
