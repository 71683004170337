import styled from 'styled-components';

export const SectionWrapper = styled.div`
  margin-bottom: ${props => (!props.subSection ? '30px' : '4px')};
`;

export const ExpandHeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: ${props => (!props.subSection ? '10px' : '0')};
  border-radius: ${props =>
    props.subSection && props.isOpen ? '5px 0' : '5px'};
  cursor: pointer;

  & button {
    width: 100%;
    cursor: pointer;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${props => props.isOpen && '& > svg { transform:rotate(90deg);}'}
  ${props => props.subSection && '& > svg { width:8px; height:12px;}'}
`;

export const HeaderTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  cursor: pointer;
`;

export const ExpandableContentWrapper = styled.div`
  ${p =>
    p.disabled &&
    `
    display: none;
  `}
`;
