export const form = {
  buttons: {
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    close: 'Close',
    continue: 'Continue',
    addMore: 'Add more',
    addSelected: 'Add Selected',
    addCategory: 'Add Category',
    createProduct: 'Create Product',
    update: 'Update',
    create: 'Create',
    createNew: 'Create New',
    saveAndView: 'Save and View',
    save: 'Save',
    publish: 'Publish',
    add: 'Add',
    adding: 'Adding',
    editing: 'Editing',
    edit: 'Edit',
    delete: 'Delete',
    browse: 'Browse',
    logIn: 'Log In',
    createCopy: 'Create a Copy',
    addNewReport: 'Add New Report',
    createNewOrder: 'Create New Order',
    okay: 'Okay',
    addNewDeliveryType: 'Add New Delivery Type',
    addLinkToSelectedText: 'Add link to selected text',
    removeLinkFromSelectedText: 'Remove link from selected text',
    connect: 'Connect',
    deleteId: 'Delete ID',
    addProductId: 'Add product ID',
    logInAsUser: 'Log in as User',
    submit: 'Submit',
  },

  links: {
    addMore: 'Add more',
    createNew: 'Create a new',
    goToAllCategories: 'Go to all categories',
  },

  fields: {
    username: 'Username',
    password: 'Password',
    label: 'Label',
    category: 'Сategory',
    minPeriod: 'Minimum hiring period',
    avgMarketPriceSave: 'Avg Market Price Save',
    subCategory: 'Sub Category',
    subSubCategory: 'Sub Sub Category',
    maxQuantity: 'Max quantity',
    popularity: 'Popularity',
    date: 'Date',
    show: 'Show',
    showAll: 'Show All',
    all: 'All',
    deliveryType: 'Delivery type',
    title: 'Title',
    titleGb: 'Title Gb',
    titleIt: 'Title It',
    sku: 'Sku',
    description: 'Description',
    productUrl: 'Product URL',
    feature: 'Feature',
    rangeInDays: 'Range in Days',
    pricePerDay: 'Price, per day',
    pricePerRange: 'Price, per range',
    outOfRangeRation: 'Out of range ratio (per day), %',
    outOfRangePrice: 'Out of range price, per day',
    individualUser: 'Individual User',
    companyUser: 'Company User',
    feePerDayPerItem: 'Fee per Day per Item',
    feePerItem: 'Fee per Item',
    feePerOrder: 'Fee per Order',
    amountPerItem: 'Amount per Item',
    metaDescription: 'Meta Description',
    metaCanonical: 'Meta Canonical',
    h1Tag: 'H1 Tag',
    robotsTag: 'Robots Tag',
    parentCategory: 'Parent Category',
    deposit: 'Deposit',
    measurements: 'Measurements',
    capacity: 'Capacity',
    weight: 'Weight',
    craneCapacity: 'Crane capacity',
    power: 'Power',
    maxHeight: 'Max height',
    maxWorkingHeight: 'Max working height',
    holeDiameter: 'Hole diameter',
    notes: 'Notes',
    diskDiameter: 'Disk diameter',
    cuttingDepth: 'Cutting depth',
    cuttingWidth: 'Cutting width',
    excavationDepth: 'Excavation depth',
    question: 'Question',
    answer: 'Answer',
    name: 'Name',
    location: 'Location',
    rating: 'Rating',
    review: 'Review',
    productCode: 'Product Code',
    photo: 'Photo',
    price: 'Price',
    tag: 'Tag',
    search: 'Search',
    specification: 'Specification',
    specificationName: 'name',
    specificationVaValue: 'value',
    startTyping: 'Start typing to search to filter reports',
    reportsAreFound: 'reports are found',
    maxLoadCapacity: 'Max Load Capacity, kg',
    startPrice: 'Start Price',
    minPrice: 'Minimum Price',
    pricePerKm: 'Price per 1 km',
    pricePerWay: '*Price per way',
    ReportYear: 'Report Year / Month',
    reportTitle: 'Report title',
    reviewTitle: 'Review Title',
    marginOfDays: 'Margin of days before rental',
    checkoutAvailable: 'Available for checkout',
    checkoutNotAvailable: 'Not available for checkout',
    sectionDescription: 'Section Description (optional)',
    visual: 'Visual',
    productTitle: 'Product Title',
    tax: 'Tax',
    trainingCardDiscountValue: 'Training Card Discount Value',
    drafts: 'Drafts',
    priceWithoutVat: 'Price without VAT',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    emailAddress: 'Email address',
    companyName: 'Company Name',
    vatNumber: 'VAT Number',
    outOfStock: 'Out of Stock',
    unavailable: 'Not Available',
    googleMapLink: 'Google map link',
    openingTimes: 'Opening Times',
    latitude: 'Latitude',
    longitude: 'Longitude',
    perDay: 'Per day',
    perWeek: 'Per week',
  },

  defaultText: {
    selectCategory: 'Select category',
    selectSubCategory: 'Select sub category',
    selectSubSubCategory: 'Select sub sub category',
    selectDeliveryType: 'Select delivery type',
    noParent: 'No parent',
    enterDeliveryTypeTitle: 'Enter delivery type title',
  },

  checkbox: {
    insurance: 'Insurance',
    safetyFee: 'Safety Fee',
    sanitationFee: 'Sanitation Fee',
    retailItem: 'Retail item',
    controlledBy: 'controlled by "fixedRangesPrice" regional setting',
    hideProduct: 'Hide product in catalog',
    showProductVideo: 'Show Product Video',
    gasTank: 'Gas Tank Deposit',
    showCarbon: 'Show Carbon Neutral badge',
    adminFeeFixed: 'Admin Fee Fixed',
    adminFeeVat: 'Admin Fee Vat',
    wasteManagementFee: 'Waste Management Fee',
    deposit: 'Deposit',
    depositToBeDefined: 'Deposit To Be Defined',
    deliveryWithoutVat: 'Delivery Without Vat',
    showAsOutOfStock: 'Show product as Out of Stock',
    idVerification: 'ID verification',
    damageWaiver: 'Damage waiver',
  },

  radio: {
    file: 'File',
  },

  title: {
    loginIn: 'Login in',
  },

  toastTitle: {
    thelastImage: "The last image can't be deleted",
    onlyImagesCan: 'Only {0} images can be added',
    successfullyUpdated: 'Successfully updated',
    successfullyCreated: 'Successfully created',
    successfullyDeleted: 'Successfully deleted',
    successfullyApproved: 'Successfully approved',
    successfullyDeclined: 'Successfully declined',
    successfullyRequest: 'Your request has been sent successfully!',
    createError: 'Create error',
    deleteError: 'Delete error',
    updateError: 'Update error',
    creating: 'Creating',
    updating: 'Updating',
    wasUpdated: 'was updated',
    wasCreated: 'was created',
    extraCantBeCreated: "Extra can't be created",
    onlySeveralFiledCanBeUpdated: 'Only {0} files can be added',
    invalidForm: 'Not all fields have been entered correctly',
    minProducts: 'Job type should have minimum 4 products',
    wentWrong: 'Something went wrong',
  },

  notification: {
    valueShouldBeUnique: 'Value should be unique',
    valueShouldBePositive: 'Value should be a positive number',
    valueShouldBeBetween: 'Value should be between {0} and {1}',
    valueShouldBeValid: 'Value should be a valid url',
    shouldBeInsideOneOfTheRanges:
      'Value should be inside one of the existing ranges',
    theFieldIsRequired: 'The field is required',
    theLastSlideImage: "The last slide image can't be deleted",
    theBiggestRange:
      "The range day can't be more than day from additional prices",
    notAvailableArea: 'Area is not available for delivery',
    notAvailable: 'Is not available',
    loading: 'Loading..',
    notFound: 'Id is not found',
    chooseCorrectAddress: 'Please choose the correct address from the list',
    rangeDaysMustBe: 'Range in days must be 1-7 or 3-7',
    phonePatter: 'Incorrect phone number',
    minLength: 'Min. {0} characters',
    emailPattern: 'Incorrect email',
    passwordLength: 'Password must have at least 6 characters',
    passwordDifferent: 'Passwords are different',
    maxLength: 'Max. {0} characters',
    requiredLength: '{0} symbols are required',
    minValue: 'Min. {0}',
    firstShouldBeLessSecond: 'First value should be less than second',
    valueAlreadyPresents: 'Value has already been presented',
    rangeShouldNotOverlapOthers: 'Range should not overlap other ranges',
    availableOnly:
      'Available only if there is a range ending in “7" is present',
  },

  descriptions: {
    enterVideoUrl: 'Enter Youtube or Vimeo video URL',
    filesAsMax: '{0} files as max',
    areYouSureToDelete: 'Are you sure to delete product {0} with sku {1} ?',
    popularMaxReached: 'Max. limit of popular product reached',
  },

  placeholders: {
    startTyping: 'Start typing...',
    enterProductId: 'Enter product ID and click Connect',
    enterTitle: 'Enter title ({0} characters max)',
  },

  active: 'Active',
  inactive: 'Inactive',
  vat: 'VAT',

  calendar: {
    selectHirePeriod: 'Select Hire Period',
    previous: 'Previous',
    reset: 'Reset to Default',
    next: 'Next',
    dayPlaceholder: 'DD',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'YYYY',
  },

  prevPage: 'Prev Page',
  nextPage: 'Next Page',
  disabledOnRegionalLevel: 'disabled on a regional level',
  extra: 'Extra',
  enterDepotName: 'Enter depot name',
  depotOwner: 'Depot owner',
};
