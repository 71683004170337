import styled from 'styled-components';

export const HeadersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  text-align: center;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-basis: calc(50% + 160px);
`;

export const SubHeader = styled.div`
  max-width: 500px;
  min-width: 300px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;
