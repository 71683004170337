import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

import styled from 'styled-components';

import { Row } from 'ui';

export const StyledCalendarIcon = styled(Row)`
  margin-right: 10px;
`;

export const StyledRangePicker = styled(DateRangePicker)`
  .react-daterange-picker {
    &__wrapper {
      flex-shrink: 1;
      height: 40px;
      padding: 0px 20px 0px 10px;
      color: #2e2e2e;
      background-color: #fff;
      border: 1px solid #dddddd;
      border-radius: 3px;
    }

    &__inputGroup {
      order: 2;
      white-space: nowrap;
    }

    &__range-divider {
      order: 2;
    }
  }

  /*calendar styles*/
  .react-daterange-picker__calendar {
    width: 272px;

    .react-calendar {
      color: #424242;
      line-height: 1.125em;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

      &__navigation {
        height: 44px;
        margin-bottom: 0.5em;
        display: flex;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 44px;
          font-weight: bold;
          font-size: 16px;
        }
      }

      &__month-view {
        &__weekdays {
          font-weight: bold;
          font-size: 0.75em;
          text-align: center;
          text-transform: uppercase;

          &__weekday {
            padding: 0.5em;

            abbr {
              text-decoration: none;
            }
          }
        }

        &__days {
          &__day--neighboringMonth {
            color: #cccccc;
          }
        }
      }

      &__tile {
        max-width: 100%;
        padding: 0.75em 0.5em;
        text-align: center;

        &::disabled {
          background-color: #f0f0f0;
        }
        &:enabled:hover,
        &:enabled:focus {
          background-color: #e6e6e6;
        }

        &--hasActive,
        &--active {
          color: #fff;
          background: #ff6600;

          &:enabled:hover,
          &:enabled:focus {
            color: #fff;
            background: #ff6600;
          }
        }
      }

      &--selectRange .react-calendar__tile--hover {
        background-color: #e6e6e6;
      }

      &__year-view .react-calendar__tile,
      &__decade-view .react-calendar__tile,
      &__century-view .react-calendar__tile {
        padding: 2em 0.5em;
      }

      &__navigation__label__labelText,
      &__year-view__months__month {
        text-transform: capitalize;
      }

      button:enabled:hover {
        cursor: pointer;
      }
    }
  }
`;
