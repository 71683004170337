import React, { Suspense, useEffect, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useDialogState } from 'reakit';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { clearOrders } from 'models/orders';
import {
  clearTradeAccount,
  getTradeAccount,
  tradeAccount$,
} from 'models/tradeAccounts';
import { getQueryParam } from 'utils/getQueryParam';

import CustomButton from 'components/atoms/CustomButton';
import PageHeaderWithBackLink from 'components/atoms/PageHeaderWithBackLink';
import ConfirmLoggInAsModal from 'components/molecules/ConfirmLoggInAsModal';
import Stepper from 'components/molecules/Tabs';
import AccountOrders from 'components/organisms/AccountOrders';
import TradeAccountCompanyEditor from 'components/organisms/TradeAccountCompanyEditor';
import TradeAccountPrivateEditor from 'components/organisms/TradeAccountPrivateEditor';
import UserOrderProfile from 'components/organisms/UserOrderProfile';

import * as ST from './styles';

const TradeAccount = () => {
  const { header, users, orders, form, tradeAccounts } = Lang();

  const tradeAccount = useStore(tradeAccount$);

  const { id, orderId, type } = useParams();
  const dialog = useDialogState();
  const history = useHistory();

  const isNewAccount = id === 'new';
  const isCompany = type === 'companies';

  const orderType = useMemo(
    () => getQueryParam(history.location.search, 'type'),
    [history.location.search],
  );

  useEffect(() => {
    if (id && !isNewAccount) {
      getTradeAccount({ type, id });
    }

    return () => {
      clearTradeAccount();
      clearOrders();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = useMemo(
    () => [
      {
        label: isCompany
          ? tradeAccounts.titles.companyAccountProfile
          : tradeAccounts.titles.privateAccountProfile,
        path: PATHS.TRADE_ACCOUNT_PROFILE(type, id),
      },
      ...(isNewAccount
        ? []
        : [
            {
              label: header.titles.orders,
              path: PATHS.TRADE_ACCOUNT_ORDERS(type, id),
            },
          ]),
      ...(orderId
        ? [
            {
              label: `${orders.order} #${orderId}`,
              path: PATHS.TRADE_ACCOUNT_ORDER_PROFILE(
                type,
                id,
                orderId,
                orderType,
              ),
            },
          ]
        : []),
    ],
    [
      isCompany,
      tradeAccounts,
      type,
      id,
      isNewAccount,
      header,
      orderId,
      orders,
      orderType,
    ],
  );

  const sectionHeader = useMemo(() => {
    let accountName = isNewAccount && 'new';

    if (!accountName) {
      accountName = isCompany
        ? tradeAccount?.name
        : `${tradeAccount?.firstName || ''} ${tradeAccount?.lastName || ''}`;
    }

    return isCompany
      ? `${tradeAccounts.titles.companyAccountProfile} (${accountName})`
      : `${tradeAccounts.titles.privateAccountProfile} (${accountName})`;
  }, [isCompany, isNewAccount, tradeAccount, tradeAccounts]);

  if (!tradeAccount && !isNewAccount) {
    return null;
  }

  return (
    <ST.UserPageWrapper>
      <ST.HeadersWrapper>
        <PageHeaderWithBackLink
          link={PATHS.TRADE_ACCOUNTS(type)}
          linkText={
            isCompany
              ? tradeAccounts.backToCompaniesList
              : users.backToListUsers
          }
          header={sectionHeader}
        />
        {!isNewAccount && (
          <CustomButton
            text={form.buttons.logInAsUser}
            width="22rem"
            onClick={dialog.show}
          />
        )}
      </ST.HeadersWrapper>
      <Stepper steps={steps} marginBottom="20px" />
      <Suspense fallback={<div> Loading ...</div>}>
        <Switch>
          <Route
            path={PATHS.TRADE_ACCOUNT_COMPANY_PROFILE_TEMPLATE}
            component={TradeAccountCompanyEditor}
          />
          <Route
            path={PATHS.TRADE_ACCOUNT_PRIVATE_PROFILE_TEMPLATE}
            component={TradeAccountPrivateEditor}
          />
          <Route
            exact
            path={PATHS.TRADE_ACCOUNT_ORDERS_TEMPLATE}
            component={AccountOrders}
          />
          <Route
            exact
            path={PATHS.TRADE_ACCOUNT_ORDER_PROFILE_TEMPLATE}
            component={UserOrderProfile}
          />
          <Redirect to={PATHS.TRADE_ACCOUNT_PROFILE('private', 'new')} />
        </Switch>
      </Suspense>
      <ConfirmLoggInAsModal dialog={dialog} userId={tradeAccount?.id} />
    </ST.UserPageWrapper>
  );
};

export default TradeAccount;
