import React, { useCallback } from 'react';

import LocationSearchInput from 'components/atoms/LocationSearchInput';

import { Icon } from '../../Product/Icon';
import * as ST from './styles';

const NotCheckoutAvailableLocation = ({
  el,
  register,
  setValue,
  locationIndex,
  errors,
  remove,
  values,
}) => {
  const handleLocationChange = useCallback(
    (index, address) => {
      if (index === 0 && !address.placeId) {
        setValue(`form.noSchedule.locations[${locationIndex}]`, {
          lat: null,
          lng: null,
          placeId: null,
          postcode: null,
        });
      } else {
        setValue(`form.noSchedule.locations[${locationIndex}]`, address);
      }
    },
    [locationIndex, setValue],
  );

  return (
    <>
      <ST.LocationWrapper key={el.id}>
        <input
          type="hidden"
          name={`form.noSchedule.locations[${locationIndex}].placeId`}
          ref={register()}
          value={
            values?.form?.noSchedule?.locations?.[locationIndex]?.placeId ||
            null
          }
          // defaultValue={values?.form?.noSchedule?.locations?.[locationIndex]?.placeId || null}
        />
        <ST.LocationInputWrapper>
          <LocationSearchInput
            handleChange={address =>
              handleLocationChange(locationIndex, address)
            }
            address={el || null}
          />
        </ST.LocationInputWrapper>
        {locationIndex > 0 && (
          <Icon
            type="delete"
            color="gray"
            handleClick={() => remove(locationIndex)}
          />
        )}
      </ST.LocationWrapper>
      <ST.ErrorStyled
        message={
          errors?.form?.noSchedule?.locations?.[locationIndex]?.placeId?.message
        }
      />
    </>
  );
};

export default NotCheckoutAvailableLocation;
