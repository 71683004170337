import React, { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import styled from 'styled-components';

import {
  BlockRounded,
  FieldStyle,
  FieldStyle2,
  Full,
  InputStyled,
  MarginTop,
  More,
  TextareaStyled,
} from './shared';

import { colors } from 'styleGuide';
import { Cell, Grid, Row } from 'ui';
import { Icon } from 'ui/Icon';

import { ReactComponent as Delete } from 'assets/images/icon/delete.svg';

const FaqTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-top: 4.5rem;
  margin-bottom: 2.2rem;
`;

const DeleteIcon = styled(Icon)`
  position: absolute;
  top: 1.3rem;
  right: 1.2rem;

  path {
    fill: ${colors.gray};
  }

  cursor: pointer;
`;

export const Faq = ({ register, control }) => {
  const {
    product: { titles },
    form,
  } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'faqs',
  });

  const addMore = e => {
    e.preventDefault();
    append({ question: '', answer: '' });
  };

  return (
    <Fragment>
      <FaqTitle>{titles.faq}</FaqTitle>

      <Grid as={Full} marginBottom gaps="2rem 2rem" cols="repeat(3, 1fr)">
        {fields.map((faq, index) => (
          <Cell key={faq.id} as={BlockRounded}>
            <FieldStyle as={FieldStyle2} legend={form.fields.question}>
              <InputStyled
                name={`faqs[${index}].question`}
                type="text"
                ref={register()}
                defaultValue={faq.question}
                paddingRight={fields.length > 5 ? '4rem' : '1.5rem'}
              />
              {fields.length > 5 && (
                <DeleteIcon as={Delete} onClick={() => remove(index)} />
              )}
            </FieldStyle>

            <MarginTop as={FieldStyle2} legend={form.fields.answer}>
              <TextareaStyled
                name={`faqs[${index}].answer`}
                height="12rem"
                ref={register()}
                defaultValue={faq.answer}
              />
            </MarginTop>
          </Cell>
        ))}
      </Grid>

      <Row justify="center">
        <More onClick={addMore} />
      </Row>
    </Fragment>
  );
};
