import React, { useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { branding$ } from 'models/branding';
import { clearStrFromText } from 'utils/strings';

import TrainingSeoForm from 'components/molecules/TrainingSeoForm';

const TrainingSeo = ({
  register,
  values = { form: {} },
  isNewCourse,
  setValue,
  watch,
}) => {
  const branding = useStore(branding$);

  const defaultName = useMemo(() => {
    return values.form.title;
  }, [values.form.title]);

  const defaultDescription = useMemo(() => {
    return values.form.description;
  }, [values.form.description]);

  useEffect(() => {
    if (isNewCourse && setValue && defaultDescription) {
      const splittedMeta = defaultDescription
        ? clearStrFromText(defaultDescription).split('.')[0]
        : '';

      setValue('form.metaDescription', splittedMeta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCourse, setValue, defaultDescription]);

  useEffect(() => {
    if (isNewCourse && setValue && defaultName) {
      setValue('form.seoH1', defaultName);

      const title = defaultName || '';
      let companyName = branding?.companyName || '';
      if (companyName) {
        companyName = companyName[0].toUpperCase() + companyName.slice(1);
      }
      setValue('form.metaTitle', `${title} | ${companyName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCourse, setValue, defaultName]);

  return <TrainingSeoForm register={register} watch={watch} />;
};

export default TrainingSeo;
