import styled from 'styled-components';

import { T8y } from 'ui';

export const TitlesWrapper = styled.div`
  display: flex;
`;

export const CourseTitle = styled(T8y)`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const VariationTitle = styled(CourseTitle)`
  width: 280px;
`;
