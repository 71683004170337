import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';
import { handleUploadEnd } from 'utils/filesDownload';

import CourseStatusSection from 'components/atoms/CourseStatusSection';
import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import TrainingHeroImageWrapper from 'components/atoms/TrainingHeroImageWrapper';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import UploadTrainingPicture from 'components/molecules/UploadTrainingPicture';
import { FieldStyle2, InputStyled } from 'components/Product/shared';

import * as ST from './styles';

import { T8y } from 'ui';

const TITLE_MAX_LENGTH = 64;
const SHORT_DESCRIPTION_MAX_LENGTH = 400;
const THEORY_MAX_LENGTH = 64;

const TrainingGeneralInformation = ({
  watch,
  register,
  values,
  getIsError,
  setValue,
  errors,
  deletedFiles,
  setDeletedFiles,
  trigger,
  isNew,
}) => {
  const { formatString, trainings, form } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const [shortDescriptionLength, setShortDescriptionLength] = useState(0);

  useEffect(() => {
    register(
      { name: 'form.mainImage.src' },
      { required: form.notification.theFieldIsRequired },
    );
    register(
      { name: 'form.mainImageThumb.src' },
      { required: form.notification.theFieldIsRequired },
    );
    register('form.shortDescription', {
      required: form.notification.theFieldIsRequired,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const titleRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'form.title',
    TITLE_MAX_LENGTH,
  );

  const shortDescriptionRemainingSymbols = useMemo(() => {
    const currLength = SHORT_DESCRIPTION_MAX_LENGTH - shortDescriptionLength;

    return currLength > 0 ? currLength : 0;
  }, [shortDescriptionLength]);

  const theoryRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'form.theory',
    THEORY_MAX_LENGTH,
  );

  const handleDeleteImage = useCallback(
    (e, type) => {
      e.preventDefault();
      e.stopPropagation();

      setDeletedFiles([...deletedFiles, values.form?.[type]?.id]);

      setValue(`form.${type}.id`, null);
      setValue(`form.${type}.src`, null);
      trigger(`form.${type}.src`);
    },
    [deletedFiles, setDeletedFiles, setValue, trigger, values.form],
  );

  const handleUploaded = useCallback(
    (file, type) => {
      setValue(`form.${type}.src`, file.src);
      trigger(`form.${type}.src`);
    },
    [setValue, trigger],
  );

  const handleUpload = useCallback(
    async (acceptedFiles, type) => {
      await handleUploadEnd({
        acceptedFiles,
        handleUploaded: file => handleUploaded(file, type),
        maxAmountOfFiles: 1,
      });
    },
    [handleUploaded],
  );

  const handleChangeCourseIsActive = useCallback(
    value => {
      setValue('form.status', value);
    },
    [setValue],
  );

  const handleShortDescriptionText = useCallback(
    info => {
      setValue('form.shortDescription', info.text);
      trigger('form.shortDescription');
      setShortDescriptionLength(info.length);
    },
    [setValue, trigger],
  );

  const handleShortDescriptionBeforeInput = useCallback((_, state) => {
    const textLength = state.getCurrentContent().getPlainText().length;

    let selectionTextLength = 0;

    if (window.getSelection) {
      selectionTextLength = window.getSelection().toString().length;
    }

    if (textLength - selectionTextLength >= SHORT_DESCRIPTION_MAX_LENGTH) {
      return 'handled';
    }
  }, []);

  const handleShortDescriptionPastedText = useCallback(
    (text, _, state, setEditorState) => {
      let selectionTextLength = 0;

      if (window.getSelection) {
        selectionTextLength = window.getSelection().toString().length;
      }

      const restCharacters =
        SHORT_DESCRIPTION_MAX_LENGTH -
        (shortDescriptionLength - selectionTextLength);

      const actualText = text.toString().substring(0, restCharacters);

      if (actualText === '') {
        return true;
      }

      const currentContent = state.getCurrentContent();
      const currentSelection = state.getSelection();

      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        actualText,
      );

      const newEditorState = EditorState.push(
        state,
        newContent,
        'insert-characters',
      );

      setEditorState(newEditorState);

      return true;
    },
    [shortDescriptionLength],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.indexPage.generalInformation}
      listOfFields={[
        'title',
        'shortDescription',
        'theory',
        'mainImage.src',
        'mainImageThumb.src',
      ]}
      errors={errors}
      initialIsOpen
    >
      <TrainingFormLine>
        <ST.CourseTitleLineWrapper>
          <ST.CourseTitleWrapper>
            <FieldStyle2 legend={trainings.courseTitle}>
              <ST.CourseTitleInput
                name="form.title"
                width="700px"
                maxLength={TITLE_MAX_LENGTH}
                ref={register({
                  maxLength: TITLE_MAX_LENGTH,
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
                defaultValue={values.form?.title}
                readOnly={!isNew}
              />
            </FieldStyle2>
            <Error message={getIsError('title')} />
            <T8y color="gray" fontSize="12px">
              {formatString(
                trainings.remainingCharacters,
                titleRemainingSymbols,
              )}
            </T8y>
          </ST.CourseTitleWrapper>
          <CourseStatusSection
            defaultValue={trainingLocalized?.status}
            handleChange={handleChangeCourseIsActive}
          />
        </ST.CourseTitleLineWrapper>
      </TrainingFormLine>
      <TrainingFormLine>
        <ST.ShortDescriptionTitle>
          {trainings.courseShortDescription}
        </ST.ShortDescriptionTitle>
        <ReachEditor
          initialValue={trainingLocalized?.shortDescription}
          handleChange={handleShortDescriptionText}
          handleBeforeInput={handleShortDescriptionBeforeInput}
          handlePastedText={handleShortDescriptionPastedText}
        />
        <Error message={getIsError('shortDescription')} />
        <T8y color="gray" fontSize="12px">
          {formatString(
            trainings.remainingCharacters,
            shortDescriptionRemainingSymbols,
          )}
        </T8y>
      </TrainingFormLine>
      <TrainingFormLine>
        <FieldStyle2 legend={trainings.theoryAndPractice}>
          <InputStyled
            name="form.theory"
            width="425px"
            maxLength={THEORY_MAX_LENGTH}
            ref={register({
              maxLength: THEORY_MAX_LENGTH,
              validate: value =>
                !!value?.trim() || form.notification.theFieldIsRequired,
            })}
            defaultValue={values.form?.theory}
          />
        </FieldStyle2>
        <Error message={getIsError('theory')} />
        <T8y color="gray" fontSize="12px">
          {formatString(trainings.remainingCharacters, theoryRemainingSymbols)}
        </T8y>
      </TrainingFormLine>
      <div>
        <T8y fontSize="14px" color="gray" marginBottom="10px">
          {`${trainings.heroImage} (${formatString(
            trainings.heroImageOrientation,
            1680,
            700,
          )})`}
        </T8y>
        <ST.MainImageWrapper>
          {values.form?.mainImage?.src ? (
            <TrainingHeroImageWrapper
              src={values.form.mainImage.src}
              handleDeleteMainImage={e => handleDeleteImage(e, 'mainImage')}
            />
          ) : (
            <UploadTrainingPicture
              handleUploadEnd={acceptedFiles =>
                handleUpload(acceptedFiles, 'mainImage')
              }
            />
          )}
        </ST.MainImageWrapper>
        <Error message={errors?.form?.mainImage?.src?.message} />
      </div>
      <div>
        <T8y fontSize="14px" color="gray" marginBottom="10px">
          {trainings.thumbnailImage} ({trainings.thumbnailImageOrientation})
        </T8y>
        <ST.ThumbImageWrapper>
          {values.form?.mainImageThumb?.src ? (
            <TrainingHeroImageWrapper
              src={values.form.mainImageThumb.src}
              handleDeleteMainImage={e =>
                handleDeleteImage(e, 'mainImageThumb')
              }
              isDisplayThumbnail
            />
          ) : (
            <UploadTrainingPicture
              handleUploadEnd={acceptedFiles =>
                handleUpload(acceptedFiles, 'mainImageThumb')
              }
            />
          )}
        </ST.ThumbImageWrapper>
        <Error message={errors?.form?.mainImageThumb?.src?.message} />
      </div>
    </GeneralExpandableSection>
  );
};

export default TrainingGeneralInformation;
