import styled from 'styled-components';

import { colors } from 'styleGuide';

export const MorePosition = styled.div`
  margin-top: 3rem;
`;

export const LinkContentWrapper = styled.div`
  display: flex;

  ${p =>
    p.disabled &&
    `
    & svg {
      path {
      fill: ${colors.gray2};
    }
  `}
`;
