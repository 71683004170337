import React, { useEffect, useMemo, useState } from 'react';
import { Lang } from 'lang';
import { get } from 'lodash';

import InputWithCurrencySign from 'components/atoms/InputWithCurrencySign';
import { Icon } from 'components/Product/Icon';
import { InputNumberStyled } from 'components/Product/shared';

import * as ST from './styles';

import { Checkbox, Row, T8y } from 'ui';

const DeliveryByRadiusRangeItem = ({
  index,
  item,
  deliveryByRadiusItemRangesPath,
  register,
  errors,
  watch,
  trigger,
  FIELDS_NAMES_LIST,
  setValue,
  handleRemoveIconClick,
  handleRangeChange,
}) => {
  const { settings, form } = Lang();

  const [isRangeError, setIsRangeError] = useState(false);

  const isFirstRange = useMemo(() => index === 0, [index]);

  const watchRanges = watch(deliveryByRadiusItemRangesPath);

  const watchCheckboxes = watch(
    FIELDS_NAMES_LIST.map(
      el => `${deliveryByRadiusItemRangesPath}[${index}].${el.checkboxName}`,
    ),
  );

  const isNotAvailable = useMemo(
    () => !Object.keys(watchCheckboxes)?.some(el => watchCheckboxes[el]),
    [watchCheckboxes],
  );

  useEffect(() => {
    FIELDS_NAMES_LIST.forEach(el => {
      register({
        name: `${deliveryByRadiusItemRangesPath}[${index}].${el.checkboxName}`,
      });
      setValue(
        `${deliveryByRadiusItemRangesPath}[${index}].${el.checkboxName}`,
        item[el.checkboxName],
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ST.RangeRow cols="140px 184px 184px 184px 64px 128px">
      <ST.RangeItem>
        <Row>
          <ST.DistanceRangeInputWrapper>
            <InputNumberStyled
              readOnly
              value={isFirstRange ? 0 : undefined}
              name={`${deliveryByRadiusItemRangesPath}[${index}].distanceFrom`}
              ref={register({
                required: form.notification.theFieldIsRequired,
                validate: {
                  firstShouldBeLessSecond: value => {
                    const isRangeError =
                      watchRanges[index].distanceTo !== '' &&
                      Number(value) >= Number(watchRanges[index].distanceTo);

                    setIsRangeError(isRangeError);

                    return (
                      !isRangeError || form.notification.firstShouldBeLessSecond
                    );
                  },
                },
              })}
              defaultValue={isFirstRange ? undefined : item.distanceFrom}
              type="number"
            />
            {!isRangeError && (
              <ST.ErrorStyled
                message={get(
                  errors,
                  `${deliveryByRadiusItemRangesPath}[${index}].distanceFrom.message`,
                )}
              />
            )}
          </ST.DistanceRangeInputWrapper>
          <ST.RangeInputsSeparator>&nbsp;–&nbsp;</ST.RangeInputsSeparator>
          <ST.DistanceRangeInputWrapper>
            <InputNumberStyled
              name={`${deliveryByRadiusItemRangesPath}[${index}].distanceTo`}
              ref={register({
                required: form.notification.theFieldIsRequired,
                validate: {
                  positive: value => {
                    return value > 0 || form.notification.valueShouldBePositive;
                  },
                },
              })}
              defaultValue={item.distanceTo}
              type="number"
              onChange={e => {
                if (watchRanges[index].distanceFrom !== '') {
                  trigger(
                    `${deliveryByRadiusItemRangesPath}[${index}].distanceFrom`,
                  );
                }

                if (watchRanges[index + 1]) {
                  setValue(
                    `${deliveryByRadiusItemRangesPath}[${index +
                      1}].distanceFrom`,
                    e.target.value,
                  );

                  trigger(
                    `${deliveryByRadiusItemRangesPath}[${index +
                      1}].distanceFrom`,
                  );
                }

                handleRangeChange();
              }}
            />
            {!isRangeError && (
              <ST.ErrorStyled
                message={get(
                  errors,
                  `${deliveryByRadiusItemRangesPath}[${index}].distanceTo.message`,
                )}
              />
            )}
          </ST.DistanceRangeInputWrapper>
        </Row>
        {isRangeError && (
          <ST.ErrorStyled
            message={
              get(
                errors,
                `${deliveryByRadiusItemRangesPath}[${index}].distanceFrom.message`,
              ) || ''
            }
          />
        )}
      </ST.RangeItem>
      {FIELDS_NAMES_LIST.map((el, i) => (
        <ST.RangeItem key={i}>
          <Row align="center">
            <Checkbox
              checked={watchRanges[index][el.checkboxName]}
              onChange={e => {
                setValue(
                  `${deliveryByRadiusItemRangesPath}[${index}].${el.checkboxName}`,
                  e.target.checked,
                );
                handleRangeChange();
              }}
              isBlocked
            />
            <ST.PriceInputWrapper
              readOnly={!watchRanges[index][el.checkboxName]}
            >
              <InputWithCurrencySign
                paddingLeft="30px"
                name={`${deliveryByRadiusItemRangesPath}[${index}].${el.inputName}`}
                innerRef={register({
                  required:
                    watchRanges[index][el.checkboxName] &&
                    form.notification.theFieldIsRequired,
                })}
                defaultValue={item[el.inputName]}
                onChange={handleRangeChange}
                readOnly={!watchRanges[index][el.checkboxName]}
              />
            </ST.PriceInputWrapper>
          </Row>
          <ST.ErrorStyled
            withMarginLeft
            message={get(
              errors,
              `${deliveryByRadiusItemRangesPath}[${index}].${el.inputName}.message`,
            )}
          />
        </ST.RangeItem>
      ))}
      {!isFirstRange && index === watchRanges.length - 1 ? (
        <ST.AdditionalColumWrapper onClick={() => handleRemoveIconClick(index)}>
          <Icon type="delete" color="gray" />
        </ST.AdditionalColumWrapper>
      ) : (
        <div />
      )}
      {isNotAvailable && (
        <T8y as={ST.AdditionalColumWrapper} color="danger" fontSize="12px">
          {settings.thisRangeNotAvailable}
        </T8y>
      )}
    </ST.RangeRow>
  );
};

export default DeliveryByRadiusRangeItem;
