import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { categoriesGate, isLoadingCategories$ } from 'models/categories';
import {
  changeJobTypesTablePage,
  isJobTypesLoading$,
  jobTypesExpanded$,
  jobTypesFilters$,
  jobTypesGate,
  jobTypesPagination$,
} from 'models/jobTypes';
import {
  allProductsWithoutFilters$,
  getAllProductsWithoutFiltersFx,
  isAllProductsWithoutFiltersLoading$,
} from 'pages/model';

import JobTypesFilters from 'components/molecules/JobTypesFilters';
import NewTable from 'components/molecules/NewTable';

import { Row, Spinner, T8y, TablePaging } from 'ui';

const keyFields = [
  { name: 'title', type: 'text', lineClamp: 1 },
  { name: 'products', type: 'text', lineClamp: 1, maxWidth: '420px' },
  { name: 'categories', type: 'text', lineClamp: 1, maxWidth: '420px' },
  { name: 'link', type: 'link', lineClamp: 1 },
];

const JobTypes = () => {
  const { marketing } = Lang();

  const jobTypesFilters = useStore(jobTypesFilters$);
  const isJobTypesLoading = useStore(isJobTypesLoading$);
  const isLoadingProducts = useStore(isAllProductsWithoutFiltersLoading$);
  const isLoadingCategories = useStore(isLoadingCategories$);
  const allProductsWithoutFilters = useStore(allProductsWithoutFilters$);

  useGate(categoriesGate);
  useGate(jobTypesGate, jobTypesFilters);

  const jobTypesPagination = useStore(jobTypesPagination$);
  const jobTypesExpanded = useStore(jobTypesExpanded$);

  useEffect(() => {
    if (!allProductsWithoutFilters.length) {
      getAllProductsWithoutFiltersFx();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = useMemo(
    () => [
      {
        name: marketing.jobTypes.jobTypeTitle,
        type: 'text',
      },
      {
        name: marketing.jobTypes.relatedProducts,
        type: 'text',
      },
      {
        name: marketing.jobTypes.relatedCategories,
        type: 'text',
      },
      { name: '' },
    ],
    [marketing],
  );

  const modifiedDisplayedLeads = useMemo(() => {
    return jobTypesExpanded.map(el => {
      return {
        title: el.title,
        products: el.products.join(', '),
        categories: el.categories.join(', '),
        link: (
          <Link to={PATHS.JOB_TYPE(el.id)}>
            {marketing.jobTypes.viewJobType}
          </Link>
        ),
      };
    });
  }, [jobTypesExpanded, marketing.jobTypes.viewJobType]);

  return (
    <>
      <JobTypesFilters jobTypesAvailable={jobTypesPagination.total} />
      {isJobTypesLoading || isLoadingProducts || isLoadingCategories ? (
        <Row align="center" justify="center">
          <Spinner dark size={3} />
        </Row>
      ) : (
        <>
          {!!jobTypesExpanded.length ? (
            <NewTable
              headers={headers}
              keyFields={keyFields}
              items={modifiedDisplayedLeads}
              isSortByActive
            />
          ) : (
            <T8y align="center" marginTop="20px">
              {marketing.jobTypes.noJobs}
            </T8y>
          )}
          {jobTypesPagination.lastPage > 1 && (
            <Row justify="center">
              <TablePaging
                handlePageChange={changeJobTypesTablePage}
                totalPages={jobTypesPagination.lastPage}
                currPage={jobTypesFilters.page}
                isScrollToTop
              />
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default JobTypes;
