import TimePicker from 'react-time-picker';

import styled from 'styled-components';

import { colors } from 'styleGuide';

export const DatesBlock = styled.div`
  margin-right: 30px;
  margin-bottom: 30px;
`;

export const TillWrapper = styled.div`
  margin: 1px 15px 0;
  color: ${colors.negative};
`;

export const CustomTimePicker = styled(TimePicker)`
  margin-top: -2px;
  margin-bottom: 5px;

  &.react-time-picker--disabled {
    background-color: #fbfbfb;
  }

  .react-time-picker__wrapper {
    display: flex;
    width: 120px;
    display: flex;
    padding: 0 1.5rem;
    border: 1px solid #e4e8ed;
    border-radius: 0.3rem;
    outline: none;
    text-align: left;
    color: #424242;
    font-size: 1.4rem;
  }

  .react-time-picker__inputGroup {
    min-width: 40px;
  }
`;
