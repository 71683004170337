import styled from 'styled-components';

export const PriceKmLineWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ErrorWrapper = styled.div`
  align-self: center;
  max-width: 200px;
`;

export const PriceKmInputWrapper = styled.div`
  max-width: 110px;
  height: 60px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RouteCheckboxesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DeliveryWarehouseName = styled.div`
  width: 150px;
  margin-right: 30px;
  display: inline-block;
  vertical-align: middle;
`;
