import { DEFAULT_SELECTOR_STATE } from 'consts';
import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
} from 'effector';
import { createGate } from 'effector-react';
import { createDebounce } from 'utils/helpers/timeouts';

import { LEADS } from 'api';

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth() + 1;

const defaultFilters = {
  limit: 50,
  page: 1,
  selector: DEFAULT_SELECTOR_STATE,
  search: '',
  dates: {
    start: `${CURRENT_YEAR}-${CURRENT_MONTH}-01`,
    end: `${CURRENT_YEAR}-${CURRENT_MONTH}-${new Date(
      CURRENT_YEAR,
      CURRENT_MONTH,
      '0',
    ).getDate()}`,
  },
  hideTestLeads: true,
  orderBy: 'created_at',
  sortedBy: 'desc',
};

export const loadLeadsFx = createEffect({
  handler: async params => {
    const res = await LEADS.getLeads(params);

    const formattedLeads = res.data.data;

    if (params.isAutoUpdate) {
      const oldState = leads$.getState();

      if (oldState.length) {
        formattedLeads.forEach((el, i) => {
          if (!oldState.some(item => item.id === el.id)) {
            formattedLeads[i].isActive = true;
          }
        });
      }
    }

    const pagination = res.data.meta?.pagination;

    return {
      leads: formattedLeads,
      pagination: {
        lastPage: pagination.total_pages,
        total: pagination.total,
      },
    };
  },
});

export const clearLeads = createEvent();
export const setLeadsFilter = createEvent();
export const setDefaultLeadsFilters = createEvent();
export const setLeadsSearchStr = createEvent();
export const changeSelectedDates = createEvent();
export const changeHideTestLeads = createEvent();
export const changeLeadsTablePage = createEvent();
export const getLeads = createEvent();

export const setLeadsSearchStrDebounced = createDebounce(
  setLeadsSearchStr,
  100,
);

export const leads$ = createStore([])
  .on(loadLeadsFx.doneData, (s, p) => {
    return p.leads;
  })
  .on(clearLeads, () => []);

export const leadsPagination$ = createStore({}).on(
  loadLeadsFx.doneData,
  (s, p) => {
    return p.pagination;
  },
);

export const leadsGate = createGate('leadsGate');

export const filters$ = createStore(defaultFilters)
  .on(setDefaultLeadsFilters, () => ({ ...defaultFilters }))
  .on(changeLeadsTablePage, (s, p) => ({ ...s, page: p }))
  .on(setLeadsSearchStrDebounced, (s, p) => ({ ...s, search: p, page: 1 }))
  .on(setLeadsFilter, (s, p) => ({ ...s, selector: p, page: 1 }))
  .on(changeSelectedDates, (s, p) => ({ ...s, dates: p, page: 1 }))
  .on(changeHideTestLeads, (s, p) => ({ ...s, hideTestLeads: p, page: 1 }));

forward({
  from: filters$.updates,
  to: loadLeadsFx,
});

forward({
  from: getLeads,
  to: loadLeadsFx,
});

guard({
  source: leadsGate.open,
  filter: leads$,
  target: loadLeadsFx,
});
