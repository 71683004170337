import React from 'react';
import { useDialogState } from 'reakit';

import { Lang } from '../../../lang';
import { Icon } from '../Icon';
import { CustomModal } from './CustomModal';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

export const AltTagModal = ({ altTag, itemIndex }) => {
  const dialog = useDialogState();

  const disclosure = <Icon type="edit" color={colors.primary} />;

  const {
    product: { titles },
  } = Lang();

  return (
    <Modal
      disclosure={disclosure}
      title={titles.editAltTags}
      dialogState={dialog}
      width={620}
      backgroundColor={colors.primary}
      lazyRender
    >
      <CustomModal
        dialog={dialog}
        btnText={titles.edit}
        itemIndex={itemIndex}
        altTag={altTag}
      />
    </Modal>
  );
};
