import React, { Fragment } from 'react';
import { useMenuState } from 'reakit';

import * as ST from './styles';

import { ReactComponent as SelectDropdownIcon } from 'assets/icons/select-arrow-down.svg';

const findElementInArrayByKey = (arr, key, value, returnKey) => {
  const item = arr.find(i => i[key] === value);

  return item ? item[returnKey] : null;
};

export const CustomSelect = ({
  defaultText = '',
  selected,
  onChange,
  children,
}) => {
  const menu = useMenuState();
  return (
    <Fragment>
      <ST.CustomSelectSelected {...menu} selected={!!selected}>
        {findElementInArrayByKey(children, 'value', selected, 'title') ??
          defaultText}
        <SelectDropdownIcon />
      </ST.CustomSelectSelected>
      <ST.CustomSelectDropdown {...menu} aria-label={defaultText}>
        {children.map(({ title, value }) => (
          <ST.CustomSelectOption
            {...menu}
            key={value}
            onClick={() => {
              if (onChange) {
                onChange(value);
              }
              menu.hide();
            }}
            selected={selected === value}
          >
            {title}
          </ST.CustomSelectOption>
        ))}
      </ST.CustomSelectDropdown>
    </Fragment>
  );
};
