import styled from 'styled-components';

import { Row } from 'ui';

export const FieldWrapper = styled(Row)`
  cursor: pointer;
  & > *:first-child {
    border-bottom: 0.1rem dashed #2e2e2e;
  }
`;
