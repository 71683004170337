import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { membershipsGate } from 'models/memberships';
import { settings$ } from 'models/settings';
import { tiersGate } from 'models/tiers';
import { getTradeAccount, tradeAccount$ } from 'models/tradeAccounts';
import { website$ } from 'models/website';

import CreditLine from 'components/atoms/CreditLine';
import Error from 'components/atoms/Error';
import LocationSearchInput from 'components/atoms/LocationSearchInput';
import PhoneInputWithCountryInForm from 'components/atoms/PhoneInputWithCountryInForm';
import { PostcoderInput } from 'components/atoms/PostcoderInput';
import Table from 'components/atoms/Table';
import AccountSpecialDiscount from 'components/molecules/AccountSpecialDiscount';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import TradeAccountAddressesInfoBlock from 'components/molecules/TradeAccountAddressesInfoBlock';
import TradeAccountDeposit from 'components/molecules/TradeAccountDeposit';
import TradeAccountMembership from 'components/molecules/TradeAccountMembership';
import TwoField from 'components/molecules/TwoField';
import {
  FieldStyle,
  FieldWithMargin,
  HalfField,
  InputStyled,
} from 'components/Product/shared';
import { TRADE_ACCOUNTS } from 'api';

import { StyledHalfField } from './styles';

import { Grid, Row, T8y } from 'ui';

const keyFields = [
  { name: 'firstName', type: 'text' },
  { name: 'lastName', type: 'text' },
  { name: 'email', type: 'text' },
  { name: 'phone', type: 'text' },
  { name: 'idVerified', type: 'text' },
];

const DEFAULT_USER = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  account: {
    type: 'company',
    identifier: '',
    name: '',
    tradingName: '',
    billingEmail: '',
    depositFree: false,
  },
  billingAddress: '',
  shippingAddress: '',
  membershipLevelId: 1,
  sector_of_activity: null,
  know_about_us: null,
};

const TradeAccountCompanyEditor = () => {
  const { users, form, header, tradeAccounts } = Lang();

  useGate(membershipsGate);
  useGate(tiersGate);

  const settings = useStore(settings$);

  const tradeAccount = useStore(tradeAccount$);
  const website = useStore(website$);

  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  // const [twoCompanyAddress, setTwoCompanyAddress] = useState(null);
  const [isTwoSelect, setIsTwoSelect] = useState(
    settings.twoIntegrationEnabled,
  );
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);

  const isNewAccount = id === 'new';

  const formattedDefaultValues = useMemo(() => {
    if (tradeAccount) {
      const accountOwner =
        tradeAccount.users?.find(el => el.role === 'owner') || {};

      return {
        firstName: accountOwner.firstName,
        lastName: accountOwner.lastName,
        email: accountOwner.email,
        phone: accountOwner.phone,
        addresses: tradeAccount.addresses || [],
        creditLine: tradeAccount.creditLine,
        billingAddress: tradeAccount.addresses.find(el => el.type === 'billing')
          ?.address,
        shippingAddress: tradeAccount.addresses.find(
          el => el.type === 'delivery',
        )?.address,
        account: {
          name: tradeAccount.name,
          tradingName: tradeAccount.tradingName,
          billingEmail: tradeAccount.billingEmail,
          identifier: tradeAccount.identifier,
          depositFree: tradeAccount.depositFree,
          tierId: 1,
        },
      };
    }

    return DEFAULT_USER;
  }, [tradeAccount]);

  const {
    register,
    getValues,
    watch,
    reset,
    setValue,
    errors,
    handleSubmit,
    control,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: formattedDefaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const headers = useMemo(() => {
    return [
      users.tableHeaders.firstName,
      users.tableHeaders.lastName,
      users.tableHeaders.emailAddress,
      users.tableHeaders.phoneNumber,
      users.tableHeaders.idVerification,
    ];
  }, [users]);

  const modifiedDisplayedUsers = useMemo(
    () =>
      tradeAccount?.users?.map(el => ({
        ...el,
        idVerified:
          el.verifications?.find(el => el.provider === 'two')?.status || '-',
      })),
    [tradeAccount],
  );

  useEffect(() => {
    setIsTwoSelect(settings.twoIntegrationEnabled);
  }, [settings]);

  // useEffect(
  //   () => {
  //     if (twoCompanyAddress && !billingAddress) {
  //       const name = `${twoCompanyAddress.street_address ||
  //         ''}, ${twoCompanyAddress.city ||
  //         ''}, ${twoCompanyAddress.postal_code || ''}`;
  //
  //       setValue('billingAddress', name);
  //       setBillingAddress({
  //         name,
  //         postCode: twoCompanyAddress.postal_code,
  //         streetAddress: twoCompanyAddress.street_address,
  //         city: twoCompanyAddress.city,
  //       });
  //     }
  //   }, // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [twoCompanyAddress],
  // );

  const onSubmit = useCallback(
    async formData => {
      try {
        setIsLoading(true);

        for (let key in formData) {
          if (formData[key] === '') {
            formData[key] = null;
          }

          if (key === 'address') {
            for (let key in formData.address) {
              if (formData.address[key] === '') {
                formData.address[key] = null;
              }
            }
          }
        }

        formData.account.type = 'company';
        formData.account.billingPhone = '22222';

        formData.account.idVerified = tradeAccount?.idVerified || false;

        formData.addresses = isNewAccount
          ? [
              ...(billingAddress
                ? [
                    {
                      ...billingAddress,
                      address: billingAddress.name,
                      // state: billingAddress.state || null,
                      addressLine1: billingAddress.addressLine1 || null,
                      addressLine2: billingAddress.addressLine2 || null,
                      postCode: billingAddress.postcode || null,
                      streetNumber: billingAddress.streetNumber || null,
                      googlePlaceId: billingAddress.googlePlaceId || null,
                      postCoderId: billingAddress.postCoderId || null,
                      country: billingAddress.country || null,
                      state: billingAddress.town || null,
                      lat: billingAddress.lat || null,
                      lng: billingAddress.lng || null,
                      type: 'billing',
                    },
                  ]
                : []),
              ...(shippingAddress
                ? [
                    {
                      ...shippingAddress,
                      address: shippingAddress.name,
                      // state: shippingAddress.state || null,
                      addressLine1: shippingAddress.addressLine1 || null,
                      addressLine2: shippingAddress.addressLine2 || null,
                      postCode: shippingAddress.postcode || null,
                      streetNumber: shippingAddress.streetNumber || null,
                      googlePlaceId: shippingAddress.googlePlaceId || null,
                      postCoderId: shippingAddress.postCoderId || null,
                      country: shippingAddress.country || null,
                      state: shippingAddress.town || null,
                      lat: shippingAddress.lat || null,
                      lng: shippingAddress.lng || null,
                      type: 'delivery',
                    },
                  ]
                : []),
            ]
          : tradeAccount.addresses;

        const formattedData = {
          ...formData,
          membershipLevelId: +formData.membershipLevelId,
        };

        if (isNewAccount) {
          await TRADE_ACCOUNTS.createUser(formattedData);
        } else {
          await TRADE_ACCOUNTS.updateTradeAccount(id, formattedData);
        }

        toast.success(form.toastTitle.successfullyCreated);

        if (isNewAccount) {
          history.push(PATHS.TRADE_ACCOUNTS('companies'));
        } else {
          getTradeAccount({ type: tradeAccount.type, id });
        }
      } catch (e) {
        toast.error(
          e.response?.data?.message?.email?.[0] || form.toastTitle.createError,
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      billingAddress,
      form,
      history,
      id,
      isNewAccount,
      shippingAddress,
      tradeAccount,
    ],
  );

  return (
    <>
      <Grid cols="3fr 1.5fr" gaps="0 3rem">
        <div>
          <ProductEditorBlockWrapper blockTitle={users.info.generalInformation}>
            {isNewAccount && isTwoSelect ? (
              <TwoField
                register={register}
                values={values}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
                setIsTwoSelect={setIsTwoSelect}
                setError={setError}
                changeTwoCompanyAddress={() => {}}
                // twoCompanyAddress={twoCompanyAddress}
                disabled={isLoading || !isNewAccount}
              />
            ) : (
              <>
                <StyledHalfField>
                  <Row direction="column" fullWidth>
                    <FieldStyle
                      as={FieldWithMargin}
                      legend={`${users.info.companyName}*`}
                    >
                      <InputStyled
                        name="account.name"
                        ref={register({
                          required: form.notification.theFieldIsRequired,
                        })}
                        readOnly={!isNewAccount}
                        asDisabled={!isNewAccount}
                        disabled={isLoading}
                      />
                    </FieldStyle>
                    <Error message={errors?.account?.name?.message} />
                  </Row>

                  <Row direction="column" fullWidth>
                    <FieldStyle
                      as={FieldWithMargin}
                      legend={`${tradeAccounts.fields.companyNumber}*`}
                    >
                      <InputStyled
                        name="account.identifier"
                        ref={register({
                          required: form.notification.theFieldIsRequired,
                        })}
                        disabled={isLoading || !isNewAccount}
                      />
                    </FieldStyle>
                    <Error message={errors?.account?.identifier?.message} />
                  </Row>
                </StyledHalfField>
                {settings.twoIntegrationEnabled && !isTwoSelect && (
                  <T8y
                    color="primary"
                    onClick={() => {
                      setValue('account.name', null);
                      setIsTwoSelect(true);
                    }}
                    variant="t2v1"
                    cursor="pointer"
                    marginBottom="10px"
                    bold
                    display="inline-block"
                  >
                    Search for company
                  </T8y>
                )}
              </>
            )}

            <HalfField>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={tradeAccounts.fields.tradingName}
                >
                  <InputStyled
                    name="account.tradingName"
                    ref={register}
                    readOnly={!isNewAccount}
                    asDisabled={!isNewAccount}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.account?.tradingName?.message} />
              </Row>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={`${tradeAccounts.fields.billingEmailAddress}*`}
                >
                  <InputStyled
                    name="account.billingEmail"
                    ref={register({
                      required: form.notification.theFieldIsRequired,
                    })}
                    readOnly={!isNewAccount}
                    asDisabled={!isNewAccount}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.account?.billingEmail?.message} />
              </Row>
            </HalfField>
            <HalfField>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={tradeAccounts.fields.billingAddress}
                >
                  {!isNewAccount && (
                    <InputStyled
                      name="billingAddress"
                      ref={register}
                      disabled
                    />
                  )}
                  {isNewAccount && website.shortCompanyName === 'ETH' && (
                    <PostcoderInput
                      placeholder="Start typing your Post Code"
                      name="billingAddress"
                      register={register}
                      values={values}
                      setEmptyAddress={() => {}}
                      changeDeliveryPostcodeAddress={value => {
                        setBillingAddress(value);
                        setValue('billingAddress', value.name);

                        if (!shippingAddress) {
                          setShippingAddress(value);
                          setValue('shippingAddress', value.name);
                        }
                      }}
                      disabled={isLoading}
                    />
                  )}
                  {isNewAccount && website.shortCompanyName !== 'ETH' && (
                    <LocationSearchInput
                      handleChange={address => {
                        setBillingAddress(address);

                        if (shippingAddress) {
                          setShippingAddress(address);
                          setValue('shippingAddress', address.name);
                        }
                      }}
                      address={billingAddress?.address || null}
                      innerRef={register}
                      inputName="billingAddress"
                    />
                  )}
                </FieldStyle>
                <Error message={errors?.billingAddress?.message} />
              </Row>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={`${tradeAccounts.fields.shippingAddress}*`}
                >
                  {!isNewAccount && (
                    <InputStyled
                      name="shippingAddress"
                      ref={register}
                      disabled
                      title={values.billingAddress}
                    />
                  )}
                  {isNewAccount && website.shortCompanyName === 'ETH' && (
                    <PostcoderInput
                      placeholder="Start typing your Post Code"
                      name="shippingAddress"
                      register={register}
                      values={values}
                      setEmptyAddress={() => {}}
                      changeDeliveryPostcodeAddress={value => {
                        setShippingAddress(value);
                        setValue('shippingAddress', value.name);
                      }}
                      disabled={isLoading}
                    />
                  )}
                  {isNewAccount && website.shortCompanyName !== 'ETH' && (
                    <LocationSearchInput
                      handleChange={setShippingAddress}
                      address={shippingAddress?.address || null}
                      innerRef={register}
                      inputName="shippingAddress"
                    />
                  )}
                </FieldStyle>
                <Error message={errors?.shippingAddress?.message} />
              </Row>
            </HalfField>
          </ProductEditorBlockWrapper>
          <ProductEditorBlockWrapper
            blockTitle={tradeAccounts.titles.accountOwner}
          >
            <HalfField>
              <Row direction="column" fullWidth>
                <FieldStyle as={FieldWithMargin} legend={users.info.firstName}>
                  <InputStyled
                    name="firstName"
                    ref={register({
                      required: form.notification.theFieldIsRequired,
                    })}
                    readOnly={!isNewAccount}
                    asDisabled={!isNewAccount}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.firstName?.message} />
              </Row>
              <Row direction="column" fullWidth>
                <FieldStyle as={FieldWithMargin} legend={users.info.lastName}>
                  <InputStyled
                    name="lastName"
                    ref={register({
                      required: form.notification.theFieldIsRequired,
                    })}
                    readOnly={!isNewAccount}
                    asDisabled={!isNewAccount}
                    disabled={isLoading}
                  />
                </FieldStyle>
                <Error message={errors?.lastName?.message} />
              </Row>
            </HalfField>
            <HalfField>
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={users.info.emailAddress}
                >
                  <InputStyled
                    name="email"
                    ref={register({
                      required: form.notification.theFieldIsRequired,
                    })}
                    disabled={isLoading || !isNewAccount}
                  />
                </FieldStyle>
                <Error message={errors?.email?.message} />
              </Row>
              <Row direction="column">
                {isNewAccount ? (
                  <FieldStyle
                    as={FieldWithMargin}
                    legend={users.info.phoneNumber}
                    withBorder
                  >
                    <PhoneInputWithCountryInForm
                      control={control}
                      name="phone"
                    />
                  </FieldStyle>
                ) : (
                  <FieldStyle
                    as={FieldWithMargin}
                    legend={users.info.phoneNumber}
                  >
                    <InputStyled
                      name="phone"
                      ref={register}
                      readOnly
                      asDisabled
                    />
                  </FieldStyle>
                )}
                <Error message={errors?.phone?.message} />
              </Row>
            </HalfField>
          </ProductEditorBlockWrapper>
          {!isNewAccount && (
            <>
              <ProductEditorBlockWrapper blockTitle={header.menu.users}>
                <Table
                  headers={headers}
                  keyFields={keyFields}
                  items={modifiedDisplayedUsers}
                  cols="2fr 2fr 3fr 3fr 2fr"
                />
              </ProductEditorBlockWrapper>
              <TradeAccountAddressesInfoBlock />
            </>
          )}
        </div>
        <div>
          {!isNewAccount && tradeAccount?.credit?.creditApproved && (
            <ProductEditorBlockWrapper blockTitle="Credit Line">
              <CreditLine />
            </ProductEditorBlockWrapper>
          )}

          <TradeAccountMembership
            userMembership={tradeAccount?.membershipLevel}
            control={control}
            values={values}
            isLoading={isLoading}
            setValue={setValue}
          />

          {settings?.showDiscountsSectionInCP && (
            <AccountSpecialDiscount
              userTier={tradeAccount?.tier}
              control={control}
              values={values}
              setValue={setValue}
              isLoading={isLoading}
            />
          )}
          <TradeAccountDeposit control={control} isLoading={isLoading} />
        </div>
      </Grid>
      <AddNewAndSaveCancelFooter
        onSubmit={onSubmit}
        handleReset={reset}
        handleSubmit={handleSubmit}
        submitButtonText={isNewAccount && tradeAccounts.createTA}
      />
    </>
  );
};

export default TradeAccountCompanyEditor;
