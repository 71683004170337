import React, { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { isNil } from 'lodash';
import { isProductLoaded$ } from 'models/product';
import { settings$ } from 'models/settings';
import { getIsPriceRequired } from 'utils/helpers/productForm';

import AddMore from 'components/atoms/AddMore';
import Error from 'components/atoms/Error';
import RangePriceItem from 'components/atoms/RangePriceItem';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import {
  FieldStyle,
  FieldStyle2,
  InputNumberStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { Row } from 'ui';

const Pricing = ({
  control,
  register,
  values,
  errors,
  setError,
  clearErrors,
  isNewProduct,
  trigger,
}) => {
  const {
    product: { titles },
    form,
  } = Lang();

  const settings = useStore(settings$);
  const isProductLoaded = useStore(isProductLoaded$);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices',
  });

  const handleAdd = () => {
    append({ period_from: '', period_to: '', price: '' });
  };

  const pricesWatcher = useWatch({
    control,
    name: 'prices',
  });

  const validateFields = useCallback(
    actualFields => {
      const prices =
        actualFields && actualFields.length
          ? actualFields
          : values && values.prices;

      const staticValues = values && values.prices;

      prices &&
        prices.forEach((item, index) => {
          if (item.period_from && item.period_to) {
            //if second value of any period is same is first value
            const similarIndex =
              staticValues &&
              staticValues.findIndex((el, indexs) => {
                return (
                  indexs !== index &&
                  Number(el.period_to) === Number(item.period_from)
                );
              });
            const isSimilarValueExist =
              !isNil(similarIndex) &&
              similarIndex !== -1 &&
              similarIndex !== index;

            if (isSimilarValueExist) {
              setError(`prices[${index}].period_from`, {
                type: 'manual',
                message: 'Value already presents',
              });
              return;
            }

            //if two similar values presented
            const similarTwoValues =
              staticValues &&
              staticValues.some(
                (el, indexs) =>
                  indexs !== index &&
                  Number(el.period_to) === Number(item.period_to) &&
                  Number(el.period_from) === Number(item.period_from),
              );

            if (similarTwoValues) {
              setError(`prices[${index}].period_from`, {
                type: 'manual',
                message: 'Similar values presents',
              });
              return;
            }

            //if the first value is more than second
            const isLess = Number(item.period_from) <= Number(item.period_to);
            if (!isLess) {
              setError(`prices[${index}].period_from`, {
                type: 'manual',
                message: 'First value should be less than second',
              });
              return;
            }

            if (!isSimilarValueExist && !similarTwoValues && isLess) {
              clearErrors(`prices[${index}].period_from`);
            }
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearErrors, setError, values.prices],
  );

  const checkIsPriceRequired = useCallback(() => {
    if (settings.requiredPricesEnabled) {
      return;
    }

    if (!pricesWatcher.some(el => el.period_from || el.period_to || el.price)) {
      clearErrors('prices');
      trigger('min_period_days');
    }
  }, [clearErrors, pricesWatcher, settings, trigger]);

  useEffect(() => {
    register({ name: 'request' });
  }, [register]);

  useEffect(() => {
    validateFields(pricesWatcher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const isPricesRequired = useMemo(() => {
    if (!isNewProduct && !isProductLoaded) {
      return;
    }

    return getIsPriceRequired(settings.requiredPricesEnabled, values.prices);
  }, [
    isNewProduct,
    isProductLoaded,
    settings.requiredPricesEnabled,
    values.prices,
  ]);

  const biggestRangePossible = useMemo(() => {
    if (!values.additional_prices) {
      return false;
    }

    return values.additional_prices.after_days;
  }, [values.additional_prices]);

  return (
    <ProductEditorBlockWrapper blockTitle={titles.pricing}>
      <Row noWrap>
        <ST.MinRangeWrapper direction="column" justify="start">
          <FieldStyle as={FieldStyle2} legend={form.fields.minPeriod}>
            <InputNumberStyled
              name="min_period_days"
              type="number"
              width="60px"
              ref={register({
                required:
                  isPricesRequired && form.notification.theFieldIsRequired,
                validate: isPricesRequired && {
                  positive: value =>
                    value > 0 || form.notification.valueShouldBePositive,
                },
              })}
            />
          </FieldStyle>
          <Error
            height="16px"
            message={
              errors && errors.min_period_days && errors.min_period_days.message
            }
          />
        </ST.MinRangeWrapper>
      </Row>
      <ST.TitlesWrapper>
        <span>{form.fields.rangeInDays}</span>
        <span>{form.fields.pricePerDay}</span>
      </ST.TitlesWrapper>
      {fields.map((item, index) => {
        return (
          <RangePriceItem
            register={register}
            item={item}
            index={index}
            control={control}
            handleRemove={remove}
            errors={errors}
            key={item.id}
            validateFields={validateFields}
            pricesWatcher={pricesWatcher}
            isPricesRequired={isPricesRequired}
            biggestRangePossible={biggestRangePossible}
            checkIsPriceRequired={checkIsPriceRequired}
          />
        );
      })}
      <AddMore onClick={handleAdd} />
    </ProductEditorBlockWrapper>
  );
};

export default Pricing;
