import { useMemo } from 'react';

export const useFieldWatchWithMaxLength = (
  watch,
  fieldName,
  maxSymbolLength,
) => {
  const fieldWatcher = watch(fieldName);

  return useMemo(() => {
    if (!fieldWatcher) {
      return maxSymbolLength;
    }

    const currLength = maxSymbolLength - fieldWatcher.length;

    return currLength > 0 ? currLength : 0;
  }, [fieldWatcher, maxSymbolLength]);
};
