import Error from 'components/atoms/Error';
import styled from 'styled-components';

export const LocationWrapper = styled.div`
  display: flex;
  width: 459px;
  align-items: center;
  justify-content: space-between;
`;

export const LocationInputWrapper = styled.div`
  width: 415px;
`;

export const ErrorStyled = styled(Error)`
  margin: 7px 0;
`;
