export const analytics = {
  titles: {
    dataStudio: 'DataStudio',
    monthlyReports: 'Reports',
  },

  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
};
