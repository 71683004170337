import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  merge,
  sample,
} from 'effector';
import { createGate } from 'effector-react';
import { categoriesTreeStore } from 'models/categories';

import { filter$ } from 'features/filter';

import { CATREGORIES, PRODUCTS } from 'api';

const loadCategoryFx = createEffect({
  handler: async ({ slug, categories }) => {
    if (slug === 'all-products') {
      let allProducts = [];
      for (const item of categories) {
        const { data } = await CATREGORIES.getCategory(item.slug);
        allProducts = [...allProducts, ...data.data];
      }

      return { slug, data: [...allProducts] };
    }

    const { data } = await CATREGORIES.getCategory(slug);
    return { slug, data: data.data };
  },
});

export const loadCategory = createEvent();

forward({
  from: loadCategory,
  to: loadCategoryFx,
});

export const currentCategoryStore = createStore(null);
export const setCurrentCategory = createEvent();

currentCategoryStore.on(setCurrentCategory, (_, { slug }) => slug);

export const categoryGate = createGate('');
export const productsGate = createGate('');

export const getAllCategoriesFx = createEffect({
  handler: async () => {
    const { data } = await CATREGORIES.getAll();
    return data.data;
  },
});

const getAllProductsFx = createEffect({
  handler: ({ categoriesTreeStore, filter$ }) =>
    Promise.all(
      categoriesTreeStore.map(async category => {
        const params = {
          slug: category.slug,
          orderBy: filter$.sort,
          category_id: filter$.category_id,
          selector: filter$.selector || undefined,
          sortedBy: filter$.order,
          limit: filter$.limit,
          page: filter$.page,
        };
        const { data } = await PRODUCTS.getAll(params);
        return { category, cards: data.data, meta: data.meta };
      }),
    ),
});

const getProductsByCategoryFx = createEffect({
  handler: async ({ filter, gateState, idState, idArrState }) => {
    const params = {
      slug: gateState.params.category,
      all: 1,
      page: gateState.params.page,
      category_id: gateState.category_id,
      sortedBy: filter.order,
      orderBy: filter.sort,
    };

    const isMainCat = [1, 9, 17].includes(idState);
    const idString = idArrState.length && `,${idArrState.join()}`;

    !isMainCat && (params.categories = idState + idString);

    const { data } = isMainCat
      ? await PRODUCTS.getAll(params)
      : await PRODUCTS.getAllCategories(params);
    return { items: data.data, meta: data.meta };
  },
});

export const clearCategories = createEvent();

const render = obj => {
  return obj.filter(item => item?.id)[0];
};
const renderSub = obj => {
  return (
    obj.map(arr => arr && arr.find(item => item?.id)) &&
    obj.map(arr => arr && arr.find(item => item?.id)).find(item => item?.id)
  );
};

export const currentCategory$ = combine(
  categoriesTreeStore,
  categoryGate.state,
  (categories, { params }) => {
    const url = params?.category;

    const sub = categories.find(({ slug }) => slug === url);
    const sub2 = categories.map(item =>
      item?.childs?.data?.find(({ slug }) => slug === url),
    );
    const sub3 = categories.map(item =>
      item?.childs?.data?.map(item =>
        item?.childs?.data?.find(({ slug }) => slug === url),
      ),
    );

    return sub ? sub : render(sub2) ? render(sub2) : renderSub(sub3);
  },
);

const catId$ = currentCategory$.map(store => store && store.id);

const catIds$ = currentCategory$.map(
  store => store && store.childs.map(child => child.id),
);

// init
const categoriesAndFilter$ = combine({
  filter$,
  categoriesTreeStore,
});

guard({
  source: sample(
    categoriesAndFilter$,
    merge([getAllCategoriesFx.done, productsGate.open, filter$.updates]),
  ),
  filter: productsGate.status,
  target: getAllProductsFx,
});

guard({
  source: sample(
    combine({
      filter: filter$,
      gateState: categoryGate.state,
      idState: catId$,
      idArrState: catIds$,
    }),
    merge([
      categoryGate.state.updates,
      categoryGate.open,
      getAllCategoriesFx.done,
    ]),
  ),
  filter: categoryGate.status,
  target: getProductsByCategoryFx,
});
