import styled from 'styled-components';

import { Row } from 'ui';

export const HeaderWrapper = styled.div`
  margin-bottom: 34px;
`;

export const SectionWrapper = styled.div`
  max-width: 1000px;
  margin: auto;
`;

export const ButtonsWrapper = styled(Row)`
  margin-top: 40px;

  button:first-child {
    margin-right: 16px;
  }
`;
