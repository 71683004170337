import styled from 'styled-components';

export const CreditLineWrapper = styled.div`
  display: flex;
`;

export const CreditInfo = styled.div`
  width: calc(50% - 5px);
  padding: 0 4px;
`;
