import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { createGate } from 'effector-react';

import { FAQS } from 'api';

export const faqsGate = createGate('');

export const loadFaqsFx = createEffect({
  handler: async () => {
    const { data } = await FAQS.getAllFaqs();
    return data.data;
  },
});

export const isFaqsLoading$ = restore(loadFaqsFx.pending, false);

export const updateFaqsFx = createEffect({
  handler: async faqs => {
    const { data } = await FAQS.updateFaqs(faqs);
    return data;
  },
});

export const getFaqs = createEvent();
export const updateFaqs = createEvent();

export const faqs$ = createStore([])
  .on(loadFaqsFx.doneData, (_, data) => data)
  .on(updateFaqsFx.doneData, (_, data) => {
    return data.map(el => el.data);
  });

forward({
  from: faqsGate.open,
  to: loadFaqsFx,
});

forward({
  from: updateFaqs,
  to: updateFaqsFx,
});
