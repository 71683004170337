export const categories = {
  titles: {
    manageCategories: 'Manage Categories',
    categoriesFound: 'categories found in catalogue',
    startTyping: 'Start typing to filter categories by title ',
    allCategories: 'All categories',
    archievedCategories: 'Archieved categories',
    visual: 'Visual',
    categoryTitle: 'Category Title',
    products: 'Products',
    createCategory: 'Create category',
    editCategory: 'Edit category',
    categoryVisual: 'Category Visual',
    updating: 'Updating',
    creating: 'Creating',
    show: 'Show',
    selectFilter: 'Select filter',
    deleteSelected: 'Delete Selected',
    deletionConfirmation: 'Deletion confirmation',
    prerenderInfo: 'Prerender information',
    hiddenCategories: 'Hidden categories',
    visibleCategories: 'Visible categories',
  },
  buttons: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  areYouSureYouWantToDeleteCategories:
    'Are you sure you want to delete below Categories:',

  hideCategory: 'Hide category',
  prerenderStatusCode: 'Prerender status code',
  prerenderHeader: 'Prerender header - Location:',
  prerenderStatusNote: `Code ‘301' for permanent redrection. Code '302’ for temporary redirection`,
  contactToCreateCategory:
    'Please contact the dev team to create a new category',
};
