import React, { useCallback, useMemo } from 'react';
import { useGate, useStore } from 'effector-react';
import { useGetProductsListForModal } from 'hooks';
import { Lang } from 'lang';
import {
  changeTrainingRecommendedSearchStr,
  productsGate,
  trainingRecommendedFilteredStore,
  trainingRecommendedSearchStr,
} from 'pages/model';

import ModalWrapper from 'components/molecules/ModalWrapper';

export const TrainingRecommendedProductsModal = ({
  dialog,
  items,
  handleChange,
}) => {
  useGate(productsGate);

  const allAvailableProducts = useStore(trainingRecommendedFilteredStore);
  const searchStr = useStore(trainingRecommendedSearchStr);

  const newProducts = useGetProductsListForModal(allAvailableProducts);

  const onChangeCheckbox = useCallback(
    id => {
      const productIndex = items.findIndex(el => el === id);
      productIndex >= 0 ? handleChange(productIndex) : handleChange(id, 'add');
    },
    [handleChange, items],
  );

  const onSearch = useCallback(
    e => changeTrainingRecommendedSearchStr(e.target.value),
    [],
  );

  const {
    product: { titles },
    form: { fields },
  } = Lang();

  const fieldsForTable = useMemo(
    () => [
      fields.productCode,
      fields.category,
      fields.subCategory,
      fields.subSubCategory,
      fields.photo,
      fields.name,
    ],
    [fields],
  );

  return (
    <ModalWrapper
      title={titles.chooseProduct}
      onChangeCheckbox={onChangeCheckbox}
      searchStr={searchStr}
      onSearch={onSearch}
      fieldsForTable={fieldsForTable}
      newProducts={newProducts}
      cols="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr"
      selected={items}
      needDisclosure={false}
      dialogState={dialog}
      isSelect={true}
    />
  );
};
