import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import {
  getWarehouses,
  isWarehousesLoading$,
  warehouses$,
} from 'models/warehouses';

import BigAddMore from 'components/atoms/BigAddMore';
import DepotForm from 'components/organisms/DepotForm';

import { Spinner } from 'ui';

const DepotsEditor = () => {
  const isWarehousesLoading = useStore(isWarehousesLoading$);
  const warehouses = useStore(warehouses$);

  const [isAddNewDepot, setIsAddNewDepot] = useState(false);

  useEffect(() => {
    getWarehouses();
  }, []);

  if (isWarehousesLoading && !warehouses.length) {
    return <Spinner dark size={5} />;
  }

  return (
    <div>
      {warehouses.map((item, index) => (
        <DepotForm
          key={item.id}
          depot={item}
          isLastDepot={warehouses.length === 1}
          index={index}
        />
      ))}
      {isAddNewDepot && <DepotForm setIsAddNewDepot={setIsAddNewDepot} />}
      {!isAddNewDepot && <BigAddMore onClick={() => setIsAddNewDepot(true)} />}
    </div>
  );
};

export default DepotsEditor;
