import styled from 'styled-components';

export const RouteCheckboxWrapper = styled.div`
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 30px;
  }
`;
