import { createEffect, guard, restore } from 'effector';
import { createGate } from 'effector-react';

import { MEMBERSHIP } from 'api';

export const membershipsGate = createGate('');

const getMembershipsFx = createEffect({
  handler: async () => {
    const { data } = await MEMBERSHIP.getAllMemberships();
    return data || [];
  },
});

export const memberships$ = restore(getMembershipsFx, []);

guard({
  source: membershipsGate.open,
  filter: memberships$,
  target: getMembershipsFx,
});
