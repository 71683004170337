import React from 'react';
import { Lang } from 'lang';

import DropZone from 'components/molecules/DropZone';

import * as ST from './styles';

import { ReactComponent as Upload } from 'assets/images/icon/upload.svg';

export const DropZoneBlock = ({ handleUpload, multiple, accept, disabled }) => {
  const {
    product: { titles },
  } = Lang();

  return (
    <DropZone
      handleUploadEnd={handleUpload}
      multiple={multiple}
      accept={accept}
      disabled={disabled}
    >
      {isDragActive => (
        <ST.DropZoneBlockWrapper direction="column" align="center">
          <Upload />
          {isDragActive ? (
            <p>{titles.dropTheFiles} ...</p>
          ) : (
            <p>
              {titles.dragFileHere} <u>{titles.chooseFromYourComputer}</u>
            </p>
          )}
        </ST.DropZoneBlockWrapper>
      )}
    </DropZone>
  );
};
