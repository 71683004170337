import styled from 'styled-components';

export const DepotConfirmationModalControlsWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;

  &>button: first-child {
    margin-right: 10px;
  }
`;
