import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { website$ } from 'models/website';

function useSettings() {
  const website = useStore(website$);

  return useMemo(() => {
    if (website && website.regionSlug) {
      switch (website.regionSlug) {
        case 'scotland':
          return {
            videoInProductInfo: false,
          };
        default:
          return {
            videoInProductInfo: true,
          };
      }
    }

    return {
      videoInProductInfo: false,
    };
  }, [website]);
}

export default useSettings;
