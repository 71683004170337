export const header = {
  titles: {
    search: 'Buscar',
    logOut: 'Cerrar sesión',
    categories: 'Categorías',
    orders: 'Pedidos',
    userProfile: 'Perfil de usuario',
    products: 'Productos',
    marketing: 'Márketing',
    notification: 'Notificaciones',
    productsFound: 'Productos encontrados',
    sortBy: 'Ordenar por',
    price: 'Precio',
    filter: 'Filtro',
    available: 'Disponible',
    allArchived: 'Todos los archivados',
    addNew: 'Agregar nuevo',
    confirmDeleting: 'Confirmar eliminación',
    export: 'Exportar en CSV',
    productsOnPage: 'Productos en la página',
  },

  table: {
    productCode: 'Código del producto',
    photo: 'Foto',
    Name: 'Nombre',
    price: 'Precio',
    maxQuantity: 'Cantidad máx',
    save: 'Guardar',
    cancel: 'Cancelar',
    preview: 'Previsualizar',
    title: 'Nombre',
  },

  menu: {
    categories: 'Categorías',
    orders: 'Pedidos',
    products: 'Productos',
    marketing: 'Márketing',
    analytics: 'Analíticas',
    users: 'Usuarios',
    logOut: 'Cerrar sesión',
    settings: 'Configuración',
    trainings: 'Formación',
    offers: 'Ofertas',
    leads: 'Leads Web',
    tradeAccounts: 'Trade Accounts',
  },
};
