import React, { useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';

import { Icon } from 'components/Product/Icon';

import * as ST from './styles';

import { Cell, Grid, T8y } from 'ui';

import { ReactComponent as DrugDrop } from 'assets/icons/drugDrop.svg';

const ChosenProductsItem = ({
  currentId,
  productId,
  arrayName,
  index,
  register,
  remove,
  allProductsList,
  isDraggable,
  provided = { draggableProps: {}, dragHandleProps: {} },
}) => {
  const { form } = Lang();

  const branding = useStore(branding$);

  const product = useMemo(() => allProductsList.find(p => p.id === productId), [
    productId,
    allProductsList,
  ]);

  const isPrices = useMemo(() => {
    if (!product) {
      return false;
    }
    return product.prices && product.prices.data && product.prices.data.length;
  }, [product]);

  const handleRemove = useCallback(() => {
    remove(currentId);
  }, [currentId, remove]);

  if (!product) {
    return null;
  }

  return (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      {provided.placeholder}
      <input
        type="hidden"
        name={`${arrayName}[${index}]`}
        value={currentId}
        ref={register}
      />
      <Grid
        places="center start"
        cols={`${isDraggable ? '1fr ' : ''}2fr 2fr 3fr 2fr 1fr`}
        gaps="0 1rem"
        as={ST.TableHeader}
        key={index}
        marginTop="3rem"
      >
        {isDraggable && (
          <ST.DrugDropIconWrapper>
            <div {...provided.dragHandleProps}>
              <DrugDrop />
            </div>
          </ST.DrugDropIconWrapper>
        )}
        <ST.TableBodyCell>{product.sku}</ST.TableBodyCell>
        <ST.TableBodyCell>
          <ST.TableImage
            src={
              product.image
                ? product.image.data?.thumb
                : branding?.defaultImageProduct
            }
          />
        </ST.TableBodyCell>

        <ST.TableBodyCell>
          {product.name}
          {product.isOutOfStock && (
            <T8y as="span" fontSize="14px" color="danger" bold>
              {' '}
              ({form.fields.outOfStock})
            </T8y>
          )}
        </ST.TableBodyCell>
        <ST.TableBodyCell place="center end">
          {branding.currencySymbol}
          {isPrices ? product.prices.data[0].price.toFixed(2) : 0}
        </ST.TableBodyCell>
        <Cell place="center center">
          <Icon type="delete" handleClick={handleRemove} />
        </Cell>
      </Grid>
    </div>
  );
};

export default ChosenProductsItem;
