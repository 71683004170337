import { useCallback, useEffect, useMemo, useState } from 'react';
import FuzzySearch from 'fuzzy-search';

export const usePopularModalFilterAndSearch = allItems => {
  const [searchStr, setSearchStr] = useState('');
  const [searchCategoryId, setSearchCategoryId] = useState(null);
  const [filteredItems, setFilteredItems] = useState(allItems);

  const popularSearcher = useCallback(
    state => new FuzzySearch(state, ['sku', 'name', 'slug'], { sort: true }),
    [],
  );

  useEffect(() => {
    const e2 =
      searchCategoryId && searchCategoryId > 0
        ? allItems.filter(i => i.category_id === searchCategoryId)
        : allItems;
    const newItems =
      searchStr.length < 3 ? e2 : popularSearcher(e2).search(searchStr);

    setFilteredItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, searchCategoryId, searchStr]);

  return useMemo(
    () => ({
      filteredItems,
      searchStr,
      setSearchStr,
      setSearchCategoryId,
    }),
    [filteredItems, searchStr],
  );
};
