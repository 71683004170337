import React from 'react';
import { Lang } from 'lang';

import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from 'components/Product/shared';

import * as ST from './styles';

const TrainingFormFaq = ({ item, index, register, getIsError, watch }) => {
  const { trainings, form } = Lang();

  const watchSectionShow = watch('form.faqShow');

  return (
    <ST.FaqFormWrapper justify="stretch">
      <ST.FieldsWrapper direction="column">
        <FieldStyle as={FieldStyle2} legend={trainings.faq.question}>
          <InputStyled
            ref={register({
              validate: watchSectionShow
                ? value => {
                    return (
                      !!value.trim() || form.notification.theFieldIsRequired
                    );
                  }
                : undefined,
            })}
            name={`form.faqs[${index}].question`}
            defaultValue={item.question}
            type="text"
          />
        </FieldStyle>
        <ST.ErrorStyled message={getIsError('question', index)} />
        <FieldStyle as={FieldStyle2} legend={trainings.faq.answer}>
          <TextareaStyled
            ref={register({
              validate: watchSectionShow
                ? value => {
                    return (
                      !!value.trim() || form.notification.theFieldIsRequired
                    );
                  }
                : undefined,
            })}
            name={`form.faqs[${index}].answer`}
            defaultValue={item.answer}
            height="auto"
            rows="3"
          />
        </FieldStyle>
        <ST.ErrorStyled message={getIsError('answer', index)} />
      </ST.FieldsWrapper>
    </ST.FaqFormWrapper>
  );
};

export default TrainingFormFaq;
