import { MAX_GUIDES_COUNT, MAX_IMAGES_COUNT } from 'consts';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import FuzzySearch from 'fuzzy-search';
import { isNil } from 'lodash';
import { createDebounce } from 'utils/helpers/searchDebounce';

import { FILES, MEDIA, PRODUCTS } from 'api';

const loadRequiredExtraFx = createEffect({
  handler: async () => {
    const { data } = await PRODUCTS.getRequiredExtras();
    return data.data;
  },
});

export const allRequiredExtraGate = createGate('');
export const allRequiredExtraStore = restore(loadRequiredExtraFx, []);
const isIdleRequired = loadRequiredExtraFx.pending.map(state => state);

guard({
  source: allRequiredExtraGate.open,
  filter: combine(
    isIdleRequired,
    allRequiredExtraStore,
    (isLoading, requiredExtra) => !isLoading && !requiredExtra.length,
  ),
  target: loadRequiredExtraFx,
});

export const reloadRequiredExtra = createEvent();

forward({
  from: reloadRequiredExtra,
  to: loadRequiredExtraFx,
});

const loadOptionalExtraFx = createEffect({
  handler: async () => {
    const { data } = await PRODUCTS.getOptionalExtras();
    return data.data;
  },
});

export const allOptionalExtraGate = createGate('');
export const allOptionalExtraStore = restore(loadOptionalExtraFx, []);
const isIdleOptional = loadOptionalExtraFx.pending.map(state => state);

guard({
  source: allOptionalExtraGate.open,
  filter: combine(
    isIdleOptional,
    allOptionalExtraStore,
    (isLoading, optionalExtra) => !isLoading && !optionalExtra.length,
  ),
  target: loadOptionalExtraFx,
});

export const reloadOptionalExtra = createEvent();

forward({
  from: reloadOptionalExtra,
  to: loadOptionalExtraFx,
});
export const loadProductFx = createEffect({
  handler: async id => {
    const { data } = await PRODUCTS.loadProduct(id);
    if (data.data?.prices?.data) {
      data.data.prices.data.sort(
        (a, b) => Number(a.period_from) - Number(b.period_from),
      );
    }
    return data.data;
  },
});

export const loadProductGate = createGate('');
export const productStore = restore(loadProductFx, {
  name: null,
  slug: null,
  prices: { data: [{ period_from: '', period_to: '', price: '' }] },
  integrations: [],
});

export const isLoadingProduct$ = loadProductFx.pending.map(state => !!state);

export const changeProductAttribute = createEvent();
export const clearProductStore = createEvent();

productStore.reset(clearProductStore);

productStore.on(changeProductAttribute, (state, { index, value }) => {
  if (state && state.attributes) {
    const new_attributes = [...state.attributes];
    new_attributes[index].value = value;

    return { ...state, attributes: new_attributes };
  }

  return state;
});

export const isProductLoaded$ = createStore(false).on(
  loadProductFx.doneData,
  _ => true,
);

guard({
  source: loadProductGate.open,
  filter: id => !isNil(id),
  target: loadProductFx,
});

export const copiedProductIdStore = createStore(null);
export const copyProduct = createEvent();
export const clearCopiedProductIdStore = createEvent();

copiedProductIdStore
  .on(copyProduct, (state, id) => {
    return id || state;
  })
  .on(clearCopiedProductIdStore, (s, p) => null);

export const copiedProductStore = combine(
  copiedProductIdStore,
  productStore,
  (id, product) => {
    if (!id) {
      return product;
    }
    product.name = '';
    product.sku = '';
    product.slug = '';
    return product;
  },
);

export const additionalFieldsGate = createGate('');
export const clearAdditionalFields = createEvent();
guard({
  source: additionalFieldsGate.close,
  filter: () => true,
  target: clearAdditionalFields,
});

const addElement = (state, dummy) => [...state, dummy];
const repeatElement = (dummy, len) => {
  let result = [];
  for (let i = 0; i < len; i++) {
    result.push(dummy);
  }

  return result;
};
const removeElement = (state, index) => {
  const newState = [...state];
  newState.splice(index, 1);

  return newState;
};
const changeElement = (state, index, key, value) => {
  return state.map((item, i) => ({
    ...item,
    [key]: i === index ? value : item[key],
  }));
};

const typicalUseDummy = { value: '' };
export const typicalUseStore = createStore(repeatElement(typicalUseDummy, 4));
export const addTypicalUse = createEvent();
export const removeTypicalUse = createEvent();
export const changeTypicalUse = createEvent();

typicalUseStore
  .on(addTypicalUse, state => addElement(state, typicalUseDummy))
  .on(removeTypicalUse, (state, { index }) => removeElement(state, index))
  .on(changeTypicalUse, (state, { index, value }) =>
    changeElement(state, index, 'value', value),
  )
  .on(loadProductFx.doneData, (_, product) => {
    const typical_uses = product.attributes
      .filter(attribute => attribute.name === 'typical use')
      .map(attribute => ({ value: attribute.value }));
    const dummy_typical_uses = repeatElement(
      typicalUseDummy,
      4 - typical_uses.length,
    );

    return [...typical_uses, ...dummy_typical_uses];
  })
  .on(clearAdditionalFields, () => repeatElement(typicalUseDummy, 4));

export const currentEventTypeStore = createStore(null);
export const setCurrentEventType = createEvent();

currentEventTypeStore
  .on(setCurrentEventType, (_, payload) => payload)
  .on(loadProductFx.doneData, (state, product) => {
    const eventTypeObj = product.attributes.find(
      attribute => attribute.name === 'package',
    );

    return eventTypeObj ? eventTypeObj.value : state;
  })
  .on(clearAdditionalFields, () => null);

export const eventsTypesGate = createGate('');
export const getEventsTypesFx = createEffect({
  handler: async () => {
    const { data } = await PRODUCTS.getEventsTypes();
    return data.data;
  },
});
export const eventsTypesStore = restore(getEventsTypesFx, []);

export const returnDirtyStore = createStore(false);
export const setReturnDirty = createEvent();

returnDirtyStore
  .on(setReturnDirty, (_, value) => value)
  .on(loadProductFx.doneData, (state, product) => {
    // const field = product.attributes.find(
    //   attribute => attribute.name === 'return dirty' && attribute.value === 'Y',
    // );

    // return field ? true : state;
    return state;
  })
  .on(clearAdditionalFields, () => false);

export const slightDamageStore = createStore(false);
export const setSlightDamage = createEvent();

slightDamageStore
  .on(setSlightDamage, (_, value) => value)
  .on(loadProductFx.doneData, (state, product) => {
    // const field = product.attributes.find(
    //   attribute =>
    //     attribute.name === 'slight Damage Waiver fee' &&
    //     attribute.value === 'Y',
    // );
    //
    // return field ? true : state;
    return state;
  })
  .on(clearAdditionalFields, () => false);

guard({
  source: eventsTypesGate.open,
  filter: () => true,
  target: getEventsTypesFx,
});

export const relatedProductsStore = createStore([]);
export const addRelatedProduct = createEvent();
export const removeRelatedProduct = createEvent();

relatedProductsStore
  .on(addRelatedProduct, (state, id) => [...state, id])
  .on(removeRelatedProduct, (state, id) => {
    const newState = [...state];
    const index = newState.findIndex(i => i === id);
    newState.splice(index, 1);

    return newState;
  })
  .on(loadProductFx.doneData, (_, product) => {
    if (product.relatedProducts) {
      return product.relatedProducts.map(item => item.id);
    }

    return [];
  })
  .on(clearAdditionalFields, () => []);

export const variationsStore = createStore([]);
export const addVariation = createEvent();
export const removeVariation = createEvent();

variationsStore
  .on(addVariation, (state, id) => [...state, id])
  .on(removeVariation, (state, id) => {
    const newState = [...state];
    const index = newState.findIndex(variationId => variationId === id);
    newState.splice(index, 1);

    return newState;
  })
  .on(loadProductFx.doneData, (_, product) => {
    if (product.variation_products) {
      return product.variation_products.map(item => item.id);
    }

    return [];
  })
  .on(clearAdditionalFields, () => []);

const optionalExtraSearcher = state =>
  new FuzzySearch(state, ['sku', 'value'], { sort: true });
export const changeOptionalExtraSearchStr = createEvent();
export const optionalExtraSearchStr = restore(changeOptionalExtraSearchStr, '');
const changeOptionalExtraSearchStrDebounced = createDebounce(
  changeOptionalExtraSearchStr,
  100,
);
const optionalExtraSearchStrDebounced = restore(
  changeOptionalExtraSearchStrDebounced,
  '',
);
export const allOptionalExtraFilteredStore = combine(
  allOptionalExtraStore,
  optionalExtraSearchStrDebounced,
  (e, s) => {
    if (!s.length) {
      return e;
    }

    return optionalExtraSearcher(e).search(s);
  },
);

const requiredExtraSearcher = state =>
  new FuzzySearch(state, ['sku', 'value'], { sort: true });
export const changeRequiredExtraSearchStr = createEvent();
export const requiredExtraSearchStr = restore(changeRequiredExtraSearchStr, '');
const changeRequiredExtraSearchStrDebounced = createDebounce(
  changeRequiredExtraSearchStr,
  100,
);
const requiredExtraSearchStrDebounced = restore(
  changeRequiredExtraSearchStrDebounced,
  '',
);
export const allRequiredExtraFilteredStore = combine(
  allRequiredExtraStore,
  requiredExtraSearchStrDebounced,
  (e, s) => {
    if (!s.length) {
      return e;
    }

    return requiredExtraSearcher(e).search(s);
  },
);

export const uploadPhotoFx = createEffect({
  handler: async fileData => {
    const { data } = await FILES.attachUploaded(fileData);
    return data.data;
  },
});
export const changeOnMainTypeFx = createEffect({
  handler: async id => {
    const { data } = await MEDIA.changeOnMainType(id);
    return data.data;
  },
});
export const deleteUploadedPhotoFx = createEffect({
  handler: async id => {
    await MEDIA.deleteUploaded(id);
  },
});

export const deleteUploadedFileFx = createEffect({
  handler: async id => {
    await FILES.deleteUploaded(id);
  },
});

const dummyPhoto = { value: null, id: null, alt_tag: null };
export const photosStore = createStore(
  repeatElement(dummyPhoto, MAX_IMAGES_COUNT),
);
export const removePhoto = createEvent();
export const addPhoto = createEvent();
export const editTag = createEvent();
export const setMainImage = createEvent();

photosStore
  .on(loadProductFx.doneData, (_, product) => {
    if (product.gallery?.data) {
      const photos = product.gallery.data.map(el => ({
        ...el,
        value: el.main,
        id: el.id || null,
      }));
      const dummyPhotos = repeatElement(
        dummyPhoto,
        MAX_IMAGES_COUNT - photos.length,
      );

      return [...photos, ...dummyPhotos];
    }

    return repeatElement(dummyPhoto, MAX_IMAGES_COUNT);
  })
  .on(removePhoto, (state, index) => {
    let result = [...state];
    result.splice(index, 1);
    const dummyPhotos = repeatElement(
      dummyPhoto,
      MAX_IMAGES_COUNT - result.length,
    );

    return [...result, ...dummyPhotos];
  })
  .on(addPhoto, (state, newPhoto) => {
    const currPhotos = [...state];
    const emptySlot = currPhotos.findIndex(el => !el.value);
    currPhotos[emptySlot] = newPhoto;
    return [...currPhotos];
  })
  .on(editTag, (state, { alt_tag, itemIndex }) => {
    const currPhotos = [...state];
    currPhotos[itemIndex].alt_tag = alt_tag;
    return [...currPhotos];
  })
  .on(setMainImage, (state, { itemIndex }) => {
    const currPhotos = [...state].map(el => ({ ...el, isMain: false }));
    currPhotos[itemIndex].isMain = true;
    return [...currPhotos];
  })
  .on(clearAdditionalFields, () => repeatElement(dummyPhoto, MAX_IMAGES_COUNT));

export const deletedMediaAndFilesStore = createStore([]);
export const addDeletedMediaAndFiles = createEvent();

deletedMediaAndFilesStore
  .on(addDeletedMediaAndFiles, (state, value) => [...state, value])
  .on(clearAdditionalFields, () => []);

export const guidesStore = createStore(
  repeatElement(dummyPhoto, MAX_GUIDES_COUNT),
);

export const removeGuide = createEvent();
export const addGuide = createEvent();

guidesStore
  .on(loadProductFx.doneData, (_, product) => {
    if (product && product.guides && product.guides.data) {
      const guides = product.guides.data.map(i => ({
        value: i.main,
        name: i.name,
        id: i.id || null,
      }));
      const dummyGuides = repeatElement(
        dummyPhoto,
        MAX_GUIDES_COUNT - guides.length,
      );

      return [...guides, ...dummyGuides];
    }

    return repeatElement(dummyPhoto, MAX_GUIDES_COUNT);
  })
  .on(removeGuide, (state, index) => {
    let result = [...state];
    result.splice(index, 1);
    const dummyPhotos = repeatElement(
      dummyPhoto,
      MAX_GUIDES_COUNT - result.length,
    );

    return [...result, ...dummyPhotos];
  })
  .on(addGuide, (state, newPhoto) => {
    const currGuides = [...state];
    const emptySlot = currGuides.findIndex(el => !el.value);
    currGuides[emptySlot] = newPhoto;
    return [...currGuides];
  })
  .on(clearAdditionalFields, () => repeatElement(dummyPhoto, MAX_GUIDES_COUNT));
