import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';
import { Button, T8y } from 'ui';

export const ListCategories = styled(T8y)`
  max-height: calc(80vh - 214px);
  overflow-y: auto;
`;

export const ModalButton = styled(Button)`
  color: #666;
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  background: #fff;

  &:first-child {
    width: 120px;
    margin-right: 8px;
  }
  &:last-child {
    width: 140px;
    margin-right: 8px;
    border-color: #f89739;
  }
`;

export const DeleteButton = styled.button`
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 3.3rem 0 0;
  font-family: ${fonts.nunitoSans};
  font-weight: 600;
  font-size: 1.6rem;
  color: #999999;
  outline: none;
  transition: color 0.5s ease;

  &:hover {
    cursor: pointer;
    color: #999999;
  }
`;
