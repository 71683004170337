import styled from 'styled-components';

import { colors } from 'styleGuide';

export const OrderInfoWrapper = styled.div`
  margin-bottom: 30px;
  padding: 20px 30px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: ${colors.positive};
`;
