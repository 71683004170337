import React, { useEffect, useRef, useState } from 'react';
import LocalizedStrings from 'react-localization';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { settings$ } from 'models/settings';

import { currentLang$, selectLang } from './languageModel';

import { ReactComponent as ES } from 'assets/images/icon/ES.svg';
import { ReactComponent as GB } from 'assets/images/icon/GB.svg';
import { ReactComponent as IT } from 'assets/images/icon/IT.svg';
import { ReactComponent as PT } from 'assets/images/icon/PT.svg';

import style from './selectLanguage.module.scss';

//This is a helper function. It can be moved to another location.
const setLanguageFlag = flag => {
  switch (flag) {
    case 'en-gb':
      return <GB />;
    case 'it':
      return <IT />;
    case 'es':
      return <ES />;
    case 'pt':
      return <PT />;
    default:
      return;
  }
};

export const SelectLanguage = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const wrapperRef = useRef(null);

  const settings = useStore(settings$);

  useEffect(() => {
    selectLang(settings?.defaultLanguage);
  }, [settings]);

  let strings = new LocalizedStrings({
    'en-gb': {},
    it: {},
  });

  const currentLangState = useStore(currentLang$);

  strings.setLanguage(currentLangState);

  const useOutsideAlerter = ref => {
    const handleClickOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpenMenu(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  };

  useOutsideAlerter(wrapperRef);

  const handleChangeLanguage = flag => {
    selectLang(flag);
    setOpenMenu(false);
  };

  return (
    <div className={style.wrapper} ref={wrapperRef}>
      <div onClick={() => setOpenMenu(!openMenu)}>
        {setLanguageFlag(currentLangState)}
      </div>
      {openMenu && (
        <div className={clsx(style.country, openMenu ? style.open : null)}>
          {Object.values(settings.supportedLanguages)
            .filter(el => el !== currentLangState)
            .map(el => (
              <div
                key={el}
                className={clsx(style.flag)}
                title={el}
                onClick={() => handleChangeLanguage(el)}
              >
                {setLanguageFlag(el)}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
