import { createEffect, guard, restore } from 'effector';
import { createGate } from 'effector-react';

import { TIERS } from 'api';

export const tiersGate = createGate('');

const getTiersFx = createEffect({
  handler: async () => {
    const { data } = await TIERS.getAllTiers();
    data.data.sort((a, b) => a.discount - b.discount);
    return data.data || [];
  },
});

export const tiers$ = restore(getTiersFx, []);

guard({
  source: tiersGate.open,
  filter: tiers$,
  target: getTiersFx,
});
