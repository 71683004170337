import React, { useCallback, useEffect, useMemo } from 'react';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { website$ } from 'models/website';
import {
  changePlatformProductsSearchStr,
  getPlatformProductsFx,
  platformProductsFilteredStore,
  platformProductsSearchStr,
  platformProductsStoreLocalized,
} from 'pages/model';

import ChosenProductsList from 'components/molecules/ChosenProductsList';
import {
  OpenModalButton,
  ProductsModal,
} from 'components/molecules/VariationalsModal';

import { SectionWrapper, Title } from './styles';

import { colors } from 'styleGuide';

import { ReactComponent as Edit } from 'assets/images/icon/edit.svg';

export const PlatformProduct = ({ register, setValue, platformProductId }) => {
  const {
    product: { titles },
  } = Lang();

  const dialog = useDialogState();
  const filteredItems = useStore(platformProductsFilteredStore);
  const searchStr = useStore(platformProductsSearchStr);
  const platformProducts = useStore(platformProductsStoreLocalized);
  const webSite = useStore(website$);

  useEffect(() => {
    register('foreign_id');
  }, [register]);

  const handleSearch = useCallback(
    e => changePlatformProductsSearchStr(e.target.value),
    [],
  );

  const handleRemove = useCallback(() => {
    setValue('foreign_id', '', {
      shouldDirty: true,
    });
  }, [setValue]);

  const handleChange = useCallback(
    id => {
      setValue('foreign_id', id, {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const chosenProductsIdsList = useMemo(
    () =>
      platformProductId
        ? [
            {
              id: platformProductId,
              productId: platformProductId,
              orderId: platformProductId,
            },
          ]
        : [],
    [platformProductId],
  );

  const currentItems = useMemo(
    () => (platformProductId ? [platformProductId] : []),
    [platformProductId],
  );

  useEffect(
    () => {
      if (!platformProducts.length && (dialog.visible || platformProductId)) {
        getPlatformProductsFx(webSite.country);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog.visible, platformProductId],
  );

  return (
    <>
      <ProductsModal
        dialog={dialog}
        handleAdd={handleChange}
        handleRemove={handleRemove}
        currentItems={currentItems}
        searchStr={searchStr}
        filteredItems={filteredItems}
        handleSearch={handleSearch}
      />

      <Title>{titles.platformProduct}</Title>
      <SectionWrapper flexDirection={platformProductId ? 'row' : 'column'}>
        {!platformProductId && (
          <OpenModalButton
            customButtonText={titles.linkProductWithPlatform}
            icon={Edit}
            linkColor={colors.primary}
            inverseUnderline={true}
            dialog={dialog}
          />
        )}
        <ChosenProductsList
          allProductsList={platformProducts}
          arrayName="platformProduct"
          chosenProductsIdsList={chosenProductsIdsList}
          register={register}
          remove={handleRemove}
        />
      </SectionWrapper>
    </>
  );
};
