import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDialogState } from 'reakit';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { getDeliveries } from 'models/deliveries';
import { settings$ } from 'models/settings';

import Error from 'components/atoms/Error';
import ExpandableSection from 'components/atoms/ExpandableSection';
import DeliveryAreaList from 'components/organisms/DeliveryAreaList';
import {
  FieldStyle2,
  InputNumberStyled,
  InputStyled,
} from 'components/Product/shared';

import DeliveryByRadius from '../DeliveryByRadius';
import DeliveryDeleteConfirmationModal from '../DeliveryDeleteConfirmationModal';
import DeliveryMileList from '../DeliveryMileList';
import DeliveryPostcode from '../DeliveryPostcode';
import DeliveryUnableDeleteModal from '../DeliveryUnableDeleteModal';
import * as ST from './styles';

import { Radio, T8y } from 'ui';

const FIELDS_NAMES_LIST = [
  {
    checkboxName: 'depotToLocationEnabled',
    inputName: 'depotToLocation',
  },
  {
    checkboxName: 'locationToDepotEnabled',
    inputName: 'locationToDepot',
  },
  {
    checkboxName: 'bothWaysDeliveryEnabled',
    inputName: 'bothWaysDelivery',
  },
];

const TransportationDelivery = ({
  deliveryItem,
  index,
  handleRemove,
  register,
  control,
  errors,
  values,
  setValue,
  watch,
  useWatch,
  handleRemoveExistedDelivery,
  deliveryRelatedProductsList,
  setDeliveryRelatedProductsList,
  trigger,
}) => {
  const { settings: settingsLocalization, form } = Lang();

  const settings = useStore(settings$);

  const radio = useRadioState({ state: null });
  const dialogStateConfirmationModal = useDialogState();
  const dialogStateUnableToDeleteModal = useDialogState();

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (settings?.defaultDeliveryMethodType) {
      radio.setState(settings.defaultDeliveryMethodType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const getErrorMessage = useCallback(
    field => {
      return (
        errors &&
        errors.deliveries &&
        errors.deliveries[index] &&
        errors.deliveries[index][field] &&
        errors.deliveries[index][field].message
      );
    },
    [errors, index],
  );

  const deliveryHeader = useMemo(() => {
    return (
      values.deliveries?.[index]?.name ||
      form.defaultText.enterDeliveryTypeTitle
    );
  }, [form.defaultText.enterDeliveryTypeTitle, index, values.deliveries]);

  const handleRemoveDelivery = useCallback(
    async index => {
      if (!deliveryItem.serverId) {
        handleRemove(index);
        return;
      }

      dialogStateConfirmationModal.setVisible(true);
    },
    [deliveryItem.serverId, dialogStateConfirmationModal, handleRemove],
  );

  const handleDeletingConfirmation = useCallback(async () => {
    try {
      setIsDeleting(true);
      const isAttachedToProducts = await handleRemoveExistedDelivery(
        deliveryItem.serverId,
      );

      if (isAttachedToProducts) {
        dialogStateConfirmationModal.setVisible(false);
        dialogStateUnableToDeleteModal.setVisible(true);
        return;
      }

      toast.success(form.toastTitle.successfullyDeleted);
      getDeliveries();
    } catch (e) {
      toast.error(form.toastTitle.deleteError);
    } finally {
      setIsDeleting(false);
    }
  }, [
    deliveryItem.serverId,
    dialogStateConfirmationModal,
    dialogStateUnableToDeleteModal,
    form.toastTitle.deleteError,
    form.toastTitle.successfullyDeleted,
    handleRemoveExistedDelivery,
  ]);

  const handleModalClose = useCallback(() => {
    dialogStateConfirmationModal.setVisible(false);
    dialogStateUnableToDeleteModal.setVisible(false);
  }, [dialogStateConfirmationModal, dialogStateUnableToDeleteModal]);

  const isErrors = useMemo(() => {
    if (!errors || !errors.deliveries) {
      return false;
    }

    return Object.keys(errors.deliveries)
      .map(el => Number(el))
      .includes(index);
  }, [errors, index]);

  useEffect(() => {
    if (!dialogStateUnableToDeleteModal) {
      setDeliveryRelatedProductsList(null);
    }
  }, [dialogStateUnableToDeleteModal, setDeliveryRelatedProductsList]);

  return (
    <>
      <ExpandableSection
        index={index}
        header={deliveryHeader}
        handleRemove={radio.state !== 'postcode' && handleRemoveDelivery}
        isErrors={isErrors}
      >
        <ST.DeliveryInfoWrapper>
          <ST.LineWrapper>
            <ST.DeliveryTypeWrapper>
              <FieldStyle2 legend={form.fields.deliveryType}>
                <InputStyled
                  name={`deliveries[${index}].name`}
                  width="400px"
                  ref={register({
                    validate: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  })}
                  defaultValue={deliveryItem.name}
                  control={control}
                  placeholder={form.defaultText.enterDeliveryTypeTitle}
                />
              </FieldStyle2>
              <Error message={getErrorMessage('name')} />
            </ST.DeliveryTypeWrapper>
            <div>
              <FieldStyle2 legend={form.fields.maxLoadCapacity}>
                <InputNumberStyled
                  name={`deliveries[${index}].capacity`}
                  type="number"
                  width="150px"
                  ref={register({
                    required: form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      positive: value =>
                        value > 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  defaultValue={deliveryItem.capacity}
                  control={control}
                />
              </FieldStyle2>
              <Error message={getErrorMessage('capacity')} />
            </div>
          </ST.LineWrapper>
          <T8y fontSize="14px" color="gray" marginBottom="14px">
            {settingsLocalization.titles.costCalculationMethod}
          </T8y>
          <RadioGroup as={ST.RadioWrapper} {...radio}>
            <Radio
              {...radio}
              text={settingsLocalization.priceByArea}
              value="deliveryPerArea"
              disabled={radio.state !== 'deliveryPerArea'}
            />
            <Radio
              {...radio}
              text={settingsLocalization.priceByKm}
              value="deliveryPerMile"
              disabled={radio.state !== 'deliveryPerMile'}
            />
            <Radio
              {...radio}
              text={settingsLocalization.priceByPostalCode}
              value="postcode"
              disabled={radio.state !== 'postcode'}
            />
            <Radio
              {...radio}
              text={settingsLocalization.priceByRadius}
              value="deliveryByRadius"
              disabled={radio.state !== 'deliveryByRadius'}
            />
          </RadioGroup>
          {radio.state !== 'deliveryByRadius' && (
            <T8y fontSize="16px" marginBottom="30px">
              {settingsLocalization.titles.transportationCostVat} (
              {form.fields.priceWithoutVat})
            </T8y>
          )}
          {radio.state === 'deliveryPerArea' && (
            <DeliveryAreaList
              deliveryIndex={index}
              register={register}
              control={control}
              values={values}
              setValue={setValue}
              watch={watch}
              useWatch={useWatch}
              FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
            />
          )}
          {radio.state === 'postcode' && (
            <DeliveryPostcode
              deliveryItem={deliveryItem}
              register={register}
              errors={errors}
              control={control}
              deliveryIndex={index}
              values={values}
              setValue={setValue}
              watch={watch}
              FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
              trigger={trigger}
            />
          )}
          {radio.state === 'deliveryPerMile' && (
            <DeliveryMileList
              deliveryItem={deliveryItem}
              register={register}
              errors={errors}
              control={control}
              deliveryIndex={index}
              values={values}
              setValue={setValue}
              FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
              trigger={trigger}
            />
          )}
          {radio.state === 'deliveryByRadius' && (
            <DeliveryByRadius
              deliveryItem={deliveryItem}
              register={register}
              errors={errors}
              control={control}
              deliveryIndex={index}
              values={values}
              setValue={setValue}
              FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
              trigger={trigger}
              watch={watch}
              isErrors={isErrors}
            />
          )}
        </ST.DeliveryInfoWrapper>
      </ExpandableSection>
      <DeliveryDeleteConfirmationModal
        dialogState={dialogStateConfirmationModal}
        deliveryName={deliveryItem.name}
        handleDeletingConfirmation={handleDeletingConfirmation}
        handleModalClose={handleModalClose}
        loading={isDeleting}
      />
      <DeliveryUnableDeleteModal
        dialogState={dialogStateUnableToDeleteModal}
        deliveryName={deliveryItem.name}
        handleModalClose={handleModalClose}
        deliveryRelatedProductsList={deliveryRelatedProductsList}
      />
    </>
  );
};

export default TransportationDelivery;
