import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { isNil } from 'lodash';

import { CATEGORIES } from 'api';

import { currentLang$ } from '../layout/Header/SelectLanguage/languageModel';
import { getFlatCategoriesList } from '../utils/helpers/workWithLists';
import { capitalLetter } from '../utils/strings';
import { settings$ } from './settings';

const replaceState = (_state, result) => result;

export const categoriesTreeStore = createStore([]);

export const sortCategoriesFx = createEffect({
  handler: async ids => {
    const { data } = await CATEGORIES.sort(ids);
    return data;
  },
});

export const getCategoriesTree = createEffect({
  handler: async filter => {
    if (filter === 'archived') {
      const { data } = await CATEGORIES.archived();
      return data.data;
    }

    const { data } = await CATEGORIES.all();
    return data.data;
  },
});

export const isLoadingCategories$ = restore(getCategoriesTree.pending, false);

export const replaceCategoryData = createEvent();

categoriesTreeStore
  .on(getCategoriesTree.doneData, (state, data) => {
    const allCategories = [];
    data.forEach(el => {
      getFlatCategoriesList(el, 'childs', allCategories, 1);
    });

    return allCategories.map(el => {
      return {
        ...el,
        slug: el.id,
        actualSlug: el.slug,
        title: el.name ? capitalLetter(el.name) : 'No title',
        count:
          (el.products_count || 0) +
            allCategories
              .filter(secEl => secEl.parent === el.id)
              .reduce(
                (a, b) => (a.products_count || 0) + (b.products_count || 0),
                0,
              ) ?? 'N/A',
      };
    });
  })
  .on(replaceCategoryData, (s, updatedItem) => {
    const newItems = [...s];

    const newItem = {
      ...updatedItem,
      slug: updatedItem.id,
      actualSlug: updatedItem.slug,
      title: updatedItem.name ? capitalLetter(updatedItem.name) : 'No title',
    };

    const indexForSearch = s.findIndex(
      el => el.id.toString() === updatedItem.id.toString(),
    );

    newItems[indexForSearch] = newItem;

    if (updatedItem.level === 2) {
      const parentCategoryIndex = s.findIndex(
        el => el.id.toString() === updatedItem.parent?.data?.id.toString(),
      );

      const childCategoryIndex = newItems[
        parentCategoryIndex
      ].childs?.data?.findIndex(
        el => el.id.toString() === updatedItem.id.toString(),
      );

      newItems[parentCategoryIndex].childs.data[childCategoryIndex] = newItem;
    }

    if (updatedItem.level === 3) {
      const subSubCategoryParent = s.find(
        el => el.id.toString() === updatedItem.parent?.data?.id.toString(),
      );

      const subCategoryParentIndex = s.findIndex(
        el => el.id.toString() === subSubCategoryParent.parent?.toString(),
      );

      const subCategoryChildIndex = newItems[
        subCategoryParentIndex
      ].childs?.data?.findIndex(
        el => el.id.toString() === subSubCategoryParent.id.toString(),
      );

      const subSubCategoryChildIndex = newItems[
        subCategoryParentIndex
      ].childs?.data?.[subCategoryChildIndex].childs.data?.findIndex(
        el => el.id.toString() === updatedItem.id.toString(),
      );

      newItems[subCategoryParentIndex].childs.data[
        subCategoryChildIndex
      ].childs.data[subSubCategoryChildIndex] = newItem;
    }
    return newItems;
  });

export const categoriesTreeStoreLocalized$ = combine(
  categoriesTreeStore,
  currentLang$,
  settings$,
  (categories, lang, { defaultLanguage }) => {
    return categories.map(el => ({
      ...el,
      name: el.names[lang] || el.names[defaultLanguage],
    }));
  },
);

export const availableCategoriesLocalized$ = combine(
  categoriesTreeStoreLocalized$,
  categories => categories.filter(el => !el.hidden),
);

export const filterCategoryStore = createStore('');
export const setFilterCategory = createEvent();
filterCategoryStore.on(setFilterCategory, replaceState);

export const categoriesGate = createGate('');
export const reloadCategories = createEvent();

guard({
  source: categoriesGate.open,
  filter: categoriesTreeStore,
  target: getCategoriesTree,
});

forward({
  from: reloadCategories,
  to: getCategoriesTree,
});

export const deleteCategories = createEffect({
  handler: async id => {
    await CATEGORIES.delete(id);
  },
});

const loadCategoryFx = createEffect({
  handler: async id => {
    const { data } = await CATEGORIES.loadCategory(id);
    return data.data;
  },
});

const deleteCoverFx = createEffect({
  handler: async id => {
    const { data } = await CATEGORIES.deleteCover(id);
    return data.data;
  },
});

export const loadCategoryGate = createGate('');
export const categoryStore = restore(loadCategoryFx, {
  names: {},
  seo_h1: {},
});
export const changeCategory = createEvent();
export const changeCategoryNames = createEvent();
export const changeCategoryDescriptions = createEvent();
export const resetCategoryStore = createEvent();
export const deleteCategoryCover = createEvent();

forward({
  from: deleteCategoryCover,
  to: deleteCoverFx,
});

categoryStore
  .on(deleteCoverFx.doneData, (_, category) => category)
  .on(resetCategoryStore, () => ({
    name: null,
  }));

export const categoryParentIDStore = createStore(0);
export const setCategoryParentID = createEvent();

categoryParentIDStore
  .on(setCategoryParentID, (_, value) => value)
  .on(loadCategoryFx.doneData, (_, category) => {
    if (category && category.parent && category.parent.data) {
      return category.parent.data.id;
    }

    return 0;
  })
  .on(resetCategoryStore, () => 0);

export const categoryPhotoStore = createStore({ src: null, cover_id: null });
export const setCategoryPhoto = createEvent();

export const uploadPhotoFx = createEffect({
  handler: async file => {
    const { data } = await CATEGORIES.upload(file);
    return data.data;
  },
});

categoryPhotoStore
  .on(setCategoryPhoto, (_, value) => value)
  .on(loadCategoryFx.doneData, (_, category) => {
    if (category && category.image && category.image.data) {
      const image = category.image.data;
      return { src: image.main, cover_id: image.id };
    }

    return { src: null, cover_id: null };
  })
  .on(resetCategoryStore, () => ({ src: null, cover_id: null }));

guard({
  source: loadCategoryGate.open,
  filter: id => !isNil(id),
  target: loadCategoryFx,
});

guard({
  source: loadCategoryGate.close,
  filter: () => true,
  target: resetCategoryStore,
});
