import styled from 'styled-components';

export const AddProductsWrapper = styled.div`
  position: relative;

  & > div {
    bottom: -2rem !important;
    right: 0 !important;
    transform: none !important;
    inset: 2rem auto auto 10rem !important;
  }
`;
