import styled from 'styled-components';

import { colors } from 'styleGuide';

export const SameDayDeliveryCheckboxWrapper = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  background-color: ${colors.positive};
  border-radius: 5px;
`;
