import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NEWPaths, PATHS } from 'AppPaths';
import {
  API_BASENAME,
  COUNTRIES,
  getApiVersion,
  getShortDisplayedCompanyName,
  LANGUAGES,
  REGIONS,
} from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { selectLang } from 'layout/Header/SelectLanguage';
import { branding$ } from 'models/branding';
import { getUserFx } from 'models/user';
import { setWebsite } from 'models/website';

import CustomButton from 'components/atoms/CustomButton';
import Error from 'components/atoms/Error';
import ModalForm from 'components/molecules/ModalForm';
import { AUTH, getCurrentToken, setToken } from 'api';

import { PageTitle } from '../../PageTitle';
import * as ST from './styles';

import { Center, Field, inputStyle, Select } from 'ui';

import { ReactComponent as PassIcon } from 'assets/images/icon/lock.svg';
import { ReactComponent as NameIcon } from 'assets/images/icon/user_name.svg';

const Login = ({ history }) => {
  const { form, common } = Lang();

  const branding = useStore(branding$);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const onSubmit = useCallback(
    async ({ email, password, country, region }) => {
      setLoading(true);

      try {
        const currRegion = REGIONS[country]?.find(
          el => el.id.toString() === region,
        );

        const regionSlug = currRegion?.apiSlug;
        const regionName = currRegion?.name;
        const apiVersion = getApiVersion(country);

        const { data } = await AUTH.login({
          email,
          password,
          country,
          region: regionSlug,
          apiVersion,
        });

        const userInfoData = await getUserFx({
          country,
          token: data.access_token,
          apiVersion,
        });

        if (!userInfoData.regions.includes(regionSlug)) {
          toast.error('You have no admin rights for selected region');
          return;
        }

        setWebsite({
          shortCompanyName: getShortDisplayedCompanyName(country),
          country,
          regionName,
          regionSlug,
          api: `https://${API_BASENAME}.${country}/api/${apiVersion}/${regionSlug}`,
        });

        setToken(regionSlug, data.access_token);
        localStorage.setItem(
          `${getCurrentToken(regionSlug)}_displayedRegionName`,
          regionName,
        );
        selectLang(LANGUAGES[country]);
        NEWPaths.setROOT(regionSlug);
        history.push(`/${regionSlug}/products`);
      } catch (e) {
        console.warn(e);
        toast.error(
          e.response?.data?.slug === 'incorrectCredentials'
            ? 'Invalid email or password'
            : form.toastTitle.wentWrong,
        );
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [form.toastTitle.wentWrong, history],
  );

  const getSelectedRegion = () => {
    const selected = REGIONS[values.country]?.find(
      el => el.id.toString() === values.region,
    );

    return selected?.loginName || selected?.name;
  };

  return (
    <>
      <PageTitle
        displayedRegionName={`${branding?.companyName || 'easyHire'} - Admin`}
      />
      <Center>
        <ModalForm title={form.title.loginIn}>
          <ST.FieldsWrapper>
            <div>
              <Field legend={form.fields.username} icon={NameIcon}>
                <input
                  ref={register({
                    validate: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  })}
                  name="email"
                  placeholder={form.fields.username}
                  className={inputStyle}
                />
              </Field>
              <Error message={errors?.email?.message} />
            </div>
            <div>
              <Field legend={form.fields.password} icon={PassIcon}>
                <input
                  ref={register({
                    validate: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  })}
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={inputStyle}
                />
              </Field>
              <Error message={errors?.password?.message} />
            </div>
            <div>
              <Field legend="Country">
                <input
                  type="hidden"
                  name="country"
                  ref={register({
                    validate: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  })}
                  value={values.country}
                />
                <Select
                  options={COUNTRIES}
                  selected={
                    COUNTRIES.find(el => el.slug === values.country)?.name
                  }
                  defaultText="Select country"
                  aLabel="Country"
                  onClickOption={val => {
                    setValue('country', val);
                    setValue('region', REGIONS[val]?.[0].id);
                    clearErrors('country');
                    clearErrors('region');
                  }}
                  className={inputStyle}
                  maxHeight="300px"
                />
              </Field>
              <Error message={errors?.country?.message} />
            </div>
            <div>
              <Field legend="Region">
                <input
                  type="hidden"
                  name="region"
                  ref={register({
                    validate: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  })}
                  value={values.region}
                />
                <Select
                  options={REGIONS[values.country]?.map(el => ({
                    ...el,
                    name: el.loginName || el.name,
                  }))}
                  selected={getSelectedRegion()}
                  defaultText="Select region"
                  aLabel="Region"
                  onClickOption={id => {
                    setValue('region', id);
                    clearErrors('region');
                  }}
                  className={inputStyle}
                  maxHeight="300px"
                />
              </Field>
              <Error message={errors?.region?.message} />
            </div>
          </ST.FieldsWrapper>
          <ST.BtnWrapper>
            <CustomButton
              backgroundColor="darkPrimary"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={loading}
              borderRadius="0"
              text={form.buttons.logIn}
            />
            <ST.ForgotText onClick={() => history.push(PATHS.FORGOT_PASSWORD)}>
              {common.forgotPassword.header}
            </ST.ForgotText>
          </ST.BtnWrapper>
        </ModalForm>
      </Center>
    </>
  );
};

export default withRouter(Login);
