import React, { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import DeliveryPagination from 'components/atoms/DeliveryPagination';

import DeliveryArea from '../DeliveryArea';
import * as ST from './styles';

import { T8y } from 'ui';

const POSTCODES_PER_PAGE = 30;

const DeliveryAreaList = ({
  deliveryIndex,
  register,
  control,
  values,
  setValue,
  watch,
  useWatch,
  FIELDS_NAMES_LIST,
}) => {
  const { settings: settingsLocalization } = Lang();

  const [currentPage, setCurrentPage] = useState(1);

  const { fields } = useFieldArray({
    control,
    name: `deliveries[${deliveryIndex}].deliveryPerPostcode`,
    keyName: 'formId',
  });

  const itemsWithPagination = useMemo(() => {
    if (!fields) {
      return [];
    }

    return fields
      .map((el, index) => ({ ...el, originalIndex: index }))
      .slice(
        (currentPage - 1) * POSTCODES_PER_PAGE,
        POSTCODES_PER_PAGE * currentPage,
      );
  }, [currentPage, fields]);

  const totalPages = useMemo(() => {
    return Math.ceil(fields.length / POSTCODES_PER_PAGE);
  }, [fields.length]);

  return (
    <>
      <ST.DeliveryAreaTitles>
        <T8y as="span" color="gray">
          {settingsLocalization.postcode}
        </T8y>
        <T8y as="span" color="gray">
          {settingsLocalization.depotToLocation}
        </T8y>
        <T8y as="span" color="gray">
          {settingsLocalization.locationToDepot}
        </T8y>
        <T8y as="span" color="gray">
          {settingsLocalization.bothWaysDelivery}
        </T8y>
      </ST.DeliveryAreaTitles>
      <div>
        {itemsWithPagination.map((el, areaIndex) => (
          <DeliveryArea
            el={el}
            register={register}
            deliveryIndex={deliveryIndex}
            nestIndex={el.originalIndex || areaIndex}
            setValue={setValue}
            watch={watch}
            values={values}
            useWatch={useWatch}
            control={control}
            key={el.formId}
            FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
            isPostcode
          />
        ))}
        <DeliveryPagination
          items={itemsWithPagination}
          limitPerPage={POSTCODES_PER_PAGE}
          handlePageChange={el => setCurrentPage(el)}
          totalCount={fields.length}
          totalPages={totalPages}
          currPage={currentPage}
          isScrollToTop
        />
      </div>
    </>
  );
};

export default DeliveryAreaList;
