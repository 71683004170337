import React, { useCallback } from 'react';
import { languagesOverridesMapLocales } from 'consts';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';

import { StyledCalendarIcon, StyledRangePicker } from './styles';

import { ReactComponent as LeftArrow } from 'assets/images/icon/CalendarLeftArrow.svg';
import { ReactComponent as RightArrow } from 'assets/images/icon/CalendarRightArrow.svg';
import { ReactComponent as Calendar } from 'assets/images/icon/dateRangeIcon.svg';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

const RangePicker = ({ onChange, values = [] }) => {
  const {
    form: { calendar },
  } = Lang();

  const currentLangState = useStore(currentLang$);

  const getWeekdayLabel = useCallback(
    date => {
      return format(date, 'EEEEEE', {
        locale: languagesOverridesMapLocales[currentLangState],
      });
    },
    [currentLangState],
  );

  return (
    <StyledRangePicker
      nativeInputAriaLabel="nativeInput"
      onChange={onChange}
      value={values.length ? values : [new Date(), new Date()]}
      locale={currentLangState}
      nextLabel={<RightArrow />}
      prevLabel={<LeftArrow />}
      calendarIcon={
        <StyledCalendarIcon align="center">
          <Calendar />
        </StyledCalendarIcon>
      }
      format="dd/MM/yyyy"
      locate="it-HU"
      clearIcon={null}
      prev2Label={null}
      next2Label={null}
      dayPlaceholder={calendar.dayPlaceholder}
      monthPlaceholder={calendar.monthPlaceholder}
      yearPlaceholder={calendar.yearPlaceholder}
      showFixedNumberOfWeeks
      formatShortWeekday={(locale, date) => getWeekdayLabel(date)}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default RangePicker;
