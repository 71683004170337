export const allowOnlyFloatNumbers = e => {
  if (e.target.value.indexOf('.') > -1) {
    if (!'0123456789'.includes(e.key)) {
      e.preventDefault();
    }
  } else {
    if (!'0123456789.'.includes(e.key)) {
      e.preventDefault();
    }
  }
};

export const isNotNullUndefined = val => {
  return typeof val !== 'undefined' && val !== null;
};

export const handleChangePriceField = e => {
  const decimalPointPosition = e.target.value.indexOf('.');

  if (
    decimalPointPosition !== -1 &&
    e.target.value.length - decimalPointPosition > 2
  ) {
    e.target.value = e.target.value.slice(0, decimalPointPosition + 3);
  }
};
