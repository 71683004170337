import React from 'react';
import { PopoverArrow, usePopoverState } from 'reakit';
import { Lang } from 'lang';

import { PopoverDisclosureStyled, PopoverStyled } from './styles';

import { Cell, Icon, T8y } from 'ui';

import { ReactComponent as Note } from 'assets/icons/note.svg';

export const Notification = () => {
  const { header } = Lang();

  const popover = usePopoverState();

  return (
    <>
      <Cell place="center end">
        <PopoverDisclosureStyled {...popover}>
          <Icon as={Note} />
        </PopoverDisclosureStyled>
      </Cell>
      <PopoverStyled
        {...popover}
        aria-label={header.titles.notification}
        tabIndex={0}
      >
        <PopoverArrow {...popover} />
        <T8y color="negative">{header.titles.notification}</T8y>
      </PopoverStyled>
    </>
  );
};
