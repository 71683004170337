import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Button, T8y } from 'ui';

export const UserRequestsSection = styled.div`
  padding: 12px 24px 2px;
  background-color: #fffdfd;
  ${p =>
    p.isSmall
      ? `
        border: 1px solid #eeeeee;
        border-left: 3px solid ${colors.danger}};`
      : `
        border: 1px solid #E28787;
        border-radius: 5px;
        margin-bottom:30px;
        &>div:first-child {
            color: #222;
        }
         `};
`;

export const CancellationReason = styled(T8y)`
  font-style: italic;
`;

export const OrderButton = styled(Button)`
  height: 30px;
  color: ${p => colors[p.color]};
  font-size: ${p => p.fontSize};
  padding: 0 16px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  background-color: ${colors.positive};
  margin-bottom: 10px;

  & > div {
    padding: 0;
  }

  &:first-child {
    margin-right: 12px;
  }
`;
