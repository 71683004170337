export const leads = {
  subTitle: 'Monitorear todos los leads de la página web en tiempo real',
  backToAllLeads: 'Volver a la lista de todos los leads',
  sourcePage: 'Página fuente',
  leadsAvailable: '{0} leads disponibles',
  hideTestLeads: 'Ocultar leads de “Test”',
  viewSourcePage: 'Ver página fuente',
  comment: 'Mensaje',
  message: 'Mensaje',
  crmDeal: 'Negocio de CRM',
  crmContact: 'Contacto de CRM',
  callTo: 'Llamar a',
  customerTax: 'Código Fiscal',
  billingAddress: 'Billing Address',
  press: 'Presiona',
  emailIsCopied: 'Se ha copiado el correo electrónico a su portapapeles',
  shuftiVerificationStatus: 'Shufti Pro verification status',
  shuftiVerificationLink: 'Shufti Pro verification link',
  yes: 'Sí',
  no: 'No',
  stripePaymentLink: 'Stripe link de pago',

  userTypes: {
    individual: 'Privado',
    company: 'Empresa',
  },

  leadInfoText: {
    enquiry: '{0} ha hecho esta consulta y espera ser contactado.',
    callback: '{0} ha solicitado una llamada y espera ser contactado.',
    trainingEnquiry:
      '{0} ha hecho esta consulta acerca de un curso de formación y espera ser contactado.',
  },

  tableHeaders: {
    dateCreated: 'Fecha de creación',
    leadType: 'Tipo de lead',
    leadSource: 'Origen del lead',
    fullName: 'Nombre completo',
    companyName: 'Nombre de la compañía',
    phoneNumber: 'Número de teléfono',
    emailAddress: 'Correo electrónico',
    CRMDealStage: 'CRM Deal Stage',
  },

  types: {
    all: 'Todos',
    enquiry: 'Consulta',
    callback: 'Solicitud de llamada',
    trainingEnquiry: 'Consulta de formación',
    product: 'Producto',
    quote: 'Presupuesto',
    trainingOrder: 'Pedido de formación',
    training: 'Pedido de formación',
    checkoutStarted: 'El checkout ha comenzado',
    newsletterSubscribe: 'Suscrito a la newsletter',
  },
};
