import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row } from 'ui';

export const ImageContainer = styled(Row)`
  width: 70px;
  height: 70px;
  border: 1px solid #dddddd;
  background: ${colors.positive}};
  overflow: hidden;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;
