import styled from 'styled-components';

import { Row, T8y } from 'ui';

import { ReactComponent as LinkIcon } from 'assets/images/icon/linkIcon.svg';

export const LinkIconStyled = styled(LinkIcon)`
  flex-shrink: 0;
`;

export const LeadInfoHeader = styled(Row)`
  margin-bottom: 20px;
`;

export const LeadInfoFeature = styled(T8y)`
  color: #2e2e2e;
`;
