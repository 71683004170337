import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Lang } from 'lang';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

const ContainerStyles = {
  line: {
    'padding-right': '10px',
  },
  contentText: {
    'overflow-wrap': 'anywhere',
  },
};

const HistoryTableModal = ({ currentVersion, previousVersion, dialog }) => {
  const { common, formatString } = Lang();

  return (
    <Modal
      title={common.historyTable.changes}
      dialogState={dialog}
      width="80%"
      minHeight="70vh"
      overflow="hidden"
      backgroundColor={colors.primary}
      lazyRender
    >
      <ST.HistoryModalContainer justify="stretch">
        <ReactDiffViewer
          styles={ContainerStyles}
          oldValue={previousVersion}
          newValue={currentVersion}
          compareMethod={DiffMethod.WORDS}
          splitView={!!previousVersion}
          leftTitle={common.historyTable.previousVersion}
          rightTitle={common.historyTable.currentVersion}
          codeFoldMessageRenderer={totalLines =>
            formatString(common.historyTable.expandLines, totalLines || '')
          }
        />
      </ST.HistoryModalContainer>
    </Modal>
  );
};

export default HistoryTableModal;
