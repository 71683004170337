export const form = {
  buttons: {
    saveChanges: 'Salvar alterações',
    cancel: 'Cancelar',
    close: 'Fechar',
    continue: 'Prosseguir',
    addMore: 'Adicione mais',
    addSelected: 'Adicionar selecionado',
    addCategory: 'Adicionar categoria',
    createProduct: 'Criar produto',
    update: 'Atualizar',
    create: 'Criar',
    createNew: 'Criar um novo',
    saveAndView: 'Salvar e visualizar',
    save: 'Salvar',
    publish: 'Publicar',
    add: 'Adicionar',
    adding: 'Adicionando',
    editing: 'Editando',
    edit: 'Editar',
    delete: 'Deletar',
    browse: 'Navegar',
    logIn: 'Log In',
    createCopy: 'Criar uma cópia',
    addNewReport: 'Adicionar novo relatório',
    createNewOrder: 'Criar novo pedido',
    okay: 'Ok',
    addNewDeliveryType: 'Adicionar novo tipo de entrega',
    addLinkToSelectedText: 'Adicionar link ao texto selecionado',
    removeLinkFromSelectedText: 'Remover link do texto selecionado',
    connect: 'Conectar',
    deleteId: 'Excluir ID',
    addProductId: 'Adicionar ID do produto',
    logInAsUser: 'Log in como usuário',
    submit: 'Submit',
  },
  links: {
    addMore: 'Adicione mais',
    createNew: 'Crie um novo',
    goToAllCategories: 'Ir para todas as categorias',
  },
  fields: {
    username: 'Nome do usuário',
    password: 'Senha',
    label: 'Rótulo',
    category: 'Categoria',
    minPeriod: 'Período mínimo de contratação',
    avgMarketPriceSave: 'Economia média no preço',
    subCategory: 'Subcategoria',
    subSubCategory: 'Sub Subcategoria',
    maxQuantity: 'Quantidade máxima',
    popularity: 'Popularity',
    date: 'Data',
    show: 'Mostrar',
    showAll: 'Mostrar tudo',
    all: 'Todos',
    deliveryType: 'Tipo de entrega',
    title: 'Título',
    titleGb: 'Title Gb',
    titleIt: 'Title Pt',
    sku: 'Sku',
    description: 'Descrição',
    productUrl: 'URL do produto',
    feature: 'Característica',
    rangeInDays: 'Intervalo em dias',
    pricePerDay: 'Preço, por dia',
    pricePerRange: 'Preço, por gama',
    outOfRangeRation: 'Out of range ratio (per day), %',
    outOfRangePrice: 'Preço fora da faixa, por dia',
    individualUser: 'Usuário individual',
    companyUser: 'Usuário da empresa',
    feePerDayPerItem: 'Taxa por dia por item',
    feePerItem: 'Taxa por item',
    feePerOrder: 'Taxa por pedido',
    amountPerItem: 'Valor por item',
    metaDescription: 'Meta Descrição',
    metaCanonical: 'Metacanônico',
    h1Tag: 'Etiqueta H1',
    robotsTag: 'Etiqueta de Robôs',
    parentCategory: 'Categoria principal',
    deposit: 'Depósito',
    measurements: 'Medidas',
    capacity: 'Capacidade',
    weight: 'Peso',
    craneCapacity: 'Capacidade do guindaste',
    power: 'Potência',
    maxHeight: 'Altura máxima',
    maxWorkingHeight: 'Altura máxima de trabalho',
    holeDiameter: 'Diâmetro do furo',
    notes: 'Notas',
    diskDiameter: 'Diâmetro do disco',
    cuttingDepth: 'Profundidade de corte',
    cuttingWidth: 'Largura de corte',
    excavationDepth: 'Profundidade de escavação',
    question: 'Pergunta',
    answer: 'Resposta',
    name: 'Nome',
    location: 'Localização',
    rating: 'Classificação',
    review: 'Avaliação',
    productCode: 'Product Code',
    photo: 'Foto',
    price: 'Preço',
    tag: 'Etiqueta',
    search: 'Buscar',
    specification: 'Especificação',
    specificationName: 'nome',
    specificationVaValue: 'valor',
    startTyping: 'Comece a digitar para pesquisar e filtrar relatórios',
    reportsAreFound: 'relatórios encontrados',
    maxLoadCapacity: 'Capacidade máxima de carga, kg',
    startPrice: 'Preço inicial',
    minPrice: 'Preço minimo',
    pricePerKm: 'Preço por 1 km',
    pricePerWay: '*Preço por trecho',
    ReportYear: 'Relatório Ano/Mês',
    reportTitle: 'Título do relatório',
    reviewTitle: 'Título da avaliação',
    marginOfDays: 'Margem de dias antes da locação',
    checkoutAvailable: 'Disponível para checkout',
    checkoutNotAvailable: 'Não disponível para checkout',
    sectionDescription: 'Descrição da seção (opcional)',
    visual: 'Visual',
    productTitle: 'Título do produto',
    tax: 'Taxa',
    trainingCardDiscountValue: 'Valor do Desconto do Cartão de Treinamento',
    drafts: 'Esboço',
    priceWithoutVat: 'Preço sem IVA',
    fullName: 'Nome completo',
    phoneNumber: 'Número de telefone',
    emailAddress: 'Endereço de e-mail',
    companyName: 'Nome da empresa',
    vatNumber: 'Número de IVA',
    outOfStock: 'Fora de estoque',
    unavailable: 'Não disponível',
    perDay: 'Por dia',
    perWeek: 'Por semana',
  },
  defaultText: {
    selectCategory: 'Selecione a Categoria',
    selectSubCategory: 'Selecione a subcategoria',
    selectSubSubCategory: 'Selecione sub sub categoria',
    selectDeliveryType: 'Selecione o tipo de entrega',
    noParent: 'Sem categoria principal',
    enterDeliveryTypeTitle: 'Digite o título do tipo de entrega',
  },
  checkbox: {
    insurance: 'Seguro',
    safetyFee: 'Taxa de segurança',
    sanitationFee: 'Taxa de Saneamento',
    retailItem: 'Item de varejo',
    hideProduct: 'Ocultar produto no catálogo',
    showProductVideo: 'Mostrar vídeo do produto',
    gasTank: 'Caução de combustível',
    showCarbon: 'Mostrar Selo de Carbono Neutro',
    adminFeeFixed: 'Taxa Administrativa Fixa',
    adminFeeVat: 'IVA da Taxa Administrativa',
    wasteManagementFee: 'Taxa de gerenciamento de resíduos',
    deposit: 'Depósito',
    depositToBeDefined: 'Depósito a ser definido',
    deliveryWithoutVat: 'Entrega sem IVA',
    showAsOutOfStock: 'Mostrar produto como Esgotado',
    idVerification: 'ID verification',
    damageWaiver: 'Isenção de danos',
  },
  radio: {
    file: 'Arquivo',
  },
  title: {
    loginIn: 'Login in',
  },
  toastTitle: {
    thelastImage: 'A última imagem não pode ser excluída',
    onlyImagesCan: 'Apenas {0} imagens podem ser adicionadas',
    successfullyUpdated: 'Atualizado com sucesso',
    successfullyCreated: 'Criado com sucesso',
    successfullyDeleted: 'Excluído com sucesso',
    successfullyApproved: 'Aprovado com sucesso',
    successfullyDeclined: 'Recusado com sucesso',
    successfullyRequest: 'Seu pedido foi enviado com sucesso!',
    createError: 'Criar erro',
    deleteError: 'Excluir erro',
    updateError: 'Erro de atualização',
    creating: 'Criando',
    updating: 'Atualizando',
    wasUpdated: 'foi atualizado',
    wasCreated: 'foi criado',
    extraCantBeCreated: 'Extra não pode ser criado',
    onlySeveralFiledCanBeUpdated: 'Apenas {0} arquivos podem ser adicionados',
    invalidForm: 'Nem todos os campos foram inseridos corretamente',
    minProducts: 'Tipo de trabalho deve ter no mínimo 4 produtos',
    wentWrong: 'Algo deu errado',
  },
  notification: {
    valueShouldBeUnique: 'O valor deve ser único',
    valueShouldBePositive: 'O valor deve ser um número positivo',
    valueShouldBeBetween: 'O valor deve estar entre {0} e {1}',
    valueShouldBeValid: 'O valor deve ser um URL válido',
    shouldBeInsideOneOfTheRanges:
      'O valor deve estar dentro de um dos intervalos existentes',
    theFieldIsRequired: 'O campo é obrigatório',
    theLastSlideImage: 'A última imagem do slide não pode ser excluída',
    theBiggestRange: "Lógica de cálculo de definição de preço adicional'",
    notAvailableArea: 'Área não está disponível para entrega',
    notAvailable: 'Não está disponível',
    loading: 'Carregando..',
    notFound: 'Id não foi encontrado',
    chooseCorrectAddress: 'Por favor, escolha o endereço correto da lista',
    rangeDaysMustBe: 'O intervalo em dias deve ser 1-7 ou 3-7',
    phonePatter: 'Incorrect phone number',
    minLength: 'Mín. {0} caracteres',
    emailPattern: 'E-mail incorreto',
    passwordLength: 'Password must have at least 6 characters',
    passwordDifferent: 'Passwords are different',
    maxLength: 'Máx. {0} caracteres',
    requiredLength: '{0} símbolos são obrigatórios',
    minValue: 'Mín. {0}',
    firstShouldBeLessSecond: 'O primeiro valor deve ser menor que o segundo',
    valueAlreadyPresents: 'O valor já foi apresentado',
    rangeShouldNotOverlapOthers:
      'O intervalo não deve se sobrepor a outros intervalos',
    availableOnly:
      'Available only if there is a range ending in “7" is present',
  },

  descriptions: {
    enterVideoUrl: 'Digite o URL do vídeo do Youtube ou Vimeo',
    filesAsMax: '{0} arquivos no máximo',
    areYouSureToDelete:
      'Tem certeza de que deseja excluir o produto {0} com sku {1} ?',
    popularMaxReached: 'Limite máximo de produto popular atingido',
  },
  placeholders: {
    startTyping: 'Começe a digitar...',
    enterProductId: 'Digite o ID do produto e clique em Conectar',
    enterTitle: 'Digite o título (máximo de {0} caracteres)',
  },
  active: 'Ativo',
  inactive: 'Inativo',
  vat: 'IVA',
  calendar: {
    selectHirePeriod: 'Selecione o período de contratação',
    previous: 'Anterior',
    reset: 'Restaurar ao padrão',
    next: 'Próximo',
    dayPlaceholder: 'DD',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'AAAA',
  },
  prevPage: 'Página anterior',
  nextPage: 'Próxima página',
  disabledOnRegionalLevel: 'desativado em nível regional',
  extra: 'Extra',
};
