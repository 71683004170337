import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row } from 'ui';

export const TitlesWrapper = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  color: #b3b7bb;
  font-family: 'FuturaBT-Book', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  text-align: inherit;
  cursor: default;
  margin-bottom: 0.5rem;

  > span {
    flex-basis: 30%;
  }

  > span:first-child {
    flex-basis: 25%;
    margin-right: 50px;
    min-width: 175px;
  }
`;

export const EuroIcon = styled.span`
  position: absolute;
  color: ${colors.negative}!important;
  top: 1.1rem;
  left: 2rem;
  line-height: 1.5 !important;

  ${p =>
    p.disabled &&
    `
    color: ${colors.gray};
  `}
`;

export const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const FieldsWrapper = styled(Row)`
  max-width: 750px;
`;
