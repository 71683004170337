import styled from 'styled-components';

import { Row } from '../Grid/Row';

export const DropZoneBlockWrapper = styled(Row)`
  padding: 20px;

  & > svg {
    margin-bottom: 5px;
  }
`;
