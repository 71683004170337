import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { getFormattedOrderProducts } from 'utils/getFormattedOrderProducts';

import { ORDERS, SUPPLIERS } from 'api';

export const loadOrderFx = createEffect({
  handler: async ({ userId, orderId, orderType }) => {
    const res = await ORDERS.getOrder(userId, orderId, { type: orderType });

    const formattedOrder = res.data.data;

    formattedOrder.products = getFormattedOrderProducts(
      formattedOrder.products,
      formattedOrder.type,
    );

    return formattedOrder;
  },
});

export const getAllSuppliersFx = createEffect({
  handler: async () => {
    const res = await SUPPLIERS.getAllSuppliers();

    return res?.data?.data || [];
  },
});

export const clearOrder = createEvent();
export const getUserOrder = createEvent();
export const setOrderDocsForOrderProfile = createEvent();

export const order$ = createStore(null)
  .on(loadOrderFx.doneData, (s, p) => p)
  .on(setOrderDocsForOrderProfile, (s, { id, newDocs, type }) =>
    newDocs ? { ...s, [type]: newDocs } : s,
  )
  .on(clearOrder, () => null);

forward({
  from: getUserOrder,
  to: loadOrderFx,
});

export const suppliers$ = createStore([]).on(
  getAllSuppliersFx.doneData,
  (_, p) => p?.sort((a, b) => a.name?.localeCompare(b?.name)),
);

export const suppliersLoading$ = restore(
  getAllSuppliersFx.pending.updates,
  false,
);

export const setIsOrderUpdating = createEvent();

export const isOrderUpdating$ = createStore(false).on(
  setIsOrderUpdating,
  (_, p) => p,
);
