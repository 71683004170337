import React, { useMemo, useState } from 'react';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { owners$ } from 'models/owners';
import { setCurrentProductOwner } from 'pages/model';

import CustomButton from 'components/atoms/CustomButton';
import PageHeaderWithBackLink from 'components/atoms/PageHeaderWithBackLink';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import { FieldStyle, FieldStyle2 } from 'components/Product/shared';
import { history } from 'libs/history';

import * as ST from './styles';

import { inputStyle, Select, T8y } from 'ui';

const SelectProductOwner = ({ setValue }) => {
  const { product, form } = Lang();

  const owners = useStore(owners$);

  const [selected, setSelected] = useState(null);

  const formattedOwnersList = useMemo(
    () => owners.map(el => ({ ...el, slug: el.id })),
    [owners],
  );

  const selectedOwner = useMemo(
    () =>
      selected
        ? owners.find(el => el.id.toString() === selected.toString())
        : null,
    [owners, selected],
  );

  return (
    <div>
      <ST.HeaderWrapper>
        <PageHeaderWithBackLink
          header={product.titles.productProfile}
          link={PATHS.PRODUCTS}
          linkText={product.titles.goToAll}
        />
      </ST.HeaderWrapper>
      <T8y fontSize="18px" color="#1D415C" marginBottom="20px" bold>
        {product.productOwner}
      </T8y>
      <ProductEditorBlockWrapper>
        <FieldStyle as={FieldStyle2} width="400px" withBorder={true}>
          <Select
            options={formattedOwnersList}
            selected={selectedOwner?.name}
            defaultText={product.selectFranchisee}
            aLabel="Category"
            onClickOption={setSelected}
            className={inputStyle}
            maxHeight="200px"
          />
        </FieldStyle>
      </ProductEditorBlockWrapper>
      <ST.ButtonsWrapper>
        <CustomButton
          text={form.buttons.cancel}
          onClick={() => history.push(PATHS.PRODUCTS)}
          inverse
        />
        <CustomButton
          text={form.buttons.continue}
          onClick={() => setCurrentProductOwner(selectedOwner)}
        />
      </ST.ButtonsWrapper>
    </div>
  );
};

export default SelectProductOwner;
