import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import NotCheckoutAvailableLocation from 'components/atoms/NotCheckoutAvailableLocation';

const TrainingNotCheckoutAvailableLocations = ({
  register,
  values,
  setValue,
  EMPTY_LOCATION,
  control,
  errors,
  trigger,
}) => {
  const { trainings } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.noSchedule.locations',
    keyName: 'formId',
  });

  const handleAddLocation = useCallback(() => {
    append({
      ...EMPTY_LOCATION,
    });
  }, [EMPTY_LOCATION, append]);

  return (
    <>
      {fields.map((el, index) => (
        <NotCheckoutAvailableLocation
          el={el}
          register={register}
          setValue={setValue}
          locationIndex={index}
          errors={errors}
          remove={remove}
          trigger={trigger}
          key={el.id}
          values={values}
        />
      ))}
      <AddMore text={trainings.addNewLocation} onClick={handleAddLocation} />
    </>
  );
};

export default TrainingNotCheckoutAvailableLocations;
