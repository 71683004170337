import styled from 'styled-components';

export const TrainingsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 455px);
  grid-gap: 30px;
  margin-bottom: 20px;
`;

export const TrainingWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e4e8ed;
  border-radius: 2px;
  flex-basis: calc(50% - 15px);
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const ImgWrapper = styled.div`
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
