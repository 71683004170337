import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import { owners$ } from 'models/owners';
import {
  addNewWarehouse,
  deleteWarehouse,
  isWarehousesLoading$,
  updateWarehouse,
} from 'models/warehouses';

import Error from 'components/atoms/Error';
import ExpandableSection from 'components/atoms/ExpandableSection';
import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import DepotDeleteConfirmationModal from 'components/molecules/DepotDeleteConfirmationModal';
import DepotUnableDeleteModal from 'components/molecules/DepotUnableDeleteModal';
import {
  FieldStyle2,
  InputNumberStyled,
  InputStyled,
} from 'components/Product/shared';
import { WAREHOUSES } from 'api';

import * as ST from './styles';

import { inputStyle, Row, Select, T8y } from 'ui';

const NAME_MAX_LENGTH = 47;

const DepotForm = ({ depot = {}, isLastDepot, index, setIsAddNewDepot }) => {
  const { form, users, trainings, product, common, formatString } = Lang();

  const owners = useStore(owners$);
  const isWarehousesLoading = useStore(isWarehousesLoading$);
  const branding = useStore(branding$);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOwnerId, setSelectedOwnerId] = useState(
    depot.franchiseeId || null,
  );
  const [depotRelatedProductsList, setDepotRelatedProductsList] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  const dialogStateConfirmationModal = useDialogState();
  const dialogStateUnableToDeleteModal = useDialogState();

  const { handleSubmit, errors, register, reset, setValue, watch } = useForm({
    defaultValues: {
      franchiseeId: depot.franchiseeId || '',
      name: depot.name?.replace(`${branding.companyName} - `, '') || '',
      address: depot.address || '',
      googleMapLink: depot.googleMapLink || '',
      openingTimes: depot.openingTimes || '',
      latitude: depot.latitude,
      longitude: depot.longitude,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  useEffect(() => {
    register(
      { name: 'franchiseeId' },
      { required: form.notification.theFieldIsRequired },
    );
  }, [form, register]);

  const nameWatcher = watch('name');

  const actualNameMaxLength = useMemo(
    () => NAME_MAX_LENGTH - branding.companyName?.length || 0,
    [branding],
  );

  const nameRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'name',
    actualNameMaxLength,
  );

  const formattedOwnersList = useMemo(
    () => owners.map(el => ({ ...el, slug: el.id })),
    [owners],
  );

  const selectedOwner = useMemo(
    () =>
      selectedOwnerId
        ? owners.find(el => el.id.toString() === selectedOwnerId.toString())
        : null,
    [owners, selectedOwnerId],
  );

  const depotHeaderText = useMemo(() => {
    const title = nameWatcher
      ? `${branding.companyName} - ${nameWatcher}`
      : form.enterDepotName;
    return depot.id ? title : `${title} (${common.new})`;
  }, [branding, common, depot.id, form, nameWatcher]);

  const handleSelectOwner = useCallback(
    id => {
      setSelectedOwnerId(id);
      setValue('franchiseeId', id, { shouldValidate: true });
    },
    [setValue],
  );

  const onSubmit = useCallback(
    async formData => {
      setIsLoading(true);

      const forrmatedFormData = {
        ...formData,
        name: `${branding.companyName} - ${formData.name}`,
      };

      try {
        if (depot.id) {
          const { data } = await WAREHOUSES.updateWarehouse({
            params: forrmatedFormData,
            id: depot.id,
          });

          updateWarehouse({ index, data });
        } else {
          const { data } = await WAREHOUSES.createWarehouse(forrmatedFormData);

          addNewWarehouse(data);
        }

        if (setIsAddNewDepot) {
          setIsAddNewDepot(false);
        }

        toast.success(
          depot.id
            ? form.toastTitle.successfullyUpdated
            : form.toastTitle.successfullyCreated,
        );
      } catch (e) {
        toast.error(
          depot.id ? form.toastTitle.updateError : form.toastTitle.createError,
        );
      } finally {
        setIsLoading(false);
      }
    },
    [branding, depot, form, index, setIsAddNewDepot],
  );

  const handleRemoveDepot = useCallback(async () => {
    setIsDeleting(true);
    try {
      await WAREHOUSES.deleteWarehouse(depot.id);
      deleteWarehouse(depot.id);
      toast.success(form.toastTitle.successfullyDeleted);
    } catch (e) {
      if (
        e.response?.data?.message?.includes(
          'Warehouse has been related to some products',
        )
      ) {
        setDepotRelatedProductsList(e.response.data.products);
        dialogStateConfirmationModal.hide();
        dialogStateUnableToDeleteModal.show();
        return;
      }
      toast.error(form.toastTitle.wentWrong);
    } finally {
      setIsDeleting(false);
    }
  }, [
    depot,
    dialogStateConfirmationModal,
    dialogStateUnableToDeleteModal,
    form,
  ]);

  const handleRemoveIconClick = useCallback(() => {
    if (depot.id) {
      dialogStateConfirmationModal.show();
      return;
    }
    setIsAddNewDepot(false);
  }, [depot, dialogStateConfirmationModal, setIsAddNewDepot]);

  const handleReset = useCallback(() => {
    if (!depot.id && setIsAddNewDepot) {
      setIsAddNewDepot(false);
      return;
    }

    reset({
      ...depot,
      name: depot.name?.replace(`${branding.companyName} - `, '') || '',
    });
  }, [branding.companyName, depot, reset, setIsAddNewDepot]);

  return (
    <>
      <ExpandableSection
        index={index}
        header={depotHeaderText}
        initialIsOpen={!depot.id}
        handleRemove={!isLastDepot && handleRemoveIconClick}
      >
        {(isLoading || isWarehousesLoading) && <SpinnerOverlay />}
        <ST.DepotFormWrapper>
          <div>
            {depot.id ? (
              <T8y
                as={Row}
                fontSize="18px"
                color="negative"
                marginBottom="20px"
                bold
              >
                {form.depotOwner}: {selectedOwner?.name || '-'}
              </T8y>
            ) : (
              <>
                <FieldStyle2 legend={form.depotOwner} withBorder>
                  <Select
                    options={formattedOwnersList}
                    selected={selectedOwner?.name}
                    defaultText={product.selectFranchisee}
                    aLabel="Category"
                    onClickOption={handleSelectOwner}
                    className={inputStyle}
                    maxHeight="200px"
                  />
                </FieldStyle2>
                <Error message={errors.franchiseeId?.message} />
              </>
            )}
            <FieldStyle2 legend={form.fields.name} withBorder>
              <ST.CompanyNamePrefix>
                {branding.companyName}&nbsp;-&nbsp;
              </ST.CompanyNamePrefix>
              <ST.InputWIthCompanyNameStyled
                name="name"
                ref={register({
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
                maxLength={actualNameMaxLength}
              />
            </FieldStyle2>
            <Error message={errors.name?.message} />
            <T8y color="gray" fontSize="12px" marginBottom="20px">
              {formatString(
                trainings.remainingCharacters,
                nameRemainingSymbols,
              )}
            </T8y>
          </div>
          <div>
            <FieldStyle2 legend={users.info.address}>
              <InputStyled
                name="address"
                ref={register({
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
              />
            </FieldStyle2>
            <Error message={errors.address?.message} />
          </div>
          <div>
            <FieldStyle2 legend={form.fields.googleMapLink}>
              <InputStyled
                name="googleMapLink"
                ref={register({
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
              />
            </FieldStyle2>
            <Error message={errors.googleMapLink?.message} />
          </div>
          <div>
            <FieldStyle2 legend={form.fields.openingTimes}>
              <InputStyled
                name="openingTimes"
                ref={register({
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
              />
            </FieldStyle2>
            <Error message={errors.openingTimes?.message} />
          </div>
          <ST.LineWrapper>
            <div>
              <FieldStyle2 legend={form.fields.latitude}>
                <InputNumberStyled
                  name="latitude"
                  type="number"
                  ref={register({
                    required: form.notification.theFieldIsRequired,
                    validate: {
                      rangeOfValues: value =>
                        (value >= -90 && value <= 90) ||
                        formatString(
                          form.notification.valueShouldBeBetween,
                          -90,
                          90,
                        ),
                    },
                  })}
                />
              </FieldStyle2>
              <Error message={errors.latitude?.message} />
            </div>
            <div>
              <FieldStyle2 legend={form.fields.longitude}>
                <InputNumberStyled
                  name="longitude"
                  type="number"
                  ref={register({
                    required: form.notification.theFieldIsRequired,
                    validate: {
                      rangeOfValues: value =>
                        (value >= -180 && value <= 180) ||
                        formatString(
                          form.notification.valueShouldBeBetween,
                          -180,
                          180,
                        ),
                    },
                  })}
                />
              </FieldStyle2>
              <Error message={errors.longitude?.message} />
            </div>
          </ST.LineWrapper>
        </ST.DepotFormWrapper>
        <AddNewAndSaveCancelFooter
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          submitButtonText={!depot.id ? form.buttons.save : null}
        />
      </ExpandableSection>
      <DepotDeleteConfirmationModal
        dialogState={dialogStateConfirmationModal}
        depotName={depot.name}
        isDeleting={isDeleting}
        handleDeletingDepot={handleRemoveDepot}
        handleModalClose={dialogStateConfirmationModal.hide}
      />
      <DepotUnableDeleteModal
        dialogState={dialogStateUnableToDeleteModal}
        depotName={depot.name}
        handleModalClose={dialogStateUnableToDeleteModal.hide}
        depotRelatedProductsList={depotRelatedProductsList}
      />
    </>
  );
};

export default DepotForm;
