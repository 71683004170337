export const product = {
  titles: {
    createProduct: 'Crear un nuevo producto',
    goToAll: 'Ir a todos los productos',
    editProduct: 'Editar producto',
    addToArchive: 'Agregar al archivo',
    variations: 'Variaciones',
    uploadPhotos: 'Subir fotos',
    dropTheFiles: 'Suelta los archivos aquí',
    dragFileHere: 'Suelta los archivos aquí o',
    chooseFromYourComputer: 'Elige desde tu ordenador',
    createVariation: 'Crear una variación de un producto existente',
    generalInformation: 'Información general',
    pricing: 'Precios',
    additionalCharges: 'Cargos adicionales',
    seo: 'SEO',
    specifications: 'Especificaciones',
    faq: 'Preguntas frecuentes',
    reviews: 'Opiniones',
    frequentlyHiredTogether: 'Con frecuencia alquilados juntos',
    requiredExtra: 'Requerido extra',
    optionalExtra: 'Opcional extra',
    noRelatedProducts: 'No hay productos relacionados',
    noRequiredExtra: 'No se requieren extras',
    noOptionalExtra: 'No hay extras opcionales',
    createRequiredExtra: 'Crear extra requerido',
    createOptionalExtra: 'Crear extra opcional',
    uploadUserGuides: 'Subir guía de usuario',
    fileDownloaded: 'Archivo descargado',
    editAltTags: 'Editar alt tags',
    typicalUse: 'Uso típico',
    eventTypes: 'Tipo de eventos',
    thelastImage: 'La última imagen no puede ser eliminada',
    editRequiredExtra: 'Editar extra requerido',
    chooseFrequently: 'Elegir Con frecuencia alquilados juntos',
    chooseProduct: 'Elegir producto',
    warehouses: 'Disponibilidad de producto en sede',
    warehouseAvailability: 'Cantidad disponible',
    consumables: 'Consumibles',
    noConsumables: 'No consumibles',
    otherCharges: 'Otros cargos',
    chooseOptionalExtra: 'Elegir opcional extra',
    photo: 'Foto',
    extraCode: 'código',
    name: 'Nombre',
    price: 'Precio',
    saleItem: 'Producto',
    chooseRequiredExtra: 'Elegir extra requerido',
    videos: 'Videos',
    safetyRecommendations: 'Recomendaciones de seguridad',
    thirdParty: 'Integraciones de terceros',
    downloads: 'Descargas',
    productProfile: 'Perfil del producto',
    defaultDisplayedRange: 'Intervalo predefinido',
    platformProduct: 'Platform Product',
    linkProductWithPlatform: 'Link this product with a platform product',
  },

  notifications: {
    skuAlreadyBeenTaken: 'El SKU ya ha sido usado',
  },

  safetyRecommendations: {
    selectSafetyRecommendation: 'Selecciona recomendaciones de seguridad',
    listOfSafetyRecommendations: 'Lista de recomendaciones de seguridad',
  },

  thirdPartyIntegrations: {
    systemName: 'Nombre del sistema',
    integrationStatus: 'Estado de la integración',
    externalProductId: 'ID de producto externo',
    thirdPartiesNote:
      'Ten en cuenta que conectar al menos un producto externo para cualquier sistema activo deshabilitará la configuración en la sección "Disponibilidad del producto en el depósito.',
    successConnectModalTitle: 'Conectar producto externo por ID',
    response: 'Respuesta',
    productTitle: 'Título del producto',
    productDescription: 'Descripción del producto',
    okConnect: 'OK, conectar este producto',
    noIntegration: 'No hay integraciones',
    idIsNotConnected: 'El ID no se ha conectado aún',
    idIsAlreadyConnected: 'El ID ya existe',
    fieldIsEmpty: 'El campo está vacío',
    locationCode: 'Location code',
    error: 'Error',
  },
  selfCollectionOnly: 'Solo retiro en sede',
  deliveryOnly: 'Solo entrega',
  selfCollectionAndDelivery: 'Retiro en sede y entrega',
  changesNotSaved:
    'Los cambios no han sido guardados. ¿Estás seguro de querer salir de esta página?',
  day: 'día',
  draftMessage:
    'Este perfil de producto está en un borrador y no es accesible desde el sitio web público. Para que esta página de producto esté disponible en el sitio web público, haz clic en el botón Publicar. Esta acción es irreversible.',
  selectFranchisee: 'Seleccionar partner',
  productOwner: 'Propietario del producto',
  owner: 'Propietario',
};
