import styled from 'styled-components';

export const AddMoreLink = styled.div`
  display: flex;
  align-items: center;
  color: #ff6600;
  text-decoration: underline;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 400;

  ${p =>
    p.linkColor &&
    `
    color: ${p.linkColor};
  `}

  ${p =>
    p.inverseUnderline &&
    `
    text-decoration: none;
  `}

  &:hover {
    cursor: pointer;
    text-decoration: none;

    ${p =>
      p.inverseUnderline &&
      `
      text-decoration: underline;
    `}
  }
`;
