import React from 'react';
import { useStore } from 'effector-react';

import { settings$ } from '../../../models/settings';
import * as ST from './styles';

const AnalyticsDataStudio = () => {
  const settings = useStore(settings$);

  return (
    <ST.DataWrapper>
      <iframe
        src={`https://datastudio.google.com/embed/reporting/${settings?.dataStudioKey}/page/p_nq6z1rvqnc`}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          overflow: 'hidden',
        }}
        allowFullScreen
      />
    </ST.DataWrapper>
  );
};

export default AnalyticsDataStudio;
