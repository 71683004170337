import React from 'react';

import { SubmitButton } from './styles';

export const SubmitBtn = ({
  btnText,
  btnTextOnLoading,
  isLoading,
  handleClick,
}) => {
  return (
    <SubmitButton type="button" disabled={isLoading} onClick={handleClick}>
      {isLoading ? btnTextOnLoading : btnText}
    </SubmitButton>
  );
};
