import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks';
import { Lang } from 'lang';

import Error from 'components/atoms/Error';

import { InputStyled } from '../../Product/shared';
import * as ST from './styles';
import { useGetAddressesByPostcode } from './useGetAddressesByPostcode';
import { useManageHitsListVisibility } from './useManageHitsListVisibility';
import { useManageInputState } from './useManageInputState';

import { Row } from 'ui';

import { ReactComponent as OrangeArrow } from 'assets/icons/orangeArrow.svg';
import { ReactComponent as BackResults } from 'assets/icons/orangeBackResults.svg';

export const PostcoderInput = ({
  setEmptyAddress,
  changeDeliveryPostcodeAddress,
  register,
  values,
  name,
  placeholder,
}) => {
  const { users } = Lang();

  const [searchRequestError, setSearchRequestError] = useState(null);

  const postcodeListBlockRef = useRef(null);
  const inputRef = useRef(null);

  useOnClickOutside(
    postcodeListBlockRef,
    () => {
      setIsPostcodesListVisible(false);
    },
    '#uk_postcode',
  );

  const {
    makePostcodeListVisible,
    setIsPostcodesListVisible,
    isPostcodesListVisible,
  } = useManageHitsListVisibility({
    inputRef,
  });

  const {
    handleInputFocus,
    handleInputType,
    postCodeError,
    currPostCodeStr,
    setCurrPostCodeStr,
  } = useManageInputState({
    currentPostcode: values.address?.postcode,
    setIsPostcodesListVisible,
    makePostcodeListVisible,
    setEmptyAddress,
    searchRequestError,
  });

  const {
    handleBackToResultsClick,
    handleAddressClick,
    hits,
    isLoading,
    filterPaths,
  } = useGetAddressesByPostcode({
    setEmptyAddress,
    setCurrPostCodeStr,
    changeDeliveryPostcodeAddress,
    currPostCodeStr,
    makePostcodeListVisible,
    setIsPostcodesListVisible,
    postCodeError,
    setSearchRequestError,
  });

  useEffect(
    () => {
      if (searchRequestError) {
        setIsPostcodesListVisible(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchRequestError],
  );

  return (
    <Row fullWidth>
      <ST.PostcoderInputWrapper>
        <InputStyled
          placeholder={placeholder}
          name={name}
          ref={register}
          defaultValue={values?.defaultBillingAddress}
          onChange={handleInputType}
          onFocus={handleInputFocus}
        />
        {isPostcodesListVisible && (
          <ST.PostcodeListBlock ref={postcodeListBlockRef}>
            <div className={ST.PostcodeList}>
              {!!filterPaths.length && (
                <ST.BackResults
                  onClick={isLoading ? undefined : handleBackToResultsClick}
                >
                  <BackResults /> {users.backToResults}
                </ST.BackResults>
              )}
              {currPostCodeStr.length >= 3 && !hits.length && !isLoading && (
                <ST.PostcodeItem>{users.noItems}</ST.PostcodeItem>
              )}
              {hits.map((el, i) => (
                <ST.PostcodeItem
                  key={el.id}
                  onClick={() =>
                    isLoading ? undefined : handleAddressClick(el)
                  }
                  data-testid={`postcode-item-${i}`}
                >
                  <ST.SummaryText>
                    <ST.LocationTitle>{el.summaryline}</ST.LocationTitle>
                    <ST.LocationText>
                      {el.locationsummary}
                      {el.count > 1 && ` - ${el.count} Addresses`}
                    </ST.LocationText>
                  </ST.SummaryText>
                  {el.count > 1 && <OrangeArrow />}
                </ST.PostcodeItem>
              ))}
            </div>
          </ST.PostcodeListBlock>
        )}
      </ST.PostcoderInputWrapper>
      <Error message={postCodeError || searchRequestError} />
    </Row>
  );
};
