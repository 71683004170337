import React, { useEffect, useMemo } from 'react';
import { Lang } from 'lang';
import { capitalizeFirstLetter } from 'utils/strings';

import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const CalendarWeekends = ({ register, values, errors, setValue }) => {
  const { common } = Lang();

  const localizedWeekDays = useMemo(() => {
    return Object.keys(common.calendar.weekDays).map(el => ({
      slug: capitalizeFirstLetter(el),
      name: common.calendar.weekDays[el],
    }));
  }, [common.calendar.weekDays]);

  useEffect(() => {
    register('deliveryWeekends', []);
  }, [register]);

  return (
    <GeneralExpandableSection
      index={0}
      header={common.calendar.weekends}
      listOfFields={[]}
      errors={errors}
      initialIsOpen
    >
      <T8y fontSize="16px" marginBottom="20px">
        {common.calendar.deliverySelfCollection}
      </T8y>
      <T8y fontSize="14px" marginBottom="15px" color="gray">
        {common.calendar.tickDays}
      </T8y>
      <Row>
        {localizedWeekDays.map(label => (
          <ST.WeekLabel
            key={label.slug}
            onChange={() => {
              if (values.deliveryWeekends?.includes(label.slug)) {
                const newWeekends = values.deliveryWeekends.filter(
                  el => el !== label.slug,
                );
                setValue('deliveryWeekends', newWeekends);
              } else {
                setValue(
                  'deliveryWeekends',
                  values.deliveryWeekends?.length
                    ? [...values.deliveryWeekends, label.slug]
                    : [label.slug],
                );
              }
            }}
            label={label.name}
            checked={values.deliveryWeekends?.includes(label.slug)}
          />
        ))}
      </Row>
    </GeneralExpandableSection>
  );
};

export default CalendarWeekends;
