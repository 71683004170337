import React, { useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  changeHideTestTACompanies,
  changeSelectedTACompaniesLimit,
  changeTACompaniesTablePage,
  clearTradeAccounts,
  companiesGate,
  setTACompaniesFilter,
  setTACompaniesSearchStr,
  TACompaniesFilters$,
  tradeAccountsCompanies$,
  tradeAccountsCompaniesMeta$,
} from 'models/tradeAccounts';

import CustomButton from 'components/atoms/CustomButton';
import Table from 'components/atoms/Table';
import FiltersPanel from 'components/molecules/FiltersPanel';

import { StyledBillingAddress } from './styles';

import { Checkbox, Row, T8y, TablePaging } from 'ui';

const keyFields = [
  { name: 'name', type: 'text' },
  { name: 'identifier', type: 'text' },
  { name: 'tradingName', type: 'text' },
  { name: 'billingAddress', type: 'text' },
  { name: 'billingEmail', type: 'text' },
  { name: 'link', type: 'link' },
];

const CompanyTradeAccounts = () => {
  const {
    form: { fields },
    users: usersLocalized,
    tradeAccounts: tradeAccountsLocalized,
    formatString,
  } = Lang();

  const tradeAccounts = useStore(tradeAccountsCompanies$);
  const tradeAccountsMeta = useStore(tradeAccountsCompaniesMeta$);
  const tradeAccountsFilters = useStore(TACompaniesFilters$);

  useGate(companiesGate, tradeAccountsFilters);

  const history = useHistory();

  const filterOptions = useMemo(() => {
    return Object.keys(tradeAccountsLocalized.filterSelect).map(el => ({
      name: tradeAccountsLocalized.filterSelect[el],
      slug: el,
    }));
  }, [tradeAccountsLocalized]);

  const headers = useMemo(() => {
    return [
      usersLocalized.info.companyName,
      tradeAccountsLocalized.fields.companyNumber,
      tradeAccountsLocalized.fields.tradingName,
      tradeAccountsLocalized.fields.billingAddress,
      tradeAccountsLocalized.fields.billingEmailAddress,
      '',
    ];
  }, [tradeAccountsLocalized, usersLocalized]);

  useEffect(() => {
    return () => clearTradeAccounts();
  }, []);

  const limitOptions = useMemo(() => {
    return [
      { name: fields.showAll, slug: 10000 },
      { name: `${fields.show} 100`, slug: 100 },
      { name: `${fields.show} 50`, slug: 50 },
      { name: `${fields.show} 10`, slug: 10 },
    ];
  }, [fields]);

  const limitDisplayName = useMemo(() => {
    return limitOptions.find(({ slug }) => slug === tradeAccountsFilters.limit)
      .name;
  }, [limitOptions, tradeAccountsFilters]);

  const modifiedDisplayedUsers = useMemo(() => {
    return tradeAccounts.map(el => {
      const billingAdress = el.addresses?.find(el => el.type === 'billing')
        ?.address;

      return {
        name: el.name,
        identifier: el.identifier,
        tradingName: el.tradingName,
        billingAddress: (
          <StyledBillingAddress title={billingAdress}>
            {billingAdress}
          </StyledBillingAddress>
        ),
        billingEmail: el.billingEmail,
        link: (
          <Link to={PATHS.TRADE_ACCOUNT_PROFILE('companies', el.id)}>
            {usersLocalized.viewProfile}
          </Link>
        ),
      };
    });
  }, [tradeAccounts, usersLocalized]);

  const selected = useMemo(() => {
    return (
      filterOptions.find(el => el.slug === tradeAccountsFilters.selector)
        ?.name || ''
    );
  }, [filterOptions, tradeAccountsFilters]);

  return (
    <>
      <FiltersPanel
        selectOptions={filterOptions}
        selected={selected}
        onClickOption={setTACompaniesFilter}
        onChangeSearch={setTACompaniesSearchStr}
        searchStr={tradeAccountsFilters.search}
        searchPlaceholder={tradeAccountsLocalized.searchPlaceholder}
        foundStr={formatString(
          tradeAccountsLocalized.foundStr,
          tradeAccountsMeta.total || 0,
        )}
        limitOptions={limitOptions}
        limitDisplayName={limitDisplayName}
        handleLimitOptionChange={changeSelectedTACompaniesLimit}
        className="usersFilter"
      >
        <Row align="center" noWrap>
          <Checkbox
            onChange={e => changeHideTestTACompanies(e.target.checked)}
            checked={tradeAccountsFilters.hideTestUsers}
          />
          <T8y color="negative">{tradeAccountsLocalized.hideTestAccounts}</T8y>
        </Row>
        <CustomButton
          text="Add new Company"
          backgroundColor="primary"
          color="positive"
          border="1px solid #f89739"
          colorHovered="gray"
          onClick={() => {
            history.push(PATHS.TRADE_ACCOUNT_PROFILE('companies', 'new'));
          }}
        />
      </FiltersPanel>
      <Table
        headers={headers}
        keyFields={keyFields}
        items={modifiedDisplayedUsers}
        cols="2fr 2fr 2fr 2fr 3fr 2fr"
      />
      {tradeAccountsMeta.total_pages > 1 && (
        <TablePaging
          handlePageChange={changeTACompaniesTablePage}
          totalPages={tradeAccountsMeta.total_pages}
          currPage={tradeAccountsMeta.current_page}
        />
      )}
    </>
  );
};

export default CompanyTradeAccounts;
