import React from 'react';
import { Menu, MenuItem, useMenuState } from 'reakit';

import * as ST from './styles';

import { Icon, T8y } from 'ui';

import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';

const OptionSelector = ({
  options,
  handleOptionChoose,
  displayName,
  selectorTitle,
  textAlign,
}) => {
  const menu = useMenuState();
  return (
    <ST.SortDivWrapper>
      <ST.T8yStyled color="darkBlue" bold>
        {selectorTitle}
      </ST.T8yStyled>
      <ST.MenuButtonStyled {...menu}>
        <T8y color="primary" bold>
          {displayName}
        </T8y>
        <Icon as={SortIcon} />
      </ST.MenuButtonStyled>

      <Menu {...menu} as={ST.OptionsStyled}>
        {options.map(({ name, slug }, i) => (
          <MenuItem
            {...menu}
            key={i}
            onClick={() => {
              handleOptionChoose(slug);
              menu.hide();
            }}
            as={ST.OptionStyled}
            textAlign={textAlign}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </ST.SortDivWrapper>
  );
};

export default OptionSelector;
