export const settings = {
  titles: {
    transportation: 'Trasporto',
    costCalculationMethod: 'Metodo di calcolo costi',
    routeAvailability: 'Disponibilità percorso',
    transportationCostVat: 'Costo trasporto',
    additionalCharges: 'Costi aggiuntivi',
    regionalSettings: 'Impostazioni Regionali',
    calendarSetup: 'Impostazioni calendario',
    depots: 'Filiali',
  },

  priceByArea: 'Prezzo per zona',
  priceByKm: 'Prezzo al chilometro',
  area: 'Zona',
  priceByPostalCode: 'Prezzo per CAP',
  priceByRadius: 'Prezzo per raggio',
  postcode: 'CAP',
  depotToLocation: 'Alla zona di consegna',
  locationToDepot: 'Al negozio',
  bothWaysDelivery: 'Consegna e ritiro',
  depot: 'Filiale',
  outOfRangeNotAvailable: 'La consegna fuori dai limite non è disponibile',
  outOfRangeNotAvailableMsg:
    'Se questa casella  è abilitata, il messaggio "Spiacenti, non supportiamo la consegna al tuo indirizzo..." verrà mostrato durante il processo di pagamento agli utenti il cui indirizzo non è supportato.',
  copyToOtherDepots: 'Copia questa configurazione in altre filiali',
  rangeInKm: 'Distanza in {0}',
  depotToLocationByRadius: 'Dalla filiale all’indirizzo di consegna',
  locationToDepotByRadius: "Dall'indirizzo di consegna alla filiale",
  bothWaysDeliveryByRadius: 'Consegna e ritiro',
  thisRangeNotAvailable: 'A questo indirizzo la consegna non è disponibile',
  addNewRange: 'Aggiungi nuovo intervallo',
  showAllDepots: 'Espandi e mostra tutte le {0} filiali',
  showFirstDepotOnly: 'Comprimi e mostra solo la prima filiale',
  useBrToSeparate: 'Utilizza la "<br>" per separare più elementi',

  modals: {
    deleteConfirmationTitle: 'Conferma cancellazione',
    deleteConfirmationText:
      'Sei sicuro di voler cancellare {0} dalla lista dei tipo di consegna disponibili?',
    deleteMessageTitle: 'Avviso Cancellazione',
    deleteMessageText:
      'La modalità di consegna {0} è attualmente usata per alcuni prodotti nel catalogo. Se vuoi cancellarla, per favore assegna una nuova modalità a tali prodotti.',
    copyConfigurationTitle: 'Copia la configurazione',
    copyConfigurationText:
      'Sei sicuro di voler copiare questa configurazione? Questa azione sovrascriverà i valori di intervallo/costo in tutte le altre filiali e non potrai annullarla.',
    deleteDistanceRangeConfirmationTitle: 'Elimina intervallo di distanza',
    deleteDistanceRangeConfirmationText:
      'Sei sicuro di voler eliminare questo intervallo? Non potrai annullare questa azione.',
    depotDeleteConfirmationText:
      "Sei sicuro di voler eliminare {0} dall'elenco delle filiali?",
    depotDeleteMessageText:
      'La filiale {0} è utilizzata per alcuni prodotti nel catalogo. Se desideri comunque eliminarla, contrassegna prima questi prodotti come "Out of stock".',
  },
};
