import Error from 'components/atoms/Error';
import { InputNumberStyled } from 'components/Product/shared';
import styled from 'styled-components';

import { Grid, Row } from 'ui';

export const RangeRow = styled(Grid)`
  color: #b3b7bb;
`;

export const RangeItem = styled(Row)`
  align-items: center;

  &:not(:first-child) {
    margin-left: 40px;
  }
`;

export const DistanceRangeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 60px;
`;

export const DistanceRangeInputStyled = styled(InputNumberStyled)`
  width: 60px;
`;

export const RangeInputsSeparator = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  height: 44px;
`;

export const PriceInputWrapper = styled.div`
  color: #424242;
  width: 110px;
  span {
    font-size: 14px;
    ${p => p.readOnly && 'color:#b3b7bb;'}
  }

  input:read-only {
    color: #b3b7bb;
    background-color: #fbfbfb;
  }
`;

export const AdditionalColumWrapper = styled(Row)`
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
`;

export const ErrorStyled = styled(Error)`
  ${p => p.withMarginLeft && 'margin-left: 30px'};
  margin-bottom: 10px;
  height: auto;
  min-height: 20px;
`;
