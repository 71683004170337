import { analytics } from './en-gb/analytics';
import { categories } from './en-gb/categories';
import { common } from './en-gb/common';
import { form } from './en-gb/form';
import { header } from './en-gb/header';
import { leads } from './en-gb/leads';
import { marketing } from './en-gb/marketing';
import { orders } from './en-gb/orders';
import { product } from './en-gb/product';
import { promoCodes } from './en-gb/promoCodes';
import { settings } from './en-gb/settings';
import { tradeAccounts } from './en-gb/tradeAccounts';
import { trainings } from './en-gb/trainings';
import { users } from './en-gb/users';

export const enGb = {
  header,
  product,
  marketing,
  form,
  categories,
  analytics,
  users,
  tradeAccounts,
  settings,
  trainings,
  common,
  orders,
  leads,
  promoCodes,
};
