import React, { useMemo } from 'react';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { categoriesTreeStoreLocalized$ } from 'models/categories';
import { owners$, ownersGate } from 'models/owners';

import {
  changeSelectedOwner,
  changeSelectedProductsLimit,
  changeSelectedProductsSortBy,
  changeSelectedShow,
  selectedOwner$,
  selectedProductsLimit$,
  selectedProductsSortBy$,
  selectedShow$,
} from 'features/filter/filterModel';

import OptionSelector from 'components/atoms/OptionSelector';

const ProductFilterPanel = () => {
  const {
    header: { titles },
    form: { fields },
    users: { filterSelect },
    product,
  } = Lang();

  const categoriesTreeStoreLocalized = useStore(categoriesTreeStoreLocalized$);

  const selectedProductsLimit = useStore(selectedProductsLimit$);
  const selectedProductsSortBy = useStore(selectedProductsSortBy$);
  const selectedOwner = useStore(selectedOwner$);
  const selectedShow = useStore(selectedShow$);
  const owners = useStore(owners$);

  useGate(ownersGate);

  const formattedOwnersList = useMemo(
    () => [
      { name: filterSelect.all, slug: DEFAULT_SELECTOR_STATE },
      ...owners.map(el => ({ ...el, slug: el.id })),
    ],
    [filterSelect.all, owners],
  );

  const limitOptions = useMemo(() => {
    return [
      { name: filterSelect.all, slug: 10000 },
      { name: '100', slug: 100 },
      { name: '50', slug: 50 },
      { name: '10', slug: 10 },
    ];
  }, [filterSelect.all]);

  const sortOptions = useMemo(() => {
    return [
      { name: fields.date, slug: 'updated_at' },
      { name: fields.popularity, slug: 'popularity' },
    ];
  }, [fields]);

  const categoriesOptions = useMemo(() => {
    let optionsCats = categoriesTreeStoreLocalized.map(el => ({
      name: el.name,
      slug: el.id,
    }));

    return [
      { name: fields.all, slug: DEFAULT_SELECTOR_STATE },
      { name: fields.drafts, slug: 'hidden' },
      { name: fields.unavailable, slug: 'notAvailable' },
      { name: fields.outOfStock, slug: 'outOfStock' },
      ...optionsCats,
    ];
  }, [categoriesTreeStoreLocalized, fields]);

  const ownerDisplayName = useMemo(() => {
    return formattedOwnersList.find(({ slug }) => slug === selectedOwner).name;
  }, [formattedOwnersList, selectedOwner]);

  const sortDisplayName = useMemo(() => {
    return sortOptions.find(({ slug }) => slug === selectedProductsSortBy).name;
  }, [selectedProductsSortBy, sortOptions]);

  const limitDisplayName = useMemo(() => {
    return limitOptions.find(({ slug }) => slug === selectedProductsLimit).name;
  }, [limitOptions, selectedProductsLimit]);

  const categoryDisplayName = useMemo(() => {
    return categoriesOptions.find(({ slug }) => slug === selectedShow)?.name;
  }, [categoriesOptions, selectedShow]);

  return (
    <>
      <OptionSelector
        options={formattedOwnersList}
        handleOptionChoose={changeSelectedOwner}
        displayName={ownerDisplayName}
        selectorTitle={`${product.owner}:`}
      />
      <OptionSelector
        options={categoriesOptions}
        handleOptionChoose={changeSelectedShow}
        displayName={categoryDisplayName}
        selectorTitle={`${fields.show}:`}
      />
      <OptionSelector
        options={sortOptions}
        handleOptionChoose={changeSelectedProductsSortBy}
        displayName={sortDisplayName}
        selectorTitle={`${titles.sortBy}:`}
      />
      <OptionSelector
        options={limitOptions}
        handleOptionChoose={changeSelectedProductsLimit}
        displayName={limitDisplayName}
        selectorTitle={`${titles.productsOnPage}:`}
        textAlign="center"
      />
    </>
  );
};

export default ProductFilterPanel;
