import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';

import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';
import ShowSection from 'components/atoms/ShowSection';

import GeneralExpandableSection from '../GeneralExpandableSection';

const TrainingFormLearning = ({
  errors,
  getIsError,
  setValue,
  watch,
  clearErrors,
  trigger,
  control,
  isNew,
}) => {
  const { trainings, form } = Lang();

  const watchSectionShow = watch('form.learningShow');

  const trainingLocalized = useStore(trainingLocalized$);

  const handleLearningShow = useCallback(
    value => {
      setValue('form.learningShow', value);

      if (!value) {
        clearErrors('form.learningText');
      } else {
        trigger('form.learningText');
      }
    },
    [clearErrors, setValue, trigger],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.courseLearning}
      listOfFields={['learningText']}
      errors={errors}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={isNew || trainingLocalized?.learningShow}
          handleChange={handleLearningShow}
        />
      }
    >
      <Controller
        as={<ReachEditor />}
        name="form.learningText"
        control={control}
        defaultValue={trainingLocalized?.learningText || null}
        initialValue={trainingLocalized?.learningText || null}
        rules={
          watchSectionShow && {
            required: form.notification.theFieldIsRequired,
          }
        }
      />
      <Error message={getIsError('learningText')} />
    </GeneralExpandableSection>
  );
};

export default TrainingFormLearning;
