import styled from 'styled-components';

import { Button } from 'ui';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${p => p.$align};
  padding: 20px;
  text-align: center;
  background-color: #fff;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;
