import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';

export const useGetProductsWithExtra = products => {
  const currentLangState = useStore(currentLang$);

  return useMemo(
    () =>
      products?.reduce((sum, el) => {
        sum.push(el);

        if (el.attributes?.length) {
          el.attributes.forEach(attr => {
            sum.push({
              ...attr,
              sku: attr.id,
              image: { thumb: attr.src },
              names: { [currentLangState]: attr.value },
              startDate: el.startDate,
              endDate: el.endDate,
              price: attr.price,
              total: attr.total,
              type: 'extra',
            });
          });
        }

        return sum;
      }, []) || [],
    [currentLangState, products],
  );
};
