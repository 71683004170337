import React from 'react';
import { Lang } from 'lang';
import { isUrl } from 'utils/patterns';

import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import Error from '../Error';
import * as ST from './styles';

import { T8y } from 'ui';

const PrerenderInputFields = ({ values, errors, register, controlField }) => {
  const {
    formatString,
    categories,
    form: { notification },
  } = Lang();

  return (
    <>
      <ST.PrerenderStatusCodeInputWrapper direction="column" fullWidth>
        <FieldStyle as={FieldStyle2} legend={categories.prerenderStatusCode}>
          <InputStyled
            name="prerenderStatusCode"
            type="number"
            ref={register({
              required: values[controlField] && notification.theFieldIsRequired,
              validate: {
                positive: value => {
                  if (!values[controlField]) {
                    return true;
                  }

                  return (
                    value.length === 3 ||
                    formatString(notification.requiredLength, 3)
                  );
                },
              },
            })}
            disabled={!values[controlField]}
          />
        </FieldStyle>
        <Error
          message={
            errors &&
            errors.prerenderStatusCode &&
            errors.prerenderStatusCode.message
          }
        />
      </ST.PrerenderStatusCodeInputWrapper>
      <T8y marginTop="4px" color="gray" fontSize="12px">
        {categories.prerenderStatusNote}
      </T8y>
      <ST.PrerenderHeaderInputWrapper direction="column" fullWidth>
        <FieldStyle as={FieldStyle2} legend={categories.prerenderHeader}>
          <InputStyled
            name="prerenderHeaderLocation"
            type="text"
            ref={register({
              required:
                (values.prerenderStatusCode || '').startsWith('3') &&
                notification.theFieldIsRequired,
              validate: (values.prerenderStatusCode || '').startsWith('3') && {
                url: value => isUrl(value) || notification.valueShouldBeValid,
              },
            })}
            disabled={!values[controlField]}
          />
        </FieldStyle>
        <Error
          message={
            errors &&
            errors.prerenderHeaderLocation &&
            errors.prerenderHeaderLocation.message
          }
        />
      </ST.PrerenderHeaderInputWrapper>
    </>
  );
};

export default PrerenderInputFields;
