import { useCallback, useMemo } from 'react';
import { Lang } from 'lang';
import { getNearestDateIndex } from 'utils/dates';

const emptyData = {
  closestDate: null,
  locationNames: [],
  locationAddresses: [],
  price: null,
  organizer: null,
};

export const useClosestTrainingDate = item => {
  const { analytics } = Lang();

  const getClosestDateFormattedString = useCallback(
    datesInfo => {
      const startDate = new Date(datesInfo.start).getDate();
      const startYear = new Date(datesInfo.start).getFullYear();

      const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' })
        .format;
      const firstMonthName =
        analytics.months[monthName(new Date(datesInfo.start)).toLowerCase()];

      if (!datesInfo.end || datesInfo.start === datesInfo.end) {
        return `${startDate} ${firstMonthName} ${startYear}`;
      }

      const endDate = new Date(datesInfo.end).getDate();
      const endYear = new Date(datesInfo.end).getFullYear();
      const secondMonthName =
        analytics.months[monthName(new Date(datesInfo.end)).toLowerCase()];

      const isOneMonth = firstMonthName === secondMonthName;
      const isOneYear = startYear === endYear;

      if (isOneMonth && isOneYear) {
        return `${startDate}\u2013${endDate} ${secondMonthName} ${endYear}`;
      }

      return `${startDate} ${isOneMonth ? '' : firstMonthName} ${
        isOneYear ? '' : startYear
      } \u2013 ${endDate} ${secondMonthName} ${endYear}`;
    },
    [analytics.months],
  );

  return useMemo(() => {
    if (!item) {
      return {
        ...emptyData,
      };
    }

    if (item.scheduleType === 0 && item.schedules) {
      const now = Date.now();
      const datesLocationsList = item.schedules.reduce((acc, location) => {
        return [
          ...acc,
          ...location.variations
            .filter(el => el.start && new Date(el.start).getTime() > now)
            .map(el => ({
              date: el.start,
              locationId: location.id,
              dateId: el.id,
            })),
        ];
      }, []);

      const nearestDateIndex = getNearestDateIndex(datesLocationsList);

      if (isNaN(nearestDateIndex)) {
        return {
          ...emptyData,
        };
      }
      const locationId = datesLocationsList[nearestDateIndex].locationId;
      const nearestDateId = datesLocationsList[nearestDateIndex]?.dateId;

      const locationInfo = item.schedules.find(el => el.id === locationId);
      const datesInfo = locationInfo?.variations.find(
        el => el.id === nearestDateId,
      );

      let closestDate = getClosestDateFormattedString(datesInfo);
      const price = datesInfo.price;
      const location = locationInfo?.location;

      return {
        closestDate,
        locationNames: [location?.name],
        locationAddresses: [{ lat: location?.lat, lng: location?.lng }],
        organizer: locationInfo?.organizer,
        price,
      };
    } else {
      const activeLocations =
        item.noSchedule?.locations &&
        !!item.noSchedule.locations.filter(el => !!el.placeId).length;

      return {
        closestDate: null,
        locationNames: activeLocations
          ? item.noSchedule.locations.map(el => el.name)
          : [],
        locationAddresses: activeLocations
          ? item.noSchedule.locations.map(el => ({
              lat: el.lat,
              lng: el.lng,
            }))
          : [],
        price:
          item.noSchedule?.variations?.length === 1
            ? item.noSchedule.variations[0].price
            : null,
        organizer: item.noSchedule?.organizer,
      };
    }
  }, [getClosestDateFormattedString, item]);
};
