import styled from 'styled-components';

import { Row, T8y } from 'ui';

import { ReactComponent as LinkIcon } from 'assets/images/icon/linkIcon.svg';

export const OrderInfoHeader = styled(Row)`
  margin-bottom: 20px;
`;

export const OrderInfoFeature = styled(T8y)`
  color: #2e2e2e;
`;

export const LinkIconStyled = styled(LinkIcon)`
  flex-shrink: 0;
`;

export const LeadRequestsSection = styled.div`
  padding: 12px 24px 2px;
  background-color: #fffdfd;
  border: 1px solid #e28787;
  border-radius: 5px;
  margin-bottom: 30px;

  & > div:first-child {
    color: #222;
  }
`;

export const CancellationReason = styled(T8y)`
  font-style: italic;
`;
