import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import {
  categoryPhotoStore,
  categoryStore,
  loadCategoryGate,
  reloadCategories,
} from 'models/categories';
import * as productModel from 'models/product';
import { settings$ } from 'models/settings';
import { attachFile, deleteAttached } from 'utils/filesDownload';

import { PrerenderSection } from 'components/atoms/PrerenderSection';
import { ButtonsBottom } from 'components/Category/Buttons';
import { General } from 'components/Category/General';
import { RowInfo } from 'components/Category/RowInfo';
import { Seo } from 'components/Category/Seo';
import { UploadImage } from 'components/Category/UploadImage';
import CategoryBannerSlider from 'components/molecules/CategoryBannersSlider';
import HistoryTable from 'components/molecules/HistoryTable';
import { history } from 'libs/history';
import { CATEGORIES } from 'api';

import { Grid } from 'ui';

const CategoryEditor = ({ match }) => {
  const category_id = match.params.id || null;

  const isNewCategory = useMemo(() => {
    return !match.params.id;
  }, [match]);

  const {
    categories: { titles },
    form: { buttons, toastTitle, fields: fieldsLocalization },
  } = Lang();

  useGate(loadCategoryGate, category_id);

  const category = useStore(categoryStore);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    errors,
    reset,
    control,
    setError,
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: {
      names: (category && category.names) || null,
      seo_h1: (category && category.seo_h1) || null,
      slides: [
        {
          file_id_mobile: '',
          file_id_web: '',
          srcWeb: '',
          srcMobile: '',
          cta: '',
          btnUrl: '',
          serverId: '',
          target_blank: '',
        },
      ],
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { fields } = useFieldArray({
    control,
    name: 'slides',
  });

  const categoryPhoto = useStore(categoryPhotoStore);
  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);

  const [loading, setLoading] = useState(false);
  const [deletedId, setDeletedId] = useState(null);

  const attachPhotoToCategory = useCallback(
    async extraId => {
      if (
        categoryPhoto?.src &&
        categoryPhoto?.src !== category.image?.data?.main
      ) {
        await attachFile({
          src: categoryPhoto.src,
          object_id: extraId,
          type: 'category',
        });
      }
    },
    [category.image, categoryPhoto],
  );

  useEffect(() => {
    const isMediaWeb = !!category.bannerWeb;
    const isMediaMobile = !!category.bannerMobile;

    reset({
      meta_description: category.meta_description,
      seo_h1: { ...category.seo_h1 },
      seo_robots: category.seo_robots,
      seo_title: category.seo_title,
      id: category.id,
      names: { ...category.names },
      descriptions: { ...category.descriptions },
      hidden: category.hidden,
      prerenderStatusCode: category.prerenderStatusCode,
      prerenderHeaderLocation: category.prerenderHeaderLocation,
      slides: [
        {
          file_id_web: isMediaWeb ? category.bannerWeb.data.id : '',
          file_id_mobile: isMediaMobile ? category.bannerMobile.data.id : '',
          srcWeb: isMediaWeb ? category.bannerWeb.data.main : '',
          srcMobile: isMediaMobile ? category.bannerMobile.data.main : '',
          cta: category.cta,
          target_blank: category.targetBlank,
          btnUrl: category.btnUrl,
          serverId: category.id,
        },
      ],
    });
  }, [category, reset]);

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const managePictures = useCallback(
    async slide => {
      if (slide.srcWeb && slide.srcWeb !== category.bannerWeb?.data.main) {
        await new Promise(resolve => {
          productModel.uploadPhotoFx({
            src: slide.srcWeb,
            name: 'category web banner',
            object_id: category_id,
            type: 'categoryBannerWeb',
          });
          resolve();
        });
      }

      if (
        slide.srcMobile &&
        slide.srcMobile !== category.bannerMobile?.data.main
      ) {
        await new Promise(resolve => {
          productModel.uploadPhotoFx({
            src: slide.srcMobile,
            name: 'category mobile banner',
            object_id: category_id,
            type: 'categoryBannerMobile',
          });
          resolve();
        });
      }

      if (!slide.file_id_web && category.bannerWeb?.data.id) {
        await new Promise(resolve => {
          deleteAttached(category.bannerWeb?.data.id);
          resolve();
        });
      }

      if (!slide.file_id_mobile && category.bannerMobile?.data.id) {
        await new Promise(resolve => {
          deleteAttached(category.bannerMobile?.data.id);
          resolve();
        });
      }
    },
    [category.bannerMobile, category.bannerWeb, category_id],
  );

  const onSubmit = async data => {
    const bannerData = data.slides[0];

    await managePictures(bannerData);

    setLoading(true);

    let parent_id = parseInt(data.parent_id, 10);
    if (isNaN(parent_id)) {
      parent_id = 0;
    }
    if (parent_id === 0) {
      parent_id = null;
    }

    delete data.slides;

    const result = {
      ...data,
      parent_id,
      prerenderStatusCode: data.prerenderStatusCode || null,
      prerenderHeaderLocation: data.prerenderHeaderLocation || null,
      cta: bannerData.cta,
      targetBlank: bannerData.target_blank,
      btnUrl: bannerData.btnUrl,
    };

    if (deletedId) {
      await deleteAttached(deletedId);
    }

    try {
      if (category_id) {
        await CATEGORIES.update(category_id, result);
        await attachPhotoToCategory(category_id);
      } else {
        const { data } = await CATEGORIES.create(result);
        await attachPhotoToCategory(data && data.data.id);
      }

      reloadCategories();

      toast(
        `${fieldsLocalization.category} ${result.names[currentLangState] ||
          result.names[settings.defaultLanguage]} ${
          category_id ? toastTitle.wasUpdated : toastTitle.wasCreated
        }`,
      );

      history.push(PATHS.MANAGE);
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        toast.error(err.response.data.message);
      } else {
        toast.error(toastTitle.wentWrong);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RowInfo
          loading={loading}
          title={category_id ? titles.editCategory : titles.createCategory}
          submitButtonTitle={category_id ? buttons.update : buttons.create}
          loadingTitle={category_id ? titles.updating + '...' : titles.creating}
        />

        <Grid cols="3fr 1.5fr" gaps="0 3rem">
          <div>
            <General
              register={register}
              isNewCategory={isNewCategory}
              setValue={setValue}
              errors={errors}
              values={values}
            />
            <Seo
              register={register}
              isNewCategory={isNewCategory}
              setValue={setValue}
              values={values}
            />
            <PrerenderSection
              register={register}
              setValue={setValue}
              errors={errors}
              values={values}
              clearErrors={clearErrors}
            />
            <CategoryBannerSlider
              fields={fields}
              values={values}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              setError={setError}
              trigger={trigger}
              watch={watch}
            />
          </div>

          <div>
            <UploadImage
              deletedId={deletedId}
              setDeletedId={setDeletedId}
              category_id={category_id}
            />
          </div>
        </Grid>

        <ButtonsBottom
          loading={loading}
          submitButtonTitle={category_id ? buttons.update : buttons.create}
        />
      </form>
      <HistoryTable id={category_id} type="categories" />
    </>
  );
};

export default CategoryEditor;
