import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { createDebounce } from 'utils/helpers/timeouts';

import { ANALYTICS } from 'api';

export const loadReportsFx = createEffect({
  handler: async () => {
    const { data } = await ANALYTICS.getAllReports();

    return data.data;
  },
});

export const createReportFx = createEffect({
  handler: async report => {
    try {
      const { data } = await ANALYTICS.createReport({ ...report });
      return data;
    } catch (e) {
      return false;
    }
  },
});

export const updateReportFx = createEffect({
  handler: async info => {
    try {
      const { data } = await ANALYTICS.updateReport(info.id, {
        ...info.report,
      });
      return data;
    } catch (e) {
      return false;
    }
  },
});

export const deleteReportFx = createEffect({
  handler: async id => {
    try {
      const { error } = await ANALYTICS.deleteReport(id);
      return !error;
    } catch (e) {
      return false;
    }
  },
});

export const setUserSettings = createEvent();
export const getReports = createEvent();
export const deleteReport = createEvent();

forward({
  from: getReports,
  to: loadReportsFx,
});

forward({
  from: deleteReport,
  to: deleteReportFx,
});

export const setReportFilter = createEvent();
export const reportsFilters$ = createStore('All').on(
  setReportFilter,
  (s, p) => p,
);

export const setReportsSearchStr = createEvent();
export const searchStr$ = restore(setReportsSearchStr, '');

export const setReportsSearchStrDebounced = createDebounce(
  setReportsSearchStr,
  100,
);

const searchStrDebounced$ = restore(setReportsSearchStrDebounced, '');

export const reports$ = restore(loadReportsFx, []);

export const displayedReports$ = createStore([])
  .on(loadReportsFx.doneData, (s, p) => {
    return p;
  })
  .on(searchStrDebounced$.updates, (s, p) => {
    if (!p.length) {
      return reports$.getState();
    }

    return s.filter(el => el.name.toLowerCase().indexOf(p) >= 0);
  });

export const setEditedReport = createEvent();
export const editedReport$ = restore(setEditedReport, null);
