import React, { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { isProdEnv } from 'consts';
import { useStore } from 'effector-react';
import { useOrder } from 'hooks';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { branding$ } from 'models/branding';
import { getLead, setLeadDocs } from 'models/lead';
import { isOrderUpdating$, setIsOrderUpdating } from 'models/order';
import { settings$ } from 'models/settings';
import { getUser, setActualDocsToOrderUser } from 'models/users';
import { website$ } from 'models/website';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

import OrderContactSection from 'components/atoms/OrderContactSection';
import { OrderInfoBlock } from 'components/atoms/OrderInfoBlock/styles';
import { OrderInfoRow } from 'components/atoms/OrderInfoRow/styles';
import { OrderInfoWrapper } from 'components/atoms/OrderInfoWrapper/styles';
import CancelOrderModal from 'components/molecules/CancelOrderModal';
import OrderDocuments from 'components/molecules/OrderDocuments';
import { ORDERS } from 'api';

import * as ST from './styles';

import { Radio, Row, T8y } from 'ui';

const LeadOrderInfoNew = ({
  order,
  userId,
  isQuote,
  isTraining,
  isUserPage,
}) => {
  const { orders, leads, form } = Lang();

  const settings = useStore(settings$);
  const branding = useStore(branding$);
  const website = useStore(website$);
  const isOrderUpdating = useStore(isOrderUpdating$);

  const currentLangState = useStore(currentLang$);

  const radio = useRadioState({
    state: order.payment?.deposit?.refundAuto ? 'automatic' : 'manual',
  });

  const isDepositRefunded =
    order.payment?.deposit?.status?.toLowerCase() === 'refund';

  const onUpdateCallback = useCallback(() => {
    if (isUserPage) {
      getUser(userId);
    }
    getLead({ id: order.orderId, type: order.type });
  }, [isUserPage, order, userId]);

  const onAddDocumentCallback = useCallback(
    (newDocs, type) => {
      if (isUserPage) {
        setActualDocsToOrderUser({
          id: order.orderId,
          newDocs,
          type,
        });
      }
      setLeadDocs({
        id: order.orderId,
        newDocs,
        type,
      });
    },
    [isUserPage, order],
  );

  const updateDepositRefundMethod = useCallback(
    async e => {
      const value = e.target.value;

      setIsOrderUpdating(true);
      try {
        await ORDERS.updateDepositRefundMethod(order.orderId, {
          refundAuto: value === 'automatic',
        });
        onUpdateCallback();
        toast.success(form.toastTitle.successfullyUpdated);
      } catch (e) {
        console.warn(e);
        radio.setState(value === 'manual' ? 'automatic' : 'manual');
        toast.error(form.toastTitle.wentWrong);
      } finally {
        setIsOrderUpdating(false);
      }
    },
    [form, onUpdateCallback, order, radio],
  );

  const {
    cancelRequest,
    isCancelOrderLoading,
    setIsCancelOrderLoading,
    addDocumentToOrder,
    deleteDocumentFromOrder,
    isAddFilesBlocked,
    isAddInvoiceBlocked,
  } = useOrder(order, onAddDocumentCallback, onUpdateCallback);

  const leadSource = useMemo(() => {
    if (!order.products) {
      return null;
    }

    if (isTraining) {
      return `${branding.webSiteUrl}/trainings/${order.products[0]?.slug}`;
    }

    if (order.origin === 'wilkotoolhire.com') {
      return isProdEnv
        ? `https://wilkotoolhire.com/${website.regionSlug}/${order.products[0]?.slug}`
        : `https://w-stage.easytoolhire.com/${website.regionSlug}/${order.products[0]?.slug}`;
    }

    return `${branding.webSiteUrl}/${order.products[0]?.slug}`;
  }, [branding.webSiteUrl, isTraining, order, website]);

  const addressName =
    order.deliveryMethod === 'selfCollection'
      ? order.warehouse?.name
      : order.deliveryAddress;

  const showCancelOrderBtn =
    isUserPage && order.status === 'new' && !cancelRequest;

  const paymentLink = order.payment?.link || order.loanLink;

  const crmLink = useMemo(
    () =>
      window.location.hostname.includes('stage')
        ? 'https://stage-easycrm.myfreshworks.com/crm/sales/'
        : 'https://easycrm.myfreshworks.com/crm/sales/',
    [],
  );

  useEffect(
    () => {
      radio.setState(
        order.payment?.deposit?.refundAuto ? 'automatic' : 'manual',
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.payment],
  );

  return (
    <>
      <OrderInfoWrapper>
        <ST.OrderInfoHeader justify="stretch">
          <T8y color="darkBlue" fontSize="20px" bold>
            {orders.order} #{order.orderNumber || order.orderId} (
            {leads.types[order.type]})
          </T8y>
          {showCancelOrderBtn &&
            (isCancelOrderLoading ? (
              <T8y color="gray2" fontSize="18px" cursor="not-allowed">
                {orders.cancelOrder}
              </T8y>
            ) : (
              <CancelOrderModal
                userId={userId}
                orderId={order.orderId}
                orderType={order.type}
                setIsCancelOrderLoading={setIsCancelOrderLoading}
              />
            ))}
        </ST.OrderInfoHeader>
        <OrderInfoRow>
          <OrderInfoBlock>
            {!isQuote && !isTraining && (
              <T8y fontSize="18px" color="darkBlue" marginBottom="6px" bold>
                {orders.item.orderStatus}:{' '}
                {orders.statusType[order.status] || '-'}
              </T8y>
            )}

            <ST.OrderInfoFeature fontSize="16px">
              {orders.item.date}: {order.created_at}
            </ST.OrderInfoFeature>
          </OrderInfoBlock>
        </OrderInfoRow>
        <OrderInfoRow>
          <OrderInfoBlock short={order.type !== 'product'} withTopBorder>
            <OrderContactSection order={order} isTraining={isTraining} />
          </OrderInfoBlock>
          {order.type !== 'product' && (
            <OrderInfoBlock short withTopBorder>
              <T8y fontSize="18px" color="darkBlue" marginBottom="6px" bold>
                {leads.tableHeaders.leadSource}
              </T8y>
              <Row
                as="a"
                href={leadSource}
                target="_blank"
                rel="noopener noreferrer"
                align="center"
                noWrap
              >
                <T8y fontSize="16px" color="primary" marginRight="8px">
                  {getTranslatedFieldValue(
                    order.products[0]?.names,
                    currentLangState,
                    settings.defaultLanguage,
                  )}
                </T8y>
                <ST.LinkIconStyled />
              </Row>
            </OrderInfoBlock>
          )}
        </OrderInfoRow>
        {order.message && (
          <OrderInfoRow>
            <OrderInfoBlock withTopBorder>
              <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                {leads.message}
              </T8y>
              <ST.OrderInfoFeature fontSize="16px">
                {order.message}
              </ST.OrderInfoFeature>
            </OrderInfoBlock>
          </OrderInfoRow>
        )}
        <OrderInfoRow>
          {!isTraining && (
            <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
              <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                {orders.orderTableHeaders.deliveryMethod}
              </T8y>
              <ST.OrderInfoFeature>
                {orders.deliveryMethod[order.deliveryMethod]}
              </ST.OrderInfoFeature>
              <ST.OrderInfoFeature>{addressName}</ST.OrderInfoFeature>
            </OrderInfoBlock>
          )}
        </OrderInfoRow>
        {!isQuote && (
          <ST.GeneralOrderInfo>
            <OrderInfoRow $wrap>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {orders.orderTableHeaders.paymentMethod}
                </T8y>
                <ST.OrderInfoFeature>
                  {orders.paymentType[order.paymentMethod] ||
                    order.paymentMethod}
                </ST.OrderInfoFeature>
                {!!order.lastFourDigits && (
                  <ST.OrderInfoFeature>
                    {orders.item.cardEndingIn} *{order.lastFourDigits}
                  </ST.OrderInfoFeature>
                )}
              </OrderInfoBlock>

              {order.paymentMethod === 'card' && (
                <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                  <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                    {orders.paymentStatus}
                  </T8y>
                  <ST.OrderInfoFeature>
                    {order.payment?.status}
                  </ST.OrderInfoFeature>
                </OrderInfoBlock>
              )}

              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  Payment Link
                </T8y>
                {paymentLink ? (
                  <Row
                    as="a"
                    href={paymentLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    align="center"
                    noWrap
                  >
                    <ST.PaymentLink
                      fontSize="16px"
                      color="primary"
                      marginRight="8px"
                    >
                      {paymentLink}
                    </ST.PaymentLink>
                    <ST.LinkIconStyled />
                  </Row>
                ) : (
                  <T8y fontSize="16px" marginRight="8px">
                    N/A
                  </T8y>
                )}
              </OrderInfoBlock>
            </OrderInfoRow>

            <OrderInfoRow $wrap>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  Deposit Method
                </T8y>
                <ST.OrderInfoFeature>
                  {orders.paymentType[order.paymentMethod] ||
                    order.paymentMethod ||
                    'N/A'}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  Deposit Status
                </T8y>
                <ST.OrderInfoFeature>
                  {order.payment?.deposit?.status || 'N/A'}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  Deposit Refund Date
                </T8y>
                <ST.OrderInfoFeature>
                  {(order.payment?.deposit?.refundAuto &&
                    order.payment.deposit.refundDate) ||
                    'N/A'}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  Deposit Refund Method
                </T8y>
                <RadioGroup
                  as={Row}
                  justify="stretch"
                  {...radio}
                  onChange={updateDepositRefundMethod}
                >
                  <Radio
                    id="radio-automatic-refund"
                    {...radio}
                    text="Automatic"
                    value="automatic"
                    disabled={isDepositRefunded || isOrderUpdating}
                  />
                  <Radio
                    id="radio-manual-refund"
                    {...radio}
                    text="Manual"
                    value="manual"
                    disabled={isDepositRefunded || isOrderUpdating}
                  />
                </RadioGroup>
              </OrderInfoBlock>
            </OrderInfoRow>

            <OrderInfoRow $wrap>
              <OrderInfoBlock withTopBorder short $width="140px" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.tableHeaders.CRMDealStage}
                </T8y>
                <ST.OrderInfoFeature>
                  {order.crmStageStatus || '-'}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="30%" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.crmDeal} #{order.fw_deal_id}
                </T8y>
                {order.fw_deal_id ? (
                  <Row
                    as="a"
                    href={`${crmLink}deals/${order.fw_deal_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    align="center"
                    noWrap
                  >
                    <ST.PaymentLink
                      fontSize="16px"
                      color="primary"
                      marginRight="8px"
                    >
                      {`${crmLink}deals/${order.fw_deal_id}`}
                    </ST.PaymentLink>
                    <ST.LinkIconStyled />
                  </Row>
                ) : (
                  <T8y fontSize="16px" marginRight="8px">
                    N/A
                  </T8y>
                )}
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="30%" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.crmContact} #{order.fw_contact_id}
                </T8y>
                {order.fw_contact_id ? (
                  <Row
                    as="a"
                    href={`${crmLink}contacts/${order.fw_contact_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    align="center"
                    noWrap
                  >
                    <ST.PaymentLink
                      fontSize="16px"
                      color="primary"
                      marginRight="8px"
                    >
                      {`${crmLink}contacts/${order.fw_contact_id}`}
                    </ST.PaymentLink>
                    <ST.LinkIconStyled />
                  </Row>
                ) : (
                  <T8y fontSize="16px" marginRight="8px">
                    N/A
                  </T8y>
                )}
              </OrderInfoBlock>
            </OrderInfoRow>

            <OrderInfoRow>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.shuftiVerificationStatus}
                </T8y>
                <ST.OrderInfoFeature>
                  {order.verificationStatus ? leads.yes : leads.no}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="30%" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.shuftiVerificationLink}
                </T8y>
                {order.verificationLink ? (
                  <Row
                    as="a"
                    href={order.verificationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    align="center"
                    noWrap
                  >
                    <ST.PaymentLink
                      fontSize="16px"
                      color="primary"
                      marginRight="8px"
                    >
                      {order.verificationLink}
                    </ST.PaymentLink>
                    <ST.LinkIconStyled />
                  </Row>
                ) : (
                  <T8y fontSize="16px" marginRight="8px">
                    N/A
                  </T8y>
                )}
              </OrderInfoBlock>
            </OrderInfoRow>
          </ST.GeneralOrderInfo>
        )}
        {!isQuote && !isTraining && (
          <OrderDocuments
            order={order}
            addDocument={addDocumentToOrder}
            deleteDocument={deleteDocumentFromOrder}
            isAddFilesBlocked={isAddFilesBlocked}
            isAddInvoiceBlocked={isAddInvoiceBlocked}
          />
        )}
      </OrderInfoWrapper>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadOrderInfoNew;
