import styled from 'styled-components';

export const DeliveryInfoWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
`;

export const DeliveryTypeWrapper = styled.div`
  margin-right: 30px;
`;

export const LineWrapper = styled.div`
  display: flex;
`;

export const RadioWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;

  & > label:not(last-child) {
    margin-right: 30px;
  }
`;

export const DeliveryArea = styled.div`
  display: flex;
  margin-bottom: 30px;

  & > label:first-child {
    margin-right: 30px;
  }
`;
