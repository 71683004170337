//from https://github.com/streamich/react-use

import { useEffect, useRef } from 'react';

function useTitle(title, options = { restoreOnUnmount: false }) {
  const prevTitleRef = useRef(document.title);
  document.title = title;
  useEffect(() => {
    if (options && options.restoreOnUnmount) {
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.title = prevTitleRef.current;
      };
    } else {
      return;
    }
  }, [options]);
}

export default typeof document !== 'undefined' ? useTitle : _title => {};
