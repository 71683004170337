import React from 'react';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit';
import clsx from 'clsx';
import { isNotNullUndefined } from 'utils/helpers/numbers';

import style from './select.module.scss';

export function Select({
  // array with name and slug
  options = [],
  value,
  aLabel,
  selected = null,
  defaultText,
  onClickOption = null,
  className,
  maxHeight = null,
  isSelectDisable,
  placement,
  ...rest
}) {
  const menu = useMenuState({ placement });
  const menuStyle = maxHeight ? { maxHeight, overflowY: 'auto' } : null;

  return (
    <>
      <MenuButton
        {...menu}
        className={clsx(
          className,
          style.menu,
          isSelectDisable && style.disable,
        )}
        disabled={isSelectDisable}
      >
        {selected || defaultText}
      </MenuButton>
      <Menu
        {...menu}
        aria-label={aLabel}
        className={style.options}
        style={menuStyle}
      >
        {options.map((option, i) => (
          <MenuItem
            {...menu}
            key={i}
            onClick={e => {
              e.preventDefault();

              if (onClickOption) {
                onClickOption(
                  isNotNullUndefined(option.slug) ? option.slug : option.id,
                );
              }
              menu.hide();
            }}
            disabled={option.disabled}
            {...rest}
            className={style.option}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
