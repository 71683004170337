import styled from 'styled-components';

import { Row } from 'ui';

export const PrerenderStatusCodeInputWrapper = styled(Row)`
  max-width: 170px;
  margin-top: 20px;
`;

export const PrerenderHeaderInputWrapper = styled(Row)`
  max-width: 350px;
  margin-top: 20px;
`;
