import { useCallback, useMemo, useState } from 'react';

export const useManageInputState = ({
  currentPostcode,
  setIsPostcodesListVisible,
  setEmptyAddress,
  searchRequestError,
  makePostcodeListVisible,
  autoScrollToList,
}) => {
  const [currPostCodeStr, setCurrPostCodeStr] = useState(currentPostcode || '');

  const handleInputFocus = useCallback(() => {
    if (currPostCodeStr.length >= 3 && !searchRequestError) {
      if (autoScrollToList) {
        makePostcodeListVisible();
      } else {
        setIsPostcodesListVisible(true);
      }
    }
  }, [
    autoScrollToList,
    currPostCodeStr.length,
    makePostcodeListVisible,
    searchRequestError,
    setIsPostcodesListVisible,
  ]);

  const handleInputType = useCallback(
    async e => {
      const currPostcode = e.target.value;
      setCurrPostCodeStr(currPostcode);

      if (currentPostcode) {
        setEmptyAddress();
      }
    },
    [currentPostcode, setEmptyAddress],
  );

  return useMemo(
    () => ({
      handleInputFocus,
      handleInputType,
      currPostCodeStr,
      setCurrPostCodeStr,
    }),
    [currPostCodeStr, handleInputFocus, handleInputType, setCurrPostCodeStr],
  );
};
