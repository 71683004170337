import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';

import AddMore from 'components/atoms/AddMore';
import CustomButton from 'components/atoms/CustomButton';
import DeliveryByRadiusRangeItem from 'components/molecules/DeliveryByRadiusRangeItem';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, Row, T8y } from 'ui';

const DeliveryByRadiusItem = ({
  index,
  deliveryItemIndex,
  handleCopyConfigurationClick,
  copiedConfiguration,
  setCopiedConfiguration,
  FIELDS_NAMES_LIST,
  setValue,
  control,
  errors,
  register,
  watch,
  trigger,
  setIsCopyConfigurationEnabled,
  isCopyConfigurationEnabled,
  deliveryByRadiusItem,
}) => {
  const { settings: settingsLocalized, form, formatString } = Lang();

  const settings = useStore(settings$);

  const dialogStateConfirmationModal = useDialogState();

  const [rangeIndexForDeleting, setRangeIndexForDeleting] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `deliveries[${deliveryItemIndex}].deliveryByRadius[${index}].ranges`,
    keyName: 'formId',
  });

  useEffect(
    () => {
      if (copiedConfiguration && setCopiedConfiguration) {
        remove();
        append(copiedConfiguration, false);
        setCopiedConfiguration(null);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [copiedConfiguration],
  );

  const handleRangeChange = useCallback(() => {
    if (setIsCopyConfigurationEnabled) {
      setIsCopyConfigurationEnabled(true);
    }
  }, [setIsCopyConfigurationEnabled]);

  const handleAddNewRange = useCallback(() => {
    append(
      FIELDS_NAMES_LIST.reduce((acc, el) => {
        acc[el.checkboxName] = true;
        acc.distanceFrom =
          deliveryByRadiusItem.ranges[deliveryByRadiusItem.ranges.length - 1]
            .distanceTo || '';
        return acc;
      }, {}),
    );

    handleRangeChange();
  }, [FIELDS_NAMES_LIST, append, deliveryByRadiusItem, handleRangeChange]);

  const handleRemoveIconClick = useCallback(
    index => {
      setRangeIndexForDeleting(index);
      dialogStateConfirmationModal.show();
    },
    [dialogStateConfirmationModal],
  );

  const handleRemove = useCallback(() => {
    remove(rangeIndexForDeleting);
    dialogStateConfirmationModal.hide();

    handleRangeChange();
  }, [
    dialogStateConfirmationModal,
    handleRangeChange,
    rangeIndexForDeleting,
    remove,
  ]);

  const handleRemoveConfirmationModalClose = useCallback(() => {
    setRangeIndexForDeleting(null);
    dialogStateConfirmationModal.hide();
  }, [dialogStateConfirmationModal]);

  const deliveryByRadiusItemRangesPath = useMemo(
    () => `deliveries[${deliveryItemIndex}].deliveryByRadius[${index}].ranges`,
    [deliveryItemIndex, index],
  );

  return (
    <>
      <ST.SectionWrapper>
        <ST.DepotName color="negative" fontSize="16px" bold>
          {deliveryByRadiusItem.title}
        </ST.DepotName>
        <Row>
          <T8y
            color="negative"
            fontSize="16px"
            marginRight="20px"
            marginBottom="30px"
          >
            {settingsLocalized.titles.transportationCostVat} (
            {form.fields.priceWithoutVat})
          </T8y>
          {index === 0 && (
            <T8y
              fontSize="16px"
              marginBottom="30px"
              cursor={isCopyConfigurationEnabled ? 'pointer' : 'default'}
              color={isCopyConfigurationEnabled ? 'primary' : 'gray'}
              onClick={
                isCopyConfigurationEnabled && handleCopyConfigurationClick
              }
            >
              {settingsLocalized.copyToOtherDepots}
            </T8y>
          )}
        </Row>
        <ST.TableHeaderStyled cols="140px 184px 184px 184px 64px 128px">
          <ST.InputLabel as="span" color="gray">
            {formatString(
              settingsLocalized.rangeInKm,
              settings.systemOfUnits === 'metric' ? 'km' : 'm',
            )}
          </ST.InputLabel>
          <ST.InputLabel as="span" color="gray">
            {settingsLocalized.depotToLocation}
          </ST.InputLabel>
          <ST.InputLabel as="span" color="gray">
            {settingsLocalized.locationToDepot}
          </ST.InputLabel>
          <ST.InputLabel as="span" color="gray">
            {settingsLocalized.bothWaysDelivery}
          </ST.InputLabel>
        </ST.TableHeaderStyled>
        <div>
          {fields.map((el, index) => (
            <DeliveryByRadiusRangeItem
              key={el.formId}
              item={el}
              index={index}
              deliveryByRadiusItemRangesPath={deliveryByRadiusItemRangesPath}
              register={register}
              errors={errors}
              watch={watch}
              trigger={trigger}
              FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
              setValue={setValue}
              handleRemoveIconClick={handleRemoveIconClick}
              handleRangeChange={handleRangeChange}
            />
          ))}
          <AddMore
            text={settingsLocalized.addNewRange}
            onClick={handleAddNewRange}
          />
        </div>
      </ST.SectionWrapper>
      <Modal
        title={settingsLocalized.modals.deleteDistanceRangeConfirmationTitle}
        dialogState={dialogStateConfirmationModal}
        width="420px"
        overflow="hidden"
        backgroundColor={colors.primary}
        lazyRender
      >
        <T8y lineHeight="22px" marginTop="30px">
          {settingsLocalized.modals.deleteDistanceRangeConfirmationText}
        </T8y>
        <ST.RangeConfirmationModalControlsWrapper>
          <CustomButton
            text={form.buttons.cancel}
            backgroundColor="positive"
            color="gray3"
            width="120px"
            height="29px"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            onClick={handleRemoveConfirmationModalClose}
          />
          <CustomButton
            text={form.buttons.delete}
            backgroundColor="positive"
            color="gray3"
            border="1px solid #F89739"
            colorHovered="gray"
            width="120px"
            height="29px"
            onClick={handleRemove}
          />
        </ST.RangeConfirmationModalControlsWrapper>
      </Modal>
    </>
  );
};

export default DeliveryByRadiusItem;
