export const settings = {
  titles: {
    transportation: 'Transporte',
    costCalculationMethod: 'CMétodo de Cálculo de Custo',
    routeAvailability: 'Disponibilidade de rotas de transporte',
    transportationCostVat: 'Custo de transporte',
    additionalCharges: 'Despesas adicionais',
    regionalSettings: 'Configurações regionais',
    calendarSetup: 'Configuração de calendário',
  },
  priceByArea: 'Preço por Área',
  priceByKm: 'Preço por quilômetro',
  area: 'Área',
  priceByPostalCode: 'Preço por Código Postal',
  priceByRadius: 'Preço por Raio',
  postcode: 'Código postal',
  depotToLocation: 'Para local',
  locationToDepot: 'Para depósito',
  bothWaysDelivery: 'Em ambos os sentidos',
  depot: 'Depósito',
  outOfRangeNotAvailable: 'A entrega fora do intervalo não está disponível',
  outOfRangeNotAvailableMsg:
    'Se esta caixa de seleção estiver marcada, a mensagem “Desculpe, não oferecemos entrega em seu endereço...” será exibida durante o processo de checkout para os usuários cujo endereço está fora do intervalo.',
  copyToOtherDepots: 'Copie esta configuração para outros depósitos',
  rangeInKm: 'Faixa de distância em {0}',
  depotToLocationByRadius: 'Depósito para Local',
  locationToDepotByRadius: 'Localização do Depósito',
  bothWaysDeliveryByRadius: 'Entrega em ambos os sentidos',
  thisRangeNotAvailable: 'Neste intervalo a entrega não está disponível',
  addNewRange: 'Adicionar novo intervalo',
  showAllDepots: 'Expanda e mostre todos os {0} depósitos',
  showFirstDepotOnly: 'Recolher e mostrar apenas o primeiro depósito',
  useBrToSeparate: 'Use "<br>" para separar vários itens',

  modals: {
    deleteConfirmationTitle: 'Excluir confirmação',
    deleteConfirmationText:
      'Tem certeza de que deseja excluir {0} da lista de tipos de entrega disponíveis?',
    deleteMessageTitle: 'Apagar mensagem',
    deleteMessageText:
      'O tipo de entrega {0} é usado para alguns produtos do catálogo. Se você ainda deseja excluí-lo, atribua primeiro outro tipo de entrega a esses produtos.',
    copyConfigurationTitle: 'Copiar configuração',
    copyConfigurationText:
      'Tem certeza de que deseja copiar esta configuração? Esta ação substituirá os valores de alcance/custo em todos os outros depósitos e você não poderá desfazê-la.',
    deleteDistanceRangeConfirmationTitle: 'Excluir intervalo de distância',
    deleteDistanceRangeConfirmationText:
      'Tem certeza de que deseja excluir este intervalo? Você não poderá desfazer esta ação.',
    depotDeleteConfirmationText:
      'Tem certeza de que deseja excluir {0} da lista de depósitos?',
    depotDeleteMessageText:
      'O depósito {0} é usado para alguns produtos no catálogo. Se você ainda deseja excluí-lo, marque esses produtos como "Fora de estoque" primeiro.',
  },
};
