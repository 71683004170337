import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { tiers$ } from 'models/tiers';

import { FieldStyle } from 'components/Product/shared';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { Checkbox, inputStyle, Select, T8y } from 'ui';

const AccountSpecialDiscount = ({
  control,
  setValue,
  userTier,
  isLoading,
  values,
}) => {
  const { users: usersLocalization, tradeAccounts } = Lang();

  const [isTier, setIsTier] = useState(!!userTier);

  const tiers = useStore(tiers$);

  const currentTier = useMemo(
    () => tiers.find(el => el.id === values.account?.tierId) || tiers[0],
    [tiers, values.account],
  );

  useEffect(
    () => {
      const tierId =
        userTier && tiers.find(el => el.slug === userTier?.slug)?.id;

      if (tierId) {
        setIsTier(true);
      }

      setValue('account.tierId', tierId || null);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [tiers],
  );

  const toggleDiscountCheckbox = useCallback(
    e => {
      setIsTier(e.target.checked);
      setValue('account.tierId', e.target.checked ? tiers[0].id : null);
    },
    [setValue, tiers],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={tradeAccounts.accountDiscount}>
      <Checkbox
        label={usersLocalization.discount.option}
        onChange={toggleDiscountCheckbox}
        checked={isTier}
        disabled={isLoading}
      />
      <ST.InputsBlockWrapper align="end">
        <FieldStyle
          as={ST.InputsWrapperFields}
          legend={usersLocalization.discount.discount}
          withBorder
        >
          <Controller
            name="account.tierId"
            render={({ onChange }) => (
              <Select
                options={tiers}
                selected={currentTier?.name}
                onClickOption={value => {
                  const tierId = tiers.find(el => el.slug === value).id;
                  onChange(tierId);
                }}
                className={inputStyle}
                disabled={!isTier || isLoading}
                isSelectDisable={!isTier || isLoading}
              />
            )}
            control={control}
          />
        </FieldStyle>
        <FieldStyle
          disabled={!isTier || isLoading}
          as={ST.InputsWrapperFields}
          withBorder
        >
          <input disabled name="discount" value={currentTier?.discount + '%'} />
        </FieldStyle>
      </ST.InputsBlockWrapper>
      <T8y fontSize="1.6rem">{usersLocalization.discount.description} </T8y>
    </ProductEditorBlockWrapper>
  );
};

export default AccountSpecialDiscount;
