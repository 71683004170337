import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { website$ } from 'models/website';

import { userInfo } from '../models/user';
import { generateURLS } from './adminRoutes';
import commonRoutes from './commonRoutes';
import { generateSuperAdminURLS } from './superAdminRoutes';

export const useRoutesList = () => {
  const user = useStore(userInfo);
  const website = useStore(website$);

  return useMemo(() => {
    let resultRoutes = [...commonRoutes];

    const newSuperAdminRoutes = generateSuperAdminURLS();
    const newAdminRoutes = generateURLS();

    resultRoutes = [...resultRoutes, ...newSuperAdminRoutes, ...newAdminRoutes];

    return resultRoutes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, website]);
};
