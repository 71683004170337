import React, { useMemo } from 'react';
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import UserOrderDoc from 'components/atoms/UserOrderDoc';
import DropZone from 'components/molecules/DropZone';

import * as ST from './styles';

import { T8y } from 'ui';

const UploadDocumentsSection = ({
  title,
  docs,
  deleteDocumentFromOrder,
  addDocumentToOrder,
  isAddFilesBlocked,
  isAddFilesHidden,
  isSmall,
}) => {
  const { formatString, orders, form: formLocalization } = Lang();

  const isDocs = useMemo(() => {
    return !!docs?.length;
  }, [docs]);

  const popover = usePopoverState({
    gutter: 3,
  });

  return (
    <>
      <ST.DocumentsTitle bold isSmall={isSmall}>
        {title}
      </ST.DocumentsTitle>
      {isDocs && (
        <ST.OrderDocumentsWrapper>
          {docs.map((el, index) => (
            <UserOrderDoc
              key={index}
              doc={el}
              deleteDocumentFromOrder={deleteDocumentFromOrder}
              isSmall={isSmall}
            />
          ))}
        </ST.OrderDocumentsWrapper>
      )}
      <div>
        <DropZone
          accept="application/pdf"
          handleUploadEnd={addDocumentToOrder}
          disabled={isAddFilesBlocked}
        >
          {() => (
            <ST.AddLinkWrapper
              onMouseEnter={() => {
                if (isAddFilesBlocked) {
                  popover.show();
                }
              }}
              onMouseLeave={popover.hide}
            >
              {!isAddFilesHidden && (
                <PopoverDisclosure {...popover}>
                  <AddMore
                    fontSize={isSmall ? '14px' : '16px'}
                    text={orders.uploadDocument}
                    disabled={isAddFilesBlocked}
                  />
                </PopoverDisclosure>
              )}
              <Popover
                {...popover}
                tabIndex={0}
                style={{ background: 'none', padding: 0 }}
              >
                <ST.LimitedFilesWrapper>
                  <T8y fontSize="1.2rem" color="positive">
                    {formatString(formLocalization.descriptions.filesAsMax, 3)}
                  </T8y>
                </ST.LimitedFilesWrapper>
              </Popover>
            </ST.AddLinkWrapper>
          )}
        </DropZone>
      </div>
    </>
  );
};

export default UploadDocumentsSection;
