import { respondTo } from 'mixins';

import { InputStyled } from 'components/Product/shared';
import styled from 'styled-components';

import { Checkbox, Radio, Row } from 'ui';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & > div {
    flex-basis: 23%;
    display: flex;
    align-items: center;
  }

  & > div:first-child {
    flex-basis: 30%;
  }

  ${respondTo.lg`
    flex-direction: column;
    
    & > div {
      flex-basis: 100%;
    }
    
    & > div:first-child {
      flex-basis: 100%;
    }
  `}
`;

export const BlockWrapper = styled.div`
  border-bottom: 1px solid #e4e8ed;
  margin-bottom: 30px;

  ${p =>
    p.$isPadding &&
    `
      padding-bottom: 1.5rem;;
    `}

  display: flex;
  flex-direction: ${p => (p.$isColumn ? 'column' : 'row')};
  align-items: ${p => (p.$isColumn ? 'flex-start! important' : 'center')};
  justify-content: space-between;
`;

export const SettingFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 80%;
  flex-shrink: 1;
`;

export const CheckboxWrapper = styled(Checkbox)`
  width: 19rem;
  ${p =>
    p.$isPadding &&
    `
      padding-bottom: 1.5rem;;
    `}
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;

  & > div:first-child {
    margin-right: 4rem;
  }
`;

export const FakeCheckbox = styled.div`
  width: 19rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InsuranceInput = styled(InputStyled)`
  ${props =>
    props.readOnly &&
    `
     color:#b3b7bb;
     background-color: #FBFBFB;
     cursor: not-allowed;
     `}
`;

export const FieldTitle = styled.p`
  font-weight: 500;
  font-size: ${props => props.fontSize || '1.4rem'};
  color: #b3b7bb;
  height: 2rem;
  margin-left: 1rem;
`;

export const RadioGroupWrapper = styled(Row)`
  flex-direction: column;
  & > div {
    display: flex;
    width: 100%;
  }
`;

export const RadioWrapper = styled(Radio)`
  width: 190px;
  ${p =>
    p.$isPadding &&
    `
      padding-bottom: 1.5rem;;
    `}
`;
