import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { warehouses$ } from 'models/warehouses';

import DeliveryMileCheckbox from 'components/atoms/DeliveryMileCheckbox';
import Error from 'components/atoms/Error';
import InputWithCurrencySign from 'components/atoms/InputWithCurrencySign';

import * as ST from './styles';

import { T8y } from 'ui';

const DeliveryMileItem = ({
  deliveryItem,
  register,
  control,
  deliveryIndex,
  values,
  setValue,
  item,
  itemIndex,
  errors,
  trigger,
  FIELDS_NAMES_LIST,
}) => {
  const { form } = Lang();

  const warehouses = useStore(warehouses$);

  const mileItemPath = useMemo(() => {
    return `deliveries[${deliveryIndex}].deliveryPerMile[${itemIndex}]`;
  }, [deliveryIndex, itemIndex]);

  const checkboxesList = useMemo(() => {
    return FIELDS_NAMES_LIST.map(el => el.checkboxName);
  }, [FIELDS_NAMES_LIST]);

  const isDepotDisabled = useMemo(() => {
    const checkedValues = checkboxesList
      .map(
        el => values.deliveries[deliveryIndex].deliveryPerMile[itemIndex][el],
      )
      .filter(el => !!el);

    return !checkedValues.length;
  }, [checkboxesList, deliveryIndex, itemIndex, values.deliveries]);

  useEffect(() => {
    if (isDepotDisabled) {
      trigger(`${mileItemPath}.startPrice`);
      trigger(`${mileItemPath}.pricePerMile`);
      trigger(`${mileItemPath}.minimalPrice`);
    }
  }, [isDepotDisabled, mileItemPath, trigger]);

  const warehouseName = useMemo(() => {
    return warehouses.find(el => el.id === item.warehouseId)?.name || 'Rentuu';
  }, [item, warehouses]);

  const getErrorMessage = useCallback(
    field => {
      return (
        errors &&
        errors.deliveries &&
        errors.deliveries[deliveryIndex] &&
        errors.deliveries[deliveryIndex].deliveryPerMile &&
        errors.deliveries[deliveryIndex].deliveryPerMile[itemIndex] &&
        errors.deliveries[deliveryIndex].deliveryPerMile[itemIndex][field] &&
        errors.deliveries[deliveryIndex].deliveryPerMile[itemIndex][field]
          .message
      );
    },
    [errors, deliveryIndex, itemIndex],
  );

  return (
    <ST.PriceKmLineWrapper>
      <ST.DeliveryWarehouseName>{warehouseName}</ST.DeliveryWarehouseName>
      <ST.PriceKmInputWrapper>
        <InputWithCurrencySign
          type="number"
          width="110px"
          step="0.01"
          paddingLeft="3rem"
          name={`${mileItemPath}.startPrice`}
          innerRef={register({
            required: !isDepotDisabled && form.notification.theFieldIsRequired,
            valueAsNumber: true,
            validate: {
              positive: value =>
                isDepotDisabled ||
                value > 0 ||
                form.notification.valueShouldBePositive,
            },
          })}
          defaultValue={
            deliveryItem.deliveryPerMile?.[itemIndex]?.startPrice || null
          }
          control={control}
          fontSize="14px"
          disabled={isDepotDisabled}
        />
        {getErrorMessage('startPrice') && (
          <Error message={getErrorMessage('startPrice')} />
        )}
      </ST.PriceKmInputWrapper>
      <ST.PriceKmInputWrapper>
        <InputWithCurrencySign
          type="number"
          width="110px"
          step="0.01"
          paddingLeft="3rem"
          name={`${mileItemPath}.minimalPrice`}
          innerRef={register({
            required: !isDepotDisabled && form.notification.theFieldIsRequired,
            valueAsNumber: true,
            validate: {
              positive: value =>
                isDepotDisabled ||
                value >= 0 ||
                form.notification.valueShouldBePositive,
            },
          })}
          fontSize="14px"
          defaultValue={
            deliveryItem.deliveryPerMile?.[itemIndex]?.minimalPrice || 0
          }
          control={control}
          disabled={isDepotDisabled}
        />
        {getErrorMessage('minimalPrice') && (
          <Error message={getErrorMessage('minimalPrice')} />
        )}
      </ST.PriceKmInputWrapper>
      <ST.PriceKmInputWrapper>
        <InputWithCurrencySign
          type="number"
          width="110px"
          step="0.01"
          paddingLeft="3rem"
          name={`${mileItemPath}.pricePerMile`}
          innerRef={register({
            required: !isDepotDisabled && form.notification.theFieldIsRequired,
            valueAsNumber: true,
            validate: {
              positive: value =>
                isDepotDisabled ||
                value > 0 ||
                form.notification.valueShouldBePositive,
            },
          })}
          fontSize="14px"
          defaultValue={
            deliveryItem.deliveryPerMile?.[itemIndex]?.pricePerMile || null
          }
          control={control}
          disabled={isDepotDisabled}
        />
        {getErrorMessage('pricePerMile') && (
          <Error message={getErrorMessage('pricePerMile')} />
        )}
      </ST.PriceKmInputWrapper>
      <ST.RouteCheckboxesWrapper>
        {checkboxesList.map((field, checkboxIndex) => (
          <DeliveryMileCheckbox
            index={checkboxIndex}
            fieldName={field}
            register={register}
            deliveryIndex={deliveryIndex}
            values={values}
            setValue={setValue}
            key={field}
            mileIndex={itemIndex}
          />
        ))}
      </ST.RouteCheckboxesWrapper>
      {isDepotDisabled && (
        <ST.ErrorWrapper>
          <T8y as="span" variant="t3" color="danger" align="center">
            {form.notification.notAvailable}
          </T8y>
        </ST.ErrorWrapper>
      )}
    </ST.PriceKmLineWrapper>
  );
};

export default DeliveryMileItem;
