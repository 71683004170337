export const getCategoryName = (
  categories,
  categoriesList,
  currentLang,
  defaultLang,
) => {
  let level1 = null;
  let level2 = null;
  let level3 = null;

  if (categories && !!categoriesList.length) {
    const currentCategory = categoriesList.find(el => el.id === categories.id);

    if (currentCategory) {
      const gitSecondCategory = () =>
        categoriesList.find(el => el.id === currentCategory.parent);

      const gitThirdCategory = () =>
        categoriesList.find(el => el.id === gitSecondCategory().parent);

      if (currentCategory.level === 1) {
        level1 = currentCategory;
      } else if (currentCategory.level === 2) {
        level2 = currentCategory;
        level1 = gitSecondCategory();
      } else if (currentCategory.level === 3) {
        level3 = currentCategory;
        level2 = gitSecondCategory();
        level1 = gitThirdCategory();
      }
    }
  }

  return {
    level1: {
      ...level1,
      name: level1?.names[currentLang] || level1?.names[defaultLang],
    },
    level2: {
      ...level2,
      name: level2?.names[currentLang] || level2?.names[defaultLang],
    },
    level3: {
      ...level3,
      name: level3?.names[currentLang] || level3?.names[defaultLang],
    },
  };
};
