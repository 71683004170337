import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import { settings$ } from 'models/settings';
import { website$ } from 'models/website';
import { getSeoCategoryTitle } from 'utils/seo';

import styled from 'styled-components';

import { FieldStyle, FieldStyle2, InputStyled } from '../Product/shared';

import { colors } from 'styleGuide';
import { Checkbox } from 'ui';

const SeoTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

const SeoBody = styled.div`
  width: 100%;
  background: #fff;
  padding: 2.5rem 3rem 3rem;
  border-radius: 0.2rem;
  margin-bottom: 4.7rem;
`;

const RobotsWrapper = styled.div`
  margin-top: 30px;
`;

export const Seo = ({ register, setValue, values, isNewCategory }) => {
  const {
    common,
    form: { fields },
  } = Lang();

  const settings = useStore(settings$);
  const branding = useStore(branding$);
  const website = useStore(website$);

  const [isRobotsEditingEnabled, setRobotsEditingEnabled] = useState(false);

  const defaultName = useMemo(() => {
    return values.names && values.names[settings.defaultLanguage];
  }, [settings.defaultLanguage, values.names]);

  const defaultDescription = useMemo(() => {
    return values.descriptions && values.descriptions[settings.defaultLanguage];
  }, [settings.defaultLanguage, values.descriptions]);

  useEffect(() => {
    if (isNewCategory && setValue && defaultDescription) {
      const splittedMeta = defaultDescription
        ? defaultDescription.split('.')[0]
        : '';
      setValue('meta_description', splittedMeta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCategory, setValue, defaultDescription]);

  useEffect(() => {
    if (isNewCategory && setValue && defaultName) {
      const newSeoTitle = getSeoCategoryTitle({
        title: defaultName,
        region: website.regionSlug,
        companyName: branding?.companyName,
      });

      setValue('seo_title', newSeoTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCategory, defaultName, setValue]);

  return (
    <Fragment>
      <SeoTitle>SEO</SeoTitle>
      <SeoBody>
        <FieldStyle as={FieldStyle2} legend={fields.title}>
          <InputStyled
            name="seo_title"
            type="text"
            ref={register}
            onChange={e => {
              setValue('seo_title', e.target.value);
            }}
            defaultValue={values?.seo_title || null}
          />
        </FieldStyle>
        <FieldStyle
          as={FieldStyle2}
          legend={fields.metaDescription}
          marginTop="1.5rem"
        >
          <InputStyled
            name="meta_description"
            type="text"
            ref={register}
            onChange={e => {
              setValue('meta_description', e.target.value);
            }}
            defaultValue={values?.meta_description || null}
          />
        </FieldStyle>
        <RobotsWrapper>
          <Checkbox
            label={common.enabledRobotsTag}
            ref={register}
            onChange={e => setRobotsEditingEnabled(e.target.checked)}
            checked={isRobotsEditingEnabled}
          />
          <FieldStyle
            as={FieldStyle2}
            legend={fields.robotsTag}
            marginTop="1.5rem"
          >
            <InputStyled
              name="seo_robots"
              type="text"
              ref={register}
              defaultValue={values?.seo_robots || null}
              asDisabled={!isRobotsEditingEnabled}
              readOnly={!isRobotsEditingEnabled}
            />
          </FieldStyle>
        </RobotsWrapper>
      </SeoBody>
    </Fragment>
  );
};
