import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Cell } from 'ui';

export const TableWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const TableHeader = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dddddd;
  width: 100%;
`;

export const TableHeaderCell = styled(Cell)`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray};
`;
