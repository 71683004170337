import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { MAX_IMAGES_COUNT } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import * as PRODUCT from 'models/product';

import Error from 'components/atoms/Error';
import { FILES } from 'api';

import { AltTagModal } from '../AltTagModal';
import { Icon } from '../Icon';
import * as ST from './styles';

import { Cell, DropZoneBlock, Grid } from 'ui';

export const UploadPhotos = ({ isValidPhotos, setIsValidPhotos }) => {
  const {
    formatString,
    form: { toastTitle, notification },
    product: { titles },
  } = Lang();

  const photos = useStore(PRODUCT.photosStore);

  const mainImage = useMemo(() => {
    if (!photos.length) {
      return null;
    }

    return photos.find(el => !!el.isMain);
  }, [photos]);

  const addImages = useCallback(
    imagesFromServer => {
      const emptySlots = photos.filter(el => !el.value).length;

      if (emptySlots - imagesFromServer.length < 0) {
        toast(formatString(toastTitle.onlyImagesCan, MAX_IMAGES_COUNT));
      }

      imagesFromServer.forEach((el, index) => {
        const newProductPhoto = {
          value: el.url,
          alt_tag: null,
          isMain: false,
        };

        if (!mainImage && index === 0) {
          newProductPhoto.isMain = true;
        }

        PRODUCT.addPhoto(newProductPhoto);
      });
      setIsValidPhotos(true);
    },
    [
      formatString,
      mainImage,
      photos,
      setIsValidPhotos,
      toastTitle.onlyImagesCan,
    ],
  );

  const handleUploadEnd = useCallback(
    async acceptedFiles => {
      let firstEightPhotos = [...acceptedFiles];

      if (acceptedFiles.length > MAX_IMAGES_COUNT) {
        toast(formatString(toastTitle.onlyImagesCan, MAX_IMAGES_COUNT));
        firstEightPhotos = acceptedFiles.slice(0, MAX_IMAGES_COUNT);
      }

      await Promise.all(
        firstEightPhotos.map(file => {
          return new Promise(resolve => {
            const data = new FormData();
            data.append('file', file);
            FILES.upload(data).then(dataFromServer => {
              if (dataFromServer && dataFromServer.data) {
                resolve(dataFromServer.data);
              }
            });
          });
        }),
      ).then(values => {
        addImages(values);
      });
    },
    [addImages, formatString, toastTitle.onlyImagesCan],
  );

  const handleRemoveItem = useCallback(
    (index, isMainImage) => {
      PRODUCT.addDeletedMediaAndFiles({
        id: photos?.[index]?.id,
        type: 'photo',
      });

      if (isMainImage) {
        const nearestImageWithSrcIndex = photos.findIndex(
          (el, localIndex) => el.value && localIndex !== index,
        );

        if (nearestImageWithSrcIndex >= 0) {
          PRODUCT.setMainImage({ itemIndex: nearestImageWithSrcIndex });
        }
      }

      PRODUCT.removePhoto(index);
    },
    [photos],
  );

  return (
    <>
      <ST.UploadPhotosTitle>{titles.uploadPhotos}</ST.UploadPhotosTitle>
      <ST.UploadPhotosBody>
        <ST.UploadBlock>
          <DropZoneBlock handleUpload={handleUploadEnd} />
        </ST.UploadBlock>
        <Grid
          gaps="2rem 2rem"
          cols="repeat(4, 1fr)"
          rows="repeat(2, 1fr)"
          places="center"
        >
          {photos.map((item, index) => {
            const isMainImage = item.isMain;

            const isImage = !!photos?.[index]?.value;
            return (
              <Cell
                key={index}
                as={props => (
                  <ST.ImageWrapper
                    isMainImage={isMainImage}
                    isImage={isImage}
                    {...props}
                  />
                )}
                onClick={e => {
                  if (e.target.tagName === 'IMG' && isImage) {
                    PRODUCT.setMainImage({ itemIndex: index });
                  }
                }}
              >
                <ST.UploadEmptyBlock>
                  {isImage && (
                    <>
                      <img src={photos?.[index]?.value} />
                      <ST.ActionsWrapper>
                        <AltTagModal itemIndex={index} altTag={item.alt_tag} />
                        <Icon
                          type="delete"
                          handleClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (
                              isMainImage &&
                              photos.filter(el => el.value).length === 1
                            ) {
                              toast(toastTitle.thelastImage);
                              return;
                            }
                            handleRemoveItem(index, isMainImage);
                          }}
                        />
                      </ST.ActionsWrapper>
                    </>
                  )}
                </ST.UploadEmptyBlock>
                {isMainImage && <ST.CheckedCircleWrapper />}
              </Cell>
            );
          })}
        </Grid>
        <Error message={!isValidPhotos && notification.theFieldIsRequired} />
      </ST.UploadPhotosBody>
    </>
  );
};
