import React from 'react';

import { DropZoneBlockPlus, T8y } from 'ui';

export const UploadTrainingPicture = ({
  handleUploadEnd,
  header,
  accept = 'image/jpeg, image/jpg',
}) => {
  return (
    <>
      {header && (
        <T8y fontSize="14px" color="gray" marginBottom="10px">
          {header}
        </T8y>
      )}

      <DropZoneBlockPlus accept={accept} handleUpload={handleUploadEnd} />
    </>
  );
};

export default UploadTrainingPicture;
