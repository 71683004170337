import React, { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import * as MD from 'models/product';

import AttributesTable from 'components/atoms/AttributesTable';
import OptionalExtraModal from 'components/molecules/OptionalExtraModal';

const OptionalExtra = ({ control }) => {
  const allOptionalExtras = useStore(MD.allOptionalExtraStore);

  const {
    product: { titles },
  } = Lang();

  const TABLE_HEADERS = useMemo(() => {
    return [
      titles.photo,
      titles.extraCode,
      titles.name,
      titles.price,
      titles.saleItem,
    ];
  }, [titles]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'optional_extra',
  });

  return (
    <AttributesTable
      fields={fields}
      append={append}
      remove={remove}
      allItems={allOptionalExtras}
      tableHeaders={TABLE_HEADERS}
      title={titles.optionalExtra}
      noItemsTitle={titles.noOptionalExtra + '...'}
      gridSettings="1fr 2fr 2fr 1fr 1fr 1fr"
      isOptional
      isCheckbox
      type="optional-extra"
      typeForActiveCheckbox="optional-sale-extra"
      additionalFieldName="optional-sale-extra"
      reload={MD.reloadOptionalExtra}
    >
      <OptionalExtraModal fields={fields} append={append} remove={remove} />
    </AttributesTable>
  );
};

export default OptionalExtra;
