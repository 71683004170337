import { createEffect, guard, restore } from 'effector';
import { createGate } from 'effector-react';

import { OWNERS } from 'api';

export const ownersGate = createGate('owners');

const getOwnersFx = createEffect({
  handler: async () => {
    const { data } = await OWNERS.getOwners();

    return data.data || [];
  },
});

export const isLoadingOwners$ = getOwnersFx.pending.map(state => !!state);

export const owners$ = restore(getOwnersFx, []);

guard({
  source: ownersGate.open,
  filter: owners$,
  target: getOwnersFx,
});
