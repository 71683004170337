import React from 'react';
import { Lang } from 'lang';

import Error from 'components/atoms/Error';
import InputWithCurrencySign from 'components/atoms/InputWithCurrencySign';

import * as ST from './styles';

const TrainingPriceField = ({ value, error, name, register }) => {
  const { trainings, form } = Lang();

  return (
    <ST.PriceFieldWrapper>
      <ST.DatePriceFieldTitle color="gray">
        {trainings.coursePrice}
      </ST.DatePriceFieldTitle>
      <ST.DatePriceVATWrapper>
        <InputWithCurrencySign
          type="number"
          width="150px"
          step="0.01"
          paddingLeft="3rem"
          name={name}
          innerRef={register({
            required: form.notification.theFieldIsRequired,
            valueAsNumber: true,
            validate: {
              positive: value =>
                value > 0 || form.notification.valueShouldBePositive,
            },
          })}
          defaultValue={value || null}
        />
        <ST.VatWrapper>+ {form.vat}</ST.VatWrapper>
      </ST.DatePriceVATWrapper>
      <Error message={error} />
    </ST.PriceFieldWrapper>
  );
};

export default TrainingPriceField;
