import React from 'react';
import { useDialogState } from 'reakit';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import Error from 'components/atoms/Error';
import IntegrationStatusModal from 'components/atoms/IntegrationStatusModal';

import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import * as ST from './styles';
import { useManageIdsConnection } from './useManageIdsConnection';
import { useManageIdsData } from './useManageIdsData';
import { useManageIsActiveIntegrationsField } from './useManageIsActiveIntegrationsField';

const IntegrationsExternalIds = ({
  integration,
  register,
  values,
  setValue,
}) => {
  const { form } = Lang();

  const dialog = useDialogState();

  const {
    getIdInfo,
    connectId,
    disconnectId,
    isProcessingConnection,
  } = useManageIdsConnection(register, setValue, integration, values);

  const {
    handleInputChange,
    handleConnectOrRemoveIntegration,
    handleConnectClick,
    handleAddClick,
    currentId,
    handleCloseClick,
    integrationInfoResponse,
    externalIds,
  } = useManageIdsData(integration, getIdInfo, connectId, disconnectId, dialog);

  useManageIsActiveIntegrationsField(
    register,
    externalIds,
    integration,
    setValue,
    values,
  );

  return (
    <>
      {externalIds.map((el, index) => (
        <ST.IdsWrapper key={el.key}>
          <ST.IdFieldWrapper>
            <FieldStyle
              as={FieldStyle2}
              disabled={!integration.active || (!!el.id && el.isConnected)}
            >
              <InputStyled
                type="text"
                placeholder={form.placeholders.enterProductId}
                disabled={
                  !integration.active ||
                  (!!el.id && el.isConnected) ||
                  isProcessingConnection
                }
                onChange={e => handleInputChange(e, el, index)}
                value={el.id}
                isOverflow
              />
            </FieldStyle>
            <Error message={el.error} />
          </ST.IdFieldWrapper>
          <ST.ConnectBtn
            onClick={() => handleConnectOrRemoveIntegration(el, index)}
            loading={isProcessingConnection && currentId === el.id}
            disabled={!integration.active || isProcessingConnection}
          >
            {el.isConnected
              ? form.buttons.deleteId
              : `${form.buttons.connect}...`}
          </ST.ConnectBtn>
        </ST.IdsWrapper>
      ))}
      {integrationInfoResponse && (
        <IntegrationStatusModal
          dialog={dialog}
          integration={integration}
          integrationInfoResponse={integrationInfoResponse}
          handleCloseClick={handleCloseClick}
          handleConnectClick={handleConnectClick}
        />
      )}
      <AddMore
        disabled={!integration.active || isProcessingConnection}
        onClick={handleAddClick}
        text={form.buttons.addProductId}
        fontSize="14px"
      />
    </>
  );
};

export default IntegrationsExternalIds;
