import { Button } from 'reakit';

import styled from 'styled-components';

import { colors } from 'styleGuide';

export const NavLinkCustomStyled = styled.div`
  width: 100%;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &.active,
  &:hover {
    background-color: ${colors.positive};
    & > div {
      color: ${colors.primary};
    }
    svg {
      fill: ${colors.primary};
    }
  }
  svg {
    fill: ${colors.positive};
    margin-bottom: 1rem;
  }
`;

export const NavbarStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 105px;
  height: 100%;
  color: #fff;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const LogoutStyled = styled(NavLinkCustomStyled)`
  cursor: pointer;
`;

export const NavbarLinkWrapper = styled(Button)`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
