import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import {
  availableCategoriesLocalized$,
  categoriesTreeStoreLocalized$,
} from 'models/categories';
import { settings$ } from 'models/settings';

import { CheckoutAvailability } from 'components/atoms/CheckoutAvailability';
import Error from 'components/atoms/Error';
import PrerenderInputFields from 'components/atoms/PrerenderInputFields';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
} from 'components/Product/shared';

import ProductCarbonCheckbox from '../ProductCarbonCheckbox';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { Checkbox, inputStyle, Row, Select } from 'ui';

export const ProductCategoriesBlock = ({
  register,
  setValue,
  values,
  errors,
  trigger,
  watch,
  clearErrors,
  isNewProduct,
}) => {
  const {
    product: { titles },
    form: { fields, defaultText, notification, checkbox },
    formatString,
  } = Lang();

  const allCategoriesLocalized = useStore(categoriesTreeStoreLocalized$);
  const availableCategoriesLocalized = useStore(availableCategoriesLocalized$);
  const branding = useStore(branding$);
  const settings = useStore(settings$);

  useEffect(() => {
    register('visible_product_url');
    register('needIDVerification');
    register('carbonElementsShow');
    register('isOutOfStock');
  }, [register]);

  const mainCategories = useMemo(() => {
    return availableCategoriesLocalized.filter(el => !el.parent);
  }, [availableCategoriesLocalized]);

  const subCategories = useMemo(() => {
    if (values.category_id) {
      return availableCategoriesLocalized.filter(
        el => el.parent === Number(values.category_id),
      );
    }

    return [];
  }, [availableCategoriesLocalized, values.category_id]);

  const subSubCategories = useMemo(() => {
    if (values.sub_category_id) {
      return availableCategoriesLocalized.filter(
        el => el.parent === Number(values.sub_category_id),
      );
    }

    return [];
  }, [availableCategoriesLocalized, values.sub_category_id]);

  const getSelectedCategoryName = useCallback(
    id => {
      const currCategory = allCategoriesLocalized.find(
        el => el.id === Number(id),
      );
      return currCategory ? currCategory.name : null;
    },
    [allCategoriesLocalized],
  );

  useEffect(() => {
    if (!values.isOutOfStock && clearErrors) {
      clearErrors(['prerenderStatusCode', 'prerenderHeaderLocation']);
    }

    if (!values.prerenderStatusCode) {
      setValue('prerenderStatusCode', '301');
    }

    if (!values.prerenderHeaderLocation && !!values.sub_category_id) {
      const subCategory = allCategoriesLocalized.find(
        el => el.id === Number(values.sub_category_id),
      );
      setValue(
        'prerenderHeaderLocation',
        `${branding?.webSiteUrl}/${subCategory?.actualSlug || ''}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategoriesLocalized, clearErrors, setValue, values.isOutOfStock]);

  useEffect(() => {
    if (!(values.prerenderStatusCode || '').startsWith('3')) {
      clearErrors('prerenderHeaderLocation');
    }
  }, [clearErrors, values.prerenderStatusCode]);

  return (
    <ProductEditorBlockWrapper blockTitle={titles.generalInformation}>
      <Row justify="stretch" noWrap>
        <ST.ProductCheckboxWrapper>
          <Checkbox
            label={checkbox.showAsOutOfStock}
            onChange={e =>
              setValue('isOutOfStock', e.target.checked, {
                shouldDirty: true,
              })
            }
            checked={values && values.isOutOfStock}
          />
        </ST.ProductCheckboxWrapper>
        {settings.showVerifyBtn && (
          <ST.ProductCheckboxWrapper>
            <ST.IdVerificationCheckbox
              label={checkbox.idVerification}
              onChange={e =>
                setValue('needIDVerification', e.target.checked, {
                  shouldDirty: true,
                })
              }
              checked={values && values.needIDVerification}
            />
          </ST.ProductCheckboxWrapper>
        )}
      </Row>
      {!!values.isOutOfStock && (
        <ST.PrerenderFieldsWrapper>
          <PrerenderInputFields
            values={values}
            errors={errors}
            register={register}
            controlField="isOutOfStock"
          />
        </ST.PrerenderFieldsWrapper>
      )}
      <ProductCarbonCheckbox
        register={register}
        setValue={setValue}
        values={values}
      />
      <ST.StyledGrid gaps="1.5rem 3rem" cols="repeat(3, 1fr)" places="center">
        <Row direction="column" fullWidth justify="start">
          <FieldStyle as={FieldStyle2} legend={fields.category} withBorder>
            <InputStyled
              name="category_id"
              type="hidden"
              ref={register({ required: notification.theFieldIsRequired })}
              defaultValue={values && values.category_id}
            />
            <Select
              options={mainCategories}
              selected={getSelectedCategoryName(values.category_id)}
              defaultText={defaultText.selectCategory}
              aLabel={fields.category}
              onClickOption={value => {
                setValue('category_id', value);
                setValue('sub_category_id', null);
                setValue('sub_sub_category_id', null);
                trigger('category_id');
              }}
              className={inputStyle}
            />
          </FieldStyle>
          <Error
            message={errors && errors.category_id && errors.category_id.message}
          />
        </Row>
        <Row direction="column" fullWidth justify="start">
          <FieldStyle
            as={FieldStyle2}
            legend={fields.subCategory}
            withBorder={true}
          >
            <input
              type="hidden"
              name="sub_category_id"
              ref={register({ required: notification.theFieldIsRequired })}
              defaultValue={values && values.sub_category_id}
            />
            <Select
              options={subCategories}
              selected={getSelectedCategoryName(values.sub_category_id)}
              defaultText={defaultText.selectSubCategory}
              aLabel={fields.category}
              onClickOption={value => {
                setValue('sub_category_id', Number(value));
                setValue('sub_sub_category_id', null);
                trigger('sub_category_id');
              }}
              className={inputStyle}
            />
          </FieldStyle>
          <Error
            message={
              errors && errors.sub_category_id && errors.sub_category_id.message
            }
          />
        </Row>
        <Row direction="column" fullWidth justify="start">
          <FieldStyle
            as={FieldStyle2}
            legend={fields.subSubCategory}
            withBorder={true}
          >
            <input
              type="hidden"
              name="sub_sub_category_id"
              ref={register}
              defaultValue={values && values.sub_sub_category_id}
            />
            <Select
              options={subSubCategories}
              selected={getSelectedCategoryName(values.sub_sub_category_id)}
              defaultText={defaultText.selectSubSubCategory}
              aLabel={fields.category}
              onClickOption={value => {
                setValue('sub_sub_category_id', Number(value));
              }}
              className={inputStyle}
            />
          </FieldStyle>
        </Row>
        <Row direction="column" fullWidth justify="start">
          <FieldStyle as={FieldStyle2} legend={fields.maxQuantity}>
            <InputStyled
              name="max_quantity"
              type="number"
              ref={register({ required: notification.theFieldIsRequired })}
              readOnly={values.isOutOfStock}
              asDisabled={values.isOutOfStock}
            />
          </FieldStyle>
          <Error
            message={
              errors && errors.max_quantity && errors.max_quantity.message
            }
          />
        </Row>
        <Row direction="column" fullWidth justify="start">
          <FieldStyle as={FieldStyle2} legend={fields.popularity}>
            <InputStyled
              name="popularity"
              type="number"
              ref={register({
                required: notification.theFieldIsRequired,
                validate: {
                  rangeOfValues: value =>
                    (value > 0 && value <= 1000) ||
                    formatString(notification.valueShouldBeBetween, 1, 1000),
                },
              })}
              readOnly={values.isOutOfStock}
              asDisabled={values.isOutOfStock}
            />
          </FieldStyle>
          <Error
            message={errors && errors.popularity && errors.popularity.message}
          />
        </Row>
        <Row direction="column" fullWidth justify="start">
          <FieldStyle as={FieldStyle2} legend={fields.marginOfDays}>
            <InputStyled
              name="daysMarginBeforeRental"
              type="number"
              ref={register({
                valueAsNumber: true,
                validate: {
                  noNegative: value =>
                    value >= 0 || notification.valueShouldBePositive,
                },
              })}
            />
          </FieldStyle>
          <Error
            message={
              errors &&
              errors.daysMarginBeforeRental &&
              errors.daysMarginBeforeRental.message
            }
          />
        </Row>
      </ST.StyledGrid>
      <CheckoutAvailability
        values={values}
        errors={errors}
        watch={watch}
        register={register}
        setValue={setValue}
        clearErrors={clearErrors}
        isNewProduct={isNewProduct}
      />
    </ProductEditorBlockWrapper>
  );
};
