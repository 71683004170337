import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { user$ } from 'models/users';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import { AddressItem, AddressText, AddressTitle } from './styles';

import { Row, T8y } from 'ui';

const UserAddressesInfoBlock = () => {
  const { users } = Lang();
  const user = useStore(user$);

  if (!user.deliveryAddresses?.length) {
    return null;
  }

  return (
    <ProductEditorBlockWrapper blockTitle={users.info.deliveryAddressTitle}>
      <Row justify="stretch" align="normal">
        {user.deliveryAddresses.map((el, index) => (
          <AddressItem direction="column">
            <AddressTitle>
              {users.info.address} {index + 1}{' '}
              {el.default && (
                <T8y color="gray3" fontSize="16px" as="span">
                  {' '}
                  (default)
                </T8y>
              )}
            </AddressTitle>
            <AddressText>{el.name}</AddressText>
          </AddressItem>
        ))}
      </Row>
    </ProductEditorBlockWrapper>
  );
};

export default UserAddressesInfoBlock;
