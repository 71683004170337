import styled from 'styled-components';

import { T8y } from 'ui';

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const VariationWrapper = styled.div`
  width: 260px;
  margin-right: 20px;
`;

export const CoursePriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const VatWrapper = styled(T8y)`
  margin-left: 5px;
  margin-right: 25px;
`;

export const PriceField = styled.div`
  max-height: 44px;
`;
