import styled from 'styled-components';

export const OrderInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ${({ $wrap }) =>
    $wrap &&
    `flex-wrap: wrap;   
    width:auto;
    margin-right:-20px;`}
`;
