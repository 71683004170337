import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { branding$ } from 'models/branding';
import { settings$ } from 'models/settings';
import { changePopularityFx } from 'pages/model';
import { diffInDays } from 'utils/dates';

import CustomButton from 'components/atoms/CustomButton';
import Error from 'components/atoms/Error';

import { CopyProductBtn } from '../CopyProductBtn';
import { DeleteProductBtn } from '../DeleteProductBtn';
import { EditProductBtn } from '../EditProductBtn';
import * as ST from './styles';

import { Cell, T8y } from 'ui';

export const Product = React.memo(
  ({
    image,
    names,
    sku,
    slug,
    id,
    popularity,
    created_date,
    hidden,
    isOutOfStock,
    owner = {},
  }) => {
    const { formatString, form, common } = Lang();

    const currentLangState = useStore(currentLang$);
    const settings = useStore(settings$);
    const branding = useStore(branding$);

    const [currPopularity, setCurrPopularity] = useState(popularity);
    const [currPopularityError, setCurrPopularityError] = useState(null);
    const [isPopularityUpdating, setIsPopularityUpdating] = useState(false);

    const name = useMemo(() => {
      return names[currentLangState] || names[settings.defaultLanguage];
    }, [currentLangState, names, settings.defaultLanguage]);

    const isShowDeleteButton = useMemo(() => {
      return diffInDays(created_date) <= 3;
    }, [created_date]);

    const handlePopularityUpdate = useCallback(async () => {
      if (currPopularityError) {
        return;
      }

      try {
        setIsPopularityUpdating(true);

        await changePopularityFx({
          id,
          popularity: currPopularity,
        });
        toast.success(form.toastTitle.successfullyUpdated);
      } catch (e) {
        toast.error(form.toastTitle.wentWrong);
      } finally {
        setIsPopularityUpdating(false);
      }
    }, [currPopularity, currPopularityError, form, id]);

    useEffect(() => {
      if (currPopularity > 0 && currPopularity < 1001) {
        setCurrPopularityError(null);
      } else {
        setCurrPopularityError(
          formatString(form.notification.valueShouldBeBetween, 1, 1000),
        );
      }
    }, [currPopularity, form, formatString]);

    const isPopularityValueIdentical = useMemo(() => {
      try {
        return Number(currPopularity) === Number(popularity);
      } catch (e) {
        return true;
      }
    }, [currPopularity, popularity]);

    return (
      <ST.RowStyled
        rows="9rem"
        cols="1.5fr 2fr 2fr 3fr 2.5fr 1.5fr"
        cols_md="1fr 1fr 1fr"
        rows_md="1fr 1fr"
        places="center start"
        hidden={hidden}
      >
        <T8y bold={hidden}>{sku}</T8y>
        <img
          src={image?.data?.thumb || branding?.defaultImageProduct}
          loading="lazy"
          height="55"
          alt=""
        />
        <T8y bold={hidden}>{owner.data?.name}</T8y>
        <Cell as={T8y} area_md="1 / 1 / 2 / 4" bold={hidden}>
          {name}{' '}
          {hidden && (
            <T8y display="inline" color="#EF3D46" bold>
              ({common.draft})
            </T8y>
          )}
          {isOutOfStock && (
            <T8y display="inline" color="#999999" bold>
              ({form.fields.outOfStock})
            </T8y>
          )}
        </Cell>
        <ST.PopularityWrapper>
          <ST.PopularityInputWrapper>
            <ST.PopularityInput
              name="popularity"
              type="number"
              placeholder="0"
              value={currPopularity}
              onChange={e => setCurrPopularity(e.target.value)}
              disabled={isOutOfStock || hidden}
            />
            {currPopularityError && <Error message={currPopularityError} />}
          </ST.PopularityInputWrapper>

          <CustomButton
            text={form.buttons.update}
            width="100px"
            backgroundColor="primary"
            color="positive"
            colorHovered="gray"
            onClick={
              isPopularityValueIdentical || isPopularityUpdating
                ? undefined
                : handlePopularityUpdate
            }
            disabled={isPopularityValueIdentical || isPopularityUpdating}
          />
        </ST.PopularityWrapper>
        <ST.ButtonsCell>
          <EditProductBtn id={id} />
          <CopyProductBtn id={id} />
          {isShowDeleteButton ? (
            <DeleteProductBtn {...{ name, sku, slug, id }} />
          ) : (
            <>&emsp;</>
          )}
        </ST.ButtonsCell>
      </ST.RowStyled>
    );
  },
);
