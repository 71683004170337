import { analytics } from './es/analytics';
import { categories } from './es/categories';
import { common } from './es/common';
import { form } from './es/form';
import { header } from './es/header';
import { leads } from './es/leads';
import { marketing } from './es/marketing';
import { orders } from './es/orders';
import { product } from './es/product';
import { promoCodes } from './es/promoCodes';
import { settings } from './es/settings';
import { tradeAccounts } from './es/tradeAccounts';
import { trainings } from './es/trainings';
import { users } from './es/users';

export const es = {
  header,
  product,
  marketing,
  form,
  categories,
  analytics,
  users,
  tradeAccounts,
  settings,
  trainings,
  common,
  orders,
  leads,
  promoCodes,
};
