import React, { useCallback } from 'react';
import { Menu, MenuButton, MenuItemCheckbox, useMenuState } from 'reakit';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';

import style from './select.module.scss';

export const MultiSelect = ({
  options,
  className,
  onChange,
  defaultSelected = [],
  getParent,
  innerRef,
}) => {
  const menu = useMenuState();
  const removeOption = useCallback(
    option => {
      const newState = [...defaultSelected];
      const index = defaultSelected.findIndex(el => el.id === option.id);
      newState.splice(index, 1);
      onChange(newState);
    },
    [onChange, defaultSelected],
  );

  const handleOptionClick = useCallback(
    option => {
      menu.hide();

      if (defaultSelected.find(el => el.id === option.id)) {
        removeOption(option);
        return;
      }

      onChange([
        ...defaultSelected,
        {
          ...option,
          displayedNameInSelected: getParent(option).displayedNameInSelected,
        },
      ]);
    },
    [defaultSelected, getParent, menu, onChange, removeOption],
  );

  return (
    <div className={style.menu}>
      <MenuButton
        ref={innerRef}
        {...menu}
        className={clsx(className, style.subMenu)}
      >
        {(!defaultSelected || !defaultSelected.length) && 'Select categories'}
        {!!defaultSelected && (
          <div className={style.selectedOptionsWrapper}>
            {defaultSelected.map((el, index) => (
              <div className={style.selectedOption} key={index}>
                <div className={style.selectedOptionTextWrapper}>
                  {el.displayedNameInSelected
                    ? el.displayedNameInSelected
                    : el.label}
                </div>
                <div
                  className={style.closeIconWrapper}
                  onClick={e => {
                    e.preventDefault();
                    removeOption(el);
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>
        )}
      </MenuButton>
      <Menu {...menu} aria-label="View" className={style.innerMenu}>
        {options?.map((el, index) => (
          <MenuItemCheckbox
            {...menu}
            tabIndex={index}
            name={el.name}
            value={el.id}
            onClick={() => handleOptionClick(el)}
            className={style.option}
            key={el.id}
            checked={
              !!(
                defaultSelected && defaultSelected.find(opt => opt.id === el.id)
              )
            }
          >
            {el.name}
          </MenuItemCheckbox>
        ))}
      </Menu>
    </div>
  );
};
