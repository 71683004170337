import React from 'react';

import * as ST from './styles';

import { Spinner } from 'ui';

const CustomButton = ({
  text,
  onClick,
  backgroundColor,
  color,
  border,
  borderRadius,
  colorHovered,
  type = 'button',
  children,
  isLoading,
  inverse,
  darkSpinner,
  ...rest
}) => {
  return (
    <ST.SubmitButton
      onClick={isLoading ? undefined : onClick}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      borderRadius={borderRadius}
      colorHovered={colorHovered}
      type={type}
      inverse={inverse}
      {...rest}
    >
      {isLoading ? (
        <Spinner inline dark={darkSpinner} />
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </ST.SubmitButton>
  );
};

export default CustomButton;
