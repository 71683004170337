import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { branding$ } from 'models/branding';
import { settings$ } from 'models/settings';

import NewTable from 'components/molecules/NewTable';

import * as ST from './styles';

import { T8y } from 'ui';

const fields = [
  { name: 'id', type: 'text' },
  { name: 'image', type: 'image' },
  { name: 'title', type: 'link' },
  { name: 'hirePeriod', type: 'text' },
  { name: 'hirePerItem', type: 'amount' },
  { name: 'quantity', type: 'qty' },
  { name: 'totalPrice', type: 'amount' },
];

const OrderProducts = ({ products = [], isTraining }) => {
  const { form, orders } = Lang();

  const branding = useStore(branding$);
  const settings = useStore(settings$);
  const currentLangState = useStore(currentLang$);

  const HEADERS = useMemo(() => {
    if (isTraining) {
      return [
        { name: orders.productsTableHeaders.courseId, type: 'text' },
        { name: orders.productsTableHeaders.visual, type: 'text' },
        { name: orders.productsTableHeaders.courseTitle, type: 'text' },
        { name: orders.productsTableHeaders.courseDates, type: 'text' },
        { name: orders.productsTableHeaders.coursePrice, type: 'amount' },
        { name: orders.productsTableHeaders.seats, type: 'qty' },
        { name: orders.productsTableHeaders.totalCost, type: 'amount' },
      ];
    }
    return [
      { name: orders.productsTableHeaders.sku, type: 'text' },
      { name: orders.productsTableHeaders.visual, type: 'text' },
      { name: orders.productsTableHeaders.productTitle, type: 'text' },
      { name: orders.productsTableHeaders.hirePeriod, type: 'text' },
      { name: orders.productsTableHeaders.hirePerItem, type: 'amount' },
      { name: orders.productsTableHeaders.qty, type: 'qty' },
      { name: orders.productsTableHeaders.hirePrice, type: 'amount' },
    ];
  }, [isTraining, orders]);

  const productsForRender = useMemo(() => {
    const formattedProducts = products.map(el => ({
      id: el.sku,
      image: (
        <ST.ImageContainer justify="center" align="center">
          <img
            src={
              el.image?.thumb ||
              (el.type === 'extra'
                ? branding.defaultImageExtra
                : branding?.defaultImageProduct)
            }
            alt={
              el.names[currentLangState] || el.names[settings.defaultLanguage]
            }
          />
        </ST.ImageContainer>
      ),
      title:
        el.type === 'extra' ? (
          <T8y>
            {form.extra}:{' '}
            {el.names[currentLangState] || el.names[settings.defaultLanguage]}
          </T8y>
        ) : (
          <Link to={isTraining ? PATHS.TRAINING(el.id) : PATHS.PRODUCT(el.id)}>
            {el.names[currentLangState] || el.names[settings.defaultLanguage]}
          </Link>
        ),

      hirePeriod: el.startDate && `${el.startDate} - ${el.endDate}`,
      hirePerItem: `${branding.currencySymbol}${el.price ? el.price : '0'}`,
      quantity: el.quantity,
      totalPrice: `${branding.currencySymbol}${el.total ? el.total : '0'}`,
    }));

    if (isTraining && products[0]) {
      formattedProducts[0].id = products[0].id;
    }
    return formattedProducts;
  }, [
    branding,
    currentLangState,
    form.extra,
    isTraining,
    products,
    settings.defaultLanguage,
  ]);

  const cols = useMemo(
    () => (
      <colgroup>
        <col span="1" width="13%" />
        <col span="1" width="70px" />
        <col span="1" width="24%" />
        <col span="1" width="32%" />
        <col span="1" width="15%" />
        <col span="1" width="3%" />
        <col span="1" width="14%" />
      </colgroup>
    ),
    [],
  );

  const productCountWithoutExtra = useMemo(() => {
    return products.filter(el => el.type !== 'extra').length;
  }, [products]);

  return (
    <>
      <T8y fontSize="18px" color="darkBlue" marginBottom="20px" bold>
        {orders.productList} ({productCountWithoutExtra})
      </T8y>
      <NewTable
        headers={HEADERS}
        items={productsForRender}
        keyFields={fields}
        cols={cols}
        bgColor="#FBFCFE"
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default OrderProducts;
