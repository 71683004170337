import React, { useCallback, useEffect } from 'react';
import { Lang } from 'lang';
import { handleUploadEnd } from 'utils/filesDownload';

import TrainingImageItem from 'components/atoms/TrainingImageItem';

import * as ST from './styles';

const TrainingSeveralImagesSection = ({
  register,
  values,
  setValue,
  errors,
  setDeletedFiles,
  trigger,
  watchSectionShow,
  fieldsWatcher,
  fields,
  append,
  remove,
  fieldWatcherName,
  fieldsName,
  IMAGES_COUNT,
  accept,
}) => {
  const { form } = Lang();

  useEffect(() => {
    if (values?.form?.[fieldWatcherName] === undefined) {
      return;
    }

    if (fieldsWatcher.length !== Number(values.form[fieldWatcherName])) {
      setValue(`form.${fieldWatcherName}`, fieldsWatcher.length);
      trigger(`form.${fieldWatcherName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsWatcher]);

  const handleDeleteImage = useCallback(
    (e, el, index) => {
      if (el.id) {
        setDeletedFiles(files => [...files, el.id]);
      }
      remove(index);
    },
    [remove, setDeletedFiles],
  );

  const handleUploaded = useCallback(
    files => {
      if (Array.isArray(files)) {
        files.forEach(({ src }) => {
          append({ src });
        });
      } else {
        append({ src: files.src });
      }
    },
    [append],
  );

  const handleUpload = useCallback(
    async acceptedFiles => {
      await handleUploadEnd({
        acceptedFiles,
        handleUploaded,
        maxAmountOfFiles: IMAGES_COUNT - fields.length,
      });
    },
    [handleUploaded, IMAGES_COUNT, fields.length],
  );

  return (
    <>
      <input
        type="hidden"
        name={`form.${fieldWatcherName}`}
        ref={register({
          valueAsNumber: true,
          validate: watchSectionShow && {
            positive: value =>
              value > 0 || form.notification.theFieldIsRequired,
          },
        })}
        defaultValue={values?.form?.[fieldsName]?.length || 0}
      />
      <ST.ImagesWrapper>
        {fields.map((el, index) => (
          <ST.ImageItemWrapper key={el.formId}>
            <TrainingImageItem
              el={el}
              index={index}
              handleDeleteMainImage={e => handleDeleteImage(e, el, index)}
              accept={accept}
            />
          </ST.ImageItemWrapper>
        ))}
        {fields.length < IMAGES_COUNT && (
          <ST.EmptyFieldWithErrorWrapper>
            <TrainingImageItem handleUpload={handleUpload} accept={accept} />
            <ST.ErrorStyled
              message={errors?.form?.[fieldWatcherName]?.message}
            />
          </ST.EmptyFieldWithErrorWrapper>
        )}
      </ST.ImagesWrapper>
    </>
  );
};

export default TrainingSeveralImagesSection;
