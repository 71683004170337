import algoliasearch from 'algoliasearch';
import { enGB, es, it } from 'date-fns/locale';

export const DEFAULT_DELIVERY_METHODS = [
  'not-available',
  'self-collection',
  'deliveryPerMile',
  'deliveryPerArea',
  'postcode',
  'tbdDelivery',
  'deliveryByRadius',
];

export const ALGOLIA_CLIENT = algoliasearch(
  'EKGBI2MHEW',
  'd32c66e6ce21f3ddfded5135bd53a0fe',
);

export const REACT_APP_GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const languagesOverridesMapLocales = {
  'en-gb': enGB,
  it: it,
  es: es,
};

export const AVAILABLE = 'available';

export const NOT_AVAILABLE = 'not-available';
export const SELF_COLLECTION = 'self-collection';
export const SELF_COLLECTION_ONLY = 'selfCollectionOnly';

export const MAX_IMAGES_COUNT = 8;
export const MAX_GUIDES_COUNT = 3;
export const PRODUCT_MAIN_IMAGE_TYPE = 'productMain';

export const DEFAULT_SELECTOR_STATE = 'all';

export const isProdEnv =
  window.location.hostname !== 'localhost' &&
  !window.location.href.includes('stage.') &&
  !window.location.href.includes('dev.');

export const LANGUAGES = {
  'easytoolhire.com': 'en-gb',
  'easyalquiler.com': 'es',
  'easynoleggio.net': 'it',
  'easyaluguer.com': 'pt',
};

export const COUNTRIES = [
  { name: 'UK', slug: 'easytoolhire.com' },
  { name: 'Spain', slug: 'easyalquiler.com' },
  { name: 'Italy', slug: 'easynoleggio.net' },
  { name: 'Portugal', slug: 'easyaluguer.com' },
];

export const REGIONS = {
  'easytoolhire.com': [
    { id: 1, name: 'England London', apiSlug: 'london' },
    { id: 2, name: 'England North East', apiSlug: 'north-east-england' },
    { id: 3, name: 'England North West', apiSlug: 'north-west-england' },
    { id: 4, name: 'Scotland', apiSlug: 'scotland' },
    { id: 5, name: 'Yorkshire', apiSlug: 'yorkshire' },
    { id: 6, name: 'Wales', apiSlug: 'wales' },
  ],
  'easyalquiler.com': [
    { id: 1, name: 'Andalucia', apiSlug: 'andalucia' },
    { id: 2, name: 'Aragon', apiSlug: 'aragon' },
    {
      id: 3,
      name: 'Espanacentral',
      loginName: 'Castilla y León',
      apiSlug: 'espanacentral',
    },
    {
      id: 4,
      name: 'Espanacentral',
      loginName: 'Castilla-La Mancha',
      apiSlug: 'espanacentral',
    },
    { id: 5, name: 'Cataluña', apiSlug: 'catalunya' },
    {
      id: 6,
      name: 'Espanacentral',
      loginName: 'Comunidad de Madrid',
      apiSlug: 'espanacentral',
    },
    { id: 7, name: 'Comunidad Valenciana', apiSlug: 'comunidad-valenciana' },
    {
      id: 8,
      name: 'Espanacentral',
      loginName: 'Extremadura',
      apiSlug: 'espanacentral',
    },
    { id: 9, name: 'Galicia', apiSlug: 'galicia' },
    { id: 10, name: 'La Rioja', apiSlug: 'larioja' },
    { id: 11, name: 'Navarra', apiSlug: 'navarra' },
  ],
  'easynoleggio.net': [
    { id: 1, name: 'Calabria', apiSlug: 'calabria' },
    { id: 2, name: 'Campania', apiSlug: 'campania' },
    { id: 3, name: 'Emilia-Romagna', apiSlug: 'emiliaromagna' },
    { id: 4, name: 'Lazio', apiSlug: 'lazio' },
    { id: 5, name: 'Lombardia', apiSlug: 'lombardia' },
    { id: 6, name: 'Molise', apiSlug: 'molise' },
    { id: 7, name: 'Piemonte', apiSlug: 'piemonte' },
    { id: 8, name: 'Puglia', apiSlug: 'puglia' },
    { id: 9, name: 'Sardegna', apiSlug: 'sardegna' },
    { id: 10, name: 'Toscana', apiSlug: 'toscana' },
    { id: 11, name: 'Veneto', apiSlug: 'veneto' },
  ],
  'easyaluguer.com': [{ id: 1, name: 'Portugal', apiSlug: 'portugal' }],
};

export const COUNTRY_SLUG_FROM_REGION = {
  veneto: 'easynoleggio.net',
  lombardia: 'easynoleggio.net',
  campania: 'easynoleggio.net',
  calabria: 'easynoleggio.net',
  emiliaromagna: 'easynoleggio.net',
  lazio: 'easynoleggio.net',
  molise: 'easynoleggio.net',
  piemonte: 'easynoleggio.net',
  puglia: 'easynoleggio.net',
  toscana: 'easynoleggio.net',
  sardegna: 'easynoleggio.net',
  galicia: 'easyalquiler.com',
  espanacentral: 'easyalquiler.com',
  'comunidad-valenciana': 'easyalquiler.com',
  catalunya: 'easyalquiler.com',
  navarra: 'easyalquiler.com',
  aragon: 'easyalquiler.com',
  larioja: 'easyalquiler.com',
  andalucia: 'easyalquiler.com',
  portugal: 'easyaluguer.com',
  london: 'easytoolhire.com',
  'north-east-england': 'easytoolhire.com',
  'north-west-england': 'easytoolhire.com',
  scotland: 'easytoolhire.com',
  yorkshire: 'easytoolhire.com',
  wales: 'easytoolhire.com',
};

const getEnvironmentBaseName = () => {
  let envPrefix = '';

  if (
    window.location.href.includes('stage.') ||
    window.location.hostname === 'localhost'
  ) {
    envPrefix = 'stage';
  }

  if (window.location.href.includes('dev.')) {
    envPrefix = 'dev';
  }

  return envPrefix;
};

export const ENV_BASENAME = getEnvironmentBaseName();

const getApiBaseName = () => {
  let apiPrefix = 'admin-api';

  if (
    window.location.href.includes('stage.') ||
    window.location.hostname === 'localhost'
  ) {
    apiPrefix = 'stage-admin-api';
  }

  if (window.location.href.includes('dev.')) {
    apiPrefix = 'dev-admin-api';
  }

  return apiPrefix;
};

export const API_BASENAME = getApiBaseName();

export const getAlgoliaPrefix = () => {
  let apiPrefix = 'api';

  if (
    window.location.href.includes('stage.') ||
    window.location.hostname === 'localhost'
  ) {
    apiPrefix = 'stage-api';
  }

  if (window.location.href.includes('dev.')) {
    apiPrefix = 'dev-api';
  }

  return apiPrefix;
};

export const getMainApiBaseName = () => {
  let apiPrefix = 'api';

  if (
    window.location.href.includes('stage.') ||
    window.location.hostname === 'localhost'
  ) {
    apiPrefix = 'stage-api';
  }

  if (window.location.href.includes('dev.')) {
    apiPrefix = 'dev-api';
  }

  return apiPrefix;
};

export const DEFAULT_PHONE_VALIDATION = {
  ETH: 'GB',
  EA: 'ES',
  EN: 'IT',
  EAR: 'PT',
};

export const REACT_APP_POSTCODE_API_KEY =
  process.env.REACT_APP_POSTCODE_API_KEY;

export const getShortDisplayedCompanyName = country => {
  switch (country) {
    case 'easytoolhire.com':
      return 'ETH';
    case 'easyalquiler.com':
      return 'EA';
    case 'easynoleggio.net':
      return 'EN';
    case 'easyaluguer.com':
      return 'EAR';
    default:
      return 'ETH';
  }
};

export const getApiVersion = country => {
  switch (country) {
    case 'easytoolhire.com':
      return 'v1';
    case 'easyalquiler.com':
      return 'v1';
    case 'easynoleggio.net':
      return 'v1';
    case 'easyaluguer.com':
      return 'v1';
    default:
      return 'v1';
  }
};

export const sectorOfActivity = [
  { slug: 'business' },
  { slug: 'constructions' },
  { slug: 'public_authority' },
  { slug: 'training_courses' },
  { slug: 'gardening' },
  { slug: 'industry' },
  { slug: 'services' },
  { slug: 'transport' },
  { slug: 'tourism' },
  { slug: 'other' },
];

export const aboutUs = [
  { slug: 'search_on_the_internet' },
  { slug: 'television' },
  { slug: 'word_of_mouth' },
  { slug: 'headquarter_visibility' },
  { slug: 'construction_warehouse' },
  { slug: 'gardening_dealer' },
  { slug: 'magazines_and_newspapers' },
  { slug: 'other' },
];
