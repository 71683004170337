import styled from 'styled-components';

export const DeliveryAreaWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const DeliveryAreaName = styled.span`
  width: 150px;
  margin-right: 25px;
  display: flex;
  align-items: center;
`;

export const DeliveryAreaFieldsWrapper = styled.div`
  width: 150px;
  display: flex;

  &:not(:last-child) {
    margin-right: 40px;
  }
`;

export const ErrorWrapper = styled.div`
  align-self: center;
  max-width: 200px;
`;
