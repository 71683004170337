import { useCallback, useMemo, useState } from 'react';
import { Lang } from 'lang';

export const useManageInputState = ({
  currentPostcode,
  setIsPostcodesListVisible,
  setEmptyAddress,
  searchRequestError,
  makePostcodeListVisible,
  autoScrollToList,
}) => {
  const { form, formatString } = Lang();

  const [postCodeError, setPostCodeError] = useState(null);
  const [currPostCodeStr, setCurrPostCodeStr] = useState(currentPostcode || '');

  const handleInputFocus = useCallback(() => {
    if (currPostCodeStr.length >= 3 && !searchRequestError) {
      if (autoScrollToList) {
        makePostcodeListVisible();
      } else {
        setIsPostcodesListVisible(true);
      }
    }
  }, [
    autoScrollToList,
    currPostCodeStr.length,
    makePostcodeListVisible,
    searchRequestError,
    setIsPostcodesListVisible,
  ]);

  const handleInputType = useCallback(
    async e => {
      const currPostcode = e.target.value;
      setCurrPostCodeStr(currPostcode);

      if (currentPostcode) {
        setEmptyAddress();
      }

      if (!currPostcode || currPostcode.trim().length < 3) {
        setPostCodeError(
          !currPostcode
            ? form.notification.required
            : formatString(form.notification.minLength, 3),
        );
      } else {
        setPostCodeError(null);
      }
    },
    [currentPostcode, form, formatString, setEmptyAddress],
  );

  return useMemo(
    () => ({
      handleInputFocus,
      handleInputType,
      currPostCodeStr,
      postCodeError,
      setCurrPostCodeStr,
    }),
    [
      currPostCodeStr,
      handleInputFocus,
      handleInputType,
      setCurrPostCodeStr,
      postCodeError,
    ],
  );
};
