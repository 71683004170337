import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors, colorsHovered } from 'styleGuide';
import { Grid, IconWrapper } from 'ui';

export const AddNewStyled = styled(Link)`
  min-width: 200px;
  height: 50px;
  background: ${colors.primary};
  display: flex;
  border-radius: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  justify-content: center;
  color: #fff;
  transition: background-color 0.5s ease;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${colorsHovered.primaryHovered};
  }
`;

export const RowInfoStyled = styled(Grid)`
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.lightGray};
`;

export const RowTableHeaderStyled = styled(Grid)`
  margin-top: 60px;
  margin-bottom: 30px;
`;

export const StyledIconWrapper = styled(IconWrapper)`
  display: flex;
  align-items: center;

  ${p => p.$marginRight && `margin-right:${p.$marginRight}`}
`;

export const StyledDeleteButton = styled.div`
  cursor: pointer;
`;
