export const trainings = {
  title: 'Gestione Formazione',
  subTitle: 'Gestisci la pagina indice Formazione e i corsi di formazione',
  trainingIndexPage: 'Pagina indice Formazione',
  trainingsCourses: 'Corsi di formazione',
  addNewCourse: 'Aggiungi un nuovo corso',
  closestDate: 'Prossima data',
  showSection: 'Mostra sezione',
  remainingCharacters: '{0} caratteri rimanenti',
  heroImage: 'Hero Immagine',
  heroImageOrientation:
    'Immagine JPG {0} x {1} pixel, orientamento orizzontale',
  thumbnailImage: 'Anteprima immagine',
  thumbnailImageOrientation:
    'Immagine JPG 600 x 450 pixel, orientamento orizzontale',
  yes: 'Si',
  no: 'No',

  indexPage: {
    title: 'Pagina indice Formazione ',
    generalInformation: 'Informazioni generali',
    introductorySection: 'Sezione introduttiva',
    exploreOurTrainingCourses: 'Esplora i nostri corsi di formazione',
    pageTitle: 'Titolo della pagina',
    introductoryText: 'Testo introduttivo',
    automaticallyGeneratedSection:
      'Questa sezione viene generata automaticamente utilizzando i corsi creati',
    sectionTitle: 'Titolo della sezione (es. Corsi di formazione a {0}, etc)',
  },

  courseStatus: 'Stato del corso',
  courseTitle: 'Titolo del corso',
  courseShortDescription: 'Descrizione breve del corso',
  courseDescription: 'Descrizione del corso',
  courseLearning: 'Cosa imparerai',
  courseWhyChooseUs: 'Perchè scegliere noi',
  courseReviews: 'Recensioni del corso',
  frequentlyAskedQuestions: 'Domande Frequenti',
  theoryAndPractice: 'Ore di teoria e pratica (es. 4h Teoria, 8h Pratica)',
  courseGallery: 'Galleria',
  galleryImageRequirements:
    'Requisiti file: immagine JPG 800 x 600 pixel, orientamento orizzontale',
  benefitImageRequirements: 'Immagine JPG o PNG, 120 x 120 pixel',
  scheduleCourses: 'Corsi in programma',
  availabilityAndSchedule: 'Checkout Disponibilità e programma',
  courseIsAvailable: 'Il corso è disponibile per il checkout',
  courseIsAvailableText:
    'Gli utenti potranno effettuare una prenotazione utilizzando il checkout online.',
  courseIsNotAvailable: 'Il corso non è disponibile per il checkout',
  courseIsNotAvailableText:
    'Gli utenti potranno inviare una richiesta utilizzando un semplice modulo di contatto.',
  courseLocation: 'Luogo del corso',
  courseOrganizer: 'Organizzatore del corso (opzionale)',
  datesAndPrice: 'Date e prezzo del corso programmato',
  startDate: 'Data di inizio',
  endDate: 'Data di fine (opzionale)',
  coursePrice: 'Prezzo del corso',
  addNewDate: 'Aggiungi nuova data',
  addNewLocation: 'Aggiungi nuova posizione',
  addNewVariation: 'Aggiungi una nuova variante',
  addNewBenefit: 'Aggiungi nuovo vantaggio',
  addNewReview: 'Aggiungi nuova recensione',
  addNewFAQ: 'Aggiungi nuova FAQ',
  addNewProduct: 'Aggiungi prodotto',
  scheduleDescription:
    'Descrizione del programma (es: il programma è disponibile su richiesta, ecc.)',
  courseLocations: 'Luogo/i del corso',
  priceExample: '(es: € 200,00 + IVA)',
  partnership: 'Il corso è disponibile in partnership con',
  partnershipImageRequirements:
    'Requisiti file: immagine JPG, PNG o SVG, NN x NN pixel',
  closestDateAutomaticallyGenerated:
    'Questa sezione viene aggiornata automaticamente utilizzando i dati del corso forniti in altre sezioni',
  aboutCertificate: 'Informazioni sul certificato',
  certificateImage:
    'Immagine del certificato (immagine JPG 600 x 400 pixel, orientamento orizzontale)',
  emptyDates: 'Date da definire',
  emptyLocation: 'Organizzatore  e luogo del corso da definire',
  emptyPrice: 'Prezzo da definire',
  emptyTheory: 'Ore di teoria e pratica da definire',
  schedule: 'Programma (date e prezzi)',
  noSchedule: 'Nessuna data programmata (solo prezzo)',
  courseVariationTitle: 'Titolo variante corso (facoltativo)',
  recommendedProducts: 'Prodotti consigliati',

  benefits: {
    enterBenefitTitle: 'Inserisci titolo del vantaggio',
    icon: 'Icona',
    shortTitle: 'Titolo breve',
    details: 'Vantaggi',
  },

  reviews: {
    enterReviewFields: 'Inserisci campi recensione',
    photo: 'Foto',
    review: 'Recensione',
    fullName: 'Nome completo (es: Mario Rossi)',
    position: 'Posizione (es: Ingegnere presso X) (opzionale)',
  },

  faq: {
    enterFaq: 'Inserisci una domanda',
    question: 'Domanda',
    answer: 'Risposta',
  },

  enterCourseTitle: 'Inserisci il titolo del corso',
};
