import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDialogState } from 'reakit';
import { Lang } from 'lang';
import { getLead } from 'models/lead';
import { getUser } from 'models/users';

import Error from 'components/atoms/Error';
import { FieldStyle2, TextareaStyled } from 'components/Product/shared';
import { ORDERS } from 'api';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, Row, T8y } from 'ui';

const CancelOrderModal = ({
  orderId,
  userId,
  orderType,
  setIsCancelOrderLoading,
  isSmall,
}) => {
  const { orders, form } = Lang();

  const dialog = useDialogState();

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const handleCancelOrder = useCallback(
    data => {
      dialog.hide();
      setIsCancelOrderLoading(true);

      ORDERS.cancelOrder(orderId, data)
        .then(() => {
          if (!isSmall) {
            getLead({ id: orderId, type: orderType });
          }
          getUser(userId);
          toast.success(form.toastTitle.successfullyRequest);
        })
        .catch(() => {
          toast.error(form.toastTitle.wentWrong);
        })
        .finally(() => setIsCancelOrderLoading(false));
    },
    [
      dialog,
      setIsCancelOrderLoading,
      orderId,
      isSmall,
      userId,
      form,
      orderType,
    ],
  );

  const disclosure = useMemo(
    () => (
      <ST.OrderCancelButton
        color="gray2"
        fontSize={isSmall ? '14px' : '18px'}
        cursor="pointer"
      >
        {orders.cancelOrder}
      </ST.OrderCancelButton>
    ),
    [isSmall, orders],
  );

  return (
    <Modal
      disclosure={disclosure}
      title={orders.cancelOrder}
      dialogState={dialog}
      width="430px"
      overflow="hidden"
      backgroundColor={colors.primary}
      lazyRender
    >
      <T8y marginTop="20px" marginBottom="20px">
        {orders.youWantToCancelThisOrder}
      </T8y>
      <form onSubmit={handleSubmit(handleCancelOrder)}>
        <FieldStyle2 legend={orders.provideCancellationReason}>
          <TextareaStyled
            name="reasons"
            ref={register({
              validate: value => {
                return !!value.trim() || form.notification.theFieldIsRequired;
              },
            })}
          />
        </FieldStyle2>
        <Error message={errors && errors.reasons && errors.reasons.message} />
        <Row justify="end">
          <ST.OrderCancelModalButton onClick={dialog.hide}>
            {form.buttons.close}
          </ST.OrderCancelModalButton>
          <ST.OrderCancelModalButton type="submit">
            {orders.submitRequest}
          </ST.OrderCancelModalButton>
        </Row>
      </form>
    </Modal>
  );
};

export default CancelOrderModal;
