import React from 'react';

import { Row, Spinner } from 'ui';

const LoadingSpinner = () => (
  <Row align="center" justify="center" style={{ height: '100vh' }}>
    <Spinner dark size={5} />
  </Row>
);

export default LoadingSpinner;
