import styled from 'styled-components';

import { FieldStyle2 } from '../../Product/shared';

import { colors } from 'styleGuide';

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;

  & > button:first-child {
    margin-right: 20px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > div:first-child {
    flex-basis: 40%;
    margin-right: 20px;
  }
`;

export const FileUploadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const FileNameWrapper = styled.div`
  flex-grow: 1;
  margin: 0 10px 0 20px;

  ${p => p.removeLastMargin && 'margin-right: 0'}
`;

export const FormFieldsWrapper = styled.div`
  margin-top: 20px;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const MonthSelector = styled(FieldStyle2)`
  display: flex;
  align-items: flex-end;
`;

export const LocalFieldWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
`;
