import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { deleteUploadedFileFx, uploadPhotoFx } from 'models/product';
import { settings$ } from 'models/settings';
import { currentProductOwner$ } from 'pages/model';
import { handleUploadEnd } from 'utils/filesDownload';

import Error from 'components/atoms/Error';
import { Icon } from 'components/Product/Icon';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from 'components/Product/shared';
import { PRODUCTS } from 'api';

import * as ST from './styles';

import { Cell, DropZoneBlock, Grid } from 'ui';

export const AttributeEditForm = ({
  dialog,
  btnText,
  item,
  append,
  isCheckbox = false,
  type,
  typeForActiveCheckbox,
  isDescription = false,
  reload = null,
}) => {
  const {
    form: { toastTitle, fields, checkbox, buttons, notification },
  } = Lang();

  const settings = useStore(settings$);

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    watch,
    getValues,
  } = useForm();

  const currentProductOwner = useStore(currentProductOwner$);

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, toggleChecked] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const setExtraInfo = useCallback(() => {
    setValue('name', item.value);
    setValue('sku', item.sku);
    setValue('price', item.price);
    setValue('photo.src', item.media && item.media.data && item.media.data.src);
    setValue(
      'photo.cover_id',
      item.media && item.media.data && item.media.data.id,
    );

    if (isCheckbox && item.slug === typeForActiveCheckbox) {
      toggleChecked(true);
    }

    if (isDescription) {
      setValue('description', item.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, item, isCheckbox, typeForActiveCheckbox]);

  useEffect(() => {
    register('photo.src');
    register('photo.cover_id');

    if (item && Object.keys(item).length) {
      setIsEdit(true);
      setExtraInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCheckbox = useCallback(
    () => toggleChecked(prevState => !prevState),
    [],
  );

  const handleDelete = useCallback(
    (e, photo) => {
      e.preventDefault();
      e.stopPropagation();
      setDeletedId(photo?.cover_id);
      setValue('photo.src', null);
      setValue('photo.cover_id', null);
      setIsImageUpdated(false);
    },
    [setValue],
  );

  const attachPhotoToExtra = useCallback(
    async extraId => {
      const actualValues = getValues({ nest: true });

      if (actualValues && actualValues.photo && actualValues.photo.src) {
        await uploadPhotoFx({
          src: actualValues.photo.src,
          object_id: extraId,
          type: 'extra',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadPhotoFx],
  );

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const params = {
        type: isChecked ? typeForActiveCheckbox : type,
        price: formData.price,
        sku: formData.sku,
        value: formData.name,
        description: isDescription ? formData.description : undefined,
        franchiseeId: currentProductOwner?.id || undefined,
      };

      if (deletedId) {
        await deleteUploadedFileFx(deletedId);
      }

      if (isEdit) {
        await PRODUCTS.updateExtra(item.id, params);

        if (isImageUpdated) {
          await attachPhotoToExtra(item.id);
        }
      } else {
        const { data } = await PRODUCTS.createExtra(params);
        await attachPhotoToExtra(data.data.id);
        append({ serverId: data.data.id });
      }

      dialog.hide();
      if (reload && typeof reload === 'function') {
        reload();
      }
    } catch (error) {
      if (error.response) {
        toast.error(toastTitle.extraCantBeCreated);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = useCallback(
    acceptedFiles => {
      handleUploadEnd({
        acceptedFiles,
        handleUploaded: ({ src }) => {
          setValue('photo.src', src);
          setIsImageUpdated(true);
        },
        maxAmountOfFiles: 1,
      });
    },
    [setValue],
  );

  return (
    <form>
      <Grid
        cols="17rem 1fr"
        places="start start"
        gaps="0 3rem"
        as={ST.GridStyled}
      >
        <Cell as={ST.CellStyled} place="start start">
          <ST.ImageWrapper disabled={loading}>
            {values.photo?.src ? (
              <>
                <ST.UploadEmptyBlock src={values.photo.src} />
                <ST.IconWrapper>
                  <Icon
                    type="delete"
                    handleClick={e => handleDelete(e, values.photo)}
                  />
                </ST.IconWrapper>
              </>
            ) : (
              <DropZoneBlock handleUpload={handleUpload} multiple={false} />
            )}
          </ST.ImageWrapper>
        </Cell>
        <Cell as={ST.CellStyled} place="start start">
          <FieldStyle as={FieldStyle2}>
            <InputStyled
              type="text"
              placeholder={fields.productCode}
              name="sku"
              disabled={loading}
              ref={register({
                validate: value =>
                  !!value?.trim() || notification.theFieldIsRequired,
              })}
            />
          </FieldStyle>
          <Error message={errors && errors.sku && errors.sku.message} />
          <ST.FormRow marginTop="10px">
            <FieldStyle as={FieldStyle2}>
              <InputStyled
                type="text"
                placeholder={fields.name}
                name="name"
                disabled={loading}
                ref={register({
                  validate: value =>
                    !!value?.trim() || notification.theFieldIsRequired,
                })}
              />
            </FieldStyle>
          </ST.FormRow>
          <Error message={errors && errors.name && errors.name.message} />
          {isDescription && (
            <ST.FormRow>
              <FieldStyle as={FieldStyle2}>
                <TextareaStyled
                  type="text"
                  placeholder={fields.description}
                  name="description"
                  disabled={loading}
                />
              </FieldStyle>
            </ST.FormRow>
          )}
          <ST.FormRow marginTop="10px">
            <FieldStyle as={FieldStyle2}>
              <InputStyled
                type="text"
                placeholder={fields.price}
                name="price"
                disabled={loading}
                ref={register({
                  validate: value =>
                    !!value?.trim() || notification.theFieldIsRequired,
                })}
              />
            </FieldStyle>
          </ST.FormRow>
          <Error message={errors && errors.price && errors.price.message} />
          {isCheckbox && (
            <ST.FormRow isBlocked={settings.fixedRangesPrice}>
              <ST.CheckboxStyled
                checked={isChecked}
                onChange={onChangeCheckbox}
                isBlocked={settings.fixedRangesPrice}
              />
              {checkbox.retailItem}{' '}
              {settings.fixedRangesPrice && `(${checkbox.controlledBy})`}
            </ST.FormRow>
          )}
          <ST.FormRow>
            <ST.SubmitButton
              type="button"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {loading
                ? btnText === 'Adding'
                  ? buttons.adding
                  : buttons.editing
                : btnText}
            </ST.SubmitButton>
          </ST.FormRow>
        </Cell>
      </Grid>
    </form>
  );
};
