import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';

import { SETTINGS } from 'api';

import { DEFAULT_DELIVERY_METHODS } from '../consts';

export const loadSettingsFx = createEffect({
  handler: async () => {
    const { data } = await SETTINGS.getAllSettings();

    return { ...data };
  },
});

export const settingsLoading$ = restore(loadSettingsFx.pending, false);

export const updateSettingFx = createEffect({
  handler: async id => {
    const { data } = await SETTINGS.updateSetting(id);
    return data;
  },
});

export const setUserSettings = createEvent();
export const getSettings = createEvent();
export const updateUserSetting = createEvent();
export const updatePartOfSetting = createEvent();

export const settings$ = createStore({ supportedLanguages: [] })
  .on(loadSettingsFx.doneData, (state, data) => {
    data.supportedLanguages.sort(el => {
      if (el === data.defaultLanguage) {
        return -1;
      } else {
        return 0;
      }
    });

    data.defaultDeliveryMethodType =
      DEFAULT_DELIVERY_METHODS[data.defaultDeliveryMethodType];

    data.twoIntegrationEnabled = true;

    return data;
  })
  .on(setUserSettings, (state, data) => {
    return data;
  })
  .on(updatePartOfSetting, (state, data) => {
    return { ...state, ...data };
  });

forward({
  from: getSettings,
  to: loadSettingsFx,
});

forward({
  from: updateUserSetting,
  to: updateSettingFx,
});
