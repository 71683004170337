import React from 'react';
import { AVAILABLE, NOT_AVAILABLE, SELF_COLLECTION_ONLY } from 'consts';
import { Lang } from 'lang';

import Error from 'components/atoms/Error';

import { FieldStyle, FieldStyle2 } from '../../Product/shared';
import * as ST from './styles';
import { useSelectorsState } from './useSelectorsState';

import { inputStyle, Select } from 'ui';

export const CheckoutAvailability = ({
  register,
  setValue,
  values,
  errors,
  watch,
  clearErrors,
  isNewProduct,
}) => {
  const {
    form: { fields, defaultText, notification },
  } = Lang();

  const {
    selectedDeliveryType,
    selectedDeliveryOption,
    handleRadioClick,
    selectDeliveryOption,
    selectDeliveryType,
    radio,
    optionsForOptionSection,
    optionsForSelect,
    deliveryOptionWatcher,
  } = useSelectorsState(setValue, watch, clearErrors, isNewProduct);

  return (
    <ST.SectionWrapper>
      <ST.SectionTitle>{fields.deliveryType}</ST.SectionTitle>
      <ST.RadioGroupWrapper {...radio} onClick={handleRadioClick}>
        <ST.AvailableCheckoutOptions>
          <ST.RadioOptionStyled
            id="radio-checkout-available"
            {...radio}
            text={fields.checkoutAvailable}
            onClick={handleRadioClick}
            value={AVAILABLE}
            disabled={!values.owner?.checkoutAvailability}
          />
          <ST.SelectWrapper
            direction="column"
            fullWidth
            justify="start"
            disabled
          >
            <FieldStyle as={FieldStyle2} withBorder={true}>
              <input
                type="hidden"
                name="deliveryOption"
                ref={register({
                  required:
                    radio.state !== NOT_AVAILABLE &&
                    notification.theFieldIsRequired,
                })}
                defaultValue={values?.deliveryOption}
              />
              <Select
                options={optionsForOptionSection}
                selected={selectedDeliveryOption?.name}
                defaultText={defaultText.selectDeliveryType}
                onClickOption={selectDeliveryOption}
                className={inputStyle}
                isSelectDisable={radio.state === NOT_AVAILABLE}
              />
            </FieldStyle>
            <Error message={errors?.deliveryOption?.message} />
          </ST.SelectWrapper>
          <ST.SelectWrapper
            direction="column"
            fullWidth
            justify="start"
            disabled
          >
            <FieldStyle as={FieldStyle2} withBorder={true}>
              <input
                type="hidden"
                name="delivery_id"
                ref={register({
                  required:
                    radio.state !== NOT_AVAILABLE &&
                    deliveryOptionWatcher !== SELF_COLLECTION_ONLY &&
                    notification.theFieldIsRequired,
                  valueAsNumber: true,
                  validate: {
                    positive: value =>
                      radio.state === NOT_AVAILABLE ||
                      deliveryOptionWatcher === SELF_COLLECTION_ONLY ||
                      value > 0 ||
                      notification.theFieldIsRequired,
                  },
                })}
                defaultValue={values?.delivery_id}
              />
              <Select
                options={optionsForSelect}
                selected={selectedDeliveryType?.name}
                defaultText={defaultText.selectDeliveryType}
                aLabel={fields.deliveryType}
                onClickOption={selectDeliveryType}
                className={inputStyle}
                isSelectDisable={
                  radio.state === NOT_AVAILABLE ||
                  !selectedDeliveryOption ||
                  selectedDeliveryOption.slug === SELF_COLLECTION_ONLY
                }
              />
            </FieldStyle>
            <Error message={errors?.delivery_id?.message} />
          </ST.SelectWrapper>
        </ST.AvailableCheckoutOptions>
        <ST.NotAvailableRadio
          id="radio-checkout-not-available"
          {...radio}
          text={fields.checkoutNotAvailable}
          value={NOT_AVAILABLE}
          onClick={handleRadioClick}
        />
      </ST.RadioGroupWrapper>
    </ST.SectionWrapper>
  );
};
