import numeral from 'numeral';

export const getNumeral = () => {
  numeral.defaultFormat('0,0');
  numeral.zeroFormat('N/A');
  numeral.nullFormat('N/A');

  if (!('it' in numeral.locales)) {
    numeral.register('locale', 'it', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'mila',
        million: 'mil',
        billion: 'b',
        trillion: 't',
      },
      ordinal: number => 'º',
      currency: {
        symbol: '€',
      },
    });
  }

  numeral.locale('it');

  return numeral;
};

export default getNumeral;
