import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
} from 'effector';
import { createGate } from 'effector-react';
import { getFormattedStringDate } from 'utils/dates';
import { getFormattedOrderProducts } from 'utils/getFormattedOrderProducts';

import { LEADS } from 'api';

export const loadLeadFx = createEffect({
  handler: async ({ id, type }) => {
    const res = await LEADS.getLead(id, { type });

    const formattedLead = res.data;

    formattedLead.created_at = getFormattedStringDate(
      formattedLead.created_at || formattedLead.addedOn,
    );

    formattedLead.type = formattedLead.lead_type || formattedLead.type;

    formattedLead.products = getFormattedOrderProducts(
      formattedLead.products,
      formattedLead.type,
    );

    if (formattedLead.payment?.deposit?.refundDate) {
      formattedLead.payment.deposit.refundDate = getFormattedStringDate(
        formattedLead.payment.deposit.refundDate,
      );
    }

    return formattedLead;
  },
});

export const clearLead = createEvent();
export const getLead = createEvent();
export const setLeadDocs = createEvent();

export const lead$ = createStore(null)
  .on(loadLeadFx.doneData, (s, p) => p)
  .on(setLeadDocs, (s, { newDocs, type }) =>
    newDocs ? { ...s, [type]: newDocs } : s,
  )
  .on(clearLead, () => null);

forward({
  from: getLead,
  to: loadLeadFx,
});

export const getLeadsFiltersFx = createEffect({
  handler: async () => {
    const data = await LEADS.getLeadsFilterTypes();

    return data.data.types;
  },
});

export const leadsFiltersGate = createGate('leadsFilters');

export const leadsTypesFilters$ = createStore([]).on(
  getLeadsFiltersFx.doneData,
  (s, p) => p,
);

const isLoadingLeadsFilters = getLeadsFiltersFx.pending.map(state => !!state);

guard({
  source: leadsFiltersGate.open,
  filter: combine(
    isLoadingLeadsFilters,
    leadsTypesFilters$,
    (isLoadingLeadsFilters, leadsFilters) =>
      !isLoadingLeadsFilters && !leadsFilters.length,
  ),
  target: getLeadsFiltersFx,
});
