import React, { Fragment } from 'react';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { categoriesTreeStore } from 'models/categories';
import { relatedProductsStore, removeRelatedProduct } from 'models/product';
import { settings$ } from 'models/settings';
import { allProductsWithoutFiltersLocalized$ } from 'pages/model';
import { getCategoryName } from 'utils/getCategoryName';

import styled from 'styled-components';

import { RelativeProductsModal } from '../molecules/RelativeProductsModal';
import { IconStyled } from './shared';

import { colors } from 'styleGuide';
import { Cell, Grid, T8y } from 'ui';
import { Icon } from 'ui/Icon';

import { ReactComponent as DeleteIcon } from 'assets/images/icon/delete.svg';
import { ReactComponent as ProductIcon } from 'assets/images/icon/product.svg';

const FrequentlyHiredTogetherGrid = styled.div`
  margin-top: 5rem;
  margin-bottom: 2rem;
`;

const FrequentlyHiredTogetherTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
`;

const FrequentlyHiredTogetherBody = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  padding: 4rem 3rem;
  background: #fff;
  margin-bottom: 5rem;
  font-size: 1.4rem;
  color: #424242;
  display: flex;
  align-items: center;

  ${p =>
    p.flexDirection &&
    `
    flex-direction: ${p.flexDirection};
  `}
`;

const TableHeader = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;

  ${p =>
    p.marginTop &&
    `
    margin-top: ${p.marginTop};
  `}
`;

const TableHeaderCell = styled(Cell)`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.textGray};
`;

const TableBodyCell = styled(Cell)`
  font-size: 1.4rem;
  color: ${colors.inputColor};
`;

const TableImage = styled.img`
  max-width: 3.7rem;
  max-height: 3.7rem;
`;

const DeleteIconStyled = styled(Icon)`
  cursor: pointer;

  path {
    fill: ${colors.textGray};
  }
`;

const RelativeProducts = ({ register }) => {
  const { form } = Lang();

  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);
  const relativeProducts = useStore(relatedProductsStore);
  const products = useStore(allProductsWithoutFiltersLocalized$);
  const categories = useStore(categoriesTreeStore);

  const {
    form: { fields },
  } = Lang();

  return (
    <Fragment>
      <Grid
        places="center start"
        cols="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr"
        gaps="1rem 1rem"
        as={TableHeader}
      >
        <TableHeaderCell>{fields.productCode}</TableHeaderCell>
        <TableHeaderCell>{fields.category}</TableHeaderCell>
        <TableHeaderCell>{fields.subCategory}</TableHeaderCell>
        <TableHeaderCell>{fields.subSubCategory}</TableHeaderCell>
        <TableHeaderCell>{fields.photo}</TableHeaderCell>
        <TableHeaderCell>{fields.name}</TableHeaderCell>
        <Cell />
      </Grid>
      {relativeProducts.map((productId, index) => {
        const product = products.find(p => p.id === productId);
        const currentCategories = getCategoryName(
          product?.category?.data,
          categories,
          currentLangState,
          settings?.defaultLanguage,
        );

        const category =
          currentCategories.level1 && currentCategories.level1.name;
        const sub_category =
          currentCategories.level2 && currentCategories.level2.name;
        const sub_sub_category =
          currentCategories.level3 && currentCategories.level3.name;

        return product ? (
          <Fragment key={index}>
            <input
              type="hidden"
              name={`related[${index}]`}
              value={productId}
              ref={register}
            />
            <Grid
              places="center start"
              cols="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr"
              gaps="0 1rem"
              as={TableHeader}
              key={index}
              marginTop="3rem"
            >
              <TableBodyCell>{product.sku}</TableBodyCell>
              <TableBodyCell>{category}</TableBodyCell>
              <TableBodyCell>{sub_category}</TableBodyCell>
              <TableBodyCell>{sub_sub_category}</TableBodyCell>
              <TableBodyCell>
                {product.image && <TableImage src={product.image.data.thumb} />}
              </TableBodyCell>
              <TableBodyCell>
                {product.name}
                {product.isOutOfStock && (
                  <T8y as="span" fontSize="14px" color="danger" bold>
                    {' '}
                    ({form.fields.outOfStock})
                  </T8y>
                )}
              </TableBodyCell>
              <Cell place="center end">
                <DeleteIconStyled
                  as={DeleteIcon}
                  onClick={() => removeRelatedProduct(productId)}
                />
              </Cell>
            </Grid>
          </Fragment>
        ) : null;
      })}
    </Fragment>
  );
};

export const FrequentlyHiredTogether = ({ register }) => {
  const dialog = useDialogState();
  const relativeProducts = useStore(relatedProductsStore);

  const {
    product: { titles },
  } = Lang();

  return (
    <Fragment>
      <Grid as={FrequentlyHiredTogetherGrid} cols="2fr 1fr">
        <Cell place="center start">
          <FrequentlyHiredTogetherTitle>
            {titles.frequentlyHiredTogether}
          </FrequentlyHiredTogetherTitle>
        </Cell>
        <Cell place="center end">
          <RelativeProductsModal dialog={dialog} />
        </Cell>
      </Grid>
      <FrequentlyHiredTogetherBody
        flexDirection={relativeProducts.length === 0 ? 'row' : 'column'}
      >
        {relativeProducts.length === 0 && (
          <Fragment>
            <IconStyled as={ProductIcon} />
            {titles.noRelatedProducts}...
          </Fragment>
        )}
        {relativeProducts.length > 0 && (
          <RelativeProducts register={register} />
        )}
      </FrequentlyHiredTogetherBody>
    </Fragment>
  );
};
