import React, { useCallback, useEffect, useState } from 'react';
import { DisclosureContent, useDialogState, useDisclosureState } from 'reakit';
import { Lang } from 'lang';

import DeliveryByRadiusItem from 'components/molecules/DeliveryByRadiusItem';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Checkbox, Modal, T8y } from 'ui';

import { ReactComponent as ArrowBottom } from 'assets/images/icon/arrowBottomOrange.svg';

const DeliveryByRadius = ({
  deliveryItem,
  register,
  control,
  deliveryIndex,
  values,
  setValue,
  errors,
  FIELDS_NAMES_LIST,
  trigger,
  watch,
  isErrors,
}) => {
  const { settings, form, formatString } = Lang();

  const [copiedConfiguration, setCopiedConfiguration] = useState(false);
  const [isCopyConfigurationEnabled, setIsCopyConfigurationEnabled] = useState(
    true,
  );

  const dialogStateCopyConfiguration = useDialogState();
  const disclosure = useDisclosureState();

  const watchDeliveryByRadius = watch(
    `deliveries[${deliveryIndex}].deliveryByRadius`,
  );

  useEffect(
    () => {
      register(`deliveries[${deliveryIndex}].outOfRangeNotAvailable`);
      setValue(
        `deliveries[${deliveryIndex}].outOfRangeNotAvailable`,
        deliveryItem.outOfRangeNotAvailable || false,
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [register, setValue],
  );

  useEffect(
    () => {
      if (
        isErrors &&
        errors.deliveries?.[deliveryIndex]?.deliveryByRadius?.slice(1).length
      ) {
        disclosure.show();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryIndex, errors, isErrors],
  );

  const handleCopyConfiguration = useCallback(() => {
    setCopiedConfiguration(watchDeliveryByRadius?.[0]?.ranges);
    dialogStateCopyConfiguration.hide();
    setIsCopyConfigurationEnabled(false);
  }, [dialogStateCopyConfiguration, watchDeliveryByRadius]);

  if (!watchDeliveryByRadius?.length) {
    return null;
  }

  return (
    <>
      <Checkbox
        label={settings.outOfRangeNotAvailable}
        fontSize="14px"
        checked={values.deliveries[deliveryIndex].outOfRangeNotAvailable}
        onChange={e =>
          setValue(
            `deliveries[${deliveryIndex}].outOfRangeNotAvailable`,
            e.target.checked,
          )
        }
      />
      <ST.OutOfRangeNotAvailableMsg
        fontSize="14px"
        color="gray2"
        marginTop="4px"
        marginBottom="30px"
        marginLeft="30px"
      >
        {settings.outOfRangeNotAvailableMsg}
      </ST.OutOfRangeNotAvailableMsg>
      <DeliveryByRadiusItem
        index={0}
        deliveryItemIndex={deliveryIndex}
        handleCopyConfigurationClick={dialogStateCopyConfiguration.show}
        FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
        setValue={setValue}
        control={control}
        errors={errors}
        register={register}
        watch={watch}
        trigger={trigger}
        setIsCopyConfigurationEnabled={setIsCopyConfigurationEnabled}
        isCopyConfigurationEnabled={isCopyConfigurationEnabled}
        deliveryByRadiusItem={watchDeliveryByRadius[0]}
      />
      {watchDeliveryByRadius.length > 1 && (
        <>
          <DisclosureContent {...disclosure}>
            {watchDeliveryByRadius.slice(1).map((el, index) => (
              <DeliveryByRadiusItem
                key={index}
                index={index + 1}
                deliveryItemIndex={deliveryIndex}
                copiedConfiguration={copiedConfiguration}
                setCopiedConfiguration={setCopiedConfiguration}
                FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
                setValue={setValue}
                control={control}
                errors={errors}
                register={register}
                watch={watch}
                trigger={trigger}
                deliveryByRadiusItem={el}
              />
            ))}
          </DisclosureContent>
          <ST.DepotsDisclosureStyled {...disclosure}>
            <T8y fontSize="16px" color="primary">
              {formatString(
                disclosure.visible
                  ? settings.showFirstDepotOnly
                  : settings.showAllDepots,
                watchDeliveryByRadius.length,
              )}
            </T8y>
            <ArrowBottom />
          </ST.DepotsDisclosureStyled>
        </>
      )}
      <Modal
        title={settings.modals.copyConfigurationTitle}
        dialogState={dialogStateCopyConfiguration}
        width="420px"
        overflow="hidden"
        backgroundColor={colors.primary}
        lazyRender
      >
        <T8y lineHeight="22px" marginTop="30px">
          {settings.modals.copyConfigurationText}
        </T8y>
        <ST.DeliveryConfirmationModalControlsWrapper>
          <ST.CustomButtonStyled
            text={form.buttons.cancel}
            backgroundColor="positive"
            color="gray3"
            width="120px"
            height="29px"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            onClick={dialogStateCopyConfiguration.hide}
          />
          <ST.CustomButtonStyled
            text={settings.modals.copyConfigurationTitle}
            backgroundColor="positive"
            color="gray3"
            border="1px solid #F89739"
            colorHovered="gray"
            width="120px"
            height="29px"
            onClick={handleCopyConfiguration}
          />
        </ST.DeliveryConfirmationModalControlsWrapper>
      </Modal>
    </>
  );
};

export default DeliveryByRadius;
