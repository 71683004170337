import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useStore } from 'effector-react';
import { useClosestTrainingDate } from 'hooks';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';

import * as ST from './styles';

import { T8y } from 'ui';

import { ReactComponent as DrugDrop } from 'assets/icons/drugDrop.svg';

const TrainingItem = ({
  history,
  item,
  inIndexPage,
  link,
  provided = { draggableProps: {}, dragHandleProps: {} },
}) => {
  const { trainings, form, product } = Lang();

  const branding = useStore(branding$);

  const { closestDate } = useClosestTrainingDate(item);

  const isItemActive = useMemo(() => {
    return item?.status;
  }, [item]);

  const itemImage = useMemo(() => {
    let image = null;
    if (item) {
      image = inIndexPage
        ? item.mainImage?.src
        : item.mainImageThumb?.data.thumb;
    }
    return image || branding?.defaultImageProduct;
  }, [branding, inIndexPage, item]);

  const handleItemClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  if (!item) {
    return null;
  }

  return (
    <ST.TrainingItemWrapper
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      {provided.placeholder}
      <ST.DrugDropIconWrapper>
        {!inIndexPage && (
          <div {...provided.dragHandleProps}>
            <DrugDrop />
          </div>
        )}
      </ST.DrugDropIconWrapper>
      <ST.ImgWrapper>
        <img src={itemImage} alt="Training" />
      </ST.ImgWrapper>
      <ST.InfoWrapper>
        <T8y
          color="primary"
          fontSize="18px"
          cursor="pointer"
          marginBottom="4px"
          bold
          onClick={handleItemClick}
        >
          {item.title}
        </T8y>
        {!inIndexPage && (
          <>
            <T8y
              color="#2E2E2E"
              fontSize="16px"
              marginBottom="12px"
              bold
              onClick={handleItemClick}
            >
              {`${product.owner}: ${item.owner?.data?.name}`}
            </T8y>
            <T8y color="negative" fontSize="16px">
              {trainings.closestDate}: {closestDate || 'N/A'}
            </T8y>
          </>
        )}
        {inIndexPage ? (
          <ST.TextWrapper>{item.text}</ST.TextWrapper>
        ) : (
          <ST.TextWrapper
            marginTop="4px"
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        )}
      </ST.InfoWrapper>
      {!inIndexPage && (
        <T8y
          fontSize="16px"
          lineHeight="22px"
          color={isItemActive ? 'green' : 'danger'}
          bold
        >
          {isItemActive ? form.active : form.inactive}
        </T8y>
      )}
    </ST.TrainingItemWrapper>
  );
};

export default withRouter(TrainingItem);
