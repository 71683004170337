import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';

import ShowSection from 'components/atoms/ShowSection';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import TrainingSeveralImagesSection from '../TrainingSeveralImagesSection';

import { T8y } from 'ui';

const IMAGES_COUNT = 4;

const TrainingPartnership = ({
  watch,
  register,
  values,
  setValue,
  errors,
  setDeletedFiles,
  trigger,
  control,
  clearErrors,
  isNew,
}) => {
  const { trainings } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const watchSectionShow = watch('form.partnerShow');

  const fieldsWatcher = watch(
    'form.partnerImages',
    trainingLocalized?.partnerImages || [],
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.partnerImages',
    keyName: 'formId',
  });

  const handleShowChange = useCallback(
    value => {
      setValue('form.partnerShow', value);
      if (!value) {
        clearErrors('form.partnerImagesLength');
      } else {
        trigger('form.partnerImagesLength');
      }
    },
    [clearErrors, setValue, trigger],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.partnership}
      listOfFields={['partnerImagesLength']}
      errors={errors}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={isNew || trainingLocalized?.partnerShow}
          handleChange={handleShowChange}
        />
      }
    >
      <TrainingSeveralImagesSection
        register={register}
        values={values}
        setValue={setValue}
        errors={errors}
        setDeletedFiles={setDeletedFiles}
        trigger={trigger}
        watchSectionShow={watchSectionShow}
        fieldsWatcher={fieldsWatcher}
        fields={fields}
        append={append}
        remove={remove}
        fieldWatcherName="partnerImagesLength"
        fieldsName="partnerImages"
        IMAGES_COUNT={IMAGES_COUNT}
        accept="image/jpeg, image/jpg, image/png, .svg"
      />
      <T8y fontSize="12px" color="gray" align="center">
        {trainings.partnershipImageRequirements}
      </T8y>
    </GeneralExpandableSection>
  );
};

export default TrainingPartnership;
