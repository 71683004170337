import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { deleteUploadedPhotoFx, uploadPhotoFx } from 'models/product';
import { settings$ } from 'models/settings';
import {
  getTrainingIndex,
  trainingIndexLoading$,
  trainingIndexLocalized$,
  updateTrainingIndex,
} from 'models/trainings';

import TrainingIndexFormCoursesList from 'components/molecules/TrainingIndexFormCoursesList';
import TrainingIndexFormGeneralInformation from 'components/molecules/TrainingIndexFormGeneralInformation';
import TrainingIndexFormIntroductory from 'components/molecules/TrainingIndexFormIntroductory';
import TrainingsEditorHeader from 'components/molecules/TrainingsEditorHeader';
import TrainingSeoForm from 'components/molecules/TrainingSeoForm';

import { Spinner } from 'ui';

const TrainingIndex = ({ history }) => {
  const { trainings, form } = Lang();

  const settings = useStore(settings$);
  const trainingIndexLocalized = useStore(trainingIndexLocalized$);
  const trainingIndexLoading = useStore(trainingIndexLoading$);

  const {
    getValues,
    errors,
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: {
      form: {
        title: null,
        description: null,
        mainImage: {
          id: null,
          src: null,
        },
        introductoryShow: false,
        introductoryTitle: null,
        introductoryText: null,
      },
    },
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const [deletedFile, setDeletedFile] = useState(null);

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  useEffect(() => {
    if (!trainingIndexLoading && !trainingIndexLocalized) {
      getTrainingIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      form: { ...trainingIndexLocalized },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingIndexLocalized]);

  const getIsError = useCallback(
    field => {
      return (
        errors &&
        errors.form &&
        errors.form[field] &&
        errors.form[field].message
      );
    },
    [errors],
  );

  const deletePreviousAttachedImage = useCallback(async () => {
    if (deletedFile) {
      await deleteUploadedPhotoFx(deletedFile);
      setDeletedFile(null);
    }
  }, [deletedFile]);

  const attachedNewImage = useCallback(async src => {
    await uploadPhotoFx({
      src: src,
      object_id: 1,
      type: 'trainingIndex',
    });
  }, []);

  const onSubmit = useCallback(
    async data => {
      try {
        deletePreviousAttachedImage();

        if (!data.form.mainImage.id) {
          await attachedNewImage(data.form.mainImage.src);
        }

        const defaultLang = settings.defaultLanguage;

        await updateTrainingIndex({
          title: { [defaultLang]: data.form.title },
          text: { [defaultLang]: data.form.text },
          introductoryShow: data.form.introductoryShow,
          introductoryTitle: { [defaultLang]: data.form.introductoryTitle },
          introductoryText: { [defaultLang]: data.form.introductoryText },
          seoH1: data.form.seoH1,
          metaTitle: data.form.metaTitle,
          metaDescription: data.form.metaDescription,
          seoRobots: data.form.seoRobots,
          seoDescription: data.form.seoDescription,
        });
        toast.success(form.toastTitle.successfullyUpdated);
        history.push(PATHS.TRAININGS);
      } catch (e) {
        toast.error(form.toastTitle.updateError);
      }
    },
    [
      attachedNewImage,
      deletePreviousAttachedImage,
      form.toastTitle.successfullyUpdated,
      form.toastTitle.updateError,
      history,
      settings.defaultLanguage,
    ],
  );

  const onError = useCallback(() => {
    toast.error(form.toastTitle.invalidForm);
  }, [form.toastTitle.invalidForm]);

  if (trainingIndexLoading) {
    return <Spinner dark size={5} />;
  }

  if (!trainingIndexLocalized) {
    return null;
  }

  return (
    <TrainingsEditorHeader
      handleSubmit={handleSubmit(onSubmit, onError)}
      pageHeader={trainings.indexPage.title}
    >
      <TrainingIndexFormGeneralInformation
        watch={watch}
        register={register}
        values={values}
        setValue={setValue}
        setDeletedFile={setDeletedFile}
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
      />
      <TrainingSeoForm register={register} watch={watch} />

      <TrainingIndexFormCoursesList />

      <TrainingIndexFormIntroductory
        watch={watch}
        register={register}
        values={values}
        errors={errors}
        setValue={setValue}
        trigger={trigger}
        getIsError={getIsError}
      />
    </TrainingsEditorHeader>
  );
};

export default withRouter(TrainingIndex);
