import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import { USERS } from 'api';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';

import { Checkbox, T8y } from 'ui';

const UserDeposit = ({ userId, isDeposit, isDisabled }) => {
  const {
    users: usersLocalization,
    form: { fields, toastTitle },
  } = Lang();

  const [isLoading, setIsLoading] = useState(false);
  const [isDepositEnabled, setIsDepositEnabled] = useState(isDeposit !== false);

  const handleChange = useCallback(
    value => {
      setIsDepositEnabled(value);
      setIsLoading(true);

      USERS.updateUser(userId, { depositEnabled: value })
        .then(res => {
          setIsDepositEnabled(res.data.data?.deposit_setting !== false);
          toast(`${usersLocalization.deposit.title}  ${toastTitle.wasUpdated}`);
        })
        .catch(() => {
          setIsDepositEnabled(!setIsDepositEnabled);
          toast.error(toastTitle.updateError);
        })
        .finally(() => setIsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, toastTitle, fields],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={usersLocalization.deposit.title}>
      <Checkbox
        label={usersLocalization.deposit.option}
        onChange={e => !isLoading && handleChange(e.target.checked)}
        checked={isDepositEnabled}
        disabled={isLoading || isDisabled}
      />
      <T8y fontSize="1.6rem" marginTop="15px" color="">
        {usersLocalization.deposit.description}
      </T8y>
    </ProductEditorBlockWrapper>
  );
};

export default UserDeposit;
