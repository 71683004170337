import React, { useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  changeHideTestTACustomers,
  changeSelectedTACustomersLimit,
  changeTACustomersTablePage,
  clearTradeAccounts,
  customersGate,
  setTACustomersFilter,
  setTACustomersSearchStr,
  TACustomersFilters$,
  tradeAccountsCustomers$,
  tradeAccountsCustomersMeta$,
} from 'models/tradeAccounts';

import CustomButton from 'components/atoms/CustomButton';
import Table from 'components/atoms/Table';
import FiltersPanel from 'components/molecules/FiltersPanel';

import { Checkbox, Row, T8y, TablePaging } from 'ui';

const keyFields = [
  { name: 'first_name', type: 'text' },
  { name: 'last_name', type: 'text' },
  { name: 'email', type: 'text' },
  { name: 'phone', type: 'text' },
  { name: 'idVerified', type: 'text' },
  { name: 'link', type: 'link' },
];

const PrivateTradeAccounts = () => {
  const {
    form: { fields },
    users: usersLocalized,
    tradeAccounts: tradeAccountsLocalized,
    formatString,
  } = Lang();

  const tradeAccounts = useStore(tradeAccountsCustomers$);
  const tradeAccountsMeta = useStore(tradeAccountsCustomersMeta$);
  const tradeAccountsFilters = useStore(TACustomersFilters$);

  useGate(customersGate, tradeAccountsFilters);

  const history = useHistory();

  const filterOptions = useMemo(() => {
    return Object.keys(tradeAccountsLocalized.filterSelect).map(el => ({
      name: tradeAccountsLocalized.filterSelect[el],
      slug: el,
    }));
  }, [tradeAccountsLocalized]);

  const headers = useMemo(() => {
    return [
      usersLocalized.tableHeaders.firstName,
      usersLocalized.tableHeaders.lastName,
      usersLocalized.tableHeaders.emailAddress,
      usersLocalized.tableHeaders.phoneNumber,
      usersLocalized.tableHeaders.idVerification,
      '',
    ];
  }, [usersLocalized]);

  useEffect(() => {
    return () => clearTradeAccounts();
  }, []);

  const limitOptions = useMemo(() => {
    return [
      { name: fields.showAll, slug: 10000 },
      { name: `${fields.show} 100`, slug: 100 },
      { name: `${fields.show} 50`, slug: 50 },
      { name: `${fields.show} 10`, slug: 10 },
    ];
  }, [fields]);

  const limitDisplayName = useMemo(() => {
    return limitOptions.find(({ slug }) => slug === tradeAccountsFilters.limit)
      .name;
  }, [limitOptions, tradeAccountsFilters]);

  const modifiedDisplayedUsers = useMemo(() => {
    return tradeAccounts.map(el => ({
      first_name: el.firstName,
      last_name: el.lastName,
      email: el.email,
      phone: el.phone || el.address?.phone,
      idVerified: el.idVerified || '-',
      link: (
        <Link to={PATHS.TRADE_ACCOUNT_PROFILE('private', el.id)}>
          {usersLocalized.viewProfile}
        </Link>
      ),
    }));
  }, [tradeAccounts, usersLocalized]);

  const selected = useMemo(() => {
    return (
      filterOptions.find(el => el.slug === tradeAccountsFilters.selector)
        ?.name || ''
    );
  }, [filterOptions, tradeAccountsFilters]);

  return (
    <>
      <FiltersPanel
        selectOptions={filterOptions}
        selected={selected}
        onClickOption={setTACustomersFilter}
        onChangeSearch={setTACustomersSearchStr}
        searchStr={tradeAccountsFilters.search}
        searchPlaceholder={tradeAccountsLocalized.searchPlaceholder}
        foundStr={formatString(
          tradeAccountsLocalized.foundStr,
          tradeAccountsMeta.total || 0,
        )}
        limitOptions={limitOptions}
        limitDisplayName={limitDisplayName}
        handleLimitOptionChange={changeSelectedTACustomersLimit}
        className="usersFilter"
      >
        <Row align="center" noWrap>
          <Checkbox
            onChange={e => changeHideTestTACustomers(e.target.checked)}
            checked={tradeAccountsFilters.hideTestUsers}
          />
          <T8y color="negative">{tradeAccountsLocalized.hideTestAccounts}</T8y>
        </Row>
        <CustomButton
          text="Add new Private"
          backgroundColor="primary"
          color="positive"
          border="1px solid #f89739"
          colorHovered="gray"
          onClick={() => {
            history.push(PATHS.TRADE_ACCOUNT_PROFILE('private', 'new'));
          }}
        />
      </FiltersPanel>
      <Table
        headers={headers}
        keyFields={keyFields}
        items={modifiedDisplayedUsers}
        cols="2fr 2fr 3fr 2fr 2fr 1.5fr"
      />
      {tradeAccountsMeta.total_pages > 1 && (
        <TablePaging
          handlePageChange={changeTACustomersTablePage}
          totalPages={tradeAccountsMeta.total_pages}
          currPage={tradeAccountsMeta.current_page}
        />
      )}
    </>
  );
};

export default PrivateTradeAccounts;
