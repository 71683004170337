import React, { useEffect } from 'react';
import { Lang } from 'lang';

import PrerenderInputFields from 'components/atoms/PrerenderInputFields';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';

import { Checkbox, Row } from 'ui';

export const PrerenderSection = ({
  register,
  setValue,
  errors,
  values,
  clearErrors,
}) => {
  const { categories } = Lang();

  useEffect(() => {
    register({ name: 'hidden' });
  }, [register]);

  useEffect(() => {
    if (!values.hidden && clearErrors) {
      clearErrors(['prerenderStatusCode', 'prerenderHeaderLocation']);
    }
  }, [clearErrors, setValue, values.hidden]);

  useEffect(() => {
    if (!(values.prerenderStatusCode || '').startsWith('3')) {
      clearErrors('prerenderHeaderLocation');
    }
  }, [clearErrors, values.prerenderStatusCode]);

  return (
    <ProductEditorBlockWrapper blockTitle={categories.titles.prerenderInfo}>
      <Row direction="column" noWrap>
        <Checkbox
          label={categories.hideCategory}
          onChange={e => setValue('hidden', e.target.checked)}
          checked={values.hidden}
        />
        <PrerenderInputFields
          values={values}
          errors={errors}
          register={register}
          controlField="hidden"
        />
      </Row>
    </ProductEditorBlockWrapper>
  );
};
