import { getFormattedStringDate } from './dates';

export const getFormattedOrderProducts = (products, type) => {
  if (products) {
    return type === 'training'
      ? [
          {
            ...products,
            startDate:
              products.startDate &&
              getFormattedStringDate(products.startDate, 'dd/MM/yyyy'),
            endDate:
              products.endDate &&
              getFormattedStringDate(products.endDate, 'dd/MM/yyyy'),
          },
        ]
      : products.map(item => ({
          ...item,
          startDate: getFormattedStringDate(item.startDate, 'dd/MM/yyyy'),
          endDate: getFormattedStringDate(item.endDate, 'dd/MM/yyyy'),
        }));
  }
  return null;
};
