import styled from 'styled-components';

import { colors, colorsHovered } from 'styleGuide';

export const SubmitButton = styled.button`
  display: flex;
  width: 14.5rem;
  height: 4rem;
  border-radius: 0.5rem;
  background-color: ${colors.primary};
  outline: none;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  transition: background-color 0.5s ease;
  margin: 0 auto;

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: ${colors.gray};
  `}

  &:hover {
    background-color: ${colorsHovered.primaryHovered};
    cursor: pointer;

    ${p =>
      p.disabled &&
      `
      cursor: not-allowed;
      pointer-events: all !important;
      background-color: ${colors.gray};
    `}
  }
`;
