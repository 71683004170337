import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import { ORDERS } from 'api';

export const useOrderCancelRequest = onUpdateCallback => {
  const { form } = Lang();

  const [
    isAnswerForCancelOrderSending,
    setIsAnswerForCancelOrderSending,
  ] = useState(false);

  const sendAnswerForCancelOrder = useCallback(
    (orderId, cancelRequestId, value) => {
      setIsAnswerForCancelOrderSending(true);
      ORDERS.sendAnswerForCancelOrder(orderId, cancelRequestId, value)
        .then(() => {
          if (onUpdateCallback) {
            onUpdateCallback();
          }

          if (value === 'accepted') {
            toast.success(form.toastTitle.successfullyApproved);
            return;
          }
          toast.success(form.toastTitle.successfullyDeclined);
        })
        .catch(() => {
          toast.error(form.toastTitle.wentWrong);
        })
        .finally(() => {
          setIsAnswerForCancelOrderSending(false);
        });
    },
    [form, onUpdateCallback],
  );

  return useMemo(
    () => ({
      sendAnswerForCancelOrder,
      isAnswerForCancelOrderSending,
    }),
    [isAnswerForCancelOrderSending, sendAnswerForCancelOrder],
  );
};
