import styled from 'styled-components';

import { FieldStyle2 } from '../../Product/shared';

import { colors } from 'styleGuide';
import { Field } from 'ui';

export const SlugText = styled(FieldStyle2)`
  color: ${colors.gray};
  text-decoration: underline;
`;

export const SlugLink = styled.a`
  color: ${colors.gray};
  text-decoration: underline;
  margin-top: 10px;
`;

export const FeatureDeleteIconWrapper = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 1.2rem;
`;

export const EditorWrapper = styled(Field)`
  & > span {
    font-weight: 500;
    font-size: ${props => props.fontSize || '1.4rem'};
  }

  & > span {
    color: ${colors.gray} !important;
  }
`;
