import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { ownersGate } from 'models/owners';
import { deleteUploadedPhotoFx, uploadPhotoFx } from 'models/product';
import { settings$ } from 'models/settings';
import {
  clearTraining,
  createTrainingFx,
  getTraining,
  trainingLoading$,
  trainingLocalized$,
  updateTrainingFx,
} from 'models/trainings';

import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import SelectTrainingOwner from 'components/molecules/SelectTrainingOwner';
import TrainingAboutCertificate from 'components/molecules/TrainingAboutCertificate';
import TrainingClosestDate from 'components/molecules/TrainingClosestDate';
import TrainingFaq from 'components/molecules/TrainingFaq';
import TrainingFormDescription from 'components/molecules/TrainingFormDescription';
import TrainingFormWhatYouWillLearn from 'components/molecules/TrainingFormLearning';
import TrainingGallery from 'components/molecules/TrainingGallery';
import TrainingGeneralInformation from 'components/molecules/TrainingGeneralInformation';
import TrainingPartnership from 'components/molecules/TrainingPartnership';
import TrainingRecommendedProducts from 'components/molecules/TrainingRecommendedProducts';
import TrainingReviews from 'components/molecules/TrainingReviews';
import TrainingSchedule from 'components/molecules/TrainingSchedule';
import TrainingsEditorHeader from 'components/molecules/TrainingsEditorHeader';
import TrainingSeo from 'components/molecules/TrainingSeo';
import TrainingWhyChoseUs from 'components/molecules/TrainingWhyChooseUs';
import { history } from 'libs/history';

import { T8y } from 'ui';

const EMPTY_DATE = {
  start: '',
  end: '',
  price: null,
  title: '',
};

const EMPTY_LOCATION = {
  lat: 0,
  lng: 0,
  placeId: '',
  name: '',
};

const EMPTY_SCHEDULE = {
  location: {
    ...EMPTY_LOCATION,
  },
  organizer: '',
  variationsType: 0,
  variations: [
    {
      start: null,
      end: null,
      price: null,
      title: '',
    },
  ],
  variationsPricesOnly: [{ price: '', title: '' }],
};

const TrainingEditor = ({ match }) => {
  const { trainings, product, form } = Lang();

  useGate(ownersGate);

  const trainingLocalized = useStore(trainingLocalized$);
  const trainingLoading = useStore(trainingLoading$);
  const settings = useStore(settings$);

  const [isTrainingLoading, setIsTrainingLoading] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const {
    getValues,
    errors,
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    trigger,
    control,
    clearErrors,
    unregister,
    setError,
  } = useForm({
    defaultValues: {
      form: {
        status: false,
        title: null,
        shortDescription: null,
        scheduleType: 0,
        theory: null,
        mainImage: {
          id: null,
          src: null,
        },
        mainImageThumb: {
          id: null,
          src: null,
        },
        schedules: [{ ...EMPTY_SCHEDULE }],
        noSchedule: {
          description: '',
          locations: [{ ...EMPTY_LOCATION }],
          variations: [{ price: '', title: '' }],
          organizer: '',
        },
        galleryImages: [],
        partnerImages: [],
        about: {
          image: {
            id: null,
            src: null,
          },
          text: null,
        },
        description: null,
        learningShow: true,
        learningText: null,
        partnerShow: true,
        closestShow: true,
        galleryShow: true,
        benefitShow: false,
        reviewShow: false,
        faqShow: false,
        certificateShow: true,
        benefits: [],
        reviews: [],
        faqs: [],
        certificateImage: null,
        certificateText: null,
        recommendedProducts: [],
        owner: null,
      },
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const idFromUrl = useMemo(() => {
    return match?.params?.id;
  }, [match]);

  useEffect(() => {
    if (idFromUrl && idFromUrl !== 'new') {
      getTraining(idFromUrl);
    }

    return () => clearTraining();
  }, [idFromUrl]);

  useEffect(() => {
    if (trainingLocalized) {
      reset({
        form: {
          ...trainingLocalized,
          schedules: trainingLocalized.schedules.length
            ? trainingLocalized.schedules
            : [{ ...EMPTY_SCHEDULE }],
          noSchedule: trainingLocalized.noSchedule || {
            description: '',
            locations: [{ ...EMPTY_LOCATION }],
            variations: [{ price: '', title: '' }],
            organizer: '',
          },
          owner: trainingLocalized.owner?.data || null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingLocalized]);

  const isNew = useMemo(() => idFromUrl === 'new', [idFromUrl]);

  const getIsError = useCallback(
    field => {
      return (
        errors &&
        errors.form &&
        errors.form[field] &&
        errors.form[field].message
      );
    },
    [errors],
  );

  const deletePreviousAttachedImage = useCallback(async () => {
    if (deletedFiles?.length) {
      await Promise.all(
        deletedFiles.map(async id => {
          await deleteUploadedPhotoFx(id);
        }),
      );
    }
  }, [deletedFiles]);

  const attachedNewImage = useCallback(async (id, src, type) => {
    await uploadPhotoFx({
      src: src,
      object_id: id,
      type: type,
    });
  }, []);

  const attachedImages = useCallback(
    async (id, data) => {
      const promises = [];
      if (!data.mainImage.id) {
        promises.push({ src: data.mainImage.src, type: 'trainingMainImage' });
      }
      if (!data.mainImageThumb.id) {
        promises.push({
          src: data.mainImageThumb.src,
          type: 'trainingMainImageThumb',
        });
      }
      if (data.certificateImage.src && !data.certificateImage.id) {
        promises.push({
          src: data.certificateImage.src,
          type: 'trainingCertificateImage',
        });
      }

      if (data.partnerImages.length) {
        data.partnerImages.map(async image => {
          if (!image.id && image.src) {
            promises.push({ src: image.src, type: 'trainingPartnerImages' });
          }
        });
      }

      data.galleryImages.map(async image => {
        if (!image.id && image.src) {
          promises.push({ src: image.src, type: 'trainingGalleryImages' });
        }
      });

      await Promise.all(
        promises.map(async el => {
          await attachedNewImage(id, el.src, el.type);
        }),
      );
    },
    [attachedNewImage],
  );

  const attachedSubImages = useCallback(
    async (serverData, localData) => {
      const filesForAttach = [];

      serverData.benefits.data.forEach(el => {
        const indexInLocalData = localData.benefits.findIndex(
          localEl => localEl.text === el.text?.[settings.defaultLanguage],
        );
        const iconImage = localData.benefits[indexInLocalData]?.iconImage;

        if (iconImage?.src && !iconImage.id) {
          filesForAttach.push({
            id: el.id,
            src: iconImage.src,
            type: 'trainingBenefitIconImage',
          });
        }
      });

      serverData.reviews.data.forEach(el => {
        const indexInLocalData = localData.reviews.findIndex(
          localEl => localEl.text === el.text?.[settings.defaultLanguage],
        );
        const photo = localData.reviews[indexInLocalData]?.photo;

        if (photo?.src && !photo.id) {
          filesForAttach.push({
            id: el.id,
            src: photo.src,
            type: 'trainingReviewPhoto',
          });
        }
      });

      await Promise.all(
        filesForAttach.map(async el => {
          await attachedNewImage(el.id, el.src, el.type);
        }),
      );
    },
    [attachedNewImage, settings.defaultLanguage],
  );

  const onSubmit = useCallback(
    async data => {
      try {
        const formData = data.form;
        setIsTrainingLoading(true);
        deletePreviousAttachedImage();

        const defaultLang = settings.defaultLanguage;

        const localizedData = {
          status: formData.status,
          description: formData.description && {
            [defaultLang]: formData.description,
          },
          title: formData.title && {
            [defaultLang]: formData.title,
          },
          shortDescription: formData.shortDescription && {
            [defaultLang]: formData.shortDescription,
          },
          scheduleType: formData.scheduleType,
          theory: formData.theory && { [defaultLang]: formData.theory },
          schedules:
            formData.scheduleType === 0
              ? formData.schedules.map(schedule => ({
                  id: schedule.id,
                  variationsType: schedule.variationsType,
                  location: schedule.location,
                  organizer: schedule.organizer
                    ? { [defaultLang]: schedule.organizer }
                    : undefined,
                  variations:
                    schedule.variationsType === 0
                      ? schedule.variations
                      : schedule.variationsPricesOnly,
                }))
              : [],
          scheduleDescription:
            formData.scheduleType === 0
              ? {
                  [defaultLang]: formData.scheduleDescription || null,
                }
              : { [defaultLang]: null },
          noSchedule:
            formData.scheduleType === 1
              ? {
                  ...formData.noSchedule,
                  locations: formData.noSchedule.locations.find(
                    el => el.lat && el.lng,
                  )
                    ? [...formData.noSchedule.locations]
                    : [],
                  variations: formData.noSchedule.variations.find(
                    el => el.price,
                  )
                    ? [...formData.noSchedule.variations]
                    : [],
                  description: {
                    [defaultLang]: formData.noSchedule.description,
                  },
                  organizer: formData.noSchedule.organizer
                    ? { [defaultLang]: formData.noSchedule.organizer }
                    : undefined,
                }
              : [],
          learningText: { [defaultLang]: formData.learningText },
          benefitShow: formData.benefitShow,
          benefits: formData.benefits.map(benefit => ({
            ...benefit,
            title: { [defaultLang]: benefit.title },
            text: { [defaultLang]: benefit.text },
          })),
          learningShow: formData.learningShow,
          galleryShow: formData.galleryShow,
          certificateShow: formData.certificateShow,
          certificateText: { [defaultLang]: formData.certificateText },
          reviewShow: formData.reviewShow,
          reviews: formData.reviews.map(review => ({
            ...review,
            name: { [defaultLang]: review.name },
            text: { [defaultLang]: review.text },
            position: { [defaultLang]: review.position },
          })),
          partnerShow: formData.partnerShow,
          closestShow: formData.closestShow,
          faqShow: formData.faqShow,
          faqs: formData.faqs.map(faq => ({
            question: { [defaultLang]: faq.question },
            answer: { [defaultLang]: faq.answer },
          })),
          seoH1: formData.seoH1 || undefined,
          metaTitle: formData.metaTitle || undefined,
          metaDescription: formData.metaDescription || undefined,
          seoRobots: formData.seoRobots || undefined,
          seoDescription: formData.seoDescription || undefined,
          recommendedProducts: formData.recommendedProducts,
          recommendationsShow: formData.recommendationsShow,
          franchiseeId: formData.owner?.id,
        };

        const serverData = isNew
          ? await createTrainingFx(localizedData)
          : await updateTrainingFx({ id: idFromUrl, data: localizedData });

        await attachedImages(serverData.id, formData);
        await attachedSubImages(serverData, formData);
        toast.success(form.toastTitle.successfullyUpdated);
        history.push(PATHS.TRAININGS);
      } catch (e) {
        console.warn(e);
        toast.error(form.toastTitle.updateError);
      } finally {
        setIsTrainingLoading(false);
      }
    },
    [
      attachedImages,
      attachedSubImages,
      deletePreviousAttachedImage,
      form,
      idFromUrl,
      isNew,
      settings.defaultLanguage,
    ],
  );

  const onError = useCallback(() => {
    toast.error(form.toastTitle.invalidForm);
  }, [form.toastTitle.invalidForm]);

  if (!idFromUrl) {
    return null;
  }

  if (isNew && !values.form?.owner) {
    return <SelectTrainingOwner setValue={setValue} />;
  }

  return (
    <TrainingsEditorHeader
      handleSubmit={handleSubmit(onSubmit, onError)}
      pageHeader={
        idFromUrl === 'new'
          ? trainings.addNewCourse || ' '
          : values?.form?.title || trainings.enterCourseTitle
      }
      loading={isTrainingLoading}
    >
      {(isTrainingLoading || trainingLoading) && <SpinnerOverlay />}
      <GeneralExpandableSection
        index={0}
        header={product.productOwner}
        listOfFields={['owner']}
        initialIsOpen
      >
        <T8y fontSize="16px" color="negative" bold>
          {values.form.owner?.name || '-'}
        </T8y>
      </GeneralExpandableSection>
      <TrainingGeneralInformation
        watch={watch}
        register={register}
        values={values}
        setValue={setValue}
        deletedFiles={deletedFiles}
        setDeletedFiles={setDeletedFiles}
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
        isNew={isNew}
      />
      <TrainingSeo
        register={register}
        values={values}
        setValue={setValue}
        watch={watch}
        isNewCourse={isNew}
      />
      <TrainingPartnership
        watch={watch}
        register={register}
        values={values}
        setValue={setValue}
        setDeletedFiles={setDeletedFiles}
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
        control={control}
        clearErrors={clearErrors}
        isNew={isNew}
      />
      <TrainingClosestDate
        values={values}
        setValue={setValue}
        errors={errors}
        isNew={isNew}
      />
      <TrainingGallery
        watch={watch}
        register={register}
        values={values}
        setValue={setValue}
        deletedFiles={deletedFiles}
        setDeletedFiles={setDeletedFiles}
        errors={errors}
        trigger={trigger}
        control={control}
        clearErrors={clearErrors}
        isNew={isNew}
      />
      <TrainingSchedule
        watch={watch}
        register={register}
        values={values}
        setValue={setValue}
        setDeletedFiles={setDeletedFiles}
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
        control={control}
        EMPTY_SCHEDULE={EMPTY_SCHEDULE}
        EMPTY_LOCATION={EMPTY_LOCATION}
        EMPTY_DATE={EMPTY_DATE}
        clearErrors={clearErrors}
        unregister={unregister}
        setError={setError}
      />
      <TrainingFormDescription
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
        values={values}
        setValue={setValue}
        register={register}
      />
      <TrainingFormWhatYouWillLearn
        errors={errors}
        getIsError={getIsError}
        setValue={setValue}
        register={register}
        watch={watch}
        clearErrors={clearErrors}
        trigger={trigger}
        getValues={getValues}
        values={values}
        control={control}
        isNew={isNew}
      />
      <TrainingAboutCertificate
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
        values={values}
        setValue={setValue}
        register={register}
        watch={watch}
        control={control}
        clearErrors={clearErrors}
        isNew={isNew}
        setDeletedFiles={setDeletedFiles}
      />
      <TrainingWhyChoseUs
        errors={errors}
        trigger={trigger}
        values={values}
        setValue={setValue}
        control={control}
        register={register}
        setDeletedFiles={setDeletedFiles}
        watch={watch}
        clearErrors={clearErrors}
      />
      <TrainingReviews
        errors={errors}
        values={values}
        setValue={setValue}
        control={control}
        register={register}
        setDeletedFiles={setDeletedFiles}
        watch={watch}
        clearErrors={clearErrors}
      />
      <TrainingFaq
        errors={errors}
        trigger={trigger}
        values={values}
        setValue={setValue}
        control={control}
        register={register}
        watch={watch}
        clearErrors={clearErrors}
      />
      <TrainingRecommendedProducts
        setValue={setValue}
        values={values}
        watch={watch}
      />
    </TrainingsEditorHeader>
  );
};

export default withRouter(TrainingEditor);
