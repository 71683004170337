export const promoCodes = {
  searchPlaceholder: 'Digita per filtrare i risultati',
  promoCodesFound: '{0} codici promozionali disponibili',
  addNewPromoCode: 'Aggiungi nuovo codice promozionale',
  addPromoCode: 'Aggiungi codice promozionale',
  editPromoCode: 'Modifica codice promozionale',
  taUsersOnly: 'Solo utenti TA',
  allCatalogue: 'Catalogo completo',
  specificCategories: 'Categorie specifiche',
  specificProducts: 'Prodotti specifici',
  pause: 'Pausa',
  resume: 'Riprendere',
  enterProductSKU: 'Inserisci SKU del prodotto',
  useCommaToSeparate: 'Utilizza la virgola per separare più elementi',
  cat: 'Categorie',
  maxDiscount: 'Sconto massimo',

  statuses: {
    active: 'Attivo',
    past: 'Passato',
    paused: 'In pausa',
  },

  types: {
    multiple: 'Multiplo',
    onTime: 'Utilizzo singolo',
  },

  userTypes: {
    all: 'Tutti gli utenti',
    taOnly: 'Solo utenti TA',
    notTaOnly: 'Solo utenti ospiti',
  },

  tableHeaders: {
    promoTextCode: 'Codice di testo promozionale',
    discountValue: 'Valore di sconto',
    status: 'Status',
    type: 'Tipo',
    startDate: 'Data di inizio',
    endDate: 'Data di fine',
    applyPromoCodeTo: 'Applica codice promozionale a',
  },

  filterSelect: {
    all: 'Tutto',
    active: 'Codici promozionali attivi',
    paused: 'Codici promozionali sospesi',
    past: 'Codici promozionali passati',
  },
};
