import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';

import AddMore from 'components/atoms/AddMore';
import OtherChargesItem from 'components/atoms/OtherChargesItem';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';

import { Grid } from 'ui';

export const ProductOtherCharges = ({ register, control }) => {
  const settings = useStore(settings$);

  const {
    product: { titles },
  } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'other_charges',
  });

  const handleAdd = useCallback(
    () =>
      append(
        settings.supportedLanguages.reduce((acc, el) => {
          acc[el] = '';
          return acc;
        }, {}) || {},
      ),
    [append, settings.supportedLanguages],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={titles.otherCharges}>
      <Grid gaps="1.5rem 3rem" places="center start">
        {fields.map((item, index) => (
          <OtherChargesItem
            key={item.id}
            index={index}
            control={control}
            register={register}
            handleRemove={index => remove(index)}
            item={item}
          />
        ))}
      </Grid>
      <AddMore onClick={handleAdd} />
    </ProductEditorBlockWrapper>
  );
};
