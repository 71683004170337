import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import {
  changeConsumablesSearchStr,
  consumablesFiltered$,
  consumablesSearchStr$,
} from 'models/consumables';
import { currentProductOwner$ } from 'pages/model';

import ModalWrapper from 'components/molecules/ModalWrapper';

const ConsumablesModal = ({ fields, append, remove }) => {
  const {
    product: { titles },
  } = Lang();

  const branding = useStore(branding$);
  const consumables = useStore(consumablesFiltered$);
  const currentProductOwner = useStore(currentProductOwner$);

  const availableConsumables = useMemo(
    () =>
      currentProductOwner
        ? consumables.filter(
            el => el.ownerId?.toString() === currentProductOwner.id?.toString(),
          )
        : [],
    [consumables, currentProductOwner],
  );

  const fieldsForTable = useMemo(() => {
    return [titles.photo, titles.extraCode, titles.name, titles.price];
  }, [titles]);

  const newProducts = useMemo(
    () =>
      availableConsumables.map(product => {
        return [
          { value: product.id, type: 'checkbox', field: 'id' },
          {
            value: product.media.data.src || branding.defaultImageExtra,
            type: 'image',
            field: 'image',
          },
          { value: product.sku, type: 'initial', field: 'sku' },
          { value: product.value, type: 'initial', field: 'value' },
          { value: product.price, type: 'initial', field: 'price' },
        ];
      }),
    [availableConsumables, branding.defaultImageExtra],
  );

  const selected = useMemo(() => {
    return fields.map(el => el.serverId);
  }, [fields]);

  const onChangeCheckbox = id => {
    const index = fields.findIndex(el => el.serverId === id);

    if (index === -1) {
      append({ serverId: id });
      return;
    }

    remove(index);
  };

  const searchStr = useStore(consumablesSearchStr$);
  const onSearch = e => changeConsumablesSearchStr(e.target.value);

  return (
    <ModalWrapper
      title="Choose Consumable"
      onChangeCheckbox={onChangeCheckbox}
      searchStr={searchStr}
      onSearch={onSearch}
      fieldsForTable={fieldsForTable}
      newProducts={newProducts}
      cols="1fr 1fr 2fr 2fr 1fr 1fr"
      selected={selected}
    />
  );
};

export default ConsumablesModal;
