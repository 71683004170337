import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import {
  availableCategoriesLocalized$,
  categoriesTreeStoreLocalized$,
} from 'models/categories';
import { getPromoCodes } from 'models/promoCodes';
import { capitalLetter } from 'utils/strings';

import Error from 'components/atoms/Error';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
} from 'components/Product/shared';
import { PROMO_CODES } from 'api';

import * as ST from './styles';

import { Checkbox, inputStyle, MultiSelect, Radio, Row, T8y } from 'ui';

const PromoCodeForm = ({ promoCode = {}, setIsModalOpen }) => {
  const { form, promoCodes, formatString } = Lang();

  const allLocalizedCategories = useStore(categoriesTreeStoreLocalized$);
  const availableCategoriesLocalized = useStore(availableCategoriesLocalized$);

  const [loading, setLoading] = useState(false);

  const radio = useRadioState({
    state: promoCode.isOnetime ? 'oneTime' : 'multiple',
  });

  const applyToRadio = useRadioState({
    state: promoCode.type || DEFAULT_SELECTOR_STATE,
  });

  const userTypeRadioState = useRadioState({
    state: promoCode.userType || 'all',
  });

  const {
    watch,
    reset,
    getValues,
    register,
    errors,
    setValue,
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: {
      code: '',
      value: '',
      maxDiscount: '',
      isOnetime: false,
      startDate: '',
      endDate: '',
      type: DEFAULT_SELECTOR_STATE,
      userType: 'all',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  useEffect(() => {
    register(
      { name: 'startDate' },
      { required: form.notification.theFieldIsRequired },
    );
    register(
      { name: 'endDate' },
      { required: form.notification.theFieldIsRequired },
    );
  }, [form, register]);

  const watchAll = watch();

  const watchStartDate = watch(`startDate`);

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const handleDateChange = useCallback(
    (field, value) => {
      setValue(field, value);
      trigger(field);
    },
    [setValue, trigger],
  );

  const onChangeCategories = useCallback(
    selected => {
      setValue('categoriesIds', selected);
      trigger('categoriesIds');
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getParent = useCallback(
    el => {
      if (!el) {
        return;
      }

      let displayedNameInSelected = '';

      if (!el.parent) {
        return { ...el, displayedNameInSelected: el.name };
      }

      const parent = allLocalizedCategories.find(par => par.id === el.parent);

      if (!parent) {
        return { ...el, displayedNameInSelected: el.name };
      }

      displayedNameInSelected = `${parent.name} > ${el.name} `;

      const grandFather = parent.parent
        ? allLocalizedCategories.find(par => par.id === parent.parent)
        : null;

      if (grandFather) {
        displayedNameInSelected = `${grandFather.name} > ${parent.name} > ${el.name} `;
      }

      return {
        displayedNameInSelected: capitalLetter(displayedNameInSelected),
        ...el,
      };
    },
    [allLocalizedCategories],
  );

  const onSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        const ids = () => {
          if (data.type !== DEFAULT_SELECTOR_STATE) {
            if (data.type === 'product') {
              return data.productsSkues.split(',').filter(el => el);
            } else {
              return values.categoriesIds.map(el => el.id || el);
            }
          }
        };

        const formattedData = {
          code: data.code,
          value: data.value,
          maxDiscount: data.maxDiscount,
          isOnetime: data.isOnetime,
          startDate: data.startDate,
          endDate: data.endDate,
          type: data.type,
          userType: data.userType,
          ids: ids(),
        };

        if (promoCode.id) {
          await PROMO_CODES.updatePromoCode(promoCode.id, formattedData);
        } else {
          await PROMO_CODES.createPromoCode(formattedData);
        }

        getPromoCodes();
        toast.success(
          form.toastTitle[
            promoCode.id ? 'successfullyUpdated' : 'successfullyCreated'
          ],
        );
        setIsModalOpen(false);
      } catch (e) {
        toast.error(form.toastTitle.wentWrong);
      } finally {
        setLoading(false);
      }
    },
    [form.toastTitle, promoCode.id, setIsModalOpen, values.categoriesIds],
  );

  const deletePromo = useCallback(async () => {
    setLoading(true);
    try {
      await PROMO_CODES.deletePromoCode(promoCode.id);
      getPromoCodes();
      toast.success(form.toastTitle.successfullyDeleted);
      setIsModalOpen(false);
    } catch (e) {
      toast.error(form.toastTitle.wentWrong);
    } finally {
      setLoading(false);
    }
  }, [form, promoCode.id, setIsModalOpen]);

  const changeStatusPromo = useCallback(async () => {
    setLoading(true);
    try {
      await PROMO_CODES.updatePromoCode(promoCode.id, {
        ...promoCode,
        ids: promoCode.items.map(el =>
          promoCode.type === 'product' ? el.sku : el.id,
        ),
        isActive: !promoCode.isActive,
      });

      getPromoCodes();
      toast.success(
        form.toastTitle[
          promoCode.id ? 'successfullyUpdated' : 'successfullyCreated'
        ],
      );
      setIsModalOpen(false);
    } catch (e) {
      toast.error(form.toastTitle.wentWrong);
    } finally {
      setLoading(false);
    }
  }, [form.toastTitle, promoCode, setIsModalOpen]);

  useEffect(
    () => {
      if (promoCode.id) {
        reset({
          ...promoCode,
          productsSkues:
            promoCode.type === 'product'
              ? promoCode.items.map(el => el.sku)
              : undefined,
          categoriesIds:
            promoCode.type === 'category' && allLocalizedCategories.length
              ? promoCode.items.map(el =>
                  getParent(
                    allLocalizedCategories.find(
                      category => category.id === el.id,
                    ),
                  ),
                )
              : [],
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [allLocalizedCategories, promoCode],
  );

  useEffect(() => {
    setValue('isOnetime', radio.state === 'oneTime');
  }, [radio.state, setValue]);

  useEffect(() => {
    setValue('userType', userTypeRadioState.state);
  }, [userTypeRadioState.state, setValue]);

  useEffect(() => {
    setValue('type', applyToRadio.state);
  }, [applyToRadio.state, setValue]);

  return (
    <div>
      <FieldStyle2 legend={promoCodes.tableHeaders.promoTextCode} width="350px">
        <InputStyled
          name="code"
          ref={register({
            validate: value => {
              return !!value.trim() || form.notification.theFieldIsRequired;
            },
          })}
        />
      </FieldStyle2>
      <Error message={get(errors, ['code', 'message'])} />
      <Row align="center" noWrap>
        <ST.DiscountWrapper>
          <FieldStyle2
            legend={`${promoCodes.tableHeaders.discountValue}, %`}
            width="120px"
            marginRight="20px"
          >
            <InputStyled
              type="number"
              name="value"
              ref={register({
                required: form.notification.theFieldIsRequired,
                validate: {
                  rangeOfValues: value =>
                    (value > 0 && value < 100) ||
                    formatString(form.notification.valueShouldBeBetween, 1, 99),
                },
              })}
            />
          </FieldStyle2>
          <Error message={get(errors, ['value', 'message'])} />
        </ST.DiscountWrapper>
        <div>
          <FieldStyle2 legend={promoCodes.maxDiscount} width="120px">
            <InputStyled type="number" name="maxDiscount" ref={register} />
          </FieldStyle2>
          <Error message={get(errors, ['maxDiscount', 'message'])} />
        </div>
      </Row>
      <Row>
        <RadioGroup as={ST.PromoCodeRadioWrapper} {...radio}>
          <Radio
            id="radio-onTime-promo"
            {...radio}
            text={promoCodes.types.onTime}
            value="oneTime"
          />
          <Radio
            id="radio-multiple-promo"
            {...radio}
            text={promoCodes.types.multiple}
            value="multiple"
          />
        </RadioGroup>
      </Row>
      <ST.RowWithMarginTop>
        <RadioGroup as={ST.PromoCodeRadioWrapper} {...userTypeRadioState}>
          <Radio
            id="userUsers"
            {...userTypeRadioState}
            text={promoCodes.userTypes.all}
            value="all"
          />
          <Radio
            id="taOnly"
            {...userTypeRadioState}
            text={promoCodes.userTypes.taOnly}
            value="taOnly"
          />
          <Radio
            id="notTaOnly"
            {...userTypeRadioState}
            text={promoCodes.userTypes.notTaOnly}
            value="notTaOnly"
          />
        </RadioGroup>
      </ST.RowWithMarginTop>
      <ST.RowWithMarginTop noWrap>
        <div>
          <FieldStyle
            as={FieldStyle2}
            legend={promoCodes.tableHeaders.startDate}
            width="200px"
          >
            <ST.DatePickerStyled
              defaultValue={values.startDate || null}
              dateFormat="yyyy-MM-dd"
              handleDateChange={value => handleDateChange('startDate', value)}
              isPastDatesDisabled
              isCurrentDateNotDisabled
            />
          </FieldStyle>
          <Error message={get(errors, ['startDate', 'message'])} />
        </div>
        <div>
          <FieldStyle
            as={FieldStyle2}
            legend={promoCodes.tableHeaders.endDate}
            width="200px"
          >
            <ST.DatePickerStyled
              defaultValue={values.endDate || null}
              dateFormat="yyyy-MM-dd"
              handleDateChange={value => handleDateChange('endDate', value)}
              minDate={new Date(watchStartDate)}
            />
          </FieldStyle>
          <Error message={get(errors, ['endDate', 'message'])} />
        </div>
      </ST.RowWithMarginTop>
      <FieldStyle
        as={FieldStyle2}
        legend={promoCodes.tableHeaders.applyPromoCodeTo}
        width="100%"
        height="220px"
        marginBottom="40px"
      >
        <ST.PromoCodeRadioGroup {...radio}>
          <ST.ApplyToRadioWrapper>
            <ST.ApplyToRadio
              id="radio-allCatalogue"
              {...applyToRadio}
              text={promoCodes.allCatalogue}
              value="all"
            />
          </ST.ApplyToRadioWrapper>
          <ST.ApplyToRadioWrapper noWrap>
            <ST.ApplyToRadio
              id="radio-specificCategories"
              {...applyToRadio}
              text={promoCodes.specificCategories}
              value="category"
            />
            <ST.RadioInputWrapper show={values.type === 'category'}>
              <MultiSelect
                options={availableCategoriesLocalized}
                defaultSelected={values.categoriesIds}
                className={inputStyle}
                onChange={onChangeCategories}
                getParent={getParent}
                innerRef={() =>
                  register(
                    { name: 'categoriesIds' },
                    {
                      validate: value => {
                        if (values.type === 'category') {
                          return Array.isArray(value)
                            ? value.length > 0 ||
                                form.notification.theFieldIsRequired
                            : !!value;
                        }
                      },
                    },
                  )
                }
              />
              <Error message={errors?.categoriesIds?.message} />
            </ST.RadioInputWrapper>
          </ST.ApplyToRadioWrapper>
          <ST.ApplyToRadioWrapper noWrap>
            <ST.ApplyToRadio
              id="radio-specificProducts"
              {...applyToRadio}
              text={promoCodes.specificProducts}
              value="product"
            />
            <ST.RadioInputWrapper show={values.type === 'product'}>
              <InputStyled
                type="text"
                placeholder={promoCodes.enterProductSKU}
                name="productsSkues"
                ref={register({
                  validate: values.type === 'product' && {
                    required: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  },
                })}
              />
              <T8y fontSize="12px" color="gray">
                {promoCodes.useCommaToSeparate}
              </T8y>
              <Error message={errors?.productsSkues?.message} />
            </ST.RadioInputWrapper>
          </ST.ApplyToRadioWrapper>
        </ST.PromoCodeRadioGroup>
      </FieldStyle>
      <ST.ButtonsPanel justify="end">
        {promoCode.id && (
          <>
            <ST.PromoCodeButton
              text={form.buttons.delete}
              width="auto"
              padding="0 10px"
              backgroundColor="positive"
              color="gray"
              border="1px solid #D0D0D0"
              colorHovered="gray"
              onClick={deletePromo}
              disabled={loading}
            />
            <ST.PromoCodeButton
              text={promoCode.isActive ? promoCodes.pause : promoCodes.resume}
              width="auto"
              padding="0 10px"
              backgroundColor="positive"
              color="gray"
              border="1px solid #D0D0D0"
              colorHovered="gray"
              onClick={changeStatusPromo}
              disabled={loading || promoCode.status?.toLowerCase() === 'past'}
            />
          </>
        )}
        <ST.PromoCodeButton
          text={form.buttons.cancel}
          width="auto"
          padding="0 10px"
          backgroundColor="positive"
          color="gray"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          onClick={() => setIsModalOpen(false)}
        />
        <ST.PromoCodeButton
          text={form.buttons.saveChanges}
          width="auto"
          padding="0 10px"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        />
      </ST.ButtonsPanel>
    </div>
  );
};

export default PromoCodeForm;
