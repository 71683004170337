import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { trainingLocalized$ } from 'models/trainings';

import BigAddMore from 'components/atoms/BigAddMore';
import ShowSection from 'components/atoms/ShowSection';

import GeneralExpandableSection from '../GeneralExpandableSection';
import TrainingFormFaq from '../TrainingFormFaq';

const TrainingFaq = ({
  errors,
  setValue,
  values,
  control,
  register,
  clearErrors,
  watch,
}) => {
  const { trainings } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.faqs',
    keyName: 'formId',
  });

  const getFaqTitle = useCallback(
    index =>
      get(values, ['form', 'faqs', index, 'question']) ||
      trainings.faq.enterFaq,
    [trainings.faq.enterFaq, values],
  );

  const handleAdd = useCallback(() => append({}), [append]);

  const handleShow = useCallback(
    value => {
      setValue('form.faqShow', value);

      if (value && !fields.length) {
        handleAdd();
      }

      if (!value) {
        clearErrors('form.faqs');
      }
    },
    [clearErrors, fields.length, handleAdd, setValue],
  );

  const handleRemove = useCallback(
    index => {
      remove(index);
    },
    [remove],
  );

  const getIsError = useCallback(
    (field, index) => get(errors, ['form', 'faqs', index, field, 'message']),
    [errors],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.frequentlyAskedQuestions}
      listOfFields={['faqs']}
      errors={errors}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={trainingLocalized?.faqShow}
          handleChange={handleShow}
          fields={fields}
        />
      }
      withSubSections
      initialIsOpen
    >
      {fields.map((el, index) => (
        <GeneralExpandableSection
          index={0}
          key={el.formId}
          header={getFaqTitle(index)}
          listOfFields={['faqs']}
          errors={errors}
          subSection
          handleRemove={() => handleRemove(index)}
        >
          <TrainingFormFaq
            item={el}
            register={register}
            index={index}
            getIsError={getIsError}
            watch={watch}
          />
        </GeneralExpandableSection>
      ))}
      <BigAddMore
        fontSize="16px"
        onClick={handleAdd}
        text={trainings.addNewFAQ}
      />
    </GeneralExpandableSection>
  );
};

export default TrainingFaq;
