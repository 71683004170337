import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { getAreas } from 'models/areas';
import { getDeliveries, isDeliveriesLoading$ } from 'models/deliveries';
import { settings$ } from 'models/settings';
import { getWarehouses, isWarehousesNotLoaded$ } from 'models/warehouses';

import TransportationDeliveriesForm from '../TransportationDeliveriesForm';

import { Spinner } from 'ui';

const SettingsTransportation = () => {
  const settings = useStore(settings$);
  const isDeliveriesLoading = useStore(isDeliveriesLoading$);
  const isWarehousesNotLoaded = useStore(isWarehousesNotLoaded$);

  const [isWaitingForWarehouses, setIsWaitingForWarehouses] = useState(false);

  useEffect(() => {
    getAreas();
    getDeliveries();

    if (
      settings.defaultDeliveryMethodType === 'deliveryPerMile' ||
      settings.defaultDeliveryMethodType === 'deliveryByRadius'
    ) {
      setIsWaitingForWarehouses(true);
      getWarehouses();
    }
  }, [settings.defaultDeliveryMethodType]);

  return isDeliveriesLoading ||
    (isWaitingForWarehouses && isWarehousesNotLoaded) ? (
    <Spinner dark size={5} />
  ) : (
    <TransportationDeliveriesForm />
  );
};

export default SettingsTransportation;
