import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';

export const TableHeader = styled.div`
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin-top: 2rem;
  height: 4rem;
  background: #fff;
  padding: 0 1rem;
  line-height: 4rem;
`;

export const TableHeaderTitle = styled.div`
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  font-weight: 600;
  color: #b3b7bb;
  white-space: noWrap;

  &:not(:last-child) {
    padding-right: 5px;
  }
`;

export const TableRow = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 5rem;
  background: #fff;
  padding: 0 1rem;

  & > div:first-child {
    width: 100%;
  }
  ${p =>
    p.index % 2 === 0 &&
    `
    background: rgba(255, 255, 255, 0.5);
  `}
  ${p =>
    p.selected &&
    `
    background: #fefaed;
  `};
`;

export const TableRowTitle = styled.div`
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  color: ${colors.negative};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    padding-right: 5px;
  }
  & > a {
    color: ${colors.primary};
    margin-left: 0.5rem;
  }

  &,
  & > a,
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
  }

  & > span {
    width: 100%;
    ${p => p.isLongString && `white-space: nowrap;`}
  }
`;
