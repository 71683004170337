import styled from 'styled-components';

export const TrainingHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
`;

export const TrainingPageWrapper = styled.div`
  max-width: 1350px;
`;
