import React from 'react';
import clsx from 'clsx';

import style from './grid.module.scss';

export const Row = React.forwardRef(
  (
    {
      children,
      align = 'start',
      justify = 'start',
      smJustify = justify,
      xsJustify = justify,
      direction = 'row',
      smDirection = direction,
      xsDirection = smDirection,
      className,
      noWrap,
      inline,
      xsCenter,
      fullWidth,
      as: Component = 'div',
      ...rest
    },
    ref,
  ) => {
    return (
      <Component
        ref={ref}
        className={clsx(
          [
            style.row,
            fullWidth && style.fullWidth,
            style[`row-align-${align}`],
            style[`row-justify-${justify}`],
            style[`sm-row-justify-${justify}`],
            style[`xs-row-justify-${justify}`],
            style[`row-direction-${direction}`],
            style[`sm-row-direction-${smDirection}`],
            style[`xs-row-direction-${xsDirection}`],
            className,
          ],
          {
            [style['row-noWrap']]: noWrap,
            [style['row-inline']]: inline,
            [style['row-xsCenter']]: xsCenter,
          },
        )}
        {...rest}
      >
        {children}
      </Component>
    );
  },
);
