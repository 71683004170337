import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { MAX_GUIDES_COUNT } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  addDeletedMediaAndFiles,
  addGuide,
  guidesStore,
  removeGuide,
} from 'models/product';

import { FILES } from 'api';

import { Icon } from '../Icon';
import * as ST from './styles';

import { DropZoneBlock, Row } from 'ui';

export const UploadUserGuides = () => {
  const guides = useStore(guidesStore);

  const addGuides = useCallback(
    guidesFromServer => {
      const emptySlots = guides.filter(el => !el.value).length;
      if (emptySlots - guidesFromServer.length < 0) {
        toast(`Only ${MAX_GUIDES_COUNT} files can be added`);
        return;
      }

      guidesFromServer.forEach(el => {
        addGuide({ value: el.url, name: el.name });
      });
    },
    [guides],
  );

  const handleUploadEnd = useCallback(
    async acceptedFiles => {
      let firstEightPhotos = [...acceptedFiles];

      if (acceptedFiles.length > MAX_GUIDES_COUNT) {
        toast(`Only ${MAX_GUIDES_COUNT} guides can be added`);
        firstEightPhotos = acceptedFiles.slice(0, MAX_GUIDES_COUNT);
      }

      await Promise.all(
        firstEightPhotos.map(file => {
          return new Promise(resolve => {
            const data = new FormData();
            data.append('file', file);
            FILES.upload(data).then(dataFromServer => {
              if (dataFromServer && dataFromServer.data) {
                resolve({ ...dataFromServer.data, name: file.name });
              }
            });
          });
        }),
      ).then(values => {
        addGuides(values);
      });
    },
    [addGuides],
  );

  const handleRemoveItem = index => {
    addDeletedMediaAndFiles({ id: guides?.[index]?.id });
    removeGuide(index);
  };

  const {
    product: { titles },
  } = Lang();

  return (
    <>
      <ST.UploadUserGuidesTitle>{titles.downloads}</ST.UploadUserGuidesTitle>
      <ST.UploadUserGuidesBody>
        <Row>
          {guides &&
            guides.map((item, index) => {
              const isFile = !!guides[index]?.value;
              return (
                <ST.FileWrapper key={index} justify="center" align="center">
                  {isFile ? (
                    <>
                      <p>{item.name || titles.fileDownloaded}</p>
                      <Icon
                        type="delete"
                        handleClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleRemoveItem(index);
                        }}
                      />
                    </>
                  ) : (
                    <ST.UploadUserGuidesBlock>
                      <DropZoneBlock
                        handleUpload={handleUploadEnd}
                        accept="application/pdf"
                      />
                    </ST.UploadUserGuidesBlock>
                  )}
                </ST.FileWrapper>
              );
            })}
        </Row>
      </ST.UploadUserGuidesBody>
    </>
  );
};
