import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Button, T8y } from 'ui';

export const OrderCancelButton = styled(T8y)`
  &:hover {
    color: ${colors.darkPrimary}};
  }
`;

export const OrderCancelModalButton = styled(Button)`
  color: #666;
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  background: ${colors.positive}};

  &:first-child {
    width: 120px;
    margin-right: 8px;
  }
  &:last-child {
    width: 140px;
    border-color: ${colors.primary}};
  }
`;
