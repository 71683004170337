import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { trainingLocalized$ } from 'models/trainings';

import BigAddMore from 'components/atoms/BigAddMore';
import ShowSection from 'components/atoms/ShowSection';

import GeneralExpandableSection from '../GeneralExpandableSection';
import TrainingFormReviews from '../TrainingFormReviews';

const TrainingReviews = ({
  errors,
  setValue,
  register,
  values,
  control,
  setDeletedFiles,
  watch,
  clearErrors,
}) => {
  const { trainings } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.reviews',
    keyName: 'formId',
  });

  const getIsError = useCallback(
    (field, index) => get(errors, ['form', 'reviews', index, field, 'message']),
    [errors],
  );

  const getReviewTitle = useCallback(
    index => {
      const name = get(values, ['form', 'reviews', index, 'name']);
      if (name) {
        const position = get(values, ['form', 'reviews', index, 'position']);
        return `${name}  ${position ? `, ${position}` : ''}`;
      }
      return trainings.reviews.enterReviewFields;
    },
    [trainings.reviews.enterReviewFields, values],
  );

  const handleAdd = useCallback(() => append({}), [append]);

  const handleRemove = useCallback(
    index => {
      remove(index);
    },
    [remove],
  );

  const handleShow = useCallback(
    value => {
      setValue('form.reviewShow', value);

      if (value && !fields.length) {
        handleAdd();
      }

      if (!value) {
        clearErrors('form.reviews');
      }
    },
    [clearErrors, fields.length, handleAdd, setValue],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.courseReviews}
      listOfFields={['reviews']}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={trainingLocalized?.reviewShow}
          handleChange={handleShow}
          fields={fields}
        />
      }
      withSubSections
      initialIsOpen
    >
      {fields.map((el, index) => (
        <GeneralExpandableSection
          index={0}
          key={el.formId}
          header={getReviewTitle(index)}
          listOfFields={['reviews']}
          errors={errors}
          subSection
          handleRemove={() => handleRemove(index)}
        >
          <TrainingFormReviews
            item={el}
            values={values}
            register={register}
            index={index}
            setValue={setValue}
            getIsError={getIsError}
            setDeletedFiles={setDeletedFiles}
            control={control}
            watch={watch}
          />
        </GeneralExpandableSection>
      ))}
      <BigAddMore
        fontSize="16px"
        onClick={handleAdd}
        text={trainings.addNewReview}
      />
    </GeneralExpandableSection>
  );
};

export default TrainingReviews;
