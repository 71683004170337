import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { branding$ } from 'models/branding';
import * as MD from 'models/product';
import { settings$ } from 'models/settings';
import { userInfo } from 'models/user';

import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';
import { Icon } from 'components/Product/Icon';
import {
  FieldStyle,
  FieldStyle2,
  Full,
  generateURL,
  InputStyled,
  More,
} from 'components/Product/shared';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { Cell, Grid, Row } from 'ui';

export const ProductGeneralInfoBlock = ({
  register,
  setValue,
  trigger,
  errors,
  values,
  control,
  isNewProduct,
}) => {
  const { form } = Lang();

  const product = useStore(MD.productStore);
  const user = useStore(userInfo);
  const settings = useStore(settings$);
  const branding = useStore(branding$);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'feature',
    keyName: 'formId',
  });

  const addMore = useCallback(
    e => {
      e.preventDefault();
      append({ value: '' });
    },
    [append],
  );

  const handleRemove = useCallback(
    (e, index) => {
      e.preventDefault();
      remove(index);
    },
    [remove],
  );

  const actualUrl = useMemo(() => {
    return generateURL(values.slug);
  }, [values.slug]);

  const displayedSlug = useMemo(() => {
    return `${branding?.webSiteUrl}/${actualUrl || ''}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, actualUrl, branding]);

  useEffect(() => {
    setValue('visible_product_url', displayedSlug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, actualUrl, displayedSlug]);

  return (
    <ProductEditorBlockWrapper>
      <Grid
        gaps="1.5rem 3rem"
        cols="2fr 2fr 1fr"
        rows="auto"
        places="center start"
      >
        {settings.supportedLanguages.map((el, index) => (
          <Cell as={Full} area="/ span 3" key={index}>
            <Row direction="column" fullWidth justify="start">
              <FieldStyle
                as={FieldStyle2}
                legend={`${form.fields.title} ${el}`}
              >
                <InputStyled
                  name={`names.${el}`}
                  type="text"
                  ref={register(
                    el === settings.defaultLanguage && {
                      validate: value =>
                        !!value?.trim() || form.notification.theFieldIsRequired,
                    },
                  )}
                  onChange={e => {
                    if (el === settings.defaultLanguage) {
                      if (e.target.value) {
                        setValue('slug', generateURL(e.target.value));
                      } else {
                        setValue('slug', null);
                        trigger('slug');
                      }
                    }
                  }}
                />
              </FieldStyle>
              <Error message={get(errors, ['names', el, 'message'])} />
            </Row>
          </Cell>
        ))}
        <Cell as={Full} area="/ span 3">
          <Row direction="column" fullWidth justify="start">
            <FieldStyle as={FieldStyle2} legend={form.fields.sku}>
              <InputStyled
                name="sku"
                ref={register({
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
                type="text"
              />
            </FieldStyle>
            <Error message={get(errors, ['sku', 'message'])} />
          </Row>
        </Cell>
        {settings.supportedLanguages.map((el, index) => (
          <Cell as={Full} area="/ span 3" key={index}>
            <Row direction="column" fullWidth justify="start">
              <FieldStyle
                as={ST.EditorWrapper}
                legend={`${form.fields.description} ${el}`}
                noBorder
              >
                <Controller
                  control={control}
                  rules={{
                    required:
                      el === settings.defaultLanguage &&
                      form.notification.theFieldIsRequired,
                  }}
                  name={`descriptions.${el}`}
                  render={({ onChange, ref }) => (
                    <ReachEditor
                      initialValue={product?.descriptions?.[el]}
                      handleChange={text => {
                        onChange(text.text);
                      }}
                      innerRef={editorRef => {
                        ref.current = editorRef;
                      }}
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'list',
                          'textAlign',
                          'link',
                          'emoji',
                          'remove',
                          'history',
                        ],
                        list: {
                          options: ['unordered'],
                        },
                        inline: {
                          options: ['bold', 'italic'],
                        },
                      }}
                    />
                  )}
                />
              </FieldStyle>
              <Error message={get(errors, ['descriptions', el, 'message'])} />
            </Row>
          </Cell>
        ))}
        <Cell as={Full} area="/ span 3">
          <Row direction="column" fullWidth justify="start">
            <FieldStyle as={FieldStyle2} legend={form.fields.productUrl}>
              <InputStyled
                type="text"
                name="slug"
                ref={register({
                  validate: value =>
                    !!value?.trim() || form.notification.theFieldIsRequired,
                })}
              />
            </FieldStyle>
            {isNewProduct ? (
              <ST.SlugText>{displayedSlug}</ST.SlugText>
            ) : (
              <ST.SlugLink href={displayedSlug} target="_blank">
                {displayedSlug}
              </ST.SlugLink>
            )}
            <Error message={get(errors, ['slug', 'message'])} />
          </Row>
        </Cell>
        {fields.map((feature, index) => (
          <Fragment key={feature.formId}>
            <Cell as={Full}>
              <FieldStyle
                as={FieldStyle2}
                legend={`${form.fields.feature} ${index + 1}`}
              >
                <InputStyled
                  name={`feature[${index}].value`}
                  ref={register()}
                  type="text"
                  paddingRight={fields.length > 4 ? '4rem' : '1.5rem'}
                  defaultValue={feature.value}
                />
                {fields.length > 4 && (
                  <ST.FeatureDeleteIconWrapper>
                    <Icon
                      type="delete"
                      handleClick={e => handleRemove(e, index)}
                    />
                  </ST.FeatureDeleteIconWrapper>
                )}
              </FieldStyle>
            </Cell>
            {index === 1 && (
              <Cell place="center">
                <More marginTop={true} onClick={addMore} />
              </Cell>
            )}
            {index % 2 === 1 && index > 1 && <Cell />}
          </Fragment>
        ))}
      </Grid>
    </ProductEditorBlockWrapper>
  );
};
