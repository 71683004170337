import React, { useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import {
  availableCategoriesLocalized$,
  categoriesTreeStoreLocalized$,
} from 'models/categories';

import Error from 'components/atoms/Error';
import {
  FieldStyle,
  FieldStyle2,
  TextareaStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { inputStyle, Select, T8y } from 'ui';

const DESCRIPTION_MAX_LENGTH = 500;

const JobTypeCategory = ({
  index,
  watch,
  values,
  setValue,
  register,
  fields,
  el,
  errors,
  remove,
  unregister,
}) => {
  const { formatString, trainings, form } = Lang();

  const allLocalizedCategories = useStore(categoriesTreeStoreLocalized$);
  const availableCategoriesLocalized = useStore(availableCategoriesLocalized$);

  const optionsForDisplaying = useMemo(
    () => [
      { name: form.defaultText.selectCategory, id: null },
      ...availableCategoriesLocalized,
    ],
    [availableCategoriesLocalized, form.defaultText.selectCategory],
  );

  const titleRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    `categories[${index}].description`,
    DESCRIPTION_MAX_LENGTH,
  );

  const handleSelectCategory = useCallback(
    id => {
      const initialEl = allLocalizedCategories.find(el => el.id === id);
      setValue(`categories.[${index}]`, {
        // description: values.categories[index]?.description || '',
        ...el,
        ...(initialEl && { ...initialEl }),
      });
    },
    [allLocalizedCategories, el, index, setValue],
  );

  return (
    <ST.CategoryWrapper key={el.id}>
      <ST.CategoryHeader>
        <T8y as="span" fontSize="16px" bold>
          {el.name || form.defaultText.selectCategory}
        </T8y>
        <ST.Delete
          as="span"
          color="gray"
          fontSize="14px"
          onClick={() => {
            unregister(`categories[${index}].description`);
            remove(index);
          }}
        >
          {form.buttons.delete}
        </ST.Delete>
      </ST.CategoryHeader>
      <FieldStyle
        as={FieldStyle2}
        legend={`${form.fields.category} ${index + 1}`}
        width="200px"
        withBorder={true}
        marginRight={index !== fields.length - 1 && '20px'}
        marginBottom="20px"
      >
        <Select
          options={optionsForDisplaying}
          selected={values.categories[index]?.name}
          defaultText={form.defaultText.selectCategory}
          aLabel={form.fields.category}
          onClickOption={handleSelectCategory}
          className={inputStyle}
          maxHeight="300px"
        />
      </FieldStyle>
      <FieldStyle2 legend={form.fields.description}>
        <TextareaStyled
          name={`categories[${index}].description`}
          height="130px"
          ref={register({
            maxLength: DESCRIPTION_MAX_LENGTH,
            validate: value =>
              !!value?.trim() || form.notification.theFieldIsRequired,
          })}
          defaultValue={values.categories?.[index]?.description}
          maxLength={DESCRIPTION_MAX_LENGTH}
        />
      </FieldStyle2>
      <Error
        message={errors && errors.categories?.[index]?.description?.message}
      />
      <T8y color="gray" fontSize="12px">
        {formatString(trainings.remainingCharacters, titleRemainingSymbols)}
      </T8y>
    </ST.CategoryWrapper>
  );
};

export default JobTypeCategory;
