import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCheckboxState } from 'reakit/Checkbox';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import {
  categoriesGate,
  categoriesTreeStore,
  deleteCategories,
  filterCategoryStore,
  getCategoriesTree as serverGetCategoriesTree,
  setFilterCategory,
} from 'models/categories';
import { settings$ } from 'models/settings';

import TooltipWrapper from 'components/atoms/Tooltip';
import { CustomSelect } from 'components/molecules/CategoriesCustomSelect';
import ConfirmCategoriesDeletionModal from 'components/molecules/ConfirmCategoriesDeletionModal';
import { Categories } from 'components/organisms/Categories';
import styled from 'styled-components';

import { colors, colorsHovered, fonts } from 'styleGuide';
import { Cell, Grid } from 'ui';
import { Icon } from 'ui/Icon';

import { ReactComponent as Lens } from 'assets/icons/lens.svg';

const ManageHeader = styled.div`
  font-family: ${fonts.nunitoSans};
  font-size: 2.4rem;
  font-weight: 900;
  color: ${colors.darkBlue};
  padding-bottom: 2rem;
  border-bottom: 1px solid ${colors.lightGray};

  span {
    display: block;
    margin-top: 0.5rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

const GridStyled = styled.div`
  padding-top: 2.3rem;
`;

const SearchWrapperStyled = styled.div`
  position: relative;
  height: 4rem;
  width: 100%;
  max-width: 48rem;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 4rem;
  display: block;
  outline: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.3rem;
  padding: 0 1rem 0 4rem;
  line-height: 4rem;
  margin: 0;
  font-family: ${fonts.nunitoSans};
  font-size: 1.4rem;
  color: ${colors.darkBlue};

  &::placeholder {
    color: #777777;
  }
`;

const SearchIcon = styled(Lens)`
  position: absolute;
  top: 1.2rem;
  left: 1.1rem;
  z-index: 1;
  height: 1.5rem;
`;

const ButtonStyled = styled(Link)`
  display: block;
  margin: 0;
  padding: 0;
  width: 21rem;
  height: 4.6rem;
  line-height: 4.6rem;
  background: ${colors.primary};
  transition: background-color 0.5s ease;
  border-radius: 0.5rem;
  font-family: ${fonts.nunitoSans};
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  text-align: center;

  &:hover {
    background-color: ${colorsHovered.primaryHovered};
    cursor: pointer;
  }

  ${p =>
    p.disabled &&
    `
    background-color: ${colors.gray};
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

const ActionsStyled = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  height: 5.5rem;
  border: 1px dashed #d0d0d0;
  border-radius: 0.5rem;
  margin: 2.3rem 0 1rem;
  font-family: ${fonts.nunitoSans};
  font-size: 1.6rem;
  color: #999999;
  padding: 0 1.5rem;
  align-items: center;
`;

const FilterSection = styled.div`
  font-family: ${fonts.nunitoSans};
  font-weight: 600;
  font-size: 1.6rem;
  color: #666666;
  display: flex;
  align-items: center;
`;

export const Manage = () => {
  useGate(categoriesGate);

  const {
    categories,
    form: { buttons, toastTitle },
  } = Lang();

  const checkbox = useCheckboxState({ state: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);
  const allCategories = useStore(categoriesTreeStore);
  const searchStr = useStore(filterCategoryStore);

  const onChangeFilterValue = useCallback(e => {
    setFilterCategory(e.target.value);
  }, []);

  const { hiddenCategories, visibleCategories } = useMemo(
    () =>
      allCategories.reduce(
        (acc, el) => {
          if (el.hidden) {
            acc.hiddenCategories.push(el);
          } else {
            acc.visibleCategories.push(el);
          }
          return acc;
        },
        { hiddenCategories: [], visibleCategories: [] },
      ),
    [allCategories],
  );

  const filteredByVisibilityCategories = useMemo(() => {
    if (selectedFilter === 'visible') {
      return visibleCategories;
    }
    if (selectedFilter === 'hidden') {
      return hiddenCategories;
    }

    return allCategories;
  }, [allCategories, hiddenCategories, selectedFilter, visibleCategories]);

  const filteredCategories = useMemo(() => {
    if (!searchStr) {
      return filteredByVisibilityCategories;
    }

    return filteredByVisibilityCategories?.filter(category =>
      Object.values(category.names).some(el =>
        el.toLowerCase().includes(searchStr.toLowerCase()),
      ),
    );
  }, [filteredByVisibilityCategories, searchStr]);

  const selected = useMemo(() => {
    return checkbox.state.filter(item =>
      searchStr !== ''
        ? filteredCategories.map(category => category.id).includes(item)
        : true,
    );
  }, [checkbox.state, searchStr, filteredCategories]);

  const hasActions = selected.length > 0;

  const getSelectedCategoriesNames = useCallback(
    () =>
      selected
        .map(el => {
          const categoryNames = filteredCategories.find(item => item.id === el)
            .names;
          return (
            categoryNames[currentLangState] ||
            categoryNames[settings?.defaultLanguage]
          );
        })
        .join(', '),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, currentLangState, settings],
  );

  const onSubmitDelete = async () => {
    if (selected && selected.length) {
      setIsLoading(true);
      await Promise.all(
        selected.map(el => {
          return new Promise(resolve => {
            deleteCategories(el);
            resolve();
          });
        }),
      ).then(() => {
        serverGetCategoriesTree();
        toast.success(toastTitle.successfullyDeleted);
        setIsLoading(false);
        checkbox.setState([]);
      });
    }
  };

  return (
    <Fragment>
      <ManageHeader>
        {categories.titles.manageCategories}
        <span>
          {filteredCategories.length} {categories.titles.categoriesFound}
        </span>
      </ManageHeader>
      <Grid gaps="0" cols="1fr 1fr" places="center" as={GridStyled}>
        <Cell place="center start" as={SearchWrapperStyled}>
          <Icon as={SearchIcon} />
          <SearchInput
            type="text"
            placeholder={categories.titles.startTyping}
            value={searchStr}
            onChange={onChangeFilterValue}
          />
        </Cell>
        <TooltipWrapper
          TooltipReferenceComponent={() => (
            <Cell
              place="center end"
              as={ButtonStyled}
              to={PATHS.CREATE_CATEGORY()}
              disabled={!settings.addNewCategoryEnabled}
            >
              {buttons.addCategory}
            </Cell>
          )}
          tooltipText={categories.contactToCreateCategory}
          disabled={settings.addNewCategoryEnabled}
        />
      </Grid>
      <ActionsStyled>
        {hasActions && (
          <ConfirmCategoriesDeletionModal
            categoriesCount={selected.length}
            onSubmitDelete={onSubmitDelete}
            selectedCategoriesNames={getSelectedCategoriesNames()}
          />
        )}
        <FilterSection>
          {categories.titles.show}:
          <CustomSelect
            defaultText={categories.titles.selectFilter}
            selected={selectedFilter}
            onChange={setSelectedFilter}
          >
            {[
              { title: categories.titles.allCategories, value: null },
              { title: categories.titles.visibleCategories, value: 'visible' },
              { title: categories.titles.hiddenCategories, value: 'hidden' },
            ]}
          </CustomSelect>
        </FilterSection>
      </ActionsStyled>
      <Categories
        isLoading={isLoading}
        isFiltered={searchStr || selectedFilter}
        filteredCategories={filteredCategories}
        checkbox={checkbox}
      />
    </Fragment>
  );
};
