export const settings = {
  titles: {
    transportation: 'Transportation',
    costCalculationMethod: 'Cost Calculation Method',
    routeAvailability: 'Transportation Route Availability',
    transportationCostVat: 'Transportation Cost',
    additionalCharges: 'Additional charges',
    regionalSettings: 'Regional settings',
    calendarSetup: 'Calendar Setup',
    depots: 'Depots',
  },

  priceByArea: 'Price by Area',
  priceByKm: 'Price by Kilometer',
  area: 'Area',
  priceByPostalCode: 'Price by Postal Code',
  priceByRadius: 'Price by Radius',
  postcode: 'Postcode',
  depotToLocation: 'To location',
  locationToDepot: 'To depot',
  bothWaysDelivery: 'Both Ways',
  depot: 'Depot',
  outOfRangeNotAvailable: 'Out of range delivery is not available',
  outOfRangeNotAvailableMsg:
    'If this checkbox is enabled, the message “Sorry, we don’t support delivery to your address...” will be shown during checkout process to the users whose address is out of range.',
  copyToOtherDepots: 'Copy this configuration to other depots',
  rangeInKm: 'Distance Range in {0}',
  depotToLocationByRadius: 'Depot to Location',
  locationToDepotByRadius: 'Location to Depot',
  bothWaysDeliveryByRadius: 'Both Ways Delivery',
  thisRangeNotAvailable: 'In this range delivery is not available',
  addNewRange: 'Add New Range',
  showAllDepots: 'Expand and show all {0} depots',
  showFirstDepotOnly: 'Collapse and show the first depot only',
  useBrToSeparate: 'Use "<br>" to separate several items',

  modals: {
    deleteConfirmationTitle: 'Delete Confirmation',
    deleteConfirmationText:
      'Are you sure you want to delete {0} from the list of available delivery types?',
    deleteMessageTitle: 'Delete Message',
    deleteMessageText:
      'Delivery type {0} is used for some products in the catalogue. If you still want to delete it, please assign another delivery type to such products first.',
    copyConfigurationTitle: 'Copy Configuration',
    copyConfigurationText:
      'Are you sure you want to copy this configuration? This action will overwrite range/cost values in all other depots and you won’t be able to undo it.',
    deleteDistanceRangeConfirmationTitle: 'Delete Distance Range',
    deleteDistanceRangeConfirmationText:
      'Are you sure you want to delete this range? You won’t be able to undo this action.',
    depotDeleteConfirmationText:
      'Are you sure you want to delete {0} from the list of depots?',
    depotDeleteMessageText:
      'Depot {0} is used for some products in the catalogue. If you still want to delete it, please mark these products as “Out of Stock“ first.',
  },
};
