import React, { useEffect } from 'react';
import { CellMeasurerCache } from 'react-virtualized';
import { Lang } from 'lang';

import RenderProduct from '../../atoms/RenderProduct';
import { FieldStyle, FieldStyle3 } from '../../Product/shared';
import * as ST from './styles';

import { Grid, inputStyle, Select } from '../../../ui';

import { ReactComponent as Lens } from '../../../assets/icons/lens.svg';

const TableModalContent = ({
  searchStr,
  onSearch,
  categoriesTree,
  selectedCategory,
  fieldsForTable,
  products,
  onChangeCheckbox,
  selected,
  fields,
  cols,
  handleSelectCategory,
  isSelect,
  isDisabled,
}) => {
  const [cache, setCache] = React.useState(null);

  useEffect(() => {
    if (!cache) {
      setCache(
        new CellMeasurerCache({
          fixedWidth: true,
          minHeight: 40,
        }),
      );
    }
  }, [cache]);

  const {
    header: { titles },
  } = Lang();

  return (
    <>
      <Grid
        places="center center"
        cols={isSelect ? '1fr 2.5fr' : '1fr'}
        as={ST.FilterGrid}
      >
        <ST.FilterCell place="center start">
          {isSelect && (
            <FieldStyle as={FieldStyle3} withBorder={true}>
              <Select
                options={categoriesTree}
                selected={selectedCategory ? selectedCategory.name : null}
                defaultText={fields.category}
                aLabel={fields.category}
                onClickOption={value => {
                  const selected = categoriesTree.find(el => el.id === value);
                  handleSelectCategory(selected);
                }}
                className={inputStyle}
                maxHeight="30rem"
              />
            </FieldStyle>
          )}
        </ST.FilterCell>
        <ST.FilterCell place="center start">
          <ST.SearchInputWrapper>
            <ST.SearchInput
              type="text"
              placeholder={titles.search}
              value={searchStr}
              onChange={onSearch}
            />
            <ST.SearchIcon as={Lens} />
          </ST.SearchInputWrapper>
        </ST.FilterCell>
      </Grid>
      <Grid
        places="center start"
        cols={cols}
        gaps="1rem 1rem"
        as={ST.TableHeader}
      >
        <ST.CheckboxCell />
        {fieldsForTable &&
          fieldsForTable.map((field, index) => {
            return <ST.TableHeaderCell key={index}>{field}</ST.TableHeaderCell>;
          })}
      </Grid>
      {isDisabled && <ST.DisabledBlock />}
      {cache && (
        <ST.AutoSizerStyled>
          {({ width, height }) => (
            <ST.VirtualizedListStyled
              width={width}
              height={height - 120}
              overscanRowCount={5}
              rowCount={products.length}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={props => (
                <RenderProduct
                  product={products[props.index]}
                  selected={selected}
                  onChangeCheckbox={onChangeCheckbox}
                  cache={cache}
                  cols={cols}
                  isDisabled={isDisabled}
                  {...props}
                />
              )}
              scrollToAlignment="start"
            />
          )}
        </ST.AutoSizerStyled>
      )}
    </>
  );
};

export default TableModalContent;
