import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Lang } from 'lang';
import { isUrl } from 'utils/patterns';

import Error from '../../atoms/Error';
import { InputStyled } from '../../Product/shared';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { Checkbox } from 'ui';

const VideoBlock = ({ register, setValue, values, errors }) => {
  const {
    form: { notification, checkbox, descriptions },
    product: { titles },
  } = Lang();

  useEffect(() => {
    register('video_viewable');
  }, [register]);

  const checkIfLinkIsCorrect = link => {
    if (!values || !values.video_viewable) {
      return true;
    }

    return isUrl(link) && (link.includes('youtube') || link.includes('vimeo'));
  };

  return (
    <ProductEditorBlockWrapper blockTitle={titles.videos}>
      <ST.CheckboxWrapper>
        <Checkbox
          label={checkbox.showProductVideo}
          onChange={e => {
            setValue('video_viewable', e.target.checked, {
              shouldDirty: true,
            });
          }}
          checked={values && values.video_viewable}
        />
      </ST.CheckboxWrapper>
      <InputStyled
        name="video"
        ref={register({
          required:
            values && values.video_viewable && notification.theFieldIsRequired,
          validate: {
            url: value =>
              checkIfLinkIsCorrect(value) || notification.valueShouldBeValid,
          },
        })}
        defaultValue={values.video}
      />
      <Error message={errors && errors.video && errors.video.message} />
      <ST.Promt>{descriptions.enterVideoUrl}</ST.Promt>
    </ProductEditorBlockWrapper>
  );
};

// eslint-disable-next-line import/no-default-export
export default withRouter(VideoBlock);
