import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { tradeAccount$ } from 'models/tradeAccounts';

import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';

import TradeAccountOrderSmall from './TradeAccountOrderSmall';

const TradeAccountOrdersSmall = () => {
  const { orders } = Lang();

  const tradeAccount = useStore(tradeAccount$);

  return (
    <ProductEditorBlockWrapper blockTitle={orders.title} isNoBackground>
      {tradeAccount.orders.map(order => (
        <TradeAccountOrderSmall order={order} key={order.orderHash} />
      ))}
    </ProductEditorBlockWrapper>
  );
};

export default TradeAccountOrdersSmall;
