import styled from 'styled-components';

import { colors } from 'styleGuide';

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  margin-bottom: 2.2rem;
  color: ${colors.darkBlue};
`;

export const SectionWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 0.2rem;
  padding: 4rem 3rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${colors.primary};
  margin-bottom: 4.7rem;

  ${p =>
    p.flexDirection &&
    `
    flex-direction: ${p.flexDirection};
  `}
`;
