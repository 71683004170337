import React, { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import LocationDateItem from 'components/atoms/LocationDateItem';
import TrainingFormLine from 'components/atoms/TrainingFormLine';

import * as ST from './styles';

const TrainingAvailableLocationDates = ({
  scheduleIndex,
  register,
  values,
  setValue,
  control,
  EMPTY_DATE,
  errors,
  trigger,
  watch,
  unregister,
}) => {
  const { trainings } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `form.schedules[${scheduleIndex}].variations`,
    keyName: 'formId',
  });

  const actualFieldInfo = useMemo(() => {
    return values?.form?.schedules[scheduleIndex];
  }, [scheduleIndex, values]);

  const handleAddDate = useCallback(() => {
    append({
      ...EMPTY_DATE,
    });
  }, [EMPTY_DATE, append]);

  return (
    <>
      <TrainingFormLine>
        <ST.LocationCoursesTitle>
          {trainings.datesAndPrice}
        </ST.LocationCoursesTitle>
      </TrainingFormLine>
      {fields.map((el, index) => (
        <LocationDateItem
          scheduleIndex={scheduleIndex}
          register={register}
          dateIndex={index}
          setValue={setValue}
          actualFieldInfo={actualFieldInfo}
          remove={remove}
          key={el.formId}
          trigger={trigger}
          errors={errors}
          watch={watch}
          el={el}
          unregister={unregister}
        />
      ))}
      <AddMore text={trainings.addNewDate} onClick={handleAddDate} />
    </>
  );
};

export default TrainingAvailableLocationDates;
