export const marketing = {
  titles: {
    content: 'Contenido',
    homePage: 'Hero slider página de inicio',
    slideIndex: 'Número de diapositiva',
    deleteSlide: 'Borrar diapositiva',
    CTA: 'Botón CTA',
    buttonText: 'Botón texto',
    buttonURL: 'Botón URL',
    learnMore: 'Saber más',
    declareInterest: 'Declarar interés',
    exploreCategory: 'Explorar categoría',
    addNewSlide: 'Agregar nueva diapositiva',
    desktopVersion: 'Imagen para escritorio',
    mobileVersion: 'Imagen para móvil',
    heroImages: 'Hero Images',
    popularProducts: 'Productos populares',
    popularProductsNote:
      'Los Productos Populares solo serán visibles si hay más de 3 productos añadidos',
    targetBlank: 'Abrir enlace en una nueva pestaña',
    enquiryForm: 'Formulario de consulta',
    popularCategories: 'Categorías populares',
    promoCodes: 'Códigos Promocionales',
    buttonTitle: 'Título del botón',
    h1Text: 'Texto H1',
    pText: 'Texto párrafo (sólo versión de escritorio)',
    jobTypes: 'Tipo de Trabajo',
    taFaqs: 'TA FAQs',
  },

  enquiry: {
    enquiryFormVariation: 'Tipo de formulario',
    defaultTitle: 'Formulario por defecto',
    defaultSubTitle:
      'El formulario por defecto contiene los campos: nombre completo, número de teléfono, nombre de empresa, correo electrónico, mensaje',
    customizedTitle: 'Formulario personalizable',
    customizedSubTitle:
      'Selecciona esta opción si quisieras agregar preguntas adicionales',
    addNewAnswer: 'Agrega una nueva respuesta',
  },

  jobTypes: {
    jobType: 'Tipo de trabajo',
    jobTypeTitle: 'Titulo del trabajo',
    relatedProducts: 'Productos relacionados',
    relatedCategories: 'Categorias relacionadas',
    addNewJobType: 'Añadir un nuevo tipo de trabajo',
    viewJobType: 'Ver trabajos',
    availableItems: '{0} Trabajos disponibles',
    jobURL: 'URL de la página',
    jobHTMLTitle: 'HMTL titulo',
    hideJob: 'Esconder página de trabajo',
    marketingText: 'Texto Marketing',
    addProduct: 'Añadir producto relacionado',
    addCategory: 'Añadir categoria relacionada',
    backToJobs: 'Volver a la lista de trabajos',
    noJobs: 'Sin trabajos',
  },
};
