import styled from 'styled-components';

import { T8y } from 'ui';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const ErrorWrapper = styled.div`
  &:empty:before {
    content: '\\200b';
  }
`;

export const RangeInputsWrapper = styled.div`
  flex-basis: 25%;
  display: flex;
  margin-right: 50px;
`;

export const SmallInputWrapper = styled.div`
  position: relative;
  flex-basis: 15%;
`;

export const PriceInputWrapper = styled(SmallInputWrapper)`
  flex-basis: 30%;
`;

export const DividerWrapper = styled.div`
  margin: 0 20px;
`;

export const IconWrapper = styled.div`
  flex-basis: 10%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
`;

export const EuroIcon = styled.span`
  position: absolute;
  top: 1.1rem;
  left: 2rem;
`;

export const DivideSymbol = styled(T8y)`
  height: 100%;
  padding-top: 10px;
`;
