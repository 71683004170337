export const header = {
  titles: {
    search: 'Cerca',
    logOut: 'Esci',
    categories: 'Categorie',
    orders: 'Ordini',
    userProfile: 'Profilo utente',
    products: 'Prodotti',
    marketing: 'Marketing',
    notification: 'Notifica',
    productsFound: 'Prodotti Trovati',
    sortBy: 'Ordina per',
    price: 'Prezzo',
    filter: 'Filtri',
    available: 'Disponibile',
    allArchived: 'Prodotti archiviati',
    addNew: 'Aggiungi nuovo prodotto',
    confirmDeleting: "Conferma l'eliminazione",
    export: 'Esporta in CSV',
    productsOnPage: 'Prodotti sulla pagina',
  },

  table: {
    productCode: 'Codice prodotto',
    photo: 'Immagine',
    Name: 'Nome',
    price: 'Prezzo',
    maxQuantity: 'Quantità massima',
    save: 'Salva',
    cancel: 'Cancella',
    preview: 'Anteprima',
    title: 'Nome',
  },

  menu: {
    categories: 'Categorie',
    orders: 'Ordini',
    products: 'Prodotti',
    marketing: 'Marketing',
    analytics: 'Analytics',
    users: 'Utenti',
    logOut: 'Esci',
    settings: 'Impostazioni',
    trainings: 'Formazione',
    offers: 'Offerte',
    leads: 'Leads Web',
    tradeAccounts: 'Trade Accounts',
  },
};
