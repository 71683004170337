import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { changeSearchStr, searchStr$ } from 'pages/model';

import { SearchStyled } from './styles';

import { Cell, Icon, T8y } from 'ui';

import { ReactComponent as Lens } from 'assets/icons/lens.svg';

export const Search = () => {
  const { header } = Lang();

  const searchStr = useStore(searchStr$);

  return (
    <Cell place="center end">
      <SearchStyled align="center">
        <Icon as={Lens} />
        <T8y
          color="negative"
          as="input"
          placeholder={header.titles.search}
          name="search"
          value={searchStr}
          onChange={e => changeSearchStr(e.target.value)}
        />
      </SearchStyled>
    </Cell>
  );
};
