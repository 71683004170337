import React, { useState } from 'react';

import * as ST from './styles';

import { T8y } from 'ui';

const PriceWithAdditionalText = ({ children, text = '' }) => {
  const [isTextOpen, setIsTextOpen] = useState(false);

  return (
    <div>
      <ST.FieldWrapper
        justify="stretch"
        align="center"
        onClick={() => setIsTextOpen(!isTextOpen)}
      >
        {children}
      </ST.FieldWrapper>
      {isTextOpen && (
        <T8y fontSize="13px">
          {text.split('\n').map((el, index) => (
            <p key={index}>{el}</p>
          ))}
        </T8y>
      )}
    </div>
  );
};

export default PriceWithAdditionalText;
