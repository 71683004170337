import React from 'react';
import { Lang } from 'lang';

import { doNothing, IconStyled } from '../../Product/shared';
import * as ST from './styles';

import { T8y } from 'ui';

import { ReactComponent as Plus } from 'assets/images/icon/plus.svg';

const AddMore = ({ marginTop, onClick, text, disabled, fontSize, ...rest }) => {
  const {
    form: { buttons },
  } = Lang();

  return (
    <T8y
      asLink
      display="inline-block"
      color="primary"
      onClick={onClick && !disabled ? onClick : doNothing}
      cursor="pointer"
      disabled={disabled}
      lineHeight="normal"
      fontSize={fontSize}
      {...rest}
    >
      <ST.LinkContentWrapper
        align="center"
        as={marginTop ? ST.MorePosition : 'div'}
        disabled={disabled}
      >
        <IconStyled as={Plus} />
        {text || buttons.addMore}
      </ST.LinkContentWrapper>
    </T8y>
  );
};

export default AddMore;
