import { InputStyled } from 'components/Product/shared';
import styled from 'styled-components';

export const CourseTitleLineWrapper = styled.div`
  display: flex;
`;

export const CourseTitleWrapper = styled.div`
  margin-right: 42px;
`;

export const CourseTitleInput = styled(InputStyled)`
  &:read-only {
    color: #b3b7bb;
    background-color: #fbfbfb;
    cursor: not-allowed;
    border: 1px solid #e4e8ed;
  }
`;

export const MainImageWrapper = styled.div`
  width: 940px;
  height: 390px;
  display: flex;
  flex-direction: column;
`;

export const ThumbImageWrapper = styled.div`
  width: 300px;
  height: 225px;
`;

export const ShortDescriptionTitle = styled.span`
  display: inline-block;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  color: #b3b7bb;
  line-height: 14px;
`;
