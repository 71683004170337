import React from 'react';
import { usePagination } from 'hooks';

import Pagination from '../Pagination';
import * as ST from './styles';

export const TablePaging = ({
  handlePageChange,
  totalPages,
  currPage,
  isScrollToTop = true,
}) => {
  const {
    handleClickPreviousArrow,
    handleClickNextArrow,
    handleClickOnPage,
    visiblePages,
  } = usePagination({
    totalPages,
    currPage,
    handlePageChange,
    isScrollToTop,
  });

  return (
    <ST.TablePaginationWrapper>
      <Pagination
        handleClickPreviousArrow={handleClickPreviousArrow}
        handleClickNextArrow={handleClickNextArrow}
        handleClickOnPage={handleClickOnPage}
        visiblePages={visiblePages}
        range={5}
        currPage={currPage}
        totalPages={totalPages}
      />
    </ST.TablePaginationWrapper>
  );
};
