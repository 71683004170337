import { PATHS } from 'AppPaths';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { combine, createEvent, createStore, restore } from 'effector';

import { history } from 'libs/history';

export const changeSortby = createEvent();
export const changeSearchStr = createEvent();
export const changeProductsTablePage = createEvent();

export const sortbyData = [
  { slug: 'popular', name: 'Popularity' },
  { slug: 'price-low', name: 'Price (low to high)' },
  { slug: 'price-high', name: 'Price (high to low)' },
  { slug: 'date', name: 'New arrivals' },
];

export const selectedSortby$ = createStore(sortbyData[0].name).on(
  changeSortby,
  (s, p) => sortbyData.find(({ slug }) => slug === p).name,
);

export const searchStr$ = restore(changeSearchStr, '');

// filter of products quantity showing
export const changeSelectedProductsLimit = createEvent();
export const selectedProductsLimit$ = restore(changeSelectedProductsLimit, 50);

export const changeSelectedProductsSortBy = createEvent();
export const selectedProductsSortBy$ = restore(
  changeSelectedProductsSortBy,
  'updated_at',
);

export const changeSelectedOwner = createEvent();
export const selectedOwner$ = restore(
  changeSelectedOwner,
  DEFAULT_SELECTOR_STATE,
);

export const changeSelectedShow = createEvent();
export const selectedShow$ = restore(
  changeSelectedShow,
  DEFAULT_SELECTOR_STATE,
);

export const productsTablePage$ = createStore(1)
  .on(changeProductsTablePage, (s, p) => p)
  .on(changeSelectedProductsLimit, () => 1)
  .on(changeSearchStr, () => 1)
  .on(changeSelectedProductsSortBy, () => 1);

export const filter$ = combine(
  {
    sortedBy: 'desc',
    owner: selectedOwner$,
    selectedShow: selectedShow$,
    order: selectedProductsSortBy$,
    limit: selectedProductsLimit$,
    page: productsTablePage$,
    q: searchStr$,
  },
  s => ({
    ...s,
    category_id:
      s.selectedShow === DEFAULT_SELECTOR_STATE ||
      s.selectedShow === 'hidden' ||
      s.selectedShow === 'outOfStock' ||
      s.selectedShow === 'notAvailable'
        ? undefined
        : s.selectedShow,
    selector:
      s.selectedShow === 'hidden' ||
      s.selectedShow === 'outOfStock' ||
      s.selectedShow === 'notAvailable'
        ? s.selectedShow
        : undefined,
  }),
);

searchStr$.updates.watch(str => {
  if (!history.location.pathname.includes(PATHS._SEARCH)) {
    history.push(`${PATHS.SEARCH(1)}?q=${str}`);
  }
});
