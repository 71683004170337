import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import CategoryItem from 'components/molecules/CategoryItem';

import SubCategories from './SubCategories';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? '#f897395e' : 'grey',
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#f897395e' : 'initial',
});

const DraggableCategories = ({
  categories,
  checkbox,
  isLoading,
  onDragEnd,
}) => {
  return (
    <DragDropContext droppableId="categories" onDragEnd={onDragEnd}>
      <Droppable
        droppableId="mainCat"
        type="mainCat"
        isDropDisabled={isLoading}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {categories.map((category, index) => {
              return (
                <Draggable
                  key={category.id.toString()}
                  draggableId={category.id.toString()}
                  index={index}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps?.style,
                  )}
                  isDragDisabled
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CategoryItem
                        category={category}
                        index={index}
                        checkbox={checkbox}
                        isLoading={isLoading}
                        level={1}
                      />
                      {!!category.childs?.data?.length && (
                        <Droppable
                          droppableId={`${category.id}`}
                          type="SubCategories"
                          isDropDisabled={isLoading}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {category.childs.data.map((el, index) => {
                                return (
                                  <SubCategories
                                    key={el.id.toString()}
                                    subCategory={el}
                                    checkbox={checkbox}
                                    index={index}
                                    provided={provided}
                                    snapshot={snapshot}
                                    getItemStyle={getItemStyle}
                                    getListStyle={getListStyle}
                                    isLoading={isLoading}
                                    level={2}
                                  />
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableCategories;
