import { getDisplayedRegionNameInSeo } from 'utils/getDisplayedSeoNames';
import { capitalizeFirstLetter, strToUpper } from 'utils/strings';

export const getSeoCategoryTitle = ({ title = '', region, companyName }) => {
  const regionForDisplay = getDisplayedRegionNameInSeo(region);
  const regionToUpper = strToUpper(regionForDisplay);
  const companyNameCapital = capitalizeFirstLetter(companyName);

  return `${companyNameCapital} ${title} ${regionToUpper}`;
};

export const getSeoTitle = ({ product = {}, companyName }) => {
  const title = (product && product.name) || '';
  const companyNameCapital = capitalizeFirstLetter(companyName);

  return `${title} - ${companyNameCapital}`;
};
