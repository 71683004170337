// helper
import { createEffect, createEvent, forward } from 'effector';

export function createDebounce(callee, timeout) {
  let timeoutId;
  const tick = createEvent();
  let rejectPromise;

  const timer = createEffect().use(
    parameter =>
      new Promise((resolve, reject) => {
        rejectPromise = reject;
        timeoutId = setTimeout(resolve, timeout, parameter);
      }),
  );

  timer.watch(() => {
    clearTimeout(timeoutId);
    rejectPromise();
  });

  forward({
    from: callee,
    to: timer,
  });

  forward({
    from: timer.done.map(({ result }) => result),
    to: tick,
  });

  return tick;
}
