import React from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { Lang } from 'lang';
import { deleteReportFx, getReports, setEditedReport } from 'models/analytics';

import * as ST from './styles';

import { Row } from 'ui';

import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../../assets/images/icon/delete.svg';

const ReportsByMonthCart = ({ data, dialog }) => {
  const {
    form: { toastTitle },
  } = Lang();

  const handleDeleteReport = async id => {
    const res = await deleteReportFx(id);

    if (res) {
      toast.success(toastTitle.successfullyDeleted);
      getReports();
    } else {
      toast.success(toastTitle.deleteError);
    }
  };

  const handleEditReport = report => {
    const parsedData = parseISO(report.date);

    setEditedReport({
      id: report.id,
      year: format(parsedData, 'yyyy'),
      month: format(parsedData, 'MMMM'),
      fileName: report.name,
      title: report.name,
      media: report.media.data.src,
    });

    dialog.show();
  };

  return (
    <ST.ReportsByMonthWrapper>
      <ST.ReportTextTitle>{data.date}</ST.ReportTextTitle>
      <div>
        {data.reports.map((report, index) => (
          <ST.ReportTextWrapper key={index}>
            <Row align="center">
              <ST.ReportTextLink
                color="primary"
                href={report.media.data.src}
                download
                target="_blank"
                rel="noopener noreferrer"
                cursor="pointer"
              >
                {report.name}
              </ST.ReportTextLink>
              <ST.IconWrapper>
                <Edit onClick={() => handleEditReport(report)} />
              </ST.IconWrapper>
              <ST.DeleteIcon
                as={Delete}
                onClick={() => handleDeleteReport(report.id)}
              />
            </Row>
          </ST.ReportTextWrapper>
        ))}
      </div>
    </ST.ReportsByMonthWrapper>
  );
};

export default ReportsByMonthCart;
