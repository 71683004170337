import SmallDatePicker from 'components/atoms/SmallDatePicker';
import styled from 'styled-components';

import { T8y } from 'ui';

export const DateWrapper = styled.div`
  display: flex;
`;

export const DateFieldWrapper = styled.div`
  width: 160px;
  margin-right: 20px;
`;

export const PriceFieldWrapper = styled.div`
  width: 200px;
  margin-right: 20px;
`;

export const DatePickerStyled = styled(SmallDatePicker)`
  border: 1px solid #e4e8ed !important;
  border-radius: 3px;
  height: 44px;
  width: 100%;

  span {
    color: #424242;
  }
`;

export const TitleField = styled.div`
  width: 260px;
  margin-right: 20px;
`;

export const DatePriceRemoveIconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 35px;
`;

export const DatePriceFieldTitle = styled(T8y)`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 12px;
`;

export const DatePriceVATWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const VatWrapper = styled(T8y)`
  margin-left: 5px;
`;
