export const getTranslatedFieldValue = (field, currLang, defaultLang) => {
  return field ? field[currLang] || field[defaultLang] : null;
};

export const getTranslatedDefaultFieldValue = (
  field,
  currLang,
  defaultLang,
) => {
  return field ? field[defaultLang] || field[currLang] || '' : null;
};
