import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';
import { getTrainingIndex, getTrainings } from 'models/trainings';

import CustomButton from 'components/atoms/CustomButton';
import TrainingsList from 'components/organisms/TrainingsList';
import { EXPORT } from 'api';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const Trainings = () => {
  const {
    trainings: trainingsLocalization,
    header: { titles },
    form,
  } = Lang();

  const [isTrainingsExportLoading, setIsTrainingsExportLoading] = useState(
    false,
  );

  useEffect(() => {
    getTrainings();
    getTrainingIndex();
  }, []);

  const handleExportClick = useCallback(async () => {
    setIsTrainingsExportLoading(true);

    try {
      const { data } = await EXPORT.getAllTrainings();

      const link = document.createElement('a');
      link.setAttribute('href', data.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      toast.error(form.toastTitle.wentWrong);
    } finally {
      setIsTrainingsExportLoading(false);
    }
  }, [form.toastTitle.wentWrong]);

  return (
    <ST.TrainingPageWrapper>
      <ST.TrainingHeaderWrapper>
        <div>
          <T8y color="darkBlue" fontSize="24px" marginBottom="5px" bold>
            {trainingsLocalization.title}
          </T8y>
          <T8y color="gray" fontSize="16px">
            {trainingsLocalization.subTitle}
          </T8y>
        </div>
        <Row align="center" justify="stretch">
          <CustomButton
            onClick={handleExportClick}
            isLoading={isTrainingsExportLoading}
            height="50px"
          >
            {titles.export}
          </CustomButton>
        </Row>
      </ST.TrainingHeaderWrapper>
      <TrainingsList />
    </ST.TrainingPageWrapper>
  );
};

export default Trainings;
