import React, { useCallback, useMemo } from 'react';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { leadsTypesFilters$ } from 'models/lead';
import {
  changeHideTestLeads,
  changeSelectedDates,
  filters$,
  setLeadsFilter,
  setLeadsSearchStr,
} from 'models/leads';
import { getDateFromString, getDateInFormat } from 'utils/dates';

import { FieldStyle2 } from 'components/Product/shared';

import * as ST from './styles';

import { Checkbox, Icon, inputStyle, Row, Select, T8y } from 'ui';
import RangePicker from 'ui/RangePicker/RangePicker';

const LeadsListFilters = ({ leadsAvailable = 0 }) => {
  const { leads, orders, formatString } = Lang();

  const leadsFilters = useStore(filters$);
  const leadsTypesFilters = useStore(leadsTypesFilters$);

  const selectOptions = useMemo(() => {
    return [
      { name: leads.types.all, slug: DEFAULT_SELECTOR_STATE },
      ...leadsTypesFilters.map(el => ({
        slug: el,
        name: leads.types[el] || el,
      })),
    ];
  }, [leads.types, leadsTypesFilters]);

  const selectedOption = useMemo(() => {
    return (
      selectOptions.find(el => el.slug === leadsFilters.selector)?.name || ''
    );
  }, [leadsFilters.selector, selectOptions]);

  const handleChangeDates = useCallback(dates => {
    changeSelectedDates({
      start: getDateInFormat(dates[0], 'yyyy-MM-dd'),
      end: getDateInFormat(dates[1], 'yyyy-MM-dd'),
    });
  }, []);

  const selectedDates = useMemo(
    () => [
      getDateFromString(leadsFilters.dates.start, 'yyyy-MM-dd'),
      getDateFromString(leadsFilters.dates.end, 'yyyy-MM-dd'),
    ],
    [leadsFilters.dates],
  );

  return (
    <ST.FiltersWrapper align="center" noWrap>
      <ST.FlltersSelectorWrapper as={FieldStyle2} withBorder={true}>
        <Select
          options={selectOptions}
          selected={selectedOption}
          onClickOption={setLeadsFilter}
          className={inputStyle}
        />
      </ST.FlltersSelectorWrapper>
      {selectedDates && (
        <RangePicker onChange={handleChangeDates} values={selectedDates} />
      )}
      <ST.SearchWrapperStyled>
        <Icon as={ST.SearchIcon} />
        <ST.SearchInput
          type="text"
          placeholder={orders.startTypingToFilter}
          value={leadsFilters.search}
          onChange={e => setLeadsSearchStr(e.target.value)}
        />
      </ST.SearchWrapperStyled>
      <T8y>{formatString(leads.leadsAvailable, leadsAvailable)}</T8y>
      <Row align="center" noWrap>
        <Checkbox
          onChange={e => changeHideTestLeads(e.target.checked)}
          checked={leadsFilters.hideTestLeads}
        />
        <T8y color="negative">{leads.hideTestLeads}</T8y>
      </Row>
    </ST.FiltersWrapper>
  );
};

export default LeadsListFilters;
