import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useDialogState } from 'reakit';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { clearOrders } from 'models/orders';
import { clearUser, getUser, user$ } from 'models/users';
import { getQueryParam } from 'utils/getQueryParam';

import CustomButton from 'components/atoms/CustomButton';
import PageHeaderWithBackLink from 'components/atoms/PageHeaderWithBackLink';
import ConfirmLoggInAsModal from 'components/molecules/ConfirmLoggInAsModal';
import Stepper from 'components/molecules/Tabs';
import UserEditor from 'components/organisms/UserEditor';
import UserEditorNew from 'components/organisms/UserEditorNew';
import UserOrderProfile from 'components/organisms/UserOrderProfile';
import UserOrders from 'components/organisms/UserOrders';

import * as ST from './styles';

const User = ({ match, history }) => {
  const { header, users, orders, form, formatString } = Lang();

  const dialog = useDialogState();

  const user = useStore(user$);

  const id = match.params.id;
  const orderId = match.params.orderId;
  const isNewUser = id === 'new';

  const orderType = useMemo(
    () => getQueryParam(history.location.search, 'type'),
    [history.location.search],
  );

  useEffect(() => {
    if (id && !isNewUser) {
      getUser(id);
    }

    return () => {
      clearUser();
      clearOrders();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserWithType = user?.type;

  const steps = useMemo(
    () => [
      {
        label: header.titles.userProfile,
        path: PATHS.USER_PROFILE(id),
      },
      ...(!isNewUser
        ? [
            {
              label: header.titles.orders,
              path: PATHS.USER_ORDERS(id),
            },
          ]
        : []),
      ...(orderId
        ? [
            {
              label: `${orders.order} #${orderId}`,
              path: PATHS.ORDER_PROFILE(id, orderId, orderType),
            },
          ]
        : []),
    ],
    [isNewUser, header, id, orderId, orderType, orders],
  );

  if (!user && !isNewUser) {
    return null;
  }

  return (
    <ST.UserPageWrapper>
      <ST.HeadersWrapper>
        <PageHeaderWithBackLink
          link={PATHS.USERS}
          linkText={users.backToListUsers}
          header={
            isNewUser
              ? users.info.titleNewUser
              : formatString(
                  users.info.title,
                  `${user?.first_name || ''} ${user?.last_name || ''}`,
                  `${
                    isUserWithType ? users.types[user?.type] : users.info.noType
                  }`,
                )
          }
        />
        {!isNewUser && (
          <CustomButton
            text={form.buttons.logInAsUser}
            width="22rem"
            onClick={dialog.show}
          />
        )}
      </ST.HeadersWrapper>
      <Stepper steps={steps} marginBottom="20px" />
      <React.Suspense fallback={<div> Loading ...</div>}>
        <Switch>
          <Route path={PATHS.USER_PROFILE_NEW()} component={UserEditorNew} />
          <Route path={PATHS.USER_PROFILE(id)} component={UserEditor} />
          <Route
            path={PATHS.ORDER_PROFILE_TEMPLATE}
            component={UserOrderProfile}
          />
          <Route path={PATHS.USER_ORDERS(id)} component={UserOrders} />
          <Redirect to={PATHS.USER_PROFILE(id)} />
        </Switch>
      </React.Suspense>
      <ConfirmLoggInAsModal dialog={dialog} userId={user?.id} />
    </ST.UserPageWrapper>
  );
};

export default withRouter(User);
