import { createEffect, guard, restore } from 'effector';
import { createGate } from 'effector-react';

import { SETTINGS } from 'api';

export const groupSettingsGate = createGate('');

const getGroupSettingsFx = createEffect({
  handler: async () => {
    const { data } = await SETTINGS.getGroupSettings();
    return data || [];
  },
});

export const groupSettings$ = restore(getGroupSettingsFx, []);

guard({
  source: groupSettingsGate.open,
  filter: groupSettings$,
  target: getGroupSettingsFx,
});
