import { ReactComponent as DeleteIcon } from 'assets/images/icon/delete.svg';
import { ReactComponent as EditIcon } from 'assets/images/icon/edit.svg';
import { ReactComponent as LightDeleteIcon } from 'assets/images/icon/lightDelete.svg';
import { ReactComponent as Upload } from 'assets/images/icon/upload.svg';

export const getIcon = type => {
  switch (type) {
    case 'delete':
      return DeleteIcon;
    case 'lightDelete':
      return LightDeleteIcon;
    case 'edit':
      return EditIcon;
    case 'upload':
      return Upload;
    default:
      return EditIcon;
  }
};
