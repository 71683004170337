import styled from 'styled-components';

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button:first-child {
    margin-right: 20px;
  }
`;

export const AddMoreWrapper = styled.div`
  margin-bottom: 40px;
`;
