import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import DeliveryArea from 'components/molecules/DeliveryArea';

import * as ST from './styles';

import { T8y } from 'ui';

const DeliveryAreaList = ({
  deliveryIndex,
  register,
  control,
  values,
  setValue,
  watch,
  useWatch,
  FIELDS_NAMES_LIST,
}) => {
  const { settings: settingsLocalization } = Lang();

  const { fields } = useFieldArray({
    control,
    name: `deliveries[${deliveryIndex}].deliveryPerArea`,
  });

  return (
    <>
      <ST.DeliveryAreaTitles>
        <T8y as="span" color="gray">
          {settingsLocalization.area}
        </T8y>
        <T8y as="span" color="gray">
          {settingsLocalization.depotToLocation}
        </T8y>
        <T8y as="span" color="gray">
          {settingsLocalization.locationToDepot}
        </T8y>
        <T8y as="span" color="gray">
          {settingsLocalization.bothWaysDelivery}
        </T8y>
      </ST.DeliveryAreaTitles>
      <div>
        {fields.map((el, areaIndex) => (
          <DeliveryArea
            el={el}
            register={register}
            deliveryIndex={deliveryIndex}
            nestIndex={areaIndex}
            setValue={setValue}
            watch={watch}
            values={values}
            useWatch={useWatch}
            control={control}
            key={el.id}
            FIELDS_NAMES_LIST={FIELDS_NAMES_LIST}
          />
        ))}
      </div>
    </>
  );
};

export default DeliveryAreaList;
