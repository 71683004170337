import styled from 'styled-components';

import { T8y } from 'ui';

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  & label > div {
    margin-right: 12px;
  }
`;

export const FirstRadioWrapper = styled.div`
  margin-bottom: 16px;
`;

export const AvailableOption = styled(T8y)`
  line-height: 22px;
  font-size: 16px;
`;

export const LocationWrapper = styled.div`
  position: relative;
  padding: 30px;
  margin-bottom: 42px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const LocationControls = styled.div`
  position: absolute;
  padding: 0 10px;
  top: -10px;
  left: 20px;
  background-color: #ffffff;
  display: flex;
`;

export const LocationRemove = styled(T8y)`
  cursor: pointer;
  margin-left: 18px;
`;

export const OrganizerLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const LocationInputWrapper = styled(T8y)`
  width: 425px;
`;
