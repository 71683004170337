import React from 'react';
import { Lang } from 'lang';

import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import {
  FieldStyle,
  FieldWithMargin,
  HalfField,
  InputStyled,
} from 'components/Product/shared';

const UserPrivateGeneralInfo = ({ values, register }) => {
  const strings = Lang();

  return (
    <ProductEditorBlockWrapper
      blockTitle={strings.users.info.generalInformation}
    >
      <HalfField>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.firstName}>
          <InputStyled
            disabled
            name="first_name"
            ref={register}
            defaultValue={values?.first_name}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.lastName}>
          <InputStyled
            disabled
            name="last_name"
            ref={register}
            defaultValue={values?.last_name}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.emailAddress}
        >
          <InputStyled
            disabled
            name="email"
            ref={register}
            defaultValue={values?.email}
          />
        </FieldStyle>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.idVerificationStatus}
        >
          <InputStyled disabled name="userVerificationStatus" ref={register} />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.address}>
          <InputStyled
            disabled
            name="address"
            ref={register}
            defaultValue={values?.address}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.leads.billingAddress}>
          <InputStyled
            disabled
            name="defaultBillingAddress"
            ref={register}
            defaultValue={values?.defaultBillingAddress}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.postalCodeFieldTitle}
        >
          <InputStyled
            disabled
            name="postcode"
            ref={register}
            defaultValue={values?.postcode}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.city}>
          <InputStyled
            disabled
            name="city"
            ref={register}
            defaultValue={values?.city}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.provinceFieldTitle}
        >
          <InputStyled
            disabled
            name="province"
            ref={register}
            defaultValue={values?.province}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.country}>
          <InputStyled
            disabled
            name="country"
            ref={register}
            defaultValue={values?.country}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.phoneNumber}
        >
          <InputStyled
            disabled
            name="phone"
            ref={register}
            defaultValue={values?.phone}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.sectorOfActivity}
        >
          <InputStyled
            disabled
            name="sector_of_activity"
            ref={register}
            defaultValue={values?.sector_of_activity}
          />
        </FieldStyle>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.howDidYouKnowAboutUs}
        >
          <InputStyled
            disabled
            name="know_about_us"
            ref={register}
            defaultValue={values?.know_about_us}
          />
        </FieldStyle>
      </HalfField>
    </ProductEditorBlockWrapper>
  );
};

export default UserPrivateGeneralInfo;
