import React from 'react';

import { getIcon } from './consts';
import * as ST from './styles';

export const Icon = ({
  type,
  handleClick = null,
  color,
  marginRight,
  shadow,
}) => {
  return (
    <ST.StyledIcon
      as={getIcon(type)}
      onClick={handleClick}
      color={color}
      marginRight={marginRight}
      $shadow={shadow}
    />
  );
};
