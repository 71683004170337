import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Icon } from 'ui';

export const DeleteIcon = styled(Icon)`
  path {
    fill: ${colors.gray};
  }

  cursor: pointer;
`;

export const ReportTextWrapper = styled.div`
  cursor: pointer;
  color: ${colors.primary};
  margin-bottom: 10px;
`;

export const ReportsByMonthWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 20px;
`;

export const ReportTextTitle = styled.div`
  color: ${colors.darkBlue};
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
`;

export const ReportTextLink = styled.a`
  margin-right: 20px;

  &,
  &:visited {
    color: ${colors.primary};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  & svg {
    transition: fill 0.15s ease-in-out;
    fill: ${colors.primary};
  }
`;
