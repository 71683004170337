export const orders = {
  order: 'Ordine',
  title: 'Ordini Recenti',
  total: 'Grand Totale',
  documents: 'Documenti',
  invoice: 'Fattura',
  uploadDocument: 'Caricare Documenti',
  requestToCancelThisOrder:
    "L'utente ha inviato una richiesta per annullare questo ordine",
  approve: 'Approva',
  decline: 'Declina',
  youWantToCancelThisOrder: 'Sei sicuro di voler cancellare questo ordine?',
  provideCancellationReason: 'Se sì, fornisci il motivo della cancellazione',
  cancellationReason: 'Motivo della cancellazione',
  cancelOrder: 'Cancella ordine',
  submitRequest: 'Invia richiesta',
  viewOrder: 'Visualizza Ordine',
  ordersAvailable: '{0} ordini disponibili',
  startTypingToFilter: 'Inizia a scrivere per filtrare',
  paymentStatus: 'Stato del pagamento',
  productList: 'Elenco prodotti',

  orderTableHeaders: {
    orderId: 'ID Ordine',
    createdBy: 'Created By',
    orderType: 'Tipo di ordine',
    dateCreated: 'Data di creazione',
    dates: 'Fechas',
    status: 'Status',
    deliveryMethod: 'Modalità di consegna',
    paymentMethod: 'Modalità di pagamento',
    deposit: 'Deposito',
    total: 'Grand Totale',
    author: 'Autore',
    purchaseOrderNumber: `Numero d'ordine di Acquisto`,
  },

  productsTableHeaders: {
    sku: 'Sku',
    visual: 'Immagine',
    productTitle: 'Titolo del prodotto',
    hirePeriod: 'Durata del Noleggio',
    hirePerItem: 'Costo per articolo',
    qty: 'Qt',
    hirePrice: 'Prezzo di noleggio',
    courseId: 'ID corso',
    courseTitle: 'Titolo del corso',
    courseDates: 'Date del corso',
    coursePrice: 'Prezzo del corso',
    seats: 'Posti',
    totalCost: 'Costo totale',
  },

  item: {
    orderStatus: "Stato dell'ordine",
    date: 'Data di creazione',
    transport: 'Logistica',
    paymentType: 'Modalità di pagamento',
    productList: 'Elenco prodotti',
    cardEndingIn: 'Che termina',
  },

  paymentType: {
    card: 'Carta di credito',
    cash: 'Contanti',
    later: 'Paga dopo',
    bank: 'Bonifico Bancario',
  },

  statusType: {
    paid: 'Pagato',
    not_paid: 'Non Pagato',
    new: 'Nuova',
    in_progress: 'In Corso',
    completed: 'Completato',
    cancelled: 'Cancellato',
    all: 'Tutti Gli Ordini',
    quote: 'Preventivo',
  },

  orderType: {
    product: 'Prodotto',
    quote: 'Preventivo',
    training: 'Formazione',
  },

  deliveryMethod: {
    selfCollection: 'Ritiro in Sede',
    delivery: 'Consegna',
  },

  costSummary: {
    title: 'Anteprima dei Costi',
    subtotal: 'Subtotale',
    tbd: 'Da Definire',
    slightDamageWaiverFee: 'Rinuncia al danno',
    adminFee: 'Admin Fee',
    total: 'Totale',
    free: 'Gratuito',
    depositAdditionalText:
      'La cauzione verrà rimborsata sul metodo di pagamento non appena accertate le condizioni del materiale noleggiato',
    discount: 'Sconto',
    itemsToHire: 'Prodotti a noleggio',
    tradeAccountDiscount: 'Sconto per account commerciale',
  },

  offers: {
    offer: 'Offerta',
    offers: 'Offerte',
    newOffer: 'Nuova Offerta',
    createNewOffer: 'Cra una nuova offerta',
    viewOffer: 'Vedi Offerta',
    urlFromCheckout: 'Url dal checkout',
    enterUrl: `Inserisci qui l'URL`,
    backToList: `Torna all'elenco di tutte le offerte`,
    offersAvailable: '{0} offerte disponibili',
    saveOffer: `Salva l'offerta e genera PDF`,
    quotePDF: 'Preventivo PDF',
  },

  noDocuments: 'Nessun documento',
};
