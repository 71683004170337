import styled from 'styled-components';

import { colors } from 'styleGuide';

export const ItemWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: ${colors.positive};
  display: flex;
  margin-bottom: 20px;
  padding: 30px;

  > div {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.gray};
  background: #f8f8f8;
  border: 1px dashed #dddddd;
  margin-top: 1rem;

  & > img {
    max-width: 100%;
    height: 100%;
  }
`;

export const ImageErrorWrapper = styled.div`
  position: absolute;
`;

export const TextDataWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > div,
  & > button {
    margin-bottom: 30px;
  }

  & > button {
    margin-top: 30px;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;

  & > button {
    margin: 20px 20px 0 0;
  }
`;

export const DeleteImageIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 50px;
`;

export const IconWrapper = styled.div`
  flex-basis: 10%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 4.4rem;
  margin-left: 2rem;
`;

export const hiddenBlock = styled.div`
  position: absolute;
  visibility: hidden;
`;

export const hiddenInput = styled.input`
  visibility: hidden;
`;

export const BlocksFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageBlocksWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    position: relative;
  }

  & > div:first-child {
    flex-basis: 60%;
    margin-right: 30px;
  }

  & > div:nth-child(2) {
    flex-basis: 40%;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
