import { useEffect } from 'react';

export const useManageIsActiveIntegrationsField = (
  register,
  externalIds,
  integration,
  setValue,
  values,
) => {
  useEffect(() => {
    register('isActiveIntegration');
  }, [register]);

  useEffect(() => {
    const isActive = externalIds.find(el => el.isConnected);

    if (isActive && integration.active && !values.isActiveIntegration) {
      setValue('isActiveIntegration', true);
    }
  }, [externalIds, integration.active, setValue, values.isActiveIntegration]);
};
