import { DEFAULT_SELECTOR_STATE } from 'consts';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
} from 'effector';
import { createGate } from 'effector-react';
import FuzzySearch from 'fuzzy-search';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { getDateFromString, getDateInFormat } from 'utils/dates';
import { createDebounce } from 'utils/helpers/timeouts';

import { PROMO_CODES } from 'api';

import { settings$ } from './settings';

export const setPromoCodesFilter = createEvent();
export const setPromoCodesSearchStr = createEvent();
export const getPromoCodes = createEvent();

const promoCodesSearcher = state =>
  new FuzzySearch(state, ['code'], { sort: true });

export const setPromoCodesSearchStrDebounced = createDebounce(
  setPromoCodesSearchStr,
  100,
);

const defaultFilters = {
  selector: DEFAULT_SELECTOR_STATE,
  search: '',
};

export const filters$ = createStore(defaultFilters)
  .on(setPromoCodesFilter, (s, p) => ({ ...s, selector: p }))
  .on(setPromoCodesSearchStrDebounced, (s, p) => ({
    ...s,
    search: p,
  }));

export const loadPromoCodesFx = createEffect({
  handler: async params => {
    const { data } = await PROMO_CODES.getAllPromoCodes(params);

    const formattedPromoCodes = data.data.map(el => ({
      ...el,
      startDate: getDateInFormat(
        getDateFromString(el.startDate, "yyyy-MM-dd'T'HH:mm:ss"),
        'yyyy-MM-dd',
      ),
      endDate: getDateInFormat(
        getDateFromString(el.endDate, "yyyy-MM-dd'T'HH:mm:ss"),
        'yyyy-MM-dd',
      ),
    }));

    return { promoCodes: formattedPromoCodes, meta: data.meta };
  },
});

export const promoCodesGate = createGate('promoCodesGate');

export const promoCodes$ = createStore([]).on(
  loadPromoCodesFx.doneData,
  (_, p) => p.promoCodes,
);

export const promoCodesLocalized$ = combine(
  promoCodes$,
  currentLang$,
  settings$,
  (promoCodes, lang, { defaultLanguage }) =>
    promoCodes.map(el => ({
      ...el,
      items: el.items.map(item => ({
        ...item,
        name: item.names[lang] || item.names[defaultLanguage],
      })),
    })),
);

export const promoCodesFiltered$ = combine(
  promoCodesLocalized$,
  filters$,
  (promoCodesLocalized, { selector, search }) => {
    const filteredPromoCodes =
      selector === DEFAULT_SELECTOR_STATE
        ? promoCodesLocalized
        : promoCodesLocalized.filter(
            el => el.status?.toLowerCase() === selector,
          );

    return search
      ? promoCodesSearcher(filteredPromoCodes).search(search)
      : filteredPromoCodes;
  },
);

export const isPromoCodesLoading$ = loadPromoCodesFx.pending;

forward({
  from: getPromoCodes,
  to: loadPromoCodesFx,
});

guard({
  source: promoCodesGate.open,
  filter: loadPromoCodesFx.pending.map(state => !state),
  target: loadPromoCodesFx,
});
