export const marketing = {
  titles: {
    content: 'Contenuti',
    homePage: 'Galleria immagini per Home',
    slideIndex: 'Immagine numero',
    deleteSlide: 'Cancella Immagine',
    CTA: 'Bottone Call to Action',
    buttonText: 'Testo per il Bottone',
    buttonURL: 'Indirizzo web per il bottone CTA',
    learnMore: 'Scopri di più',
    declareInterest: 'Puoi spiegarmi cosa vuol dire?',
    exploreCategory: 'Guarda la Categoria',
    addNewSlide: 'Aggiungi nuova immagine per galleria',
    desktopVersion: 'Immagine desktop',
    mobileVersion: 'Immagine mobile',
    heroImages: 'Hero Images',
    popularProducts: 'Prodotti popolari',
    popularProductsNote:
      'La sezione Prodotti popolari sarà visibile SOLO se vengono aggiunti più di 3 prodotti',
    targetBlank: 'Apri link in una nuova scheda',
    enquiryForm: 'Modulo di richiesta',
    popularCategories: 'Categorie popolari',
    promoCodes: 'Codici promozionali',
    buttonTitle: 'Titolo pulsante',
    h1Text: 'H1 Tag',
    pText: 'Testo Paragrafo (solo versione desktop)',
    jobTypes: 'Progetti',
    taFaqs: 'TA FAQs',
  },

  enquiry: {
    enquiryFormVariation: 'Tipo di modulo',
    defaultTitle: 'Modulo di default',
    defaultSubTitle:
      'Il modulo di default include questi campi: nome completo, numero di telefono, nome dell’azienda, email, messaggio',
    customizedTitle: 'Modulo personalizzabile',
    customizedSubTitle:
      'Seleziona questa opzione per aggiungere ulteriori domande',
    addNewAnswer: 'Aggiungi una nuova risposta',
  },

  jobTypes: {
    jobType: 'Tipo di progetto',
    jobTypeTitle: 'Nome del progetto',
    relatedProducts: 'Prodotti correlati',
    relatedCategories: 'Categorie correlate',
    addNewJobType: 'Aggiungi un nuovo tipo di progetto',
    viewJobType: 'Vedi i progetti',
    availableItems: '{0} lavori disponibili',
    jobURL: 'URL della pagina',
    jobHTMLTitle: 'Titolo HTML ',
    hideJob: 'Nascondi la pagina dei progetti',
    marketingText: 'Testo di marketing',
    addProduct: 'Aggiungi un prodotto correlato',
    addCategory: 'Aggiungi una categoria correlata',
    backToJobs: 'Torna alla lista dei progetti',
    noJobs: 'Progetti non ancora disponibili',
  },
};
