import styled from 'styled-components';

import { colors } from 'styleGuide';
import { T8y } from 'ui';

export const OrderDocumentsWrapper = styled.div`
  margin-bottom: 10px;
`;

export const AddLinkWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  & > div {
    bottom: -2rem !important;
    right: 0 !important;
    transform: none !important;
    inset: 2rem auto auto 10rem !important;
  }
`;

export const LimitedFilesWrapper = styled.div`
  background-color: rgba(33, 33, 33, 0.9);
  padding: 0.8rem;
  border-radius: 0.4rem;
`;

export const DocumentsTitle = styled(T8y)`
  ${p =>
    p.isSmall
      ? `
       font-size: 14px;
       color: #2e2e2e; 
       margin: 14px 0 10px`
      : `
      font-size:18px; 
      color: ${colors.darkBlue}}; 
      margin-bottom: 6px`};
`;
