import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 0 15px;
  bottom: 13px;

  &:hover {
    path {
      fill: #f89739;
    }
  }
`;

export const HeroImageWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(248, 248, 248);
  outline: 1px dashed #dddddd;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
