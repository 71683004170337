export const form = {
  buttons: {
    saveChanges: 'Salva le Modifiche',
    cancel: 'Cancella',
    close: 'Chiudi',
    continue: 'Continua',
    addMore: 'Aggiungi altre',
    addSelected: 'Aggiungere Selezionati',
    addCategory: 'Aggiungi Categoria',
    createProduct: 'Crea nuovo prodotto',
    update: 'Aggiorna',
    create: 'Creare',
    createNew: 'Crea nuovo',
    saveAndView: 'Salva e Controlla la Pagina',
    save: 'Salva',
    publish: 'Pubblica',
    add: 'Aggiungi',
    adding: 'Sto aggiungendo',
    editing: 'Sto modificando',
    edit: 'Modificare',
    delete: 'Cancellare',
    browse: 'Naviga',
    logIn: 'Accesso',
    createCopy: 'Crea una copia',
    addNewReport: 'Aggiungi nuovo report',
    createNewOrder: 'Crea nuovo ordine',
    okay: 'Okay',
    addNewDeliveryType: 'Aggiungi un nuovo metodo di consegna',
    addLinkToSelectedText: 'Aggiungi collegamento al testo selezionato',
    removeLinkFromSelectedText: 'Rimuovi il collegamento dal testo selezionato',
    connect: 'Connettere',
    deleteId: 'Eliminare ID',
    addProductId: 'Aggiungere ID prodotto',
    logInAsUser: 'Accedi',
    submit: 'Submit',
  },

  links: {
    addMore: 'Aggiungi altre',
    createNew: 'Crea nuovo',
    goToAllCategories: 'Vai a tutte le categorie',
  },

  fields: {
    username: 'Nome utente',
    password: 'Parola',
    label: 'Etichetta',
    category: 'Categoria',
    minPeriod: 'Periodo minimo di assunzione',
    avgMarketPriceSave: 'Risparmio sul prezzo medio di mercato',
    subCategory: 'Sottocategoria',
    subSubCategory: 'Categoria Terziaria',
    maxQuantity: 'Quantità massima',
    popularity: 'Popolarità',
    date: 'Data',
    show: 'Mostra',
    showAll: 'Mostra tutto',
    all: 'Tutti',
    deliveryType: 'Tipo di Spedizione',
    title: 'Nome',
    titleGb: 'Nome Gb',
    titleIt: 'Nome It',
    sku: 'Sku',
    description: 'Descrizione',
    productUrl: 'URL Prodotto',
    feature: 'Caratteristica',
    rangeInDays: 'Numero di giorni',
    pricePerDay: 'Prezzo al giorno',
    pricePerRange: 'Prezzo per intervallo di tempo',
    outOfRangeRation: 'Out of range ratio (per day), %',
    outOfRangePrice: 'Al di fuori dell’intervallo di prezzo, al giorno',
    individualUser: 'Privato',
    companyUser: 'Azienda',
    feePerDayPerItem: 'Prezzo per Giorno per Oggetto',
    feePerItem: 'Quota per prodotto',
    feePerOrder: 'Fee per Order',
    amountPerItem: 'Importo per articolo',
    metaDescription: 'Meta Description',
    metaCanonical: 'Meta Canonical',
    h1Tag: 'Tag Intestazione H1',
    robotsTag: 'Tag Robots',
    parentCategory: 'Categoria Collegata',
    deposit: 'Deposito',
    measurements: 'Misure',
    capacity: 'Capacità',
    weight: 'Peso',
    craneCapacity: 'Capacita della gru',
    power: 'Energia',
    maxHeight: 'Altezza massima',
    maxWorkingHeight: 'Altezza di lavoro massima',
    holeDiameter: 'Diametro del foro',
    notes: 'Commenti',
    diskDiameter: 'Diametro del disco',
    cuttingDepth: 'Profondità di taglio',
    cuttingWidth: 'Larghezza di taglio',
    excavationDepth: 'Profondità di scavo',
    question: 'Domanda',
    answer: 'Risposta',
    name: 'Nome',
    location: 'Posizione geografica',
    rating: 'Voto',
    review: 'Recensione',
    productCode: 'Codice prodotto',
    photo: 'Immagine',
    price: 'Prezzo',
    tag: 'Tag',
    search: 'Cerca',
    specification: 'Specifica',
    specificationName: 'nome',
    specificationVaValue: 'valore',
    startTyping: 'Inizia a digitare per cercare per filtrare i report',
    reportsAreFound: 'Report non trovato',
    maxLoadCapacity: 'Massimo carico, kg',
    startPrice: 'Prezzo di partenza',
    minPrice: 'Prezzo minimo',
    pricePerKm: 'Prezzo al km',
    pricePerWay: '*Prezzo per tratta',
    ReportYear: 'Report Anno / Mese',
    reportTitle: 'Titolo Report',
    reviewTitle: 'Titolo della review',
    marginOfDays: 'Margine di giorni prima del noleggio',
    checkoutAvailable: 'Disponibile per checkout',
    checkoutNotAvailable: 'Non disponibile per checkout',
    sectionDescription: 'Descrizione sezione (opzionale)',
    visual: 'Immagine',
    productTitle: 'Titolo del prodotto',
    tax: 'Tax',
    trainingCardDiscountValue: 'Training Card Discount Value',
    drafts: 'Bozze',
    priceWithoutVat: 'Prezzo senza IVA',
    fullName: 'Nome e Cognome',
    emailAddress: 'Indirizzo email',
    PhoneNumber: 'Numero di telefono',
    companyName: 'Ragione Sociale',
    vatNumber: 'Partita IVA',
    outOfStock: 'Non disponibile',
    unavailable: 'Not Available',
    googleMapLink: 'Link Google map',
    openingTimes: 'Orario di apertura',
    latitude: 'Atitudine',
    longitude: 'Longitudine',
    perDay: 'al giorno',
    perWeek: 'a settimana',
  },

  defaultText: {
    selectCategory: 'Selezionare Categoria',
    selectSubCategory: 'Selezionare sotto categoria',
    selectSubSubCategory: 'Selezionare sotto sotto categoria',
    selectDeliveryType: 'Selezionare tipo di spedizione',
    noParent: 'Nessuna categoria parente',
    enterDeliveryTypeTitle: 'Inserisci il titolo del metodo di trasporto',
  },

  checkbox: {
    insurance: 'Assicurazione',
    safetyFee: 'Quota messa sicurezza',
    sanitationFee: 'Quota sanificazione',
    retailItem: 'Articolo in dettaglio',
    controlledBy: 'controllato dall\'impostazione regionale "fixedRangesPrice"',
    hideProduct: 'Nascondi prodotto nel catalogo',
    showProductVideo: 'Mostra il video del prodotto',
    gasTank: 'Deposito Carburante',
    showCarbon: 'Mostra il badge Carbon Neutral',
    adminFeeFixed: 'Admin Fee Fixed',
    adminFeeVat: 'Admin Fee Vat',
    wasteManagementFee: 'Waste Management Fee',
    deposit: 'Deposito',
    depositToBeDefined: 'Deposit To Be Defined',
    deliveryWithoutVat: 'Delivery Without Vat',
    showAsOutOfStock: 'Mostrare prodotto come Non Disponibile',
    idVerification: 'ID verification',
    damageWaiver: 'Rinuncia ai danni',
  },

  radio: {
    file: 'File',
  },

  title: {
    loginIn: 'Per favore autenticati',
  },

  toastTitle: {
    thelastImage: "L'ultima immagine non puo essere cancellata",
    onlyImagesCan: 'Possono essere aggiunte solo {0} immagini',
    successfullyUpdated: 'Aggiornamento riuscito con successo',
    successfullyCreated: 'Creato con successo',
    successfullyDeleted: 'Eliminato con successo',
    successfullyApproved: 'Approvato con succeso',
    successfullyDeclined: 'Declinato con successo',
    successfullyRequest: 'La tua richiesta è stata inviata con successo!',
    createError: 'Create error',
    deleteError: 'Delete error',
    wasUpdated: "e' stato aggiornato",
    wasCreated: "e' stato creato",
    updateError: 'Errore di aggiornamento',
    creating: 'Sto creando',
    updating: 'Sto aggiornando',
    extraCantBeCreated: 'Non e stato possibile creare gli Extra',
    onlySeveralFiledCanBeUpdated: 'Only {0} files can be added',
    invalidForm: 'Non tutti i campi sono stati inseriti correttamente',
    minProducts: 'Il progetto deve avere almeno 4 prodotti',
    wentWrong: 'Qualcosa è andato storto',
  },

  notification: {
    valueShouldBeUnique: 'Inserire un valore unico',
    valueShouldBePositive: 'Inserire come valore un numero positivo',
    valueShouldBeBetween: 'Il valore deve essere compreso tra {0} e {1}',
    valueShouldBeValid: 'Inserire una URL valida come valore',
    shouldBeInsideOneOfTheRanges:
      'Il valore deve rientrare in uno dei range esistenti',
    theFieldIsRequired: 'Campo richiesto',
    theLastSlideImage:
      "L'ultima immagine dello slider non puo essere cancellata",
    theBiggestRange:
      "The range day can't be more than day from additional prices",
    notAvailableArea: 'Non si effettuano consegne in questa zona',
    notAvailable: 'Non disponibile',
    loading: 'Caricamento in corso..',
    notFound: 'ID non trovato',
    chooseCorrectAddress:
      'Per favore seleziona l’indirizzo corretto in questa lista',
    rangeDaysMustBe: 'Range in days must be 1-7 or 3-7',
    phonePatter: 'Numero di telefono non corretto',
    minLength: 'Min. {0} caratteri',
    emailPattern: 'Indirizzo email errato',
    passwordLength: 'Password must have at least 6 characters',
    passwordDifferent: 'Passwords are different',
    maxLength: 'Max. {0} caratteri',
    requiredLength: '{0} simboli richiesti',
    minValue: 'Min. {0}',
    firstShouldBeLessSecond: 'Il primo valore deve essere minore del secondo',
    valueAlreadyPresents: 'Il valore è già stato utilizzato',
    rangeShouldNotOverlapOthers: 'Il range non deve sovrapporsi agli altri',
    availableOnly:
      'Available only if there is a range ending in “7" is present',
  },

  descriptions: {
    enterVideoUrl: "Inserisci l'URL del video Youtube o Vimeo",
    filesAsMax: 'Massimo {0} documenti',
    areYouSureToDelete:
      'Sei sicuro di voler eliminare il prodotto {0} con sku {1}?',
    popularMaxReached: 'Limite massimo di prodotti popolari raggiunto',
  },

  placeholders: {
    startTyping: 'Inizia a scrivere...',
    enterProductId: "Inserire l'ID prodotto e fare click su Connetti",
    enterTitle: 'Inserisci il titolo (max. {0} caratteri)',
  },

  active: 'Attivo',
  inactive: 'Disattivo',
  vat: 'IVA',

  calendar: {
    selectHirePeriod: 'Seleziona periodo di noleggio',
    previous: 'Precedente',
    reset: 'Reimpostare i valori predefiniti',
    next: 'Il prossimo',
    dayPlaceholder: 'GG',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'AAAA',
  },

  prevPage: 'Pagina precedente',
  nextPage: 'Prossima pagina',
  disabledOnRegionalLevel: 'disabilitato a livello regionale',
  extra: 'Extra',
  enterDepotName: 'Inserisci il nome della filiale',
  depotOwner: 'Proprietario della filiale',
};
