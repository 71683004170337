import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useGetLocalizedExtraFields } from 'hooks';
import { Lang } from 'lang';
import { settings$ } from 'models/settings';

import {
  FieldStyle,
  FieldWithMargin,
  HalfField,
  InputStyled,
} from '../../Product/shared';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';

const UserCompanyGeneralInfo = ({ values, register }) => {
  const strings = Lang();

  const settings = useStore(settings$);

  const extraFieldsLocalized = useGetLocalizedExtraFields(
    settings?.extraFieldsTradeProfile,
  );

  const { companyExtraFields } = useMemo(() => {
    return extraFieldsLocalized.reduce(
      (acc, el) => {
        if (el.type === 'company') {
          return {
            ...acc,
            companyExtraFields: [...acc.companyExtraFields, el],
          };
        }

        return acc;
      },
      { companyExtraFields: [] },
    );
  }, [extraFieldsLocalized]);

  return (
    <ProductEditorBlockWrapper
      blockTitle={strings.users.info.generalInformation}
    >
      <FieldStyle as={FieldWithMargin} legend={strings.users.info.businessName}>
        <InputStyled
          disabled
          name="business_name"
          ref={register}
          defaultValue={values?.business_name}
        />
      </FieldStyle>
      <HalfField>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.vatNumber}>
          <InputStyled
            disabled
            name="vat_number"
            ref={register}
            defaultValue={values?.vat_number}
          />
        </FieldStyle>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.fiscalCodeFieldTitle}
        >
          <InputStyled
            disabled
            name="fiscal_code"
            ref={register}
            defaultValue={values?.fiscal_code}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.emailAddress}
        >
          <InputStyled
            disabled
            name="email"
            ref={register}
            defaultValue={values?.email}
          />
        </FieldStyle>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.idVerificationStatus}
        >
          <InputStyled disabled name="userVerificationStatus" ref={register} />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.officeAddressFieldTitle}
        >
          <InputStyled
            disabled
            name="address"
            ref={register}
            defaultValue={values?.address}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.leads.billingAddress}>
          <InputStyled
            disabled
            name="defaultBillingAddress"
            ref={register}
            defaultValue={values?.defaultBillingAddress}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.postalCodeFieldTitle}
        >
          <InputStyled
            disabled
            name="postcode"
            ref={register}
            defaultValue={values?.postcode}
          />
        </FieldStyle>

        <FieldStyle as={FieldWithMargin} legend={strings.users.info.city}>
          <InputStyled
            disabled
            name="city"
            ref={register}
            defaultValue={values?.city}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.provinceFieldTitle}
        >
          <InputStyled
            disabled
            name="province"
            ref={register}
            defaultValue={values?.province}
          />
        </FieldStyle>
        <FieldStyle as={FieldWithMargin} legend={strings.users.info.country}>
          <InputStyled
            disabled
            name="country"
            ref={register}
            defaultValue={values?.country}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.phoneNumber}
        >
          <InputStyled
            disabled
            name="phone"
            ref={register}
            defaultValue={values?.phone}
          />
        </FieldStyle>
      </HalfField>
      <HalfField>
        <FieldStyle
          as={FieldWithMargin}
          legend={strings.users.info.sectorOfActivity}
        >
          <InputStyled
            disabled
            name="sector_of_activity"
            ref={register}
            defaultValue={values?.sector_of_activity}
          />
        </FieldStyle>
      </HalfField>
      {companyExtraFields.map(el => (
        <HalfField key={el.name}>
          <FieldStyle as={FieldWithMargin} legend={el.title}>
            <InputStyled
              disabled
              name={el.name}
              ref={register}
              defaultValue={values?.[el.name]}
            />
          </FieldStyle>
        </HalfField>
      ))}
    </ProductEditorBlockWrapper>
  );
};

export default UserCompanyGeneralInfo;
