import styled from 'styled-components';

import { colors } from 'styleGuide';

import { ReactComponent as CheckedCircle } from 'assets/images/icon/checkedCircle.svg';

export const UploadPhotosTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

export const UploadPhotosBody = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  padding: 3rem;
  margin-bottom: 5rem;
`;

export const UploadBlock = styled.div`
  border: 1px solid ${colors.lightGray};
  text-align: center;
  font-size: 1.2rem;
  color: #787878;
  cursor: pointer;
  margin-bottom: 2rem;
`;

export const UploadEmptyBlock = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  min-width: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1px;
  }
`;

export const ImageWrapper = styled.div`
  border: ${props =>
    props.isMainImage
      ? `1px solid ${colors.primary}`
      : `1px solid ${colors.lightGray}`};
  position: relative;
  width: 100%;
  height: 100%;
  cursor: ${props => props.isImage && `pointer`};
`;

export const CheckedCircleWrapper = styled(CheckedCircle)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const ActionsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
  bottom: 5px;
`;
