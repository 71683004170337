export const settings = {
  titles: {
    transportation: 'Transporte',
    costCalculationMethod: 'Método de cálculo del coste',
    routeAvailability: 'Disponibilidad de ruta del transporte',
    transportationCostVat: 'Coste del transporte',
    additionalCharges: 'Cargos adicionales',
    regionalSettings: 'Configuración regional',
    calendarSetup: 'Configuración del calendario',
    depots: 'Delegaciones',
  },

  priceByArea: 'Precio por área',
  priceByKm: 'Precio por kilómetro',
  area: 'Área',
  priceByPostalCode: 'Precio por código postal',
  priceByRadius: 'Precio por radio',
  postcode: 'Código Postal',
  depotToLocation: 'Entrega',
  locationToDepot: 'Recogida',
  bothWaysDelivery: 'Entrega y recogida',
  depot: 'Delegación',
  outOfRangeNotAvailable: 'La entrega fuera de rango no está disponible',
  outOfRangeNotAvailableMsg:
    'Si se marca esta casilla, el mensaje “Lo sentimos, no entregamos a tu dirección” sera mostrado durante el proceso de checkout a los usuarios cuya dirección este fuera de nuestro alcance',
  copyToOtherDepots: 'Copiar esta configuración a otras delegaciones',
  rangeInKm: 'Distancia en {0}',
  depotToLocationByRadius: 'Delegación a dirección de trabajo',
  locationToDepotByRadius: 'Dirección de trabajo a delegación',
  bothWaysDeliveryByRadius: 'Entrega y recogida',
  thisRangeNotAvailable: 'En este rango la entrega no es posible',
  addNewRange: 'Añadir rango nuevo',
  showAllDepots: 'Expandir y mostrar todos {0} las delegaciones',
  showFirstDepotOnly: 'Ocultar y mostrar solo la primera delegación',
  useBrToSeparate: 'Usa una "<br>" para separar ítems',

  modals: {
    deleteConfirmationTitle: 'Eliminar confirmación',
    deleteConfirmationText:
      '¿Estás seguro que quieres eliminar {0} de la lista de tipos de entrega disponibles?',
    deleteMessageTitle: 'Aviso',
    deleteMessageText:
      'El tipo de entrega {0} se usa para algunos productos del catálogo. Si aún quieres eliminarlo, por favor asigna otro tipo de entrega a esos productos.',
    copyConfigurationTitle: 'Copiar configuración',
    copyConfigurationText:
      'Estas seguro que quieres copiar esta configuración? Esta acción sobreescribirá los valores de rango/coste y no sera posible recuperarlos',
    deleteDistanceRangeConfirmationTitle: 'Eliminar rango de distancia',
    deleteDistanceRangeConfirmationText:
      'Estás seguro que quieres borrar este rango?',
    depotDeleteConfirmationText:
      'Estas seguro que desea eliminar {0} de la lista de delegaciones?',
    depotDeleteMessageText:
      'La delegación {0} esta siendo usanda por algunos productos del catálogo. Si desea eliminarla, primero marque estos productos como “Sin Stock”.',
  },
};
