import { createEffect, createEvent, forward, guard, restore } from 'effector';

import { SETTINGS } from 'api';

export const loadBrandingFx = createEffect({
  handler: async () => {
    const { data } = await SETTINGS.getBranding();

    return { ...data };
  },
});

export const updateBrandingFx = createEffect({
  handler: async params => {
    const { data } = await SETTINGS.updateBranding(params);

    return { ...data };
  },
});

export const getBranding = createEvent();
export const updateBranding = createEvent();

export const branding$ = restore(loadBrandingFx.doneData, {});

export const brandingNotLoaded$ = restore(
  loadBrandingFx.doneData,
  (_, p) => false,
).on(updateBranding, (_, p) => p);

guard({
  source: getBranding,
  filter: brandingNotLoaded$,
  target: loadBrandingFx,
});

forward({
  from: updateBranding,
  to: updateBrandingFx,
});
