import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { categoriesTreeStore, getCategoriesTree } from 'models/categories';
import { ConsumablesGate } from 'models/consumables';
import { allOptionalExtraGate, allRequiredExtraGate } from 'models/product';
import * as MD from 'pages/model';
import { getNumeral } from 'utils/numeral';

import {
  changeProductsTablePage,
  selectedProductsLimit$,
} from 'features/filter/filterModel';

import CustomButton from 'components/atoms/CustomButton';
import LoadingSpinner from 'components/atoms/LoadingSpinner';
import ProductFilterPanel from 'components/molecules/ProductsFilterPanel';
import { history } from 'libs/history';
import { EXPORT } from 'api';

import { Product } from './Product';
import { AddNewStyled, RowInfoStyled, RowTableHeaderStyled } from './styles';

import { Cell, Grid, Icon, Row, T8y, TablePaging } from 'ui';

import { ReactComponent as Cart } from 'assets/icons/cart.svg';

const numeral = getNumeral();

export const Products = () => {
  const { header, product, form } = Lang();

  const selectedProductsLimit = useStore(selectedProductsLimit$);
  const products = useStore(MD.allProducts$);
  const productsMeta = useStore(MD.allProductsMeta$);
  const searchPending = useStore(MD.searchPending$);
  const categories = useStore(categoriesTreeStore);

  useGate(allRequiredExtraGate);
  useGate(allOptionalExtraGate);
  useGate(MD.productsGate);
  useGate(ConsumablesGate);

  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isProductsExportLoading, setIsProductsExportLoading] = useState(false);

  const getAllProducts = useCallback(() => {
    if (!isProductsLoading) {
      if (MD.cancelGetAllProductsWithoutFilters) {
        MD.cancelGetAllProductsWithoutFilters.cancel();
      }

      MD.getAllProductsFx().then(() => {
        MD.getAllProductsWithoutFiltersFx();
      });
    }
  }, [isProductsLoading]);

  useEffect(() => {
    if (!categories || !categories.length) {
      getCategoriesTree();
    }
    getAllProducts();
    return () => {
      if (!history.location.pathname.includes(PATHS.PRODUCT(''))) {
        MD.changeSearchStr('');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  MD.allProductsLoading$.watch(val => {
    if (val !== isProductsLoading) {
      setIsProductsLoading(val);
    }
  });

  const handleExportClick = useCallback(async () => {
    setIsProductsExportLoading(true);

    try {
      const { data } = await EXPORT.getAllProducts();

      const link = document.createElement('a');
      link.setAttribute('href', data.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      toast.error(form.toastTitle.wentWrong);
    } finally {
      setIsProductsExportLoading(false);
    }
  }, [form.toastTitle.wentWrong]);

  return (
    <>
      <RowInfoStyled
        gaps="30px"
        cols="2fr 2fr 2fr 2fr 3fr 2fr 20rem"
        places="center start"
      >
        <Cell place="center start" as={T8y} color="darkBlue" variant="t1" bold>
          {searchPending
            ? 'Searching...'
            : `${numeral(productsMeta.total || products.length).format()} ${
                header.titles.productsFound
              }`}
        </Cell>
        <ProductFilterPanel />
        <Row align="center" justify="stretch">
          <CustomButton
            onClick={handleExportClick}
            isLoading={isProductsExportLoading}
            height="50px"
          >
            {header.titles.export}
          </CustomButton>
        </Row>
        <Cell place="center stretch">
          <AddNewStyled to={PATHS.CREATE_PRODUCT}>
            <Icon as={Cart} $light />
            {header.titles.addNew}
          </AddNewStyled>
        </Cell>
      </RowInfoStyled>
      {isProductsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <RowTableHeaderStyled
            cols="1.5fr 2fr 2fr 3fr 2.5fr 1.5fr"
            places="center start"
          >
            <T8y color="gray">{header.table.productCode}</T8y>
            <T8y color="gray">{header.table.photo}</T8y>
            <T8y color="gray">{product.owner}</T8y>
            <T8y color="gray">{header.table.title}</T8y>
            <T8y color="gray">{form.fields.popularity}</T8y>
            <div />
          </RowTableHeaderStyled>
          <Grid gaps="1rem 0">
            {products.map((el, index) => (
              <Product {...el} key={index} />
            ))}
            {productsMeta.total_pages > 1 && (
              <TablePaging
                items={products}
                limitPerPage={selectedProductsLimit}
                handlePageChange={changeProductsTablePage}
                totalCount={productsMeta.total}
                totalPages={productsMeta.total_pages}
                currPage={productsMeta.current_page}
              />
            )}
          </Grid>
        </>
      )}
    </>
  );
};
