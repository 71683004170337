export const categories = {
  titles: {
    manageCategories: 'Gerenciar categorias',
    categoriesFound: 'categorias encontradas no catálogo',
    startTyping: 'Comece a digitar para filtrar categorias por título ',
    allCategories: 'Todas as categorias',
    archievedCategories: 'Categorias arquivadas',
    visual: 'Visual',
    categoryTitle: 'Título da categoria',
    products: 'Produtos',
    createCategory: 'Criar categoria',
    editCategory: 'Editar categoria',
    categoryVisual: 'Visual da categoria',
    updating: 'Atualizando',
    creating: 'Criando',
    show: 'Mostrar',
    selectFilter: 'Selecione o filtro',
    deleteSelected: 'Excluir selecionado',
    deletionConfirmation: 'Confirmação de exclusão',
    prerenderInfo: 'Informações de pré-renderização',
    hiddenCategories: 'Categorias ocultas',
    visibleCategories: 'Categorias visíveis',
  },
  buttons: {
    cancel: 'Cancelar',
    confirm: 'Confirmar',
  },
  areYouSureYouWantToDeleteCategories:
    'Tem certeza de que deseja excluir as categorias abaixo:',
  hideCategory: 'Ocultar categoria',
  prerenderStatusCode: 'Pré-renderizar Código de status',
  prerenderHeader: 'Pré-renderizar cabeçalho - Localização:',
  prerenderStatusNote: `Código '301' para redirecionamento permanente. Código '302' para redirecionamento temporário`,
  contactToCreateCategory:
    'Entre em contato com a equipe de desenvolvimento para criar uma nova categoria',
};
