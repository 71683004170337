import styled from 'styled-components';

import { colors } from 'styleGuide';
import { T8y } from 'ui';

export const OrderTotalPrice = styled.div`
  margin-bottom: 36px;
`;

export const OrderTotalPriceSection = styled.div`
  padding: 18px 50px;
  border-radius: 5px;
  background: ${colors.positive};
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  & > *:not(:last-child) {
    &:first-child {
      margin-bottom: 14px;
    }
    margin-bottom: 6px;
  }
`;

export const PriceField = styled(T8y)`
  color: #2e2e2e;
`;

export const LoadingSection = styled.div`
  padding: 18px 50px;
  border-radius: 5px;
  background: ${colors.positive};
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
