import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { settings$ } from 'models/settings';

import Error from 'components/atoms/Error';
import * as ST from 'components/molecules/Charges/styles';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';

import { Row, T8y } from 'ui';

const AdditionalCharges = () => {
  const {
    product: { titles },
    form,
  } = Lang();

  const settings = useStore(settings$);

  const { register, setValue, watch, errors, getValues, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  useEffect(() => {
    register({ name: 'adminFeeFixedEnabled' });
    register({ name: 'adminFeeVatEnabled' });
    register({ name: 'safetyFeeEnabled' });
    register({ name: 'sanitationFeeEnabled' });
    register({ name: 'depositEnabled' });
    register({ name: 'depositToBeDefined' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const handleReset = useCallback(
    () =>
      reset({
        adminFeeFixed: settings.adminFeeFixed || 0,
        adminFeeFixedEnabled: settings.adminFeeFixedEnabled,
        adminFeeVat: settings.adminFeeVat || 0,
        adminFeeVatEnabled: settings.adminFeeVatEnabled,
        safetyFee: settings.safetyFee || 0,
        safetyFeeEnabled: settings.safetyFeeEnabled,
        sanitationFee: settings.sanitationFee || 0,
        sanitationFeeEnabled: settings.sanitationFeeEnabled,
        tax: settings.vat || 0,
        depositEnabled: settings.depositEnabled,
        depositToBeDefined: settings.depositToBeDefined,
        trainingCardDiscountValue: settings.trainingCardDiscountValue,
      }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings],
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <ProductEditorBlockWrapper blockTitle={titles.additionalCharges}>
      <ST.Wrapper>
        <ST.BlockWrapper>
          <ST.CheckboxWrapper
            label={form.checkbox.adminFeeFixed}
            ref={register}
            onChange={e => setValue('adminFeeFixedEnabled', e.target.checked)}
            checked={values?.adminFeeFixedEnabled}
            isBlocked
            $isPadding
          />
          <ST.InputsWrapper>
            <Row direction="column">
              <ST.InputWrapper>
                <ST.InsuranceInput
                  name="adminFeeFixed"
                  ref={register({
                    required:
                      values?.adminFeeFixedEnabled &&
                      form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      noNegative: value =>
                        value >= 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  width="80px"
                  readOnly
                />
              </ST.InputWrapper>
              <Error
                height="16px"
                message={get(errors, ['adminFeeFixed', 'message'])}
              />
            </Row>
          </ST.InputsWrapper>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.CheckboxWrapper
            label={form.checkbox.adminFeeVat}
            ref={register}
            onChange={e => setValue('adminFeeVatEnabled', e.target.checked)}
            $isPadding
            checked={values?.adminFeeVatEnabled}
            isBlocked
          />
          <ST.InputsWrapper>
            <Row direction="column">
              <ST.InputWrapper>
                <ST.InsuranceInput
                  name="adminFeeVat"
                  ref={register({
                    required:
                      values?.adminFeeVatEnabled &&
                      form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      noNegative: value =>
                        value >= 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  width="80px"
                  readOnly
                />
              </ST.InputWrapper>
              <Error
                height="16px"
                message={get(errors, ['adminFeeVat', 'message'])}
              />
            </Row>
          </ST.InputsWrapper>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.CheckboxWrapper
            label={form.checkbox.safetyFee}
            ref={register}
            onChange={e => setValue('safetyFeeEnabled', e.target.checked)}
            checked={values?.safetyFeeEnabled}
            $isPadding
            isBlocked
          />
          <ST.InputsWrapper>
            <Row direction="column">
              <ST.InputWrapper>
                <ST.InsuranceInput
                  name="safetyFee"
                  ref={register({
                    required:
                      values?.safetyFeeEnabled &&
                      form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      noNegative: value =>
                        value >= 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  width="80px"
                  readOnly
                />
                <ST.FieldTitle>{form.fields.feePerDayPerItem}</ST.FieldTitle>
              </ST.InputWrapper>
              <Error
                height="16px"
                message={get(errors, ['safetyFee', 'message'])}
              />
            </Row>
          </ST.InputsWrapper>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.CheckboxWrapper
            label={form.checkbox.sanitationFee}
            ref={register}
            onChange={e => setValue('sanitationFeeEnabled', e.target.checked)}
            checked={values?.sanitationFeeEnabled}
            $isPadding
            isBlocked
          />
          <ST.InputsWrapper>
            <Row direction="column">
              <ST.InputWrapper>
                <ST.InsuranceInput
                  name="sanitationFee"
                  ref={register({
                    required:
                      values?.safetyFeeEnabled &&
                      form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      noNegative: value =>
                        value >= 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  width="80px"
                  readOnly
                />
                <ST.FieldTitle>{form.fields.feePerItem}</ST.FieldTitle>
              </ST.InputWrapper>
              <Error
                height="16px"
                message={get(errors, ['sanitationFee', 'message'])}
              />
            </Row>
          </ST.InputsWrapper>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.FakeCheckbox>
            <T8y as="span">{form.vat}</T8y>
          </ST.FakeCheckbox>
          <ST.InputsWrapper>
            <Row direction="column">
              <ST.InputWrapper>
                <ST.InsuranceInput
                  name="tax"
                  ref={register({
                    required: form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      noNegative: value =>
                        value >= 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  width="80px"
                  readOnly
                />
              </ST.InputWrapper>
              <Error height="16px" message={get(errors, ['tax', 'message'])} />
            </Row>
          </ST.InputsWrapper>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.FakeCheckbox>
            <T8y as="span">{form.fields.trainingCardDiscountValue}</T8y>
          </ST.FakeCheckbox>
          <ST.InputsWrapper>
            <Row direction="column">
              <ST.InputWrapper>
                <ST.InsuranceInput
                  name="trainingCardDiscountValue"
                  ref={register({
                    required: form.notification.theFieldIsRequired,
                    valueAsNumber: true,
                    validate: {
                      noNegative: value =>
                        value >= 0 || form.notification.valueShouldBePositive,
                    },
                  })}
                  width="80px"
                  readOnly
                />
              </ST.InputWrapper>
              <Error
                height="16px"
                message={get(errors, ['trainingCardDiscountValue', 'message'])}
              />
            </Row>
          </ST.InputsWrapper>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.CheckboxWrapper
            label={form.checkbox.deposit}
            ref={register}
            onChange={e => setValue('depositEnabled', e.target.checked)}
            checked={values?.depositEnabled}
            $isPadding
            isBlocked
          />
          <ST.FieldTitle>{form.fields.amountPerItem}</ST.FieldTitle>
        </ST.BlockWrapper>
        <ST.BlockWrapper>
          <ST.CheckboxWrapper
            label={form.checkbox.depositToBeDefined}
            ref={register}
            onChange={e => setValue('depositToBeDefined', e.target.checked)}
            checked={values?.depositToBeDefined}
            $isPadding
            isBlocked
          />
        </ST.BlockWrapper>
      </ST.Wrapper>
    </ProductEditorBlockWrapper>
  );
};

export default AdditionalCharges;
