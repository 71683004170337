import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingsLocalized$ } from 'models/trainings';

import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import * as ST from './styles';

import { T8y } from 'ui';

const TrainingIndexFormCoursesList = () => {
  const { trainings } = Lang();

  const trainingsLocalized = useStore(trainingsLocalized$);
  const activeTrainings = useMemo(
    () => trainingsLocalized.filter(el => el.status),
    [trainingsLocalized],
  );

  return (
    <GeneralExpandableSection
      index={1}
      header={trainings.indexPage.exploreOurTrainingCourses}
      initialIsOpen
    >
      <ST.TrainingsListWrapper>
        {activeTrainings.map((el, index) => (
          <ST.TrainingWrapper key={index}>
            <ST.ImgWrapper>
              <img src={el.mainImage.src} alt={`Training ${index}`} />
            </ST.ImgWrapper>
            <ST.InfoWrapper>
              <T8y
                color="primary"
                fontSize="18px"
                lineHeight="25px"
                marginBottom="4px"
                bold
              >
                {el.title}
              </T8y>
              <T8y
                fontSize="14px"
                lineHeight="19px"
                dangerouslySetInnerHTML={{ __html: el.text }}
              />
            </ST.InfoWrapper>
          </ST.TrainingWrapper>
        ))}
      </ST.TrainingsListWrapper>
      <T8y fontSize="12px" color="gray" align="center">
        {trainings.indexPage.automaticallyGeneratedSection}
      </T8y>
    </GeneralExpandableSection>
  );
};

export default TrainingIndexFormCoursesList;
