import { AutoSizer } from 'react-virtualized';
import { List as VirtualizedList } from 'react-virtualized/dist/commonjs/List';

import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Cell, Icon } from 'ui';

export const FilterCell = styled(Cell)`
  width: 100%;
`;

export const FilterGrid = styled.div`
  position: relative;
  margin-top: 2.5rem;
  grid-gap: 10px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 4rem;
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  top: 0.75rem;
  left: 1.5rem;

  path {
    fill: ${colors.primary};
  }
`;

export const SearchInput = styled.input`
  outline: none;
  display: block;
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  padding: 0 2.5rem 0 5rem;
  margin: 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 0 0.3rem 0.3rem 0;
  font-size: 1.4rem;
  color: ${colors.inputColor};

  &::placeholder {
    font-size: 1.4rem;
    color: ${colors.textGray};
  }
`;

export const TableHeader = styled.div`
  padding: 3rem 0 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TableHeaderCell = styled(Cell)`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.textGray};
`;

export const TableBodyCell = styled(Cell)`
  font-size: 1.4rem;
  color: ${colors.inputColor};
`;

export const TableImage = styled.img`
  max-width: 3.7rem;
  max-height: 3.7rem;
`;

export const CheckboxCell = styled(Cell)`
  padding-left: 3rem;
`;

export const AutoSizerStyled = styled(AutoSizer)`
  outline: none;
`;

export const VirtualizedListStyled = styled(VirtualizedList)`
  outline: none;
`;

export const DisabledBlock = styled.div`
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
`;
