import React, { useEffect } from 'react';
import { Lang } from 'lang';
import { isUrl } from 'utils/patterns';

import Error from 'components/atoms/Error';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import * as ST from './styles';

import { Checkbox, Row, T8y } from 'ui';

const JobTypePrerender = ({
  errors,
  register,
  setValue,
  values,
  clearErrors,
}) => {
  const {
    formatString,
    marketing,
    categories,
    form: { notification },
  } = Lang();

  useEffect(() => {
    register({ name: 'hidden' });
  }, [register]);

  useEffect(() => {
    if (!values.hidden && clearErrors) {
      clearErrors(['prerenderStatusCode', 'prerenderHeaderLocation']);
    }
  }, [clearErrors, setValue, values.hidden]);

  useEffect(() => {
    if (!(values.prerenderStatusCode || '').startsWith('3')) {
      clearErrors('prerenderHeaderLocation');
    }
  }, [clearErrors, values.prerenderStatusCode]);

  return (
    <GeneralExpandableSection
      index={1}
      header={categories.titles.prerenderInfo}
      listOfFields={['prerenderStatusCode', 'prerenderHeaderLocation']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      <Row direction="column" noWrap>
        <Checkbox
          label={marketing.jobTypes.hideJob}
          onChange={e => setValue('hidden', e.target.checked)}
          checked={values.hidden}
        />
        <ST.PrerenderStatusCodeInputWrapper direction="column" fullWidth>
          <FieldStyle as={FieldStyle2} legend={categories.prerenderStatusCode}>
            <InputStyled
              name="prerenderStatusCode"
              type="number"
              ref={register({
                required: values.hidden && notification.theFieldIsRequired,
                validate: {
                  positive: value => {
                    if (!values.hidden) {
                      return true;
                    }

                    return (
                      value.length === 3 ||
                      formatString(notification.requiredLength, 3)
                    );
                  },
                },
              })}
              disabled={!values.hidden}
            />
          </FieldStyle>
          <Error
            message={
              errors &&
              errors.prerenderStatusCode &&
              errors.prerenderStatusCode.message
            }
          />
        </ST.PrerenderStatusCodeInputWrapper>
        <T8y marginTop="4px" color="gray" fontSize="12px">
          {categories.prerenderStatusNote}
        </T8y>
        <ST.PrerenderHeaderInputWrapper direction="column" fullWidth>
          <FieldStyle as={FieldStyle2} legend={categories.prerenderHeader}>
            <InputStyled
              name="prerenderHeaderLocation"
              type="text"
              ref={register({
                required:
                  (values.prerenderStatusCode || '').startsWith('3') &&
                  notification.theFieldIsRequired,
                validate: (values.prerenderStatusCode || '').startsWith(
                  '3',
                ) && {
                  url: value => isUrl(value) || notification.valueShouldBeValid,
                },
              })}
              disabled={!values.hidden}
            />
          </FieldStyle>
          <Error
            message={
              errors &&
              errors.prerenderHeaderLocation &&
              errors.prerenderHeaderLocation.message
            }
          />
        </ST.PrerenderHeaderInputWrapper>
      </Row>
    </GeneralExpandableSection>
  );
};

export default JobTypePrerender;
