import React from 'react';
import { useStore } from 'effector-react';
import { website$ } from 'models/website';

import LeadOrderInfo from 'components/molecules/LeadOrderInfo';
import LeadOrderInfoNew from 'components/molecules/LeadOrderInfoNew';
import OrderProducts from 'components/molecules/OrderProducts';
import { OrderSupplier } from 'components/molecules/OrderSupplier';
import OrderTotalPrice from 'components/molecules/OrderTotalPrice';
import * as ST from 'components/organisms/LeadProfile/styles';

const OrderProfile = ({
  order,
  products,
  isQuote,
  isTraining,
  userId,
  isUserPage,
}) => {
  const website = useStore(website$);

  const isETH = website.shortCompanyName === 'ETH';

  return (
    <ST.LeadProfileWrapper>
      <ST.OrderProfileLeftSide>
        {isETH ? (
          <LeadOrderInfoNew
            order={order}
            userId={userId}
            isQuote={isQuote}
            isTraining={isTraining}
            isUserPage={isUserPage}
          />
        ) : (
          <LeadOrderInfo
            order={order}
            userId={userId}
            isQuote={isQuote}
            isTraining={isTraining}
            isUserPage={isUserPage}
          />
        )}
        <OrderProducts products={products} isTraining={isTraining} />
      </ST.OrderProfileLeftSide>

      <ST.OrderProfileRightSide>
        <OrderTotalPrice order={order} isTraining={isTraining} />
        {isETH && order.type === 'product' && (
          <OrderSupplier
            orderId={order.orderId}
            orderType={order.type}
            orderSupplier={order.supplier?.name}
          />
        )}
      </ST.OrderProfileRightSide>
    </ST.LeadProfileWrapper>
  );
};

export default OrderProfile;
