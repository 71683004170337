import React from 'react';
import { Button, Checkbox as CheckboxR, useCheckboxState } from 'reakit';
import clsx from 'clsx';

import styled from 'styled-components';

import { Row, T8y } from 'ui';

import style from './checkbox.module.scss';

const CheckboxStyled = styled(Button)`
  outline: none;
`;

export const Checkbox = React.forwardRef(
  (
    {
      label,
      checked,
      onChange,
      policy,
      disabled,
      isBlocked,
      fontSize,
      ...otherProps
    },
    ref,
  ) => {
    const checkbox = useCheckboxState({ state: checked });

    return (
      <CheckboxR
        as={CheckboxStyled}
        checked={checked}
        onChange={!isBlocked && onChange}
        ref={ref}
        disabled={disabled}
        {...checkbox}
        {...otherProps}
      >
        <Row align="center" noWrap className={policy && style.policy}>
          <div
            className={clsx(
              style.checkbox,
              (disabled || isBlocked) && style.disabled,
            )}
          />
          <T8y
            align="left"
            as="span"
            color={disabled ? 'gray' : 'negative'}
            fontSize={fontSize || '16px'}
          >
            {label}
          </T8y>
        </Row>
      </CheckboxR>
    );
  },
);
