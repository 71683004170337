import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import CalendarHoliday from 'components/atoms/CalendarHoliday';

import GeneralExpandableSection from '../GeneralExpandableSection';

const CalendarHolidays = ({ control, errors, register, setValue, values }) => {
  const {
    common: { calendar },
  } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'blockedDatesList',
    keyName: 'formId',
  });

  return (
    <GeneralExpandableSection
      index={0}
      header={calendar.holidays}
      listOfFields={['blockedDatesList']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      {fields.map((el, index) => (
        <CalendarHoliday
          register={register}
          index={index}
          setValue={setValue}
          errors={errors}
          values={values}
          key={el.formId}
          remove={remove}
          el={el}
        />
      ))}
      <AddMore
        marginTop={fields.length > 0}
        fontSize="16px"
        onClick={() =>
          append({
            day: '',
            month: '',
            comment: '',
          })
        }
        text={calendar.addNewHoliday}
      />
    </GeneralExpandableSection>
  );
};

export default CalendarHolidays;
