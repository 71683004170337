import styled from 'styled-components';

import { Grid, T8y } from 'ui';

export const CustomGrid = styled(Grid)`
  grid-auto-rows: minmax(65px, auto);
`;

export const HeaderItem = styled(T8y)`
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  &:not(:last-child) {
    padding-right: 10px;
  }
`;

export const TableItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  height: 100%;
  border-bottom: 1px solid #dddddd;
  img {
    object-fit: contain;
  }
  &:not(:last-child) {
    padding-right: 10px;
  }
`;
