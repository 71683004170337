import React, { useState } from 'react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';

import ExpandableSection from 'components/atoms/ExpandableSection';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { Checkbox, T8y } from 'ui';

const TITLE_MAX_LENGTH = 64;

const TrainingSeoForm = ({ register, watch }) => {
  const {
    common,
    product: { titles },
    form: { fields },
    formatString,
    trainings,
  } = Lang();

  const [isRobotsEditingEnabled, setRobotsEditingEnabled] = useState(false);

  const titleRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'form.seoH1',
    TITLE_MAX_LENGTH,
  );

  return (
    <ExpandableSection index={0} header={titles.seo} initialIsOpen>
      <ST.SeoBody>
        <FieldStyle as={FieldStyle2} legend={fields.h1Tag}>
          <InputStyled
            name="form.seoH1"
            type="text"
            maxLength={TITLE_MAX_LENGTH}
            ref={register}
          />
        </FieldStyle>
        <T8y marginTop="4px" color="gray" fontSize="12px">
          {formatString(trainings.remainingCharacters, titleRemainingSymbols)}
        </T8y>
        <FieldStyle as={FieldStyle2} legend={fields.title} marginTop="1.5rem">
          <InputStyled name="form.metaTitle" type="text" ref={register} />
        </FieldStyle>
        <FieldStyle
          as={FieldStyle2}
          legend={fields.metaDescription}
          marginTop="1.5rem"
        >
          <InputStyled name="form.metaDescription" type="text" ref={register} />
        </FieldStyle>
        <ST.RobotsTagWrapper>
          <Checkbox
            label={common.enabledRobotsTag}
            ref={register}
            onChange={e => setRobotsEditingEnabled(e.target.checked)}
            checked={isRobotsEditingEnabled}
          />
          <FieldStyle
            as={FieldStyle2}
            legend={fields.robotsTag}
            marginTop="10px"
          >
            <InputStyled
              name="form.seoRobots"
              type="text"
              ref={register}
              asDisabled={!isRobotsEditingEnabled}
              readOnly={!isRobotsEditingEnabled}
            />
          </FieldStyle>
        </ST.RobotsTagWrapper>
        <FieldStyle
          as={FieldStyle2}
          legend={fields.description}
          marginTop="1.5rem"
        >
          <TextareaStyled
            name={`form.seoDescription`}
            rows="4"
            ref={register}
          />
        </FieldStyle>
      </ST.SeoBody>
    </ExpandableSection>
  );
};

export default TrainingSeoForm;
