import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row, T8y } from 'ui';

export const TabWrapper = styled(Link)`
  background: ${p => (p.$isActive ? colors.darkBlue : '#dde4e9')};
  border-radius: 5px;
  min-width: 160px;
  margin: 0 0 10px 10px;

  ${p =>
    p.$isDisabled &&
    `
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: ${colors.gray};
  `}
`;

export const TabText = styled(T8y)`
  cursor: pointer;
  margin: 0 1px;
  padding: 10px 30px;
  color: ${p => (p.$isActive ? colors.positive : colors.gray2)};
  text-align: center;
  font-weight: 700;

  ${p => p.$isDisabled && `color: ${colors.positive};`}
`;

export const TabsWrapper = styled(Row)`
  margin-bottom: ${p =>
    p.$marginBottom ? `calc(${p.$marginBottom} - 10px)` : '30px'};
`;
