import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { branding$ } from 'models/branding';
import { website$ } from 'models/website';

import { AccountDetails } from './AccountDetails';
import { Notification } from './Notification';
import { Search } from './Search';
import { selectLang } from './SelectLanguage';
import { SelectLanguage } from './SelectLanguage/SelectLanguage';
import { HeaderStyled, LogoCell, RegionName } from './styles';

import { Cell } from 'ui';

import { ReactComponent as Logo } from 'assets/images/icon/easyHire.svg';

export function Header() {
  const branding = useStore(branding$);
  const website = useStore(website$);

  const location = useLocation();

  useEffect(() => {
    if (branding?.defaultLanguage) {
      selectLang(branding.defaultLanguage);
    }
  }, [branding]);

  const isProductsPage = useMemo(() => {
    return (
      location.pathname.endsWith('products') ||
      location.pathname.endsWith('products/')
    );
  }, [location.pathname]);

  return (
    <HeaderStyled places="center stretch" cols="40fr 32.5rem auto auto 3fr">
      <LogoCell place="center start">
        <Logo />
        <RegionName fontSize="24px" color="primary" marginLeft="15px" bold>
          {website.regionName}
        </RegionName>
      </LogoCell>
      {isProductsPage ? <Search /> : <div />}
      <Notification />
      <AccountDetails />
      <Cell place="center end" justifySelf="center">
        <SelectLanguage />
      </Cell>
    </HeaderStyled>
  );
}
