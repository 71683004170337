import { DEFAULT_SELECTOR_STATE } from 'consts';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { getFormattedOrderProducts } from 'utils/getFormattedOrderProducts';
import { createDebounce } from 'utils/helpers/timeouts';

import { ORDERS } from 'api';

import { user$ } from './users';

const defaultFilters = {
  limit: 50,
  page: 1,
  selector: DEFAULT_SELECTOR_STATE,
  search: '',
};

export const loadOrdersFx = createEffect({
  handler: async params => {
    const userId = user$.getState()?.id;
    const res = await ORDERS.getOrders(userId, { ...params });

    const formattedOrders = res.data.data.map(el => ({
      ...el,
      products: getFormattedOrderProducts(el.products, el.type),
    }));

    return {
      orders: formattedOrders,
      pagination: { lastPage: res.data.last_page, total: res.data.total },
    };
  },
});

export const clearOrders = createEvent();
export const setOrdersFilter = createEvent();
export const setDefaultOrdersFilters = createEvent();
export const setOrdersSearchStr = createEvent();
export const changeOrdersTablePage = createEvent();
export const getUserOrders = createEvent();

export const setOrdersSearchStrDebounced = createDebounce(
  setOrdersSearchStr,
  100,
);

export const orders$ = createStore([])
  .on(loadOrdersFx.doneData, (s, p) => {
    return p.orders;
  })
  .on(clearOrders, () => []);

export const ordersPagination$ = createStore({}).on(
  loadOrdersFx.doneData,
  (s, p) => {
    return p.pagination;
  },
);

export const filters$ = createStore(defaultFilters)
  .on(setDefaultOrdersFilters, () => ({ ...defaultFilters }))
  .on(changeOrdersTablePage, (s, p) => ({ ...s, page: p }))
  .on(setOrdersSearchStrDebounced, (s, p) => ({ ...s, search: p, page: 1 }))
  .on(setOrdersFilter, (s, p) => ({ ...s, selector: p, page: 1 }));

forward({
  from: filters$.updates,
  to: loadOrdersFx,
});

forward({
  from: getUserOrders,
  to: loadOrdersFx,
});
