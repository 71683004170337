import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { settings$ } from 'models/settings';

import CustomButton from 'components/atoms/CustomButton';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, T8y } from 'ui';

const DeliveryUnableDeleteModal = ({
  disclosure,
  dialogState,
  deliveryName,
  handleModalClose,
  deliveryRelatedProductsList,
}) => {
  const {
    formatString,
    form: { buttons },
    settings: settingsLocalization,
    header: { table },
  } = Lang();

  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);

  return (
    <>
      <Modal
        disclosure={disclosure}
        title={settingsLocalization.modals.deleteMessageTitle}
        dialogState={dialogState}
        width="420px"
        overflow="hidden"
        backgroundColor={colors.primary}
        lazyRender
      >
        <T8y lineHeight="22px" marginTop="30px">
          {formatString(
            settingsLocalization.modals.deleteMessageText,
            <T8y lineHeight="22px" display="inline-block" bold>
              {deliveryName}
            </T8y>,
          )}
        </T8y>
        <ST.ProductsListWrapper>
          <ST.ProductHeaderWrapper>
            <ST.ProductCodeWrapper>{table.productCode}</ST.ProductCodeWrapper>
            <ST.ProductNameWrapper>{table.Name}</ST.ProductNameWrapper>
          </ST.ProductHeaderWrapper>
          {deliveryRelatedProductsList?.map(el => (
            <ST.ProductItemWrapper>
              <ST.ProductCodeWrapper>{el.id}</ST.ProductCodeWrapper>
              <ST.ProductNameWrapper>
                {el.names[currentLangState] ||
                  el.names[settings.defaultLanguage]}
              </ST.ProductNameWrapper>
            </ST.ProductItemWrapper>
          ))}
        </ST.ProductsListWrapper>
        <ST.DeliveryUnableDeleteModalControlsWrapper>
          <CustomButton
            text={buttons.okay}
            backgroundColor="positive"
            color="gray3"
            width="120px"
            height="29px"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            onClick={handleModalClose}
          />
        </ST.DeliveryUnableDeleteModalControlsWrapper>
      </Modal>
    </>
  );
};

export default DeliveryUnableDeleteModal;
