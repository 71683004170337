import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { integrations$ } from 'models/integrations';

import IntegrationsExternalIds from 'components/molecules/IntegrationsExternalIds';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';

import * as ST from './styles';

const ThirdParties = ({ register, values, setValue }) => {
  const {
    product: { titles, thirdPartyIntegrations },
    form,
  } = Lang();

  const integrations = useStore(integrations$);

  const headers = useMemo(
    () => [
      thirdPartyIntegrations.systemName,
      thirdPartyIntegrations.integrationStatus,
      thirdPartyIntegrations.externalProductId,
    ],
    [thirdPartyIntegrations],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={titles.thirdParty}>
      {!!integrations.length ? (
        <>
          <ST.IntegrationsWrapper>
            {headers.map((el, i) => (
              <ST.GridItem color="gray">{el}</ST.GridItem>
            ))}
            {integrations.map((integration, i) => (
              <>
                <ST.GridItem>{integration.systemName}</ST.GridItem>
                <ST.GridItem color={integration.active ? 'green' : 'danger'}>
                  {form[integration.active ? 'active' : 'inactive']}
                </ST.GridItem>
                <ST.GridItem>
                  <IntegrationsExternalIds
                    integration={integration}
                    register={register}
                    values={values}
                    setValue={setValue}
                  />
                </ST.GridItem>
              </>
            ))}
          </ST.IntegrationsWrapper>
          <ST.Note>{thirdPartyIntegrations.thirdPartiesNote}</ST.Note>
        </>
      ) : (
        <p>{thirdPartyIntegrations.noIntegration}</p>
      )}
    </ProductEditorBlockWrapper>
  );
};

export default ThirdParties;
