import React from 'react';
import { toast } from 'react-toastify';
import { useDialogState } from 'reakit';
import { Lang } from 'lang';
import { deleteProduct } from 'pages/model';

import { PRODUCTS } from 'api';

import { StyledDeleteButton, StyledIconWrapper } from '../styles';

import { Button, Grid, Modal, Row, T8y } from 'ui';

import { ReactComponent as Delete } from 'assets/icons/delete.svg';

export const DeleteProductBtn = ({ name, sku, id }) => {
  const dialog = useDialogState();

  const { formatString, header, form } = Lang();

  const deleteItem = async () => {
    try {
      await PRODUCTS.delete(id);
      toast(`Product ${name} was deleted`);
      deleteProduct(id);
    } catch {
      toast.error(`Product ${name} wasn't deleted`);
    } finally {
      dialog.hide();
    }
  };

  const disclosure = (
    <StyledDeleteButton>
      <StyledIconWrapper $dark>
        <Delete />
      </StyledIconWrapper>
    </StyledDeleteButton>
  );

  return (
    <Modal
      disclosure={disclosure}
      title={header.titles.confirmDeleting}
      dialogState={dialog}
      width={360}
      lazyRender
    >
      <Grid gaps="2rem 0">
        <T8y variant="t2" color="darkBlue">
          {formatString(form.descriptions.areYouSureToDelete, name, sku)}
        </T8y>
        <Row justify="space">
          <Button small onClick={dialog.hide} inverse>
            {form.buttons.cancel}
          </Button>
          <Button small onClick={deleteItem}>
            {form.buttons.delete}
          </Button>
        </Row>
      </Grid>
    </Modal>
  );
};
