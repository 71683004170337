export const form = {
  buttons: {
    saveChanges: 'Guardar cambios',
    cancel: 'Cancelar',
    close: 'Cerrar',
    continue: 'Continuar',
    addMore: 'Agregar',
    addSelected: 'Agregar seleccionados',
    addCategory: 'Agregar categoría',
    createProduct: 'Crear producto',
    update: 'Actualizar',
    create: 'Crear',
    createNew: 'Crear nuevo',
    saveAndView: 'Guardar y visualizar',
    save: 'Guardar',
    publish: 'Publicar',
    add: 'Agregar',
    adding: 'Agregando',
    editing: 'Editando',
    edit: 'Editar',
    delete: 'Borrar',
    browse: 'Buscar',
    logIn: 'Ingresar',
    createCopy: 'Crear una copia',
    addNewReport: 'Agregar nuevo reporte',
    createNewOrder: 'Crear un nuevo pedido',
    okay: 'Okay',
    addNewDeliveryType: 'Agregar nuevo tipo de entrega',
    addLinkToSelectedText: 'agregar enlace al texto seleccionado',
    removeLinkFromSelectedText: 'Eliminar enlace del texto seleccionado',
    connect: 'Conectar',
    deleteId: 'Borrar ID',
    addProductId: 'Agregar ID del producto',
    logInAsUser: 'Acceder',
    submit: 'Submit',
  },

  links: {
    addMore: 'Agregar',
    createNew: 'Crear uno nuevo',
    goToAllCategories: 'Ir a todas las categorías',
  },

  fields: {
    username: 'Usuario',
    password: 'Contraseña',
    label: 'Etiqueta',
    category: 'Categoría',
    minPeriod: 'Período mínimo de alquiler',
    avgMarketPriceSave: 'Descuento sobre precio promedio del mercado',
    subCategory: 'Sub categoría',
    subSubCategory: 'Sub sub categoría',
    maxQuantity: 'Cantidad máxima',
    popularity: 'Popularidad',
    date: 'Fecha',
    show: 'Mostrar',
    showAll: 'Mostrar todos',
    all: 'Todos',
    deliveryType: 'Tipo de entrega',
    title: 'Título',
    titleGb: 'Título inglés',
    titleIt: 'Título italiano',
    sku: 'Sku',
    description: 'Descripción',
    productUrl: 'URL del producto',
    feature: 'Características',
    rangeInDays: 'Intervalo de días',
    pricePerDay: 'Precio por día',
    pricePerRange: 'Precio, por franja',
    outOfRangeRation: 'Out of range ratio (per day), %',
    outOfRangePrice: 'Precio fuera de rango, por día',
    individualUser: 'Usuario individual',
    companyUser: 'Usuario compañía',
    feePerDayPerItem: 'Tarifa por día por ítem',
    feePerItem: 'Tarifa por ítem',
    feePerOrder: 'Fee per Order',
    amountPerItem: ' Cantidad por ítem',
    metaDescription: 'Meta Descripción',
    metaCanonical: 'Meta Canonical',
    h1Tag: 'Etiqueta',
    robotsTag: 'Etiqueta de robots',
    parentCategory: 'Categoría padre',
    deposit: 'Fianza',
    measurements: 'Medidas',
    capacity: 'Capacidad',
    weight: 'Peso',
    craneCapacity: 'Capacidad de la grúa',
    power: 'Alimentación',
    maxHeight: 'Peso máximo',
    maxWorkingHeight: 'Peso máximo de trabajo',
    holeDiameter: 'Diámetro del orificio',
    notes: 'Notas',
    diskDiameter: 'Diámetro del disco',
    cuttingDepth: 'Profundidad de corte',
    cuttingWidth: 'Ancho de corte',
    excavationDepth: 'Profundidad de excavación',
    question: 'Pregunta',
    answer: 'Respuesta',
    name: 'Nombre',
    location: 'Ubicación',
    rating: 'Valoración',
    review: 'Opinión',
    productCode: 'Código de producto',
    photo: 'Foto',
    price: 'Precio',
    tag: 'Etiqueta',
    search: 'Buscar',
    specification: 'Especificación',
    specificationName: 'nombre',
    specificationVaValue: 'valor',
    startTyping: 'Comienza a escribir para filtrar reportes',
    reportsAreFound: 'reportes encontrados',
    maxLoadCapacity: 'Capacidad máxima de carga en kg',
    startPrice: 'Precio inicial',
    minPrice: 'Precio mínimo',
    pricePerKm: 'Precio por 1 km',
    pricePerWay: '*Precio por viaje',
    ReportYear: 'Reporte Año / Mes',
    reportTitle: 'Título del reporte',
    reviewTitle: 'Título de la opinión',
    marginOfDays: 'Margen de días antes del alquiler',
    checkoutAvailable: 'Disponible para el pago',
    checkoutNotAvailable: 'No disponible para el pago',
    sectionDescription: 'Descripción de la sección (opcional)',
    visual: 'Visual',
    productTitle: 'Título del producto',
    tax: 'Tax',
    trainingCardDiscountValue: 'Training Card Discount Value',
    drafts: 'Borradores',
    priceWithoutVat: 'Precio sin IVA',
    fullName: 'Nombre y apellidos',
    emailAddress: 'Dirección de correo electrónico',
    PhoneNumber: 'Número de teléfono',
    companyName: 'Nombre de empresa',
    vatNumber: 'IVA',
    outOfStock: 'Sin stock',
    unavailable: 'No disponible',
    googleMapLink: 'Link Google maps',
    openingTimes: 'Horario de apertura',
    latitude: 'Latitud',
    longitude: 'Longitud',
    perDay: 'Por dia',
    perWeek: 'por semana',
  },

  defaultText: {
    selectCategory: 'Seleccionar categoría',
    selectSubCategory: 'Seleccionar sub categoría',
    selectSubSubCategory: 'Seleccionar sub sub categoría',
    selectDeliveryType: 'Seleccionar tipo de entrega',
    noParent: 'Sin padre',
    enterDeliveryTypeTitle: 'Ingresar tipo de entrega',
  },

  checkbox: {
    insurance: 'Seguro',
    safetyFee: 'Tarifa de seguridad',
    sanitationFee: 'Tarifa de sanitización',
    retailItem: 'Artículo al por menor',
    controlledBy: 'controlado por la configuración regional "fixedRangesPrice"',
    hideProduct: 'Ocultar producto en el catálogo',
    showProductVideo: 'Mostrar video del producto',
    gasTank: 'Depósito de combustible',
    showCarbon: 'Mostrar badge Carbon Neutral',
    adminFeeFixed: 'Gestión de residuos Fixed',
    adminFeeVat: 'Gestión de residuos Vat',
    wasteManagementFee: 'Gestión de residuos',
    deposit: 'Fianza',
    depositToBeDefined: 'Deposit To Be Defined',
    deliveryWithoutVat: 'Delivery Without Vat',
    showAsOutOfStock: 'Mostrar el producto como “No disponible”',
    idVerification: 'ID verification',
    damageWaiver: 'Exención de daños',
  },

  radio: {
    file: 'Archivo',
  },

  title: {
    loginIn: 'Por favor identificate',
  },

  toastTitle: {
    thelastImage: 'La última imagen no puede ser eliminada',
    onlyImagesCan: 'Sólo pueden agregarse  {0} imágenes',
    successfullyUpdated: 'Actualizado con éxito',
    successfullyCreated: 'Creado con éxito',
    successfullyDeleted: 'Eliminado con éxito',
    successfullyApproved: 'Aprobado con éxito',
    successfullyDeclined: 'Denegado con éxito',
    successfullyRequest: 'Tu solicitud ha sido enviada con éxito',
    createError: 'Error al crear',
    deleteError: 'Error al eliminar',
    updateError: 'Error al actualizar',
    creating: 'Creando',
    updating: 'Actualizando',
    wasUpdated: 'fue actualizado',
    wasCreated: 'fue creado',
    extraCantBeCreated: 'No pueden agregarse extras',
    onlySeveralFiledCanBeUpdated: 'Pueden agregarse solamente {0} archivos',
    invalidForm: 'No todos los campos se han rellenado correctamente',
    minProducts: 'El trabajo tiene que tener 4 productos como mínimo',
    wentWrong: 'Algo salió mal',
  },

  notification: {
    valueShouldBeUnique: 'El valor ha de ser único',
    valueShouldBePositive: 'El valor debe ser un número positivo',
    valueShouldBeBetween: 'El valor ha de ser entre {0} y {1}',
    valueShouldBeValid: 'El valor ha de ser un URL válido',
    shouldBeInsideOneOfTheRanges:
      'El valor debe estar dentro de uno de los rangos existentes',
    theFieldIsRequired: 'Este campo es requerido',
    theLastSlideImage: 'La última imagen del slide no puede ser eliminada',
    theBiggestRange: 'El intervalo de días no puede ser mayor',
    notAvailableArea: 'Área no está disponible para entrega',
    notAvailable: 'No disponible',
    loading: 'Subiendo..',
    notFound: 'ID no encontrado',
    chooseCorrectAddress: 'Por favor elige la dirección correcta de esta lista',
    rangeDaysMustBe: 'Range in days must be 1-7 or 3-7',
    phonePatter: 'Número de teléfono incorrecto',
    minLength: 'Mín. {0} caracteres',
    emailPattern: 'Dirección de correo errónea',
    passwordLength: 'Password must have at least 6 characters',
    passwordDifferent: 'Passwords are different',
    maxLength: 'Max. {0} caracteres',
    requiredLength: 'Se requieren {0} caracteres',
    minValue: 'Mín. {0}',
    firstShouldBeLessSecond: 'El primer valor debe ser inferior a un segundo',
    valueAlreadyPresents: 'El valor ya esta siendo usado',
    rangeShouldNotOverlapOthers: 'El rango no debe superponerse a otros rangos',
    availableOnly:
      'Available only if there is a range ending in “7" is present',
  },

  descriptions: {
    enterVideoUrl: 'Ingresa URL del video en Youtube o Vimeo',
    filesAsMax: '{0} archivos máx',
    areYouSureToDelete:
      '¿Estás seguro de querer eliminar el producto {0} con sku {1}?',
    popularMaxReached: 'Límite máximo de producto popular alcanzado',
  },

  placeholders: {
    startTyping: 'Empieza a escribir...',
    enterProductId: 'Ingresa el ID del producto y haz click en Conectar',
    enterTitle: 'Escribe el título (máx. {0} caracteres)',
  },

  active: 'Activo',
  inactive: 'Inactivo',
  vat: 'IVA',

  calendar: {
    selectHirePeriod: 'Seleccionar período de alquiler',
    previous: 'Anterior',
    reset: 'Restablecer los valores predeterminados',
    next: 'Próximo',
    dayPlaceholder: 'DD',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'AAAA',
  },

  prevPage: 'Previa',
  nextPage: 'Siguiente',
  disabledOnRegionalLevel: 'desactivado a nivel regional',
  extra: 'Extra',
  enterDepotName: 'Insertar nombre de la delegación',
  depotOwner: 'Propietario de la delegación',
};
