export const trainings = {
  title: 'Training Management',
  subTitle: 'Manage training index page and training courses',
  trainingIndexPage: 'Training Index Page',
  trainingsCourses: 'Training Courses',
  addNewCourse: 'Add New Course',
  closestDate: 'Closest Date',
  showSection: 'Show Section',
  remainingCharacters: '{0} characters remaining',
  heroImage: 'Hero Image',
  heroImageOrientation: 'JPG image {0} x {1} pixels, landscape orientation',
  thumbnailImage: 'Thumbnail Image',
  thumbnailImageOrientation:
    'JPG image 600 x 450 pixels, landscape orientation',
  yes: 'Yes',
  no: 'No',

  indexPage: {
    title: 'Training Index Page',
    generalInformation: 'General Information',
    introductorySection: 'Introductory Section',
    exploreOurTrainingCourses: 'Explore our training courses',
    pageTitle: 'Page Title',
    introductoryText: 'Introductory Text',
    automaticallyGeneratedSection:
      'This section is generated automatically using the courses you create',
    sectionTitle: 'Section Title (e.g: Training at {0}, etc.)',
  },

  courseStatus: 'Course Status',
  courseTitle: 'Course Title',
  courseShortDescription: 'Short Course Description',
  courseDescription: 'Course Description',
  courseLearning: 'What you will learn',
  courseWhyChooseUs: 'Why Choose Us',
  courseReviews: 'Course Reviews',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  theoryAndPractice: 'Theory and Practice Hours (e.g: 4h Theory, 8h Practice)',
  courseGallery: 'Course Gallery',
  galleryImageRequirements:
    'File requirements: JPG image 800 x 600 pixels, landscape orientation',
  benefitImageRequirements: 'JPG or PNG image, 120 x 120 pixels',
  scheduleCourses: 'Scheduled Courses',
  availabilityAndSchedule: 'Checkout Availability and Schedule',
  courseIsAvailable: 'Course is available for checkout',
  courseIsAvailableText:
    'Users will be able to make a reservation online using simple checkout process.',
  courseIsNotAvailable: 'Course is not available for checkout',
  courseIsNotAvailableText:
    'Users will be able to send a request using simple contact form.',
  courseLocation: 'Course Location',
  courseOrganizer: 'Course Organizer (optional)',
  datesAndPrice: 'Scheduled course dates and price',
  startDate: 'Start Date',
  endDate: 'End Date (optional)',
  coursePrice: 'Course Price',
  addNewDate: 'Add New Date',
  addNewLocation: 'Add New Location',
  addNewVariation: 'Add New Variation',
  addNewBenefit: 'Add New Benefit',
  addNewReview: 'Add New Review',
  addNewFAQ: 'Add New FAQ',
  addNewProduct: 'Add product',
  scheduleDescription:
    'Schedule Description (e.g: Schedule is available upon request, etc)',
  courseLocations: 'Course Location(s)',
  priceExample: '(e.g: €200.00 + VAT)',
  partnership: 'Course is made available in partnership with',
  partnershipImageRequirements:
    'File requirements: JPG, PNG or SVG image, NN x NN pixels',
  closestDateAutomaticallyGenerated:
    'This section is created automatically using course data you provide in other sections',
  aboutCertificate: 'About Certificate',
  certificateImage:
    'Certificate Image  (JPG image 600 x 400 pixels, landscape orientation)',
  emptyDates: 'Dates TBD',
  emptyLocation: 'Course Organizer and Location(s) TBD',
  emptyPrice: 'Price TBD',
  emptyTheory: 'Theory and Practice Hours TBD',
  schedule: 'Schedule (dates and prices)',
  noSchedule: 'No schedule (price only)',
  courseVariationTitle: 'Course Variation Title (optional)',

  recommendedProducts: 'Recommended Products',
  benefits: {
    enterBenefitTitle: 'Enter Benefit Title',
    icon: 'Icon',
    shortTitle: 'Short Title',
    details: 'Benefit Details',
  },

  reviews: {
    enterReviewFields: 'Enter Review Fields',
    photo: 'Photo',
    review: 'Review',
    fullName: 'Full Name (e.g: John Smith)',
    position: 'Position (e.g: Stage Engineer at SomeWhere) (optional)',
  },

  faq: {
    enterFaq: 'Enter Question',
    question: 'Question',
    answer: 'Answer',
  },

  enterCourseTitle: 'Enter Course Title',
};
