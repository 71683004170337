import styled from 'styled-components';

import { colors } from 'styleGuide';

export const PopularProductsGrid = styled.div`
  margin-bottom: 22px;
`;

export const PopularProductsBody = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 0.2rem;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 1.4rem;
  color: ${colors.primary};
  margin-bottom: 4.7rem;

  ${p =>
    p.flexDirection &&
    `
    flex-direction: ${p.flexDirection};
  `}
`;

export const AddProductsWrapper = styled.div`
  position: relative;

  & > div {
    bottom: -2rem !important;
    right: 0 !important;
    transform: none !important;
    inset: 2rem auto auto 10rem !important;
  }
`;

export const LimitedProductsWrapper = styled.div`
  background-color: rgba(33, 33, 33, 0.9);
  padding: 0.8rem;
  border-radius: 0.4rem;
`;
