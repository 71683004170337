import styled from 'styled-components';

import { colors } from 'styleGuide';

export const DeliveryUnableDeleteModalControlsWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
`;

export const ProductsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 20px;
`;

export const ProductHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  color: #b3b7bb;
  font-size: 15px;
  font-weight: 400;
  font-family: 'FuturaBT-Book', Helvetica, Arial, sans-serif;
`;

export const ProductItemWrapper = styled.div`
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.lightGray};
    margin-bottom: 5px;
  }
`;

export const ProductCodeWrapper = styled.div`
  flex-basis: 40%;
`;

export const ProductNameWrapper = styled.div`
  flex-basis: 60%;
`;
