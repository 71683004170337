import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import * as productModel from 'models/product';

import { v4 as uuid } from 'uuid';

const EMPTY_EXTERNAL_ITEM = {
  key: null,
  id: null,
  isConnected: false,
  error: null,
};

export const useManageIdsData = (
  integration,
  getIdInfo,
  connectId,
  disconnectId,
  dialog,
) => {
  const {
    form,
    product: { thirdPartyIntegrations },
  } = Lang();

  const product = useStore(productModel.productStore);

  const [externalIds, setExternalIds] = useState([
    { ...EMPTY_EXTERNAL_ITEM, key: uuid() },
  ]);
  const [currentId, setCurrentId] = useState(null);
  const [integrationInfoResponse, setIntegrationInfoResponse] = useState(null);

  useEffect(() => {
    if (!product.integrations?.length) {
      return;
    }

    const newIds = product.integrations.find(el => el.id === integration.id);

    if (!newIds?.externalIds.length) {
      return;
    }

    const initialProductIntegrations = newIds.externalIds.map(el => ({
      id: el,
      key: uuid(),
      isConnected: true,
      isInitial: true,
    }));

    setExternalIds([...initialProductIntegrations]);
  }, [integration.id, product.integrations]);

  const handleConnectClick = useCallback(() => {
    if (!integrationInfoResponse) {
      dialog.hide();
    }

    connectId(integrationInfoResponse.no);
    const newIds = [...externalIds];
    newIds[integrationInfoResponse.externalIndex].isConnected = true;
    newIds[integrationInfoResponse.externalIndex].error = null;
    setExternalIds(newIds);
    setIntegrationInfoResponse(null);
    dialog.hide();
  }, [connectId, dialog, externalIds, integrationInfoResponse]);

  const handleCloseClick = useCallback(() => {
    const newIds = [...externalIds];
    newIds[integrationInfoResponse?.externalIndex].id = '';
    setExternalIds(newIds);

    setIntegrationInfoResponse(null);
    dialog.hide();
  }, [dialog, externalIds, integrationInfoResponse]);

  const handleAddClick = useCallback(() => {
    const lastElem = externalIds[externalIds.length - 1];

    if (lastElem && (!lastElem.id || !lastElem.isConnected)) {
      const newIds = [...externalIds];
      newIds[externalIds.length - 1].error = !lastElem.id
        ? form.notification.theFieldIsRequired
        : thirdPartyIntegrations.idIsNotConnected;
      setExternalIds(newIds);
      return;
    }

    setExternalIds(ids => [...ids, { ...EMPTY_EXTERNAL_ITEM, key: uuid() }]);
  }, [
    externalIds,
    form.notification.theFieldIsRequired,
    thirdPartyIntegrations,
  ]);

  const removeExternalId = useCallback(
    (el, index) => {
      disconnectId(el);

      const newIds = [...externalIds];
      newIds.splice(index, 1);
      setExternalIds([...newIds]);
    },
    [disconnectId, externalIds],
  );

  const handleConnectOrRemoveIntegration = useCallback(
    async (el, index) => {
      if (el.error) {
        return;
      }

      if (el.isConnected) {
        removeExternalId(el, index);
        return;
      }

      const sameId = externalIds.find(
        existsEl => existsEl.id === el.id && existsEl.isConnected,
      );

      if (!el.id?.trim() || sameId) {
        const newIds = [...externalIds];
        newIds[index].error =
          thirdPartyIntegrations[
            sameId ? 'idIsAlreadyConnected' : 'fieldIsEmpty'
          ];
        setExternalIds(newIds);
        return;
      }

      setCurrentId(el.id);
      const data = await getIdInfo(el.id);

      if (data) {
        setIntegrationInfoResponse({ ...data, externalIndex: index });
        dialog.show();
      }
    },
    [dialog, externalIds, getIdInfo, removeExternalId, thirdPartyIntegrations],
  );

  const handleInputChange = useCallback(
    (e, el, index) => {
      const value = e.target.value.trim();
      const newIds = [...externalIds];
      newIds[index] = {
        ...newIds[index],
        error: null,
        id: value,
      };

      setExternalIds(newIds);
    },
    [externalIds],
  );

  return useMemo(
    () => ({
      handleInputChange,
      handleConnectOrRemoveIntegration,
      handleConnectClick,
      handleAddClick,
      currentId,
      handleCloseClick,
      integrationInfoResponse,
      externalIds,
    }),
    [
      currentId,
      handleAddClick,
      handleCloseClick,
      handleConnectClick,
      handleConnectOrRemoveIntegration,
      handleInputChange,
      integrationInfoResponse,
      externalIds,
    ],
  );
};
