import React from 'react';
import { NavLink } from 'react-router-dom';
import { useList } from 'effector-react';
import { categoriesTreeStore } from 'models/categories';

import styled from 'styled-components';

import { PATHS } from '../../AppPaths';

import { Row, T8y } from 'ui';

const Container = styled.div`
  width: 100%;
  max-width: 119.8rem;
  margin: 4rem auto;
  padding: 0 1.5rem;
`;

export function Error() {
  return (
    <Container>
      <Row direction="column" align="center">
        <T8y color="primary" variant="h1" className="mt-5 mb-4">
          404 Page Not Found
        </T8y>
        <T8y variant="t1" color="silver" className="mb-5" center>
          Sorry, at this moment, we are unable to find the page you are looking
          for. 
          <br />
          Please try one of the links below or go to our{' '}
          <T8y variant="t1" color="primary" as={NavLink} to="/">
            home page
          </T8y>
          .
        </T8y>
      </Row>

      <Row justify="space" className="mt-3">
        {useList(categoriesTreeStore, ({ slug, name, childs }) => (
          <Row direction="column" key={slug}>
            <NavLink to={PATHS.CATEGORY(slug)} activeClassName="active">
              <T8y variant="t1" color="primary" className="mb-3" bold>
                {name}
              </T8y>
            </NavLink>

            {childs.length ? (
              childs.map(subCategory => (
                <NavLink
                  to={PATHS.CATEGORY(subCategory.slug)}
                  key={subCategory.slug}
                >
                  <T8y variant="t1" color="primary" className="mb-2" capitalize>
                    {subCategory?.name?.toLowerCase()}
                  </T8y>
                </NavLink>
              ))
            ) : (
              <>
                <T8y
                  as={NavLink}
                  variant="t1"
                  color="primary"
                  className="mb-2"
                  to={PATHS.ABOUT}
                >
                  About us
                </T8y>
                <T8y
                  as={NavLink}
                  variant="t1"
                  color="primary"
                  className="mb-2"
                  to={PATHS.ABOUT}
                >
                  Blog
                </T8y>
                <T8y
                  as={NavLink}
                  variant="t1"
                  color="primary"
                  className="mb-2"
                  to={PATHS.ABOUT}
                >
                  FAQ
                </T8y>
              </>
            )}
          </Row>
        ))}
      </Row>
    </Container>
  );
}
