import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Lang } from 'lang';

import Error from 'components/atoms/Error';
import {
  FieldStyle,
  FieldWithMargin,
  InputStyled,
} from 'components/Product/shared';
import { TRADE_ACCOUNTS } from 'api';

import * as ST from './styles';
import { useManageHitsListVisibility } from './useManageHitsListVisibility';
import { useManageInputState } from './useManageInputState';

const TwoField = ({
  register,
  values,
  errors,
  clearErrors,
  setValue,
  setIsTwoSelect,
  setError,
  changeTwoCompanyAddress,
  disabled,
  twoCompanyAddress,
}) => {
  const { form, users } = Lang();

  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [searchRequestError, setSearchRequestError] = useState(null);

  const postcodeListBlockRef = useRef(null);
  const inputRef = useRef(null);

  //clear errors to trigger formState.isValid
  useEffect(() => {
    return () => {
      clearErrors('account.name');
      clearErrors('account.identifier');
      setValue('account.identifier', '');
      setValue('account.name', '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEmptyResult = useCallback(() => {
    setValue('account.identifier', '', { shouldValidate: true });
    changeTwoCompanyAddress(null);
  }, [setValue, changeTwoCompanyAddress]);

  const {
    makeListVisible,
    setIsListVisible,
    isListVisible,
  } = useManageHitsListVisibility({
    inputRef,
    listRef: postcodeListBlockRef,
  });

  const {
    handleInputType,
    currValue,
    currValueError,
    setCurrValue,
    handleInputFocus,
  } = useManageInputState({
    initialValue: '',
    setIsListVisible,
    makeListVisible,
    searchRequestError,
    setEmptyResult,
  });

  const getCompanies = useCallback(
    async companyName => {
      if (companyName.length < 3) {
        setCompanies([]);
        return;
      }

      try {
        const { data } = await TRADE_ACCOUNTS.searchCompany({
          q: companyName,
        });

        clearErrors('account.name');
        setCompanies(data.data.items);
        setIsLoading(false);
        setIsListVisible(true);
      } catch (e) {
        setSearchRequestError(true);
        setError('account.name', form.toastTitle.wentWrong);
      }
    },
    [clearErrors, form, setError, setIsListVisible],
  );

  const getAddress = async companyId => {
    try {
      const { data } = await axios.get(
        `https://api.sandbox.two.inc/v1/GB/company/${companyId}/address`,
      );

      if (data.address.postalCode) {
        changeTwoCompanyAddress({
          city: data.address.city,
          postal_code: data.address.postalCode,
          street_address: data.address.streetAddress,
        });
      }
    } catch (e) {
      setSearchRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      values.account?.identifier &&
      values.account.name &&
      !twoCompanyAddress
    ) {
      getAddress(values.account.identifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.account?.identifier]);

  useEffect(() => {
    if (!currValueError && currValue && !values.account?.identifier) {
      setIsLoading(true);
      getCompanies(currValue);
    } else {
      setCompanies([]);
      setIsListVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currValue]);

  const handleCompanyClick = el => {
    setValue('account.name', el.name);
    setValue('account.identifier', el.id, { shouldValidate: true });
    setIsListVisible(false);
    setCurrValue(el.id);
  };

  return (
    <ST.TwoFieldWrapper>
      <FieldStyle as={FieldWithMargin} legend={form.fields.companyName}>
        <input
          type="hidden"
          name="account.identifier"
          value={values.account?.identifier || ''}
          ref={register({
            required: 'Please choose a company from the list',
          })}
        />
        <InputStyled
          name="account.name"
          ref={register({
            required: form.notification.theFieldIsRequired,
          })}
          onChange={handleInputType}
          onFocus={handleInputFocus}
          defaultValue={values?.account?.name}
          disabled={disabled}
          autoComplete="off"
        />
      </FieldStyle>
      <Error
        message={
          currValueError ||
          searchRequestError ||
          errors.account?.identifier?.message
        }
      />
      {isListVisible && !disabled && (
        <ST.CompanyListBlock ref={postcodeListBlockRef}>
          <ST.CompanyListWrapper>
            <ST.CompanyList>
              {values.account?.name.length >= 3 &&
                !companies.length &&
                !isLoading && <ST.CompanyItem>{users.noItems}</ST.CompanyItem>}
              {companies.map((el, i) => (
                <ST.CompanyItem
                  key={el.id}
                  onClick={() =>
                    isLoading ? undefined : handleCompanyClick(el)
                  }
                  data-testid={`company-item-${i}`}
                >
                  <ST.SummaryText>
                    <ST.CompanyName>{el.name}</ST.CompanyName>
                    <ST.OptionId>&nbsp;({el.id})</ST.OptionId>
                  </ST.SummaryText>
                </ST.CompanyItem>
              ))}
            </ST.CompanyList>
            <ST.ListFooter
              color="primary"
              onClick={() => {
                setCurrValue(null);
                setCompanies([]);
                setIsListVisible(false);
                setIsTwoSelect(false);
              }}
              variant="t2v1"
              pointer
              bold
            >
              My company is not on the list
            </ST.ListFooter>
          </ST.CompanyListWrapper>
        </ST.CompanyListBlock>
      )}
    </ST.TwoFieldWrapper>
  );
};

export default TwoField;
