import {
  combine,
  createEffect,
  createEvent,
  createStore,
  guard,
} from 'effector';
import { currentProductOwner$ } from 'pages/model';

import { WAREHOUSES } from 'api';

export const getWarehouses = createEvent();
export const updateWarehouse = createEvent();
export const addNewWarehouse = createEvent();
export const deleteWarehouse = createEvent();

export const getWarehousesFx = createEffect({
  handler: async () => {
    const { data } = await WAREHOUSES.getAllWarehouses();

    return data.data || [];
  },
});

export const warehouses$ = createStore([])
  .on(getWarehousesFx.doneData, (_, p) => p)
  .on(updateWarehouse, (s, { index, data }) => {
    const newWarehouses = [...s];
    newWarehouses[index] = data.data;
    return newWarehouses;
  })
  .on(addNewWarehouse, (s, p) => {
    const newWarehouses = [...s];
    newWarehouses.push(p.data);
    return newWarehouses;
  })
  .on(deleteWarehouse, (s, p) => {
    return s.filter(el => el.id.toString() !== p.toString());
  });

export const availableWarehouses$ = combine(
  warehouses$,
  currentProductOwner$,
  (warehouses, currentProductOwner) =>
    warehouses.filter(
      el => el.franchiseeId?.toString() === currentProductOwner?.id?.toString(),
    ),
);

export const isWarehousesLoading$ = createStore(false)
  .on(getWarehousesFx, () => true)
  .on(getWarehousesFx.done, () => false)
  .on(getWarehousesFx.fail, () => false);

export const isWarehousesNotLoaded$ = createStore(true)
  .on(getWarehousesFx, () => false)
  .on(getWarehousesFx.done, () => false)
  .on(getWarehousesFx.fail, () => true);

guard({
  source: getWarehouses,
  filter: isWarehousesNotLoaded$,
  target: getWarehousesFx,
});
