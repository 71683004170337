import React from 'react';
import { Link } from 'react-router-dom';
import { Lang } from 'lang';

import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Field, Row, T8y } from 'ui';

import { ReactComponent as Plus } from 'assets/images/icon/plus.svg';

export const IconStyled = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

export const doNothing = e => {
  e.preventDefault();
};

export const submitForm = () => {
  return true;
};

export const generateURL = string => {
  if (!string) {
    return null;
  }

  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/&/g, '') // Replace &
    .replace(/@/g, 'at') // Replace @ with 'at'
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const FieldStyle = styled.div`
  width: ${props => props.width || '100%'};
  outline: none;
  position: relative;

  ${p => p.height && ` height: ${p.height};`}
  ${p => p.marginTop && `margin-top: ${p.marginTop};`}
  ${p => p.marginRight && `margin-right: ${p.marginRight};`}
  ${p => p.marginBottom && `margin-bottom: ${p.marginBottom};`}
`;

export const FieldStyle2 = styled(Field)`
  ${p => p.width && `width:${p.width}`};
  ${p => p.marginRight && `margin-right: ${p.marginRight};`}
  ${p => p.marginTop && `margin-top: ${p.marginTop};`}

  span {
    font-weight: 500;
    font-size: ${props => props.fontSize || '1.4rem'};
    color: #b3b7bb;
    line-height: ${props => props.fontSize || '1.4rem'};
  }

  & > div {
    border: none;

    ${p =>
      p.withBorder &&
      `
      border: 1px solid ${colors.lightGray};
      border-radius: 3px;
    `}
  }
`;

export const FieldStyle3 = styled(Field)`
  span {
    font-weight: 500;
    font-size: 1.4rem;
    color: #b3b7bb;
  }

  div {
    border: none;

    ${p =>
      p.withBorder &&
      `
      border: 1px solid ${colors.lightGray};
      border-radius: 0.3rem 0 0 0.3rem;
    `}
  }
`;

export const InputStyled = styled.input`
  display: block;
  width: ${props => props.width || '100%'};
  height: 4.4rem;
  padding: 0 1.5rem;
  margin: 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 0.3rem;
  outline: none;
  line-height: 4.4rem;
  text-align: ${props => props.textAlign || 'left'};
  color: ${colors.negative};
  font-size: 1.4rem;

  &::placeholder {
    color: ${colors.gray};
  }

  ${p =>
    p.paddingLeft &&
    `
    padding-left: ${p.paddingLeft};
  `}

  ${p =>
    p.paddingRight &&
    `
    padding-right: ${p.paddingRight};
  `}

  ${p =>
    (p.disabled || p.asDisabled) &&
    `
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: ${colors.disableGray};
    border: 1px solid #E4E8ED;
    color: ${colors.gray};
  `}
  
  ${p =>
    p.isHideNumberArrows &&
    `
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type=number] {
      -moz-appearance: textfield;
    }
  `}
  
   ${p =>
     p.isOverflow &&
     `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: noWrap;
  `}

   &:read-only {
    color: #b3b7bb;
    background-color: #fbfbfb;
    cursor: not-allowed;
    border: 1px solid #e4e8ed;
`;

export const InputNumberStyled = styled(InputStyled)`
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  ${p =>
    p.asDisabled &&
    `
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: ${colors.disableGray};
    border: 1px solid #E4E8ED;
    color: ${colors.gray};
  `}
`;

export const TextareaStyled = styled.textarea`
  display: block;
  width: ${props => props.width || '100%'};
  height: 12rem;
  padding: 1.5rem;
  margin: 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 0.3rem;
  outline: none;
  line-height: 1.2;
  text-align: left;
  color: ${colors.negative};
  font-size: 1.4rem;
  resize: none;

  ${p =>
    p.height &&
    `
    height: ${p.height};
  `}

  &::placeholder {
    color: ${colors.gray};
  }
`;

export const Full = styled.div`
  width: 100%;
  margin-top: ${props => (props.marginTop ? '2rem' : 0)};
  margin-bottom: ${props => (props.marginBottom ? '3rem' : 0)};
`;

export const BlockRounded = styled.div`
  width: 100%;
  margin-top: ${props => (props.marginTop ? '2rem' : 0)};
  margin-bottom: ${props => (props.marginBottom ? '3rem' : 0)};
  padding: 2rem 2rem;
  background: #fff;
  border-radius: 0.5rem;
`;

export const MarginTop = styled.div`
  margin-top: 1rem;
`;

const MorePosition = styled.div`
  margin-top: 3rem;
`;

export const HalfField = styled.div`
  position: relative;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 1rem;
`;

export const FieldWithMargin = styled(FieldStyle2)`
  margin-bottom: 5px;
`;

export const LinkBack = styled(Link)`
  &:active,
  &:visited,
  &:hover,
  &:link {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.primary};
    text-decoration: none;
  }
`;

export const More = ({ marginTop, onClick }) => {
  const {
    form: { links },
  } = Lang();

  return (
    <T8y
      asLink
      color="primary"
      onClick={onClick ? onClick : doNothing}
      cursor="pointer"
    >
      <Row align="center" inline as={marginTop ? MorePosition : 'div'}>
        <IconStyled as={Plus} />
        {links.addMore}
      </Row>
    </T8y>
  );
};

export const HiddenInput = styled.input`
  visibility: hidden;
`;
