import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  changeLeadsTablePage,
  filters$,
  getLeads,
  leads$,
  leadsGate,
  leadsPagination$,
} from 'models/leads';
import { getFormattedStringDate } from 'utils/dates';

import NewTable from 'components/molecules/NewTable';

import EmailField from './EmailField';
import LeadsListFilters from './LeadsListFilters';

import { Row, TablePaging } from 'ui';

const keyFields = [
  { name: 'dateCreated', type: 'text' },
  { name: 'leadType', type: 'text' },
  { name: 'fullName', type: 'text' },
  { name: 'companyName', type: 'text' },
  { name: 'emailAddress' },
  { name: 'paymentMethod', type: 'text' },
  { name: 'paymentStatus', type: 'text' },
  { name: 'CRMDealStage', type: 'text' },
  { name: 'link', type: 'link' },
];

const LeadList = () => {
  const { leads, users, orders } = Lang();

  const leadsFilters = useStore(filters$);

  useGate(leadsGate, leadsFilters);

  const leadsPagination = useStore(leadsPagination$);
  const leadsList = useStore(leads$);

  useEffect(() => {
    const interval = setInterval(() => {
      getLeads({ ...leadsFilters, isAutoUpdate: true });
    }, 300000);
    return () => clearInterval(interval);
  }, [leadsFilters]);

  const headers = useMemo(
    () => [
      {
        name: leads.tableHeaders.dateCreated,
        type: 'text',
      },
      {
        name: leads.tableHeaders.leadType,
        type: 'text',
      },
      {
        name: leads.tableHeaders.fullName,
        type: 'text',
      },
      {
        name: leads.tableHeaders.companyName,
        type: 'text',
      },
      {
        name: leads.tableHeaders.emailAddress,
        type: 'text',
      },
      { name: orders.orderTableHeaders.paymentMethod, type: 'text' },
      {
        name: orders.paymentStatus,
        type: 'text',
      },
      {
        name: leads.tableHeaders.CRMDealStage,
        type: 'text',
      },
      { name: '' },
    ],
    [leads, orders],
  );

  const getPaymentStatus = useCallback(
    (isPaid, paymentMethod) => {
      if (paymentMethod !== 'card') {
        return '-';
      }
      return isPaid ? orders.statusType.paid : orders.statusType.not_paid;
    },
    [orders],
  );

  const modifiedDisplayedLeads = useMemo(
    () =>
      leadsList.map(el => ({
        dateCreated: getFormattedStringDate(el.created_at),
        leadType: leads.types[el.type] || el.type,
        fullName: el.full_name,
        companyName: el.company_name,
        emailAddress: <EmailField email={el.email} />,
        paymentMethod: orders.paymentType[el.paymentMethod] || el.paymentMethod,
        paymentStatus: getPaymentStatus(el.isPaid, el.paymentMethod),
        CRMDealStage: el.crmStageStatus || '-',
        link: <Link to={PATHS.LEAD(el.id, el.type)}>{users.viewProfile}</Link>,
        isActive: el.isActive,
      })),
    [getPaymentStatus, leads, leadsList, orders, users],
  );

  return (
    <>
      <LeadsListFilters leadsAvailable={leadsPagination.total} />
      <NewTable
        headers={headers}
        keyFields={keyFields}
        items={modifiedDisplayedLeads}
        isSortByActive
      />
      {leadsPagination.lastPage > 1 && (
        <Row justify="center">
          <TablePaging
            handlePageChange={changeLeadsTablePage}
            totalPages={leadsPagination.lastPage}
            currPage={leadsFilters.page}
            isScrollToTop
          />
        </Row>
      )}
    </>
  );
};

export default LeadList;
