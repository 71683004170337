const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (err) {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
};

export const copyText = (text, pressCopy) => {
  const copiedRes = fallbackCopyTextToClipboard(text);
  if (!copiedRes) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    const isCopied = prompt(`${pressCopy} ${copyHotkey}`, text);
    return isCopied || false;
  }

  return true;
};
