import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import { INTEGRATIONS } from 'api';

export const useManageIdsConnection = (
  register,
  setValue,
  integration,
  values,
) => {
  const {
    form: { toastTitle, notification },
  } = Lang();

  const [isProcessingConnection, setIsProcessingConnection] = useState(false);

  useEffect(() => {
    register('productIntegrations');
    setValue('productIntegrations', []);
  }, [register, setValue]);

  const getIdInfo = useCallback(
    async id => {
      setIsProcessingConnection(true);

      try {
        const { data } = await INTEGRATIONS.findIntegrationInfoById(id);
        return data;
      } catch (e) {
        const navMessage = e.response?.data?.message;

        if (navMessage) {
          return {
            error: notification[navMessage],
          };
        } else {
          toast.error(toastTitle.wentWrong);
          return null;
        }
      } finally {
        setIsProcessingConnection(false);
      }
    },
    [notification, toastTitle.wentWrong],
  );

  const connectId = useCallback(
    id => {
      setIsProcessingConnection(true);
      const currentIntegrations = [...values.productIntegrations] || [];

      const currentIntegrationIndex = currentIntegrations.findIndex(
        el => el.integrationId === integration.id,
      );

      let currentIntegration = {
        integrationId: integration.id,
        productExternalIds: [],
        deletedProductExternalIds: [],
      };

      if (currentIntegrationIndex >= 0) {
        currentIntegration = currentIntegrations[currentIntegrationIndex];
      }

      currentIntegration.productExternalIds = [
        ...currentIntegration.productExternalIds,
        id,
      ];

      currentIntegrations[
        currentIntegrationIndex >= 0 ? currentIntegrationIndex : 0
      ] = currentIntegration;

      setValue('productIntegrations', [...currentIntegrations]);
      setIsProcessingConnection(false);
      return null;
    },
    [integration.id, setValue, values.productIntegrations],
  );

  const disconnectId = useCallback(
    elForDisconnect => {
      setIsProcessingConnection(true);
      const currentIntegrations = [...values.productIntegrations] || [];

      const currentIntegrationIndex = currentIntegrations.findIndex(
        el => el.integrationId === integration.id,
      );

      let currentIntegration = {
        integrationId: integration.id,
        productExternalIds: [],
        deletedProductExternalIds: [],
      };

      if (currentIntegrationIndex >= 0) {
        currentIntegration = currentIntegrations[currentIntegrationIndex];
      }

      if (elForDisconnect.isInitial) {
        currentIntegration.deletedProductExternalIds = [
          ...currentIntegration.deletedProductExternalIds,
          elForDisconnect.id,
        ];
      } else {
        currentIntegration.productExternalIds = [
          ...currentIntegration.productExternalIds.filter(
            el => el !== elForDisconnect.id,
          ),
        ];
      }

      currentIntegrations[
        currentIntegrationIndex >= 0 ? currentIntegrationIndex : 0
      ] = currentIntegration;

      setValue('productIntegrations', [...currentIntegrations]);
      setIsProcessingConnection(false);
      return true;
    },
    [integration.id, setValue, values.productIntegrations],
  );

  return useMemo(
    () => ({
      getIdInfo,
      connectId,
      disconnectId,
      isProcessingConnection,
    }),
    [connectId, disconnectId, getIdInfo, isProcessingConnection],
  );
};
