export const leads = {
  subTitle: 'Monitora tutti i leads del sito Web in tempo reale',
  backToAllLeads: "Torna all'elenco dei leads",
  sourcePage: 'Pagina di origine',
  leadsAvailable: '{0} leads disponibili',
  hideTestLeads: 'Nascondi i test leads',
  viewSourcePage: ' Visualizza la pagina di origine',
  comment: 'Messaggio',
  message: 'Messaggio',
  crmDeal: 'CRM Deal',
  crmContact: 'CRM Contatto',
  callTo: 'Chiama',
  customerTax: 'Codice Fiscale',
  billingAddress: 'Billing Address',
  press: 'Clicca',
  emailIsCopied: "L'email è stata copiata nelle tue note",
  shuftiVerificationStatus: 'Shufti Pro verification status',
  shuftiVerificationLink: 'Shufti Pro verification link',
  yes: 'Si',
  no: 'No',
  stripePaymentLink: 'Stripe collegamento di pagamento',

  userTypes: {
    individual: 'Privato',
    company: 'Azienda',
  },

  leadInfoText: {
    enquiry:
      '{0} ha inoltrato questa richiesta ed è in attesa di essere contattato.',
    callback:
      '{0} ha richiesto una chiamata ed è in attesa di essere contattato.',
    trainingEnquiry:
      '{0} ha inoltrato questa richiesta su un corso di formazione ed è in attesa di essere contattato.',
  },

  tableHeaders: {
    dateCreated: 'Data di creazione',
    leadType: 'Tipo di lead',
    leadSource: 'Origine del lead',
    fullName: 'Nome completo',
    companyName: "Nome dell'Azienda",
    phoneNumber: 'Numero di telefono',
    emailAddress: 'Indirizzo email',
    CRMDealStage: 'CRM Deal Stage',
  },

  types: {
    all: 'Tutto',
    enquiry: 'Richiesta ',
    callback: 'Richiesta di chiamata',
    trainingEnquiry: 'Richiesta di corso di formazione',
    product: 'Prodotto',
    quote: 'Preventivo',
    trainingOrder: 'Ordine di formazione',
    training: 'Ordine di formazione',
    checkoutStarted: 'Il checkout è’ iniziato',
    newsletterSubscribe: 'Newsletter',
  },
};
