export const shortAddresses = [
  //Scotland
  {
    count: 1,
    id: '127477',
    locationsummary: 'Tillybrig, Dunecht, Westhill, Aberdeenshire, AB32 7BE',
    summaryline: 'Tillybrig Cottage',
    type: 'ADD',
    postcode: 'AB32 7BE',
  },
  {
    count: 1,
    id: '18348335',
    locationsummary: 'Renfrew, Renfrewshire',
    summaryline: 'PO Box 9764, On Ink Ltd, PA4 8YE',
    type: 'ADD',
    postcode: 'PA4 8YE',
  },

  {
    count: 1,
    id: '55120908',
    locationsummary: 'Glasgow, G43 1DR',
    summaryline: '28 Penny Crescent',
    type: 'ADD',
    postcode: 'G43 1DR',
  },

  //London
  {
    count: 1,
    id: '17753067',
    locationsummary: 'Hammers Lane, London, Barnet, NW7 4DJ',
    summaryline: 'Rookfield',
    type: 'ADD',
    postcode: 'NW7 4DJ',
  },

  //North East
  {
    count: 1,
    id: '15761422',
    locationsummary:
      'Chevy Chase, Intu Eldon Square, Newcastle Upon Tyne, Tyne and Wear, NE1 7XP',
    summaryline: 'Lush, 1B',
    type: 'ADD',
    postcode: 'NE1 7XP',
  },

  //North West
  {
    count: 1,
    id: '50561018',
    locationsummary: 'Lancaster, Lancashire, LA1 2NQ',
    summaryline: '1 Barley Cop Lane',
    type: 'ADD',
    postcode: 'LA1 2NQ',
  },

  //Yorkshire
  {
    count: 1,
    id: '50561019',
    locationsummary: 'Some test location, LS2 8BY',
    summaryline: '1 Barley Cop Lane',
    type: 'ADD',
    postcode: 'LS2 8BY',
  },
];

export const fullAddresses = [
  //Scotland
  {
    id: '127477',
    addressline1: 'Tillybrig Cottage',
    addressline2: 'Tillybrig, Dunecht',
    buildingname: 'Tillybrig Cottage',
    county: 'Aberdeenshire',
    dependentlocality: 'Dunecht',
    latitude: '57.173855462',
    longitude: '-2.4169009427',
    postalcounty: 'Aberdeenshire',
    postcode: 'AB32 7BE',
    posttown: 'Westhill',
    premise: 'Tillybrig Cottage',
    sortcode: '31074',
    street: 'Tillybrig',
    summaryline:
      'Tillybrig Cottage, Tillybrig, Dunecht, Westhill, Aberdeenshire, AB32 7BE',
  },
  {
    id: '18348335',
    addressline1: 'On Ink Ltd',
    addressline2: 'PO Box 9764',
    county: 'Renfrewshire',
    latitude: '55.8797284791',
    longitude: '-4.385754611',
    organisation: 'On Ink Ltd',
    pobox: 'PO Box 9764',
    postcode: 'PA4 8YE',
    posttown: 'Renfrew',
    premise: 'PO Box 9764',
    sortcode: '30963',
    summaryline: 'On Ink Ltd, PO Box 9764, Renfrew, Renfrewshire, PA4 8YE',
  },

  {
    id: '55120908',
    addressline1: '28 Penny Crescent',
    summaryline: '28 Penny Crescent, Glasgow, City of Glasgow, G43 1DR',
    number: '28',
    premise: '28',
    street: 'Penny Crescent',
    posttown: 'Glasgow',
    county: 'City of Glasgow',
    postcode: 'G43 1DR',
    latitude: '55.821693683',
    longitude: '-4.3338582005',
    sortcode: '30681',
  },

  //London
  {
    id: '17753067',
    addressline1: 'Rookfield',
    addressline2: 'Hammers Lane',
    buildingname: 'Rookfield',
    county: 'Greater London',
    latitude: '51.6187892268',
    longitude: '-0.2361825965',
    postcode: 'NW7 4DJ',
    posttown: 'London',
    premise: 'Rookfield',
    sortcode: '37584',
    street: 'Hammers Lane',
    summaryline: 'Rookfield, Hammers Lane, London, Greater London, NW7 4DJ',
  },

  //North East
  {
    id: '15761422',
    addressline1: 'Lush, 1B Chevy Chase',
    addressline2: 'Intu Eldon Square',
    latitude: '54.97493392',
    longitude: '-1.6153093874',
    postcode: 'NE1 7XP',
    posttown: 'Newcastle Upon Tyne',
    premise: '1B',
    sortcode: '32970',
    street: 'Chevy Chase',
    summaryline:
      'Lush, 1B Chevy Chase, Intu Eldon Square, Newcastle Upon Tyne, Tyne and Wear, NE1 7XP',
  },

  //North West
  {
    id: '50561018',
    addressline1: '1 Barley Cop Lane',
    latitude: '54.0607974737',
    longitude: '-2.8025089444',
    number: '1',
    postcode: 'LA1 2NQ',
    posttown: 'Lancaster',
    premise: '1',
    sortcode: '32085',
    street: 'Barley Cop Lane',
    summaryline: '1 Barley Cop Lane, Lancaster, Lancashire, LA1 2NQ',
  },

  //Yorkshire
  {
    id: '50561019',
    addressline1: '1 Barley Cop Lane',
    latitude: '53.802981',
    longitude: '-1.541843',
    number: '1',
    postcode: 'LS2 8BY',
    posttown: 'Leeds',
    premise: '1',
    sortcode: '32056',
    street: 'Some street',
    summaryline: 'some summary, LS2 8BY',
  },
];
