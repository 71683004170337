import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { COUNTRIES, getApiVersion } from 'consts';
import { Lang } from 'lang';
import { validateEmailReg } from 'utils/validationEmail';

import CustomButton from 'components/atoms/CustomButton';
import Error from 'components/atoms/Error';
import { AUTH } from 'api';

import * as ST from './styles';

import { Field, inputStyle, Select, T8y } from 'ui';

import { ReactComponent as NameIcon } from 'assets/images/icon/user_name.svg';

export const ForgotPassword = ({ history }) => {
  const { form, common } = Lang();

  const [loading, setLoading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const onSubmit = useCallback(
    async ({ email, country }) => {
      setLoading(true);
      const apiVersion = getApiVersion(country);

      try {
        const res = await AUTH.forgot({
          email,
          country,
          apiVersion,
        });

        if (res) {
          setIsMessageSent(true);
        }
      } catch (e) {
        if (e.response?.data?.slug === 'incorrectEmail') {
          toast.error(form.notification.emailPattern);
        } else {
          toast.error(form.toastTitle.wentWrong);
        }
      } finally {
        setLoading(false);
      }
    },
    [form.notification.emailPattern, form.toastTitle.wentWrong],
  );

  return (
    <ST.PageWrapper>
      <ST.PageTitle>{common.forgotPassword.customerHeader}</ST.PageTitle>
      <ST.FormWrapper>
        {!isMessageSent ? (
          <ST.Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <ST.FormTitleWrapper>
                {common.forgotPassword.subHeader}
              </ST.FormTitleWrapper>
              <div>
                <Field legend={form.fields.emailAddress} icon={NameIcon}>
                  <input
                    ref={register({
                      required: form.notification.theFieldIsRequired,
                      pattern: {
                        value: validateEmailReg,
                        message: form.notification.emailPattern,
                      },
                    })}
                    name="email"
                    placeholder={form.fields.emailAddress}
                    className={inputStyle}
                  />
                </Field>
                <Error message={errors?.email?.message} />
              </div>
              <div>
                <Field legend="Country">
                  <input
                    type="hidden"
                    name="country"
                    ref={register({
                      validate: value =>
                        !!value?.trim() || form.notification.theFieldIsRequired,
                    })}
                    value={values.country}
                  />
                  <Select
                    options={COUNTRIES}
                    selected={
                      COUNTRIES.find(el => el.slug === values.country)?.name
                    }
                    defaultText="Select country"
                    aLabel="Country"
                    onClickOption={val => {
                      setValue('country', val);
                      clearErrors('country');
                    }}
                    className={inputStyle}
                    maxHeight="300px"
                  />
                </Field>
                <Error message={errors?.country?.message} />
              </div>
            </div>
            <ST.FormButtonsWrapper>
              <CustomButton
                text={form.buttons.cancel}
                onClick={() => history.push('login')}
                inverse
                loading={loading}
                borderRadius="0"
              />
              <CustomButton
                isLoading={loading}
                text={form.buttons.submit}
                type="submit"
                isActive
                backgroundColor="darkPrimary"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                borderRadius="0"
              />
            </ST.FormButtonsWrapper>
          </ST.Form>
        ) : (
          <T8y justify="center" align="center" color="primary">
            {common.forgotPassword.sent}
          </T8y>
        )}
      </ST.FormWrapper>
    </ST.PageWrapper>
  );
};
