import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Lang } from 'lang';
import { getItemStyle, getListStyle } from 'utils/dndStyles';

import ChosenProductsItem from 'components/atoms/ChosenProductsItem';

import * as ST from './styles';

import { Cell, Grid } from 'ui';

const ChosenProductsList = ({
  allProductsList,
  arrayName,
  chosenProductsIdsList,
  register,
  remove,
  isDraggable,
  onDragEnd,
}) => {
  const {
    form: { fields },
  } = Lang();

  if (!chosenProductsIdsList.length || !allProductsList.length) {
    return null;
  }

  return (
    <ST.TableWrapper>
      <Grid
        places="center start"
        cols={`${isDraggable ? '1fr ' : ''}2fr 2fr 3fr 2fr 1fr`}
        gaps="1rem 1rem"
        as={ST.TableHeader}
      >
        {isDraggable && <ST.TableHeaderCell />}
        <ST.TableHeaderCell>{fields.productCode}</ST.TableHeaderCell>
        <ST.TableHeaderCell>{fields.visual}</ST.TableHeaderCell>
        <ST.TableHeaderCell>{fields.name}</ST.TableHeaderCell>
        <ST.TableHeaderCell place="center end">
          {fields.price}
        </ST.TableHeaderCell>
        <Cell />
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {chosenProductsIdsList.map((el, index) => {
                return (
                  <Draggable
                    key={el.id}
                    draggableId={el.id.toString()}
                    index={el.orderId || index}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps?.style,
                    )}
                    isDragDisabled={!isDraggable}
                  >
                    {provided => (
                      <ChosenProductsItem
                        allProductsList={allProductsList}
                        remove={remove}
                        index={index}
                        register={register}
                        arrayName={arrayName}
                        currentId={el.id}
                        productId={el.productId}
                        provided={provided}
                        isDraggable={isDraggable}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ST.TableWrapper>
  );
};

export default ChosenProductsList;
