import React from 'react';
import { Lang } from 'lang';

import { Icon } from '../../Product/Icon';
import { InputStyled } from '../../Product/shared';
import Error from '../Error';
import InputWithCurrencySign from '../InputWithCurrencySign';
import TrainingFormLine from '../TrainingFormLine';
import * as ST from './styles';

const NotCheckoutAvailablePrice = ({
  register,
  priceIndex,
  errors,
  remove,
  values,
}) => {
  const { form, formatString } = Lang();

  return (
    <TrainingFormLine>
      <ST.PriceWrapper>
        <ST.VariationWrapper>
          <InputStyled
            name={`form.noSchedule.variations[${priceIndex}].title`}
            type="text"
            ref={register()}
            maxLength={64}
            placeholder={formatString(form.placeholders.enterTitle, 30)}
            defaultValue={
              values.form?.noSchedule?.variations?.[priceIndex]?.title || null
            }
          />
        </ST.VariationWrapper>
        <ST.CoursePriceWrapper>
          <ST.PriceField>
            <InputWithCurrencySign
              type="number"
              width="150px"
              step="0.01"
              paddingLeft="3rem"
              name={`form.noSchedule.variations[${priceIndex}].price`}
              innerRef={register({
                valueAsNumber: true,
                validate: {
                  positive: value => {
                    if (!value && value !== 0) {
                      return true;
                    }

                    return value > 0 || form.notification.valueShouldBePositive;
                  },
                },
              })}
              defaultValue={
                values.form?.noSchedule?.variations?.[priceIndex]?.price || null
              }
            />
            <Error
              message={
                errors.form?.noSchedule?.variations?.[priceIndex]?.price
                  ?.message
              }
            />
          </ST.PriceField>
          <ST.VatWrapper>+ {form.vat}</ST.VatWrapper>
        </ST.CoursePriceWrapper>
        {priceIndex > 0 && (
          <Icon
            type="delete"
            color="gray"
            handleClick={() => remove(priceIndex)}
          />
        )}
      </ST.PriceWrapper>
    </TrainingFormLine>
  );
};

export default NotCheckoutAvailablePrice;
