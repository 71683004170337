import styled from 'styled-components';

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 165px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 13px;
  right: 13px;
`;
