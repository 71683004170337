export const common = {
  dropZoneErrors: {
    'file-too-large': 'O tamanho da imagem deve ser inferior a {0} MB',
  },
  enabledRobotsTag: 'Ativar a edição do campo Robots Tag',

  forgotPassword: {
    header: 'Esqueceu sua senha?',
    customerHeader: 'Painel Administrativo Esqueceu a Senha',
    subHeader:
      'Digite o endereço de e-mail que você usou para se inscrever e enviaremos um link de redefinição de senha',
    emailFieldTitle: 'Endereço de e-mail',
    emailFieldPlaceholder: 'Insira o seu endereço de email',
    goToLogin: 'Vá para a página de login, lembrei-me da minha senha',
    submit: 'Enviar',
    sent: 'A mensagem foi enviada. Por favor verifique seu email.',
    cancel: 'Cancelar',
  },

  resetPassword: {
    header: 'Redefinição de senha',
    customerHeader: 'Redefinição de senha do painel de administração',
    subHeader: 'Por favor digite sua nova senha abaixo',
    emailAddressTitle: 'Endereço de e-mail',
    passwordFieldTitle: 'Nova Senha',
    passwordFieldPlaceholder: 'Coloque sua senha',
    repeatPasswordFieldTitle: 'Repita a nova senha',
    repeatPasswordFieldPlaceholder: 'Digite sua senha novamente',
    submit: 'Enviar',
    saveChanges: 'Salvar alterações',
  },

  historyTable: {
    title: 'Registro',
    revisionNo: 'Revisão No',
    modifiedAt: 'Modificado em',
    modifiedBy: 'Modificado por',
    viewChanges: 'Ver alterações',
    changes: 'Alterações',
    previousVersion: 'Versão anterior',
    currentVersion: 'Versão Atual',
    expandLines: 'Expandir {0} linhas',
  },
  draft: 'Esboço',
  calendar: {
    weekDays: {
      monday: 'Segunda-feira',
      tuesday: 'Terça-feira',
      wednesday: 'Quarta-feira',
      thursday: 'Quinta-feira',
      friday: 'Sexta-feira',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    general: 'Geral',
    timezone: 'Fuso horário',
    defaultHirePeriod: 'Período de contratação padrão (dias)',
    sameDayDelivery: 'Entrega no mesmo dia',
    disableSameDayDelivery: 'Disable same day delivery',
    holidays: 'Férias',
    month: 'Mês',
    comment: 'Comente (opcional)',
    selectDay: 'Selecione o dia',
    selectMonth: 'Selecione o mês',
    addNewHoliday: 'Adicionar novo feriado',
    weekends: 'Finais de semana',
    deliverySelfCollection: 'Para Entrega e Auto-coleta',
    tickDays:
      'Defina os dias a serem marcados no calendário como indisponíveis para as datas de início ou término do aluguer',
    nextDayBehaviour: 'Configuração do dia seguinte',
    dayOfWeek: 'Dia da semana',
    offset: 'Compensação (dias)',
    enterNumber: 'Insira numero',
    addNewBehaviour: 'Adicionar novo comportamento',
    sameDayHire: 'Aluguel no mesmo dia',
    allowSetToday: 'Permite definir "hoje" como data de início da contratação',
    till: 'até',
  },
};
