import React from 'react';
import { Lang } from 'lang';

import { OrderInfoBlock } from 'components/atoms/OrderInfoBlock/styles';
import { OrderInfoRow } from 'components/atoms/OrderInfoRow/styles';
import UploadDocumentsSection from 'components/molecules/UploadDocumentsSection';

const OrderDocuments = ({
  order,
  deleteDocument,
  addDocument,
  isAddFilesBlocked,
  isAddInvoiceBlocked,
}) => {
  const { orders } = Lang();

  return (
    <OrderInfoRow>
      <OrderInfoBlock withTopBorder short>
        <UploadDocumentsSection
          docs={order.docs}
          title={orders.documents}
          deleteDocumentFromOrder={deleteDocument}
          addDocumentToOrder={acceptedFiles =>
            addDocument(acceptedFiles, 'docs')
          }
          isAddFilesBlocked={isAddFilesBlocked}
        />
      </OrderInfoBlock>
      <OrderInfoBlock withTopBorder short>
        <UploadDocumentsSection
          docs={order.invoice && [order.invoice]}
          title={orders.invoice}
          deleteDocumentFromOrder={deleteDocument}
          addDocumentToOrder={acceptedFiles =>
            addDocument(acceptedFiles, 'invoice')
          }
          isAddFilesHidden={isAddInvoiceBlocked}
        />
      </OrderInfoBlock>
    </OrderInfoRow>
  );
};

export default OrderDocuments;
