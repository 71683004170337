import React, { useCallback } from 'react';
import { Radio as RadioR } from 'reakit/Radio';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import style from './radio.module.scss';

export const Radio = React.forwardRef(
  (
    {
      size = 1,
      id,
      onClick,
      text,
      value = '',
      className,
      disabled,
      children,
      ...rest
    },
    ref,
  ) => {
    const handleClick = useCallback(
      e => {
        if (!disabled && onClick) {
          onClick(e, value);
        }
      },
      [disabled, onClick, value],
    );

    return (
      <Row
        align="center"
        as="label"
        className={clsx(style.label, className)}
        htmlFor={id}
        onClick={handleClick}
        noWrap
      >
        <RadioR id={id} {...rest} value={value} ref={ref} disabled={disabled} />
        <div
          className={clsx(style.button, {
            [style.buttonActive]: rest.state === value,
            [style.disabled]: disabled,
          })}
        />
        {text && (
          <T8y fontSize="14px" lineHeight="20px" disabled={disabled}>
            {text}
          </T8y>
        )}
        {children}
      </Row>
    );
  },
);
