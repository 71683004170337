import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import get from 'lodash/get';
import { branding$ } from 'models/branding';
import { settings$ } from 'models/settings';

import Error from 'components/atoms/Error';
import InputWithCurrencySign from 'components/atoms/InputWithCurrencySign';
import PriceWithAdditionalText from 'components/atoms/PriceWithAdditionalText';

import * as ST from './styles';

import { Row, Spinner } from 'ui';

const OrderTotalPrice = ({
  order,
  isTraining,
  isOffer,
  register,
  errors,
  isLoading,
}) => {
  const { orders, form } = Lang();

  const settings = useStore(settings$);
  const branding = useStore(branding$);

  const deliveryPrice = useMemo(() => {
    if (!order) {
      return 0;
    }

    switch (order.deliveryType) {
      case 'self-collection':
        return orders.costSummary.free;
      case 'tbd-delivery':
        return orders.costSummary.tbd;
      default:
        return `${branding.currencySymbol}${order.totalDelivery?.toFixed(2)}`;
    }
  }, [branding.currencySymbol, order, orders]);

  if (isLoading) {
    return (
      <ST.LoadingSection>
        <Spinner dark size={1} />
      </ST.LoadingSection>
    );
  }

  if (isTraining) {
    return (
      <div>
        <ST.OrderTotalPriceSection>
          <ST.PriceField fontSize="16px" marginBottom="14px" bold>
            {orders.costSummary.title}
          </ST.PriceField>

          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {orders.costSummary.itemsToHire}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.subTotal}
            </ST.PriceField>
          </Row>

          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px" bold>
              {orders.costSummary.total}
            </ST.PriceField>
            <ST.PriceField fontSize="16px" bold>
              {branding.currencySymbol}
              {order.subTotal}
            </ST.PriceField>
          </Row>

          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {form.vat} ({(settings.vat && Math.round(settings.vat)) || 0}%)
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.tax}
            </ST.PriceField>
          </Row>
        </ST.OrderTotalPriceSection>

        <ST.OrderTotalPriceSection>
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px" bold>
              {orders.total}:
            </ST.PriceField>
            <ST.PriceField fontSize="16px" bold>
              {branding.currencySymbol}
              {order.total}
            </ST.PriceField>
          </Row>
        </ST.OrderTotalPriceSection>
      </div>
    );
  }

  return (
    <ST.OrderTotalPrice>
      <ST.OrderTotalPriceSection>
        <ST.PriceField fontSize="16px" marginBottom="14px" bold>
          {orders.costSummary.title}
        </ST.PriceField>

        <Row justify="stretch" noWrap>
          <ST.PriceField fontSize="16px">
            {orders.costSummary.itemsToHire}
          </ST.PriceField>
          <ST.PriceField fontSize="16px">
            {branding.currencySymbol}
            {order.items_total?.toFixed(2)}
          </ST.PriceField>
        </Row>

        {settings.regionHasInsurance && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {form.checkbox.insurance}
            </ST.PriceField>
            {order?.insurance > 0 ? (
              <ST.PriceField fontSize="16px">
                {branding.currencySymbol}
                {order.insurance?.toFixed(2)}
              </ST.PriceField>
            ) : (
              <ST.PriceField fontSize="16px" color="primary">
                {orders.costSummary.tbd}
              </ST.PriceField>
            )}
          </Row>
        )}

        {settings.slightDamageWaiverEnabled && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {orders.costSummary.slightDamageWaiverFee}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.slightDamageWaiverFee?.toFixed(2) || '0.00'}
            </ST.PriceField>
          </Row>
        )}

        {(settings.adminFeeVatEnabled || settings.adminFeeFixedEnabled) && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {orders.costSummary.adminFee}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.adminFee?.toFixed(2) || '0.00'}
            </ST.PriceField>
          </Row>
        )}

        {settings.safetyFeeEnabled && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {form.checkbox.safetyFee}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.safetyFee?.toFixed(2) || '0.00'}
            </ST.PriceField>
          </Row>
        )}

        {settings.sanitationFeeEnabled && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {form.checkbox.sanitationFee}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.sanitationFee?.toFixed(2) || '0.00'}
            </ST.PriceField>
          </Row>
        )}

        {Boolean(Number(order.discount)) && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {orders.costSummary.discount}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.discount?.toFixed(2)}
            </ST.PriceField>
          </Row>
        )}

        {Boolean(Number(order.tier_discount)) && (
          <Row justify="stretch" noWrap>
            <ST.PriceField fontSize="16px">
              {orders.costSummary.tradeAccountDiscount}
            </ST.PriceField>
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.tier_discount?.toFixed(2)}
            </ST.PriceField>
          </Row>
        )}

        <Row justify="stretch" noWrap>
          <ST.PriceField fontSize="16px">{orders.item.transport}</ST.PriceField>
          {isOffer ? (
            <Row direction="column" justify="end" align="end">
              <InputWithCurrencySign
                name="total_delivery"
                type="number"
                innerRef={register({
                  required: form.notification.theFieldIsRequired,
                  validate: {
                    noNegative: value =>
                      value >= 0 || form.notification.valueShouldBePositive,
                  },
                })}
                width="90px"
                step="0.01"
                paddingLeft="3rem"
                defaultValue={order.total_delivery}
              />
              <Error message={get(errors, ['total_delivery', 'message'])} />
            </Row>
          ) : (
            <ST.PriceField fontSize="16px">{deliveryPrice}</ST.PriceField>
          )}
        </Row>

        <Row justify="stretch" noWrap>
          <ST.PriceField fontSize="16px">
            {form.vat} ({(settings.vat && Math.round(settings.vat)) || 0}%)
          </ST.PriceField>
          <ST.PriceField fontSize="16px">
            {branding.currencySymbol}
            {order.tax?.toFixed(2)}
          </ST.PriceField>
        </Row>

        <Row justify="stretch" noWrap>
          <ST.PriceField fontSize="16px" bold>
            {orders.costSummary.total}
          </ST.PriceField>
          <ST.PriceField fontSize="16px" bold>
            {branding.currencySymbol}
            {order.total?.toFixed(2)}
          </ST.PriceField>
        </Row>
      </ST.OrderTotalPriceSection>

      <ST.OrderTotalPriceSection>
        <PriceWithAdditionalText
          text={orders.costSummary.depositAdditionalText}
        >
          <ST.PriceField fontSize="16px">{form.checkbox.deposit}</ST.PriceField>
          {isOffer ? (
            <Row direction="column" justify="end" align="end">
              <InputWithCurrencySign
                name="deposit"
                type="number"
                innerRef={register({
                  required: form.notification.theFieldIsRequired,
                  validate: {
                    noNegative: value =>
                      value >= 0 || form.notification.valueShouldBePositive,
                  },
                })}
                onClick={e => {
                  e.stopPropagation();
                }}
                onChange={e => {
                  e.stopPropagation();
                }}
                width="90px"
                step="0.01"
                paddingLeft="3rem"
                defaultValue={order.deposit}
              />
              <Error message={get(errors, ['deposit', 'message'])} />
            </Row>
          ) : (
            <ST.PriceField fontSize="16px">
              {branding.currencySymbol}
              {order.deposit?.toFixed(2)}
            </ST.PriceField>
          )}
        </PriceWithAdditionalText>
      </ST.OrderTotalPriceSection>

      <ST.OrderTotalPriceSection>
        <Row justify="stretch" noWrap>
          <ST.PriceField fontSize="16px" bold>
            {orders.total}
          </ST.PriceField>
          <ST.PriceField fontSize="16px" bold>
            {branding.currencySymbol}
            {order.grandTotal?.toFixed(2)}
          </ST.PriceField>
        </Row>
      </ST.OrderTotalPriceSection>
    </ST.OrderTotalPrice>
  );
};

export default OrderTotalPrice;
