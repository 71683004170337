import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Cell } from 'ui';

export const TableImage = styled.img`
  max-width: 3.7rem;
  max-height: 3.7rem;
`;

export const TableBodyCell = styled(Cell)`
  font-size: 14px;
  color: ${colors.negative};
`;

export const DrugDropIconWrapper = styled(TableBodyCell)`
  width: 10px;
`;

export const TableHeader = styled.div`
  padding: 30px 0 15px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
`;
