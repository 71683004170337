import CustomButton from 'components/atoms/CustomButton';
import styled from 'styled-components';

export const PageHeaderWithBackLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
`;

export const ControlsWrapper = styled.div`
  display: flex;
`;

export const CustomButtonStyled = styled(CustomButton)`
  margin-right: 16px;
`;

export const FooterControls = styled.div`
  display: flex;
  justify-content: center;
`;

export const TrainingIndexFormWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
