import React, { useCallback, useMemo } from 'react';
import { Lang } from 'lang';

import * as ST from './styles';
import { useOrderCancelRequest } from './useOrderCancelRequest';

import { Row, T8y } from 'ui';

const OrderCancelRequestBlock = ({
  order,
  cancelRequest,
  onUpdateCallback,
  isSmall = false,
}) => {
  const { orders } = Lang();

  const {
    sendAnswerForCancelOrder,
    isAnswerForCancelOrderSending,
  } = useOrderCancelRequest(onUpdateCallback);

  const { actualFontSize, actualColor } = useMemo(
    () => ({
      actualFontSize: isSmall ? '14px' : '16px',
      actualColor: isSmall ? 'gray3' : 'negative',
    }),
    [isSmall],
  );

  const sendAnswer = useCallback(
    value => {
      sendAnswerForCancelOrder(order.orderId, cancelRequest.id, value);
    },
    [cancelRequest, order, sendAnswerForCancelOrder],
  );

  return (
    <ST.UserRequestsSection isSmall={isSmall}>
      <T8y fontSize={actualFontSize} marginBottom="4px" fontWeight="700">
        {orders.requestToCancelThisOrder}
      </T8y>
      <T8y fontSize={actualFontSize} color={actualColor} marginBottom="8px">
        <ST.CancellationReason
          as="span"
          fontSize={actualFontSize}
          color={actualColor}
        >
          {orders.cancellationReason}:{' '}
        </ST.CancellationReason>
        {cancelRequest.reasons}
      </T8y>
      <Row noWrap>
        <ST.OrderButton
          fontSize={actualFontSize}
          color={actualColor}
          onClick={() => sendAnswer('accepted')}
          disabled={isAnswerForCancelOrderSending}
        >
          {orders.approve}
        </ST.OrderButton>
        <ST.OrderButton
          fontSize={actualFontSize}
          color={actualColor}
          onClick={() => sendAnswer('declined')}
          disabled={isAnswerForCancelOrderSending}
        >
          {orders.decline}
        </ST.OrderButton>
      </Row>
    </ST.UserRequestsSection>
  );
};

export default OrderCancelRequestBlock;
