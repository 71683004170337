import styled from 'styled-components';

import { colors } from 'styleGuide';

export const HeaderWrapper = styled.div`
  max-width: 70rem;
  background-color: ${colors.cardBg};
`;

export const Header = styled.div`
  padding: 2rem 0 1rem;
  text-align: center;
  background-color: #f2f2f2;
`;

export const ContentWrapper = styled.div`
  padding: 4rem 3rem;
`;
