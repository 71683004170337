import React, { useCallback, useEffect, useMemo } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import { settings$ } from 'models/settings';

import Error from 'components/atoms/Error';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import { InputStyled } from 'components/Product/shared';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const Charges = ({
  values,
  setValue,
  register,
  watch,
  errors,
  trigger,
  productCharges,
}) => {
  const { product, form } = Lang();

  const settings = useStore(settings$);
  const branding = useStore(branding$);

  const radio = useRadioState();

  const defaultProductInsurance = useMemo(() => {
    if (productCharges.insuranceIndividualFee?.enabled) {
      return 'isInsurance';
    }
    if (productCharges.insuranceIndividualPerDayFee?.enabled) {
      return 'isInsurancePerDay';
    }
  }, [productCharges]);

  useEffect(
    () => radio.setState(defaultProductInsurance),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultProductInsurance],
  );

  useEffect(() => {
    setValue('isInsurance', radio.state === 'isInsurance', {
      shouldDirty: true,
    });
    setValue('isInsurancePerDay', radio.state === 'isInsurancePerDay', {
      shouldDirty: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio.state]);

  const isInsuranceWatcher = watch('isInsurance');
  const isInsurancePerDayWatcher = watch('isInsurancePerDay');
  const isDamageWaiverWatcher = watch('isDamageWaiver');

  useEffect(() => {
    register('safety');
    register('sanitation');
    register('isInsurance');
    register('isInsurancePerDay');
    register('isDamageWaiver');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    trigger(['insuranceIndividualFee', 'insuranceBusinessFee']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInsuranceWatcher]);

  useEffect(() => {
    trigger(['insuranceIndividualPerDayFee', 'insuranceBusinessPerDayFee']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInsurancePerDayWatcher]);

  useEffect(
    () => {
      trigger(['damageWaiverIndividualFee', 'damageWaiverBusinessFee']);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDamageWaiverWatcher],
  );

  const handleInsuranceRadioClick = useCallback(
    (e, val) => {
      if (radio.state === val) {
        e.preventDefault();
        radio.setState(null);
      }
    },
    [radio],
  );

  const isSafetyHiddenOnRegionalLevel = useMemo(() => {
    return !settings.safetyFeeEnabled;
  }, [settings.safetyFeeEnabled]);

  const isSanitationHiddenOnRegionalLevel = useMemo(() => {
    return !settings.sanitationFeeEnabled;
  }, [settings.sanitationFeeEnabled]);

  const isDepositHiddenOnRegionalLevel = useMemo(() => {
    return !settings.depositEnabled;
  }, [settings.depositEnabled]);

  return (
    <ProductEditorBlockWrapper blockTitle={product.titles.additionalCharges}>
      <ST.Wrapper>
        <ST.BlockWrapper $isPadding $isColumn>
          {!settings.slightDamageWaiverEnabled && (
            <T8y color="danger" marginBottom="10px">
              {form.disabledOnRegionalLevel}
            </T8y>
          )}
          <ST.SettingFieldsWrapper>
            <ST.CheckboxWrapper
              label={form.checkbox.damageWaiver + ' %'}
              onChange={e => setValue('isDamageWaiver', e.target.checked)}
              checked={(values && values.isDamageWaiver) || false}
              isBlocked={!settings.slightDamageWaiverEnabled}
              $isPadding
            />
            <ST.InputsWrapper>
              <Row direction="column">
                <ST.InputWrapper>
                  <ST.InsuranceInput
                    name="damageWaiverIndividualFee"
                    ref={register({
                      validate: {
                        ...(isDamageWaiverWatcher && {
                          required: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        }),
                        noNegative: value =>
                          value >= 0 || form.notification.valueShouldBePositive,
                      },
                    })}
                    readOnly={!isDamageWaiverWatcher}
                    width="80px"
                  />
                  <ST.FieldTitle>{form.fields.individualUser}</ST.FieldTitle>
                </ST.InputWrapper>
                <Error
                  height="16px"
                  message={errors?.damageWaiverIndividualFee?.message}
                />
              </Row>
              <Row direction="column">
                <ST.InputWrapper>
                  <ST.InsuranceInput
                    name="damageWaiverBusinessFee"
                    ref={register({
                      validate: {
                        ...(isDamageWaiverWatcher && {
                          required: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        }),
                        noNegative: value =>
                          value >= 0 || form.notification.valueShouldBePositive,
                      },
                    })}
                    readOnly={!isDamageWaiverWatcher}
                    width="80px"
                  />
                  <ST.FieldTitle>{form.fields.companyUser}</ST.FieldTitle>
                </ST.InputWrapper>
                <Error
                  height="16px"
                  message={errors?.damageWaiverBusinessFee?.message}
                />
              </Row>
            </ST.InputsWrapper>
          </ST.SettingFieldsWrapper>
        </ST.BlockWrapper>
        <RadioGroup as={ST.RadioGroupWrapper} {...radio}>
          <ST.BlockWrapper>
            <ST.RadioWrapper
              text={`${form.checkbox.insurance} %`}
              {...radio}
              value="isInsurance"
              onClick={e => handleInsuranceRadioClick(e, 'isInsurance')}
              $isPadding
            />
            <ST.InputsWrapper>
              <Row direction="column">
                <ST.InputWrapper>
                  <ST.InsuranceInput
                    name="insuranceIndividualFee"
                    ref={register({
                      validate: {
                        ...(isInsuranceWatcher && {
                          required: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        }),
                        noNegative: value =>
                          value >= 0 || form.notification.valueShouldBePositive,
                      },
                    })}
                    readOnly={!isInsuranceWatcher}
                    width="80px"
                  />
                  <ST.FieldTitle>{form.fields.individualUser}</ST.FieldTitle>
                </ST.InputWrapper>
                <Error
                  height="16px"
                  message={
                    errors &&
                    errors.insuranceIndividualFee &&
                    errors.insuranceIndividualFee.message
                  }
                />
              </Row>
              <Row direction="column">
                <ST.InputWrapper>
                  <ST.InsuranceInput
                    name="insuranceBusinessFee"
                    ref={register({
                      validate: {
                        ...(isInsuranceWatcher && {
                          required: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        }),
                        noNegative: value =>
                          value >= 0 || form.notification.valueShouldBePositive,
                      },
                    })}
                    readOnly={!isInsuranceWatcher}
                    width="80px"
                  />
                  <ST.FieldTitle>{form.fields.companyUser}</ST.FieldTitle>
                </ST.InputWrapper>
                <Error
                  height="16px"
                  message={
                    errors &&
                    errors.insuranceBusinessFee &&
                    errors.insuranceBusinessFee.message
                  }
                />
              </Row>
            </ST.InputsWrapper>
          </ST.BlockWrapper>
          <ST.BlockWrapper>
            <ST.RadioWrapper
              text={`${form.checkbox.insurance} ${branding.currency}/${product.day}`}
              {...radio}
              value="isInsurancePerDay"
              onClick={e => handleInsuranceRadioClick(e, 'isInsurancePerDay')}
              $isPadding
            />
            <ST.InputsWrapper>
              <Row direction="column">
                <ST.InputWrapper>
                  <ST.InsuranceInput
                    name="insuranceIndividualPerDayFee"
                    ref={register({
                      validate: {
                        ...(isInsurancePerDayWatcher && {
                          required: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        }),
                        noNegative: value =>
                          value >= 0 || form.notification.valueShouldBePositive,
                      },
                    })}
                    readOnly={!isInsurancePerDayWatcher}
                    width="80px"
                  />
                  <ST.FieldTitle>{form.fields.individualUser}</ST.FieldTitle>
                </ST.InputWrapper>
                <Error
                  height="16px"
                  message={
                    errors &&
                    errors.insuranceIndividualPerDayFee &&
                    errors.insuranceIndividualPerDayFee.message
                  }
                />
              </Row>
              <Row direction="column">
                <ST.InputWrapper>
                  <ST.InsuranceInput
                    name="insuranceBusinessPerDayFee"
                    ref={register({
                      validate: {
                        ...(isInsurancePerDayWatcher && {
                          required: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        }),
                        noNegative: value =>
                          value >= 0 || form.notification.valueShouldBePositive,
                      },
                    })}
                    readOnly={!isInsurancePerDayWatcher}
                    width="80px"
                  />
                  <ST.FieldTitle>{form.fields.companyUser}</ST.FieldTitle>
                </ST.InputWrapper>
                <Error
                  height="16px"
                  message={
                    errors &&
                    errors.insuranceBusinessPerDayFee &&
                    errors.insuranceBusinessPerDayFee.message
                  }
                />
              </Row>
            </ST.InputsWrapper>
          </ST.BlockWrapper>
        </RadioGroup>
        <ST.BlockWrapper $isPadding>
          <ST.SettingFieldsWrapper>
            <ST.CheckboxWrapper
              label={form.checkbox.safetyFee}
              onChange={e => setValue('safety', e.target.checked)}
              checked={(values && values.safety) || false}
              isBlocked={isSafetyHiddenOnRegionalLevel}
            />
            <ST.InputWrapper>
              <InputStyled
                disabled
                width="80px"
                defaultValue={settings?.safetyFee}
              />
              <ST.FieldTitle>{form.fields.feePerDayPerItem}</ST.FieldTitle>
            </ST.InputWrapper>
          </ST.SettingFieldsWrapper>
          {isSafetyHiddenOnRegionalLevel && (
            <T8y color="danger" marginLeft="15px">
              {form.disabledOnRegionalLevel}
            </T8y>
          )}
        </ST.BlockWrapper>
        <ST.BlockWrapper $isPadding>
          <ST.SettingFieldsWrapper>
            <ST.CheckboxWrapper
              label={form.checkbox.sanitationFee}
              onChange={e => setValue('sanitation', e.target.checked)}
              checked={(values && values.sanitation) || false}
              isBlocked={isSanitationHiddenOnRegionalLevel}
            />
            <ST.InputWrapper>
              <InputStyled
                disabled
                width="80px"
                defaultValue={settings?.sanitationFee}
              />
              <ST.FieldTitle>{form.fields.feePerItem}</ST.FieldTitle>
            </ST.InputWrapper>
          </ST.SettingFieldsWrapper>
          {isSanitationHiddenOnRegionalLevel && (
            <T8y color="danger" marginLeft="15px">
              {form.disabledOnRegionalLevel}
            </T8y>
          )}
        </ST.BlockWrapper>
        <ST.BlockWrapper $isPadding>
          <ST.SettingFieldsWrapper>
            <ST.FakeCheckbox>
              <T8y as="span">{form.fields.deposit}</T8y>
            </ST.FakeCheckbox>
            <ST.InputWrapper>
              <InputStyled
                name="deposit"
                ref={register}
                width="80px"
                onChange={e => setValue('deposit', e.target.value)}
                defaultValue={values && values.deposit}
                disabled={isDepositHiddenOnRegionalLevel}
              />
              <ST.FieldTitle>{form.fields.amountPerItem}</ST.FieldTitle>
            </ST.InputWrapper>
          </ST.SettingFieldsWrapper>
          {isDepositHiddenOnRegionalLevel && (
            <T8y color="danger" marginLeft="15px">
              {form.disabledOnRegionalLevel}
            </T8y>
          )}
        </ST.BlockWrapper>
      </ST.Wrapper>
    </ProductEditorBlockWrapper>
  );
};

export default Charges;
