import React from 'react';
import { Controller } from 'react-hook-form';
import { Lang } from 'lang';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';

import { Checkbox, T8y } from 'ui';

const TradeAccountDeposit = ({ control, isLoading }) => {
  const { users } = Lang();

  return (
    <ProductEditorBlockWrapper blockTitle={users.deposit.title}>
      <Controller
        name="account.depositFree"
        render={({ onChange, value }) => (
          <Checkbox
            label={users.deposit.option}
            onChange={e => onChange(!e.target.checked)}
            checked={!value}
            disabled={isLoading}
          />
        )}
        control={control}
      />
      <T8y fontSize="1.6rem" marginTop="15px" color="">
        {users.deposit.description}
      </T8y>
    </ProductEditorBlockWrapper>
  );
};

export default TradeAccountDeposit;
