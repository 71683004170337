export const orders = {
  order: 'Pedido',
  title: 'Pedidos recentes',
  total: 'Total geral',
  documents: 'Documentos',
  invoice: 'Fatura',
  uploadDocument: 'Upload documento',
  requestToCancelThisOrder:
    'O usuário enviou uma solicitação para cancelar este pedido',
  approve: 'Aprovar',
  decline: 'Declinar',
  youWantToCancelThisOrder: 'Tem certeza de que deseja cancelar este pedido?',
  provideCancellationReason: 'Se sim, informe o motivo do cancelamento',
  cancellationReason: 'Motivo do Cancelamento',
  cancelOrder: 'Cancelar pedido',
  submitRequest: 'Enviar pedido',
  viewOrder: 'Ver pedido',
  ordersAvailable: '{0} pedidos disponíveis',
  startTypingToFilter: 'Comece a digitar para filtrar',
  paymentStatus: 'Status do pagamento',
  productList: 'Lista de produtos',
  orderTableHeaders: {
    orderId: 'ID do pedido',
    createdBy: 'Created By',
    orderType: 'Tipo de pedido',
    dateCreated: 'Data Criada',
    dates: 'Datas',
    status: 'Status',
    deliveryMethod: 'Método de Entrega',
    paymentMethod: 'Forma de pagamento',
    deposit: 'Depósito',
    total: 'Total geral',
    author: 'Autor',
    purchaseOrderNumber: 'Número do Pedido de Compra',
  },
  productsTableHeaders: {
    sku: 'Sku',
    visual: 'Visual',
    productTitle: 'Título do produto',
    hirePeriod: 'Período de contratação',
    hirePerItem: 'AlugueR por item',
    qty: 'quant.',
    hirePrice: 'Preço do aluguer',
    courseId: 'ID do curso',
    courseTitle: 'Título do curso',
    courseDates: 'Datas do curso',
    coursePrice: 'Preço do Curso',
    seats: 'Assentos',
    totalCost: 'Custo total',
  },
  item: {
    orderStatus: 'Status do pedido',
    date: 'Data Criada',
    transport: 'Transporte',
    paymentType: 'Tipo de pagamento',
    productList: 'Product List',
    cardEndingIn: 'terminando em',
  },
  paymentType: {
    card: 'Cartão de crédito',
    cash: 'Dinheiro',
    later: 'Pague depois',
    bank: 'Transferência bancária',
  },
  statusType: {
    paid: 'Pago',
    not_paid: 'Não pago',
    new: 'Novo',
    in_progress: 'Em andamento',
    completed: 'Concluído',
    cancelled: 'Cancelado',
    all: 'Todos os pedidos',
    quote: 'Cotação',
  },
  orderType: {
    product: 'Produto',
    quote: 'Cotação',
    training: 'Treinamento',
  },
  deliveryMethod: {
    selfCollection: 'Coleta própria',
    delivery: 'Entrega',
  },
  costSummary: {
    title: 'Resumo de custos',
    subtotal: 'Subtotal',
    tbd: 'A confirmar',
    slightDamageWaiverFee: 'Isenção de Danos Ligeiros',
    adminFee: 'Taxa de administração',
    total: 'Total',
    free: 'GR´ÁTIS',
    depositAdditionalText:
      'O depósito por perdas e danos será devolvido \n ao seu cartão após a devolução segura do produto',
    discount: 'Desconto',
    itemsToHire: 'Aluguel de Máquinas',
    tradeAccountDiscount: 'Desconto de conta comercial',
  },
  offers: {
    offer: 'Oferta',
    offers: 'Ofertas',
    newOffer: 'Nova oferta',
    createNewOffer: 'Criar nova oferta',
    viewOffer: 'Ver oferta',
    urlFromCheckout: 'Url do checkout',
    enterUrl: 'Insira url aqui',
    backToList: 'Voltar à lista de ofertas',
    offersAvailable: '{0} ofertas disponíveis',
    saveOffer: 'Salvar oferta e gerar PDF',
    quotePDF: 'Cotação PDF',
  },
  noDocuments: 'Ainda não há documentos',
};
