export const marketing = {
  titles: {
    content: 'Content',
    homePage: 'Homepage hero slider',
    slideIndex: 'Slide Index',
    deleteSlide: 'Delete Slide',
    CTA: 'CTA Button',
    buttonText: 'Button Text',
    buttonURL: 'Button URL',
    learnMore: 'Learn more',
    declareInterest: 'Declare interest',
    exploreCategory: 'Explore Category',
    addNewSlide: 'Add new slide',
    desktopVersion: 'Desktop Image',
    mobileVersion: 'Mobile Image',
    heroImages: 'Hero Images',
    popularProducts: 'Popular Products',
    popularProductsNote:
      'Popular Product section will be visible ONLY if more than 3 products are added',
    targetBlank: 'Open link in new tab',
    enquiryForm: 'Enquiry Form',
    popularCategories: 'Popular categories',
    promoCodes: 'Promo Codes',
    buttonTitle: 'Button Title',
    h1Text: 'H1 Text',
    pText: 'P Text (desktop version only)',
    jobTypes: 'Job Types',
    taFaqs: 'TA FAQs',
  },

  enquiry: {
    enquiryFormVariation: 'Enquiry Form Variation',
    defaultTitle: 'Default enquiry form',
    defaultSubTitle:
      'The default form has this set of fields: Full Name, Phone Number, Company Name, Email Address, Message',
    customizedTitle: 'Enquiry form with customizable question',
    customizedSubTitle:
      'Select this option if you would like to expand the default set of fields with edditional question',
    addNewAnswer: 'Add New Answer',
  },

  jobTypes: {
    jobType: 'Job Type',
    jobTypeTitle: 'Job Type Title',
    relatedProducts: 'Related Products',
    relatedCategories: 'Related Categories',
    addNewJobType: 'Add New Job Type',
    viewJobType: 'View Job Type',
    availableItems: '{0} job types available',
    jobURL: 'Page URL',
    jobHTMLTitle: 'HTML Title',
    hideJob: 'Hide Job Type page',
    marketingText: 'Marketing Text',
    addProduct: 'Add related product',
    addCategory: 'Add related category',
    backToJobs: 'Back to list of all job types',
    noJobs: 'No jobs yet',
  },
};
