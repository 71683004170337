import React from 'react';

import AddMore from '../AddMore';
import * as ST from './styles';

const BigAddMore = ({ text, onClick, fontSize, isDisabled }) => {
  return (
    <ST.AddMoreWrapper onClick={!isDisabled && onClick}>
      <AddMore text={text} fontSize={fontSize} disabled={isDisabled} />
    </ST.AddMoreWrapper>
  );
};

export default BigAddMore;
