export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? '#f897395e' : 'grey',
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver, draggableStyle) => ({
  background: isDraggingOver ? 'rgba(248,151,57,0.13)' : 'initial',
  ...draggableStyle,
});
