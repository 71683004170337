import React from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { settings$ } from 'models/settings';

import CustomButton from 'components/atoms/CustomButton';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, T8y } from 'ui';

const DepotUnableDeleteModal = ({
  disclosure,
  dialogState,
  depotName,
  handleModalClose,
  depotRelatedProductsList,
}) => {
  const {
    formatString,
    form: { buttons },
    settings: settingsLocalization,
    header: { table },
  } = Lang();

  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);

  return (
    <Modal
      disclosure={disclosure}
      title={settingsLocalization.modals.deleteMessageTitle}
      dialogState={dialogState}
      width="420px"
      overflow="hidden"
      backgroundColor={colors.primary}
      lazyRender
    >
      <T8y lineHeight="22px" marginTop="30px">
        {formatString(
          settingsLocalization.modals.depotDeleteMessageText,
          <T8y lineHeight="22px" display="inline-block" bold>
            {depotName}
          </T8y>,
        )}
      </T8y>
      <ST.ProductsListWrapper>
        <ST.ProductHeaderWrapper>
          <ST.ProductCodeWrapper>{table.productCode}</ST.ProductCodeWrapper>
          <ST.ProductNameWrapper>{table.Name}</ST.ProductNameWrapper>
        </ST.ProductHeaderWrapper>
        {depotRelatedProductsList?.map(el => (
          <ST.ProductItemWrapper>
            <ST.ProductCodeWrapper>{el.sku}</ST.ProductCodeWrapper>
            <ST.ProductNameWrapper>
              {el.names[currentLangState] || el.names[settings.defaultLanguage]}
            </ST.ProductNameWrapper>
          </ST.ProductItemWrapper>
        ))}
      </ST.ProductsListWrapper>
      <ST.DepotUnableDeleteModalControlsWrapper>
        <CustomButton
          text={buttons.okay}
          backgroundColor="positive"
          color="gray3"
          width="120px"
          height="29px"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          onClick={handleModalClose}
        />
      </ST.DepotUnableDeleteModalControlsWrapper>
    </Modal>
  );
};

export default DepotUnableDeleteModal;
