import React from 'react';

import DropZone from 'components/molecules/DropZone';

import * as ST from './styles';

import { ReactComponent as Plus } from 'assets/icons/gray-plus.svg';

export const DropZoneBlockPlus = ({
  handleUpload,
  multiple,
  accept,
  disabled,
}) => {
  return (
    <DropZone
      handleUploadEnd={handleUpload}
      multiple={multiple}
      accept={accept}
      disabled={disabled}
    >
      {isDragActive => (
        <ST.DropZoneBlockWrapper
          justify="center"
          align="center"
          $isDragActive={isDragActive}
        >
          <Plus />
        </ST.DropZoneBlockWrapper>
      )}
    </DropZone>
  );
};
