import DatePicker from 'react-date-picker/dist/entry.nostyle';

import styled from 'styled-components';

export const PickerStyled = styled(DatePicker)`
  position: relative;
  display: inline-flex;
  padding: 0 1rem;
  
  & span {
    color: #424242;
  }
  
  .react-date-picker__--disabled {
    color: #6d6d6d;
    background-color: #f0f0f0;
  }

  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .react-date-picker__inputGroup {
    flex-grow: 1;
    box-sizing: content-box;
    min-width: calc(12px + 0.54em * 8 + 0.217em * 2);
    padding: 0 2px;

    .react-date-picker__divider {
      padding: 1px 0;
      white-space: pre;
    }

    input {
      height: 100%;
      padding: 0 1px;
      font-size:14px;
      min-width: 26px;
      
      &[name="year"] {
         min-width: 40px;
      }
      
      &.react-date-picker__inputGroup__input--hasLeadingZero {
         margin-left: -0.54em;
         padding-left: calc(0.1rem + 0.54em);
      }
    }
  }

  .react-date-picker__button {
    padding: 0.4rem 0.6rem;
    background: transparent;
    border: 0;

    svg {
      display: inherit;
    }
  }

  .react-date-picker__calendar {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    max-width: 100vw;
  }
  
  .react-date-picker__calendar--closed {
    display: none;
  }
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__calendar .react-calendar {
  position: absolute;
  z-index: 9999999;
  top: 44px;
  left: 0;
  padding: 1rem;
  border-width: thin;
}

/*calendar styles*/
.react-calendar {
  width: 272px;
  color: #424242;
  line-height: 1.125em;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  button {
    margin: 0;
    border: 0;
    outline: none;
  }

  &__navigation {
    height: 44px;
    margin-bottom: 0.5em;
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 4.4rem;
      font-weight: bold;
      font-size: 1.6rem;
      background: none;
    }
  }

  &__month-view {
    &__weekdays {
      font-weight: bold;
      font-size: 0.75em;
      text-align: center;
      text-transform: uppercase;

      &__weekday {
        padding: 0.5em;

        abbr {
          text-decoration: none;
        }
      }
    }

    &__weekNumbers {
      font-weight: bold;
    }

    &__days {
      &__day--weekend {
        color: #424242;
      }

      &__day--neighboringMonth {
        color: #cccccc;
      }
    }
  }

  &__tile {
    max-width: 100%;
    padding: 0.75em 0.5em;
    text-align: center;
    background: none;
  }
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  font-size: 0.75em;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
  color: white;
  background: #ff6600;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  color: white;
  background: #ff6600;
}

.react-calendar__tile--active {
  color: white;
  background: #ff6600;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  color: white;
  background: #ff6600;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__label__labelText,
.react-calendar__year-view__months__month {
  text-transform: capitalize;
}
`;
