import React, { Suspense, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';

import Stepper from 'components/molecules/Tabs';
import AnalyticsDataStudio from 'components/organisms/AnalyticsDataStudio';
import AnalyticsReports from 'components/organisms/AnalyticsReports';

import { T8y } from 'ui';

const Analytics = () => {
  const {
    analytics: { titles },
  } = Lang();

  const steps = useMemo(
    () => [
      {
        label: titles.dataStudio,
        path: PATHS.CHARTS,
      },
      {
        label: titles.monthlyReports,
        path: PATHS.REPORTS,
      },
    ],
    [titles],
  );

  return (
    <>
      <T8y color="darkBlue" variant="t1" bold>
        {titles.content}
      </T8y>
      <Stepper steps={steps} />
      <Suspense fallback={<div> Loading ...</div>}>
        <Switch>
          <Route path={PATHS.CHARTS} component={AnalyticsDataStudio} />
          <Route path={PATHS.REPORTS} component={AnalyticsReports} />
          <Redirect to={PATHS.REPORTS} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Analytics;
