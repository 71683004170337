import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useGate, useStore } from 'effector-react';
import { useGetLocalizedExtraFields } from 'hooks';
import { Lang } from 'lang';
import { membershipsGate } from 'models/memberships';
import { settings$ } from 'models/settings';
import { user$ } from 'models/users';

import UserAddressesInfoBlock from 'components/molecules/UserAddressesInfoBlock';
import UserCompanyContactInfo from 'components/molecules/UserCompanyContactInfo';
import UserCompanyGeneralInfo from 'components/molecules/UserCompanyGeneralInfo';
import UserDeposit from 'components/molecules/UserDeposit';
import UserMembership from 'components/molecules/UserMembership';
import UserOrdersSmall from 'components/molecules/UserOrdersSmall';
import UserPrivateGeneralInfo from 'components/molecules/UserPrivateGeneralInfo';
import UserSpecialDiscount from 'components/molecules/UserSpecialDiscount';

import { Grid } from 'ui';

const UserEditor = ({ location }) => {
  const strings = Lang();

  useGate(membershipsGate);
  const settings = useStore(settings$);
  const user = useStore(user$);

  const isNewUser = location.pathname.includes('new');

  const { register, getValues, watch, reset } = useForm({
    defaultValues: {
      type: null,
      first_name: null,
      last_name: null,
      email: null,
      fiscal_code: null,
      sector_of_activity: null,
      know_about_us: null,
      address: null,
      province: null,
      country: null,
      phone: null,
      postcode: null,
      city: null,
      vat_number: null,
      business_name: null,
      contact_phone: null,
      contact_email: null,
      userVerificationStatus: '',
      defaultBillingAddress: null,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const extraFieldsLocalized = useGetLocalizedExtraFields(
    settings?.extraFieldsTradeProfile,
  );

  const { companyExtraFields } = useMemo(() => {
    return extraFieldsLocalized.reduce(
      (acc, el) => {
        if (el.type === 'company') {
          return {
            ...acc,
            companyExtraFields: [...acc.companyExtraFields, el],
          };
        }

        return acc;
      },
      { companyExtraFields: [] },
    );
  }, [extraFieldsLocalized]);

  useEffect(() => {
    if (user) {
      reset({
        type: user.type,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        fiscal_code: user.fiscal_code,
        sector_of_activity:
          user.sector_of_activity &&
          (strings.users.sectorOfActivity[user.sector_of_activity] ||
            user.sector_of_activity),
        know_about_us:
          user.know_about_us &&
          (strings.users.aboutUs[user.know_about_us] || user.know_about_us),
        address: user.address,
        province: user.province,
        country: user.country,
        phone: user.phone || user.address?.phone,
        postcode: user.postcode,
        city: user.city,
        vat_number: user.vat_number,
        business_name: user.business_name,
        contact_phone: user.contact_phone,
        contact_email: user.contact_email,
        userVerificationStatus: user.userVerificationStatus,
        defaultBillingAddress: user.defaultBillingAddress,
        ...companyExtraFields.reduce((acc, el) => {
          acc[el.name] = user.company_details?.[el.name] || null;
          return acc;
        }, {}),
      });
    }
  }, [reset, user, strings.users, companyExtraFields]);

  const isDiscountsSectionAvailable = settings?.showDiscountsSectionInCP;
  const isUserWithType = user?.type;
  const isOrders = !!user?.orders?.length;

  if (!user && !isNewUser) {
    return null;
  }

  return (
    <Grid cols="3fr 1.5fr" gaps="0 3rem">
      {isUserWithType ? (
        <div>
          {user?.type === 'company' ? (
            <>
              <UserCompanyGeneralInfo register={register} values={values} />
              <UserCompanyContactInfo register={register} values={values} />
              <UserAddressesInfoBlock />
            </>
          ) : (
            <>
              <UserPrivateGeneralInfo register={register} values={values} />
              <UserAddressesInfoBlock />
            </>
          )}
        </div>
      ) : (
        <div>
          <UserPrivateGeneralInfo register={register} values={values} />
        </div>
      )}
      <div>
        <UserMembership />
        {isDiscountsSectionAvailable && (
          <UserSpecialDiscount
            userId={user?.id}
            userTier={user?.tier}
            isDisabled={isNewUser}
          />
        )}
        <UserDeposit
          userId={user?.id}
          isDeposit={user?.deposit_setting}
          isDisabled={isNewUser}
        />
        {isOrders && <UserOrdersSmall />}
      </div>
    </Grid>
  );
};

export default withRouter(UserEditor);
