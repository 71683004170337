import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import isEqual from 'lodash/isEqual';
import { replaceCategoryData } from 'models/categories';
import { settings$ } from 'models/settings';
import { isUrl } from 'utils/patterns';

import CustomButton from 'components/atoms/CustomButton';
import Error from 'components/atoms/Error';
import { CATEGORIES } from 'api';

import { InputStyled } from '../../Product/shared';
import * as ST from './styles';

import { Cell, Checkbox, Grid, Icon } from 'ui';

const CategoryItem = ({ category, level, checkbox, isLoading }) => {
  const {
    formatString,
    form: { notification, buttons, toastTitle },
  } = Lang();

  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);

  const initialInfoRef = useRef(null);
  const [prerenderInfo, setPrerenderInfo] = useState({
    hidden: false,
    prerenderHeaderLocation: null,
    prerenderStatusCode: null,
  });
  const [prerenderErrors, setPrerenderErrors] = useState({
    prerenderHeaderLocation: null,
    prerenderStatusCode: null,
  });
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);

  useEffect(() => {
    if (category) {
      const info = {
        hidden: category.hidden,
        prerenderHeaderLocation: category.prerenderHeaderLocation,
        prerenderStatusCode: (category.prerenderStatusCode || '').toString(),
      };

      initialInfoRef.current = info;
      setPrerenderInfo({
        ...info,
      });
    }
  }, [category]);

  const handleFieldChange = useCallback((field, value) => {
    setPrerenderInfo(currInfo => ({ ...currInfo, [field]: value }));
  }, []);

  const handleErrorChange = useCallback((field, value) => {
    setPrerenderErrors(currError => ({ ...currError, [field]: value }));
  }, []);

  const validatePrerenderCode = useCallback(
    currValue => {
      if (!prerenderInfo.hidden) {
        return true;
      }

      if (!currValue) {
        handleErrorChange(
          'prerenderStatusCode',
          notification.theFieldIsRequired,
        );
        return false;
      }

      if (currValue?.length < 3) {
        handleErrorChange(
          'prerenderStatusCode',
          formatString(notification.requiredLength, 3),
        );
        return false;
      }

      handleErrorChange('prerenderStatusCode', null);
      return true;
    },
    [formatString, handleErrorChange, notification, prerenderInfo.hidden],
  );

  const validatePrerenderLocation = useCallback(
    currValue => {
      if (!prerenderInfo.hidden) {
        return true;
      }

      if (
        !currValue &&
        (prerenderInfo.prerenderStatusCode || '').startsWith('3')
      ) {
        handleErrorChange(
          'prerenderHeaderLocation',
          notification.theFieldIsRequired,
        );
        return false;
      }

      if (
        (prerenderInfo.prerenderStatusCode || '').startsWith('3') &&
        !isUrl(currValue)
      ) {
        handleErrorChange(
          'prerenderHeaderLocation',
          notification.valueShouldBeValid,
        );
        return false;
      }

      handleErrorChange('prerenderHeaderLocation', null);
      return true;
    },
    [handleErrorChange, notification, prerenderInfo],
  );

  const handleUpdateCategory = useCallback(async () => {
    const isCodeValid = validatePrerenderCode(
      prerenderInfo.prerenderStatusCode,
    );
    const isLocationValid = validatePrerenderLocation(
      prerenderInfo.prerenderHeaderLocation,
    );

    if (!isCodeValid || !isLocationValid) {
      return;
    }

    try {
      setIsLoadingCategory(true);
      const cleanedCategoryData = { ...category };

      delete cleanedCategoryData.actualSlug;
      delete cleanedCategoryData.slug;
      delete cleanedCategoryData.childs;
      delete cleanedCategoryData.image;
      delete cleanedCategoryData.group;
      delete cleanedCategoryData.count;
      delete cleanedCategoryData.level;
      delete cleanedCategoryData.title;

      const { data } = await CATEGORIES.update(cleanedCategoryData.id, {
        ...cleanedCategoryData,
        ...prerenderInfo,
      });

      replaceCategoryData({ ...data.data, level });
      toast.success(toastTitle.successfullyUpdated);
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message &&
        typeof err.response.data.message === 'string'
      ) {
        toast.error(err.response.data.message);
      } else {
        toast.error(toastTitle.wentWrong);
      }
    } finally {
      setIsLoadingCategory(false);
    }
  }, [
    category,
    level,
    prerenderInfo,
    toastTitle.successfullyUpdated,
    toastTitle.wentWrong,
    validatePrerenderCode,
    validatePrerenderLocation,
  ]);

  return (
    <Grid
      gaps="0"
      cols="0.5fr 4fr 2fr 3fr 3fr 1.5fr"
      as={ST.TableRow}
      selected={checkbox.state.includes(category.id)}
      isLoading={isLoading}
    >
      <Cell justifySelf="center" alignSelf="center" as={ST.TableHeaderCheckbox}>
        <ST.CheckboxStyled {...checkbox} value={category.id} />
      </Cell>
      <Cell place="center start" as={ST.TableRowTitleTree} level={level}>
        <Icon as={ST.CategoryTreeIcon} />
        {category.image?.data?.main && (
          <img src={category.image.data.main} alt={category.title} />
        )}
        <ST.CategoryLink to={PATHS.CATEGORY(category.id)}>
          {category.names[currentLangState] ||
            category.names[settings.defaultLanguage]}
        </ST.CategoryLink>
      </Cell>
      {/*<Cell alignSelf="center" justifySelf="center" as={ST.TableRowTitle}>*/}
      {/*  {category.count}*/}
      {/*</Cell>*/}
      <Cell alignSelf="center" justifySelf="center" as={ST.CheckboxField}>
        <Checkbox
          checked={prerenderInfo.hidden}
          onChange={e => {
            if (!e.target.checked) {
              setPrerenderErrors({
                prerenderHeaderLocation: null,
                prerenderStatusCode: null,
              });
            }
            handleFieldChange('hidden', e.target.checked);
          }}
          disabled={isLoadingCategory}
        />
      </Cell>
      <Cell alignSelf="center" justifySelf="center" as={ST.TableRowStatus}>
        <ST.EditableFieldWrapper>
          <InputStyled
            name="prerenderStatusCode"
            value={prerenderInfo.prerenderStatusCode}
            onChange={e => {
              const currValue = e.target.value.trim();
              handleFieldChange('prerenderStatusCode', currValue);
              validatePrerenderCode(currValue);

              if (
                prerenderInfo.prerenderHeaderLocation !==
                initialInfoRef.current?.prerenderHeaderLocation
              ) {
                validatePrerenderLocation(
                  prerenderInfo.prerenderHeaderLocation,
                );
              }
            }}
            disabled={isLoadingCategory || !prerenderInfo.hidden}
          />
          <Error message={prerenderErrors.prerenderStatusCode} />
        </ST.EditableFieldWrapper>
      </Cell>
      <Cell alignSelf="center" justifySelf="center" as={ST.TableRowTitle}>
        <ST.LocationFieldWrapper>
          <InputStyled
            name="prerenderHeaderLocation"
            value={prerenderInfo.prerenderHeaderLocation}
            onChange={e => {
              const currValue = e.target.value.trim();
              handleFieldChange('prerenderHeaderLocation', currValue);
              validatePrerenderLocation(currValue);
            }}
            disabled={isLoadingCategory || !prerenderInfo.hidden}
          />
          <Error message={prerenderErrors.prerenderHeaderLocation} />
        </ST.LocationFieldWrapper>
      </Cell>
      <Cell alignSelf="center" justifySelf="center" as={ST.BtnField}>
        <CustomButton
          text={buttons.update}
          width="100px"
          backgroundColor="primary"
          color="positive"
          colorHovered="gray"
          onClick={handleUpdateCategory}
          disabled={
            isLoadingCategory ||
            prerenderErrors.prerenderHeaderLocation ||
            prerenderErrors.prerenderStatusCode ||
            isEqual(prerenderInfo, initialInfoRef.current)
          }
        />
      </Cell>
    </Grid>
  );
};

export default CategoryItem;
