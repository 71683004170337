import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Lang } from 'lang';
import { get } from 'lodash';
import { handleUploadEnd } from 'utils/filesDownload';

import TrainingAddImage from 'components/atoms/TrainingAddImage';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { Row } from 'ui';

const TrainingFormReviews = ({
  item,
  register,
  index,
  setValue,
  values,
  getIsError,
  setDeletedFiles,
  control,
  watch,
}) => {
  const { trainings, form } = Lang();

  const watchSectionShow = watch('form.reviewShow');

  const getImgData = useCallback(
    field => get(values, ['form', 'reviews', index, 'photo', field]),
    [index, values],
  );

  const handleDeleteImage = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const id = getImgData('id');

      if (id) {
        setDeletedFiles(files => [...files, id]);
      }

      setValue(`form.reviews[${index}].photo.src`, null);
      setValue(`form.reviews[${index}].photo.id`, null);
    },
    [getImgData, index, setDeletedFiles, setValue],
  );

  const handleUploaded = useCallback(
    file => {
      setValue(`form.reviews[${index}].photo.src`, file.src);
    },
    [index, setValue],
  );

  const handleUpload = useCallback(
    async acceptedFiles => {
      await handleUploadEnd({
        acceptedFiles,
        handleUploaded,
        maxAmountOfFiles: 1,
      });
    },
    [handleUploaded],
  );

  return (
    <ST.ReviewsWrapper justify="stretch">
      <Controller
        as={<input type="hidden" />}
        name={`form.reviews[${index}].photo.src`}
        control={control}
        defaultValue={item.photo?.src || null}
      />
      <TrainingAddImage
        legend={trainings.reviews.photo}
        ImageRequirements={trainings.benefitImageRequirements}
        src={getImgData('src')}
        handleUpload={handleUpload}
        handleDelete={handleDeleteImage}
      />
      <ST.FieldsWrapper direction="column">
        <FieldStyle as={FieldStyle2} legend={trainings.reviews.review}>
          <TextareaStyled
            ref={register({
              validate: watchSectionShow
                ? value => {
                    return (
                      !!value.trim() || form.notification.theFieldIsRequired
                    );
                  }
                : undefined,
            })}
            name={`form.reviews[${index}].text`}
            defaultValue={item.text}
            height="auto"
            rows="3"
          />
        </FieldStyle>
        <ST.ErrorStyled message={getIsError('text', index)} />
        <ST.RowFieldsWrapper justify="stretch">
          <Row direction="column">
            <FieldStyle as={FieldStyle2} legend={trainings.reviews.fullName}>
              <InputStyled
                ref={register({
                  validate: watchSectionShow
                    ? value => {
                        return (
                          !!value.trim() || form.notification.theFieldIsRequired
                        );
                      }
                    : undefined,
                })}
                name={`form.reviews[${index}].name`}
                defaultValue={item.name}
                type="text"
              />
            </FieldStyle>
            <ST.ErrorStyled message={getIsError('name', index)} />
          </Row>
          <Row direction="column">
            <FieldStyle as={FieldStyle2} legend={trainings.reviews.position}>
              <InputStyled
                ref={register}
                name={`form.reviews[${index}].position`}
                defaultValue={item.position}
                type="text"
              />
            </FieldStyle>
            <ST.ErrorStyled message={getIsError('position', index)} />
          </Row>
        </ST.RowFieldsWrapper>
      </ST.FieldsWrapper>
    </ST.ReviewsWrapper>
  );
};

export default TrainingFormReviews;
