import { RadioGroup } from 'reakit/Radio';

import styled from 'styled-components';

import { Radio, Row } from 'ui';

export const SectionWrapper = styled.div`
  margin-top: 15px;
`;

export const RadioGroupWrapper = styled(RadioGroup)`
  display: flex;
  justify-content: start;
  align-items: start;

  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
  }
`;

export const RadioOptionStyled = styled(Radio)`
  margin-top: 10px;
  flex-shrink: 0;

  &:first-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 1500px) {
    margin-top: 0;
    flex-shrink: 1;
  }
`;

export const NotAvailableRadio = styled(RadioOptionStyled)`
  flex-basis: 30%;

  @media screen and (max-width: 1500px) {
    flex-basis: 100%;
    margin-top: 15px;
  }
`;

export const SectionTitle = styled.span`
  display: inline-block;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
  font-weight: 500;
  font-size: 1.4rem;
  color: #b3b7bb;
  line-height: 1.4rem;
`;

export const SelectWrapper = styled(Row)`
  position: relative;
  max-width: 260px;
  margin-right: 30px;

  div[role='menu'] {
    top: 39px !important;
    right: auto !important;
    bottom: auto !important;
    left: -1px !important;
    transform: none !important;
  }

  @media screen and (max-width: 1600px) {
    max-width: 220px;
  }
`;

export const AvailableCheckoutOptions = styled(Row)`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-basis: 70%;

  @media screen and (max-width: 1500px) {
    flex-basis: 100%;
  }
`;
