import React from 'react';
import { Lang } from 'lang';

import * as ST from './styles';

const Pagination = ({
  handleClickPreviousArrow,
  handleClickNextArrow,
  handleClickOnPage,
  visiblePages,
  currPage,
  range,
  totalPages,
}) => {
  const { form } = Lang();

  return (
    <ST.PagesWrapper>
      <>
        {currPage !== 1 && (
          <ST.ArrowWrapper onClick={handleClickPreviousArrow}>
            {form.prevPage}
          </ST.ArrowWrapper>
        )}
        {currPage < visiblePages[0] && (
          <ST.PageWrapper active>{currPage}</ST.PageWrapper>
        )}
      </>
      {visiblePages.map((el, index) => (
        <ST.PageWrapper
          active={currPage === el}
          onClick={() => handleClickOnPage(el)}
          key={index}
        >
          {el}
        </ST.PageWrapper>
      ))}
      <>
        {currPage > visiblePages[range - 1] && (
          <ST.PageWrapper active>{currPage}</ST.PageWrapper>
        )}
        {currPage !== totalPages && (
          <ST.ArrowWrapper onClick={handleClickNextArrow}>
            {form.nextPage}
          </ST.ArrowWrapper>
        )}
      </>
    </ST.PagesWrapper>
  );
};

export default Pagination;
