import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row, T8y } from 'ui';

export const LeadProfileWrapper = styled(Row)`
  margin-top: 34px;
`;

export const OrderProfileRightSide = styled.div`
  flex-basis: calc(33% - 15px);
`;

export const OrderProfileLeftSide = styled.div`
  flex-basis: calc(67% - 15px);
  max-width: calc(67% - 15px);
  margin-right: 30px;
`;

export const LeadInfoHeader = styled(Row)`
  margin-bottom: 20px;
`;

export const LeadRightSideInfoBlock = styled.div`
  padding: 30px 50px;
  background: ${colors.positive};
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const LeadInfoFeature = styled(T8y)`
  color: #2e2e2e;
`;
