import styled from 'styled-components';

import { T8y } from 'ui';

export const CategoryWrapper = styled.div`
  position: relative;
  padding: 30px 30px 20px 30px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 30px;
`;

export const CategoryHeader = styled.div`
  position: absolute;
  margin-top: -40px;
  padding: 0 10px;
  background-color: white;
  display: flex;
  align-items: center;
`;

export const Delete = styled(T8y)`
  margin-left: 18px;
  cursor: pointer;
`;
