import { PATHS } from 'AppPaths';
import axios from 'axios';
import {
  API_BASENAME,
  COUNTRY_SLUG_FROM_REGION,
  ENV_BASENAME,
  getMainApiBaseName,
  getShortDisplayedCompanyName,
  PRODUCT_MAIN_IMAGE_TYPE,
} from 'consts';
import { pickBy } from 'lodash';
import { website$ } from 'models/website';

import { history } from 'libs/history';

export const instance = axios.create({
  baseURL: '/',
});

export const countryInstance = axios.create({
  baseURL: '/',
});

export const getCurrentToken = initializedRegion => {
  const region =
    initializedRegion ||
    window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
  const country = COUNTRY_SLUG_FROM_REGION[region];
  const shortCompanyName = getShortDisplayedCompanyName(country);
  if (!shortCompanyName) {
    return null;
  }

  return `${ENV_BASENAME}_${shortCompanyName}_admin`;
};

export const setToken = (region, token) => {
  localStorage.setItem(getCurrentToken(region), token);
  instance.defaults.headers['Authorization'] = `Bearer ${token}`;
  countryInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
};

export const getToken = () => {
  const region = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
  const country = COUNTRY_SLUG_FROM_REGION[region];
  const shortCompanyName = getShortDisplayedCompanyName(country);

  return localStorage.getItem(`${ENV_BASENAME}_${shortCompanyName}_admin`);
};

export const haveToken = () => getToken() !== null;

export const removeToken = () => {
  localStorage.removeItem(getCurrentToken());
  localStorage.removeItem(`${getCurrentToken()}_displayedRegionName`);
};

export const logout = () => {
  removeToken();
  history.push(PATHS.LOGIN);
};

instance.defaults.headers.common[
  'Authorization'
] = `Bearer ${localStorage.getItem(getCurrentToken())}`;

countryInstance.defaults.headers.common[
  'Authorization'
] = `Bearer ${localStorage.getItem(getCurrentToken())}`;

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      haveToken()
    ) {
      originalRequest._retry = true;
      return axios
        .post(
          '/auth/refresh',
          {},
          {
            baseURL: website$.getState().api,
            headers: { Authorization: `Bearer ${getToken()}` },
          },
        )
        .then(res => {
          if (res.status === 201) {
            setToken(res.data);

            axios.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${getToken()}`;

            return axios(originalRequest);
          } else {
            removeToken();
          }
        })
        .catch(() => {
          removeToken();
        });
    }

    return Promise.reject(error);
  },
);

export const AUTH = {
  login({ country, email, password, apiVersion }) {
    return axios
      .create({
        baseURL: `https://${API_BASENAME}.${country}/api/${apiVersion}/`,
        defaultInterceptors: true,
      })
      .post(`auth/login`, {
        email,
        password,
      });
  },
  forgot({ email, country, apiVersion }) {
    return axios
      .create({
        baseURL: `https://${API_BASENAME}.${country}/api/${apiVersion}/`,
        defaultInterceptors: true,
      })
      .post('auth/forgot', {
        email,
      });
  },
  reset({
    password,
    token,
    email,
    password_confirmation,
    country,
    apiVersion,
  }) {
    return axios
      .create({
        baseURL: `https://${API_BASENAME}.${country}/api/${apiVersion}/`,
        defaultInterceptors: true,
      })
      .post('auth/reset', {
        password: password,
        password_confirmation,
        token,
        email,
      });
  },
};

export const SETTINGS = {
  getGroupSettings() {
    return instance.get('admin/settings/allSettings');
  },
  getAllSettings() {
    return instance.get('admin/settings/general');
  },
  updateSetting(id) {
    return instance.post(`admin/settings/${id}`);
  },
  getBranding() {
    return instance.get('admin/settings/branding');
  },
  updateGeneral(params) {
    return instance.put('admin/settings/general', params);
  },
  updateBranding(params) {
    return instance.put('admin/settings/branding', params);
  },
  updateEnquiry(params) {
    return instance.put('admin/setting/leadCustomQuestion', params);
  },
  updateDates(params) {
    return instance.put('admin/setting/updateAvailableDates', params);
  },
};

export const USER = {
  me({ country, token, apiVersion }) {
    const countryOnly = axios.create({
      baseURL: `https://${API_BASENAME}.${country}/api/${apiVersion}/`,
      defaultInterceptors: true,
    });
    countryOnly.defaults.headers['Authorization'] = `Bearer ${token}`;
    return countryOnly.get('auth/me');
  },
};

export const TIERS = {
  getAllTiers() {
    return instance.get('admin/getTiers');
  },
};

export const CATEGORIES = {
  all() {
    return instance.get('admin/categories?all=1&products_count=1');
  },
  archived() {
    return instance.get('admin/categories?all=1&products_count=1&archived=1');
  },
  deleteCover(id) {
    return instance.post(`admin/categories/${id}/deleteCover`);
  },
  delete(id) {
    return instance.delete(`admin/categories/${id}`);
  },
  loadCategory(id) {
    return instance.get(`admin/categories/${id}`);
  },
  create(params) {
    return instance.post('admin/categories', params);
  },
  update(id, params) {
    return instance.put(`admin/categories/${id}`, params);
  },
  upload(file) {
    return instance.post(`admin/categories/upload`, file);
  },
  sort(data) {
    return instance.put('admin/categories/sorting', data);
  },
};

export const PRODUCTS = {
  getAll(params, cancelToken) {
    return instance.get('admin/products', {
      params,
      cancelToken,
    });
  },
  loadProduct(id) {
    return instance.get(`admin/products/${id}`);
  },
  getEventsTypes() {
    return instance.get('admin/events');
  },
  create(params) {
    return instance.post('admin/products', params);
  },
  delete(id) {
    return instance.delete(`admin/products/${id}`);
  },
  update(id, params) {
    return instance.put(`admin/products/${id}`, params);
  },
  getRequiredExtras() {
    return instance.get('admin/extras?types[]=required-extra&limit=1000');
  },
  getDeliveryTypes() {
    return instance.get('admin/extras?types[]=delivery');
  },
  getSafetyRecommendations() {
    return instance.get('admin/safetyRecommendations');
  },
  createExtra(params) {
    return instance.post('admin/extras', params);
  },
  updateExtra(id, params) {
    return instance.put(`admin/extras/${id}`, params);
  },
  getOptionalExtras() {
    return instance.get(
      'admin/extras?types[]=optional-extra&types[]=optional-sale-extra&limit=1000',
    );
  },
  getConsumables() {
    return instance.get('admin/extras?types[]=consumables');
  },
  updateQuantityAndPrice(id, params) {
    const allowed_params = ['name', 'id', 'max_quantity', 'price'];
    const save_params = pickBy(params, (_, p) => allowed_params.includes(p));

    return instance.put(`admin/products/${id}`, save_params);
  },
  updatePopularity(id, params) {
    return instance.put(`/admin/products/${id}/popularity`, params);
  },
};

export const FILES = {
  upload(file) {
    return instance.post(`admin/uploads`, file);
  },
  attachUploaded(fileData) {
    return instance.post('admin/files', { ...fileData });
  },
  deleteUploaded(id) {
    return instance.delete(`admin/files/${id}`);
  },
};

export const MEDIA = {
  deleteUploaded(id) {
    return instance.delete(`admin/media/${id}`);
  },
  changeOnMainType(id) {
    return instance.put(`admin/media/${id}`, { type: PRODUCT_MAIN_IMAGE_TYPE });
  },
};

export const CATREGORIES = {
  getAll() {
    return instance.get(`admin/categories`, { params: { all: 1 } });
  },
  getCategory(id) {
    return instance.get(`admin/category/${id}`);
  },
};

export const LANDING = {
  getPackages() {
    return instance.get(`/attribute/package`);
  },
};

export const WAREHOUSES = {
  getAllWarehouses() {
    return instance.get('/admin/warehouses');
  },
  createWarehouse(params) {
    return instance.post('admin/warehouses', params);
  },
  updateWarehouse({ params, id }) {
    return instance.put(`admin/warehouses/${id}`, params);
  },

  deleteWarehouse(id) {
    return instance.delete(`/admin/warehouses/${id}`);
  },
};

export const BANNERS = {
  getAllBanners() {
    return instance.get('admin/banners');
  },
  deleteBanner(id) {
    return instance.delete(`admin/banners/${id}`);
  },
  updateBanner(id) {
    return instance.put(`admin/banners/${id}`);
  },
  createBanner(params) {
    return instance.post('admin/banners', params);
  },
  updateBanners(params) {
    return instance.put('admin/banners', params);
  },
};

export const ANALYTICS = {
  getAllReports() {
    return instance.get('admin/reports', { params: { limit: 10000 } });
  },
  deleteReport(id) {
    return instance.delete(`admin/reports/${id}`);
  },
  updateReport(id, params) {
    return instance.put(`admin/reports/${id}`, params);
  },
  createReport(params) {
    return instance.post('admin/reports', params);
  },
  updateBanners(params) {
    return instance.put('admin/banners', params);
  },
};

export const USERS = {
  getAllUsers(params) {
    return instance.get('admin/users', { params });
  },
  getUser(id) {
    return instance.get(`admin/users/${id}`);
  },
  createUser(params) {
    return instance.post('admin/users/', params);
  },
  updateUser(id, params) {
    return instance.patch(`admin/users/${id}`, params);
  },
  getUserToken(id) {
    return instance.get(`admin/users/${id}/token`);
  },
};

export const ORDERS = {
  getOrder(userId, orderId, params) {
    return instance.get(`admin/users/${userId}/orders/${orderId}`, { params });
  },
  getOrders(id, params) {
    return instance.get(`admin/users/${id}/orders`, { params });
  },
  downloadDocument(orderId, file) {
    return instance.post(`admin/orders/${orderId}/upload`, file);
  },
  deleteDocument(id) {
    return instance.delete(`admin/media/${id}`);
  },
  cancelOrder(id, params) {
    return instance.post(`/admin/orders/${id}/requests/cancel`, params);
  },
  sendAnswerForCancelOrder(orderId, requestId, status) {
    return instance.put(
      `admin/orders/${orderId}/requests/${requestId}/${status}`,
    );
  },
  updateDepositRefundMethod(orderId, params) {
    return instance.put(`admin/orders/${orderId}/deposit`, params);
  },
};

export const TRANSPORTATION = {
  getAllDeliveries() {
    return instance.get('admin/deliveries');
  },
  updateAllDeliveries(params) {
    return instance.post('admin/batch/deliveries', params);
  },
  deleteDelivery(id) {
    return instance.delete(`admin/deliveries/${id}`);
  },
  getAreas() {
    return instance.get('admin/areas');
  },
  getShortDeliveries() {
    return instance.get('admin/deliveriesSelector');
  },
};

export const TRAININGS = {
  getAllTrainings() {
    return instance.get('admin/trainings');
  },
  getTrainingIndex() {
    return instance.get('admin/trainingIndex/1');
  },
  updateTrainingIndex(params) {
    return instance.put('admin/trainingIndex/1', params);
  },
  getTraining(id) {
    return instance.get(`admin/trainings/${id}`);
  },
  createTraining(params) {
    return instance.post('admin/trainings', params);
  },
  updateTraining(id, params) {
    return instance.put(`admin/trainings/${id}`, params);
  },
  updateTrainingOrderId(id, params) {
    return instance.put(`admin/trainings/${id}/sorting`, params);
  },
};

export const INTEGRATIONS = {
  getAllIntegrations() {
    return instance.get('admin/integrations');
  },
  findIntegrationInfoById(id) {
    return instance.get(`admin/integrations/${id}/check`);
  },
};

export const POPULAR_PRODUCTS = {
  getAllPopularProducts() {
    return instance.get('admin/popularProducts');
  },
  addPopularProduct(params) {
    return instance.post(`admin/popularProducts`, params);
  },
  removePopularProduct(id) {
    return instance.delete(`admin/popularProducts/${id}`);
  },
  reorderPopularProduct(id, params) {
    return instance.put(`admin/popularProducts/${id}/sorting`, params);
  },
};

export const LOGS = {
  getAllLogs(type, id, params) {
    return instance.get(`admin/logs/${type}/${id}`, { params });
  },
};

export const EXPORT = {
  getAllProducts() {
    return instance.get('admin/export/products');
  },
  getAllTrainings() {
    return instance.get('admin/export/trainings');
  },
};

export const LEADS = {
  getLeads(params) {
    return instance.get('admin/leads', { params });
  },
  getLead(id, params) {
    return instance.get(`admin/leads/${id}`, { params });
  },
  getLeadsFilterTypes() {
    return instance.get('admin/leads/types');
  },
};

export const OFFERS = {
  getOffers(params) {
    return instance.get('admin/offers', { params });
  },
  getOffer(id, params) {
    return instance.get(`admin/offers/${id}`, { params });
  },
  createOffer(params) {
    return instance.post('admin/offers', params);
  },
};

export const QUERIES = {
  getCartById(id) {
    return instance.get(`admin/queries/${id}`);
  },
};

export const CART = {
  getTotalPrice(params) {
    const mainApp = axios.create({
      baseURL: `https://${getMainApiBaseName()}.${
        website$.getState().country
      }/api/${website$.getState().regionSlug}`,
    });

    return mainApp.post(`/totalPrice`, params);
  },
};

export const PROMO_CODES = {
  getAllPromoCodes(params) {
    return instance.get('admin/promocodes', { params });
  },
  createPromoCode(params) {
    return instance.post('admin/promocodes', params);
  },
  updatePromoCode(id, params) {
    return instance.put(`admin/promocodes/${id}`, params);
  },
  deletePromoCode(id) {
    return instance.delete(`admin/promocodes/${id}`);
  },
};

export const OWNERS = {
  getOwners() {
    return instance.get(`admin/franchisee`);
  },
};

export const JOB_TYPES = {
  getJobTypes(params) {
    return instance.get('admin/jobTypes', { params });
  },
  getJobType(id, params) {
    return instance.get(`admin/jobTypes/${id}`, { params });
  },
  createJobType(params) {
    return instance.post('admin/jobTypes', params);
  },
  updateJobType(id, params) {
    return instance.put(`admin/jobTypes/${id}`, params);
  },
};

export const FAQS = {
  getAllFaqs() {
    return countryInstance.get('admin/faqs');
  },
  updateFaqs(params) {
    return countryInstance.put('admin/faqs', params);
  },
};

export const MEMBERSHIP = {
  getAllMemberships() {
    return instance.get('admin/getMembershipLevels');
  },
};

export const TRADE_ACCOUNTS = {
  searchCompany(params) {
    return instance.get('admin/companies/search', { params });
  },
  getAllCompanies(params) {
    return instance.get('admin/accounts/companies', { params });
  },
  getAllCustomers(params) {
    return instance.get('admin/accounts/customers', { params });
  },
  getCustomer(id) {
    return instance.get(`admin/accounts/customers/${id}`);
  },
  getCompany(id) {
    return instance.get(`admin/accounts/companies/${id}`);
  },
  createUser(params) {
    return instance.post('admin/accounts/', params);
  },
  updateTradeAccount(id, params) {
    return instance.post(`admin/accounts/${id}/update`, params);
  },
  getAccountOrders(accountId, params) {
    return instance.get(`admin/accounts/${accountId}/orders`, {
      params,
    });
  },
};

export const SUPPLIERS = {
  getAllSuppliers() {
    return instance.get('admin/supplier');
  },
  addSupplier(orderId, supplierId) {
    return instance.put(`/admin/orders/${orderId}/suppliers/${supplierId}`);
  },
};
