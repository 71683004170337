import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import {
  NOT_AVAILABLE,
  PRODUCT_MAIN_IMAGE_TYPE,
  SELF_COLLECTION,
  SELF_COLLECTION_ONLY,
} from 'consts';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { branding$ } from 'models/branding';
import {
  categoriesGate,
  categoriesTreeStore,
  getCategoriesTree,
} from 'models/categories';
import { getShortDeliveries, shortDeliveries$ } from 'models/deliveries';
import { IntegrationsGate } from 'models/integrations';
import { isLoadingOwners$, ownersGate } from 'models/owners';
import {
  isLoadingProduct$,
  relatedProductsStore,
  variationsStore,
} from 'models/product';
import * as productModel from 'models/product';
import { settings$ } from 'models/settings';
import { availableWarehouses$, getWarehouses } from 'models/warehouses';
import { website$ } from 'models/website';
import { getIsPriceRequired } from 'utils/helpers/productForm';

import { changeProductsTablePage } from 'features/filter/filterModel';

import SelectProductOwner from 'components/molecules/SelectProductOwner';
import ProductEditor from 'components/organisms/ProductEditor';
import { generateURL } from 'components/Product/shared';
import { history } from 'libs/history';
import { PRODUCTS } from 'api';

import {
  allProductsWithoutFilters$,
  changeSearchStr,
  currentProductOwner$,
  getAllProductsWithoutFiltersFx,
  setCurrentProductOwner,
} from './model';

import { Center, Spinner } from 'ui';

const addAttribute = (arr, key, value, enabled) => {
  if (value !== '') {
    arr.push({ name: key, value, enabled });
  }
};

const getCategories = product => {
  let level1 = null;
  let level2 = null;
  let level3 = null;

  if (product.category && product.category.data) {
    level1 = product.category.data.id;

    if (product.category.data.parent && product.category.data.parent.data) {
      level2 = product.category.data.id;
      level1 = product.category.data.parent.data.id;

      if (
        product.category.data.parent.data.parent &&
        product.category.data.parent.data.parent.data
      ) {
        level3 = product.category.data.id;
        level2 = product.category.data.parent.data.id;
        level1 = product.category.data.parent.data.parent.data.id;
      }
    }
  }

  return { level1, level2, level3 };
};

const getAttribute = (obj, key, currentAttributes) => {
  const attribute = obj?.attributes?.find(attr => attr.slug === key);
  currentAttributes[key] = attribute?.value || '';

  return currentAttributes;
};

const specificationsFields = [
  'measurements',
  'capacity',
  'excavation-depth',
  'cutting-width',
  'cutting-depth',
  'disk-diameter',
  'notes',
  'hole-diameter',
  'power',
  'max-working-height',
  'max-height',
  'weight',
  'crane-capacity',
];

const defaultFaqs = new Array(5).fill({ question: '', answer: '' });
const defaultFeatures = new Array(4).fill({ value: '' });

const Product = ({ match }) => {
  const availableWarehouses = useStore(availableWarehouses$);
  const settings = useStore(settings$);
  const branding = useStore(branding$);
  const shortDeliveries = useStore(shortDeliveries$);
  const copiedProductId = useStore(productModel.copiedProductIdStore);
  const currentLangState = useStore(currentLang$);
  const relatedProducts = useStore(relatedProductsStore);

  const { form, product: productLocalized } = Lang();

  const [loading, setLoading] = useState(false);
  const [isValidPhotos, setIsValidPhotos] = useState(true);
  const [isRedirectBlocked, setIsRedirectBlocked] = useState(true);

  const product_id = match.params.id || copiedProductId;
  const isNewProduct = !match.params.id;

  useEffect(() => {
    getWarehouses();
    getShortDeliveries();

    return () => {
      productModel.clearProductStore();
      if (isNewProduct) {
        productModel.clearCopiedProductIdStore();
      }
      if (history.location.pathname !== PATHS.PRODUCTS) {
        changeSearchStr('');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGate(productModel.allRequiredExtraGate);
  useGate(productModel.allOptionalExtraGate);
  useGate(productModel.additionalFieldsGate);
  useGate(productModel.loadProductGate, product_id);
  useGate(categoriesGate);
  useGate(IntegrationsGate);
  useGate(ownersGate);

  const isLoadingProduct = useStore(isLoadingProduct$);
  const isLoadingOwners = useStore(isLoadingOwners$);
  const product = useStore(productModel.copiedProductStore);
  const allProductsWithoutFilters = useStore(allProductsWithoutFilters$);
  const photos = useStore(productModel.photosStore);
  const guides = useStore(productModel.guidesStore);
  const categories = useStore(categoriesTreeStore);
  const selected = useStore(variationsStore);
  const deletedFiles = useStore(productModel.deletedMediaAndFilesStore);
  const currentProductOwner = useStore(currentProductOwner$);
  const website = useStore(website$);

  useEffect(() => {
    if (product.owner) {
      setCurrentProductOwner(product.owner?.data);
    }
    return () => setCurrentProductOwner(null);
  }, [product]);

  const specifications = useMemo(
    () =>
      specificationsFields.reduce((acc, el) => {
        acc = getAttribute(product, el, acc);
        return acc;
      }, {}),
    [product],
  );

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    control,
    errors,
    reset,
    setError,
    clearErrors,
    trigger,
    unregister,
    formState,
  } = useForm({
    defaultValues: {
      max_quantity: '',
      min_period_days: '',
      popularity: '',
      video: '',
      slug: '',
      seo_title: '',
      meta_description: '',
      seo_h1: '',
      seo_robots: '',
      label: '',
      isDamageWaiver: false,
      isInsurance: false,
      isInsurancePerDay: false,
      safety: false,
      sanitation: false,
      video_viewable: false,
      hidden: true,
      carbonElementsShow: false,
      isOutOfStock: false,
      damageWaiverIndividualFee: 0,
      damageWaiverBusinessFee: 0,
      insuranceIndividualFee: 0,
      insuranceBusinessFee: 0,
      insuranceIndividualPerDayFee: 0,
      insuranceBusinessPerDayFee: 0,
      daysMarginBeforeRental: 0,
      deposit: '0',
      deliveryOption: SELF_COLLECTION_ONLY,
      category_id: null,
      sub_category_id: null,
      prerenderStatusCode: null,
      prerenderHeaderLocation: null,
      sub_sub_category_id: null,
      additional_prices: null,
      faqs: defaultFaqs,
      feature: defaultFeatures,
      prices: [{ period_from: 1, period_to: '', price: '' }],
      other_charges: [],
      customAttributes: [],
      safetyRecommendations: [],
      integrations: [],
      variations: [],
      required_extra: [],
      optional_extra: [],
      consumables: [],
      allWarehouses: [],
      descriptions: {},
      owner: null,
      publish: false,
      displayedRange: 'day',
      needIDVerification: false,
      foreign_id: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const { fields } = useFieldArray({
    control,
    name: 'allWarehouses',
  });

  const watchAll = watch();

  const productCategoriesTree = useMemo(() => getCategories(product), [
    product,
  ]);

  useEffect(
    () => {
      if (isNewProduct && settings.supportedLanguages.length) {
        reset({
          ...getValues(),
          names: settings.supportedLanguages.reduce((acc, el) => {
            acc[el] = '';
            return acc;
          }, {}),
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset, settings],
  );

  useEffect(
    () => {
      if (isNewProduct) {
        reset({
          ...getValues(),
          other_charges: branding.otherChargesDefault,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset, branding],
  );

  const productCharges = useMemo(
    () =>
      product.attributes?.reduce((acc, el) => {
        if (el.slug === 'damageWaiverIndividualFee') {
          acc.damageWaiverIndividualFee = el;
        }
        if (el.slug === 'damageWaiverBusinessFee') {
          acc.damageWaiverBusinessFee = el;
        }
        if (el.slug === 'insuranceIndividualFee') {
          acc.insuranceIndividualFee = el;
        }
        if (el.slug === 'insuranceBusinessFee') {
          acc.insuranceBusinessFee = el;
        }
        if (el.slug === 'insuranceIndividualPerDayFee') {
          acc.insuranceIndividualPerDayFee = el;
        }
        if (el.slug === 'insuranceBusinessPerDayFee') {
          acc.insuranceBusinessPerDayFee = el;
        }
        if (el.slug === 'safety') {
          acc.safety = el;
          acc.safety.enabled = el.value === 'Y' || false;
        }
        if (el.slug === 'sanitation') {
          acc.sanitation = el;
          acc.sanitation.enabled = el.value === 'Y' || false;
        }
        if (el.slug === 'deposit') {
          acc.deposit = el;
        }
        return acc;
      }, {}) || {},
    [product],
  );

  const { required_extra, optional_extra, consumables, featuresList } = useMemo(
    () =>
      product.attributes?.reduce(
        (acc, el) => {
          if (el.slug === 'required-extra') {
            acc.required_extra.push({ serverId: el.id });
          }
          if (
            el.slug === 'optional-extra' ||
            el.slug === 'optional-sale-extra'
          ) {
            acc.optional_extra.push({ serverId: el.id });
          }
          if (el.slug === 'consumables') {
            acc.consumables.push({ serverId: el.id });
          }
          if (el.slug === 'feature') {
            acc.featuresList.push({ value: el.value });
          }
          return acc;
        },
        {
          required_extra: [],
          optional_extra: [],
          consumables: [],
          featuresList: [],
        },
      ) || {
        required_extra: [],
        optional_extra: [],
        consumables: [],
        featuresList: [],
      },
    [product],
  );

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const getProductPrices = useCallback(
    product => {
      const prices =
        product.prices && product.prices.data && product.prices.data.length
          ? product.prices.data
          : [{ period_from: '', period_to: '', price: '' }];

      if (settings.fixedRangesPrice) {
        return prices.some(el => Number(el.period_from) === 1)
          ? prices
          : [{ period_from: 1, period_to: '', price: '' }, ...prices];
      }
      return prices;
    },
    [settings],
  );

  useEffect(() => {
    if (isNewProduct) {
      if (availableWarehouses.length) {
        const newWarehouses = [
          ...availableWarehouses.map(el => {
            const copyEl = {
              ...el,
              serverId: el.id,
              quantity: 0,
              isAdded: false,
            };

            delete copyEl['id'];
            return copyEl;
          }),
        ];
        setValue('allWarehouses', newWarehouses);
      }
      setValue('owner', currentProductOwner);
    }

    if (!product.id) {
      return;
    }

    const category_id = productCategoriesTree.level1;
    const sub_category_id = productCategoriesTree.level2;
    const sub_sub_category_id = productCategoriesTree.level3;

    const isInsurance =
      productCharges.insuranceIndividualFee &&
      productCharges.insuranceIndividualFee.enabled;

    const isInsurancePerDay =
      productCharges.insuranceIndividualPerDayFee &&
      productCharges.insuranceIndividualPerDayFee.enabled;

    const faqs =
      product.faqs.length > 4
        ? product.faqs
        : product.faqs.concat(defaultFaqs.slice(0, 5 - product.faqs.length));

    const feature =
      featuresList.length > 3
        ? featuresList
        : featuresList.concat(
            defaultFeatures.slice(0, 4 - featuresList.length),
          );

    const deliveryOption =
      currentProductOwner && !currentProductOwner.checkoutAvailability
        ? null
        : product.deliveryOption;

    reset({
      allWarehouses: [
        ...availableWarehouses.map(el => {
          const addedWarehouse =
            product &&
            product.warehouses &&
            product.warehouses.find(house => house.id === el.id);
          const defaultQuantity = addedWarehouse && addedWarehouse.quantity;

          const copyEl = {
            ...el,
            serverId: el.id,
            quantity: defaultQuantity,
            isAdded: !!addedWarehouse,
          };

          delete copyEl['id'];
          return copyEl;
        }),
      ],
      ...product,
      prerenderStatusCode: product.prerenderStatusCode
        ? product.prerenderStatusCode.toString()
        : null,
      seo_title: product.seo_title || '',
      meta_description: product.meta_description || '',
      seo_h1: product.seo_h1 || '',
      seo_robots: product.seo_robots || '',
      max_quantity: product.max_quantity?.toString() || '',
      popularity: product.popularity?.toString() || '',
      daysMarginBeforeRental: product.daysMarginBeforeRental || 0,
      min_period_days: product.min_period_days?.toString() || '',
      safety: productCharges.safety?.enabled,
      sanitation: productCharges.sanitation?.enabled,
      damageWaiverIndividualFee:
        productCharges.damageWaiverIndividualFee?.value || 0,
      damageWaiverBusinessFee:
        productCharges.damageWaiverBusinessFee?.value || 0,
      isDamageWaiver:
        productCharges.damageWaiverIndividualFee?.enabled || false,
      insuranceIndividualFee: productCharges.insuranceIndividualFee?.value || 0,
      insuranceBusinessFee: productCharges.insuranceBusinessFee?.value || 0,
      isInsurance,
      insuranceIndividualPerDayFee:
        productCharges.insuranceIndividualPerDayFee?.value || 0,
      insuranceBusinessPerDayFee:
        productCharges.insuranceBusinessPerDayFee?.value || 0,
      isInsurancePerDay,
      deposit: productCharges.deposit?.value || '0',
      deliveryOption,
      video_viewable: product.video_viewable,
      video: product.video?.src || '',
      hidden: product.hidden,
      carbonElementsShow: product.carbonElementsShow,
      isOutOfStock: product.isOutOfStock,
      displayedRange:
        website.shortCompanyName === 'ETH'
          ? product.displayedRange || 'day'
          : undefined,
      prices: getProductPrices(product),
      customAttributes: product.custom_attributes || [],
      other_charges: product.other_charges?.map(item =>
        settings.supportedLanguages.reduce((acc, el) => {
          acc[el] = item[el] || '';
          return acc;
        }, {}),
      ),
      slug: product.slug,
      label: product.label || '',
      names: settings.supportedLanguages.reduce((acc, el) => {
        acc[el] = product.names[el] || '';
        return acc;
      }, {}),
      descriptions: product.descriptions,
      additional_prices: product.additional_prices || null,
      safetyRecommendations: product.safetyRecommendations?.data || [],
      faqs,
      feature,
      category_id,
      sub_category_id,
      sub_sub_category_id,
      required_extra,
      optional_extra,
      consumables,
      variations: selected,
      ...specifications,
      publish: false,
      owner: currentProductOwner || null,
      foreign_id: product.foreignId || '',
      weekendRateEnabled: product.weekendRateEnabled || false,
      weekendRatePrice: product.weekendRatePrice || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product,
    reset,
    availableWarehouses,
    settings,
    isNewProduct,
    branding,
    currentProductOwner,
  ]);

  useEffect(() => {
    if (!categories || !categories.length) {
      getCategoriesTree();
    }

    if (!allProductsWithoutFilters || !allProductsWithoutFilters.length) {
      getAllProductsWithoutFiltersFx();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUploaded = async files => {
    await Promise.all(
      files.map(el => {
        if (el.type === 'file') {
          return new Promise(resolve => {
            productModel.deleteUploadedFileFx(el.id);
            resolve();
          });
        }

        return new Promise(resolve => {
          productModel.deleteUploadedPhotoFx(el.id);
          resolve();
        });
      }),
    );
  };

  const attachUploaded = useCallback(async (files, id, fileType) => {
    await Promise.all(
      files
        .filter(el => el && el.value)
        .map(el => {
          return new Promise(resolve => {
            productModel.uploadPhotoFx({
              src: el.value,
              name: el.name || undefined,
              object_id: id,
              type:
                fileType || (el.isMain ? PRODUCT_MAIN_IMAGE_TYPE : 'gallery'),
            });
            resolve();
          });
        }),
    );
  }, []);

  const getFilesForAttach = useCallback(
    currFiles => {
      if (copiedProductId) {
        return currFiles;
      }

      return currFiles.filter(el => !el.id);
    },
    [copiedProductId],
  );

  const handleChangeMainProductImage = useCallback(async mainImage => {
    if (!mainImage) {
      return;
    }

    if (mainImage.id) {
      await productModel.changeOnMainTypeFx(mainImage.id);
    }
  }, []);

  const isNoPhotos = useMemo(() => {
    return !photos.some(el => el.value);
  }, [photos]);

  const onError = useCallback(() => {
    if (isNoPhotos) {
      setIsValidPhotos(false);
    }
  }, [isNoPhotos]);

  const onSubmit = async data => {
    if (isNoPhotos) {
      setIsValidPhotos(false);
      toast.error(form.toastTitle.invalidForm);
      return;
    }

    setLoading(true);

    if (deletedFiles && deletedFiles.length) {
      await deleteUploaded(deletedFiles);
    }

    const isOtherChargesNoEmpty = data.other_charges.some(el =>
      Object.keys(el).some(item => el[item] !== ''),
    );

    let category_id = parseInt(data.category_id, 10);
    let sub_category_id = parseInt(data.sub_category_id, 10);
    if (sub_category_id > 0) {
      category_id = sub_category_id;
    }
    let sub_sub_category_id = parseInt(data.sub_sub_category_id, 10);

    if (sub_sub_category_id > 0) {
      category_id = sub_sub_category_id;
    }

    let delivery_id =
      currentProductOwner && !currentProductOwner.checkoutAvailability
        ? shortDeliveries.find(el => el.slug === NOT_AVAILABLE)?.id
        : null;

    if (!delivery_id) {
      delivery_id =
        data.deliveryOption === SELF_COLLECTION_ONLY
          ? shortDeliveries.find(el => el.slug === SELF_COLLECTION)?.id
          : data.delivery_id;
    }

    const isPriceRequired = getIsPriceRequired(
      settings.requiredPricesEnabled,
      data.prices,
    );

    let result = {
      description: data.description,
      sku: data.sku,
      daysMarginBeforeRental: data.daysMarginBeforeRental || 0,
      names: { ...data.names },
      descriptions: { ...data.descriptions },
      category_id: category_id,
      max_quantity: parseInt(data.max_quantity, 10),
      attributes: [],
      faqs: [],
      reviews: [],
      images: [],
      guides: [],
      slug: generateURL(data.slug),
      custom_attributes: data.customAttributes || null,
      video_viewable: data.video_viewable,
      video: data.video || null,
      warehouses: !data.isActiveIntegration
        ? data.allWarehouses
            .filter(el => el.isAdded)
            .map(el => ({ quantity: el.quantity, id: el.serverId }))
        : [],
      hidden: data.publish ? false : data.hidden,
      carbonElementsShow: data.carbonElementsShow,
      isOutOfStock: data.isOutOfStock,
      prerenderStatusCode: data.isOutOfStock ? data.prerenderStatusCode : null,
      prerenderHeaderLocation: data.isOutOfStock
        ? data.prerenderHeaderLocation
        : null,
      label: data.label,
      other_charges: isOtherChargesNoEmpty ? data.other_charges : [],
      min_period_days: data.min_period_days,
      relatedProducts,
      safetyRecommendations: data.safetyRecommendations.map(el => el.id),
      productIntegrations: data.productIntegrations,
      deliveryOption: data.deliveryOption,
      franchiseeId: data.owner?.id || null,
      displayedRange: data.displayedRange,
      prices: isPriceRequired
        ? data.prices.map(el => ({ ...el, isDisable: undefined }))
        : null,
      needIDVerification: data.needIDVerification,
      foreign_id: data.foreign_id,
      weekendRateEnabled: data.weekendRateEnabled,
      weekendRatePrice: data.weekendRatePrice || null,
    };

    if (data.popularity) {
      result.popularity = parseInt(data.popularity, 10);
    }

    if (settings.fixedRangesPrice) {
      result.outOfRangeRatio = Number(data.outOfRangeRatio);
    }

    if (data.customAttributes) {
      result.custom_attributes = data.customAttributes;
    }

    result.seo_title = data.seo_title || undefined;
    result.meta_description = data.meta_description || undefined;
    result.seo_h1 = data.seo_h1 || undefined;
    result.seo_robots = data.seo_robots || '';
    result.related = data.related || [];
    result.variations = selected || [];
    result.delivery_id = delivery_id;
    specificationsFields.forEach(el =>
      addAttribute(result.attributes, el, data[el]),
    );
    addAttribute(result.attributes, 'safety', data.safety ? 'Y' : 'N');
    addAttribute(result.attributes, 'sanitation', data.sanitation ? 'Y' : 'N');
    addAttribute(
      result.attributes,
      'deposit',
      data.deposit?.toString().trim() || '0',
    );
    addAttribute(
      result.attributes,
      'damageWaiverIndividualFee',
      data.damageWaiverIndividualFee,
      data.isDamageWaiver,
    );
    addAttribute(
      result.attributes,
      'damageWaiverBusinessFee',
      data.damageWaiverBusinessFee,
      data.isDamageWaiver,
    );
    addAttribute(
      result.attributes,
      'insuranceIndividualFee',
      data.insuranceIndividualFee,
      data.isInsurance,
    );
    addAttribute(
      result.attributes,
      'insuranceBusinessFee',
      data.insuranceBusinessFee,
      data.isInsurance,
    );
    addAttribute(
      result.attributes,
      'insuranceIndividualPerDayFee',
      data.insuranceIndividualPerDayFee,
      data.isInsurancePerDay,
    );
    addAttribute(
      result.attributes,
      'insuranceBusinessPerDayFee',
      data.insuranceBusinessPerDayFee,
      data.isInsurancePerDay,
    );

    data.feature.forEach(el => {
      if (el.value !== '') {
        addAttribute(result.attributes, 'feature', el.value);
      }
    });

    let extras = [];

    if (data.required_extra) {
      data.required_extra.forEach(extra => {
        extras.push({ id: extra.serverId });
      });
    }

    if (data.optional_extra) {
      data.optional_extra.forEach(extra => {
        extras.push({ id: extra.serverId });
      });
    }

    if (data.consumables && settings?.extraConsumablesEnabled) {
      data.consumables.forEach(extra => {
        extras.push({ id: extra.serverId });
      });
    }

    result.extras = extras;

    data.faqs
      .filter(({ question, answer }) => question !== '' && answer !== '')
      .forEach(faq => {
        result.faqs.push(faq);
      });

    if (data.reviews) {
      data.reviews.forEach(review => {
        result.reviews.push({
          ...review,
          rating: review.rating ? parseInt(review.rating, 10) : 0,
        });
      });
    }

    try {
      const response = isNewProduct
        ? await PRODUCTS.create(result)
        : await PRODUCTS.update(product_id, result);
      const newProduct = response.data && response.data.data;

      if (newProduct && newProduct.id && photos && photos.length) {
        const mainImage = photos.find(el => !!el.isMain);
        await handleChangeMainProductImage(mainImage);
        await attachUploaded(getFilesForAttach(photos), newProduct.id);
      }

      if (newProduct && newProduct.id && guides && guides.length) {
        await attachUploaded(getFilesForAttach(guides), newProduct.id, 'guide');
      }

      toast(
        `Product ${result.name ||
          result.names[currentLangState] ||
          result.names[settings.defaultLanguage]} ${
          form.toastTitle[isNewProduct ? 'wasCreated' : 'wasUpdated']
        }`,
      );
      setIsRedirectBlocked(false);
      changeProductsTablePage(1);
      history.push(PATHS.PRODUCTS);
    } catch (err) {
      console.warn(err);
      let resError = null;
      if (err.response?.data?.data) {
        const firstError = err.response.data.data.split(',')[0];
        resError =
          firstError === 'The sku has already been taken.'
            ? productLocalized.notifications.skuAlreadyBeenTaken
            : firstError;
      }

      toast.error(resError || form.toastTitle.wentWrong);
    } finally {
      setLoading(false);
    }
  };

  const getIsProductFieldsChanged = useCallback(
    () => {
      if (Object.values(formState.dirtyFields).length) {
        return true;
      }

      const values = getValues({ nest: true });

      if (product.id) {
        const getIsItemsChanged = (items = [], product, field, withoutField) =>
          items.length !== product[field]?.length ||
          product[field]?.some((el, index) => {
            const currItem = withoutField ? items[index] : items[index].id;
            return el.id !== currItem;
          });

        const getIsGalleryChanged = (photos, product) =>
          photos.filter(el => el.value).length !==
            product.gallery?.data.length ||
          product.gallery?.data.some(
            (el, index) =>
              el.isMain !== photos[index].isMain || el.id !== photos[index].id,
          );

        const getIsCategoriesChanged = (productCategoriesTree, values) =>
          (productCategoriesTree.level1 &&
            productCategoriesTree.level1 !== Number(values.category_id)) ||
          (productCategoriesTree.level2 &&
            productCategoriesTree.level2 !== Number(values.sub_category_id)) ||
          (productCategoriesTree.level3 &&
            productCategoriesTree.level3 !==
              Number(values.sub_sub_category_id));

        const getIsWarehousesChanged = (values, product) => {
          const selectedWarehouses = values.allWarehouses.filter(
            el => el.isAdded,
          );
          return (
            selectedWarehouses.length !== product.warehouses?.length ||
            product.warehouses?.some(
              (el, index) =>
                el.id !== selectedWarehouses[index].serverId ||
                el.quantity !== selectedWarehouses[index].quantity,
            )
          );
        };

        const getIsDocumentsChanged = (documents, product) =>
          documents?.filter(el => el.value).length !==
            product.guides?.data.length ||
          product.guides?.data?.some(
            (el, index) => el.id !== documents[index].id,
          );

        const getIsDescriptionsChanged = (
          values,
          product,
          supportedLanguages,
        ) =>
          supportedLanguages.some(
            el =>
              !(
                values?.descriptions?.[el] === product.descriptions?.[el] ||
                values?.descriptions?.[el] === `${product.descriptions?.[el]}\n`
              ),
          );

        const getIsReviewsChanged = (values, product) =>
          product?.reviews?.some(
            (el, index) =>
              el.rating !== values?.reviews?.[index]?.rating ||
              el.added_on !== values?.reviews?.[index]?.added_on,
          );

        const getIsExtrasChanged = (extras = [], productExtras) =>
          extras.length !== productExtras?.length ||
          productExtras?.some(
            (el, index) => el.serverId !== extras[index].serverId,
          );

        if (
          values.safety !== productCharges.safety?.enabled ||
          values.sanitation !== productCharges.sanitation?.enabled ||
          getIsGalleryChanged(photos, product) ||
          getIsCategoriesChanged(productCategoriesTree, values) ||
          getIsWarehousesChanged(values, product) ||
          getIsDescriptionsChanged(
            values,
            product,
            settings.supportedLanguages,
          ) ||
          getIsReviewsChanged(values, product) ||
          getIsDocumentsChanged(guides, product) ||
          getIsItemsChanged(selected, product, 'variation_products', true) ||
          getIsItemsChanged(
            relatedProducts,
            product,
            'relatedProducts',
            true,
          ) ||
          getIsExtrasChanged(values.required_extra, required_extra) ||
          getIsExtrasChanged(values.optional_extra, optional_extra) ||
          (settings.extraConsumablesEnabled &&
            getIsExtrasChanged(values.consumables, consumables))
        ) {
          return true;
        }
      }
      if (isNewProduct) {
        if (
          values.owner &&
          (values.hidden ||
            values.carbonElementsShow ||
            values.isOutOfStock ||
            selected.length > 0 ||
            values.category_id ||
            values.deliveryOption !== SELF_COLLECTION_ONLY ||
            values.video_viewable ||
            values.safetyRecommendations.length ||
            values.safety ||
            values.sanitation ||
            values.isInsurance ||
            relatedProducts.length ||
            values.required_extra?.length ||
            values.optional_extra?.length ||
            values.consumables?.length ||
            guides.some(el => el.value) ||
            values.allWarehouses?.some(el => el.isAdded) ||
            photos?.some(el => el.value) ||
            settings.supportedLanguages.some(el => values?.descriptions?.[el]))
        ) {
          return true;
        }
      }
      return false;
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formState.dirtyFields,
      product,
      isNewProduct,
      productCharges,
      photos,
      productCategoriesTree,
      guides,
      selected,
      relatedProducts,
      required_extra,
      optional_extra,
      settings,
      consumables,
    ],
  );

  useEffect(() => {
    window.onbeforeunload = () => getIsProductFieldsChanged() || null;
    return () => (window.onbeforeunload = () => null);
  }, [getIsProductFieldsChanged]);

  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (
        action !== 'REPLACE' &&
        isRedirectBlocked &&
        getIsProductFieldsChanged()
      ) {
        return window.confirm(productLocalized.changesNotSaved);
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [
    isRedirectBlocked,
    getIsProductFieldsChanged,
    productLocalized.changesNotSaved,
  ]);

  if (
    (!isNewProduct && !product) ||
    (isNewProduct && isLoadingOwners) ||
    isLoadingProduct
  ) {
    return (
      <Center>
        <Spinner dark size={5} />
      </Center>
    );
  }

  if (isNewProduct && !currentProductOwner) {
    return <SelectProductOwner setValue={setValue} />;
  }

  return (
    <ProductEditor
      register={register}
      loading={loading}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onError={onError}
      values={values}
      getValues={getValues}
      setValue={setValue}
      control={control}
      isNewProduct={isNewProduct}
      errors={errors}
      watch={watch}
      setError={setError}
      clearErrors={clearErrors}
      trigger={trigger}
      fields={fields}
      reset={reset}
      unregister={unregister}
      isValidPhotos={isValidPhotos}
      setIsValidPhotos={setIsValidPhotos}
      productCharges={productCharges}
      isNoPhotos={isNoPhotos}
    />
  );
};

export default Product;
