import { PATHS } from '../AppPaths';
import { Manage } from '../pages/Manage';

export function generateSuperAdminURLS() {
  return [
    {
      path: PATHS.MANAGE,
      exact: true,
      component: Manage,
    },
  ];
}
