import React, { useCallback } from 'react';

import { Icon } from 'components/Product/Icon';

import * as ST from './styles';

import { T8y } from 'ui';

const UserOrderDoc = ({ doc, deleteDocumentFromOrder, isSmall }) => {
  const handleUrlClick = useCallback(() => {
    window.open(doc.url, '_blank');
  }, [doc.url]);

  return (
    <ST.DocumentWrapper key={doc.id}>
      <T8y
        color="primary"
        fontSize={isSmall ? '14px' : '16px'}
        onClick={handleUrlClick}
        cursor="pointer"
      >
        {doc.name}
      </T8y>
      <ST.IconWrapper>
        <Icon
          type="delete"
          handleClick={e => {
            e.preventDefault();
            e.stopPropagation();
            deleteDocumentFromOrder(doc.id);
          }}
        />
      </ST.IconWrapper>
    </ST.DocumentWrapper>
  );
};

export default UserOrderDoc;
