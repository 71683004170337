import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { getApiVersion } from 'consts';
import { Lang } from 'lang';

import CustomButton from 'components/atoms/CustomButton';
import Error from 'components/atoms/Error';
import { AUTH } from 'api';

import * as ST from './styles';

import { Field, inputStyle } from 'ui';

import { ReactComponent as PassIcon } from 'assets/images/icon/lock.svg';

export const ResetPassword = ({ history }) => {
  const { form, common } = Lang();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');
    const email = params.get('email');
    const country = params.get('site');

    if (!token || !email || !country) {
      history.push(PATHS.LOGIN);
    }

    register('email');
    setValue('email', email);
    register('token');
    setValue('token', token);
    register('country');
    setValue('country', country);
  }, [history, register, setValue]);

  const onSubmit = useCallback(
    async ({ password, token, email, password_confirmation, country }) => {
      setLoading(true);
      const apiVersion = getApiVersion(country);

      try {
        const res = await AUTH.reset({
          password,
          token,
          email,
          password_confirmation,
          country,
          apiVersion,
        });

        if (res.data) {
          history.replace(PATHS.LOGIN);
        } else {
          toast.error('Error');
        }
      } catch (e) {
        toast.error(form.toastTitle.wentWrong);
      } finally {
        setLoading(false);
      }
    },
    [form.toastTitle.wentWrong, history],
  );

  return (
    <ST.PageWrapper>
      <ST.PageTitle>{common.resetPassword.customerHeader}</ST.PageTitle>
      <ST.FormWrapper>
        <ST.Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ST.FormTitleWrapper>
              {common.resetPassword.subHeader}
            </ST.FormTitleWrapper>
            <div>
              <Field
                legend={common.resetPassword.passwordFieldTitle}
                icon={PassIcon}
              >
                <input
                  ref={register({
                    validate: value =>
                      !!value?.trim() || form.notification.theFieldIsRequired,
                  })}
                  name="password"
                  type="password"
                  placeholder={common.resetPassword.passwordFieldPlaceholder}
                  className={inputStyle}
                />
              </Field>
              <Error message={errors?.password?.message} />
            </div>
            <div>
              <Field
                legend={common.resetPassword.repeatPasswordFieldTitle}
                icon={PassIcon}
              >
                <input
                  ref={register({
                    required: form.notification.theFieldIsRequired,
                    validate: value => {
                      if (!value.trim()) {
                        return form.notification.passwordLength;
                      }

                      return (
                        value === watch('password') ||
                        form.notification.passwordDifferent
                      );
                    },
                  })}
                  name="password_confirmation"
                  type="password"
                  placeholder={
                    common.resetPassword.repeatPasswordFieldPlaceholder
                  }
                  className={inputStyle}
                />
              </Field>
              <Error message={errors?.password_confirmation?.message} />
            </div>
          </div>
          <ST.FormButtonsWrapper>
            <CustomButton
              backgroundColor="darkPrimary"
              type="submit"
              loading={loading}
              borderRadius="0"
              text={form.buttons.saveChanges}
              isActive
            />
          </ST.FormButtonsWrapper>
        </ST.Form>
      </ST.FormWrapper>
    </ST.PageWrapper>
  );
};
