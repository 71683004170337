import React, { Fragment } from 'react';
import { useStore } from 'effector-react';
import { branding$ } from 'models/branding';

import { AttributeModal } from 'components/Product/AttributeModal';
import { IconStyled } from 'components/Product/shared';

import * as ST from './styles';

import { Cell, Grid } from 'ui';

import { ReactComponent as DeleteIcon } from 'assets/images/icon/delete.svg';
import { ReactComponent as ProductIcon } from 'assets/images/icon/product.svg';

const AttributesTable = ({
  tableHeaders,
  fields,
  title,
  noItemsTitle,
  gridSettings,
  append,
  remove,
  allItems,
  isOptional = false,
  children,
  additionalFieldName,
  isCheckbox,
  type,
  typeForActiveCheckbox,
  reload,
  isDescription,
}) => {
  const branding = useStore(branding$);

  return (
    <>
      <Grid as={ST.StyledGrid} cols="2fr 1fr 1fr">
        <Cell place="center start">
          <ST.StyledTitle>{title}</ST.StyledTitle>
        </Cell>
        <Cell place="center end">
          <AttributeModal
            isOptional={isOptional}
            append={append}
            title={title}
            isCheckbox={isCheckbox}
            type={type}
            typeForActiveCheckbox={typeForActiveCheckbox}
            reload={reload}
            isDescription={isDescription}
          />
        </Cell>
        <Cell place="center end">{children}</Cell>
      </Grid>
      <ST.TableBody flexDirection={fields.length === 0 ? 'row' : 'column'}>
        {fields.length === 0 && (
          <Fragment>
            <IconStyled as={ProductIcon} />
            {noItemsTitle}
          </Fragment>
        )}
        {!!fields.length && (
          <>
            <Grid
              places="center start"
              cols={gridSettings}
              gaps="1rem 1rem"
              as={ST.TableHeader}
            >
              {tableHeaders.map((el, index) => (
                <ST.TableHeaderCell key={index}>{el}</ST.TableHeaderCell>
              ))}
            </Grid>
            {fields.map((el, index) => {
              const obj = allItems.find(p => p.id === el.serverId);

              return obj ? (
                <Fragment key={el.id}>
                  <Grid
                    places="center start"
                    cols={gridSettings}
                    gaps="0 1rem"
                    as={ST.TableHeader}
                    key={index}
                    marginTop="3rem"
                  >
                    <ST.TableBodyCell>
                      <ST.TableImage
                        src={obj.media.data.src || branding.defaultImageExtra}
                      />
                    </ST.TableBodyCell>
                    <ST.TableBodyCell>{obj.sku}</ST.TableBodyCell>
                    <ST.TableBodyCell>{obj.value}</ST.TableBodyCell>
                    <ST.TableBodyCell>
                      {branding?.currencySymbol}
                      {obj.price}
                    </ST.TableBodyCell>
                    {!!additionalFieldName && (
                      <ST.TableBodyCell>
                        {obj.slug === additionalFieldName ? 'Y' : 'N'}
                      </ST.TableBodyCell>
                    )}
                    <Cell place="center end">
                      <ST.ActionsWrapper>
                        <AttributeModal
                          isOptional={isOptional}
                          append={append}
                          title={title}
                          isCheckbox={isCheckbox}
                          type={type}
                          typeForActiveCheckbox={typeForActiveCheckbox}
                          item={obj}
                          reload={reload}
                          isEdit
                          isDescription={isDescription}
                        />
                        <ST.DeleteIconStyled
                          as={DeleteIcon}
                          onClick={() => remove(index)}
                        />
                      </ST.ActionsWrapper>
                    </Cell>
                  </Grid>
                </Fragment>
              ) : null;
            })}
          </>
        )}
      </ST.TableBody>
    </>
  );
};

export default AttributesTable;
