import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Grid } from 'ui';

export const RowStyled = styled(Grid)`
  background: ${({ hidden }) => (hidden ? '#FFFDFD' : colors.positive)};
  border-radius: 5px;
  padding-left: 10px;
  &:hover {
    margin-left: -5px;
    border-left: 5px solid ${colors.primary};
  }
`;

export const PopularityWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-left: 5px;
`;

export const PopularityInputWrapper = styled.div`
  max-width: 160px;
  margin-right: 10px;
`;

export const PopularityInput = styled.input`
  display: block;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  margin: 0;
  border: 1px solid ${colors.lightGray};
  border-radius: 3px;
  outline: none;
  line-height: 44px;
  text-align: left;
  font-size: 14px;

  &::placeholder {
    color: ${colors.gray};
  }

  &:disabled {
    background-color: #fbfbfb;
  }
`;

export const ButtonsCell = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
`;
