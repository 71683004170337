import styled from 'styled-components';

import { T8y } from 'ui';

export const ThumbImageWrapper = styled.div`
  width: 300px;
  height: 225px;
`;

export const AboutImageTitle = styled(T8y)`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const HiddenInput = styled.input`
  visibility: hidden;
`;
