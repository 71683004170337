import React from 'react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { get } from 'lodash';

import Error from 'components/atoms/Error';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import { Icon } from 'components/Product/Icon';
import { FieldStyle2, InputStyled } from 'components/Product/shared';

import * as ST from './styles';

import { T8y } from 'ui';

const ANSWER_MAX_LENGTH = 24;

const EnquiryAnswer = ({
  watch,
  index,
  register,
  errors,
  fields,
  handleRemove,
}) => {
  const { form, formatString, trainings } = Lang();

  const questionRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    `leadCustomQuestion.answers[${index}].value`,
    ANSWER_MAX_LENGTH,
  );

  const watchAnswer = watch(`leadCustomQuestion.answers[${index}].value`);

  return (
    <ST.AnswerWrapper>
      <TrainingFormLine>
        <FieldStyle2 legend={`${form.fields.answer} ${index + 1}`}>
          <InputStyled
            name={`leadCustomQuestion.answers[${index}].value`}
            type="text"
            width="790px"
            maxLength={ANSWER_MAX_LENGTH}
            ref={register({
              validate: value => {
                return !!value.trim() || form.notification.theFieldIsRequired;
              },
            })}
            defaultValue={watchAnswer}
          />
        </FieldStyle2>
        <Error
          message={get(errors, [
            'leadCustomQuestion',
            `answers`,
            index,
            'value',
            'message',
          ])}
        />
        <T8y color="gray" fontSize="12px">
          {formatString(
            trainings.remainingCharacters,
            questionRemainingSymbols,
          )}
        </T8y>
      </TrainingFormLine>
      {fields.length > 2 && index > 1 && (
        <ST.FeatureDeleteIconWrapper>
          <Icon type="delete" handleClick={e => handleRemove(e, index)} />
        </ST.FeatureDeleteIconWrapper>
      )}
    </ST.AnswerWrapper>
  );
};

export default EnquiryAnswer;
