import styled from 'styled-components';

import { colors } from 'styleGuide';

export const AddMoreWrapper = styled.button`
  width: 100%;
  height: 70px;
  border-radius: 5px;
  background-color: ${colors.positive};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
