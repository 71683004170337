import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '../../Product/Icon';
import * as ST from './styles';

import { Row, T8y } from 'ui';

import { ReactComponent as YellowArrowRight } from 'assets/icons/YellowArrowRight.svg';

const ExpandableSection = ({
  header,
  children,
  index,
  handleRemove,
  isErrors,
  additionalHeaderComponent,
  initialIsOpen,
  subSection,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  useEffect(() => {
    if (isErrors) {
      setIsOpen(true);
    }
  }, [isErrors]);

  const handleRemoveClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      return handleRemove(index);
    },
    [handleRemove, index],
  );

  const handleHeaderSectionClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <ST.SectionWrapper subSection={subSection}>
      <ST.ExpandHeaderWrapper subSection={subSection} isOpen={isOpen}>
        <button
          type="button"
          id={`expandable-btn-${index}`}
          onClick={handleHeaderSectionClick}
        >
          <ST.HeaderWrapper isOpen={isOpen} subSection={subSection}>
            <YellowArrowRight />
            <ST.HeaderTitleWrapper>
              <T8y
                lineHeight="21px"
                color="darkBlue"
                fontSize={subSection ? '16px' : '18px'}
                bold
              >
                {header}
              </T8y>
              <Row>
                {additionalHeaderComponent}
                {handleRemove && (
                  <Icon
                    type="delete"
                    color="gray"
                    handleClick={handleRemoveClick}
                  />
                )}
              </Row>
            </ST.HeaderTitleWrapper>
          </ST.HeaderWrapper>
        </button>
      </ST.ExpandHeaderWrapper>
      <ST.ExpandableContentWrapper disabled={!isOpen}>
        {children}
      </ST.ExpandableContentWrapper>
    </ST.SectionWrapper>
  );
};

export default ExpandableSection;
