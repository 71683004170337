import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import AddMore from 'components/atoms/AddMore';
import NotCheckoutAvailablePrice from 'components/atoms/NotCheckoutAvailablePrice';

import * as ST from './styles';

const TrainingNotCheckoutAvailablePrices = ({
  register,
  values,
  control,
  errors,
}) => {
  const { trainings } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.noSchedule.variations',
    keyName: 'formId',
  });

  const handleAddVariation = useCallback(() => {
    append({
      price: '',
      title: '',
    });
  }, [append]);

  return (
    <>
      {!!fields.length && (
        <ST.TitlesWrapper>
          <ST.VariationTitle color="gray">
            {trainings.courseVariationTitle}
          </ST.VariationTitle>
          <ST.CourseTitle color="gray">{trainings.coursePrice}</ST.CourseTitle>
        </ST.TitlesWrapper>
      )}
      {fields.map((el, index) => (
        <NotCheckoutAvailablePrice
          el={el}
          register={register}
          priceIndex={index}
          errors={errors}
          remove={remove}
          key={el.id}
          values={values}
        />
      ))}
      <AddMore text={trainings.addNewVariation} onClick={handleAddVariation} />
    </>
  );
};

export default TrainingNotCheckoutAvailablePrices;
