import styled from 'styled-components';

export const EditorAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 1;

  a {
    color: #ff6600;
  }

  & .editorArea {
    width: 100%;
    height: 200px;
    padding: 0 15px;
    border: 1px solid #e4e8ed;
    border-radius: 3px;
    outline: none;
    text-align: left;
    font-size: 1.4rem;
    resize: vertical;
    color: #424242;

    & > div {
      height: 180px;
      z-index: 0;
    }
  }

  & .toolbar {
    border: none;
    margin-bottom: 16px;
    padding-left: 0;

    .rdw-image-wrapper .rdw-image-modal {
      .rdw-image-modal-size:nth-child(4) {
        display: none;
      }
    }
  }
`;
