import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Lang } from 'lang';
import { get } from 'lodash';
import { handleUploadEnd } from 'utils/filesDownload';

import TrainingAddImage from 'components/atoms/TrainingAddImage';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from 'components/Product/shared';

import * as ST from './styles';

const TrainingFormWhyChooseUs = ({
  index,
  register,
  errors,
  setValue,
  item,
  values,
  setDeletedFiles,
  clearErrors,
  trigger,
  getIsError,
  control,
  watch,
}) => {
  const { trainings, form } = Lang();

  const watchSectionShow = watch('form.benefitShow');

  const getImgData = useCallback(
    field => get(values, ['form', 'benefits', index, 'iconImage', field]),
    [index, values],
  );

  const handleDeleteImage = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const id = getImgData('id');
      if (id) {
        setDeletedFiles(files => [...files, id]);
      }
      setValue(`form.benefits[${index}].iconImage.src`, null);
      setValue(`form.benefits[${index}].iconImage.id`, null);
      trigger(`form.benefits[${index}].iconImage.src`);
    },
    [getImgData, index, setDeletedFiles, setValue, trigger],
  );

  const handleUploaded = useCallback(
    file => {
      setValue(`form.benefits[${index}].iconImage.src`, file.src);
      clearErrors(`form.benefits[${index}].iconImage.src`);
    },
    [clearErrors, index, setValue],
  );

  const handleUpload = useCallback(
    async acceptedFiles => {
      await handleUploadEnd({
        acceptedFiles,
        handleUploaded,
        maxAmountOfFiles: 1,
      });
    },
    [handleUploaded],
  );

  return (
    <ST.WhyChooseUsWrapper justify="stretch">
      <Controller
        as={<input type="hidden" />}
        name={`form.benefits[${index}].iconImage.src`}
        control={control}
        rules={
          watchSectionShow && {
            required: form.notification.theFieldIsRequired,
          }
        }
        defaultValue={item.iconImage?.src || null}
      />
      <TrainingAddImage
        legend={trainings.benefits.icon}
        ImageRequirements={trainings.benefitImageRequirements}
        handleUpload={handleUpload}
        handleDelete={handleDeleteImage}
        src={getImgData('src')}
        error={
          get(errors, `form.benefits[${index}].iconImage.src`, {})?.message
        }
      />
      <ST.FieldsWrapper direction="column">
        <FieldStyle as={FieldStyle2} legend={trainings.benefits.shortTitle}>
          <InputStyled
            ref={register({
              validate: watchSectionShow
                ? value => {
                    return (
                      !!value.trim() || form.notification.theFieldIsRequired
                    );
                  }
                : undefined,
            })}
            defaultValue={item.title}
            name={`form.benefits[${index}].title`}
            type="text"
          />
        </FieldStyle>
        <ST.ErrorStyled message={getIsError('title', index)} />
        <FieldStyle as={FieldStyle2} legend={trainings.benefits.details}>
          <TextareaStyled
            ref={register({
              validate: watchSectionShow
                ? value => {
                    return (
                      !!value.trim() || form.notification.theFieldIsRequired
                    );
                  }
                : undefined,
            })}
            defaultValue={item.text}
            name={`form.benefits[${index}].text`}
            height="auto"
            rows="3"
          />
        </FieldStyle>
        <ST.ErrorStyled message={getIsError('text', index)} />
      </ST.FieldsWrapper>
    </ST.WhyChooseUsWrapper>
  );
};

export default TrainingFormWhyChooseUs;
