import styled from 'styled-components';

import { T8y } from 'ui';

export const TrainingItemWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px 90px 30px 37px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const DrugDropIconWrapper = styled.div`
  width: 10px;
  margin-right: 37px;
`;

export const ImgWrapper = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 40px;

  img {
    max-height: 100%;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextWrapper = styled(T8y)`
  margin-top: ${p => (p.marginTop ? p.marginTop : '12px')};
  overflow: hidden;
  width: 640px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 16px;
`;
