import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { settings$ } from 'models/settings';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

export const useGetLocalizedExtraFields = (extraFields = []) => {
  const settings = useStore(settings$);
  const currentLangState = useStore(currentLang$);

  return useMemo(
    () =>
      extraFields.map(el => ({
        name: el.name,
        type: el.type,
        title: getTranslatedFieldValue(
          el.titles,
          currentLangState,
          settings?.defaultLanguage,
        ),
        required: el.required,
        placeholder: el.placeholders
          ? getTranslatedFieldValue(
              el.placeholders,
              currentLangState,
              settings?.defaultLanguage,
            )
          : null,
      })),
    [settings, currentLangState, extraFields],
  );
};
