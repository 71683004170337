import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { membershipsGate } from 'models/memberships';
import { settings$ } from 'models/settings';
import { tiersGate } from 'models/tiers';
import { getTradeAccount, tradeAccount$ } from 'models/tradeAccounts';
import { website$ } from 'models/website';
import { validateEmailReg } from 'utils/validationEmail';

import Error from 'components/atoms/Error';
import LocationSearchInput from 'components/atoms/LocationSearchInput';
import PhoneInputWithCountryInForm from 'components/atoms/PhoneInputWithCountryInForm';
import AccountSpecialDiscount from 'components/molecules/AccountSpecialDiscount';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import TradeAccountAddressesInfoBlock from 'components/molecules/TradeAccountAddressesInfoBlock';
import TradeAccountDeposit from 'components/molecules/TradeAccountDeposit';
import TradeAccountMembership from 'components/molecules/TradeAccountMembership';
import TradeAccountOrdersSmall from 'components/molecules/TradeAccountOrdersSmall';
import {
  FieldStyle,
  FieldWithMargin,
  HalfField,
  InputStyled,
} from 'components/Product/shared';
import { TRADE_ACCOUNTS } from 'api';

import { PostcoderInput } from './PostcoderInput';

import { Grid, Row } from 'ui';

const DEFAULT_USER = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  billingAddress: '',
  addresses: [],
  account: { depositFree: false },
};

const TradeAccountPrivateEditor = () => {
  const { users, form, tradeAccounts, formatString } = Lang();

  useGate(membershipsGate);
  useGate(tiersGate);

  const settings = useStore(settings$);
  const tradeAccount = useStore(tradeAccount$);
  const website = useStore(website$);

  const [isLoading, setIsLoading] = useState(false);
  const [billingAddress, setBillingAddress] = useState(null);
  const [searchRequestError, setSearchRequestError] = useState(null);

  const history = useHistory();
  const { id } = useParams();

  const isNewAccount = id === 'new';

  const formattedDefaultValues = useMemo(() => {
    if (tradeAccount) {
      const billingAddress = tradeAccount.addresses?.find(
        el => el.type === 'billing',
      )?.address;

      return {
        firstName: tradeAccount.firstName,
        lastName: tradeAccount.lastName,
        email: tradeAccount.email,
        phone: tradeAccount.phone,
        billingAddress: billingAddress,
        account: { depositFree: tradeAccount.depositFree, tierId: 1 },
      };
    }

    return DEFAULT_USER;
  }, [tradeAccount]);

  const {
    register,
    getValues,
    watch,
    reset,
    errors,
    control,
    setValue,
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: formattedDefaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const isOrders = !!tradeAccount?.orders?.length;

  const onSubmit = useCallback(
    async formData => {
      try {
        setIsLoading(true);

        formData.account.type = 'customer';

        if (isNewAccount) {
          formData.account.idVerified = false;

          formData.addresses = billingAddress
            ? [
                {
                  ...billingAddress,
                  address: billingAddress.name,
                  state: billingAddress.state || null,
                  addressLine1: billingAddress.addressLine1 || null,
                  addressLine2: billingAddress.addressLine2 || null,
                  postCode: billingAddress.postCode || null,
                  streetNumber: billingAddress.streetNumber || null,
                  googlePlaceId: billingAddress.googlePlaceId || null,
                  postCoderId: billingAddress.postCoderId || null,
                  country: billingAddress.country || null,
                  lat: billingAddress.lat || null,
                  lng: billingAddress.lng || null,
                  type: 'billing',
                },
              ]
            : [];
        } else {
          formData.addresses = tradeAccount.addresses;
          formData.account.idVerified = tradeAccount.idVerified;
        }

        const formattedData = {
          ...formData,
          membershipLevelId: +formData.membershipLevelId,
        };

        if (isNewAccount) {
          await TRADE_ACCOUNTS.createUser(formattedData);
        } else {
          await TRADE_ACCOUNTS.updateTradeAccount(id, formattedData);
        }

        toast.success(form.toastTitle.successfullyCreated);

        if (isNewAccount) {
          history.push(PATHS.TRADE_ACCOUNTS('private'));
        } else {
          getTradeAccount({ type: tradeAccount.type, id });
        }
      } catch (e) {
        toast.error(
          e.response?.data?.message?.email?.[0] || form.toastTitle.createError,
        );
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    },
    [billingAddress, form, history, id, isNewAccount, tradeAccount],
  );

  useEffect(
    () => {
      if (values.billingAddress) {
        trigger('billingAddress');
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [billingAddress],
  );

  return (
    <>
      <Grid cols="3fr 1.5fr" gaps="0 3rem">
        <div>
          <div>
            <ProductEditorBlockWrapper
              blockTitle={users.info.generalInformation}
            >
              <Row direction="column" fullWidth>
                <FieldStyle
                  as={FieldWithMargin}
                  legend={tradeAccounts.fields.billingAddress}
                >
                  {!isNewAccount && (
                    <InputStyled
                      name="billingAddress"
                      ref={register}
                      disabled
                      title={values.billingAddress}
                    />
                  )}
                  {isNewAccount && website.shortCompanyName === 'ETH' && (
                    <PostcoderInput
                      placeholder="Start typing your Post Code"
                      name="billingAddress"
                      ref={register({
                        validate: {
                          required: value =>
                            !!value.trim() ||
                            form.notification.theFieldIsRequired,
                          minLength: value =>
                            value.length > 2 ||
                            formatString(form.notification.minLength, 3),
                          addressNotValid: () =>
                            billingAddress ||
                            form.notification.chooseCorrectAddress,
                        },
                      })}
                      values={values}
                      setEmptyAddress={() => {
                        setBillingAddress(null);
                      }}
                      changeDeliveryPostcodeAddress={value => {
                        setBillingAddress(value);
                        setValue('billingAddress', value.name);
                      }}
                      disabled={isLoading}
                      selectedPostcode={billingAddress?.postcode}
                      setSearchRequestError={setSearchRequestError}
                      searchRequestError={searchRequestError}
                    />
                  )}
                  {isNewAccount && website.shortCompanyName !== 'ETH' && (
                    <LocationSearchInput
                      handleChange={setBillingAddress}
                      address={billingAddress?.address || null}
                      innerRef={register}
                      inputName="billingAddress"
                    />
                  )}
                </FieldStyle>
                <Error
                  message={
                    errors?.billingAddress?.message || searchRequestError
                  }
                />
              </Row>
            </ProductEditorBlockWrapper>
            <ProductEditorBlockWrapper
              blockTitle={tradeAccounts.titles.accountOwner}
            >
              <HalfField>
                <Row direction="column" fullWidth>
                  <FieldStyle
                    as={FieldWithMargin}
                    legend={users.info.firstName}
                  >
                    <InputStyled
                      name="firstName"
                      ref={register({
                        validate: value =>
                          !!value.trim() ||
                          form.notification.theFieldIsRequired,
                      })}
                      readOnly={!isNewAccount}
                      asDisabled={!isNewAccount}
                      disabled={isLoading}
                    />
                  </FieldStyle>
                  <Error message={errors?.firstName?.message} />
                </Row>
                <Row direction="column" fullWidth>
                  <FieldStyle as={FieldWithMargin} legend={users.info.lastName}>
                    <InputStyled
                      name="lastName"
                      ref={register({
                        validate: value =>
                          !!value.trim() ||
                          form.notification.theFieldIsRequired,
                      })}
                      readOnly={!isNewAccount}
                      asDisabled={!isNewAccount}
                      disabled={isLoading}
                    />
                  </FieldStyle>
                  <Error message={errors?.lastName?.message} />
                </Row>
              </HalfField>
              <HalfField>
                <Row direction="column" fullWidth>
                  <FieldStyle
                    as={FieldWithMargin}
                    legend={users.info.emailAddress}
                  >
                    <InputStyled
                      name="email"
                      ref={register({
                        required: form.notification.theFieldIsRequired,
                        pattern: {
                          value: validateEmailReg,
                          message: form.notification.emailPattern,
                        },
                      })}
                      disabled={!isNewAccount || isLoading}
                    />
                  </FieldStyle>
                  <Error message={errors?.email?.message} />
                </Row>
                <Row direction="column">
                  {isNewAccount ? (
                    <FieldStyle
                      as={FieldWithMargin}
                      legend={users.info.phoneNumber}
                      withBorder
                    >
                      <PhoneInputWithCountryInForm
                        control={control}
                        name="phone"
                        disabled={isLoading}
                      />
                    </FieldStyle>
                  ) : (
                    <FieldStyle
                      as={FieldWithMargin}
                      legend={users.info.phoneNumber}
                    >
                      <InputStyled
                        name="phone"
                        ref={register}
                        readOnly
                        asDisabled
                      />
                    </FieldStyle>
                  )}
                  <Error message={errors?.phone?.message} />
                </Row>
              </HalfField>
            </ProductEditorBlockWrapper>
          </div>
          {!isNewAccount && <TradeAccountAddressesInfoBlock />}
        </div>
        <div>
          <TradeAccountMembership
            userMembership={tradeAccount?.membershipLevel}
            control={control}
            values={values}
            isLoading={isLoading}
            setValue={setValue}
          />
          {settings?.showDiscountsSectionInCP && (
            <AccountSpecialDiscount
              userTier={tradeAccount?.tier}
              control={control}
              getValues={getValues}
              values={values}
              setValue={setValue}
              isLoading={isLoading}
            />
          )}
          <TradeAccountDeposit control={control} isLoading={isLoading} />
          {isOrders && <TradeAccountOrdersSmall />}
        </div>
      </Grid>
      <AddNewAndSaveCancelFooter
        onSubmit={onSubmit}
        handleReset={reset}
        handleSubmit={handleSubmit}
        submitButtonText={isNewAccount && tradeAccounts.createTA}
        isDisabled={isLoading}
      />
    </>
  );
};

export default TradeAccountPrivateEditor;
