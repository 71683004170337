export const users = {
  title: 'Usuários',
  subTitle:
    'Analise os usuários do Portal do Cliente e gerencie seus descontos especiais',
  hideTestUsers: 'Ocultar usuários de teste',

  filterSelect: {
    all: 'Todos',
    company: 'Companhia',
    private: 'Privado',
    verified: 'Ativação de Conta - Sim',
    unverified: 'Ativação de Conta - Não',
    archived: 'Arquivado',
  },

  tableHeaders: {
    firstName: 'Nome',
    lastName: 'Sobrenome',
    businessName: 'Nome da empresa',
    segment: 'Segmento',
    discount: 'Desconto',
    emailAddress: 'Endereço de e-mail',
    phoneNumber: 'Número de telefone',
    idVerification: 'ID verification',
    status: 'Ativação de Conta',
  },
  types: {
    private: 'Privado',
    company: 'Companhia',
  },
  statuses: {
    verified: 'Yes',
    unverified: 'Não',
    archived: 'Arquivado',
  },
  viewProfile: 'Ver perfil',
  foundStr: '{0} usuários encontrados',
  searchPlaceholder: 'Comece a digitar para encontrar usuários',
  backToPreviousScreen: 'Voltar para a página anterior',
  backToListUsers: 'Voltar à lista de todos os usuários',
  info: {
    title: 'Perfil do usuário ({0}, {1})',
    titleNewUser: 'Perfil do usuário (Novo)',
    fiscalCodeFieldTitle: 'Código fiscal',
    officeAddressFieldTitle: 'Endereço registrado do escritório',
    postalCodeFieldTitle: 'Postal Code',
    provinceFieldTitle: 'Província',
    sectorOfActivity: 'Setor de atividade?',
    uniqueCodeFieldTitle: 'Código único',
    PECFieldTitle: 'PEC',
    deliveryAddressTitle: 'Endereço de entrega',
    howDidYouKnowAboutUs: 'Como você soube sobre nós',
    generalInformation: 'Informações gerais',
    contactInformation: 'Informação de contato',
    contactPhoneNumber: 'Número de telefone de contato',
    contactEmailAddress: 'Endereço de e-mail de contato',
    backToLogin: 'Volte ao login',
    businessName: 'Nome da empresa',
    fullName: 'Nome completo',
    emailAddress: 'Endereço de e-mail',
    phoneNumber: 'Número de telefone',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    dateOfBirth: 'Data de nascimento',
    birthplace: 'Local de nascimento',
    address: 'Endereço',
    companyName: 'Nome da empresa',
    country: 'País',
    vatNumber: 'Número de IVA',
    sortCode: 'Sort Code',
    userType: 'User Type',
    accountNumber: 'Número de conta',
    taxNumber: 'Número de identificação fiscal',
    city: 'Cidade',
    newEmail: 'Novo Email',
    noType: 'Nenhum tipo',
    noDataAboutType: 'Não há dados sobre o tipo',
    idVerificationStatus: 'ID Verification status',
  },
  sectorOfActivity: {
    business: 'Negócio',
    constructions: 'Construções',
    public_authority: 'Autoridade pública',
    training_courses: 'Cursos de treinamento',
    gardening: 'Jardinagem',
    industry: 'Indústria',
    services: 'Serviços',
    transport: 'Transporte',
    tourism: 'Turismo',
    other: 'Outro',
  },
  aboutUs: {
    search_on_the_internet: 'Pesquisa na internet',
    television: 'Televisão',
    word_of_mouth: 'Boca a boca',
    headquarter_visibility: 'Visibilidade da sede',
    construction_warehouse: 'Armazém de construção',
    gardening_dealer: 'Loja de jardinagem',
    magazines_and_newspapers: 'Revistas e jornais',
    Outro: 'Outro',
  },
  discount: {
    title: 'Desconto do usuário',
    discount: 'Filiação com desconto',
    option: 'Enable Discount',
    description:
      'O desconto selecionado será aplicado a todos os produtos do catálogo se o usuário estiver logado no Portal do Cliente',
  },
  discountOptions: {
    platinum: 'Platino',
    gold: 'Ouro',
    silver: 'Prata',
  },
  deposit: {
    title: 'Depósito reembolsável',
    option: 'Ativar Depósito',
    description:
      'Se desmarcado, o depósito reembolsável não será adicionado aos pedidos feitos pelo usuário',
  },
  sureToLogIn: 'Tem certeza de que deseja fazer login como usuário?',
  membershipLevel: 'Nível de associação',
  findPostcode: 'Comece a digitar seu código postal',
  noItems: 'Nenhum item para exibir',
  backToResults: 'Voltar aos resultados',
  newUser: 'Adicionar Novo Usuário',
  imCompany: 'Eu sou uma empresa',
};
