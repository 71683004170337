import CustomButton from 'components/atoms/CustomButton';
import { FieldStyle2 } from 'components/Product/shared';
import styled from 'styled-components';

import { Row } from 'ui';

import { ReactComponent as Delete } from 'assets/images/icon/delete.svg';

export const SafetyRecommendationsWrapper = styled(Row)`
  column-gap: 20px;
  flex-wrap: noWrap;
  & > div:last-child {
    width: 90px;
  }
`;

export const SafetyRecommendationsFields = styled(FieldStyle2)`
  width: 100%;
  margin-bottom: 24px;
  color: #424242;
`;

export const AddRecommendationBtn = styled(CustomButton)`
  color: #666 !important;
  border-radius: 3px !important;
  &:hover {
    color: #fff !important;
  }
`;

export const RecommendationItem = styled(Row)`
  border-top: 1px solid #e4e8ed;
  padding: 12px 5px 12px 0;
  &:last-child {
    padding-bottom: 0;
  }
`;

export const RecommendationIcon = styled.img`
  width: 40px;
  height: 40px;
  background: #666;
  border-radius: 50%;
  margin-right: 14px;
`;

export const DeleteIcon = styled(Delete)`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 10px;
  &:hover {
    path {
      fill: #f89739;
    }
  }
`;
