export const marketing = {
  titles: {
    content: 'Conteúdo',
    homePage: 'Deslizante do Hero',
    slideIndex: 'Índice de slides',
    deleteSlide: 'Excluir slide',
    CTA: 'Botão CTA',
    buttonText: 'Botão de texto',
    buttonURL: 'Botão URL',
    learnMore: 'Saber mais',
    declareInterest: 'Declarar interesse',
    exploreCategory: 'Explorar categoria',
    addNewSlide: 'Adicionar novo slide',
    desktopVersion: 'Imagem da área de trabalho',
    mobileVersion: 'Imagem para celular',
    heroImages: 'Hero Imagens',
    popularProducts: 'Produtos populares',
    popularProductsNote:
      'A seção de produtos populares APENAS ficará visível se mais de 3 produtos forem adicionados',
    targetBlank: 'Abrir link em uma nova aba',
    enquiryForm: 'Formulário de consulta',
    popularCategories: 'Categorias populares',
    promoCodes: 'Códigos promocionais',
    buttonTitle: 'Título do botão',
    h1Text: 'Texto H1',
    pText: 'P Texto (somente versão desktop)',
    jobTypes: 'Job Types',
    taFaqs: 'TA FAQs',
  },
  enquiry: {
    enquiryFormVariation: 'Variação do formulário de consulta',
    defaultTitle: 'Formulário de consulta padrão',
    defaultSubTitle:
      'O formulário padrão tem este conjunto de campos: Nome completo, Número de telefone, Nome da empresa, Endereço de e-mail, Mensagem',
    customizedTitle: 'Formulário de consulta com pergunta personalizável',
    customizedSubTitle:
      'Selecione esta opção se desejar expandir o conjunto padrão de campos com perguntas adicionais',
    addNewAnswer: 'Adicionar nova resposta',
  },

  jobTypes: {
    jobType: 'Tipo de emprego',
    jobTypeTitle: 'Título do tipo de trabalho',
    relatedProducts: 'Produtos relacionados',
    relatedCategories: 'Categorias relacionadas',
    addNewJobType: 'Adicionar novo tipo de trabalho',
    viewJobType: 'Ver tipo de trabalho',
    availableItems: '{0} tipos de trabalho disponíveis',
    jobURL: 'URL da página',
    jobHTMLTitle: 'Título HTML',
    hideJob: 'Ocultar página de tipo de trabalho',
    marketingText: 'Texto de Marketing',
    addProduct: 'Adicionar produto relacionado',
    addCategory: 'Adicionar categoria relacionada',
    backToJobs: 'Voltar à lista de todos os tipos de trabalho',
    noJobs: 'Ainda não há empregos',
  },
};
