export const header = {
  titles: {
    search: 'Search',
    logOut: 'Log Out',
    categories: 'Categories',
    orders: 'Orders',
    userProfile: 'User Profile',
    products: 'Products',
    marketing: 'Marketing',
    notification: 'Notification',
    productsFound: 'Products Found',
    sortBy: 'Sort by',
    price: 'Price',
    filter: 'Filter',
    available: 'Available',
    allArchived: 'All Archived',
    addNew: 'Add new',
    confirmDeleting: 'Confirm deleting',
    export: 'Export in CSV',
    productsOnPage: 'Products on Page',
  },

  table: {
    productCode: 'Product code',
    photo: 'Photo',
    Name: 'Name',
    price: 'Price',
    maxQuantity: 'Max Quantity',
    save: 'Save',
    cancel: 'Cancel',
    preview: 'Preview',
    title: 'Title',
  },

  menu: {
    categories: 'Categories',
    orders: 'Orders',
    products: 'Products',
    marketing: 'Marketing',
    analytics: 'Analytics',
    users: 'Users',
    logOut: 'Log Out',
    settings: 'Settings',
    trainings: 'Training',
    offers: 'Offers',
    leads: 'Web Leads',
    tradeAccounts: 'Trade Accounts',
  },
};
