import { createEffect, restore } from 'effector';

import { USER } from 'api';

export const getUserFx = createEffect({
  handler: async info => {
    const { data } = await USER.me(info);

    return data;
  },
});

export const userInfo = restore(getUserFx, {});
