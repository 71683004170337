import { Popover, PopoverDisclosure } from 'reakit';
import { transparentize } from 'polished';

import styled from 'styled-components';

import { colors } from 'styleGuide';

export const PopoverDisclosureStyled = styled(PopoverDisclosure)`
  margin-left: 100px;
`;

export const PopoverStyled = styled(Popover)`
  padding: 20px;
  background-color: ${colors.positive};
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  box-shadow: 0 5px 25px ${transparentize(0.8, colors.gray)};
  path:first-of-type {
    fill: ${colors.primary};
  }
  path:last-of-type {
    fill: ${colors.positive};
  }
`;
