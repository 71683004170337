import React, { useMemo } from 'react';
import { useGate, useStore } from 'effector-react';
import { useGetProductsListForModal } from 'hooks';
import { Lang } from 'lang';
import {
  addRelatedProduct,
  relatedProductsStore,
  removeRelatedProduct,
} from 'models/product';
import {
  changeRelativeProductsSearchStr,
  productsGate,
  relativeProductsFilteredStore,
  relativeProductsSearchStr,
} from 'pages/model';

import ModalWrapper from 'components/molecules/ModalWrapper';

export const RelativeProductsModal = ({ dialog }) => {
  useGate(productsGate);
  const products = useStore(relativeProductsFilteredStore);
  const searchStr = useStore(relativeProductsSearchStr);
  const selected = useStore(relatedProductsStore);

  const newProducts = useGetProductsListForModal(products);

  const onChangeCheckbox = id =>
    selected.includes(id) ? removeRelatedProduct(id) : addRelatedProduct(id);

  const onSearch = e => changeRelativeProductsSearchStr(e.target.value);

  const {
    product: { titles },
    form: { fields },
  } = Lang();

  const fieldsForTable = useMemo(
    () => [
      fields.productCode,
      fields.category,
      fields.subCategory,
      fields.subSubCategory,
      fields.photo,
      fields.name,
    ],
    [fields],
  );

  return (
    <ModalWrapper
      title={titles.chooseFrequently}
      dialogState={dialog}
      onChangeCheckbox={onChangeCheckbox}
      searchStr={searchStr}
      onSearch={onSearch}
      fieldsForTable={fieldsForTable}
      newProducts={newProducts}
      cols="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr"
      selected={selected}
      isSelect={true}
    />
  );
};
