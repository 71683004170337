import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { tradeAccount$ } from 'models/tradeAccounts';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import { AddressItem, AddressText, AddressTitle } from './styles';

import { Row, T8y } from 'ui';

const TradeAccountAddressesInfoBlock = () => {
  const { users } = Lang();

  const tradeAccount = useStore(tradeAccount$);

  const addresses = useMemo(
    () => tradeAccount?.addresses?.filter(el => el.type === 'delivery') || [],
    [tradeAccount],
  );

  if (!addresses.length) {
    return null;
  }

  return (
    <ProductEditorBlockWrapper blockTitle={users.info.deliveryAddressTitle}>
      <Row justify="stretch" align="normal">
        {addresses.map((el, index) => (
          <AddressItem key={index} direction="column">
            <AddressTitle>
              {users.info.address} {index + 1}{' '}
              {el.default && (
                <T8y color="gray3" fontSize="16px" as="span">
                  {' '}
                  (default)
                </T8y>
              )}
            </AddressTitle>
            <AddressText>{el.address}</AddressText>
          </AddressItem>
        ))}
      </Row>
    </ProductEditorBlockWrapper>
  );
};

export default TradeAccountAddressesInfoBlock;
