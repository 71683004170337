import styled from 'styled-components';

import { Row } from 'ui';

export const SpinnerWrapper = styled(Row)`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
  }
`;
