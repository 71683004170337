export const common = {
  dropZoneErrors: {
    'file-too-large':
      'Le dimensioni dell’immagine devono essere inferiori a {0} MB',
  },
  enabledRobotsTag: 'Abilita la modifica del campo Robots Tag',

  forgotPassword: {
    header: 'Hai dimenticato la password?',
    customerHeader: 'Admin Panel Password dimenticata',
    subHeader:
      "Inserisci l'indirizzo e-mail che hai usato per la registrazione e ti invieremo un link per il ripristino della password",
    emailFieldTitle: 'Indirizzo e-mail',
    emailFieldPlaceholder: 'Inserisci il tuo indirizzo e-mail',
    goToLogin: 'Vai alla pagina di Accesso, mi sono ricordato la mia password',
    submit: 'Invia',
    sent: 'Ti abbiamo inviato una email. Controlla la tua casella di posta.',
    cancel: 'Cancella',
  },

  resetPassword: {
    header: 'Vai alla pagina di Accesso, mi sono ricordato la mia password',
    customerHeader: 'Admin Panel Reimposta la Password',
    subHeader: 'Inserisci la tua nuova password qui sotto',
    passwordFieldTitle: 'Nuova password',
    passwordFieldPlaceholder: 'Inserisci la tua password',
    repeatPasswordFieldTitle: 'Ripeti la nuova password',
    repeatPasswordFieldPlaceholder: 'Reinserisci la tua nuova password',
  },

  historyTable: {
    title: 'Storico',
    revisionNo: 'Revisione n.',
    modifiedAt: 'Modificato il',
    modifiedBy: 'Modificato da',
    viewChanges: 'Vedi modifiche',
    changes: 'Modifiche',
    previousVersion: 'Versioni precedenti',
    currentVersion: 'Versione attuale',
    expandLines: 'Espandi {0} righe',
  },

  draft: 'Bozza',
  new: 'Nuova',

  calendar: {
    weekDays: {
      monday: 'Lunedì',
      tuesday: 'Martedì',
      wednesday: 'Mercoledì',
      thursday: 'Giovedì',
      friday: 'Venerdì',
      saturday: 'Sabato',
      sunday: 'Domenica',
    },
    general: 'Generale',
    timezone: 'Fuso orario',
    defaultHirePeriod: 'Periodo di noleggio predefinito (giorni)',
    sameDayDelivery: 'Consegna in giornata',
    disableSameDayDelivery: 'Disattiva consegna in giornata ',
    holidays: 'Vacanze',
    month: 'Mese',
    comment: 'Commento (facoltativo)',
    selectDay: 'Seleziona il giorno',
    selectMonth: 'Seleziona il mese',
    addNewHoliday: 'Aggiungi Nuova Vacanza',
    weekends: 'Weekend',
    deliverySelfCollection: 'Per consegna e ritiro',
    tickDays:
      'Seleziona i giorni che verranno contrassegnati nel calendario come non disponibili per le date di inizio o fine noleggio',
    nextDayBehaviour: 'Impostazione giorno successivo',
    dayOfWeek: 'Giorno della settimana',
    offset: 'Differenza (giorni)',
    enterNumber: 'Inserisci numero',
    addNewBehaviour: 'Aggiungi Nuovo Comportamento',
    sameDayHire: 'Noleggia il giorno stesso',
    allowSetToday: 'Attiva “oggi” come data di inizio',
    till: 'fino a',
  },
};
