import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import { ORDERS } from 'api';

const getFormData = (file, type) => {
  const data = new FormData();
  data.append('file', file);
  data.append('type', type);
  return data;
};

export const useOrder = (
  order,
  onAddDocumentCallback,
  onDeleteDocumentCallback,
) => {
  const { formatString, form } = Lang();

  const [isCancelOrderLoading, setIsCancelOrderLoading] = useState();

  const cancelRequest = useMemo(
    () =>
      order.requests?.find(
        el => el.type === 'cancel' && el.status === 'pending',
      ),
    [order],
  );

  const addDocumentToOrder = useCallback(
    async (acceptedFiles, type) => {
      const maxFiles = type === 'invoice' ? 1 : 3;
      if (acceptedFiles.length > maxFiles) {
        toast(
          formatString(form.toastTitle.onlySeveralFiledCanBeUpdated, maxFiles),
        );
      }

      const loadedFilesPromises = acceptedFiles.map(file => {
        return new Promise(resolve => {
          const data = getFormData(file, type);
          ORDERS.downloadDocument(order.orderHash, data).then(res => {
            if (onAddDocumentCallback) {
              onAddDocumentCallback(res.data, type);
            }
            resolve(res.data);
          });
        });
      });

      await Promise.all(loadedFilesPromises)
        .then(() => {
          toast.success(form.toastTitle.successfullyUpdated);
        })
        .catch(() => {
          toast.error(form.toastTitle.updateError);
        });
    },
    [form, formatString, onAddDocumentCallback, order],
  );

  const deleteDocumentFromOrder = useCallback(
    async id => {
      try {
        await ORDERS.deleteDocument(id);

        if (onDeleteDocumentCallback) {
          onDeleteDocumentCallback();
        }

        toast.success(form.toastTitle.successfullyDeleted);
      } catch (e) {
        toast.error(form.toastTitle.updateError);
      }
    },
    [form, onDeleteDocumentCallback],
  );

  const isAddFilesBlocked = order.docs?.length >= 3;
  const isAddInvoiceBlocked = !!order.invoice;

  return useMemo(
    () => ({
      cancelRequest,
      isCancelOrderLoading,
      setIsCancelOrderLoading,
      addDocumentToOrder,
      deleteDocumentFromOrder,
      isAddFilesBlocked,
      isAddInvoiceBlocked,
    }),
    [
      cancelRequest,
      isCancelOrderLoading,
      addDocumentToOrder,
      deleteDocumentFromOrder,
      isAddFilesBlocked,
      isAddInvoiceBlocked,
    ],
  );
};
