import {
  add,
  closestIndexTo,
  differenceInDays,
  format,
  max,
  min,
  parse,
  parseISO,
} from 'date-fns';

const TYPICAL_DATE_FORMAT = 'dd/MM/yyyy HH:mm';

export const getYears = years => {
  let currDate = new Date();
  const yearsList = [
    { name: currDate.getFullYear(), slug: currDate.getFullYear() },
  ];
  let count = years;

  while (count > 0) {
    currDate.setFullYear(currDate.getFullYear() - 1);
    yearsList.push({
      name: currDate.getFullYear(),
      slug: currDate.getFullYear(),
    });
    count--;
  }
  return yearsList;
};

export const getFormattedStringDate = (
  date,
  template = TYPICAL_DATE_FORMAT,
) => {
  return format(parseISO(date), template);
};

export const getDateInFormat = (date, template = 'dd/MM/yyyy HH:mm') => {
  if (!date) return null;
  return format(date, template);
};

const nowDate = new Date().toISOString();

export const getNearestDateIndex = datesArray => {
  const parsedDates = datesArray.map(el => parseISO(el.date));
  return closestIndexTo(parseISO(nowDate), parsedDates);
};

export const diffInDays = date => {
  const currentDate = new Date().toISOString();
  return differenceInDays(new Date(currentDate), parseISO(date));
};

export const diffBetweenTwoDays = (dateLeft, dateRight) => {
  return differenceInDays(dateLeft, dateRight) || 1;
};

export const getDateFromString = (date, format = 'dd/MM/yyyy') => {
  if (!date) return null;
  return parse(date, format, new Date());
};

export const getOrderDates = (products = []) => {
  if (!products.length) {
    return {
      startDate: '',
      endDate: '',
    };
  }

  if (products.length === 1) {
    return {
      startDate: products[0].startDate.split('T', 1)[0],
      endDate: products[0].endDate.split('T', 1)[0],
    };
  }
  const { startDates, endDates } = products.reduce(
    (acc, el) => {
      acc.startDates.push(
        getDateFromString(el.startDate.split('T', 1)[0], 'yyyy-mm-dd'),
      );
      acc.endDates.push(
        getDateFromString(el.endDate.split('T', 1)[0], 'yyyy-mm-dd'),
      );
      return acc;
    },
    {
      startDates: [],
      endDates: [],
    },
  );

  return {
    startDate: getDateInFormat(min(startDates), 'dd/MM/yyyy'),
    endDate: getDateInFormat(max(endDates), 'dd/MM/yyyy'),
  };
};

export const getDaysCount = (item, format = 'yyyy-mm-dd') => {
  if (!item.startDate || !item.endDate) {
    return 0;
  }

  const endDate = getDateFromString(item.endDate.split('T', 1)[0], format);
  const startDate = getDateFromString(item.startDate.split('T', 1)[0], format);

  return diffBetweenTwoDays(endDate, startDate) || 0;
};
