import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';

import ShowSection from 'components/atoms/ShowSection';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import TrainingSeveralImagesSection from '../TrainingSeveralImagesSection';

import { T8y } from 'ui';

const IMAGES_COUNT = 8;

const TrainingGallery = ({
  watch,
  register,
  values,
  setValue,
  errors,
  setDeletedFiles,
  trigger,
  control,
  clearErrors,
  isNew,
}) => {
  const { trainings } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const watchSectionShow = watch('form.galleryShow');

  const fieldsWatcher = watch(
    'form.galleryImages',
    trainingLocalized?.galleryImages || [],
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.galleryImages',
    keyName: 'formId',
  });

  const handleShowChange = useCallback(
    value => {
      setValue('form.galleryShow', value);

      if (!value) {
        clearErrors('form.galleryImagesLength');
      }
    },
    [clearErrors, setValue],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.courseGallery}
      listOfFields={['galleryImagesLength']}
      errors={errors}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={isNew || trainingLocalized?.galleryShow}
          handleChange={handleShowChange}
        />
      }
    >
      <TrainingSeveralImagesSection
        register={register}
        values={values}
        setValue={setValue}
        errors={errors}
        setDeletedFiles={setDeletedFiles}
        trigger={trigger}
        watchSectionShow={watchSectionShow}
        fieldsWatcher={fieldsWatcher}
        fields={fields}
        append={append}
        remove={remove}
        fieldWatcherName="galleryImagesLength"
        fieldsName="galleryImages"
        IMAGES_COUNT={IMAGES_COUNT}
      />
      <T8y fontSize="12px" color="gray" align="center">
        {trainings.galleryImageRequirements}
      </T8y>
    </GeneralExpandableSection>
  );
};

export default TrainingGallery;
