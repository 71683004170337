import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { settings$, updatePartOfSetting } from 'models/settings';

import { FieldStyle } from 'components/Product/shared';
import { SETTINGS } from 'api';

import { Checkbox, T8y } from 'ui';

export const SameDayDeliveryCheckbox = () => {
  const { common, form } = Lang();

  const [isUpdating, setIsUpdating] = useState(false);

  const settings = useStore(settings$);

  const onSubmit = useCallback(
    async value => {
      setIsUpdating(true);
      try {
        const { data } = await SETTINGS.updateGeneral({
          name: 'isDeliveryInSameDayPossible',
          value: value,
        });

        delete data.defaultDeliveryMethodType;

        updatePartOfSetting(data);

        toast.success(form.toastTitle.successfullyUpdated);
      } catch (e) {
        toast.error(form.toastTitle.updateError);
      } finally {
        setIsUpdating(false);
      }
    },
    [form],
  );

  return (
    <div>
      <T8y color="gray" marginBottom="10px" fontSize="14px">
        {common.calendar.sameDayDelivery}
      </T8y>
      <FieldStyle>
        <Checkbox
          onChange={e => onSubmit(!e.target.checked)}
          label={common.calendar.disableSameDayDelivery}
          checked={!settings.isDeliveryInSameDayPossible}
          disabled={isUpdating}
        />
      </FieldStyle>
    </div>
  );
};
