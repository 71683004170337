export const product = {
  titles: {
    createProduct: 'Crea nuovo prodotto',
    goToAll: 'Vai alla lista dei prodotti',
    editProduct: 'Creare prodotto',
    addToArchive: 'Aggiungi all’archivio',
    variations: 'Varianti',
    uploadPhotos: 'Aggiungi immagini',
    dropTheFiles: 'Trascina e rilascia il file',
    dragFileHere: 'Trascina File qui o',
    chooseFromYourComputer: 'caricalo dal tuo computer',
    createVariation: 'Crea una variante di un prodotto esistente',
    generalInformation: 'Informazioni',
    pricing: 'Listino Prezzi',
    additionalCharges: 'Costi aggiuntivi',
    seo: 'SEO',
    specifications: 'Specifiche',
    faq: 'FAQ',
    reviews: 'Recensioni',
    frequentlyHiredTogether: 'Noleggiati di solito insieme',
    requiredExtra: 'Extra Richiesti',
    optionalExtra: 'Extra Opzionali',
    noRelatedProducts: 'Nessun prodotto collegato',
    noRequiredExtra: 'Nessun extra richiesto',
    noOptionalExtra: 'Nessun extra opzionale',
    createRequiredExtra: 'Creare extra richiesto',
    createOptionalExtra: "Creare extra opzionale'",
    uploadUserGuides: 'Carica le guide utente',
    fileDownloaded: 'File caricato',
    editAltTags: 'Modifica gli Alt Tags',
    typicalUse: 'Utilizzo classico',
    eventTypes: 'Utilizzi',
    thelastImage: "L'ultima immagine non puo essere cancellata",
    editRequiredExtra: 'Modifica gli extra richiesti',
    chooseFrequently: 'Scegli Di Solito Noleggiati Insieme',
    chooseProduct: 'Scegli Prodotto',
    warehouses: 'Disponibilità Prodotto/Filiale',
    warehouseAvailability: 'Available quantity',
    consumables: 'Consumables',
    noConsumables: 'No consumables',
    otherCharges: 'Costi addizionali',
    chooseOptionalExtra: 'Scegli Extra opzionale',
    photo: 'Immagine',
    extraCode: 'Codice',
    name: 'Nome',
    price: 'Prezzo',
    saleItem: 'Prodotto',
    chooseRequiredExtra: 'Scegli Extra obbligatorio',
    videos: 'Video',
    safetyRecommendations: 'Raccomandazioni di sicurezza',
    thirdParty: 'Integrazioni terze parti',
    downloads: 'Documenti',
    productProfile: 'Pagina Prodotto',
    defaultDisplayedRange: 'Intervallo predefinito',
    platformProduct: 'Platform Product',
    linkProductWithPlatform: 'Link this product with a platform product',
  },

  notifications: {
    skuAlreadyBeenTaken: 'The SKU è già stato usato',
  },

  safetyRecommendations: {
    selectSafetyRecommendation: 'Seleziona raccomandazione di sicurezza',
    listOfSafetyRecommendations: 'Lista delle raccomandazioni di sicurezza',
  },
  thirdPartyIntegrations: {
    systemName: 'Nome sistema',
    integrationStatus: 'Stato integrazione',
    externalProductId: 'ID prodotto esterno',
    thirdPartiesNote:
      'Nota che il collegamento di almeno un prodotto esterno per qualsiasi sistema attivo disabiliterà le impostazioni nella sezione Disponibilità Prodotto-Filiale.',
    successConnectModalTitle: 'Collegare prodotto esterno tramite ID',
    response: 'Risposta',
    productTitle: 'Titolo del prodotto',
    productDescription: 'Descrizione del prodotto',
    okConnect: 'OK, Collegare questo prodotto',
    noIntegration: 'Nessuna integrazione',
    idIsNotConnected: "L'ID non è ancora connesso",
    idIsAlreadyConnected: "L'ID esiste già",
    fieldIsEmpty: 'Il campo è vuoto',
    locationCode: 'Location code',
    error: 'Errore',
  },
  selfCollectionOnly: 'Solo Ritiro in Sede',
  deliveryOnly: 'Solo Consegna',
  selfCollectionAndDelivery: 'Ritiro in Sede e Consegna',
  changesNotSaved:
    'Le modifiche non sono state salvate. Sei sicuro di voler lasciare questa pagina?',
  day: 'giorno',
  draftMessage:
    'Questo prodotto è in stato Bozza e non è visibile sul sito Web. Per rendere disponibile questo prodotto sul sito Web, fare clic sul pulsante Pubblica. Questa azione è irreversibile.',
  selectFranchisee: 'Seleziona il partner',
  productOwner: 'Proprietario del prodotto',
  owner: 'Proprietario',
};
