import styled from 'styled-components';

import Error from '../Error';

import { Row } from 'ui';

export const UploadImageWrapper = styled(Row)`
  width: ${props => props.width || '120px'};
`;

export const DropzoneWrapper = styled(Row)`
  width: ${props => props.width || '120px'};
  height: ${props => props.height || '120px'};
`;

export const ImageWrapper = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || '120px'};
  height: ${props => props.height || '120px'};
  padding: 4px;
  border: 1px solid #dddddd;
`;

export const RemoveIconWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 5px;
  right: 5px;
  &:hover {
    path {
      fill: #f89739;
    }
  }
`;

export const ErrorStyled = styled(Error)`
  margin-top: 5px;
`;
