import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { copyProduct } from 'models/product';

import { StyledIconWrapper } from '../styles';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';

export const CopyProductBtn = ({ id }) => {
  return (
    <Link to={PATHS.CREATE_PRODUCT} onClick={() => copyProduct(id)}>
      <StyledIconWrapper $marginRight="40px">
        <Copy />
      </StyledIconWrapper>
    </Link>
  );
};
