import React from 'react';
import { useStore } from 'effector-react';
import { useOrderProfile } from 'hooks';
import { user$ } from 'models/users';

import OrderProfile from 'components/molecules/OrderProfile';

const UserOrderProfile = () => {
  const user = useStore(user$);

  const {
    lead: order,
    productsWithExtra,
    isQuote,
    isTraining,
  } = useOrderProfile();

  if (!order) {
    return null;
  }

  return (
    <OrderProfile
      order={order}
      products={productsWithExtra}
      isQuote={isQuote}
      isTraining={isTraining}
      isUserPage
      userId={user?.id}
    />
  );
};

export default UserOrderProfile;
