import styled from 'styled-components';

import { Row, T8y } from 'ui';

import { ReactComponent as LinkIcon } from 'assets/images/icon/linkIcon.svg';

export const OrderInfoHeader = styled(Row)`
  margin-bottom: 20px;
`;

export const GeneralOrderInfo = styled.div`
  margin-bottom: 20px;
`;

export const OrderInfoFeature = styled(T8y)`
  color: #2e2e2e;
`;

export const PaymentLink = styled(T8y)`
  width: calc(100% - 26px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LinkIconStyled = styled(LinkIcon)`
  flex-shrink: 0;
`;
