import styled from 'styled-components';

export const FaqsWrapper = styled.div`
  margin-bottom: 20px;
  padding: 20px;
`;

export const IconWrapper = styled.div`
  margin-left: 15px;
`;
