import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Lang } from 'lang';
import { get } from 'lodash';

import Error from 'components/atoms/Error';
import { InputStyled } from 'components/Product/shared';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { Checkbox, Row, T8y } from 'ui';

const Warehouses = ({
  values,
  fields,
  setValue,
  errors,
  watch,
  control,
  clearErrors,
  isDisabled,
}) => {
  const {
    product: { titles },
    form: { notification },
  } = Lang();

  const handleCheckboxChange = useCallback(
    (e, index) => {
      setValue(`allWarehouses[${index}].isAdded`, e.target.checked);

      if (!e.target.checked) {
        clearErrors(`allWarehouses[${index}].quantity`);
      }
    },
    [setValue, clearErrors],
  );

  if (!fields.length) {
    return null;
  }

  return (
    <ProductEditorBlockWrapper blockTitle={titles.warehouses}>
      {fields.map((el, index) => {
        const isWarehouseAdded = watch(`allWarehouses[${index}].isAdded`);

        return (
          <ST.Wrapper key={el.id}>
            <Controller
              as={<ST.hiddenInput />}
              name={`allWarehouses[${index}].serverId`}
              control={control}
              defaultValue={el.serverId}
            />
            <Row noWrap>
              <ST.WarehouseAddedCheckbox>
                <Checkbox
                  isBlocked={isDisabled}
                  onChange={e => handleCheckboxChange(e, index)}
                  checked={get(values, ['allWarehouses', index, 'isAdded'])}
                />
              </ST.WarehouseAddedCheckbox>
              <ST.WarehouseAddedData>
                <div>
                  <T8y fontWeight="600" fontSize="1.6rem">
                    {el.name}
                  </T8y>
                  <T8y fontSize="1.2rem">{el.address}</T8y>
                </div>
                <ST.QuantityWrapper>
                  <T8y
                    color={isWarehouseAdded && !isDisabled ? 'green' : 'gray'}
                    fontWeight="600"
                    fontSize="1.4rem"
                  >
                    {titles.warehouseAvailability}:
                  </T8y>
                  <ST.QuantityInput>
                    <Controller
                      as={
                        <InputStyled
                          width="35%"
                          textAlign="right"
                          disabled={!isWarehouseAdded || isDisabled}
                          type="number"
                          isHideNumberArrows
                        />
                      }
                      disabled={isDisabled}
                      name={`allWarehouses[${index}].quantity`}
                      type="number"
                      control={control}
                      rules={
                        isWarehouseAdded && {
                          required:
                            !isDisabled && notification.theFieldIsRequired,
                          valueAsNumber: true,
                          validate: {
                            positive: value => {
                              if (isDisabled) {
                                return true;
                              }
                              return (
                                value > 0 || notification.valueShouldBePositive
                              );
                            },
                          },
                        }
                      }
                    />
                    <Error
                      message={get(errors, [
                        'allWarehouses',
                        index,
                        'quantity',
                        'message',
                      ])}
                    />
                  </ST.QuantityInput>
                </ST.QuantityWrapper>
              </ST.WarehouseAddedData>
            </Row>
          </ST.Wrapper>
        );
      })}
    </ProductEditorBlockWrapper>
  );
};

export default Warehouses;
