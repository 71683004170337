import { API_BASENAME, COUNTRY_SLUG_FROM_REGION } from 'consts';
import { createEvent, createStore } from 'effector';

import { countryInstance, instance } from 'api';

export const setWebsite = createEvent();

export const website$ = createStore({
  regionName: '',
  regionSlug: '',
  api: '',
}).on(setWebsite, (_, p) => {
  instance.defaults.baseURL = p.api;
  const country = COUNTRY_SLUG_FROM_REGION[p.regionSlug];
  countryInstance.defaults.baseURL = `https://${API_BASENAME}.${country}/api/v2/`;

  return p;
});
