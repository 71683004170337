import React, { Fragment } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { categoryPhotoStore, setCategoryPhoto } from 'models/categories';
import { handleUploadEnd } from 'utils/filesDownload';

import styled from 'styled-components';

import { Icon } from '../Product/Icon';

import { colors } from 'styleGuide';
import { DropZoneBlock } from 'ui';

const UploadImageTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

const UploadImageBody = styled.div`
  width: 100%;
  background: #fff;
  padding: 3rem;
  border-radius: 0.3rem;
`;

const ImageWrapper = styled.div`
  border: 1px solid ${colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.3;
  color: #787878;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`;

const UploadEmptyBlock = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1px;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 0 5px;
  bottom: 5px;
`;

export const UploadImage = ({ category_id, setDeletedId }) => {
  const {
    categories: { titles },
  } = Lang();

  const categoryPhoto = useStore(categoryPhotoStore);

  const handleUpload = acceptedFiles => {
    handleUploadEnd({
      acceptedFiles,
      handleUploaded: setCategoryPhoto,
      maxAmountOfFiles: 1,
    });
  };

  return (
    <Fragment>
      <UploadImageTitle>{titles.categoryVisual}</UploadImageTitle>
      <UploadImageBody>
        <ImageWrapper>
          {categoryPhoto?.src ? (
            <>
              <UploadEmptyBlock src={categoryPhoto.src} />
              <IconWrapper>
                <Icon
                  type="delete"
                  handleClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (category_id) {
                      setDeletedId(categoryPhoto.cover_id);
                    }

                    setCategoryPhoto({ src: null, cover_id: null });
                  }}
                />
              </IconWrapper>
            </>
          ) : (
            <DropZoneBlock handleUpload={handleUpload} multiple={false} />
          )}
        </ImageWrapper>
      </UploadImageBody>
    </Fragment>
  );
};
