import React, { useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
  RichUtils,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import * as ST from './styles';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const DEFAULT_OPTIONS = {
  options: ['inline', 'link', 'list'],
  inline: {
    options: ['bold', 'italic'],
  },
};

const customContentStateConverter = contentState => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map(block => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case 'IMAGE': {
          const newBlock = block.merge({
            type: 'atomic',
            text: 'img',
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });
  return contentState.set('blockMap', newBlockMap);
};

const ReachEditor = ({
  initialValue,
  handleChange,
  onChange,
  handleBeforeInput,
  handlePastedText,
  toolbar = DEFAULT_OPTIONS,
  innerRef,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const currentTextInHtml = useMemo(() => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    if (initialValue) {
      const blocksFromHTML = convertFromHTML(initialValue);

      setEditorState(
        EditorState.createWithContent(
          customContentStateConverter(
            ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            ),
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    let textForSetting = currentTextInHtml;

    const textLength = editorState.getCurrentContent().getPlainText().length;

    if (!textLength) {
      textForSetting = null;
    }

    if (handleChange) {
      handleChange({ text: textForSetting, length: textLength });
    }

    if (onChange) {
      onChange(textForSetting ? textForSetting.trim() : textForSetting);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTextInHtml]);

  return (
    <ST.EditorAreaWrapper>
      <Editor
        editorState={editorState}
        editorClassName="editorArea"
        toolbarClassName="toolbar"
        onEditorStateChange={state => setEditorState(state)}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={
          handlePastedText
            ? (text, html, state) =>
                handlePastedText(text, html, state, setEditorState)
            : undefined
        }
        toolbar={toolbar}
        handleKeyCommand={command => {
          const newState = RichUtils.handleKeyCommand(editorState, command);

          if (newState) {
            setEditorState(newState);
            return 'handled';
          }

          return 'not-handled';
        }}
        editorRef={innerRef}
      />
    </ST.EditorAreaWrapper>
  );
};

export default ReachEditor;
