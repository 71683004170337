import styled from 'styled-components';

import { colors } from 'styleGuide';

export const RowInfoStyled = styled.div`
  margin: 5rem 0;
  border-bottom: 1px solid ${colors.lightGray};
`;

export const SubTitleWrapper = styled.div`
  margin-bottom: 20px;
`;
