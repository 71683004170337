import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';

import CustomButton from 'components/atoms/CustomButton';
import PageHeaderWithBackLink from 'components/atoms/PageHeaderWithBackLink';

import * as ST from './styles';

const TrainingsEditorHeader = ({
  history,
  handleSubmit,
  pageHeader,
  children,
  loading,
}) => {
  const { header } = Lang();

  const handleCancel = useCallback(() => {
    history.push(PATHS.TRAININGS);
  }, [history]);

  return (
    <div>
      <ST.PageHeaderWithBackLinkWrapper>
        <PageHeaderWithBackLink header={pageHeader} link={PATHS.TRAININGS} />

        <ST.ControlsWrapper>
          <ST.CustomButtonStyled
            text={header.table.preview}
            backgroundColor="positive"
            width="160px"
            color="gray3"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            disabled
          />
          <ST.CustomButtonStyled
            text={header.table.cancel}
            backgroundColor="positive"
            width="160px"
            color="gray3"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            onClick={handleCancel}
          />
          <CustomButton
            text={header.table.save}
            width="160px"
            onClick={handleSubmit}
          />
        </ST.ControlsWrapper>
      </ST.PageHeaderWithBackLinkWrapper>

      <ST.TrainingIndexFormWrapper>{children}</ST.TrainingIndexFormWrapper>

      <ST.FooterControls>
        <ST.ControlsWrapper>
          <ST.CustomButtonStyled
            text={header.table.preview}
            backgroundColor="positive"
            width="160px"
            color="gray3"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            disabled
          />
          <ST.CustomButtonStyled
            text={header.table.cancel}
            backgroundColor="positive"
            width="160px"
            color="gray3"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            onClick={handleCancel}
          />
          <CustomButton
            text={header.table.save}
            width="160px"
            onClick={handleSubmit}
            loading={loading}
          />
        </ST.ControlsWrapper>
      </ST.FooterControls>
    </div>
  );
};

export default withRouter(TrainingsEditorHeader);
