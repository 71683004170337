export const users = {
  title: 'Users',
  subTitle: 'Review Customer Portal users and manage their special discounts',
  hideTestUsers: 'Hide test users',

  filterSelect: {
    all: 'All',
    company: 'Company',
    private: 'Private',
    verified: 'TA Activation - Yes',
    unverified: 'TA Activation - No',
    archived: 'Archived',
  },

  tableHeaders: {
    firstName: 'First Name',
    lastName: 'Last Name',
    businessName: 'Business Name',
    segment: 'Segment',
    discount: 'Discount',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number',
    idVerification: 'ID verification',
    status: 'TA Activation',
  },

  types: {
    private: 'Private',
    company: 'Company',
  },

  statuses: {
    verified: 'Yes',
    unverified: 'No',
    archived: 'Archived',
  },

  viewProfile: 'View profile',
  foundStr: '{0} users are found',
  searchPlaceholder: 'Start typing to search to filter users',
  backToPreviousScreen: 'Back to previous screen',
  backToListUsers: 'Back to list of all users',

  info: {
    title: 'User Profile ({0}, {1})',
    titleNewUser: 'User Profile (New)',
    fiscalCodeFieldTitle: 'Fiscal Code',
    officeAddressFieldTitle: 'Registered Office Address',
    postalCodeFieldTitle: 'Postal Code',
    provinceFieldTitle: 'Province',
    sectorOfActivity: 'Sector of activity?',
    uniqueCodeFieldTitle: 'Unique code',
    PECFieldTitle: 'PEC',
    deliveryAddressTitle: 'Delivery Address',
    howDidYouKnowAboutUs: 'How did you know about us',
    generalInformation: 'General Information',
    contactInformation: 'Contact Information',
    contactPhoneNumber: 'Contact Phone Number',
    contactEmailAddress: 'Contact Email Address',
    backToLogin: 'Back To Login',
    businessName: 'Business Name',
    fullName: 'Full Name',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number',
    firstName: 'First name',
    lastName: 'Last name',
    dateOfBirth: 'Date of birth',
    birthplace: 'Birthplace',
    address: 'Address',
    companyName: 'Company Name',
    country: 'Country',
    vatNumber: 'VAT Number',
    sortCode: 'Sort Code',
    userType: 'User Type',
    accountNumber: 'Account Number',
    taxNumber: 'Tax Number',
    city: 'City',
    newEmail: 'New Email',
    noType: 'No type',
    noDataAboutType: 'No data about type',
    idVerificationStatus: 'ID Verification status',
  },

  sectorOfActivity: {
    business: 'Business',
    constructions: 'Constructions',
    public_authority: 'Public authority',
    training_courses: 'Training courses',
    gardening: 'Gardening',
    industry: 'Industry',
    services: 'Services',
    transport: 'Transport',
    tourism: 'Tourism',
    other: 'Other',
  },

  aboutUs: {
    search_on_the_internet: 'Search on the Internet',
    television: 'Television',
    word_of_mouth: 'Word of mouth',
    headquarter_visibility: 'Headquarter visibility',
    construction_warehouse: 'Construction warehouse',
    gardening_dealer: 'Gardening dealer',
    magazines_and_newspapers: 'Magazines and newspapers',
    other: 'Other',
  },

  discount: {
    title: 'User Discount',
    discount: 'Discount Membership',
    option: 'Enable Discount',
    description:
      'Selected discount will be applied to all catalogue products if user is logged into Customer Portal',
  },

  discountOptions: {
    platinum: 'Platino',
    gold: 'Oro',
    silver: 'Plata',
  },

  deposit: {
    title: 'Refundable Deposit',
    option: 'Enable Deposit',
    description:
      'If unchecked the refundable deposit won’t be added to the orders placed by the user',
  },

  sureToLogIn: 'Are you sure you want to log in as a user?',
  membershipLevel: 'Membership Level',
  findPostcode: 'Start typing your Post Code',
  noItems: 'No items to display',
  backToResults: 'Back to results',
  newUser: 'Add New User',
  imCompany: 'I am a company',
};
