import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
  DisclosureContent,
  useDialogState,
} from 'reakit';
import clsx from 'clsx';

import styled from 'styled-components';

import { Row, T8y } from 'ui';

import { ReactComponent as Cross } from 'assets/images/icon/cross.svg';

import style from './modal.module.scss';

const DialogStyled = styled(Dialog)`
  outline: none;
`;

const ButtonStyled = styled(Button)`
  outline: none;
`;

const ModalHeader = styled.div`
  ${p =>
    p.backgroundColor &&
    `
    background-color: ${p.backgroundColor};
  `}
`;

export const Modal = ({
  children,
  title,
  disclosure,
  backgroundColor = null,
  width: maxWidth,
  onCloseCallback,
  overflow = 'auto',
  minHeight = null,
  dialogState,
  lazyRender,
}) => {
  const dialog = useDialogState();

  const dialogCurrentState = dialogState || dialog;

  const ref = React.useRef();

  React.useEffect(() => {
    if (dialogCurrentState.visible) {
      ref.current.focus();
    }
  }, [dialogCurrentState.visible]);

  const handleClose = useCallback(() => {
    dialogCurrentState.hide();

    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [dialogCurrentState, onCloseCallback]);

  const header = React.useMemo(
    () =>
      title ? (
        <Row
          as={ModalHeader}
          align="center"
          justify="stretch"
          className={style.header}
          backgroundColor={backgroundColor}
        >
          <T8y color="positive" variant="t2" bold>
            {title}
          </T8y>
          <ButtonStyled
            onClick={handleClose}
            className={style.close}
            width={18}
            height={18}
            ref={ref}
          >
            <Cross />
          </ButtonStyled>
        </Row>
      ) : (
        <ButtonStyled
          onClick={dialogCurrentState.hide}
          className={clsx(style.close, style.closeabsolute)}
          width={18}
          height={18}
          ref={ref}
        >
          <Cross fill="#2e2e2e" />
        </ButtonStyled>
      ),
    [title, backgroundColor, handleClose, dialogCurrentState.hide],
  );

  return (
    <>
      {disclosure && (
        <DialogDisclosure {...dialogCurrentState} as="div">
          {disclosure}
        </DialogDisclosure>
      )}
      {lazyRender ? (
        <DisclosureContent>
          {props =>
            dialogCurrentState.visible && (
              <DialogBackdrop
                {...dialogCurrentState}
                className={style.backdrop}
              >
                <DialogStyled
                  {...dialogCurrentState}
                  aria-label={title}
                  className={style.container}
                  style={{ maxWidth, overflow }}
                >
                  {header}
                  {minHeight ? (
                    <div style={{ minHeight }}>{children}</div>
                  ) : (
                    children
                  )}
                </DialogStyled>
              </DialogBackdrop>
            )
          }
        </DisclosureContent>
      ) : (
        <DialogBackdrop {...dialogCurrentState} className={style.backdrop}>
          <DialogStyled
            {...dialogCurrentState}
            aria-label={title}
            className={style.container}
            style={{ maxWidth, overflow }}
          >
            {header}
            {minHeight ? <div style={{ minHeight }}>{children}</div> : children}
          </DialogStyled>
        </DialogBackdrop>
      )}
    </>
  );
};
