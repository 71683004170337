import React, { useEffect, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  changeHideTestUsers,
  changeSelectedUsersLimit,
  changeUsersTablePage,
  clearUsers,
  filters$,
  setDefaultUsersFilters,
  setUsersFilter,
  setUsersSearchStr,
  users$,
  usersMeta$,
} from 'models/users';

import CustomButton from 'components/atoms/CustomButton';
import Table from 'components/atoms/Table';
import FiltersPanel from 'components/molecules/FiltersPanel';

import * as ST from './styles';

import { Checkbox, Row, T8y, TablePaging } from 'ui';

const keyFields = [
  { name: 'first_name', type: 'text' },
  { name: 'last_name', type: 'text' },
  { name: 'business_name', type: 'text' },
  { name: 'type', type: 'text' },
  { name: 'discount', type: 'text' },
  { name: 'email', type: 'text' },
  { name: 'phone', type: 'text' },
  { name: 'idVerified', type: 'text' },
  { name: 'status', type: 'text' },
  { name: 'link', type: 'link' },
];

const Users = ({ history }) => {
  const {
    form: { fields },
    users: usersLocalized,
    formatString,
  } = Lang();

  const users = useStore(users$);
  const usersMeta = useStore(usersMeta$);
  const usersFilters = useStore(filters$);

  const filterOptions = useMemo(() => {
    return Object.keys(usersLocalized.filterSelect).map(el => ({
      name: usersLocalized.filterSelect[el],
      slug: el,
    }));
  }, [usersLocalized]);

  const headers = useMemo(() => {
    return [...Object.values(usersLocalized.tableHeaders), ''];
  }, [usersLocalized]);

  useEffect(() => {
    setDefaultUsersFilters();
    return () => clearUsers();
  }, []);

  const limitOptions = useMemo(() => {
    return [
      { name: fields.showAll, slug: 10000 },
      { name: `${fields.show} 100`, slug: 100 },
      { name: `${fields.show} 50`, slug: 50 },
      { name: `${fields.show} 10`, slug: 10 },
    ];
  }, [fields]);

  const limitDisplayName = useMemo(() => {
    return limitOptions.find(({ slug }) => slug === usersFilters.limit).name;
  }, [limitOptions, usersFilters]);

  const handleLimitOptionChange = option => {
    changeSelectedUsersLimit(option);
  };

  const modifiedDisplayedUsers = useMemo(() => {
    return users.map(el => {
      const realType = el.type === 'individual' ? 'private' : el.type;
      return {
        first_name: el.first_name,
        last_name: el.last_name,
        business_name: el.business_name || '-',
        type: realType ? usersLocalized.types[realType] : '',
        discount: el.tier?.data.discount || '',
        email: el.email,
        phone: el.phone || el.address?.phone,
        idVerified: el.idVerified || '-',
        status:
          usersLocalized.statuses[el.verified ? 'verified' : 'unverified'],
        link: (
          <Link to={PATHS.USER_PROFILE(el.id)}>
            {usersLocalized.viewProfile}
          </Link>
        ),
      };
    });
  }, [users, usersLocalized]);

  const selected = useMemo(() => {
    return (
      filterOptions.find(el => el.slug === usersFilters.selector)?.name || ''
    );
  }, [filterOptions, usersFilters]);

  return (
    <>
      <ST.HeadersWrapper>
        <T8y color="darkBlue" fontSize="2.4rem" bold>
          {usersLocalized.title}
        </T8y>
        <T8y fontSize="1.6rem" color="gray2" fontWeight="600">
          {usersLocalized.subTitle}
        </T8y>
      </ST.HeadersWrapper>
      <FiltersPanel
        selectOptions={filterOptions}
        selected={selected}
        onClickOption={setUsersFilter}
        onChangeSearch={setUsersSearchStr}
        searchStr={usersFilters.search}
        searchPlaceholder={usersLocalized.searchPlaceholder}
        foundStr={formatString(usersLocalized.foundStr, usersMeta.total || 0)}
        limitOptions={limitOptions}
        limitDisplayName={limitDisplayName}
        handleLimitOptionChange={handleLimitOptionChange}
        className="usersFilter"
      >
        <Row align="center" noWrap>
          <Checkbox
            onChange={e => changeHideTestUsers(e.target.checked)}
            checked={usersFilters.hideTestUsers}
          />
          <T8y color="negative">{usersLocalized.hideTestUsers}</T8y>
        </Row>
        <CustomButton
          text={usersLocalized.newUser}
          backgroundColor="primary"
          color="positive"
          border="1px solid #f89739"
          colorHovered="gray"
          onClick={() => {
            history.push(PATHS.USER_PROFILE_NEW());
          }}
        />
      </FiltersPanel>
      <Table
        headers={headers}
        keyFields={keyFields}
        items={modifiedDisplayedUsers}
        cols="2fr 2fr 2fr 1fr 1fr 3fr 2fr 2fr 1fr 1fr"
      />
      {usersMeta.total_pages > 1 && (
        <TablePaging
          handlePageChange={el => changeUsersTablePage(el)}
          totalPages={usersMeta.total_pages}
          currPage={usersMeta.current_page}
        />
      )}
    </>
  );
};

export default withRouter(Users);
