import React, { useCallback, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { trainingLocalized$ } from 'models/trainings';

import Error from 'components/atoms/Error';
import ReachEditor from 'components/atoms/ReachEditor';

import GeneralExpandableSection from '../GeneralExpandableSection';

const TrainingFormDescription = ({
  errors,
  getIsError,
  setValue,
  trigger,
  register,
}) => {
  const { trainings, form } = Lang();
  const trainingLocalized = useStore(trainingLocalized$);

  useEffect(() => {
    register(
      'form.description',
      { required: form.notification.theFieldIsRequired },
      {
        validate: value => {
          return (
            (value && !!value.trim()) || form.notification.theFieldIsRequired
          );
        },
      },
    );
  }, [form.notification.theFieldIsRequired, register]);

  const handleDescriptionText = useCallback(
    info => {
      setValue('form.description', info.text);
      trigger('form.description');
    },
    [setValue, trigger],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.courseDescription}
      listOfFields={['description']}
      errors={errors}
      initialIsOpen
    >
      <ReachEditor
        initialValue={trainingLocalized?.description}
        handleChange={handleDescriptionText}
      />
      <Error message={getIsError('description')} />
    </GeneralExpandableSection>
  );
};

export default TrainingFormDescription;
