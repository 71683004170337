export const users = {
  title: 'Utenti',
  subTitle: 'Vedi gli utenti del Customer Portal e gestisci la scontistica',
  hideTestUsers: 'Nascondi utenti ‘test’',

  filterSelect: {
    all: 'Tutto',
    company: 'Azienda',
    private: 'Privato',
    verified: 'TA Attivo - Si',
    unverified: 'TA Attivo - No',
    archived: 'Archiviato',
  },

  tableHeaders: {
    firstName: 'Nome',
    lastName: 'Cognome',
    businessName: 'Ragione Sociale',
    segment: 'Settore',
    discount: 'Discount',
    emailAddress: 'Indirizzo email',
    phoneNumber: 'Numero di telefono',
    idVerification: 'ID verification',
    status: 'TA Attivo',
  },

  types: {
    private: 'Privato',
    company: 'Azienda',
  },

  statuses: {
    verified: 'Si',
    unverified: 'No',
    archived: 'Archiviato',
  },

  viewProfile: 'Vedi profilo',
  foundStr: 'trovati {0} utenti',
  searchPlaceholder: 'Inizia a digitare per ricercare gli utenti',
  backToPreviousScreen: 'Torna alla schermata precedente',
  backToListUsers: "Torna all'elenco degli utenti",

  info: {
    title: 'Profilo utente ({0}, {1})',
    titleNewUser: 'Profilo utente (Nuovo)',
    fiscalCodeFieldTitle: 'Codice Fiscale',
    officeAddressFieldTitle: 'Indirizzo Sede Legale',
    postalCodeFieldTitle: 'CAP',
    provinceFieldTitle: 'Regione',
    sectorOfActivity: 'Settore di appartenzenza?',
    uniqueCodeFieldTitle: 'Codice Univoco',
    PECFieldTitle: 'PEC',
    deliveryAddressTitle: 'Indirizzo di Consegna',
    howDidYouKnowAboutUs: 'Come hai conosciuto la nostra azienda',
    generalInformation: 'Informazioni Generali',
    contactInformation: 'Informazioni di Contatto',
    contactPhoneNumber: 'Numero di Telefono',
    contactEmailAddress: 'Indirizzo E-mail',
    backToLogin: 'Back To Login',
    businessName: 'Ragione Sociale',
    fullName: 'Nome e Cognome',
    emailAddress: 'Indirizzo email',
    phoneNumber: 'Numero di telefono',
    userType: 'Profilo Utente',
    firstName: 'Nome',
    lastName: 'Cognome',
    dateOfBirth: 'Data di nascita',
    birthplace: 'Luogo di Nascita',
    address: 'Indirizzo',
    companyName: "Nome dell'Azienda",
    country: 'Paese',
    vatNumber: 'Partita IVA',
    sortCode: 'BIC/SWIFT',
    accountNumber: 'Numero Conto Corrente',
    taxNumber: 'Codice Fiscale',
    city: 'Città',
    newEmail: 'Nuova e-mail',
    noType: 'Nessun Tipo',
    noDataAboutType: 'Non è stato specificato alcun tipo',
    idVerificationStatus: 'ID Verification status',
  },

  sectorOfActivity: {
    business: 'Attività Commerciale',
    constructions: 'Edilizia',
    public_authority: 'Ente Pubblico',
    training_courses: 'Formazione',
    gardening: 'Giardinaggio',
    industry: 'Industria',
    services: 'Servizi',
    transport: 'Trasporti',
    tourism: 'Turismo',
    other: 'Altro',
  },

  aboutUs: {
    search_on_the_internet: 'Ricerca Su Internet',
    television: 'Televisione',
    word_of_mouth: 'Passaparola',
    headquarter_visibility: 'Visibilità Sede',
    construction_warehouse: 'Magazzino Edile',
    gardening_dealer: 'Rivenditore Giardinaggio',
    magazines_and_newspapers: 'Riviste e giornali',
    other: 'Altro',
  },

  discount: {
    title: 'Sconto Utente',
    discount: 'Membership Sconto Clienti',
    option: 'Applicare Sconto',
    description:
      "Lo sconto selezionato verrà applicato a tutti i prodotti del catalogo se l'utente ha effettuato l'accesso al Portale Clienti",
  },

  discountOptions: {
    platinum: 'Platino',
    gold: 'Oro',
    silver: 'Plata',
  },

  deposit: {
    title: 'Deposito rimborsabile',
    option: 'Attiva Deposito',
    description:
      "Se deselezionato il deposito rimborsabile non verrà aggiunto agli ordini effettuati dall'utente",
  },

  sureToLogIn: 'Sei sicuro di voler accedere come utente?',
  membershipLevel: 'Tipo di membership',
  findPostcode: 'Inizia a digitare il tuo codice postale',
  noItems: 'Nessun articolo da visualizzare',
  backToResults: 'Torna ai risultati',
  newUser: 'Aggiungere nuovo utente',
  imCompany: 'Sono un’azienda',
};
