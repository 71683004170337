export const leads = {
  subTitle: 'Monitor all website leads in realtime',
  backToAllLeads: 'Back to list of all leads',
  sourcePage: 'Source page',
  leadsAvailable: '{0} leads available',
  hideTestLeads: 'Hide test leads',
  viewSourcePage: 'View source page',
  comment: 'Comment',
  message: 'Message',
  crmDeal: 'CRM Deal',
  crmContact: 'CRM Contact',
  callTo: 'Call to',
  customerTax: 'Customer Tax',
  billingAddress: 'Billing Address',
  press: 'Press',
  emailIsCopied: 'Email is copied to your clipboard',
  shuftiVerificationStatus: 'Shufti Pro verification status',
  shuftiVerificationLink: 'Shufti Pro verification link',
  yes: 'Yes',
  no: 'No',
  stripePaymentLink: 'Stripe Payment link',

  userTypes: {
    individual: 'Individual',
    company: 'Company',
  },

  leadInfoText: {
    enquiry: '{0} has made this enquiry and is waiting to be contacted.',
    callback: '{0} has requested this callback and is waiting to be contacted.',
    trainingEnquiry:
      '{0} has made this enquiry about a training course and is waiting to be contacted.',
  },

  tableHeaders: {
    dateCreated: 'Date Created',
    leadType: 'Lead Type',
    leadSource: 'Lead Source',
    fullName: 'Full Name',
    companyName: 'Company Name',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    CRMDealStage: 'CRM Deal Stage',
  },

  types: {
    all: 'All',
    enquiry: 'Enquiry',
    callback: 'Callback',
    trainingEnquiry: 'Training Enquiry',
    product: 'Product',
    quote: 'Quote',
    trainingOrder: 'Training Order',
    training: 'Training Order',
    checkoutStarted: 'Checkout Started',
    newsletterSubscribe: 'Subscribed to newsletter',
  },
};
