import { PATHS } from 'AppPaths';
import { ForgotPassword } from 'pages/ForgotPassword';
import Login from 'pages/login';
import { ResetPassword } from 'pages/ResetPassword';

export default [
  {
    path: PATHS.LOGIN,
    exact: true,
    component: Login,
  },
  {
    path: PATHS.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword,
  },
  {
    path: PATHS.RESET_PASSWORD,
    exact: true,
    component: ResetPassword,
  },
];
