import { combine, createEffect, guard, restore } from 'effector';
import { createGate } from 'effector-react';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';

import { PRODUCTS } from 'api';

import { settings$ } from './settings';

export const safetyRecommendationsGate = createGate('');

const getSafetyRecommendationsFx = createEffect({
  handler: async () => {
    const { data } = await PRODUCTS.getSafetyRecommendations();
    return data.data;
  },
});

export const safetyRecommendations$ = restore(getSafetyRecommendationsFx, []);

export const safetyRecommendationsLocalized$ = combine(
  safetyRecommendations$,
  currentLang$,
  settings$,
  (safetyRecommendations, lang, { defaultLanguage }) => {
    return safetyRecommendations.map(el => ({
      ...el,
      name: el.names[lang] || el.names[defaultLanguage],
    }));
  },
);

guard({
  source: safetyRecommendationsGate.open,
  filter: safetyRecommendations$,
  target: getSafetyRecommendationsFx,
});
