import { createEffect, createEvent, forward, restore } from 'effector';

import { BANNERS } from 'api';

export const loadBannersFx = createEffect({
  handler: async () => {
    const { data } = await BANNERS.getAllBanners();

    return data.data.map(el => {
      const isMediaWeb = el.media_web && el.media_web.data;
      const isMediaMobile = el.media_mobile && el.media_mobile.data;

      return {
        file_id_web: isMediaWeb ? el.media_web.data.id : '',
        file_id_mobile: isMediaMobile ? el.media_mobile.data.id : '',
        srcWeb: isMediaWeb ? el.media_web.data.src : '',
        srcMobile: isMediaMobile ? el.media_mobile.data.src : '',
        slideIndex: el.sort,
        cta: el.cta_optional,
        target_blank: el.target_blank,
        btnUrl: el.url,
        serverId: el.id,
        h1Text: el.h1Text || '',
        pText: el.pText || '',
        btnText: el.btnText || '',
      };
    });
  },
});

export const updateBannersFx = createEffect({
  handler: async items => {
    try {
      const itemsForCreation = items.map(el => ({
        sort: el.slideIndex || 1,
        target_blank: el.target_blank || false,
        url: el.btnUrl,
        cta_optional: el.cta,
        media: {
          web: el.srcWeb || null,
          mobile: el.srcMobile || null,
        },
        h1Text: el.h1Text || '',
        pText: el.pText || '',
        btnText: el.btnText || '',
      }));

      const { data } = await BANNERS.updateBanners({
        banners: itemsForCreation,
      });

      return data.map(el => {
        const isMediaWeb = el.media_web && el.media_web.data;
        const isMediaMobile = el.media_mobile && el.media_mobile.data;

        return {
          file_id_web: isMediaWeb ? el.media_web.data.id : '',
          file_id_mobile: isMediaMobile ? el.media_mobile.data.id : '',
          srcWeb: isMediaWeb ? el.media_web.data.src : '',
          srcMobile: isMediaMobile ? el.media_mobile.data.src : '',
          slideIndex: el.sort,
          cta: el.cta_optional,
          target_blank: el.target_blank,
          btnUrl: el.url,
          h1Text: el.h1Text,
          pText: el.pText,
          btnText: el.btnText,
          serverId: el.id,
        };
      });
    } catch (e) {
      return false;
    }
  },
});

export const setUserSettings = createEvent();
export const getBanners = createEvent();
export const updateBanners = createEvent();

export const banners$ = restore(loadBannersFx, []).on(
  updateBanners,
  (s, p) => p,
);

forward({
  from: getBanners,
  to: loadBannersFx,
});

forward({
  from: updateBanners,
  to: updateBannersFx,
});
