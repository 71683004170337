import React, { useCallback } from 'react';
import { Tooltip, TooltipReference, useTooltipState } from 'reakit';
import { Lang } from 'lang';
import { copyText } from 'utils/copyText';

import * as ST from './styles';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

const EmailField = ({ email }) => {
  const { leads } = Lang();

  const tooltip = useTooltipState();

  const disableTooltipEvent = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div>
      <TooltipReference
        {...tooltip}
        onMouseLeave={disableTooltipEvent}
        onMouseDown={disableTooltipEvent}
        onMouseEnter={disableTooltipEvent}
      >
        <ST.EmailField>
          {email}{' '}
          <CopyIcon
            onClick={() => {
              if (copyText(email, leads.press)) {
                tooltip.show();
                setTimeout(() => {
                  tooltip.hide();
                }, 1000);
              }
            }}
            title="Copy"
          />
        </ST.EmailField>
      </TooltipReference>
      <Tooltip {...tooltip}>
        <ST.TooltipTextWrapper>{leads.emailIsCopied}</ST.TooltipTextWrapper>
      </Tooltip>
    </div>
  );
};

export default EmailField;
