import styled from 'styled-components';

import { colors } from 'styleGuide';

export const IntegrationsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 5fr;
`;

export const GridItem = styled.div`
  border-bottom: 1px solid #e4e8ed;
  padding: 14px 0;
  font-size: 16px;

  ${p =>
    p.color &&
    `
       color: ${colors[p.color]};
    `}
`;

export const Note = styled.span`
  display: block;
  font-size: 16px;
  margin-top: 24px;
`;
