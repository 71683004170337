import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { isLoadingCategories$ } from 'models/categories';
import { settingsLoading$ } from 'models/settings';

import AddMore from 'components/atoms/AddMore';
import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import JobTypeCategory from '../JobTypeCategory';
import * as ST from './styles';

const JobTypeCategories = ({
  errors,
  control,
  values,
  setValue,
  register,
  getIsError,
  watch,
  unregister,
}) => {
  const { marketing } = Lang();

  const isLoadingCategories = useStore(isLoadingCategories$);
  const settingsLoading = useStore(settingsLoading$);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'categories',
    keyName: 'formId',
  });

  const isAddProductsBlocked = fields.length >= 10;

  const addCategory = useCallback(() => {
    append({ description: null, id: null });
  }, [append]);

  return (
    <GeneralExpandableSection
      index={1}
      header={marketing.jobTypes.relatedCategories}
      listOfFields={['categories']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      {(settingsLoading || isLoadingCategories) && <SpinnerOverlay />}
      {fields.map((el, index) => (
        <JobTypeCategory
          index={index}
          watch={watch}
          values={values}
          setValue={setValue}
          register={register}
          getIsError={getIsError}
          fields={fields}
          el={el}
          remove={remove}
          unregister={unregister}
          errors={errors}
          key={el.formId}
        />
      ))}
      <ST.AddProductsWrapper>
        <AddMore
          fontSize="14px"
          text={marketing.jobTypes.addCategory}
          disabled={isAddProductsBlocked}
          onClick={addCategory}
        />
      </ST.AddProductsWrapper>
    </GeneralExpandableSection>
  );
};

export default JobTypeCategories;
