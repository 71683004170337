import { FieldStyle } from 'components/Product/shared';
import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row } from 'ui';

import { ReactComponent as Lens } from 'assets/icons/lens.svg';

export const FiltersWrapper = styled(Row)`
  margin-bottom: 20px;

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlltersSelectorWrapper = styled(FieldStyle)`
  max-width: 200px;
`;

export const SearchWrapperStyled = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: 280px;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 0 10px 0 40px;
  line-height: 40px;
  font-size: 14px;
  color: ${colors.darkBlue};

  &::placeholder {
    color: #777777;
  }
`;

export const SearchIcon = styled(Lens)`
  position: absolute;
  top: 12px;
  left: 11px;
  height: 15px;
`;
