export const trainings = {
  title: 'Gestión de cursos de formación',
  subTitle: 'Gestionar la página de inicio de los cursos de formación',
  trainingIndexPage: 'Página de inicio de los cursos de formación',
  trainingsCourses: 'Cursos de formación',
  addNewCourse: 'Agregar nuevo curso',
  closestDate: 'Fecha más cercana',
  showSection: 'Mostrar sección',
  remainingCharacters: 'Quedan {0} caracteres',
  heroImage: 'Imagen Hero',
  heroImageOrientation: 'Imagen JPG {0} x {1} píxeles, orientación horizontal',
  thumbnailImage: 'Imagen thumbnail',
  thumbnailImageOrientation:
    'Imagen JPG 600 x 450 píxeles, orientación horizontal',
  yes: 'Sí',
  no: 'No',

  indexPage: {
    title: 'Página de inicio de los cursos de formación',
    generalInformation: 'Información General',
    introductorySection: 'Introducción',
    exploreOurTrainingCourses: 'Explora nuestros cursos de formación',
    pageTitle: 'Título de la página',
    introductoryText: 'Texto introductorio',
    automaticallyGeneratedSection:
      'Esta sección se genera automáticamente usando los cursos que creas.',
    sectionTitle: 'Título de la sección',
  },

  courseStatus: 'Estado del curso',
  courseTitle: 'Título del curso',
  courseShortDescription: 'Breve descripción del curso',
  courseDescription: 'Descripción del curso',
  courseLearning: 'Lo que vas a aprender',
  courseWhyChooseUs: 'Por qué elegirnos',
  courseReviews: 'Opiniones del curso',
  frequentlyAskedQuestions: 'Preguntas frecuentes',
  theoryAndPractice:
    'Horas de teoría y de práctica (ej: 4h teoría, 8h práctica)',
  courseGallery: 'Galería del curso',
  galleryImageRequirements:
    'Requisitos del archivo: imagen JPG, 800 x 600 píxeles, orientación horizontal',
  benefitImageRequirements: 'Imagen JPG o PNG, 120 x 120 píxeles',
  scheduleCourses: 'Cursos programados',
  availabilityAndSchedule: 'Curso disponible para reserva',
  courseIsAvailable: 'Curso disponible para pago de la reserva',
  courseIsAvailableText:
    'Los usuarios podrán hacer la reserva y el pago online',
  courseIsNotAvailable: 'Curso no disponible para reserva',
  courseIsNotAvailableText:
    'Los usuarios podrán enviar una solicitud mediante un formulario de contacto.',
  courseLocation: 'Ubicación del curso',
  courseOrganizer: 'Organizador del curso (opcional)',
  datesAndPrice: 'Precio y fechas del curso programado',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de finalización (opcional)',
  coursePrice: 'Precio del curso',
  addNewDate: 'Agregar nueva fecha',
  addNewLocation: 'Agregar nueva ubicación',
  addNewVariation: 'Agrega una nueva variante',
  addNewBenefit: 'Agregar beneficio',
  addNewReview: 'Agregar nueva opinión',
  addNewFAQ: 'Agregar nuevas preguntas frecuentes',
  addNewProduct: 'Agregar producto',
  scheduleDescription:
    'Descripción del horario o fechas del curso (ej: el horario está disponible sobre pedido)',
  courseLocations: 'Ubicación del curso',
  priceExample: 'ej: €200.00 + IVA',
  partnership: 'Curso realizado en colaboración con',
  partnershipImageRequirements:
    'Requisitos del archivo: imagen JPG, PNG o SVG, NN x NN píxeles',
  closestDateAutomaticallyGenerated:
    'Esta sección se genera automáticamente utilizando la información del curso que has dado en otras secciones.',
  aboutCertificate: 'Acerca del certificado',
  certificateImage:
    'Imagen del certificado (imagen JPG, 600 x 400 píxeles, orientación horizontal)',
  emptyDates: 'Fechas a definir',
  emptyLocation: 'Organizador del curso y ubicación a definir',
  emptyPrice: 'Precio a definir',
  emptyTheory: 'Horas de teoría y práctica a definir',
  schedule: 'Programado (fechas y precios)',
  noSchedule: 'Sin programar (sólo precio)',
  courseVariationTitle: 'Título de la variante del curso (opcional)',
  recommendedProducts: 'Productos Recomendados',

  benefits: {
    enterBenefitTitle: 'Ingresar título del beneficio',
    icon: 'Ícono',
    shortTitle: 'Título corto',
    details: 'Detalles de los beneficios',
  },

  reviews: {
    enterReviewFields: 'Ingresar campos de opinión',
    photo: 'Foto',
    review: 'Opinión',
    fullName: 'Nombre completo (ej: Juan Pérez)',
    position: 'Cargo (ej: Jefe de planta en EmpresaX) (opcional)',
  },

  faq: {
    enterFaq: 'Ingresar pregunta',
    question: 'Pregunta',
    answer: 'Respuesta',
  },

  enterCourseTitle: 'Ingresar título del curso',
};
