import React, { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  consumables$,
  ConsumablesGate,
  reloadConsumables,
} from 'models/consumables';

import AttributesTable from 'components/atoms/AttributesTable';
import ConsumablesModal from 'components/molecules/ConsumablesModal';

const Consumables = ({ control }) => {
  useGate(ConsumablesGate);
  const consumables = useStore(consumables$);

  const {
    product: { titles },
  } = Lang();

  const TABLE_HEADERS = useMemo(() => {
    return [titles.photo, titles.extraCode, titles.name, titles.price];
  }, [titles]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'consumables',
  });

  return (
    <AttributesTable
      fields={fields}
      allItems={consumables}
      tableHeaders={TABLE_HEADERS}
      title={titles.consumables}
      noItemsTitle={titles.noRequiredExtra + '...'}
      gridSettings="1fr 2fr 2fr 1fr 1fr"
      append={append}
      remove={remove}
      type="consumables"
      reload={reloadConsumables}
      isDescription
    >
      <ConsumablesModal fields={fields} append={append} remove={remove} />
    </AttributesTable>
  );
};

export default Consumables;
