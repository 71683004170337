export const tradeAccounts = {
  subTitle: 'Review Trade Accounts and manage their special discounts',

  titles: {
    privateAccountProfile: 'Private Account profile',
    companyAccountProfile: 'Company profile',
    accountOwner: 'Account Owner',
  },

  filterSelect: {
    all: 'Todos',
    verified: 'TA Activo - Si',
    unverified: 'TA Activo - No',
    archived: 'Archivado',
  },

  fields: {
    tradingName: 'Trading Name',
    companyNumber: 'Company Number',
    billingAddress: 'Billing Address',
    billingEmailAddress: 'Billing Email Address',
    shippingAddress: 'Shipping Address',
  },

  types: {
    private: 'Privado',
    companies: 'Companies',
  },

  foundStr: '{0} accounts are found',
  searchPlaceholder: 'Start typing to search to filter accounts',
  hideTestAccounts: 'Hide test accounts',
  backToCompaniesList: 'Back to list of all companies',
  accountDiscount: 'Account Discount',
  createTA: 'Create TA',
};
