import React from 'react';
import { useStore } from 'effector-react';
import { branding$ } from 'models/branding';
import { handleChangePriceField } from 'utils/helpers/numbers';

import { InputNumberStyled } from 'components/Product/shared';

import * as ST from './styles';

const InputWithCurrencySign = ({ innerRef, disabled, ...rest }) => {
  const branding = useStore(branding$);

  return (
    <ST.InputWrapper>
      <ST.EuroIcon disabled={disabled}>{branding?.currencySymbol}</ST.EuroIcon>
      <InputNumberStyled
        ref={innerRef}
        type="number"
        disabled={disabled}
        onChange={handleChangePriceField}
        {...rest}
      />
    </ST.InputWrapper>
  );
};

export default InputWithCurrencySign;
