import React, { useCallback, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { trainingIndexLocalized$ } from 'models/trainings';
import { handleUploadEnd } from 'utils/filesDownload';

import Error from 'components/atoms/Error';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import TrainingHeroImageWrapper from 'components/atoms/TrainingHeroImageWrapper';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import UploadTrainingPicture from 'components/molecules/UploadTrainingPicture';

import { FieldStyle2, InputStyled, TextareaStyled } from '../../Product/shared';
import * as ST from './styles';

import { T8y } from 'ui';

const TITLE_MAX_LENGTH = 64;
const TEXT_MAX_LENGTH = 500;

const TrainingIndexFormGeneralInformation = ({
  watch,
  register,
  values,
  getIsError,
  setValue,
  errors,
  setDeletedFile,
  trigger,
}) => {
  const { formatString, trainings, form } = Lang();

  const trainingIndexLocalized = useStore(trainingIndexLocalized$);

  const watchImageId = watch('form.mainImage.id');

  useEffect(() => {
    register(
      { name: 'form.mainImage.src' },
      { required: form.notification.theFieldIsRequired },
    );
  }, [form.notification.theFieldIsRequired, register]);

  const titleRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'form.title',
    TITLE_MAX_LENGTH,
  );
  const textRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'form.text',
    TEXT_MAX_LENGTH,
  );

  const handleDeleteMainImage = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      if (watchImageId) {
        setDeletedFile(watchImageId);
      }

      setValue('form.mainImage.id', null);
      setValue('form.mainImage.src', null);
      trigger('form.mainImage.src');
    },
    [setDeletedFile, setValue, trigger, watchImageId],
  );

  const handleUploaded = useCallback(
    file => {
      setValue('form.mainImage.src', file.src);
    },
    [setValue],
  );

  const handleUpload = useCallback(
    async acceptedFiles => {
      await handleUploadEnd({
        acceptedFiles,
        handleUploaded,
        maxAmountOfFiles: 1,
      });
      trigger('form.mainImage.src');
    },
    [handleUploaded, trigger],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.indexPage.generalInformation}
      listOfFields={['title', 'text', 'mainImage']}
      errors={errors}
      initialIsOpen
    >
      <TrainingFormLine>
        <FieldStyle2 legend={trainings.indexPage.pageTitle}>
          <InputStyled
            name="form.title"
            width="790px"
            maxLength={TITLE_MAX_LENGTH}
            ref={register({
              maxLength: TITLE_MAX_LENGTH,
              validate: value =>
                !!value?.trim() || form.notification.theFieldIsRequired,
            })}
            defaultValue={trainingIndexLocalized.title}
          />
        </FieldStyle2>
        <Error message={getIsError('title')} />
        <T8y color="gray" fontSize="12px">
          {formatString(trainings.remainingCharacters, titleRemainingSymbols)}
        </T8y>
      </TrainingFormLine>
      <TrainingFormLine>
        <FieldStyle2 legend={trainings.indexPage.introductoryText}>
          <TextareaStyled
            name="form.text"
            width="790px"
            rows="4"
            maxLength={TEXT_MAX_LENGTH}
            ref={register({
              maxLength: TEXT_MAX_LENGTH,
              validate: value =>
                !!value?.trim() || form.notification.theFieldIsRequired,
            })}
            defaultValue={trainingIndexLocalized.description}
          />
        </FieldStyle2>
        <Error message={getIsError('text')} />
        <T8y color="gray" fontSize="12px">
          {formatString(trainings.remainingCharacters, textRemainingSymbols)}
        </T8y>
      </TrainingFormLine>
      <T8y fontSize="14px" color="gray" marginBottom="10px">
        {`${trainings.heroImage} (${formatString(
          trainings.heroImageOrientation,
          1680,
          580,
        )})`}
      </T8y>
      <ST.MainImageWrapper>
        {values.form?.mainImage?.src ? (
          <TrainingHeroImageWrapper
            src={values.form.mainImage.src}
            handleDeleteMainImage={handleDeleteMainImage}
          />
        ) : (
          <UploadTrainingPicture handleUploadEnd={handleUpload} />
        )}
      </ST.MainImageWrapper>
      <Error message={errors?.form?.mainImage?.src?.message} />
    </GeneralExpandableSection>
  );
};

export default TrainingIndexFormGeneralInformation;
