import React from 'react';
import { Lang } from 'lang';

import CustomButton from '../../atoms/CustomButton';
import ReportForm from '../../molecules/ReportForm';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

export const CreateReport = ({ dialog }) => {
  const {
    form: { buttons },
  } = Lang();

  const disclosure = (
    <CustomButton
      text={buttons.addNewReport}
      backgroundColor="primary"
      color="positive"
      border="1px solid #f89739"
      colorHovered="gray"
    />
  );

  return (
    <Modal
      disclosure={disclosure}
      title={buttons.addNewReport}
      dialogState={dialog}
      width={620}
      backgroundColor={colors.primary}
      lazyRender
    >
      <ReportForm onSuccess={() => dialog.hide()} />
    </Modal>
  );
};
