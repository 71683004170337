import styled from 'styled-components';

import { Row } from '../Grid/Row';

export const DropZoneBlockWrapper = styled(Row)`
  background: #ffffff;
  border: 1px dashed #dddddd;
  width: 100%;
  height: 100%;

  &:hover {
    border-color: #f89739;
    path {
      fill: #f89739;
    }
  }

  ${props =>
    props.$isDragActive &&
    '& > svg path {fill: #f89739;} border-color: #f89739;'};
`;
