import TrainingFormLine from 'components/atoms/TrainingFormLine';
import styled from 'styled-components';

import { T8y } from 'ui';

export const LocationsWrapper = styled(TrainingFormLine)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const VatWrapper = styled(T8y)`
  margin-left: 5px;
`;
