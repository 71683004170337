import { useCallback, useMemo, useState } from 'react';
import { useOnClickOutside } from 'hooks';

export const useManageHitsListVisibility = ({ inputRef, listRef }) => {
  const [isListVisible, setIsListVisible] = useState(false);

  const offsetFromTop = 100;

  useOnClickOutside(listRef, () => {
    setIsListVisible(false);
  });

  const scrollToList = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      top:
        inputRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        offsetFromTop -
        100,
    });
  }, [inputRef, offsetFromTop]);

  const makeListVisible = useCallback(() => {
    if (!isListVisible) {
      setIsListVisible(true);
      scrollToList();
    }
  }, [isListVisible, scrollToList]);

  return useMemo(
    () => ({
      makeListVisible,
      setIsListVisible,
      isListVisible,
    }),
    [makeListVisible, isListVisible, setIsListVisible],
  );
};
