import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Row } from 'ui';

export const SearchStyled = styled(Row)`
  height: 46px;
  width: 325px;
  padding-left: 20px;
  border: 1px solid ${colors.lightGray};
  border-radius: 3px;
  &:focus-within {
    border-color: ${colors.primary};
    svg {
      animation: pulsate-bck 0.7s ease-in-out infinite both;
    }
  }
  input {
    flex-grow: 1;
    height: 100%;
    line-height: 100%;
    padding: 0 15px;
    outline: none;
  }

  @keyframes pulsate-bck {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(12deg) scale(0.9);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;
