import React from 'react';
import { PATHS } from 'AppPaths';
import { useOrderProfile } from 'hooks';
import { Lang } from 'lang';

import PageHeaderWithBackLink from 'components/atoms/PageHeaderWithBackLink';
import LeadInfo from 'components/molecules/LeadInfo';
import OrderProfile from 'components/molecules/OrderProfile';

import * as ST from './styles';

import { Row } from 'ui';

import { ReactComponent as CallIcon } from 'assets/images/icon/callOrange.svg';

const LeadProfile = () => {
  const { leads, formatString } = Lang();

  const {
    lead,
    productsWithExtra,
    isColdLead,
    isQuote,
    isTraining,
    isCallback,
  } = useOrderProfile();

  if (!lead) {
    return null;
  }

  return (
    <>
      <PageHeaderWithBackLink
        link={PATHS.LEADS}
        linkText={leads.backToAllLeads}
        header={`${leads.types[lead.type] || lead.type} ${lead.created_at}`}
      />
      {isColdLead ? (
        <ST.LeadProfileWrapper>
          <ST.OrderProfileLeftSide>
            <LeadInfo lead={lead} isCallback={isCallback} />
          </ST.OrderProfileLeftSide>

          <ST.OrderProfileRightSide>
            {leads.leadInfoText[lead.type] && (
              <ST.LeadRightSideInfoBlock>
                <ST.LeadInfoFeature fontSize="16px">
                  {formatString(
                    leads.leadInfoText[lead.type],
                    lead.full_name || '',
                  )}
                </ST.LeadInfoFeature>
              </ST.LeadRightSideInfoBlock>
            )}
            <ST.LeadRightSideInfoBlock>
              <Row as="a" href={`tel:${lead.phone}`} align="center" noWrap>
                <CallIcon />
                <ST.LeadInfoFeature fontSize="16px" marginLeft="12px" bold>
                  {leads.callTo} {lead.full_name}
                </ST.LeadInfoFeature>
              </Row>
            </ST.LeadRightSideInfoBlock>
          </ST.OrderProfileRightSide>
        </ST.LeadProfileWrapper>
      ) : (
        <OrderProfile
          order={lead}
          products={productsWithExtra}
          isQuote={isQuote}
          isTraining={isTraining}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadProfile;
