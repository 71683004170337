import React, { useCallback, useEffect } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { Lang } from 'lang';

import * as ST from './styles';

import { Radio, T8y } from 'ui';

const CourseStatusSection = ({ handleChange, defaultValue = false }) => {
  const { trainings, form } = Lang();

  const radio = useRadioState({ state: defaultValue });

  useEffect(() => {
    handleChange(radio.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio.state]);

  useEffect(() => {
    radio.setState(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <ST.ShowSectionWrapper>
      <T8y color="gray" fontSize="14px" lineHeight="19px">
        {trainings.courseStatus}
      </T8y>
      <RadioGroup as={ST.RadioWrapper} {...radio}>
        <Radio
          id="radio-show-introductory"
          {...radio}
          text={form.active}
          value
        />
        <Radio
          id="radio-hide-introductory"
          {...radio}
          text={form.inactive}
          value={false}
        />
      </RadioGroup>
    </ST.ShowSectionWrapper>
  );
};

export default CourseStatusSection;
