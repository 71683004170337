export class NEWPaths {
  static ROOT = '/';
  _PRODUCTS = 'products';
  _MARKETING = 'marketing';
  _ANALYTICS = 'analytics';
  _LEADS = 'leads';
  _CHARTS = 'charts';
  _REPORTS = 'reports';
  _MANAGE = 'manage';
  _LOGIN = 'login';
  _USERS = 'users';
  _SETTINGS = 'settings';
  _TRANSPORTATION = 'transportation';
  _REGIONAL_SETTINGS = 'regional-settings';
  _TRAININGS = 'trainings';
  _ADDITIONAL_CHARGES = 'additional-charges';
  _HERO_IMAGES = 'hero-images';
  _POPULAR_PRODUCTS = 'popular-products';
  _USER_PROFILE = 'user-profile';
  _ORDERS = 'orders';
  _ENQUIRY = 'enquiry-form';
  _OFFERS = 'offers';
  _POPULAR_CATEGORIES = 'popular-categories';
  _PROMO_CODES = 'promo-codes';
  _DATES_SETTINGS = 'dates';
  _DEPOTS = 'depots';
  _JOB_TYPES = 'job-types';
  _TA_FAQS = 'faqs';
  _TRADE_ACCOUNTS = 'trade-accounts';
  _COMPANIES = 'companies';
  _PRIVATE = 'private';

  static setROOT(region) {
    this.ROOT = `/${region}`;
  }

  get PRODUCTS() {
    return `${this.constructor.ROOT}/${this._PRODUCTS}`;
  }

  get MARKETING() {
    return `${this.constructor.ROOT}/${this._MARKETING}`;
  }
  get HERO_IMAGES() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._HERO_IMAGES}`;
  }
  get POPULAR_PRODUCTS() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._POPULAR_PRODUCTS}`;
  }
  get ENQUIRY() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._ENQUIRY}`;
  }
  get POPULAR_CATEGORIES() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._POPULAR_CATEGORIES}`;
  }
  get JOB_TYPES() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._JOB_TYPES}`;
  }
  get SEE_JOB_TYPE() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._JOB_TYPES}/:id`;
  }
  JOB_TYPE(id) {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._JOB_TYPES}/${id}`;
  }
  get TA_FAQS() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._TA_FAQS}`;
  }
  get PROMO_CODES() {
    return `${this.constructor.ROOT}/${this._MARKETING}/${this._PROMO_CODES}`;
  }
  get ANALYTICS() {
    return `${this.constructor.ROOT}/${this._ANALYTICS}`;
  }
  get LEADS() {
    return `${this.constructor.ROOT}/${this._LEADS}`;
  }
  get OFFERS() {
    return `${this.constructor.ROOT}/${this._OFFERS}`;
  }
  SEE_OFFER() {
    return `${this.constructor.ROOT}/${this._OFFERS}/:id`;
  }
  OFFER(id) {
    return `${this.constructor.ROOT}/${this._OFFERS}/${id}`;
  }
  CREATE_OFFER() {
    return `${this.constructor.ROOT}/${this._OFFERS}/new`;
  }
  get LEAD_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._LEADS}/:orderId`;
  }
  LEAD(id, type) {
    return `${this.constructor.ROOT}/${this._LEADS}/${id}/?type=${type}`;
  }
  get SETTINGS() {
    return `${this.constructor.ROOT}/${this._SETTINGS}`;
  }
  get TRANSPORTATION() {
    return `${this.constructor.ROOT}/${this._SETTINGS}/${this._TRANSPORTATION}`;
  }
  get ADDITIONAL_CHARGES() {
    return `${this.constructor.ROOT}/${this._SETTINGS}/${this._ADDITIONAL_CHARGES}`;
  }
  get REGIONAL_SETTINGS() {
    return `${this.constructor.ROOT}/${this._SETTINGS}/${this._REGIONAL_SETTINGS}`;
  }
  get DATES_SETTINGS() {
    return `${this.constructor.ROOT}/${this._SETTINGS}/${this._DATES_SETTINGS}`;
  }
  get DEPOTS() {
    return `${this.constructor.ROOT}/${this._SETTINGS}/${this._DEPOTS}`;
  }
  get TRAININGS() {
    return `${this.constructor.ROOT}/${this._TRAININGS}`;
  }
  get TRAININGS_INDEX() {
    return `${this.constructor.ROOT}/${this._TRAININGS}/index`;
  }
  TRAINING(id) {
    return `${this.constructor.ROOT}/${this._TRAININGS}/${id}`;
  }
  EDIT_TRAININGS() {
    return `${this.constructor.ROOT}/${this._TRAININGS}/:id`;
  }
  get USERS() {
    return `${this.constructor.ROOT}/${this._USERS}`;
  }
  get USER() {
    return `${this.constructor.ROOT}/${this._USERS}/:id`;
  }
  get USER_PROFILE_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._USERS}/:id/${this._USER_PROFILE}`;
  }
  get USER_ORDERS_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._USERS}/:id/${this._ORDERS}`;
  }
  get ORDER_PROFILE_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._USERS}/:id/${this._ORDERS}/:orderId`;
  }
  USER_PROFILE_NEW() {
    return `${this.constructor.ROOT}/${this._USERS}/new/${this._USER_PROFILE}`;
  }
  USER_PROFILE(id) {
    return `${this.constructor.ROOT}/${this._USERS}/${id}/${this._USER_PROFILE}`;
  }
  USER_ORDERS(id) {
    return `${this.constructor.ROOT}/${this._USERS}/${id}/${this._ORDERS}`;
  }
  ORDER_PROFILE(id, orderId, type) {
    return `${this.constructor.ROOT}/${this._USERS}/${id}/${this._ORDERS}/${orderId}/?type=${type}`;
  }
  get CHARTS() {
    return `${this.constructor.ROOT}/${this._ANALYTICS}/${this._CHARTS}`;
  }
  get REPORTS() {
    return `${this.constructor.ROOT}/${this._ANALYTICS}/${this._REPORTS}`;
  }

  get TRADE_ACCOUNTS_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/:type`;
  }
  TRADE_ACCOUNTS(type) {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/${type}`;
  }

  get TRADE_ACCOUNT_PROFILE_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/:type/:id/${this._USER_PROFILE}`;
  }

  get TRADE_ACCOUNT_PRIVATE_PROFILE_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/${this._PRIVATE}/:id/${this._USER_PROFILE}`;
  }
  get TRADE_ACCOUNT_COMPANY_PROFILE_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/${this._COMPANIES}/:id/${this._USER_PROFILE}`;
  }

  TRADE_ACCOUNT_PROFILE(type, id) {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/${type}/${id}/${this._USER_PROFILE}`;
  }

  get TRADE_ACCOUNT_ORDERS_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/:type/:id/${this._ORDERS}`;
  }
  TRADE_ACCOUNT_ORDERS(type, id) {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/${type}/${id}/${this._ORDERS}`;
  }

  get TRADE_ACCOUNT_ORDER_PROFILE_TEMPLATE() {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/:type/:id/${this._ORDERS}/:orderId`;
  }
  TRADE_ACCOUNT_ORDER_PROFILE(type, id, orderId, orderType) {
    return `${this.constructor.ROOT}/${this._TRADE_ACCOUNTS}/${type}/${id}/${this._ORDERS}/${orderId}/?type=${orderType}`;
  }

  get CREATE_PRODUCT() {
    return `${this.constructor.ROOT}/${this._PRODUCTS}/new`;
  }

  EDIT_PRODUCT() {
    return `${this.constructor.ROOT}/${this._PRODUCTS}/:id`;
  }

  PRODUCT(id) {
    return `${this.constructor.ROOT}/${this._PRODUCTS}/${id}`;
  }

  get MANAGE() {
    return `${this.constructor.ROOT}/${this._MANAGE}`;
  }

  CREATE_CATEGORY() {
    return `${this.constructor.ROOT}/${this._MANAGE}/new`;
  }

  EDIT_CATEGORY() {
    return `${this.constructor.ROOT}/${this._MANAGE}/:id`;
  }

  CATEGORY(id) {
    return `${this.constructor.ROOT}/${this._MANAGE}/${id}`;
  }

  get LOGIN() {
    return `/${this._LOGIN}`;
  }
  get FORGOT_PASSWORD() {
    return '/forgot-password/';
  }
  get RESET_PASSWORD() {
    return `/password-reset/:email?/:token?/:site?`;
  }
}

export const PATHS = new NEWPaths();
