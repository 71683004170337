import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { user$ } from 'models/users';

import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';

import UserOrderSmall from './UserOrderSmall';

const UserOrdersSmall = () => {
  const { orders: ordersLocalization } = Lang();
  const user = useStore(user$);

  const orders = useMemo(() => user.orders, [user]);

  return (
    <ProductEditorBlockWrapper
      blockTitle={ordersLocalization.title}
      isNoBackground
    >
      {orders.map(order => (
        <UserOrderSmall order={order} key={order.orderHash} />
      ))}
    </ProductEditorBlockWrapper>
  );
};

export default UserOrdersSmall;
