import { useCallback, useEffect } from 'react';

export function useKeyUpEsc(handler) {
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.body.style.overflow = 'hidden';
    document.body.style.marginRight =
      document.body.scrollHeight !== window.innerHeight ? '8px' : '0';

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.body.style.overflow = 'unset';
      document.body.style.marginRight = '0';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyUp = useCallback(e => {
    const keys = {
      27: () => {
        e.preventDefault();
        handler();
        window.removeEventListener('keyup', handleKeyUp, false);
      },
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
