import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  display: flex;
  width: 350px;
`;

export const Line = styled.div`
  max-width: 210px;
  margin-right: 30px;
`;

export const DeleteIconWrapper = styled.div`
  margin-top: 10px;
`;
