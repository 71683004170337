import React, { Fragment, useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';

import { ProductReviewForm } from 'components/molecules/ProductReviewForm';
import styled from 'styled-components';

import { Full, More } from './shared';

import { colors } from 'styleGuide';
import { Grid, Row } from 'ui';

const ReviewsTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-top: 1rem;
  margin-bottom: 2.2rem;
`;

export const Reviews = ({
  watch,
  trigger,
  control,
  register,
  setValue,
  values,
  errors,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reviews',
  });

  const addMore = useCallback(() => {
    append({});
  }, [append]);

  const {
    product: { titles },
  } = Lang();

  return (
    <Fragment>
      <ReviewsTitle>{titles.reviews}</ReviewsTitle>

      <Grid as={Full} marginBottom gaps="2rem 2rem" cols="repeat(3, 1fr)">
        {fields.map((review, index) => (
          <ProductReviewForm
            key={review.id}
            review={review}
            trigger={trigger}
            values={values}
            register={register}
            watch={watch}
            setValue={setValue}
            errors={errors}
            index={index}
            remove={remove}
          />
        ))}
      </Grid>

      <Row justify="center">
        <More onClick={addMore} />
      </Row>
    </Fragment>
  );
};
