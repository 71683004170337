import React, { useCallback, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { settings$ } from 'models/settings';

import Error from 'components/atoms/Error';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import * as ST from './styles';

import { Checkbox, inputStyle, Row, Select, T8y } from 'ui';

import { ReactComponent as NotAvailableIcon } from 'assets/icons/closeGray.svg';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const CalendarGeneral = ({
  errors,
  register,
  setValue,
  values,
  setError,
  clearErrors,
}) => {
  const {
    form,
    common: { calendar },
  } = Lang();

  const settings = useStore(settings$);

  const checkIfTimeValuePresent = useCallback(() => {
    if (values.isSameDayHire && !values.sameDayMaxHireTime) {
      setError('sameDayMaxHireTime', {
        message: form.notification.theFieldIsRequired,
      });
    } else {
      clearErrors('sameDayMaxHireTime');
    }
  }, [
    clearErrors,
    form.notification.theFieldIsRequired,
    setError,
    values.isSameDayHire,
    values.sameDayMaxHireTime,
  ]);

  useEffect(() => {
    checkIfTimeValuePresent();
  }, [checkIfTimeValuePresent]);

  return (
    <GeneralExpandableSection
      index={0}
      header={calendar.general}
      listOfFields={['defaultHiringPeriod']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      <ST.DatesBlock>
        <FieldStyle
          as={FieldStyle2}
          legend={calendar.timezone}
          withBorder
          width="480px"
        >
          <Select
            options={[]}
            selected={null}
            defaultText={settings.regionTimezone}
            className={inputStyle}
            isSelectDisable
          />
        </FieldStyle>
      </ST.DatesBlock>
      <ST.DatesBlock>
        <FieldStyle2 legend={calendar.defaultHirePeriod}>
          <InputStyled
            name="defaultHiringPeriod"
            width="120px"
            ref={register({
              validate: value =>
                !!value?.trim() || form.notification.theFieldIsRequired,
            })}
            defaultValue={1}
          />
        </FieldStyle2>
        <Error message={get(errors, ['defaultHiringPeriod', 'message'])} />
      </ST.DatesBlock>
      <ST.DatesBlock>
        <T8y color="gray" marginBottom="10px" fontSize="14px">
          {calendar.sameDayHire}
        </T8y>
        <FieldStyle>
          <Row>
            <Checkbox
              onChange={() => setValue('isSameDayHire', !values.isSameDayHire)}
              label={calendar.allowSetToday}
              checked={values.isSameDayHire}
            />
            <ST.TillWrapper>{calendar.till}</ST.TillWrapper>
            <Row direction="column">
              <ST.CustomTimePicker
                format="HH:mm"
                required={values.isSameDayHire}
                onChange={val => {
                  setValue('sameDayMaxHireTime', val);
                  clearErrors('sameDayMaxHireTime');
                }}
                name="timeInput"
                value={values.sameDayMaxHireTime}
                disabled={!values.isSameDayHire}
                disableClock
                clearIcon={<NotAvailableIcon />}
                locale={settings?.defaultLanguage}
                maxTime="23:59"
                minTime="00:01"
                onKeyUp={() => {
                  if (
                    document.querySelector(
                      '.react-time-picker__inputGroup__input:invalid',
                    )
                  ) {
                    setError('sameDayMaxHireTime', {
                      message: 'Value should be between 00:01 and 23:59',
                    });
                  } else {
                    clearErrors('sameDayMaxHireTime');
                  }
                }}
                onBlur={checkIfTimeValuePresent}
              />
              <Error message={get(errors, ['sameDayMaxHireTime', 'message'])} />
            </Row>
          </Row>
        </FieldStyle>
      </ST.DatesBlock>
    </GeneralExpandableSection>
  );
};

export default CalendarGeneral;
