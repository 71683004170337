import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Lang } from 'lang';
import { get } from 'lodash';
import { isUrl } from 'utils/patterns';

import Error from 'components/atoms/Error';
import { Icon } from 'components/Product/Icon';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from 'components/Product/shared';
import { FILES } from 'api';

import * as ST from './styles';
import { CtaButtonInputsWrapper } from './styles';

import { Checkbox, DropZoneBlock, T8y } from 'ui';

const HomePageSliderForm = ({
  values,
  control,
  setValue,
  errors,
  trigger,
  watch,
}) => {
  const {
    marketing: { titles },
    form: { notification },
  } = Lang();

  const CTAWatcher = watch('cta');

  const handleUploadEnd = useCallback(async acceptedFiles => {
    const file = acceptedFiles && acceptedFiles[0];

    return new Promise(resolve => {
      const data = new FormData();
      data.append('file', file);
      FILES.upload(data).then(dataFromServer => {
        if (dataFromServer && dataFromServer.data) {
          resolve(dataFromServer.data);
        }
      });
    });
  }, []);

  const handleUploadMobileFiles = useCallback(
    async acceptedFiles => {
      const uploaded = await handleUploadEnd(acceptedFiles);
      setValue('srcMobile', uploaded.url || null);
      trigger('srcMobile');
    },
    [handleUploadEnd, setValue, trigger],
  );

  const handleUploadWebFiles = useCallback(
    async acceptedFiles => {
      const uploaded = await handleUploadEnd(acceptedFiles);
      setValue('srcWeb', uploaded.url || null);
      trigger('srcWeb');
    },
    [handleUploadEnd, setValue, trigger],
  );

  const handleRemoveItem = isWeb => {
    setValue(`file_id_${isWeb ? 'web' : 'mobile'}`, null);
    setValue(`src${isWeb ? 'Web' : 'Mobile'}`, null);
    trigger(`src${isWeb ? 'Web' : 'Mobile'}`);
  };

  // Note: need to use hidden inputs
  // https://spectrum.chat/react-hook-form/help/empty-fieldarray-values~f5eb5229-c261-4ba8-a476-fb0f2429b8a0
  return (
    <ST.ItemWrapper>
      <ST.hiddenBlock>
        <Controller
          as={<ST.hiddenInput />}
          name="file_id_mobile"
          control={control}
          defaultValue={values.file_id_mobile}
        />
        <Controller
          as={<ST.hiddenInput />}
          name="file_id_web"
          control={control}
          defaultValue={values.file_id_web}
        />
        <Controller
          as={<ST.hiddenInput />}
          name="srcWeb"
          control={control}
          defaultValue={values.srcWeb}
          rules={{ required: notification.theFieldIsRequired }}
        />
        <Controller
          as={<ST.hiddenInput />}
          name="srcMobile"
          control={control}
          defaultValue={values.srcMobile}
          rules={{ required: notification.theFieldIsRequired }}
        />
        <Controller
          as={<ST.hiddenInput />}
          name="cta"
          control={control}
          defaultValue={values.cta}
        />
        <Controller
          as={<ST.hiddenInput />}
          name="target_blank"
          control={control}
          defaultValue={values.target_blank}
          disabled
        />
      </ST.hiddenBlock>
      <ST.BlocksFieldsWrapper>
        <ST.ImageBlocksWrapper>
          <div>
            <T8y color="darkBlue" variant="t2" bold md2>
              {titles.desktopVersion}
            </T8y>
            <ST.ImageWrapper>
              {values.srcWeb ? (
                <>
                  <img src={values.srcWeb} alt="SlideWeb" />
                  <ST.DeleteImageIconWrapper>
                    <Icon
                      type="delete"
                      handleClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveItem(true);
                      }}
                    />
                  </ST.DeleteImageIconWrapper>
                </>
              ) : (
                <DropZoneBlock
                  handleUpload={handleUploadWebFiles}
                  multiple={false}
                />
              )}
            </ST.ImageWrapper>
            <ST.ImageErrorWrapper>
              <Error message={get(errors, ['srcWeb', 'message'])} />
            </ST.ImageErrorWrapper>
          </div>
          <div>
            <T8y color="darkBlue" variant="t2" bold md2>
              {titles.mobileVersion}
            </T8y>
            <ST.ImageWrapper>
              {values.srcMobile ? (
                <>
                  <img src={values.srcMobile} alt="SlideMobile" />
                  <ST.DeleteImageIconWrapper>
                    <Icon
                      type="delete"
                      handleClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveItem();
                      }}
                    />
                  </ST.DeleteImageIconWrapper>
                </>
              ) : (
                <DropZoneBlock
                  handleUpload={handleUploadMobileFiles}
                  multiple={false}
                />
              )}
            </ST.ImageWrapper>
            <ST.ImageErrorWrapper>
              <Error message={get(errors, ['srcMobile', 'message'])} />
            </ST.ImageErrorWrapper>
          </div>
        </ST.ImageBlocksWrapper>
        <ST.FieldWrapper>
          <FieldStyle as={FieldStyle2} legend={titles.h1Text}>
            <Controller
              as={<InputStyled />}
              name="h1Text"
              control={control}
              defaultValue={values.h1Text}
              rules={{
                validate: value =>
                  !!value?.trim() || notification.theFieldIsRequired,
              }}
            />
          </FieldStyle>
          <Error message={get(errors, ['h1Text', 'message'])} />
        </ST.FieldWrapper>
        <ST.FieldWrapper>
          <FieldStyle as={FieldStyle2} legend={titles.pText}>
            <Controller
              as={<TextareaStyled />}
              type="text"
              name="pText"
              control={control}
              defaultValue={values.pText}
              rules={{
                validate: value =>
                  !!value?.trim() || notification.theFieldIsRequired,
              }}
            />
          </FieldStyle>
          <Error message={get(errors, ['pText', 'message'])} />
        </ST.FieldWrapper>
        <ST.TextDataWrapper>
          <Checkbox
            label={titles.CTA}
            onChange={e => setValue('cta', e.target.checked)}
            checked={values.cta}
          />
          <ST.FieldsWrapper>
            <CtaButtonInputsWrapper>
              <ST.FieldWrapper>
                <FieldStyle as={FieldStyle2} legend={titles.buttonTitle}>
                  <Controller
                    as={<InputStyled disabled={!CTAWatcher} />}
                    name="btnText"
                    control={control}
                    defaultValue={values.btnText}
                    rules={
                      CTAWatcher && {
                        validate: value =>
                          !!value?.trim() || notification.theFieldIsRequired,
                      }
                    }
                  />
                </FieldStyle>
                <Error message={get(errors, ['btnText', 'message'])} />
              </ST.FieldWrapper>
              <ST.FieldWrapper>
                <FieldStyle as={FieldStyle2} legend={titles.buttonURL}>
                  <Controller
                    as={<InputStyled disabled={!CTAWatcher} />}
                    name="btnUrl"
                    control={control}
                    defaultValue={values.btnUrl}
                    rules={
                      CTAWatcher && {
                        required: notification.theFieldIsRequired,
                        validate: {
                          url: value =>
                            isUrl(value) || notification.valueShouldBeValid,
                        },
                      }
                    }
                  />
                </FieldStyle>
                <Error message={get(errors, ['btnUrl', 'message'])} />
              </ST.FieldWrapper>
              <Checkbox
                label={titles.targetBlank}
                onChange={e => setValue('target_blank', e.target.checked)}
                checked={values.target_blank}
                // isBlocked={!CTAWatcher}
                isBlocked
                disabled
              />
            </CtaButtonInputsWrapper>
          </ST.FieldsWrapper>
        </ST.TextDataWrapper>
      </ST.BlocksFieldsWrapper>
    </ST.ItemWrapper>
  );
};

export default HomePageSliderForm;
