import Error from 'components/atoms/Error';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import styled from 'styled-components';

export const ImagesWrapper = styled(TrainingFormLine)`
  display: grid;
  grid-template-columns: repeat(4, 220px);
  grid-gap: 20px;
`;

export const ImageItemWrapper = styled.div`
  width: 220px;
  height: 165px;
`;

export const EmptyFieldWithErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorStyled = styled(Error)`
  margin-top: 7px;
`;
