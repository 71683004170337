import React from 'react';
import { useStore } from 'effector-react';
import { userInfo } from 'models/user';

import { AvatarStyled } from './styles';

import { Cell } from 'ui';

const shortAccountName = user => {
  const { email } = user;

  if (!email) {
    return '';
  }

  return email.length > 2
    ? email.substr(0, 2).toUpperCase()
    : email.toUpperCase();
};

export const AccountDetails = () => {
  const user = useStore(userInfo);

  return (
    <Cell place="center end">
      <AvatarStyled marginLeft="50px" color="primary" bold>
        {shortAccountName(user)}
      </AvatarStyled>
    </Cell>
  );
};
