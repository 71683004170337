import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import * as ST from './styles';

const imageSizeLimit = 2097152;
const imageSizeBase10 = 2000000;
const imageSizeLimitInMB = 2;

const DropZone = ({
  handleUploadEnd,
  accept = 'image/*',
  multiple = true,
  disabled,
  children,
}) => {
  const { common, formatString } = Lang();

  const actualSizeLimit = useMemo(
    () =>
      /Macintosh|Linux/i.test(navigator.userAgent)
        ? imageSizeBase10
        : imageSizeLimit,
    [],
  );

  const maxSize = useMemo(() => {
    if (accept.includes('image')) {
      return actualSizeLimit;
    }
  }, [accept, actualSizeLimit]);

  const onDrop = useCallback(
    acceptedFiles => {
      handleUploadEnd(acceptedFiles);
    },
    [handleUploadEnd],
  );

  const onDropRejected = useCallback(
    notAcceptedFiles => {
      notAcceptedFiles.forEach(el => {
        const errorMsgLocalized = common.dropZoneErrors[el.errors[0].code];
        const errorMsg = errorMsgLocalized
          ? formatString(errorMsgLocalized, imageSizeLimitInMB)
          : el.errors[0].message;
        toast.error(() => (
          <div>
            {el.file.name}
            <br />
            {errorMsg}
          </div>
        ));
      });
    },
    [common, formatString],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    multiple,
    maxSize,
    disabled,
  });

  return (
    <ST.DropZoneStyle {...getRootProps()}>
      <input {...getInputProps()} />
      {children(isDragActive)}
    </ST.DropZoneStyle>
  );
};

export default DropZone;
