import styled from 'styled-components';

import { Grid, T8y } from 'ui';

export const SectionWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid #dddddd;
  border-radius: 5px;
`;

export const DepotName = styled(T8y)`
  position: absolute;
  left: 30px;
  top: -10px;
  background-color: #fff;
  padding: 0 10px;
`;

export const TableHeaderStyled = styled(Grid)`
  margin-bottom: 10px;
`;

export const InputLabel = styled(T8y)`
  font-size: 14px;
  &:not(:first-child) {
    margin-left: 40px;
    text-align: center;
  }
`;

export const RangeConfirmationModalControlsWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;

  &>button: first-child {
    margin-right: 10px;
  }
`;
