import React from 'react';
import { Lang } from 'lang';

import LeadInfoCRMLinks from 'components/atoms/LeadInfoCRMLinks';
import { OrderInfoBlock } from 'components/atoms/OrderInfoBlock/styles';
import { OrderInfoRow } from 'components/atoms/OrderInfoRow/styles';
import { OrderInfoWrapper } from 'components/atoms/OrderInfoWrapper/styles';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const LeadInfo = ({ lead, isCallback }) => {
  const { orders, leads, users } = Lang();

  return (
    <>
      <OrderInfoWrapper>
        <ST.LeadInfoHeader justify="stretch">
          <T8y color="darkBlue" fontSize="20px" bold>
            {leads.types[lead.type] || lead.type}
          </T8y>
        </ST.LeadInfoHeader>
        <OrderInfoRow>
          <OrderInfoBlock>
            <ST.LeadInfoFeature fontSize="16px">
              {orders.item.date}: {lead.created_at}
            </ST.LeadInfoFeature>
          </OrderInfoBlock>
        </OrderInfoRow>
        <OrderInfoRow>
          <OrderInfoBlock short withTopBorder>
            <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
              {users.info.contactInformation}
            </T8y>
            {lead.attributes &&
              Object.keys(lead.attributes).map((el, i) => {
                if (el.substring(0, 9) !== 'checkbox_') {
                  return (
                    <ST.LeadInfoFeature key={i} fontSize="16px">
                      {el}: {lead.attributes[el]?.toString()}
                    </ST.LeadInfoFeature>
                  );
                }
              })}
          </OrderInfoBlock>
          <OrderInfoBlock short withTopBorder>
            <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
              {leads.tableHeaders.leadSource}
            </T8y>
            <Row
              as="a"
              href={lead.page_url}
              target="_blank"
              rel="noopener noreferrer"
              align="center"
              noWrap
            >
              <T8y fontSize="16px" color="primary" marginRight="8px">
                {leads.viewSourcePage}
              </T8y>
              <ST.LinkIconStyled />
            </Row>
          </OrderInfoBlock>
        </OrderInfoRow>
        {lead.message && (
          <OrderInfoRow>
            <OrderInfoBlock withTopBorder>
              <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                {isCallback ? leads.comment : leads.message}
              </T8y>
              <ST.LeadInfoFeature fontSize="16px">
                {lead.message}
              </ST.LeadInfoFeature>
            </OrderInfoBlock>
          </OrderInfoRow>
        )}
      </OrderInfoWrapper>
      <LeadInfoCRMLinks lead={lead} />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadInfo;
