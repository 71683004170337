import React from 'react';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { jobTypesFilters$, setJobTypesSearchStr } from 'models/jobTypes';

import * as ST from './styles';

import { Icon, Row, T8y } from 'ui';

const JobTypesFilters = ({ jobTypesAvailable = 0, history }) => {
  const { marketing, orders, formatString } = Lang();

  const leadsFilters = useStore(jobTypesFilters$);

  return (
    <Row justify="stretch" fullWidth>
      <ST.FiltersWrapper align="center" noWrap>
        <ST.SearchWrapperStyled>
          <Icon as={ST.SearchIcon} />
          <ST.SearchInput
            type="text"
            placeholder={orders.startTypingToFilter}
            value={leadsFilters.search}
            onChange={e => setJobTypesSearchStr(e.target.value)}
          />
        </ST.SearchWrapperStyled>
        <T8y>
          {formatString(marketing.jobTypes.availableItems, jobTypesAvailable)}
        </T8y>
      </ST.FiltersWrapper>
      <Row align="center" noWrap>
        <ST.StyledAddBtn
          text={marketing.jobTypes.addNewJobType}
          backgroundColor="positive"
          width="auto"
          padding="0 10px"
          height="40px"
          color="negative"
          border="1px solid #F89739"
          colorHovered="primaryHovered"
          textColorHovered="positive"
          onClick={() => {
            history.push(PATHS.JOB_TYPE('new'));
          }}
        />
      </Row>
    </Row>
  );
};

export default withRouter(JobTypesFilters);
