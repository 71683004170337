import { analytics } from './pt/analytics';
import { categories } from './pt/categories';
import { common } from './pt/common';
import { form } from './pt/form';
import { header } from './pt/header';
import { leads } from './pt/leads';
import { marketing } from './pt/marketing';
import { orders } from './pt/orders';
import { product } from './pt/product';
import { promoCodes } from './pt/promoCodes';
import { settings } from './pt/settings';
import { tradeAccounts } from './pt/tradeAccounts';
import { trainings } from './pt/trainings';
import { users } from './pt/users';

export const pt = {
  header,
  product,
  marketing,
  form,
  categories,
  analytics,
  users,
  tradeAccounts,
  settings,
  trainings,
  common,
  orders,
  leads,
  promoCodes,
};
