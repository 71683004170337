import React, { useMemo } from 'react';
import { isNotNullUndefined } from 'utils/helpers/numbers';

import * as ST from './styles';

const NewTable = ({
  headers = [],
  keyFields = [],
  items = [],
  cols,
  isSortByActive,
  bgColor,
  isFixedLayout,
}) => {
  const itemsForRender = useMemo(
    () =>
      isSortByActive ? [...items].sort(el => (el.isActive ? -1 : 0)) : items,
    [isSortByActive, items],
  );

  return (
    <ST.CustomTable
      bgColor={bgColor}
      isFixedLayout={isFixedLayout}
      width="100%"
    >
      {cols}
      <thead>
        <ST.TableHeaders>
          {headers.map((el, index) => (
            <th key={index}>
              <ST.TableCell cellType={el.type}>
                {isNotNullUndefined(el.name) ? el.name : el}
              </ST.TableCell>
            </th>
          ))}
        </ST.TableHeaders>
      </thead>
      <tbody>
        {itemsForRender.map((el, index) => (
          <ST.TableRow key={index} isActive={el.isActive}>
            {keyFields.map((cell, index) => (
              <td key={index}>
                <ST.TableCell
                  {...cell}
                  title={cell.type === 'text' ? el[cell.name] : undefined}
                >
                  {el[cell.name] || '-'}
                </ST.TableCell>
              </td>
            ))}
          </ST.TableRow>
        ))}
      </tbody>
    </ST.CustomTable>
  );
};

export default NewTable;
