import styled from 'styled-components';

import { T8y } from 'ui';

export const PriceKmWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeliveryMileTitles = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > span:not(:last-child) {
    margin-right: 30px;
  }
`;

export const DepotLabel = styled(T8y)`
  width: 150px;
`;

export const InputLabel = styled(T8y)`
  width: 110px;
`;

export const CheckboxLabel = styled(T8y)`
  width: 90px;
`;
