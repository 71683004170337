import React, { useState } from 'react';
import { Lang } from 'lang';

import Error from 'components/atoms/Error';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import { FieldStyle2, InputStyled, TextareaStyled } from '../../Product/shared';
import * as ST from './styles';

import { Checkbox } from 'ui';

const JobTypeSeo = ({ errors, register, values, getIsError }) => {
  const { marketing, form, product, common } = Lang();

  const [isRobotsEditingEnabled, setRobotsEditingEnabled] = useState(false);

  return (
    <GeneralExpandableSection
      index={1}
      header={product.titles.seo}
      listOfFields={['seoTitle', 'metaDescription', 'seoH1', 'seoRobots']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      <FieldStyle2 legend={marketing.jobTypes.jobHTMLTitle}>
        <InputStyled
          name="seoTitle"
          width="700px"
          type="text"
          ref={register}
          defaultValue={values.seoTitle}
        />
      </FieldStyle2>
      <Error message={getIsError('seoTitle')} />
      <FieldStyle2 legend={form.fields.metaDescription} marginTop="10px">
        <TextareaStyled
          name="metaDescription"
          width="700px"
          type="text"
          ref={register}
          defaultValue={values.metaDescription}
        />
      </FieldStyle2>
      <Error message={getIsError('metaDescription')} />
      <FieldStyle2 legend={form.fields.h1Tag} marginTop="10px">
        <InputStyled
          name="seoH1"
          width="700px"
          type="text"
          ref={register}
          defaultValue={values.seoH1}
        />
      </FieldStyle2>
      <Error message={getIsError('seoH1')} />
      <ST.RobotsWrapper>
        <Checkbox
          label={common.enabledRobotsTag}
          ref={register}
          onChange={e => setRobotsEditingEnabled(e.target.checked)}
          checked={isRobotsEditingEnabled}
        />
        <FieldStyle2 legend={form.fields.robotsTag} marginTop="10px">
          <InputStyled
            name="seoRobots"
            type="text"
            width="700px"
            ref={register}
            defaultValue={values.seoRobots}
            asDisabled={!isRobotsEditingEnabled}
            readOnly={!isRobotsEditingEnabled}
          />
        </FieldStyle2>
        <Error message={getIsError('seoRobots')} />
      </ST.RobotsWrapper>
    </GeneralExpandableSection>
  );
};

export default JobTypeSeo;
