import React, { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { branding$ } from 'models/branding';
import { website$ } from 'models/website';
import { handleChangePriceField } from 'utils/helpers/numbers';

import AddMore from 'components/atoms/AddMore';
import Error from 'components/atoms/Error';
import PricingByRangeItem from 'components/atoms/PricingByRangeItem';
import { WeekendRatePrice } from 'components/atoms/WeekendRatePrice';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import {
  FieldStyle,
  FieldStyle2,
  InputNumberStyled,
  InputStyled,
} from 'components/Product/shared';

import * as ST from './styles';

import { inputStyle, Select } from 'ui';

const PricingByRange = ({
  control,
  register,
  values,
  setValue,
  errors,
  trigger,
  watch,
}) => {
  const {
    product: { titles },
    form,
  } = Lang();

  const branding = useStore(branding$);
  const website = useStore(website$);

  const isETH = website.shortCompanyName === 'ETH';

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices',
    keyName: 'formId',
  });

  const isPerWeekOptionAvailable = values.prices?.some(
    el => Number(el.period_to) === 7,
  );

  const ranges = useMemo(
    () => [
      {
        id: 1,
        slug: 'day',
        name: form.fields.perDay,
      },
      {
        id: 2,
        slug: 'week',
        name: form.fields.perWeek,
        disabled: !isPerWeekOptionAvailable,
      },
    ],
    [form, isPerWeekOptionAvailable],
  );

  const getSelectedRange = useCallback(
    slug => {
      const currRange = ranges.find(el => el.slug === slug);
      return currRange?.name || null;
    },
    [ranges],
  );

  const handleAdd = useCallback(() => {
    append({
      period_from: values.prices[values.prices.length - 1].period_to
        ? Number(values.prices[values.prices.length - 1].period_to) + 1
        : '',
      period_to: '',
      price: '',
    });
  }, [append, values.prices]);

  useEffect(
    () => {
      if (values.displayedRange) {
        trigger('displayedRange');
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.prices],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={titles.pricing}>
      <div>
        <FieldStyle
          as={FieldStyle2}
          legend={titles.defaultDisplayedRange}
          withBorder
          width="150px"
        >
          <InputStyled
            name="displayedRange"
            type="hidden"
            ref={register({
              required: form.notification.theFieldIsRequired,
              validate: () =>
                values.displayedRange !== 'week' ||
                isPerWeekOptionAvailable ||
                form.notification.availableOnly,
            })}
            defaultValue={values && values.displayedRange}
          />
          <Select
            options={ranges}
            selected={getSelectedRange(values.displayedRange)}
            onClickOption={value => {
              setValue('displayedRange', value);
              trigger('displayedRange');
            }}
            className={inputStyle}
          />
        </FieldStyle>
        <Error message={get(errors, ['displayedRange', 'message'])} />
      </div>
      <ST.FieldsWrapper noWrap>
        <ST.FieldWrapper>
          <FieldStyle as={FieldStyle2} legend={form.fields.minPeriod}>
            <InputNumberStyled
              name="min_period_days"
              type="number"
              width="60px"
              ref={register({
                required: form.notification.theFieldIsRequired,
                validate: value =>
                  value > 0 || form.notification.valueShouldBePositive,
              })}
            />
          </FieldStyle>
          <Error message={get(errors, ['min_period_days', 'message'])} />
        </ST.FieldWrapper>
        <ST.FieldWrapper>
          <FieldStyle as={FieldStyle2} legend={form.fields.outOfRangePrice}>
            <ST.EuroIcon>{branding?.currencySymbol}</ST.EuroIcon>
            <InputNumberStyled
              name="outOfRangeRatio"
              type="number"
              width="80px"
              paddingLeft="30px"
              ref={register({
                required: form.notification.theFieldIsRequired,
                validate: {
                  positive: value =>
                    value > 0 || form.notification.valueShouldBePositive,
                },
              })}
              onChange={handleChangePriceField}
              defaultValue={values.outOfRangeRatio || ''}
            />
          </FieldStyle>
          <Error message={get(errors, ['outOfRangeRatio', 'message'])} />
        </ST.FieldWrapper>
      </ST.FieldsWrapper>
      {isETH && (
        <WeekendRatePrice
          register={register}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          error={get(errors, ['weekendRatePrice', 'message'])}
        />
      )}

      <ST.TitlesWrapper>
        <span>{form.fields.rangeInDays}</span>
        <span>{form.fields.pricePerRange}</span>
      </ST.TitlesWrapper>
      {fields.map((item, index) => (
        <PricingByRangeItem
          key={item.formId}
          item={item}
          index={index}
          register={register}
          handleRemove={remove}
          errors={errors}
          watch={watch}
          setValue={setValue}
          trigger={trigger}
        />
      ))}
      <AddMore onClick={handleAdd} />
    </ProductEditorBlockWrapper>
  );
};

export default PricingByRange;
