import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { branding$ } from 'models/branding';
import { handleChangePriceField } from 'utils/helpers/numbers';

import Error from 'components/atoms/Error';
import { Icon } from 'components/Product/Icon';
import { InputNumberStyled } from 'components/Product/shared';

import * as ST from './styles';

import { Row } from 'ui';

const PricingByRangeItem = ({
  index,
  item,
  handleRemove,
  errors,
  register,
  watch,
  setValue,
  trigger,
}) => {
  const {
    form: { notification },
  } = Lang();

  const branding = useStore(branding$);

  const [isRangeErrorFrom, setIsRangeErrorFrom] = useState(false);
  const [isRangeErrorTo, setIsRangeErrorTo] = useState(false);

  const prices = watch('prices');

  const handlePeriodToChange = useCallback(
    e => {
      if (prices[index].period_from !== '') {
        trigger(`prices[${index}].period_from`);
      }

      if (e.target.value && prices[index + 1]) {
        setValue(
          `prices[${index + 1}].period_from`,
          Number(e.target.value) + 1,
        );

        trigger(`prices[${index + 1}].period_from`);
      }
    },
    [index, prices, setValue, trigger],
  );

  useEffect(
    () => {
      if (prices[index].period_to) {
        trigger(`prices[${index}].period_to`);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [prices[index + 1]?.period_from],
  );

  return (
    <ST.Wrapper>
      <ST.RangeInputsWrapper>
        <Row noWrap>
          <ST.SmallInputWrapper>
            <InputNumberStyled
              name={`prices[${index}].period_from`}
              type="number"
              width="60px"
              ref={register({
                required: notification.theFieldIsRequired,
                validate: {
                  firstShouldBeLessSecond: value => {
                    const isRangeErrorFrom =
                      prices[index].period_to !== '' &&
                      Number(value) > Number(prices[index].period_to);

                    setIsRangeErrorFrom(isRangeErrorFrom);

                    return (
                      !isRangeErrorFrom || notification.firstShouldBeLessSecond
                    );
                  },
                },
              })}
              defaultValue={item.period_from || ''}
              readOnly
            />
            {!isRangeErrorFrom && !isRangeErrorTo && (
              <Error
                message={get(errors, [
                  'prices',
                  index,
                  'period_from',
                  'message',
                ])}
              />
            )}
          </ST.SmallInputWrapper>
          <ST.DividerWrapper>
            <ST.DivideSymbol color="lightGray" align="center">
              &mdash;
            </ST.DivideSymbol>
          </ST.DividerWrapper>
          <ST.SmallInputWrapper>
            <InputNumberStyled
              name={`prices[${index}].period_to`}
              type="number"
              width="60px"
              ref={register({
                required: notification.theFieldIsRequired,
                validate: {
                  positive: value => {
                    return value > 0 || notification.valueShouldBePositive;
                  },
                  overlapRanges: value => {
                    if (prices[index + 1]) {
                      const isRangeOverlapOther =
                        Number(value) >= Number(prices[index + 1].period_from);

                      setIsRangeErrorTo(isRangeOverlapOther);

                      return (
                        !isRangeOverlapOther ||
                        notification.rangeShouldNotOverlapOthers
                      );
                    }
                  },
                },
              })}
              defaultValue={item.period_to || ''}
              onChange={handlePeriodToChange}
            />
            {!isRangeErrorFrom && !isRangeErrorTo && (
              <Error
                message={get(errors, ['prices', index, 'period_to', 'message'])}
              />
            )}
          </ST.SmallInputWrapper>
        </Row>
        {isRangeErrorTo && (
          <Error
            message={get(errors, ['prices', index, 'period_to', 'message'])}
          />
        )}
        {isRangeErrorFrom && !isRangeErrorTo && (
          <Error
            message={get(errors, ['prices', index, 'period_from', 'message'])}
          />
        )}
      </ST.RangeInputsWrapper>
      <ST.PriceInputWrapper>
        <ST.EuroIcon>{branding.currencySymbol}</ST.EuroIcon>
        <InputNumberStyled
          name={`prices[${index}].price`}
          type="number"
          step="0.01"
          paddingLeft="3rem"
          width="110px"
          ref={register({
            required: notification.theFieldIsRequired,
            validate: value => value > 0 || notification.valueShouldBePositive,
          })}
          onChange={handleChangePriceField}
          defaultValue={item.price || ''}
        />
        <Error message={get(errors, ['prices', index, 'price', 'message'])} />
      </ST.PriceInputWrapper>
      {index > 0 && index === prices.length - 1 && (
        <ST.IconWrapper>
          <Icon
            type="delete"
            color="gray"
            handleClick={() => {
              handleRemove(index);
            }}
          />
        </ST.IconWrapper>
      )}
    </ST.Wrapper>
  );
};

export default PricingByRangeItem;
