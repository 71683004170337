import React, { useCallback, useEffect, useMemo } from 'react';

import * as ST from './styles';

import { Checkbox } from 'ui';

const DeliveryMileCheckbox = ({
  fieldName,
  index,
  register,
  deliveryIndex,
  values,
  setValue,
  mileIndex,
}) => {
  const isChecked = useMemo(
    () =>
      values.deliveries[deliveryIndex]?.deliveryPerMile[mileIndex][fieldName],
    [deliveryIndex, fieldName, mileIndex, values.deliveries],
  );

  useEffect(() => {
    register({
      name: `deliveries[${deliveryIndex}].deliveryPerMile[${mileIndex}].${fieldName}`,
    });

    setValue(
      `deliveries[${deliveryIndex}].deliveryPerMile[${mileIndex}].${fieldName}`,
      values.deliveries[deliveryIndex]?.deliveryPerMile[mileIndex][fieldName],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckCheckbox = useCallback(
    e => {
      setValue(
        `deliveries[${deliveryIndex}].deliveryPerMile[${mileIndex}].${fieldName}`,
        e.target.checked,
      );
    },
    [deliveryIndex, fieldName, mileIndex, setValue],
  );

  return (
    <ST.RouteCheckboxWrapper>
      <Checkbox
        onChange={handleCheckCheckbox}
        checked={isChecked}
        disabled
        isBlocked
        key={index}
      />
    </ST.RouteCheckboxWrapper>
  );
};

export default DeliveryMileCheckbox;
