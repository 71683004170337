import React from 'react';

import CategoryPageSliderItem from 'components/molecules/CategoryPageSliderItem';

const CategoryBannerSlider = ({
  fields,
  values,
  register,
  control,
  errors,
  setValue,
  setError,
  trigger,
  watch,
}) => {
  return fields.map((item, index) => (
    <CategoryPageSliderItem
      register={register}
      item={item}
      control={control}
      values={values}
      errors={errors}
      setValue={setValue}
      setError={setError}
      trigger={trigger}
      key={item.id}
      watch={watch}
      index={index}
    />
  ));
};

export default CategoryBannerSlider;
