import styled from 'styled-components';

export const DateWrapper = styled.div`
  display: flex;
`;

export const PriceFieldWrapper = styled.div`
  width: 200px;
  margin-right: 20px;
`;

export const TitleField = styled.div`
  width: 260px;
  margin-right: 20px;
`;

export const DatePriceRemoveIconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 35px;
`;
