import styled from 'styled-components';

import { colors } from 'styleGuide';

export const PagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > div:not(last-child) {
    margin-right: 10px;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid ${props => (props.active ? colors.primary : '#dddddd')};
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.primary};
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${colors.primary};

  &:first-child {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
  }
`;
