import styled from 'styled-components';

import { Button } from 'ui';

export const Note = styled.span`
  display: block;
  font-size: 16px;
  margin-top: 24px;
`;

export const IdsWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const IdFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ConnectBtn = styled(Button)`
  min-width: 140px;
  height: 44px;
  margin-left: 10px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  color: #666666;

  &:hover {
    color: #666666;
    background: #fbfbfb;
  }
`;
