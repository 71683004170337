import styled from 'styled-components';

export const Form = styled.form`
  padding: 30px 30px 20px;
  border-radius: 5px;
  background-color: rgb(249, 249, 249);
`;

export const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
  column-gap: 1rem;
`;

export const PageTitle = styled.p`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormTitleWrapper = styled.div`
  margin-bottom: 30px;
  color: #424242;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 4rem;
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

export const SentContainer = styled.div`
  min-height: 50px;
  text-align: center;
}`;
