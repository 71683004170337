import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import styled from 'styled-components';

export const PhoneCountryInput = styled(PhoneInputWithCountry)`
  display: flex;
  align-items: center;
  align-self: stretch;
  width: calc(100% + 2px);
  margin: -1px;
  color: #757575;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: left;
  text-indent: 3rem;
  padding-left: 15px;

  select:disabled {
    visibility: hidden;
  }
`;
