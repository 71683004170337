import React, { useMemo } from 'react';
import { useDialogState } from 'reakit';
import { Lang } from 'lang';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, Row, T8y } from 'ui';

const ConfirmCategoriesDeletionModal = ({
  categoriesCount,
  onSubmitDelete,
  selectedCategoriesNames,
}) => {
  const { categories } = Lang();

  const dialog = useDialogState();

  const disclosure = useMemo(
    () => (
      <ST.DeleteButton type="button" disabled>
        {categories.titles.deleteSelected}
      </ST.DeleteButton>
    ),
    [categories.titles.deleteSelected],
  );

  return (
    <Modal
      disclosure={disclosure}
      title={categories.titles.deletionConfirmation}
      dialogState={dialog}
      width="450px"
      overflow="hidden"
      backgroundColor={colors.primary}
      lazyRender
    >
      <T8y>{categories.areYouSureYouWantToDeleteCategories}</T8y>
      <ST.ListCategories marginTop="10px" marginBottom="20px" fontSize="16px">
        <T8y fontSize="15px" bold>
          {selectedCategoriesNames}
          <T8y as="span">?</T8y>
        </T8y>
      </ST.ListCategories>
      <Row justify="center">
        <ST.ModalButton onClick={dialog.hide}>
          {categories.buttons.cancel}
        </ST.ModalButton>
        <ST.ModalButton
          onClick={() => {
            onSubmitDelete();
            dialog.hide();
          }}
        >
          {categories.buttons.confirm}
        </ST.ModalButton>
      </Row>
    </Modal>
  );
};

export default ConfirmCategoriesDeletionModal;
