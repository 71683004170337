import styled from 'styled-components';

import { Checkbox } from 'ui';

export const AreaCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

export const DeliveryAreaFieldsWrapper = styled.div`
  width: 150px;
  display: flex;

  &:not(:last-child) {
    margin-right: 40px;
  }
`;
