export const capitalLetter = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word && word[0].toUpperCase() + word.substr(1))
    .join(' ');
};

export const clearStrFromText = str => {
  const newStr = str.replace(/<[^>]*>?/gm, '');

  return newStr.replace('&nbsp;', '');
};

export const strToUpper = title => {
  title = title.replace('/ ', '');

  const splitWords = title.toLowerCase().split(' ');

  const splitWordsUpper = splitWords.map(
    el => el.charAt(0).toUpperCase() + el.substring(1),
  );

  return splitWordsUpper.join(' ');
};

export const capitalizeFirstLetter = string => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
