import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import CategoryItem from 'components/molecules/CategoryItem';

const SubCategories = ({
  subCategory,
  checkbox,
  isLoading,
  index,
  snapshot,
  provided,
  getItemStyle,
  getListStyle,
  level,
}) => {
  return (
    <Draggable
      key={subCategory.id.toString()}
      draggableId={subCategory.id.toString()}
      index={index}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
      isDragDisabled
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CategoryItem
            category={subCategory}
            checkbox={checkbox}
            isLoading={isLoading}
            level={level}
          />
          {subCategory.childs?.data && (
            <Droppable
              droppableId={`${subCategory.id}`}
              type="SubSubCategories"
              isDropDisabled={isLoading}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {subCategory.childs.data.map((el, index) => (
                    <SubCategories
                      key={el.id}
                      subCategory={el}
                      checkbox={checkbox}
                      index={index}
                      isLoading={isLoading}
                      provided={provided}
                      snapshot={snapshot}
                      getItemStyle={getItemStyle}
                      getListStyle={getListStyle}
                      level={3}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

export default SubCategories;
