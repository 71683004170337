import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { areas$ } from 'models/areas';
import {
  deleteDeliveryFx,
  filteredDeliveries$,
  updateAllDeliveriesFx,
} from 'models/deliveries';
import { settings$ } from 'models/settings';
import { warehouses$ } from 'models/warehouses';

import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import { SameDayDeliveryCheckbox } from 'components/molecules/SameDayDeliveryCheckbox';
import TransportationDelivery from 'components/molecules/TransportationDelivery';

import * as ST from './styles';

const TransportationDeliveriesForm = () => {
  const { form } = Lang();

  const settings = useStore(settings$);
  const filteredDeliveries = useStore(filteredDeliveries$);
  const areas = useStore(areas$);
  const warehouses = useStore(warehouses$);

  const [
    deliveryRelatedProductsList,
    setDeliveryRelatedProductsList,
  ] = useState(null);

  const {
    handleSubmit,
    getValues,
    errors,
    register,
    watch,
    control,
    reset,
    setValue,
    useWatch,
    trigger,
  } = useForm({
    defaultValues: {
      deliveries: [
        {
          name: null,
          capacity: null,
          deliveryPerArea: [],
          deliveryPerMile: [],
          deliveryPerPostcode: [],
        },
      ],
    },
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'deliveries',
  });

  const deliveryMethod = useMemo(() => {
    return settings?.defaultDeliveryMethodType;
  }, [settings]);

  const handleAdd = useCallback(() => {
    const possibleDeliveriesMethods = {
      deliveryPerArea: areas.map(el => ({
        areaId: el.id,
        depotToLocationEnabled: false,
        depotToLocation: 100,
        locationToDepotEnabled: false,
        locationToDepot: 100,
        bothWaysDeliveryEnabled: true,
        bothWaysDelivery: 100,
      })),
      deliveryPerMile: warehouses.map(el => ({
        depotToLocationEnabled: true,
        locationToDepotEnabled: false,
        bothWaysDeliveryEnabled: false,
        startPrice: null,
        minimalPrice: 0,
        pricePerMile: null,
        warehouseId: el.id,
      })),
      deliveryByRadius: warehouses.map(el => ({
        title: el.name,
        warehouseId: el.id,
        outOfRangeNotAvailable: false,
        ranges: [
          {
            distanceFrom: 0,
            distanceTo: null,
            depotToLocationEnabled: true,
            locationToDepotEnabled: true,
            bothWaysDeliveryEnabled: true,
          },
        ],
      })),
    };

    append({
      name: null,
      capacity: null,
      [deliveryMethod]: possibleDeliveriesMethods[deliveryMethod],
    });
  }, [append, areas, deliveryMethod, warehouses]);

  const handleRemove = useCallback(
    index => {
      remove(index);
    },
    [remove],
  );

  const handleRemoveExistedDelivery = useCallback(
    async id => {
      try {
        await deleteDeliveryFx(id);
        return false;
      } catch (err) {
        let isAttachedToProducts = false;

        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message ===
            'Delivery method has been related to some products'
        ) {
          isAttachedToProducts = true;
        } else {
          toast.error(form.toastTitle.deleteError);
        }
        setDeliveryRelatedProductsList(err.response.data.products);
        return isAttachedToProducts;
      }
    },
    [form.toastTitle.deleteError],
  );

  const onSubmit = useCallback(async () => {
    if (!deliveryMethod) {
      return;
    }

    const actualData = values.deliveries.length
      ? values.deliveries.map(el => {
          if (deliveryMethod === 'deliveryPerMile') {
            el[deliveryMethod].map(item => {
              if (item.startPrice === undefined) {
                item.startPrice = null;
              }

              if (item.pricePerMile === undefined) {
                item.pricePerMile = null;
              }

              return item;
            });
          }

          if (deliveryMethod === 'postcode') {
            return {
              id: el.serverId,
              name: el.name,
              capacity: el.capacity,
              deliveryPerPostcode: el.deliveryPerPostcode,
            };
          }

          if (deliveryMethod === 'deliveryByRadius') {
            return {
              id: el.serverId,
              name: el.name,
              capacity: el.capacity,
              outOfRangeNotAvailable: el.outOfRangeNotAvailable,
              deliveryByRadius: el.deliveryByRadius?.reduce((acc, item) => {
                acc = acc.concat(
                  item.ranges.map(el => ({
                    ...el,
                    warehouseId: item.warehouseId,
                  })),
                );
                return acc;
              }, []),
            };
          }

          return {
            id: el.serverId,
            name: el.name,
            capacity: el.capacity,
            [deliveryMethod]: el[deliveryMethod],
          };
        })
      : [];

    try {
      await updateAllDeliveriesFx(actualData);
      toast.success(form.toastTitle.successfullyUpdated);
    } catch (e) {
      toast.error(form.toastTitle.updateError);
    }
  }, [values.deliveries, deliveryMethod, form]);

  const formattedDeliveries = useMemo(
    () =>
      filteredDeliveries.map(deliveryItem => {
        if ('deliveryByRadius' in deliveryItem) {
          return {
            ...deliveryItem,
            deliveryByRadius: warehouses.map(warehouse => {
              const deliveryByRadiusItemRanges = deliveryItem.deliveryByRadius.filter(
                item => item.warehouseId === warehouse.id,
              );
              return {
                ranges: deliveryByRadiusItemRanges.length
                  ? deliveryByRadiusItemRanges
                  : [
                      {
                        distanceFrom: 0,
                        distanceTo: null,
                        depotToLocationEnabled: true,
                        locationToDepotEnabled: true,
                        bothWaysDeliveryEnabled: true,
                      },
                    ],
                title: warehouse.name,
                warehouseId: warehouse.id,
              };
            }),
          };
        }
        return deliveryItem;
      }),
    [filteredDeliveries, warehouses],
  );

  const handleReset = useCallback(() => {
    reset({
      deliveries: JSON.parse(JSON.stringify(formattedDeliveries)),
    });
  }, [formattedDeliveries, reset]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedDeliveries]);

  return (
    <>
      <ST.SameDayDeliveryCheckboxWrapper>
        <SameDayDeliveryCheckbox />
      </ST.SameDayDeliveryCheckboxWrapper>
      {fields.map((item, index) => (
        <TransportationDelivery
          index={index}
          deliveryItem={item}
          values={values}
          handleRemove={handleRemove}
          register={register}
          control={control}
          errors={errors}
          key={item.id}
          setValue={setValue}
          watch={watch}
          useWatch={useWatch}
          handleRemoveExistedDelivery={handleRemoveExistedDelivery}
          trigger={trigger}
          deliveryRelatedProductsList={deliveryRelatedProductsList}
          setDeliveryRelatedProductsList={setDeliveryRelatedProductsList}
        />
      ))}
      <AddNewAndSaveCancelFooter
        handleAdd={handleAdd}
        onSubmit={onSubmit}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        text={form.buttons.addNewDeliveryType}
        isDisabled={settings?.defaultDeliveryMethodType === 'postcode'}
        isAddBtn
      />
    </>
  );
};

export default TransportationDeliveriesForm;
