import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Lang } from 'lang';
import { get } from 'lodash';

import AddMore from 'components/atoms/AddMore';
import Error from 'components/atoms/Error';
import styled from 'styled-components';

import { Icon } from './Icon';
import {
  FieldStyle,
  FieldStyle2,
  Full,
  InputStyled,
  TextareaStyled,
} from './shared';

import { colors } from 'styleGuide';
import { Cell, Grid } from 'ui';

const SpecificationsTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

const SpecificationsBody = styled.div`
  margin-top: 2rem;
  border-radius: 0.2rem;
  margin-bottom: 5rem;
`;

const SpecificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 2.5rem 3rem 3rem;
  border-radius: 0.2rem;
`;

const SpecificationItemWrapper = styled.div`
  flex-basis: 50%;
  display: flex;
  margin-bottom: 2rem;
`;

const CustomAttributesWrapper = styled.div`
  display: flex;
`;

const CustomAttributesFieldsWrapper = styled.div`
  flex-basis: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  flex-basis: 2%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AddMoreWrapper = styled.div`
  flex-basis: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 4rem;
`;

const SpecificationFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
`;

export const Specifications = ({ register, control, errors, values }) => {
  const {
    product: { titles },
    form,
  } = Lang();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customAttributes',
  });

  const handleAdd = () => {
    append({ key: '', value: '' });
  };

  const handleRemove = index => {
    remove(index);
  };

  return (
    <>
      <SpecificationsTitle>{titles.specifications}</SpecificationsTitle>
      <SpecificationsWrapper>
        <FieldStyle as={FieldStyle2} legend={form.fields.label} width="24%">
          <InputStyled
            name="label"
            type="text"
            ref={register}
            defaultValue={get(values, 'label')}
          />
        </FieldStyle>
        <Grid
          as={SpecificationsBody}
          marginTop
          marginBottom
          gaps="2rem 2rem"
          cols="1fr 1fr 2fr"
          places="center start"
        >
          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.measurements}>
              <InputStyled
                name="measurements"
                type="text"
                ref={register}
                defaultValue={get(values, 'measurements')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.capacity}>
              <InputStyled
                name="capacity"
                type="text"
                ref={register}
                defaultValue={get(values, 'capacity')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.weight}>
              <InputStyled
                name="weight"
                type="text"
                ref={register}
                defaultValue={get(values, 'weight')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.craneCapacity}>
              <InputStyled
                name="crane-capacity"
                type="text"
                ref={register}
                defaultValue={get(values, 'crane-capacity')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.power}>
              <InputStyled
                name="power"
                type="text"
                ref={register}
                defaultValue={get(values, 'power')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.maxHeight}>
              <InputStyled
                name="max-height"
                type="text"
                ref={register}
                defaultValue={get(values, 'max-height')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.maxWorkingHeight}>
              <InputStyled
                name="max-working-height"
                type="text"
                ref={register}
                defaultValue={get(values, 'max-working-height')}
              />
            </FieldStyle>
          </Cell>

          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.holeDiameter}>
              <InputStyled
                name="hole-diameter"
                type="text"
                ref={register}
                defaultValue={get(values, 'hole-diameter')}
              />
            </FieldStyle>
          </Cell>
          <Cell as={Full} place="start" area="span 3 / auto">
            <FieldStyle as={FieldStyle2} legend={!form.fields}>
              <TextareaStyled
                name="notes"
                height="23.5rem"
                ref={register}
                defaultValue={get(values, 'notes')}
              />
            </FieldStyle>
          </Cell>
          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.diskDiameter}>
              <InputStyled
                name="disk-diameter"
                type="text"
                ref={register}
                defaultValue={get(values, 'disk-diameter')}
              />
            </FieldStyle>
          </Cell>
          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.cuttingDepth}>
              <InputStyled
                name="cutting-depth"
                type="text"
                ref={register}
                defaultValue={get(values, 'cutting-depth')}
              />
            </FieldStyle>
          </Cell>
          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.cuttingWidth}>
              <InputStyled
                name="cutting-width"
                type="text"
                ref={register}
                defaultValue={get(values, 'cutting-width')}
              />
            </FieldStyle>
          </Cell>
          <Cell as={Full}>
            <FieldStyle as={FieldStyle2} legend={form.fields.excavationDepth}>
              <InputStyled
                name="excavation-depth"
                type="text"
                ref={register}
                defaultValue={get(values, 'excavation-depth')}
              />
            </FieldStyle>
          </Cell>
        </Grid>
        <CustomAttributesWrapper>
          <CustomAttributesFieldsWrapper>
            {fields.map((item, index) => {
              return (
                <SpecificationItemWrapper key={item.id}>
                  <SpecificationFieldWrapper>
                    <FieldStyle
                      as={FieldStyle2}
                      legend={`${form.fields.specification} (${form.fields.specificationName})`}
                    >
                      <InputStyled
                        name={`customAttributes[${index}].key`}
                        type="text"
                        width="90%"
                        ref={register({
                          validate: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        })}
                        defaultValue={item.key}
                        control={control}
                      />
                    </FieldStyle>
                    <Error
                      message={get(errors, [
                        'customAttributes',
                        index,
                        'key',
                        'message',
                      ])}
                    />
                  </SpecificationFieldWrapper>
                  <SpecificationFieldWrapper>
                    <FieldStyle
                      as={FieldStyle2}
                      legend={`${form.fields.specification} (${form.fields.specificationVaValue})`}
                    >
                      <InputStyled
                        name={`customAttributes[${index}].value`}
                        type="text"
                        width="90%"
                        ref={register({
                          validate: value =>
                            !!value?.trim() ||
                            form.notification.theFieldIsRequired,
                        })}
                        defaultValue={item.value}
                        control={control}
                      />
                    </FieldStyle>
                    <Error
                      message={get(errors, [
                        'customAttributes',
                        index,
                        'value',
                        'message',
                      ])}
                    />
                  </SpecificationFieldWrapper>
                  <IconWrapper>
                    <Icon
                      type="delete"
                      color="gray"
                      handleClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemove(index);
                      }}
                    />
                  </IconWrapper>
                </SpecificationItemWrapper>
              );
            })}
          </CustomAttributesFieldsWrapper>
          <AddMoreWrapper>
            <AddMore onClick={handleAdd} />
          </AddMoreWrapper>
        </CustomAttributesWrapper>
      </SpecificationsWrapper>
    </>
  );
};
