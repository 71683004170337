import TrainingFormLine from 'components/atoms/TrainingFormLine';
import styled from 'styled-components';

import { T8y } from 'ui';

export const InfoWrapper = styled(TrainingFormLine)`
  width: 800px;
  border: 1px solid #e4e8ed;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 18px 0;
  margin-left: auto;
  margin-right: auto;
`;

export const AutomaticallyText = styled(T8y)`
  font-size: 12px;
  text-align: center;
`;

export const MapSectionWrapper = styled(TrainingFormLine)`
  margin: 18px 0;
  background: #ffffff;
`;

export const MapWrapper = styled.div`
  margin: 18px 0;
  width: 100%;
`;

export const BoldTextWrapper = styled(T8y)`
  text-align: center;
  font-size: 16px;
  margin-bottom: 6px;
`;

export const UsualTextWrapper = styled(T8y)`
  font-size: 16px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 0 10px;
`;
