import styled from 'styled-components';

export const Wrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
`;

export const hiddenInput = styled.input`
  visibility: hidden;
`;

export const WarehouseAddedCheckbox = styled.div`
  flex-basis: 6%;
`;

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  & > div:first-child {
    height: 44px;
    white-space: nowrap;
    margin-right: 1rem;
  }
`;

export const QuantityInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WarehouseAddedData = styled.div`
  flex-basis: 94%;
`;
