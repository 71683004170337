import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { cloneDeep } from 'lodash';
import { branding$, updateBranding } from 'models/branding';
import {
  categoriesTreeStore,
  isLoadingCategories$,
  sortCategoriesFx,
} from 'models/categories';
import * as productModel from 'models/product';

import CustomButton from 'components/atoms/CustomButton';
import LoadingSpinner from 'components/atoms/LoadingSpinner';
import CategoryBannerSlider from 'components/molecules/CategoryBannersSlider';
import CategoryItem from 'components/molecules/CategoryItem';
import DraggableCategories from 'components/molecules/DraggableCategories';

import * as ST from './styles';

import { Cell, Grid } from 'ui';

const renderTrackVertical = ({ style, ...props }) => {
  const finalStyle = {
    ...style,
    width: '1rem',
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: '#eeeeee',
  };

  return <div style={finalStyle} {...props} />;
};

const renderThumbVertical = ({ style, ...props }) => {
  const finalStyle = {
    ...style,
    width: '0.8rem',
    cursor: 'pointer',
    borderRadius: '0.4rem',
    backgroundColor: '#aaaaaa',
    left: '0.1rem',
  };

  return <div style={finalStyle} {...props} />;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const Categories = ({
  isLoading,
  isFiltered,
  filteredCategories = [],
  checkbox,
}) => {
  const {
    categories,
    form: { buttons, toastTitle },
  } = Lang();

  const isLoadingCategories = useStore(isLoadingCategories$);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    errors,
    control,
    setError,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      slides: [
        {
          file_id_mobile: '',
          file_id_web: '',
          srcWeb: '',
          srcMobile: '',
          cta: '',
          btnUrl: '',
          serverId: '',
          target_blank: '',
        },
      ],
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { fields } = useFieldArray({
    control,
    name: 'slides',
  });

  const branding = useStore(branding$);
  const allCategories = useStore(categoriesTreeStore);

  const [groupChecked, setGroupChecked] = useState(false);
  const [finalCats, setFinalCats] = useState([]);
  const [isRearrangeLoading, setIsRearrangeLoading] = useState(false);
  const [isLoadingDefaultBanners, setIsLoadingDefaultBanners] = useState(false);

  const handleSortCategories = useCallback(
    async ids => {
      try {
        await sortCategoriesFx(ids);
        toast.success(toastTitle.successfullyUpdated);
        return true;
      } catch (e) {
        toast.error(toastTitle.updateError);
        return false;
      }
    },
    [toastTitle.successfullyUpdated, toastTitle.updateError],
  );

  const setRearrange = useCallback(
    async (parentId, items) => {
      setIsRearrangeLoading(true);
      const ids = items.map(el => el.id);
      const sortingResult = await handleSortCategories({
        parent_id: parentId,
        ids,
      });

      if (!sortingResult) {
        setFinalCats(cloneDeep(allCategories.filter(el => el.level === 1)));
      }
      setIsRearrangeLoading(false);
    },
    [allCategories, handleSortCategories],
  );

  useEffect(() => {
    setFinalCats(cloneDeep(allCategories.filter(el => el.level === 1)));
  }, [allCategories]);

  useEffect(() => {
    if (branding.categoryDefaultBanner) {
      reset({
        slides: [
          {
            file_id_mobile: '',
            file_id_web: '',
            serverId: '',
            srcWeb: branding.categoryDefaultBanner.web || '',
            srcMobile: branding.categoryDefaultBanner.mobile || '',
            cta: branding.categoryDefaultBanner.cta || false,
            btnUrl: branding.categoryDefaultBanner.btnUrl || '',
            target_blank: branding.categoryDefaultBanner.targetBlank || false,
          },
        ],
      });
    }
  }, [branding.categoryDefaultBanner, reset]);

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const categoriesIds = useMemo(() => {
    if (!filteredCategories) {
      return [];
    }

    return filteredCategories.map(category => category.id);
  }, [filteredCategories]);

  const toggleGroupChecked = () => {
    setGroupChecked(!groupChecked);
    checkbox.setState(!groupChecked ? categoriesIds : []);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    const isMain = result.type === 'mainCat';
    const sourceParentId = isMain ? null : parseInt(result.source.droppableId);

    if (sourceIndex === destIndex) {
      return;
    }

    let newItems = [...finalCats];

    if (isMain) {
      newItems = reorder(finalCats, sourceIndex, destIndex);
      setRearrange(sourceParentId, newItems);
    } else {
      const level = result.type === 'SubCategories' ? 2 : 3;
      const destParentId = parseInt(result.destination.droppableId);

      if (destParentId !== sourceParentId) {
        toast.error(`Can't change the hierarchy`);
        return;
      }

      const itemSubItemMap = finalCats.reduce((acc, item) => {
        if (level === 2) {
          acc[item.id] = item.childs;
          return acc;
        }

        if (level === 3) {
          // eslint-disable-next-line no-unused-expressions
          item.childs?.data?.forEach(el => {
            acc[el.id] = el.childs;
          });
          return acc;
        }

        return acc;
      }, {});

      const sourceSubItems = itemSubItemMap[sourceParentId]?.data;

      const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);

      newItems = newItems.map(item => {
        if (level === 2 && item.id === sourceParentId) {
          item.childs = { data: reorderedSubItems };
        }

        if (level === 3 && item.childs) {
          item.childs.data.forEach(el => {
            if (el.id === sourceParentId) {
              el.childs = { data: reorderedSubItems };
            }
          });
        }
        return item;
      });

      setRearrange(sourceParentId, reorderedSubItems);
    }

    setFinalCats(newItems);
  };

  const attachUploaded = useCallback(
    async slide => {
      const isNewWebImage =
        slide.srcWeb && slide.srcWeb !== branding.categoryDefaultBanner.web;
      const isNewMobileImage =
        slide.srcMobile &&
        slide.srcMobile !== branding.categoryDefaultBanner.mobile;

      if (isNewWebImage) {
        await new Promise(resolve => {
          productModel.uploadPhotoFx({
            src: slide.srcWeb,
            name: 'category default web banner',
            object_id: 1,
            type: 'categoryDefaultBannerWeb',
          });
          resolve();
        });
      }

      if (isNewMobileImage) {
        await new Promise(resolve => {
          productModel.uploadPhotoFx({
            src: slide.srcMobile,
            name: 'category default mobile banner',
            object_id: 1,
            type: 'categoryDefaultBannerMobile',
          });
          resolve();
        });
      }
    },
    [branding.categoryDefaultBanner],
  );

  const onSubmit = useCallback(
    async data => {
      try {
        setIsLoadingDefaultBanners(true);
        const currSlide = data.slides[0];

        await attachUploaded(currSlide);
        updateBranding({
          name: 'categoryDefaultBanner',
          value: {
            web: currSlide.srcWeb,
            mobile: currSlide.srcMobile,
            cta: currSlide.cta || null,
            targetBlank: currSlide.target_blank || null,
            btnUrl: currSlide.btnUrl || null,
          },
        });
        toast.success(toastTitle.successfullyUpdated);
      } catch (e) {
        toast.error(toastTitle.wentWrong);
      } finally {
        setIsLoadingDefaultBanners(false);
      }
    },
    [attachUploaded, toastTitle.successfullyUpdated, toastTitle.wentWrong],
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Grid
        gaps="0"
        cols="0.5fr 4fr 2fr 3fr 3fr 1.5fr"
        places="center"
        as={ST.TableHeader}
      >
        <Cell place="center start" as={ST.TableHeaderCheckbox}>
          <ST.CheckboxStyled
            checked={groupChecked}
            onChange={toggleGroupChecked}
          />
        </Cell>
        <Cell place="center start" as={ST.TableHeaderTitle}>
          {categories.titles.visual} / {categories.titles.categoryTitle}
        </Cell>
        <Cell place="center end" as={ST.TableHeaderTitle}>
          {categories.hideCategory}
        </Cell>
        <Cell place="center end" as={ST.TableHeaderTitle}>
          {categories.prerenderStatusCode}
        </Cell>
        <Cell place="center end" as={ST.TableHeaderTitle}>
          {categories.prerenderHeader}
        </Cell>
        <Cell place="center start" as={ST.TableHeaderTitle} />
      </Grid>
      <ST.TableRowsWrapper
        style={{
          height: '400px',
        }}
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumbVertical}
      >
        {isFiltered ? (
          <>
            {!!filteredCategories.length &&
              filteredCategories.map(el => (
                <CategoryItem
                  key={el.id}
                  category={el}
                  level={el.level}
                  checkbox={checkbox}
                />
              ))}
          </>
        ) : (
          <DraggableCategories
            categories={finalCats}
            checkbox={checkbox}
            isLoading={isRearrangeLoading || isLoadingCategories}
            onDragEnd={onDragEnd}
          />
        )}
      </ST.TableRowsWrapper>
      <CategoryBannerSlider
        fields={fields}
        values={values}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        setError={setError}
        trigger={trigger}
        watch={watch}
      />
      <ST.SaveBtnWrapper>
        <CustomButton
          text={buttons.save}
          backgroundColor="primary"
          color="positive"
          border="1px solid #F89739"
          colorHovered="gray"
          disabled={isLoadingDefaultBanners}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        />
      </ST.SaveBtnWrapper>
    </>
  );
};
