import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';

//TODO remove all styles except color, font-weight and font-family
export const T8y = styled.div`
  color: ${({ color }) => colors[color] || color};
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-family: ${({ bold }) => (bold ? fonts.bold : fonts.normal)};

  ${p =>
    p.lineHeight &&
    `
      line-height: ${p.lineHeight};
    `}

  ${p =>
    p.cursor &&
    `
      cursor: ${p.cursor};
    `}
    
  ${p =>
    p.align &&
    `
          text-align: ${p.align};
    `}
      
  ${p =>
    p.marginRight &&
    `
        margin-right: ${p.marginRight};
      `}

    ${p =>
      p.marginLeft &&
      `
      margin-left: ${p.marginLeft};
    `}
    
    ${p =>
      p.marginBottom &&
      `
      margin-bottom: ${p.marginBottom};
    `}
    
    ${p =>
      p.marginTop &&
      `
      margin-top: ${p.marginTop};
    `}

  ${p =>
    p.display &&
    `
      display: ${p.display};
    `}

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
    color: ${colors.gray2};
  `}
`;
