import React, { useEffect, useMemo } from 'react';
import { useDialogState } from 'reakit';
import { format, parse } from 'date-fns';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  displayedReports$,
  getReports,
  reportsFilters$,
  searchStr$,
  setReportFilter,
  setReportsSearchStr,
} from 'models/analytics';

import { CreateReport } from 'components/molecules/CreateReport';
import FiltersPanel from 'components/molecules/FiltersPanel';
import ReportsByMonthCart from 'components/molecules/ReportsByMonthCart';

const AnalyticsReports = () => {
  const displayedReports = useStore(displayedReports$);
  const reportsFilters = useStore(reportsFilters$);
  const searchStr = useStore(searchStr$);

  const {
    form: { fields },
    analytics,
  } = Lang();

  const dialog = useDialogState();

  const FILTER_OPTIONS = [{ name: fields.all, slug: 'All' }];

  const getReportsData = async () => {
    try {
      await getReports();
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    getReportsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedReports = useMemo(() => {
    if (!displayedReports || !displayedReports.length) {
      return [];
    }

    const groups = displayedReports.reduce((groups, report) => {
      const date = report.date.substring(0, 7);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(report);
      return groups;
    }, {});

    return Object.keys(groups).map(date => {
      const monthName =
        analytics.months[
          format(parse(date, 'yyyy-MM', new Date()), 'MMMM').toLowerCase()
        ];

      return {
        date:
          monthName + ' ' + format(parse(date, 'yyyy-MM', new Date()), 'yyyy'),
        reports: groups[date],
      };
    });
  }, [displayedReports, analytics]);

  const filter_options = useMemo(() => {
    const dateFromReports = formattedReports.map(el => ({
      name: el.date,
      slug: el.date,
    }));
    return [...FILTER_OPTIONS, ...dateFromReports];
  }, [FILTER_OPTIONS, formattedReports]);

  const filteredFinalReports = useMemo(() => {
    if (reportsFilters === filter_options[0].slug) {
      return formattedReports;
    }

    return formattedReports.filter(el => el.date === reportsFilters);
  }, [filter_options, formattedReports, reportsFilters]);

  const reportsLength = useMemo(() => {
    if (!filteredFinalReports || !filteredFinalReports.length) {
      return 0;
    }

    return filteredFinalReports.map(el => el.reports).flat().length;
  }, [filteredFinalReports]);

  return (
    <>
      <FiltersPanel
        selectOptions={filter_options}
        selected={fields[reportsFilters.toLowerCase()] || reportsFilters}
        onClickOption={setReportFilter}
        onChangeSearch={setReportsSearchStr}
        searchStr={searchStr}
        searchPlaceholder={fields.startTyping}
        foundStr={`${reportsLength} ${fields.reportsAreFound}`}
      >
        <CreateReport dialog={dialog} />
      </FiltersPanel>
      {filteredFinalReports.map(el => (
        <ReportsByMonthCart data={el} dialog={dialog} />
      ))}
    </>
  );
};

export default AnalyticsReports;
