import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';

import CustomButton from 'components/atoms/CustomButton';
import { USERS } from 'api';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

const ConfirmLoggInAsModal = ({ dialog, userId }) => {
  const {
    form: { buttons, toastTitle },
    users,
    trainings,
  } = Lang();

  const branding = useStore(branding$);

  const [isLoading, setIsLoading] = useState(false);

  const getAdminToken = useCallback(async () => {
    if (!userId) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await USERS.getUserToken(userId);

      window.location.href = `${branding.webSiteUrl}/admin/verify/?token=${data.access_token}`;
    } catch (e) {
      toast.error(toastTitle.wentWrong);
    } finally {
      setIsLoading(false);
    }
  }, [branding.webSiteUrl, toastTitle.wentWrong, userId]);

  return (
    <Modal
      title={buttons.logIn}
      dialogState={dialog}
      width={620}
      backgroundColor={colors.primary}
      lazyRender
    >
      {users.sureToLogIn}
      <ST.ControlsWrapper>
        <ST.CustomButtonNegative
          text={trainings.no}
          backgroundColor="primary"
          color="positive"
          border="1px solid #f89739"
          colorHovered="gray"
          onClick={dialog.hide}
        />
        <CustomButton
          text={trainings.yes}
          backgroundColor="primary"
          color="positive"
          border="1px solid #f89739"
          colorHovered="gray"
          onClick={isLoading ? undefined : getAdminToken}
          loading={isLoading}
          disabled={isLoading}
        />
      </ST.ControlsWrapper>
    </Modal>
  );
};

export default ConfirmLoggInAsModal;
