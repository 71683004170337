import styled from 'styled-components';

import { colors, fonts } from 'styleGuide';

import { ReactComponent as Lens } from 'assets/icons/lens.svg';

export const ListControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &.usersFilter {
    margin-right: 10px;
  }
`;

export const FiltersWrapper = styled.div`
  flex-basis: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${p =>
    p.limitOptions
      ? 'flex-basis: 70%; ' + '& > div {flex-basis: 20%};'
      : '& > div:first-child {flex-basis: 50%;} ' +
        '& > div:last-child {flex-basis: 60%;}'}

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const SearchWrapperStyled = styled.div`
  position: relative;
  flex-grow: 1;
  height: 4rem;
  width: 100%;
  max-width: 48rem;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 4rem;
  display: block;
  outline: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.3rem;
  padding: 0 1rem 0 4rem;
  line-height: 4rem;
  margin: 0;
  font-family: ${fonts.nunitoSans};
  font-size: 1.4rem;
  color: ${colors.darkBlue};

  &::placeholder {
    color: #777777;
  }
`;

export const SearchIcon = styled(Lens)`
  position: absolute;
  top: 1.2rem;
  left: 1.1rem;
  z-index: 1;
  height: 1.5rem;
`;
