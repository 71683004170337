export const orders = {
  order: 'Pedido',
  title: 'Pedidos recientes',
  total: 'Total final',
  documents: 'Documentos',
  invoice: 'Factura',
  uploadDocument: 'Subir documento',
  requestToCancelThisOrder:
    'El usuario envió una solicitud para cancelar este pedido',
  approve: 'Aprobar',
  decline: 'Denegar',
  youWantToCancelThisOrder: '¿Estás seguro de querer cancelar este pedido',
  provideCancellationReason:
    'Si es así, por favor explica el motivo de la cancelación',
  cancellationReason: 'Motivo de la cancelación',
  cancelOrder: 'Cancelar pedido',
  submitRequest: 'Enviar solicitud',
  viewOrder: 'Ver pedido',
  ordersAvailable: '{0} pedidos disponibles',
  startTypingToFilter: 'Empieza a escribir para filtrar',
  paymentStatus: 'Estado del pago',
  productList: 'Lista de productos',

  orderTableHeaders: {
    orderId: 'ID del pedido',
    createdBy: 'Created By',
    orderType: 'Tipo de pedido',
    dateCreated: 'Fecha de creación',
    dates: 'Date',
    status: 'Estado',
    deliveryMethod: 'Método de entrega',
    paymentMethod: 'Método de pago',
    deposit: 'Fianza',
    total: 'Total final',
    author: 'Autor',
    purchaseOrderNumber: 'Número de Pedido',
  },

  productsTableHeaders: {
    sku: 'Sku',
    visual: 'Imagen',
    productTitle: 'Título del producto',
    hirePeriod: 'Duración del Alquiler',
    hirePerItem: 'Coste por artículo',
    qty: 'Cantidad',
    hirePrice: 'Precio del alquiler',
    courseId: 'ID del curso',
    courseTitle: 'Título del curso',
    courseDates: 'Fechas del curso',
    coursePrice: 'Precio del curso',
    seats: 'Plazas',
    totalCost: 'Costo total',
  },

  item: {
    orderStatus: 'Estado del pedido',
    date: 'Fecha de creación',
    transport: 'Transporte',
    paymentType: 'Método de pago',
    productList: 'Lista de productos',
    cardEndingIn: 'Que termina en',
  },

  paymentType: {
    card: 'Tarjeta de crédito',
    cash: 'Efectivo',
    later: 'Pagas después',
    bank: 'Transferencia bancaria',
  },

  statusType: {
    paid: 'Pagado',
    not_paid: 'No pagado',
    new: 'Nuevo',
    in_progress: 'En curso',
    completed: 'Completado',
    cancelled: 'Cancelado',
    all: 'Todos los pedidos',
    quote: 'Presupuesto',
  },

  orderType: {
    product: 'Producto',
    quote: 'Presupuesto',
    training: 'Formación',
  },

  deliveryMethod: {
    selfCollection: 'Retiro en sede',
    delivery: 'Entrega',
  },

  costSummary: {
    title: 'Resumen del costo',
    subtotal: 'Subtotal',
    tbd: 'A definir',
    slightDamageWaiverFee: 'Exención de responsabilidad por daños leves',
    adminFee: 'Gestión de residuos',
    total: 'Total',
    free: 'Gratuito',
    depositAdditionalText:
      'El depósito por pérdida y daños se reembolsará a tu tarjeta una vez que el producto sea devuelto en buenas condiciones y con el depósito de combustible lleno (cuando aplique).',
    discount: 'Descuento',
    itemsToHire: 'Alquiler Maquinaria',
    tradeAccountDiscount: 'Descuento de cuenta comercial',
  },

  offers: {
    offer: 'Oferta',
    offers: 'Ofertas',
    newOffer: 'Nueva oferta',
    createNewOffer: 'Crear nueva oferta',
    viewOffer: 'Ver oferta',
    urlFromCheckout: 'URL del carrito',
    enterUrl: 'Ingresar URL aquí',
    backToList: 'Volver a la lista de ofertas',
    offersAvailable: '{0} ofertas disponibles',
    saveOffer: 'Guardar oferta y generar PDF',
    quotePDF: 'Presupuesto',
  },

  noDocuments: 'No hay documentos aún',
};
