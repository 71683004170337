import React from 'react';
import { Lang } from 'lang';

import OptionSelector from 'components/atoms/OptionSelector';
import { FieldStyle, FieldStyle2 } from 'components/Product/shared';

import * as ST from './styles';

import { Icon, inputStyle, Select, T8y } from 'ui';

const FiltersPanel = ({
  selectOptions,
  selected,
  onClickOption,
  searchStr,
  onChangeSearch,
  children,
  foundStr,
  searchPlaceholder,
  limitOptions,
  limitDisplayName,
  handleLimitOptionChange,
  className,
}) => {
  const {
    header: { titles },
  } = Lang();

  return (
    <ST.ListControlsWrapper className={className}>
      <ST.FiltersWrapper limitOptions={limitOptions}>
        <FieldStyle as={FieldStyle2} withBorder={true}>
          <Select
            options={selectOptions}
            selected={selected}
            onClickOption={onClickOption}
            className={inputStyle}
          />
        </FieldStyle>
        {limitOptions && (
          <OptionSelector
            options={limitOptions}
            handleOptionChoose={handleLimitOptionChange}
            displayName={limitDisplayName}
            selectorTitle={`${titles.filter}:`}
          />
        )}
        <ST.SearchWrapperStyled>
          <Icon as={ST.SearchIcon} />
          <ST.SearchInput
            type="text"
            placeholder={searchPlaceholder}
            value={searchStr}
            onChange={e => onChangeSearch(e.target.value)}
          />
        </ST.SearchWrapperStyled>
        <T8y>{foundStr}</T8y>
      </ST.FiltersWrapper>
      {children}
    </ST.ListControlsWrapper>
  );
};

export default FiltersPanel;
