import React from 'react';

import * as ST from './styles';

import { Cell, Grid } from 'ui';

const Table = ({
  headers = [],
  keyFields = [],
  items = [],
  cols = '1fr 1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr',
}) => {
  return (
    <>
      <Grid gaps="0 .2rem" cols={cols} places="flex-start" as={ST.TableHeader}>
        {headers.map((item, index) => {
          return (
            <Cell as={ST.TableHeaderTitle} key={index}>
              {item}
            </Cell>
          );
        })}
        <Cell />
      </Grid>
      {items.map((el, index) => (
        <Grid
          gaps="0 .2rem"
          cols={cols}
          places="center"
          as={ST.TableRow}
          key={index}
        >
          {keyFields.map(({ name, type }, index) => {
            const isLongString = name === 'city' || name === 'payload';
            return (
              <Cell
                justifySelf="start"
                alignSelf="center"
                as={ST.TableRowTitle}
                key={index}
                isLongString={isLongString}
              >
                {type === 'text' ? (
                  <span title={isLongString ? el[name] : undefined}>
                    {el[name]}
                  </span>
                ) : (
                  el[name]
                )}
              </Cell>
            );
          })}
        </Grid>
      ))}
    </>
  );
};

export default Table;
