import styled from 'styled-components';

import { ReactComponent as LinkIcon } from 'assets/images/icon/linkIcon.svg';

export const OrderInfoCrmLink = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const LinkIconStyled = styled(LinkIcon)`
  flex-shrink: 0;
`;
