import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Icon } from 'ui';

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-right: ${props => props.marginRight || 0};
  filter: drop-shadow(
    ${props => (props.$shadow ? '0 0 0.7rem rgba(0,0,0,.4)' : 0)}
  );
  path {
    fill: ${props => colors[props.color || 'gray']};
  }
`;
