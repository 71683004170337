import FiltersPanel from 'components/molecules/FiltersPanel';
import styled from 'styled-components';

import { T8y } from 'ui';

export const PromoCodesFiltersPanel = styled(FiltersPanel)`
  flex-grow: 1;
  & > div {
    flex-grow: 1;
    flex-basis: auto;
    max-width: 900px;
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  min-height: 100px;
`;

export const PromoCodeName = styled(T8y)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.04);
`;
