import React from 'react';
import { Lang } from 'lang';
import { get } from 'lodash';

import Error from 'components/atoms/Error';

import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
  TextareaStyled,
} from '../../Product/shared';
import TrainingNotCheckoutAvailableLocations from '../TrainingNotCheckoutAvailableLocations';
import TrainingNotCheckoutAvailablePrices from '../TrainingNotCheckoutAvailablePrices';
import * as ST from './styles';

import { T8y } from 'ui';

const TrainingNotCheckoutAvailable = ({
  register,
  values,
  setValue,
  EMPTY_LOCATION,
  control,
  errors,
  trigger,
}) => {
  const { trainings, form } = Lang();

  return (
    <>
      <div>
        <FieldStyle2 legend={trainings.scheduleDescription}>
          <TextareaStyled
            name="form.noSchedule.description"
            ref={register({
              validate: value =>
                !!value?.trim() || form.notification.theFieldIsRequired,
            })}
            defaultValue={values.form?.noSchedule?.description || null}
          />
        </FieldStyle2>
        <Error
          message={get(errors, [
            'form',
            'noSchedule',
            'description',
            'message',
          ])}
        />
      </div>
      <T8y fontSize="14px" marginBottom="10px" color="gray">
        {trainings.courseLocations}
      </T8y>
      <ST.LocationsWrapper>
        <TrainingNotCheckoutAvailableLocations
          register={register}
          values={values}
          setValue={setValue}
          EMPTY_LOCATION={EMPTY_LOCATION}
          control={control}
          errors={errors}
          trigger={trigger}
        />
      </ST.LocationsWrapper>
      <div>
        <FieldStyle as={FieldStyle2} legend={trainings.courseOrganizer}>
          <InputStyled
            name="form.noSchedule.organizer"
            width="415px"
            ref={register}
            defaultValue={values.form?.noSchedule?.organizer}
          />
        </FieldStyle>
        <Error
          message={get(errors, ['form', 'noSchedule', 'organizer', 'message'])}
        />
      </div>
      <TrainingNotCheckoutAvailablePrices
        register={register}
        values={values}
        control={control}
        errors={errors}
      />
    </>
  );
};

export default TrainingNotCheckoutAvailable;
