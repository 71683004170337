import React from 'react';
import { CellMeasurer } from 'react-virtualized';

import * as ST from 'components/molecules/TableModalContent/styles';

import { Checkbox, Grid } from 'ui';

const RenderProduct = ({
  product,
  selected,
  onChangeCheckbox,
  cache,
  index,
  parent,
  style,
  cols,
  isDisabled,
}) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      parent={parent}
      rowIndex={index}
    >
      <Grid
        places="center start"
        cols={cols}
        gaps="0 1rem"
        as={ST.TableHeader}
        key={index}
        style={style}
      >
        {product.map((item, index) => {
          switch (item.type) {
            case 'checkbox':
              return (
                <ST.CheckboxCell key={index}>
                  <Checkbox
                    checked={selected.includes(item.value)}
                    onChange={() => !isDisabled && onChangeCheckbox(item.value)}
                  />
                </ST.CheckboxCell>
              );
            case 'image':
              return (
                <ST.TableBodyCell key={index}>
                  <ST.TableImage src={item.value} />
                </ST.TableBodyCell>
              );
            case 'initial':
              return (
                <ST.TableBodyCell key={index}>{item.value}</ST.TableBodyCell>
              );
            default:
              return (
                <ST.TableBodyCell key={index}>{item.value}</ST.TableBodyCell>
              );
          }
        })}
      </Grid>
    </CellMeasurer>
  );
};

export default RenderProduct;
