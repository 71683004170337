export function isUrl(s) {
  const pattern = new RegExp(
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
    'i',
  ); // fragment locator
  return !!pattern.test(s);
}

export function convertImages(htmlText) {
  return htmlText.replace(
    /<div style="text-align:none;"><img/g,
    '<div style="text-align:center;"><img',
  );
}
