import React from 'react';
import { useGate } from 'effector-react';
import { Lang } from 'lang';
import { leadsFiltersGate } from 'models/lead';

import LeadsList from 'components/organisms/LeadsList';

import { T8y } from 'ui';

const Leads = () => {
  const { header, leads } = Lang();
  useGate(leadsFiltersGate);

  return (
    <div>
      <T8y fontSize="24px" color="darkBlue" fontWeight="900">
        {header.menu.leads}
      </T8y>
      <T8y fontSize="16px" color="gray2" fontWeight="600" marginBottom="30px">
        {leads.subTitle}
      </T8y>
      <LeadsList />
    </div>
  );
};

export default Leads;
