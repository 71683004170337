import { MenuButton } from 'reakit';
import { transparentize } from 'polished';

import styled from 'styled-components';

import { colors, fontSizes } from 'styleGuide';
import { T8y } from 'ui';

export const T8yStyled = styled(T8y)`
  margin-right: 2.5rem;
`;

export const OptionsStyled = styled.div`
  z-index: 1;
  box-sizing: content-box;
  background-color: ${colors.positive};
  border-top: none;
  box-shadow: 0px 0.5rem 2.5rem ${transparentize(0.8, colors.gray)};
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
`;
export const OptionStyled = styled.div`
  display: block;
  width: 100%;
  padding: 0 1.2rem;
  font-size: ${fontSizes.t3};
  text-align: ${props => props.textAlign || 'left'};
  cursor: pointer;
  border-bottom: 1px solid #80808042;

  &:not(:first-child) {
    padding-top: 10px;
  }

  &:hover {
    background-color: #ffffff4f;
  }
`;

export const DivWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SortDivWrapper = styled(DivWrapper)`
  svg {
    margin-left: 1rem;
  }
`;

export const MenuButtonStyled = styled(MenuButton)`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 1rem;
  }
`;
