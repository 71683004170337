import styled from 'styled-components';

import { Row } from 'ui';

export const TitlesWrapper = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  color: #b3b7bb;
  font-family: 'FuturaBT-Book', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  text-align: inherit;
  cursor: default;
  margin-bottom: 0.5rem;

  > span {
    flex-basis: 30%;
  }

  > span:first-child {
    flex-basis: 25%;
    margin-right: 50px;
    min-width: 175px;
  }
`;

export const MinRangeWrapper = styled(Row)`
  flex-basis: 25%;
  margin-right: 50px;
`;

export const OutOfRangeWrapper = styled(Row)`
  margin-left: 50px;
`;
