import React, { useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { get } from 'lodash';
import { getSettings, settings$ } from 'models/settings';

import AddMore from 'components/atoms/AddMore';
import EnquiryAnswer from 'components/atoms/EnquiryAnswer';
import Error from 'components/atoms/Error';
import TrainingFormLine from 'components/atoms/TrainingFormLine';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import { FieldStyle2, InputStyled } from 'components/Product/shared';
import { SETTINGS } from 'api';

import * as ST from './styles';

import { Radio, T8y } from 'ui';

const QUESTION_MAX_LENGTH = 124;

const EnquiryForm = () => {
  const {
    marketing: { enquiry },
    form,
    formatString,
    trainings,
  } = Lang();

  const settings = useStore(settings$);

  const radio = useRadioState({ state: 0 });

  const handleRadioClick = useCallback(() => {}, []);

  const {
    handleSubmit,
    register,
    watch,
    control,
    errors,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      leadCustomQuestion: {
        question: '',
        answers: [{ value: '' }, { value: '' }],
      },
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  useEffect(
    () => {
      if (radio.state === 0) {
        clearErrors();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [radio.state],
  );

  const getModifiedAnswers = useCallback(initialData => {
    return initialData.map(el => ({
      value: el || '',
    }));
  }, []);

  const setInitialState = useCallback(() => {
    radio.setState(settings.showLeadCustomQuestion ? 1 : 0);

    if (settings.leadCustomQuestion) {
      reset({
        leadCustomQuestion: {
          ...settings.leadCustomQuestion,
          answers: getModifiedAnswers(settings.leadCustomQuestion.answers),
        },
      });
    }
  }, [getModifiedAnswers, radio, reset, settings]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'leadCustomQuestion.answers',
    keyName: 'formId',
  });

  useEffect(() => {
    setInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const handleAdd = useCallback(
    e => {
      e.preventDefault();
      append({ value: '' });
    },
    [append],
  );

  const handleRemove = useCallback(
    (e, index) => {
      e.preventDefault();
      remove(index);
    },
    [remove],
  );

  const questionRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'leadCustomQuestion.question',
    QUESTION_MAX_LENGTH,
  );

  const onSubmit = useCallback(
    async formData => {
      try {
        const modifiedFormData = {
          leadCustomQuestion: {
            ...formData.leadCustomQuestion,
            answers: formData.leadCustomQuestion.answers.map(el => el.value),
          },
        };

        const { data } = await SETTINGS.updateEnquiry({
          ...modifiedFormData,
          showLeadCustomQuestion: Boolean(radio.state),
        });

        getSettings();

        radio.setState(data.showLeadCustomQuestion ? 1 : 0);

        toast.success(form.toastTitle.successfullyUpdated);
      } catch (e) {
        toast.error(form.toastTitle.updateError);
      }
    },
    [form, radio],
  );

  const handleReset = useCallback(() => {
    setInitialState();
  }, [setInitialState]);

  return (
    <ST.EnquiryFormWrapper>
      <ProductEditorBlockWrapper>
        <T8y color="gray" fontSize="14px" marginBottom="21px">
          {enquiry.enquiryFormVariation}
        </T8y>
        <RadioGroup {...radio} onClick={handleRadioClick}>
          <ST.RadioWrapper>
            <Radio {...radio} value={0}>
              <ST.AvailableOption>{enquiry.defaultTitle}</ST.AvailableOption>
            </Radio>
            <ST.AvailableOptionText color="gray">
              {enquiry.defaultSubTitle}
            </ST.AvailableOptionText>
          </ST.RadioWrapper>
          <ST.RadioWrapper>
            <Radio {...radio} value={1}>
              <ST.AvailableOption>{enquiry.customizedTitle}</ST.AvailableOption>
            </Radio>
            <ST.AvailableOptionText color="gray">
              {enquiry.customizedSubTitle}
            </ST.AvailableOptionText>
          </ST.RadioWrapper>
        </RadioGroup>
        {!!radio.state && (
          <ST.QuestionPartWrapper>
            <TrainingFormLine>
              <FieldStyle2 legend={form.fields.question}>
                <InputStyled
                  name="leadCustomQuestion.question"
                  width="790px"
                  maxLength={QUESTION_MAX_LENGTH}
                  ref={register({
                    validate: value => {
                      return (
                        !!value.trim() || form.notification.theFieldIsRequired
                      );
                    },
                  })}
                />
              </FieldStyle2>
              <Error
                message={get(errors, [
                  'leadCustomQuestion',
                  'question',
                  'message',
                ])}
              />
              <T8y color="gray" fontSize="12px">
                {formatString(
                  trainings.remainingCharacters,
                  questionRemainingSymbols,
                )}
              </T8y>
            </TrainingFormLine>
            {fields.map((el, index) => (
              <EnquiryAnswer
                watch={watch}
                index={index}
                register={register}
                errors={errors}
                fields={fields}
                handleRemove={handleRemove}
                key={el.formId}
              />
            ))}
            <AddMore text={enquiry.addNewAnswer} onClick={handleAdd} />
          </ST.QuestionPartWrapper>
        )}
      </ProductEditorBlockWrapper>
      <AddNewAndSaveCancelFooter
        handleAdd={handleAdd}
        onSubmit={onSubmit}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
      />
    </ST.EnquiryFormWrapper>
  );
};

export default EnquiryForm;
