import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { get } from 'lodash';
import { trainingLocalized$ } from 'models/trainings';

import BigAddMore from 'components/atoms/BigAddMore';
import ShowSection from 'components/atoms/ShowSection';

import GeneralExpandableSection from '../GeneralExpandableSection';
import TrainingFormWhyChooseUs from '../TrainingFormWhyChooseUs';

const TrainingWhyChoseUs = ({
  errors,
  setValue,
  trigger,
  values,
  register,
  control,
  setDeletedFiles,
  watch,
  clearErrors,
}) => {
  const { trainings } = Lang();

  const trainingLocalized = useStore(trainingLocalized$);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.benefits',
    keyName: 'formId',
  });

  const getIsError = useCallback(
    (field, index) =>
      get(errors, ['form', 'benefits', index, field, 'message']),
    [errors],
  );

  const getBenefitTitle = useCallback(
    index =>
      get(values, ['form', 'benefits', index, 'title']) ||
      trainings.benefits.enterBenefitTitle,
    [trainings.benefits.enterBenefitTitle, values],
  );

  const handleAdd = useCallback(() => append({}), [append]);

  const handleRemove = useCallback(
    index => {
      remove(index);
    },
    [remove],
  );

  const handleShow = useCallback(
    value => {
      setValue('form.benefitShow', value);

      if (value && !fields.length) {
        handleAdd();
      }

      if (!value) {
        clearErrors('form.benefits');
      }
    },
    [clearErrors, fields.length, handleAdd, setValue],
  );

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.courseWhyChooseUs}
      errors={errors}
      listOfFields={['benefits']}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={trainingLocalized?.benefitShow}
          handleChange={handleShow}
          fields={fields}
        />
      }
      withSubSections
      initialIsOpen
    >
      {fields.map((el, index) => (
        <GeneralExpandableSection
          index={0}
          key={el.formId}
          header={getBenefitTitle(index)}
          listOfFields={[`benefits`]}
          errors={errors}
          subSection
          handleRemove={() => handleRemove(index)}
        >
          <TrainingFormWhyChooseUs
            item={el}
            values={values}
            register={register}
            index={index}
            setValue={setValue}
            trigger={trigger}
            getIsError={getIsError}
            setDeletedFiles={setDeletedFiles}
            control={control}
            clearErrors={clearErrors}
            errors={errors}
            watch={watch}
          />
        </GeneralExpandableSection>
      ))}
      <BigAddMore
        fontSize="16px"
        onClick={handleAdd}
        text={trainings.addNewBenefit}
      />
    </GeneralExpandableSection>
  );
};

export default TrainingWhyChoseUs;
