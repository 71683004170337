import React from 'react';

import styled from 'styled-components';

import { Lang } from '../../lang';
import { doNothing, IconStyled, submitForm } from '../Product/shared';

import { colors, colorsHovered } from 'styleGuide';
import { Cell, Grid } from 'ui';

import { ReactComponent as Eye } from 'assets/images/icon/eye.svg';

export const SubmitButton = styled.button`
  display: block;
  min-width: 20rem;
  height: 5rem;
  background: ${colors.primary};
  outline: none;
  line-height: 5rem;
  text-align: center;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1.6rem;
  color: #fff;
  transition: background-color 0.5s ease;

  &:disabled,
  &[disabled] {
    background-color: ${colors.gray};
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &:hover {
    cursor: pointer;
    background-color: ${colorsHovered.primaryHovered};

    &:disabled,
    &[disabled] {
      background-color: ${colors.gray};
      cursor: not-allowed;
      pointer-events: all !important;
    }
  }
`;

export const SaveAndViewButton = styled.button`
  display: block;
  min-width: 20rem;
  height: 5rem;
  padding: 0 20px;
  background: transparent;
  outline: none;
  line-height: 5rem;
  text-align: center;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1.6rem;
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  transition: background-color, border-color 0.5s ease;

  &:disabled,
  &[disabled] {
    color: ${colors.gray};
    border-color: ${colors.gray};
    cursor: not-allowed;
    pointer-events: all !important;

    svg {
      path {
        fill: ${colors.gray};
      }
    }
  }

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: ${colorsHovered.primaryHovered};
    border-color: ${colorsHovered.primaryHovered};

    svg {
      path {
        fill: #fff;
      }
    }

    &:disabled,
    &[disabled] {
      color: ${colors.gray};
      border-color: ${colors.gray};
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: all !important;

      svg {
        path {
          fill: ${colors.gray};
        }
      }
    }
  }
`;

export const ButtonsBottom = ({ loading, submitButtonTitle }) => {
  const {
    form: { buttons },
  } = Lang();

  return (
    <Grid gaps="0" cols="1fr 26rem 23rem" places="center">
      <Cell />
      <Cell
        place="center end"
        as={SaveAndViewButton}
        type="button"
        disabled={loading}
        onClick={e => (loading ? doNothing(e) : submitForm())}
      >
        <IconStyled as={Eye} />
        {buttons.saveAndView}
      </Cell>

      <Cell
        place="center end"
        as={SubmitButton}
        type="submit"
        disabled={loading}
        onClick={e => (loading ? doNothing(e) : submitForm())}
      >
        {submitButtonTitle}
      </Cell>
    </Grid>
  );
};
