import styled from 'styled-components';

import { colors, colorsHovered } from 'styleGuide';
import { Checkbox } from 'ui';

export const GridStyled = styled.div`
  padding: 3.2rem 2rem 1rem;
`;

export const CellStyled = styled.div`
  width: 100%;
`;

export const FormRow = styled.div`
  margin-top: ${p => p.marginTop || '20px'};
  ${p => p.isBlocked && 'color:#999'};
  display: flex;
  align-items: center;
`;

export const SubmitButton = styled.button`
  display: flex;
  width: 14.5rem;
  height: 4rem;
  border-radius: 0.5rem;
  background-color: ${colors.primary};
  outline: none;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  transition: background-color 0.5s ease;

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: ${colors.gray};
  `}

  &:hover {
    background-color: ${colorsHovered.primaryHovered};
    cursor: pointer;

    ${p =>
      p.disabled &&
      `
      cursor: not-allowed;
      pointer-events: all !important;
      background-color: ${colors.gray};
    `}
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 17rem;
  height: 17rem;
  border: 1px solid ${colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  color: #333333;
  flex-direction: column;
  line-height: 1.4;

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
    pointer-events: all !important;

    svg {
      path {
        fill: ${colors.lightGray};
      }
    }
  `}
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-right: 1rem;
`;

export const UploadEmptyBlock = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;
