import { Disclosure } from 'reakit';

import CustomButton from 'components/atoms/CustomButton';
import styled from 'styled-components';

import { T8y } from 'ui';

export const OutOfRangeNotAvailableMsg = styled(T8y)`
  max-width: 580px;
`;

export const DeliveryConfirmationModalControlsWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;

  &>button: first-child {
    margin-right: 10px;
  }
`;

export const CustomButtonStyled = styled(CustomButton)`
  width: auto;
  min-width: 120px;
  padding: 0 30px;
`;

export const DepotsDisclosureStyled = styled(Disclosure)`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  svg {
    ${p => p.visible && 'transform: rotate(180deg);'}
    margin-left: 8px;
  }
`;
