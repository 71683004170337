import React from 'react';

import * as ST from './styles';

const ProductEditorBlockWrapper = ({
  blockTitle,
  isNoBackground,
  children,
}) => {
  return (
    <>
      {blockTitle && <ST.BlockTitle>{blockTitle}</ST.BlockTitle>}
      <ST.BlockBody isNoBackground={isNoBackground}>{children}</ST.BlockBody>
    </>
  );
};

export default ProductEditorBlockWrapper;
