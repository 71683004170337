import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Lang } from 'lang';
import { get } from 'lodash';
import { isUrl } from 'utils/patterns';

import Error from 'components/atoms/Error';
import { Icon } from 'components/Product/Icon';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
} from 'components/Product/shared';
import { FILES } from 'api';

import * as ST from './styles';

import { Checkbox, DropZoneBlock, T8y } from 'ui';

const CategoryPageSliderItem = ({
  values,
  control,
  index,
  setValue,
  item,
  errors,
  trigger,
  watch,
}) => {
  const {
    marketing: { titles },
    form: { notification },
  } = Lang();

  const CTAWatcher = watch(`slides[${index}].cta`);

  const handleUploadEnd = useCallback(async acceptedFiles => {
    const file = acceptedFiles && acceptedFiles[0];

    return new Promise(resolve => {
      const data = new FormData();
      data.append('file', file);
      FILES.upload(data).then(dataFromServer => {
        if (dataFromServer && dataFromServer.data) {
          resolve(dataFromServer.data);
        }
      });
    });
  }, []);

  const handleUploadMobileFiles = useCallback(
    async acceptedFiles => {
      const uploaded = await handleUploadEnd(acceptedFiles);
      setValue(`slides[${index}].srcMobile`, uploaded.url || null);
      trigger(`slides[${index}].srcMobile`);
    },
    [handleUploadEnd, index, setValue, trigger],
  );

  const handleUploadWebFiles = useCallback(
    async acceptedFiles => {
      const uploaded = await handleUploadEnd(acceptedFiles);
      setValue(`slides[${index}].srcWeb`, uploaded.url || null);
      trigger(`slides[${index}].srcWeb`);
    },
    [handleUploadEnd, index, setValue, trigger],
  );

  const handleRemoveItem = (index, isWeb) => {
    setValue(`slides[${index}].file_id_${isWeb ? 'web' : 'mobile'}`, null);
    setValue(`slides[${index}].src${isWeb ? 'Web' : 'Mobile'}`, null);
    trigger(`slides[${index}].src${isWeb ? 'Web' : 'Mobile'}`);
  };

  // Note: need to use hidden inputs
  // https://spectrum.chat/react-hook-form/help/empty-fieldarray-values~f5eb5229-c261-4ba8-a476-fb0f2429b8a0
  return (
    <ST.ItemWrapper>
      <ST.hiddenBlock>
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].serverId`}
          control={control}
          defaultValue={item.serverId}
        />
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].file_id_mobile`}
          control={control}
          defaultValue={item.file_id}
        />
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].file_id_web`}
          control={control}
          defaultValue={item.file_id}
        />
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].srcWeb`}
          control={control}
          defaultValue={item.srcWeb}
        />
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].srcMobile`}
          control={control}
          defaultValue={item.srcMobile}
        />
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].cta`}
          control={control}
          defaultValue={item.cta}
        />
        <Controller
          as={<ST.hiddenInput />}
          name={`slides[${index}].target_blank`}
          control={control}
          defaultValue={item.target_blank}
        />
      </ST.hiddenBlock>
      <ST.BlocksFieldsWrapper>
        <ST.ImageBlocksWrapper>
          <div>
            <T8y color="darkBlue" variant="t2" bold md2>
              {titles.desktopVersion}
            </T8y>
            <ST.ImageWrapper>
              {get(values, ['slides', index, 'srcWeb']) ? (
                <>
                  <img src={values.slides[index].srcWeb} alt="SlideWeb" />
                  <ST.DeleteImageIconWrapper>
                    <Icon
                      type="delete"
                      handleClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveItem(index, true);
                      }}
                    />
                  </ST.DeleteImageIconWrapper>
                </>
              ) : (
                <DropZoneBlock
                  handleUpload={handleUploadWebFiles}
                  multiple={false}
                />
              )}
            </ST.ImageWrapper>
            <ST.ImageErrorWrapper>
              <Error
                message={get(errors, ['slides', index, 'srcWeb', 'message'])}
              />
            </ST.ImageErrorWrapper>
          </div>
          <div>
            <T8y color="darkBlue" variant="t2" bold md2>
              {titles.mobileVersion}
            </T8y>
            <ST.ImageWrapper>
              {get(values, ['slides', index, 'srcMobile']) ? (
                <>
                  <img src={values.slides[index].srcMobile} alt="SlideMobile" />
                  <ST.DeleteImageIconWrapper>
                    <Icon
                      type="delete"
                      handleClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveItem(index);
                      }}
                    />
                  </ST.DeleteImageIconWrapper>
                </>
              ) : (
                <DropZoneBlock
                  handleUpload={handleUploadMobileFiles}
                  multiple={false}
                />
              )}
            </ST.ImageWrapper>
            <ST.ImageErrorWrapper>
              <Error
                message={get(errors, ['slides', index, 'srcMobile', 'message'])}
              />
            </ST.ImageErrorWrapper>
          </div>
        </ST.ImageBlocksWrapper>
        <ST.TextDataWrapper>
          <Checkbox
            label={titles.CTA}
            onChange={e => setValue(`slides[${index}].cta`, e.target.checked)}
            checked={get(values, ['slides', index, 'cta'])}
          />
          <ST.FieldsWrapper>
            <Checkbox
              label={titles.targetBlank}
              onChange={e =>
                setValue(`slides[${index}].target_blank`, e.target.checked)
              }
              checked={get(values, ['slides', index, 'target_blank'])}
              isBlocked={!CTAWatcher}
            />
            <ST.FieldWrapper>
              <FieldStyle as={FieldStyle2} legend={titles.buttonURL}>
                <Controller
                  as={<InputStyled disabled={!CTAWatcher} />}
                  name={`slides[${index}].btnUrl`}
                  control={control}
                  defaultValue={item.btnUrl}
                  rules={
                    CTAWatcher && {
                      required: notification.theFieldIsRequired,
                      validate: {
                        url: value =>
                          isUrl(value) || notification.valueShouldBeValid,
                      },
                    }
                  }
                />
              </FieldStyle>
              <Error
                message={get(errors, ['slides', index, 'btnUrl', 'message'])}
              />
            </ST.FieldWrapper>
          </ST.FieldsWrapper>
        </ST.TextDataWrapper>
      </ST.BlocksFieldsWrapper>
    </ST.ItemWrapper>
  );
};

export default CategoryPageSliderItem;
