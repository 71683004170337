export const header = {
  titles: {
    search: 'Buscar',
    logOut: 'Log Out',
    categories: 'Categorias',
    orders: 'Pedidos',
    userProfile: 'Perfil de usuário',
    products: 'Produtos',
    marketing: 'Marketing',
    notification: 'Notificação',
    productsFound: 'Produtos Encontrados',
    sortBy: 'Ordenar por',
    price: 'Preço',
    filter: 'Filtro',
    available: 'Disponível',
    allArchived: 'Todos arquivados',
    addNew: 'Adicionar novo',
    confirmDeleting: 'Confirmar exclusão',
    export: 'Exportar em CSV',
    productsOnPage: 'Produtos na página',
  },
  table: {
    productCode: 'Código do produto',
    photo: 'Foto',
    Name: 'Nome',
    price: 'Preço',
    maxQuantity: 'Quantidade máxima',
    save: 'Salvar',
    cancel: 'Cancelar',
    preview: 'Visualização',
    title: 'Título',
  },
  menu: {
    categories: 'Categorias',
    orders: 'Pedidos',
    products: 'Produtos',
    marketing: 'Marketing',
    analytics: 'Analítica',
    users: 'Usuários',
    logOut: 'Log Out',
    settings: 'Configurações',
    trainings: 'Treinamento',
    offers: 'Ofertas',
    leads: 'Leads da Web',
    tradeAccounts: 'Trade Accounts',
  },
};
