import React, { useCallback, useEffect } from 'react';
import { differenceInDays } from 'date-fns';
import { Lang } from 'lang';
import { get } from 'lodash';

import Error from 'components/atoms/Error';
import TrainingPriceField from 'components/molecules/TrainingPriceField';
import { Icon } from 'components/Product/Icon';
import {
  FieldStyle,
  FieldStyle2,
  InputStyled,
} from 'components/Product/shared';

import * as ST from './styles';

const LocationDateItem = ({
  scheduleIndex,
  register,
  dateIndex,
  setValue,
  actualFieldInfo,
  remove,
  trigger,
  errors,
  watch,
  el,
  unregister,
}) => {
  const { trainings, form, formatString } = Lang();

  const watchStartDate = watch(
    `form.schedules[${scheduleIndex}].variations[${dateIndex}].start`,
  );

  const watchEndDate = watch(
    `form.schedules[${scheduleIndex}].variations[${dateIndex}].end`,
  );

  useEffect(() => {
    register(
      {
        name: `form.schedules[${scheduleIndex}].variations[${dateIndex}].start`,
      },
      {
        required: form.notification.theFieldIsRequired,
      },
    );
    setValue(
      `form.schedules[${scheduleIndex}].variations[${dateIndex}].start`,
      el.start || null,
    );
    return () => {
      unregister(
        `form.schedules[${scheduleIndex}].variations[${dateIndex}].start`,
      );
      trigger(`form.schedules[${scheduleIndex}].variations`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, unregister, scheduleIndex, dateIndex]);

  const handleDateChange = useCallback(
    (field, value) => {
      setValue(
        `form.schedules[${scheduleIndex}].variations[${dateIndex}].${field}`,
        value,
      );
      trigger(
        `form.schedules[${scheduleIndex}].variations[${dateIndex}].${field}`,
      );
    },
    [dateIndex, scheduleIndex, setValue, trigger],
  );

  useEffect(
    () => {
      if (
        watchEndDate &&
        differenceInDays(new Date(watchEndDate), new Date(watchStartDate)) < 0
      ) {
        handleDateChange('end', watchStartDate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchStartDate, handleDateChange],
  );

  const getIsError = useCallback(
    field => {
      return errors?.form?.schedules?.[scheduleIndex]?.variations?.[
        dateIndex
      ]?.[field]?.message;
    },
    [dateIndex, errors, scheduleIndex],
  );

  const handleDeleteDate = useCallback(() => {
    remove(dateIndex);
  }, [dateIndex, remove]);

  return (
    <ST.DateWrapper>
      <ST.TitleField>
        <FieldStyle as={FieldStyle2} legend={trainings.courseVariationTitle}>
          <InputStyled
            name={`form.schedules[${scheduleIndex}].variations[${dateIndex}].title`}
            type="text"
            ref={register}
            placeholder={formatString(form.placeholders.enterTitle, 30)}
            defaultValue={actualFieldInfo?.variations[dateIndex]?.title || null}
            maxLength={30}
          />
        </FieldStyle>
      </ST.TitleField>
      <ST.DateFieldWrapper>
        <FieldStyle as={FieldStyle2} legend={trainings.startDate}>
          <ST.DatePickerStyled
            defaultValue={actualFieldInfo?.variations[dateIndex]?.start || null}
            handleDateChange={value => handleDateChange('start', value)}
            isPastDatesDisabled
          />
        </FieldStyle>
        <Error message={getIsError('start')} />
      </ST.DateFieldWrapper>
      <ST.DateFieldWrapper>
        <FieldStyle as={FieldStyle2} legend={trainings.endDate}>
          <ST.DatePickerStyled
            defaultValue={actualFieldInfo?.variations[dateIndex]?.end || null}
            handleDateChange={value => handleDateChange('end', value)}
            minDate={new Date(watchStartDate)}
          />
        </FieldStyle>
        <Error message={getIsError('end')} />
      </ST.DateFieldWrapper>
      <TrainingPriceField
        scheduleIndex={scheduleIndex}
        dateIndex={dateIndex}
        error={get(errors, [
          'form',
          'schedules',
          scheduleIndex,
          'variations',
          dateIndex,
          'price',
          'message',
        ])}
        name={`form.schedules[${scheduleIndex}].variations[${dateIndex}].price`}
        value={actualFieldInfo?.variations[dateIndex]?.price}
        register={register}
      />
      {dateIndex > 0 && (
        <ST.DatePriceRemoveIconWrapper>
          <Icon type="delete" color="gray" handleClick={handleDeleteDate} />
        </ST.DatePriceRemoveIconWrapper>
      )}
    </ST.DateWrapper>
  );
};

export default LocationDateItem;
