import React, { useCallback } from 'react';
import { useStore } from 'effector-react';
import { useOrder } from 'hooks';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import { getUser, setActualDocsToOrderUser, user$ } from 'models/users';
import { getFormattedStringDate } from 'utils/dates';

import CancelOrderModal from 'components/molecules/CancelOrderModal';
import OrderCancelRequestBlock from 'components/molecules/OrderCancelRequestBlock';
import UploadDocumentsSection from 'components/molecules/UploadDocumentsSection';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const UserOrderSmall = ({ order }) => {
  const { orders } = Lang();

  const user = useStore(user$);
  const branding = useStore(branding$);

  const onUpdateCallback = useCallback(() => {
    getUser(user.id);
  }, [user]);

  const onAddDocumentCallback = useCallback(
    (newDocs, type) => {
      setActualDocsToOrderUser({
        id: order.orderId,
        newDocs,
        type,
      });
    },
    [order],
  );

  const {
    cancelRequest,
    isCancelOrderLoading,
    setIsCancelOrderLoading,
    addDocumentToOrder,
    deleteDocumentFromOrder,
    isAddFilesBlocked,
    isAddInvoiceBlocked,
  } = useOrder(order, onAddDocumentCallback, onUpdateCallback);

  return (
    <ST.OrderWrapper>
      <Row justify="stretch" align="center">
        <Row>
          <T8y fontSize="1.6rem" bold color="primary">
            {orders.order} #{order.orderNumber}
          </T8y>
          &nbsp;-&nbsp;
          <T8y fontSize="1.6rem" bold>
            {orders.statusType[order.status] || '-'}
          </T8y>
        </Row>
        {order.status === 'new' &&
          !cancelRequest &&
          (isCancelOrderLoading ? (
            <T8y color="gray2" fontSize="14px" cursor="not-allowed">
              {orders.cancelOrder}
            </T8y>
          ) : (
            <CancelOrderModal
              userId={user.id}
              orderId={order.orderId}
              setIsCancelOrderLoading={setIsCancelOrderLoading}
              isSmall
            />
          ))}
      </Row>
      <ST.OrderInfoWrapper>
        <T8y fontSize="1.4rem" marginBottom="0.5rem">
          {orders.item.date}: {getFormattedStringDate(order.addedOn)}
        </T8y>
        <T8y fontSize="1.4rem">
          {orders.total}: {branding.currencySymbol}
          {order.grandTotal}
        </T8y>
      </ST.OrderInfoWrapper>
      {cancelRequest && order.status === 'new' && (
        <OrderCancelRequestBlock
          order={order}
          cancelRequest={cancelRequest}
          onUpdateCallback={onUpdateCallback}
          isSmall
        />
      )}

      <UploadDocumentsSection
        docs={order.docs}
        title={orders.documents}
        deleteDocumentFromOrder={deleteDocumentFromOrder}
        addDocumentToOrder={acceptedFiles =>
          addDocumentToOrder(acceptedFiles, 'docs')
        }
        isAddFilesBlocked={isAddFilesBlocked}
        isSmall
      />
      <UploadDocumentsSection
        docs={order.invoice && [order.invoice]}
        title={orders.invoice}
        deleteDocumentFromOrder={deleteDocumentFromOrder}
        addDocumentToOrder={acceptedFiles =>
          addDocumentToOrder(acceptedFiles, 'invoice')
        }
        isAddFilesHidden={isAddInvoiceBlocked}
        isSmall
      />
    </ST.OrderWrapper>
  );
};

export default UserOrderSmall;
