import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDialogState } from 'reakit';
import { Lang } from 'lang';

import Table from 'components/atoms/Table';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import { LOGS } from 'api';

import HistoryTableModal from './HistoryTableModal';

import { Spinner, T8y, TablePaging } from 'ui';

const HistoryTable = ({ type, id }) => {
  const { common } = Lang();
  const dialog = useDialogState();

  const [logs, setLogs] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lastRevisionOnPage, setLastRevisionOnPage] = useState(null);
  const [openRevision, setOpenRevision] = useState(null);

  const getLogs = useCallback(
    async (type, id, currPage, limit, callbackFunc) => {
      const { data } = await LOGS.getAllLogs(type, id, {
        limit: limit,
        page: currPage,
      });
      if (data) {
        callbackFunc(data);
      }
    },
    [],
  );

  useEffect(
    () => {
      if (id) {
        setIsLoading(true);
        try {
          getLogs(type, id, currPage, 5, setLogs);

          if (!logs.last_page || currPage < logs.last_page) {
            getLogs(type, id, currPage * 5 + 1, 1, data =>
              setLastRevisionOnPage(data.data?.[0]?.data),
            );
          } else {
            setLastRevisionOnPage(null);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, type, currPage],
  );

  const modifiedDisplayedRevisions = useMemo(
    () =>
      logs.data?.map((el, index) => ({
        number: el.revision,
        modifiedAt: el.created,
        modifiedBy: el.external_author_email,
        link: (
          <T8y
            color="primary"
            onClick={() => {
              setOpenRevision(index);
              dialog.show();
            }}
            cursor="pointer"
          >
            {common.historyTable.viewChanges}...
          </T8y>
        ),
      })) || [], // eslint-disable-next-line react-hooks/exhaustive-deps
    [common, logs],
  );

  const { currentVersion, previousVersion } = useMemo(
    () => {
      if (logs.data?.[openRevision]?.data) {
        const previousVersion =
          logs.data[openRevision + 1]?.data || lastRevisionOnPage;
        return {
          currentVersion: JSON.stringify(
            logs.data[openRevision].data,
            undefined,
            2,
          ),
          ...(previousVersion && {
            previousVersion: JSON.stringify(previousVersion, undefined, 2),
          }),
        };
      }
      return {};
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [logs, openRevision],
  );

  const headers = useMemo(() => {
    return [
      common.historyTable.revisionNo,
      common.historyTable.modifiedAt,
      common.historyTable.modifiedBy,
    ];
  }, [common]);

  const keyFields = useMemo(
    () => [
      { name: 'number', type: 'text' },
      { name: 'modifiedAt', type: 'text' },
      { name: 'modifiedBy', type: 'text' },
      { name: 'link' },
    ],
    [],
  );

  return (
    <>
      <ProductEditorBlockWrapper blockTitle={common.historyTable.title}>
        {isLoading && !modifiedDisplayedRevisions.length ? (
          <Spinner dark size={3} />
        ) : (
          <>
            <Table
              headers={headers}
              keyFields={keyFields}
              items={modifiedDisplayedRevisions}
              cols="1fr 2fr 2fr 1fr"
            />
            {logs.last_page > 1 && (
              <TablePaging
                handlePageChange={el => setCurrPage(el)}
                totalPages={logs.last_page}
                currPage={currPage}
                isScrollToTop={false}
              />
            )}
          </>
        )}
      </ProductEditorBlockWrapper>
      <HistoryTableModal
        currentVersion={currentVersion}
        previousVersion={previousVersion}
        dialog={dialog}
      />
    </>
  );
};

export default HistoryTable;
