import React, { useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import { useClosestTrainingDate } from 'hooks';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';
import { trainingLocalized$ } from 'models/trainings';

import GoogleMapsWrapper from 'components/atoms/GoogleMapsWrapper';
import ShowSection from 'components/atoms/ShowSection';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';
import { GoogleMap, Marker } from '@react-google-maps/api';

import * as ST from './styles';

import { T8y } from 'ui';

import mapMarkerActive from 'assets/icons/mapMarkerActive.svg';
import mapPlaceholder from 'assets/images/MapPlaceholder.png';

const mapStyle = {
  height: '300px',
  width: '100%',
};

const TrainingClosestDate = ({ errors, values, setValue, isNew }) => {
  const { trainings } = Lang();

  const branding = useStore(branding$);
  const trainingLocalized = useStore(trainingLocalized$);
  const closestDateInfo = useClosestTrainingDate(values?.form);

  const handleShowChange = useCallback(
    value => {
      setValue('form.closestShow', value);
    },
    [setValue],
  );

  const firstLocationLine = useMemo(() => {
    const firstLocation = `${closestDateInfo.locationNames[0]}${
      closestDateInfo.locationNames.length > 1 ? ',' : ''
    }`;
    if (closestDateInfo.organizer) {
      return `${closestDateInfo.organizer}, ${firstLocation}`;
    }

    return firstLocation;
  }, [closestDateInfo.locationNames, closestDateInfo.organizer]);

  const mapLocationsNames = useMemo(() => {
    return closestDateInfo.locationNames.slice(1);
  }, [closestDateInfo.locationNames]);

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.closestDate}
      listOfFields={['text']}
      errors={errors}
      additionalHeaderComponent={
        <ShowSection
          defaultValue={isNew || trainingLocalized?.closestShow}
          handleChange={handleShowChange}
        />
      }
    >
      <ST.InfoWrapper>
        <ST.BoldTextWrapper bold>
          {closestDateInfo.closestDate || trainings.emptyDates}
        </ST.BoldTextWrapper>
        <ST.UsualTextWrapper>
          <T8y color="gray">
            <p>
              {!!mapLocationsNames.length || closestDateInfo.organizer
                ? firstLocationLine
                : trainings.emptyLocation}
            </p>
            {!!mapLocationsNames.length && (
              <p>{mapLocationsNames.join(', ')}</p>
            )}
          </T8y>
        </ST.UsualTextWrapper>
        <ST.MapSectionWrapper>
          <GoogleMapsWrapper>
            <ST.MapWrapper>
              {!!closestDateInfo.locationAddresses.length ? (
                <GoogleMap
                  id="course map"
                  mapContainerStyle={mapStyle}
                  zoom={5}
                  center={{
                    lat: closestDateInfo.locationAddresses[0].lat,
                    lng: closestDateInfo.locationAddresses[0].lng,
                  }}
                >
                  {closestDateInfo.locationAddresses.map((el, i) => (
                    <Marker
                      key={i}
                      position={{ lat: el.lat, lng: el.lng }}
                      icon={mapMarkerActive}
                    />
                  ))}
                </GoogleMap>
              ) : (
                <img src={mapPlaceholder} alt="Empty map" />
              )}
            </ST.MapWrapper>
          </GoogleMapsWrapper>
        </ST.MapSectionWrapper>
        {!!closestDateInfo.price && (
          <>
            <ST.BoldTextWrapper bold>
              {`${branding?.currencySymbol}${closestDateInfo.price} + VAT`}
            </ST.BoldTextWrapper>
            <ST.UsualTextWrapper>
              <T8y color="gray">{values?.form?.theory || ''}</T8y>
            </ST.UsualTextWrapper>
          </>
        )}
      </ST.InfoWrapper>
      <ST.AutomaticallyText color="gray">
        {trainings.closestDateAutomaticallyGenerated}
      </ST.AutomaticallyText>
    </GeneralExpandableSection>
  );
};

export default TrainingClosestDate;
