import React, { useEffect, useMemo, useState } from 'react';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { categoriesTreeStore } from 'models/categories';
import { settings$ } from 'models/settings';

import { IconStyled } from '../../Product/shared';
import TableModalContent from '../TableModalContent';
import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

import { ReactComponent as Plus } from 'assets/images/icon/plus.svg';

const ModalWrapper = ({
  onChangeCheckbox,
  searchStr,
  onSearch,
  fieldsForTable,
  cols,
  selected,
  title,
  newProducts,
  needDisclosure = true,
  dialogState = null,
  isSelect = false,
  isDisabled,
}) => {
  const {
    form: { links, fields },
    categories: { titles },
  } = Lang();

  const innerDialog = useDialogState();

  const dialog = useMemo(() => dialogState || innerDialog, [
    dialogState,
    innerDialog,
  ]);

  useEffect(
    () => {
      if (!dialog?.visible && onSearch && searchStr) {
        onSearch({ target: { value: '' } });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog?.visible],
  );

  const disclosure = useMemo(
    () =>
      needDisclosure ? (
        <ST.AddMoreLink>
          <IconStyled as={Plus} />
          {links.addMore}
        </ST.AddMoreLink>
      ) : null,
    [links.addMore, needDisclosure],
  );

  const categories = useStore(categoriesTreeStore);
  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);

  const mainCategories = useMemo(() => {
    const newCats = categories.reduce((localizedCategories, el) => {
      if (el.level === 1) {
        localizedCategories.push({
          ...el,
          name:
            el.names[currentLangState] || el.names[settings.defaultLanguage],
        });
      }
      return localizedCategories;
    }, []);
    newCats.unshift({
      name: titles.allCategories,
      slug: 0,
      id: 0,
    });

    return newCats;
  }, [
    categories,
    currentLangState,
    settings.defaultLanguage,
    titles.allCategories,
  ]);

  const [modifiedProducts, setModifiedProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    let currProducts = [...newProducts];

    if (selectedCategory && selectedCategory.id) {
      currProducts = currProducts.filter(
        el => el[2] && el[2].value === selectedCategory.name,
      );
    }

    setModifiedProducts(currProducts);
  }, [newProducts, selectedCategory]);

  return (
    <Modal
      disclosure={disclosure}
      title={title}
      dialogState={dialog}
      width="80%"
      minHeight="70vh"
      overflow="hidden"
      backgroundColor={colors.primary}
      lazyRender
    >
      <TableModalContent
        searchStr={searchStr}
        onSearch={onSearch}
        cols={cols}
        categoriesTree={mainCategories}
        selectedCategory={selectedCategory}
        selected={selected}
        onChangeCheckbox={onChangeCheckbox}
        fields={fields}
        products={modifiedProducts}
        fieldsForTable={fieldsForTable}
        handleSelectCategory={setSelectedCategory}
        isSelect={isSelect}
        isDisabled={isDisabled}
      />
    </Modal>
  );
};

export default ModalWrapper;
