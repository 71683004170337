import styled from 'styled-components';

export const ShowSectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioWrapper = styled.div`
  display: flex;

  & label:first-child {
    margin-right: 22px;
  }
`;
