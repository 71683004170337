import { getOrderDates } from './dates';

export const getDisplayedOrderDates = products => {
  try {
    const dates = getOrderDates(products);

    if (dates.startDate) {
      return dates.startDate === dates.endDate
        ? dates.startDate
        : `${dates.startDate} - ${dates.endDate}`;
    }
    return '-';
  } catch (e) {
    console.warn(e);
    return '-';
  }
};
