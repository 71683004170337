import React, { useMemo } from 'react';
import { Lang } from 'lang';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

const IntegrationStatusModal = ({
  integration,
  dialog,
  handleCloseClick,
  handleConnectClick,
  integrationInfoResponse,
  isLoadingData,
}) => {
  const {
    product: { thirdPartyIntegrations },
    form,
  } = Lang();

  const modalFields = useMemo(
    () => [
      {
        fieldTitle: 'Id',
        fieldName: 'serialNo',
      },
      {
        fieldTitle: thirdPartyIntegrations.externalProductId,
        fieldName: 'no',
      },
      {
        fieldTitle: thirdPartyIntegrations.productDescription,
        fieldName: 'description',
      },
      {
        fieldTitle: thirdPartyIntegrations.locationCode,
        fieldName: 'locationCode',
      },
    ],
    [thirdPartyIntegrations],
  );

  return (
    <Modal
      title={thirdPartyIntegrations.successConnectModalTitle}
      dialogState={dialog}
      width={620}
      onCloseCallback={handleCloseClick}
      backgroundColor={colors.primary}
      lazyRender
    >
      <div>
        <ST.ResponseWrapper>
          {integration.systemName} {thirdPartyIntegrations.response}
        </ST.ResponseWrapper>
        {integrationInfoResponse.error ? (
          <>
            <ST.ErrorWrapper>
              {thirdPartyIntegrations.error}: {integrationInfoResponse.error}
            </ST.ErrorWrapper>
            <ST.ModalControlsWrapper>
              <ST.CancelBtn onClick={handleCloseClick} loading={isLoadingData}>
                {form.buttons.close}
              </ST.CancelBtn>
            </ST.ModalControlsWrapper>
          </>
        ) : (
          <>
            {modalFields.map((el, i) => (
              <ST.ProductInfoRowWrapper key={i}>
                <ST.ProductInfoTitle>
                  {el.fieldTitle}: &nbsp;
                </ST.ProductInfoTitle>
                <span>{integrationInfoResponse[el.fieldName]}</span>
              </ST.ProductInfoRowWrapper>
            ))}
            <ST.ModalControlsWrapper>
              <ST.CancelBtn onClick={handleCloseClick} loading={isLoadingData}>
                {form.buttons.cancel}
              </ST.CancelBtn>
              <ST.ConnectBtn onClick={handleConnectClick}>
                {thirdPartyIntegrations.okConnect}
              </ST.ConnectBtn>
            </ST.ModalControlsWrapper>
          </>
        )}
      </div>
    </Modal>
  );
};

export default IntegrationStatusModal;
