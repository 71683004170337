import { useCallback, useEffect, useMemo, useState } from 'react';

export const usePagination = ({
  range = 5,
  totalPages,
  currPage,
  handlePageChange,
  isScrollToTop,
}) => {
  const [currRenderItems, setCurrRenderItems] = useState(1);

  const totalPossiblePages = useMemo(() => {
    if (range > totalPages) {
      return totalPages;
    }

    return range;
  }, [range, totalPages]);

  useEffect(() => {
    setCurrRenderItems(totalPossiblePages);
  }, [totalPossiblePages]);

  const visiblePages = useMemo(() => {
    const isBackToTheStart = totalPossiblePages > currRenderItems;
    return new Array(totalPossiblePages)
      .fill(null)
      .map((el, index) =>
        isBackToTheStart ? totalPossiblePages - index : currRenderItems - index,
      )
      .reverse();
  }, [currRenderItems, totalPossiblePages]);

  const scrollTop = useCallback(() => {
    if (isScrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isScrollToTop]);

  const handleClickPreviousArrow = useCallback(
    () => {
      if (currPage !== 1) {
        handlePageChange(currPage - 1);
      }
      if (
        currPage === visiblePages[0] &&
        currRenderItems !== totalPossiblePages
      ) {
        setCurrRenderItems(
          currRenderItems - totalPossiblePages <= 0
            ? 1
            : currRenderItems - totalPossiblePages,
        );
      }
      scrollTop();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currPage, visiblePages],
  );

  const handleClickNextArrow = useCallback(
    () => {
      if (currPage !== totalPages) {
        handlePageChange(currPage + 1);
      }
      if (currPage === visiblePages[totalPossiblePages - 1]) {
        setCurrRenderItems(
          currRenderItems + totalPossiblePages > totalPages
            ? totalPages
            : currRenderItems + totalPossiblePages,
        );
      }
      scrollTop();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currPage, visiblePages],
  );

  const handleClickOnPage = useCallback(
    el => {
      handlePageChange(el);
      scrollTop();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollTop],
  );

  return useMemo(
    () => ({
      handleClickPreviousArrow,
      handleClickNextArrow,
      handleClickOnPage,
      visiblePages,
    }),
    [
      handleClickNextArrow,
      handleClickOnPage,
      handleClickPreviousArrow,
      visiblePages,
    ],
  );
};
