import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import {
  safetyRecommendationsGate,
  safetyRecommendationsLocalized$,
} from 'models/safetyRecommendations';
import { settings$ } from 'models/settings';

import { FieldStyle } from '../../Product/shared';
import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import * as ST from './styles';

import { inputStyle, Row, Select, T8y } from 'ui';

const ProductSafetyRecommendations = ({
  register,
  currentSafetyRecommendations = [],
  setValues,
}) => {
  useGate(safetyRecommendationsGate);
  const { product, form } = Lang();

  const [selectedRecommendation, setSelectedRecommendation] = useState();

  const currentLangState = useStore(currentLang$);
  const settings = useStore(settings$);
  const safetyRecommendations = useStore(safetyRecommendationsLocalized$);

  const availableForAddingRecommendations = useMemo(
    () =>
      safetyRecommendations.filter(
        el => !currentSafetyRecommendations.some(item => item.id === el.id),
      ),
    [safetyRecommendations, currentSafetyRecommendations],
  );

  const handleChange = useCallback(
    (option, availableForAddingRecommendations) => {
      if (option) {
        setSelectedRecommendation(
          availableForAddingRecommendations.find(el => el.id === option),
        );
      }
    },
    [setSelectedRecommendation],
  );

  const addRecommendation = useCallback(
    selectedRecommendations => {
      if (!selectedRecommendation) {
        return;
      }
      const newRecommendations = [...selectedRecommendations];

      const newSafetyItem = { ...selectedRecommendation };

      delete newSafetyItem.name;

      newRecommendations.push(newSafetyItem);

      setValues('safetyRecommendations', newRecommendations, {
        shouldDirty: true,
      });

      setSelectedRecommendation(null);
    },
    [selectedRecommendation, setValues],
  );

  const deleteRecommendation = useCallback(
    (id, selectedRecommendations) => {
      const newRecommendations = [...selectedRecommendations];
      const indexDeleteItem = newRecommendations.findIndex(el => el.id === id);
      newRecommendations.splice(indexDeleteItem, 1);
      setValues('safetyRecommendations', newRecommendations, {
        shouldDirty: true,
      });
    },
    [setValues],
  );

  useEffect(() => {
    register('safetyRecommendations');
  }, [register]);

  return (
    <ProductEditorBlockWrapper
      blockTitle={product.titles.safetyRecommendations}
    >
      <ST.SafetyRecommendationsWrapper align="end">
        <FieldStyle as={ST.SafetyRecommendationsFields} withBorder>
          <Select
            options={availableForAddingRecommendations}
            defaultText={
              product.safetyRecommendations.selectSafetyRecommendation
            }
            selected={selectedRecommendation?.name}
            onClickOption={e =>
              handleChange(e, availableForAddingRecommendations)
            }
            className={inputStyle}
          />
        </FieldStyle>
        <FieldStyle as={ST.SafetyRecommendationsFields} withBorder>
          <ST.AddRecommendationBtn
            onClick={() => addRecommendation(currentSafetyRecommendations)}
            backgroundColor="positive"
            text={form.buttons.add}
          />
        </FieldStyle>
      </ST.SafetyRecommendationsWrapper>
      <T8y
        color="gray"
        marginBottom={currentSafetyRecommendations.length ? '18px' : undefined}
        fontSize="16px"
      >
        {product.safetyRecommendations.listOfSafetyRecommendations} (
        {currentSafetyRecommendations.length})
      </T8y>
      {currentSafetyRecommendations.map(el => (
        <ST.RecommendationItem
          align="center"
          justify="stretch"
          key={el.id}
          noWrap
        >
          <Row align="center" noWrap>
            <ST.RecommendationIcon src={el.icon_url} alt="icon" />
            <T8y fontSize="16px">
              {el.names[currentLangState] ||
                el.names[settings?.defaultLanguage]}
            </T8y>
          </Row>
          <ST.DeleteIcon
            onClick={() =>
              deleteRecommendation(el.id, currentSafetyRecommendations)
            }
          />
        </ST.RecommendationItem>
      ))}
    </ProductEditorBlockWrapper>
  );
};

export default ProductSafetyRecommendations;
