import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Cell, Icon } from 'ui';

export const StyledGrid = styled.div`
  margin-bottom: 2rem;
`;

export const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
`;

export const TableBody = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  padding: 4rem 3rem;
  background: #fff;
  margin-bottom: 5rem;
  font-size: 1.4rem;
  color: #424242;
  display: flex;
  align-items: center;

  ${p =>
    p.flexDirection &&
    `
    flex-direction: ${p.flexDirection};
  `}
`;

export const TableHeader = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;

  ${p =>
    p.marginTop &&
    `
    margin-top: ${p.marginTop};
  `}
`;

export const TableHeaderCell = styled(Cell)`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.textGray};
`;

export const TableBodyCell = styled(Cell)`
  font-size: 1.4rem;
  color: ${colors.inputColor};
`;

export const TableImage = styled.img`
  max-width: 3.7rem;
  max-height: 3.7rem;
`;

export const DeleteIconStyled = styled(Icon)`
  cursor: pointer;

  path {
    fill: ${colors.textGray};
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;
