import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  availableCategoriesLocalized$,
  categoriesTreeStore,
  categoryParentIDStore,
  getCategoriesTree,
  setCategoryParentID,
} from 'models/categories';
import { settings$ } from 'models/settings';

import styled from 'styled-components';

import Error from '../atoms/Error';
import {
  FieldStyle,
  FieldStyle2,
  Full,
  InputStyled,
  TextareaStyled,
} from '../Product/shared';

import { colors } from 'styleGuide';
import { Cell, Grid, inputStyle, Row, Select } from 'ui';

const GeneralTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

const GeneralBody = styled.div`
  width: 100%;
  background: #fff;
  padding: 2.5rem 3rem 3rem;
  border-radius: 0.2rem;

  ${p =>
    p.marginTop &&
    `
    margin-top: ${p.marginTop};
  `}

  ${p =>
    p.marginBottom &&
    `
    margin-bottom: ${p.marginBottom};
  `}
`;

const DescriptionArea = styled(TextareaStyled)`
  resize: vertical;
`;

export const General = ({
  register,
  isNewCategory,
  setValue,
  errors,
  values,
}) => {
  const {
    product: { titles },
    form: { fields, defaultText, notification },
  } = Lang();

  const categoryParentID = useStore(categoryParentIDStore);
  const categories = useStore(categoriesTreeStore);
  const availableCategoriesLocalized = useStore(availableCategoriesLocalized$);
  const settings = useStore(settings$);

  useEffect(() => {
    if (!categories || !categories.length) {
      getCategoriesTree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeName = useCallback(
    (value, language) => {
      setValue(`names.${language}`, value);
      if (isNewCategory) {
        setValue(`seo_h1.${language}`, value);
      }
    },
    [isNewCategory, setValue],
  );

  const options = useMemo(() => {
    const res = [{ name: defaultText.noParent, slug: 0 }];
    if (!availableCategoriesLocalized || !availableCategoriesLocalized.length) {
      return res;
    }

    availableCategoriesLocalized.forEach(el => {
      if (el.level !== 3 && (!values?.id || el.id !== values.id)) {
        res.push({
          name: `${el.level === 2 ? ' - ' : ''} ${el.name || ''}`,
          slug: el.id,
        });
      }
    });

    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCategoriesLocalized, values?.id]);

  const selectedObj = useMemo(() => {
    return options.find(i => i.slug === categoryParentID);
  }, [categoryParentID, options]);

  const selected = useMemo(() => {
    return selectedObj ? selectedObj.name : null;
  }, [selectedObj]);

  return (
    <Fragment>
      <GeneralTitle>{titles.generalInformation}</GeneralTitle>
      <GeneralBody marginBottom="4.7rem">
        <Grid gaps="1.5rem 3rem" cols="2fr 2fr 1fr" places="center start">
          {settings.supportedLanguages.map((el, index) => (
            <Cell as={Full} area="/ span 3" key={index}>
              <Row direction="column" fullWidth justify="start">
                <FieldStyle as={FieldStyle2} legend={`${fields.title} ${el}`}>
                  <InputStyled
                    name={`names.${el}`}
                    type="text"
                    ref={register(
                      el === settings.defaultLanguage && {
                        validate: value =>
                          !!value?.trim() || notification.theFieldIsRequired,
                      },
                    )}
                    onChange={e => changeName(e.target.value, el)}
                    defaultValue={values?.names?.[el] || null}
                  />
                </FieldStyle>
                <Error
                  message={
                    errors &&
                    errors.names &&
                    errors.names[el] &&
                    errors.names[el].message
                  }
                />
              </Row>
            </Cell>
          ))}
          {settings.supportedLanguages.map((el, index) => (
            <Cell as={Full} area="/ span 3" key={index}>
              <Row direction="column" fullWidth justify="start">
                <FieldStyle
                  as={FieldStyle2}
                  legend={`${fields.h1Tag} ${el}`}
                  marginTop="1.5rem"
                >
                  <InputStyled
                    name={`seo_h1.${el}`}
                    type="text"
                    ref={register}
                    onChange={e => {
                      setValue(`seo_h1.${el}`, e.target.value);
                    }}
                    defaultValue={values?.seo_h1?.[el] || null}
                  />
                </FieldStyle>
              </Row>
            </Cell>
          ))}
          <Cell as={Full} area="/ span 3">
            <FieldStyle
              as={FieldStyle2}
              legend={fields.parentCategory}
              withBorder={true}
            >
              <input
                type="hidden"
                name="parent_id"
                ref={register}
                value={categoryParentID}
              />
              <Select
                options={options}
                selected={selected}
                defaultText={defaultText.selectCategory}
                aLabel="Category"
                onClickOption={setCategoryParentID}
                className={inputStyle}
                maxHeight="200px"
              />
            </FieldStyle>
          </Cell>
          {settings.supportedLanguages.map((el, index) => (
            <Cell as={Full} area="/ span 3" key={index}>
              <FieldStyle
                as={FieldStyle2}
                legend={`${fields.description} ${el}`}
              >
                <DescriptionArea
                  name={`descriptions.${el}`}
                  rows="4"
                  ref={register}
                  onChange={e => {
                    setValue(`descriptions.${el}`, e.target.value);
                  }}
                  defaultValue={values?.descriptions?.[el] || null}
                />
              </FieldStyle>
            </Cell>
          ))}
        </Grid>
      </GeneralBody>
    </Fragment>
  );
};
