import React from 'react';
import { Lang } from 'lang';

import CustomButton from 'components/atoms/CustomButton';

import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal, T8y } from 'ui';

const DeliveryDeleteConfirmationModal = ({
  disclosure,
  dialogState,
  deliveryName,
  handleDeletingConfirmation,
  handleModalClose,
  loading,
}) => {
  const {
    formatString,
    form: { buttons },
    settings,
  } = Lang();

  return (
    <>
      <Modal
        disclosure={disclosure}
        title={settings.modals.deleteConfirmationTitle}
        dialogState={dialogState}
        width="420px"
        overflow="hidden"
        backgroundColor={colors.primary}
        lazyRender
      >
        <T8y lineHeight="22px" marginTop="30px">
          {formatString(
            settings.modals.deleteConfirmationText,
            <T8y lineHeight="22px" display="inline-block" bold>
              {deliveryName}
            </T8y>,
          )}
        </T8y>
        <ST.DeliveryConfirmationModalControlsWrapper>
          <CustomButton
            text={buttons.cancel}
            backgroundColor="positive"
            color="gray3"
            width="120px"
            height="29px"
            border="1px solid #D0D0D0"
            colorHovered="gray"
            disabled={loading}
            onClick={handleModalClose}
          />
          <CustomButton
            text={buttons.delete}
            backgroundColor="positive"
            color="gray3"
            border="1px solid #F89739"
            colorHovered="gray"
            width="120px"
            height="29px"
            darkSpinner
            isLoading={loading}
            onClick={handleDeletingConfirmation}
          />
        </ST.DeliveryConfirmationModalControlsWrapper>
      </Modal>
    </>
  );
};

export default DeliveryDeleteConfirmationModal;
