import React from 'react';
import { useHistory } from 'react-router-dom';

import * as ST from './styles';

const Tabs = ({ steps, marginBottom }) => {
  const history = useHistory();

  return (
    <ST.TabsWrapper
      justify="center"
      smDirection="column"
      $marginBottom={marginBottom}
    >
      {steps?.map((el, i) => {
        const isActive =
          history.location.pathname +
            (history.location.search ? history.location.search : '') ===
          el.path;

        return (
          <ST.TabWrapper
            key={i}
            to={el.path}
            onClick={e => el.isDisabled && e.preventDefault()}
            $isActive={isActive}
            $isDisabled={el.isDisabled}
          >
            <ST.TabText
              fontSize="18px"
              center
              $isActive={isActive}
              $isDisabled={el.isDisabled}
            >
              {el.label}
            </ST.TabText>
          </ST.TabWrapper>
        );
      })}
    </ST.TabsWrapper>
  );
};

export default Tabs;
