import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';

import { TRANSPORTATION } from 'api';

export const DeliveriesGate = createGate('deliveries');

const getFormattedDeliveries = data => {
  return data.data.map(el => {
    const copyEl = { ...el };

    if (el.deliveryPerArea?.data) {
      copyEl.deliveryPerArea = el.deliveryPerArea.data;
    }

    if (el.deliveryPerMile?.data) {
      copyEl.deliveryPerMile = el.deliveryPerMile.data;
    }

    if (el.deliveryPerPostcode?.data) {
      copyEl.deliveryPerPostcode = el.deliveryPerPostcode.data;
    }

    if (el.deliveryByRadius?.data) {
      copyEl.deliveryByRadius = el.deliveryByRadius.data;
    }

    delete copyEl['id'];
    return { ...copyEl, serverId: el.id };
  });
};

export const getAllDeliveriesFx = createEffect({
  handler: async () => {
    const { data } = await TRANSPORTATION.getAllDeliveries();

    return getFormattedDeliveries(data);
  },
});

export const updateAllDeliveriesFx = createEffect({
  handler: async params => {
    const { data } = await TRANSPORTATION.updateAllDeliveries(params);

    return getFormattedDeliveries(data);
  },
});

export const getDeliveries = createEvent();

export const deliveries$ = restore(getAllDeliveriesFx.doneData, []).on(
  updateAllDeliveriesFx.doneData,
  (s, p) => p,
);

export const filteredDeliveries$ = createStore([]).on(
  deliveries$.updates,
  (s, p) => p,
);

export const isDeliveriesLoading$ = restore(getAllDeliveriesFx.pending, false);

forward({
  from: getDeliveries,
  to: getAllDeliveriesFx,
});

guard({
  source: DeliveriesGate.open,
  filter: deliveries$ => !deliveries$.length,
  target: getAllDeliveriesFx,
});

export const deleteDeliveryFx = createEffect({
  handler: async id => {
    const { data } = await TRANSPORTATION.deleteDelivery(id);

    return data.data;
  },
});

export const getShortDeliveries = createEvent();

const getShortDeliveriesFx = createEffect({
  handler: async () => {
    const { data } = await TRANSPORTATION.getShortDeliveries();

    return data.data || [];
  },
});

export const shortDeliveries$ = restore(getShortDeliveriesFx, []);

forward({
  from: getShortDeliveries,
  to: getShortDeliveriesFx,
});
