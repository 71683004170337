import styled from 'styled-components';

export const StyledError = styled.p`
  display: inline-block;
  color: #ef3d46;
  height: ${props => props.height || '10px'};
  font-size: ${props => props.fontSize || '12px'};
  font-weight: 500;
  min-height: 10px;
  line-height: ${props => props.height || '10px'};
  margin-top: 4px;
`;
