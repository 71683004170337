import styled from 'styled-components';

export const OrderInfoBlock = styled.div`
  ${p =>
    p.withTopBorder &&
    `
    width:100%;
    max-width: calc(50% - 20px);
    flex-grow:1;
    padding-top: 12px;
    border-top: 1px solid #EEEEEE;
    overflow: hidden;
`}

  ${p => p.$mb && `margin-bottom:${p.$mb}; margin-right:20px;`}
  ${p => p.$width && `width:${p.$width};`}
  ${p => p.short && '&:not(:last-child) {margin-right:20px;};'}
`;
