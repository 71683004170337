import styled from 'styled-components';

import { colors } from 'styleGuide';

export const OrderWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 25px 30px 30px;
  border-radius: 2px;
  background-color: ${colors.positive};
`;

export const OrderInfoWrapper = styled.div`
  margin: 10px 0;
`;
