import React, { useMemo } from 'react';
import { Lang } from 'lang';

import { OrderInfoWrapper } from 'components/atoms/OrderInfoWrapper/styles';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const LeadInfoCRMLinks = ({ lead }) => {
  const { leads } = Lang();

  const crmLink = useMemo(
    () =>
      window.location.hostname.includes('stage')
        ? 'https://stage-easycrm.myfreshworks.com/crm/sales/'
        : 'https://easycrm.myfreshworks.com/crm/sales/',
    [],
  );

  return (
    <OrderInfoWrapper>
      <ST.OrderInfoCrmLink>
        <T8y color="darkBlue" fontSize="20px" marginBottom="6px" bold>
          {leads.crmDeal} #{lead.fw_deal_id}
        </T8y>
        {lead.fw_deal_id ? (
          <Row
            as="a"
            href={`${crmLink}deals/${lead.fw_deal_id}`}
            target="_blank"
            rel="noopener noreferrer"
            align="center"
            noWrap
          >
            <T8y fontSize="16px" color="primary" marginRight="8px">
              {`${crmLink}deals/${lead.fw_deal_id}`}
            </T8y>
            <ST.LinkIconStyled />
          </Row>
        ) : (
          <T8y fontSize="16px" marginRight="8px">
            N/A
          </T8y>
        )}
      </ST.OrderInfoCrmLink>
      <ST.OrderInfoCrmLink>
        <T8y color="darkBlue" fontSize="20px" marginBottom="6px" bold>
          {leads.crmContact} #{lead.fw_contact_id}
        </T8y>
        {lead.fw_contact_id ? (
          <Row
            as="a"
            href={`${crmLink}contacts/${lead.fw_contact_id}`}
            target="_blank"
            rel="noopener noreferrer"
            align="center"
            noWrap
          >
            <T8y fontSize="16px" color="primary" marginRight="8px">
              {`${crmLink}contacts/${lead.fw_contact_id}`}
            </T8y>
            <ST.LinkIconStyled />
          </Row>
        ) : (
          <T8y fontSize="16px" marginRight="8px">
            N/A
          </T8y>
        )}
      </ST.OrderInfoCrmLink>
      <ST.OrderInfoCrmLink>
        <T8y color="darkBlue" fontSize="20px" marginBottom="6px" bold>
          {leads.stripePaymentLink}
        </T8y>
        {lead.paymentLink ? (
          <Row
            as="a"
            href={lead.paymentLink}
            target="_blank"
            rel="noopener noreferrer"
            align="center"
            noWrap
          >
            <T8y fontSize="16px" color="primary" marginRight="8px">
              {lead.paymentLink}
            </T8y>
            <ST.LinkIconStyled />
          </Row>
        ) : (
          <T8y fontSize="16px" marginRight="8px">
            N/A
          </T8y>
        )}
      </ST.OrderInfoCrmLink>
      <ST.OrderInfoCrmLink>
        <T8y color="darkBlue" fontSize="20px" marginBottom="6px" bold>
          {leads.shuftiVerificationLink}
        </T8y>
        {lead.verificationLink ? (
          <Row
            as="a"
            href={lead.verificationLink}
            target="_blank"
            rel="noopener noreferrer"
            align="center"
            noWrap
          >
            <T8y fontSize="16px" color="primary" marginRight="8px">
              {lead.verificationLink}
            </T8y>
            <ST.LinkIconStyled />
          </Row>
        ) : (
          <T8y fontSize="16px" marginRight="8px">
            N/A
          </T8y>
        )}
      </ST.OrderInfoCrmLink>
      <ST.OrderInfoCrmLink>
        <T8y color="darkBlue" fontSize="20px" marginBottom="6px" bold>
          Two order link
        </T8y>
        {lead.loanLink ? (
          <Row
            as="a"
            href={lead.loanLink}
            target="_blank"
            rel="noopener noreferrer"
            align="center"
            noWrap
          >
            <T8y fontSize="16px" color="primary" marginRight="8px">
              {lead.loanLink}
            </T8y>
            <ST.LinkIconStyled />
          </Row>
        ) : (
          <T8y fontSize="16px" marginRight="8px">
            N/A
          </T8y>
        )}
      </ST.OrderInfoCrmLink>
    </OrderInfoWrapper>
  );
};

export default LeadInfoCRMLinks;
