export const common = {
  dropZoneErrors: {
    'file-too-large': 'La imagen ha de pesar menos de {0} MB',
  },
  enabledRobotsTag: 'Habilitar la edición del campo Etiqueta de robots',

  forgotPassword: {
    header: '¿Has olvidado tu contraseña?',
    customerHeader: 'Admin Panel Contraseña Olvidada',
    subHeader:
      'Introduce la dirección de correo electrónico que has usado para registrarte y te enviaremos un enlace para restablecer la contraseña',
    emailFieldTitle: 'Dirección de correo electrónico',
    emailFieldPlaceholder: 'Introduce tu dirección de correo electrónico',
    goToLogin: 'Ir a Inicio de Sesión, he recordado mi contraseña',
    submit: 'Enviar',
    sent: 'El mensaje ha sido enviado, por favor revisa tu bandeja de entrada',
    cancel: 'Cancelar',
  },

  resetPassword: {
    header: 'Restablecer la contraseña',
    customerHeader: 'Admin Panel Nueva Contraseña',
    subHeader: 'Por favor introduce tu nueva contraseña aquí debajo',
    emailAddressTitle: 'Dirección de correo electrónico',
    passwordFieldTitle: 'Nueva contraseña',
    passwordFieldPlaceholder: 'Introduce tu contraseña',
    repeatPasswordFieldTitle: 'Repite tu contraseña',
    repeatPasswordFieldPlaceholder: 'Re-introduce tu contraseña',
    submit: 'Enviar',
    saveChanges: 'Guardar cambios',
  },

  historyTable: {
    title: 'Historial',
    revisionNo: 'Número de revisión',
    modifiedAt: 'Modificado en',
    modifiedBy: 'Modificado por',
    viewChanges: 'Ver cambios',
    changes: 'Cambios',
    previousVersion: 'Versión anterior',
    currentVersion: 'Versión actual',
    expandLines: 'Expandir {0} líneas',
  },

  draft: 'Borrador',
  new: 'Nuevo',

  calendar: {
    weekDays: {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    general: 'General',
    timezone: 'Zona horaria',
    defaultHirePeriod: 'Periodo de alquiler predefinido (días)',
    sameDayDelivery: 'Entrega el mismo dia',
    disableSameDayDelivery: 'Desactivar entrega el mismo dia',
    holidays: 'Festivos',
    month: 'Mes',
    comment: 'Comentario (opcional)',
    selectDay: 'Selecciona día',
    selectMonth: 'Selecciona mes',
    addNewHoliday: 'Agrega nuevo festivo',
    weekends: 'Fines de semana',
    deliverySelfCollection: 'Para entrega y recogida',
    tickDays:
      'Selecciona los días que se marcarán en el calendario como no disponibles para el inicio o el fin del alquiler',
    nextDayBehaviour: 'Comportamiento del día siguiente',
    dayOfWeek: 'Día de la semana',
    offset: 'Diferencia (días)',
    enterNumber: 'Ingresa número',
    addNewBehaviour: 'Agrega nuevo comportamiento',
    sameDayHire: 'Alquilar el mismo dia',
    allowSetToday: 'Activar “hoy” como fecha de inicio',
    till: 'hasta',
  },
};
