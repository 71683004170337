import styled from 'styled-components';

import { colors } from 'styleGuide';

export const BlockTitle = styled.div`
  font-weight: 900;
  font-size: 1.8rem;
  color: ${colors.darkBlue};
  margin-bottom: 2.2rem;
`;

export const BlockBody = styled.div`
  width: 100%;
  margin-bottom: 4.7rem;

  ${p =>
    !p.isNoBackground &&
    `
    background: #fff;
    padding: 2.5rem 3rem 3rem;
    border-radius: 0.2rem;
  `}
`;
