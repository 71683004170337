export const analytics = {
  titles: {
    dataStudio: 'DataStudio',
    monthlyReports: 'Reportes',
  },

  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Setiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
};
