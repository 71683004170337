import React, { useMemo, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en';
import es from 'react-phone-number-input/locale/es';
import it from 'react-phone-number-input/locale/it';
import { DEFAULT_PHONE_VALIDATION } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { website$ } from 'models/website';

import * as ST from './styles';

import 'react-phone-number-input/style.css';

const PhoneInputWithCountryInForm = ({
  placeholder,
  value,
  setValue,
  control,
  name,
  ...rest
}) => {
  const { formatString, form } = Lang();

  const website = useStore(website$);

  const [phoneMaskCountry, setPhoneMaskCountry] = useState(
    DEFAULT_PHONE_VALIDATION[website.shortCompanyName],
  );

  const labels = useMemo(() => {
    switch (phoneMaskCountry) {
      case 'GB':
        return en;
      case 'ES':
        return es;
      case 'IT':
        return it;
      default:
        return en;
    }
  }, [phoneMaskCountry]);

  return (
    <ST.PhoneCountryInput
      name={name || 'phone'}
      rules={{
        validate: {
          minLength: value =>
            value?.trim().length > 5 ||
            formatString(form.notification.minLength, 6),
          rightLength: value => {
            return (
              isPossiblePhoneNumber(value || '') ||
              form.notification.phonePatter
            );
          },
        },
      }}
      defaultValue={value}
      control={control}
      type="tel"
      defaultCountry={phoneMaskCountry}
      labels={labels}
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      autoComplete="shipping tel"
      onCountryChange={setPhoneMaskCountry}
      {...rest}
      international
      limitMaxLength
    />
  );
};

export default PhoneInputWithCountryInForm;
