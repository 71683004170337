import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';

import Stepper from 'components/molecules/Tabs';
import EnquiryForm from 'components/organisms/EnquiryForm';
import FAQs from 'components/organisms/FAQs';
import HeroImages from 'components/organisms/HeroImages';
import JobTypes from 'components/organisms/JobTypes';
import PopularCategories from 'components/organisms/PopularCategories';
import PopularProducts from 'components/organisms/PopularProducts';
import PromoCodes from 'components/organisms/PromoCodes';

const Marketing = () => {
  const {
    marketing: { titles },
  } = Lang();

  const steps = useMemo(
    () => [
      {
        label: titles.heroImages,
        path: PATHS.HERO_IMAGES,
      },
      {
        label: titles.popularProducts,
        path: PATHS.POPULAR_PRODUCTS,
      },
      {
        label: titles.enquiryForm,
        path: PATHS.ENQUIRY,
      },
      {
        label: titles.popularCategories,
        path: PATHS.POPULAR_CATEGORIES,
      },
      {
        label: titles.promoCodes,
        path: PATHS.PROMO_CODES,
      },
      {
        label: titles.jobTypes,
        path: PATHS.JOB_TYPES,
      },
      {
        label: titles.taFaqs,
        path: PATHS.TA_FAQS,
      },
    ],
    [titles],
  );

  return (
    <>
      <Stepper steps={steps} />
      <React.Suspense fallback={<div> Loading ...</div>}>
        <Switch>
          <Route path={PATHS.HERO_IMAGES} component={HeroImages} />
          <Route path={PATHS.POPULAR_PRODUCTS} component={PopularProducts} />
          <Route path={PATHS.ENQUIRY} component={EnquiryForm} />
          <Route
            path={PATHS.POPULAR_CATEGORIES}
            component={PopularCategories}
          />
          <Route path={PATHS.PROMO_CODES} component={PromoCodes} />
          <Route path={PATHS.JOB_TYPES} component={JobTypes} />
          <Route path={PATHS.TA_FAQS} component={FAQs} />
          <Redirect to={PATHS.HERO_IMAGES} />
        </Switch>
      </React.Suspense>
    </>
  );
};

export default Marketing;
