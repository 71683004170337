import React from 'react';
import { Lang } from 'lang';
import { get } from 'lodash';

import TrainingPriceField from 'components/molecules/TrainingPriceField';

import { Icon } from '../../Product/Icon';
import { FieldStyle, FieldStyle2, InputStyled } from '../../Product/shared';
import * as ST from './styles';

const LocationPriceOnlyItem = ({
  scheduleIndex,
  register,
  actualFieldInfo,
  remove,
  errors,
  priceOnlyIndex,
}) => {
  const { trainings, form, formatString } = Lang();

  return (
    <ST.DateWrapper>
      <ST.TitleField>
        <FieldStyle as={FieldStyle2} legend={trainings.courseVariationTitle}>
          <InputStyled
            name={`form.schedules[${scheduleIndex}].variationsPricesOnly[${priceOnlyIndex}].title`}
            type="text"
            ref={register}
            maxLength={64}
            placeholder={formatString(form.placeholders.enterTitle, 30)}
            defaultValue={
              actualFieldInfo?.variationsPricesOnly[priceOnlyIndex]?.title ||
              null
            }
          />
        </FieldStyle>
      </ST.TitleField>
      <TrainingPriceField
        scheduleIndex={scheduleIndex}
        name={`form.schedules[${scheduleIndex}].variationsPricesOnly[${priceOnlyIndex}].price`}
        error={get(errors, [
          'form',
          'schedules',
          scheduleIndex,
          'variationsPricesOnly',
          priceOnlyIndex,
          'price',
          'message',
        ])}
        value={actualFieldInfo?.variationsPricesOnly[priceOnlyIndex]?.price}
        register={register}
      />
      {priceOnlyIndex > 0 && (
        <ST.DatePriceRemoveIconWrapper>
          <Icon
            type="delete"
            color="gray"
            handleClick={() => remove(priceOnlyIndex)}
          />
        </ST.DatePriceRemoveIconWrapper>
      )}
    </ST.DateWrapper>
  );
};

export default LocationPriceOnlyItem;
