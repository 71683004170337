import styled from 'styled-components';

import { colors } from 'styleGuide';

export const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  gap: 0;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
  column-gap: 1rem;
`;

export const ForgotText = styled.p`
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 16px;
  color: ${colors.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
