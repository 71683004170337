import React, { Fragment, useCallback, useMemo } from 'react';
import { useDialogState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { addVariation, removeVariation, variationsStore } from 'models/product';
import {
  allProductsWithoutFiltersLocalized$,
  changeVariationsSearchStr,
  variationsFilteredStore,
  variationsSearchStr,
} from 'pages/model';

import ChosenProductsList from 'components/molecules/ChosenProductsList';
import {
  OpenModalButton,
  ProductsModal,
} from 'components/molecules/VariationalsModal';
import styled from 'styled-components';

import { colors } from 'styleGuide';
import { Cell, Grid } from 'ui';

import { ReactComponent as Edit } from 'assets/images/icon/edit.svg';

const VariationsGrid = styled.div`
  margin-bottom: 2.2rem;
`;

const VariationsTitle = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  color: ${colors.darkBlue};
`;

const VariationsBody = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 0.2rem;
  padding: 4rem 3rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${colors.primary};
  margin-bottom: 4.7rem;

  ${p =>
    p.flexDirection &&
    `
    flex-direction: ${p.flexDirection};
  `}
`;

export const Variations = ({ register }) => {
  const {
    product: { titles },
  } = Lang();

  const dialog = useDialogState();
  const variations = useStore(variationsStore);
  const filteredItems = useStore(variationsFilteredStore);
  const currentItems = useStore(variationsStore);
  const searchStr = useStore(variationsSearchStr);
  const products = useStore(allProductsWithoutFiltersLocalized$);

  const handleSearch = useCallback(
    e => changeVariationsSearchStr(e.target.value),
    [],
  );

  const handleRemove = useCallback(id => {
    removeVariation(id);
  }, []);

  const chosenProductsIdsList = useMemo(() => {
    return variations.map(el => ({ id: el, productId: el, orderId: el }));
  }, [variations]);

  return (
    <Fragment>
      <ProductsModal
        dialog={dialog}
        handleAdd={id => addVariation(id)}
        handleRemove={handleRemove}
        currentItems={currentItems}
        searchStr={searchStr}
        filteredItems={filteredItems}
        handleSearch={handleSearch}
      />
      <Grid as={VariationsGrid} cols="2fr 1fr">
        <Cell place="center start">
          <VariationsTitle>{titles.variations}</VariationsTitle>
        </Cell>
        <Cell place="center end">
          {variations.length > 0 && <OpenModalButton dialog={dialog} />}
        </Cell>
      </Grid>
      <VariationsBody
        flexDirection={variations.length === 0 ? 'row' : 'column'}
      >
        {variations.length === 0 && (
          <OpenModalButton
            customButtonText={titles.createVariation}
            icon={Edit}
            linkColor={colors.primary}
            inverseUnderline={true}
            dialog={dialog}
          />
        )}
        <ChosenProductsList
          allProductsList={products}
          arrayName="variations"
          chosenProductsIdsList={chosenProductsIdsList}
          register={register}
          remove={handleRemove}
        />
      </VariationsBody>
    </Fragment>
  );
};
