import styled from 'styled-components';

export const DeliveryAreaTitles = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > span {
    width: 150px;
  }

  & > span:not(:last-child) {
    margin-right: 40px;
  }
`;
