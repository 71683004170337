import styled from 'styled-components';

import { colors } from 'styleGuide';

export const InputWrapper = styled.div`
  position: relative;
`;

export const EuroIcon = styled.span`
  position: absolute;
  top: 1.1rem;
  left: 2rem;

  ${p =>
    p.disabled &&
    `
    color: ${colors.gray};
  `}
`;
