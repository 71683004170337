import { toast } from 'react-toastify';
import axios from 'axios';
import {
  ALGOLIA_CLIENT,
  DEFAULT_SELECTOR_STATE,
  getAlgoliaPrefix,
} from 'consts';
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import FuzzySearch from 'fuzzy-search';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { settings$ } from 'models/settings';
import { website$ } from 'models/website';
import { createDebounce } from 'utils/helpers/timeouts';

import { filter$ } from 'features/filter';
import { changeProductsTablePage } from 'features/filter/filterModel';

import { PRODUCTS } from 'api';

export const productsGate = createGate('');

export const changeSearchStr = createEvent();
export const updateProduct = createEvent();

export const changeSearchStrDebounced = createDebounce(changeSearchStr, 100);

export const getAllProductsFx = createEffect({
  handler: async () => {
    const filters = filter$.getState();
    const searchStr = searchStrDebounced$.getState();

    const params = {
      owner:
        filters.owner === DEFAULT_SELECTOR_STATE ? undefined : filters.owner,
      orderBy: filters.order,
      category_id: filters.category_id,
      sortedBy: 'desc',
      limit: filters.limit,
      selector: filters.selector || undefined,
      page: filters.page,
      name: searchStr || undefined,
    };

    const { data } = await PRODUCTS.getAll({
      ...params,
    });
    return data;
  },
});

export let cancelGetAllProductsWithoutFilters;

export const getAllProductsWithoutFiltersFx = createEffect({
  handler: async () => {
    const params = {
      limit: 10000,
    };

    cancelGetAllProductsWithoutFilters = axios.CancelToken.source();
    const { data } = await PRODUCTS.getAll(
      {
        ...params,
      },
      cancelGetAllProductsWithoutFilters.token,
    );
    return data;
  },
});

filter$.updates.watch(() => {
  getAllProductsFx();
});

export const changePopularityFx = createEffect({
  handler: async props => {
    const params = {
      popularity: props.popularity,
    };

    const { data } = await PRODUCTS.updatePopularity(props.id, {
      ...params,
    });

    return data.data;
  },
});

export const allProductsLoading$ = getAllProductsFx.pending;
export const isAllProductsWithoutFiltersLoading$ =
  getAllProductsWithoutFiltersFx.pending;

export const deleteProduct = createEvent();

export const allProductsWithoutFilters$ = createStore([]).on(
  getAllProductsWithoutFiltersFx.done,
  (s, p) => {
    return p.result.data;
  },
);

export const allProducts$ = createStore([])
  .on(getAllProductsFx.done, (s, p) => {
    return p.result.data || p.result;
  })
  .on(changePopularityFx.doneData, (s, p) => {
    const newProductIndex = s.findIndex(el => el.id === p.id);

    if (newProductIndex !== -1) {
      const newProducts = [...s];
      newProducts[newProductIndex] = p;
      return newProducts;
    }

    return s;
  });

export const allProductsWithoutFiltersLocalized$ = combine(
  allProductsWithoutFilters$,
  currentLang$,
  settings$,
  (allProducts, lang, { defaultLanguage }) => {
    return allProducts.map(el => ({
      ...el,
      name: el.names[lang] || el.names[defaultLanguage],
    }));
  },
);

export const allAvailableProducts$ = createStore(
  [],
).on(allProductsWithoutFiltersLocalized$, (_, p) =>
  p.filter(el => !el.isOutOfStock && !el.hidden),
);

export const allProductsMeta$ = createStore({}).on(
  getAllProductsFx.done,
  (s, p) => {
    return p.result.meta?.pagination || {};
  },
);

const relativeProductsSearcher = state =>
  new FuzzySearch(state, ['sku', 'name', 'slug'], { sort: true });
export const changeRelativeProductsSearchStr = createEvent();
export const relativeProductsSearchStr = restore(
  changeRelativeProductsSearchStr,
  '',
);
const changeRelativeProductsSearchStrDebounced = createDebounce(
  changeRelativeProductsSearchStr,
  100,
);
const relativeProductsSearchStrDebounced = restore(
  changeRelativeProductsSearchStrDebounced,
  '',
);
export const relativeProductsCategoryFilterStore = createStore(null);
export const relativeProductsSetCategoryFilter = createEvent();

relativeProductsCategoryFilterStore.on(
  relativeProductsSetCategoryFilter,
  (_, value) => value,
);

export const relativeProductsFilteredStore = combine(
  allAvailableProducts$,
  relativeProductsSearchStrDebounced,
  relativeProductsCategoryFilterStore,
  (e, s, c) => {
    const e2 = c && c > 0 ? e.filter(i => i.category_id === c) : e;
    return s.length < 3 ? e2 : relativeProductsSearcher(e2).search(s);
  },
);

const variationsSearcher = state =>
  new FuzzySearch(state, ['sku', 'name', 'slug'], { sort: true });

export const changeVariationsSearchStr = createEvent();

export const variationsSearchStr = restore(changeVariationsSearchStr, '');

const changeVariationsSearchStrDebounced = createDebounce(
  changeVariationsSearchStr,
  100,
);
const variationsSearchStrDebounced = restore(
  changeVariationsSearchStrDebounced,
  '',
);

export const setCurrentProductOwner = createEvent();
export const currentProductOwner$ = restore(setCurrentProductOwner, null);

export const variationsCategoryFilterStore = createStore(null);
export const variationsSetCategoryFilter = createEvent();

variationsCategoryFilterStore.on(
  variationsSetCategoryFilter,
  (_, value) => value,
);

const availableFranchiseeProducts$ = combine(
  allProductsWithoutFiltersLocalized$,
  currentProductOwner$,
  (products, owner) =>
    owner
      ? products.filter(
          el =>
            !el.isOutOfStock &&
            el.owner?.data?.id.toString() === owner.id?.toString(),
        )
      : [],
);

export const variationsFilteredStore = combine(
  availableFranchiseeProducts$,
  variationsSearchStrDebounced,
  variationsCategoryFilterStore,
  (e, s, c) => {
    const e2 = c && c > 0 ? e.filter(i => i.category_id === c) : e;
    return s.length < 3 ? e2 : variationsSearcher(e2).search(s);
  },
);

const trainingRecommendedSearcher = state =>
  new FuzzySearch(state, ['sku', 'name', 'slug'], { sort: true });
export const changeTrainingRecommendedSearchStr = createEvent();
export const trainingRecommendedSearchStr = restore(
  changeTrainingRecommendedSearchStr,
  '',
);
const changeTrainingRecommendedSearchStrDebounced = createDebounce(
  changeTrainingRecommendedSearchStr,
  100,
);
const trainingRecommendedSearchStrDebounced = restore(
  changeTrainingRecommendedSearchStrDebounced,
  '',
);
export const trainingRecommendedCategoryFilterStore = createStore(null);
export const trainingRecommendedSetCategoryFilter = createEvent();

trainingRecommendedCategoryFilterStore.on(
  trainingRecommendedSetCategoryFilter,
  (_, value) => value,
);

export const trainingRecommendedFilteredStore = combine(
  allAvailableProducts$,
  trainingRecommendedSearchStrDebounced,
  trainingRecommendedCategoryFilterStore,
  (e, s, c) => {
    const e2 = c && c > 0 ? e.filter(i => i.category_id === c) : e;
    return s.length < 3 ? e2 : trainingRecommendedSearcher(e2).search(s);
  },
);

export const touchProduct = createEvent();

export const cancelTouchProduct = createEvent();

export const touchedProducts$ = createStore([]);

const updateProductFx = createEffect({
  handler: async ({ id, params }) => {
    await PRODUCTS.updateQuantityAndPrice(id, params);
  },
});

updateProductFx.done.watch(({ params }) => {
  toast(`Product ${params?.params?.name} was updated`);
});

forward({
  from: updateProductFx.done.map(({ params }) => params?.params),
  to: updateProduct,
});
forward({
  from: updateProductFx.fail.map(({ params }) => params?.params),
  to: cancelTouchProduct,
});

updateProductFx.fail.watch(({ params }) => {
  toast.error(`Product ${params?.params?.name} wasn't updated`);
});

export const updateProductWithTouched = attach({
  effect: updateProductFx,
  source: touchedProducts$,
  mapParams: (id, touchedProducts) => {
    return { id, params: touchedProducts.find(({ id: ID }) => ID === id) };
  },
});

touchedProducts$
  .on(touchProduct, (s, p) => {
    return [...s.filter(e => e.id !== p.id), p];
  })
  .on(cancelTouchProduct, (s, { id }) => {
    return s.filter(e => e.id !== id);
  })
  .on(updateProductFx.done, (s, { params: { id } }) => {
    return s.filter(e => e.id !== id);
  });

allProducts$.on(deleteProduct, (s, p) => s.filter(({ id }) => id !== p));

export const searchStr$ = restore(changeSearchStr, '');

const searchStrDebounced$ = restore(changeSearchStrDebounced, '');

export const searchPending$ = combine(
  searchStr$,
  searchStrDebounced$,
  (s, e) => s !== e,
);

searchStrDebounced$.updates.watch(val => {
  if (!val.length) {
    getAllProductsFx();
  }
  if (val.length < 3) {
    return;
  }

  changeProductsTablePage(1);
  getAllProductsFx();
});

export const getPlatformProductsFx = createEffect({
  handler: async country => {
    const productsAlgoliaIndex = ALGOLIA_CLIENT.initIndex(
      `${getAlgoliaPrefix()}_${country.split('.')[0]}_products`,
    );

    let hits = [];
    await productsAlgoliaIndex.browseObjects({
      batch: batch => {
        hits = hits.concat(batch);
      },
    });

    return { [country]: hits };
  },
});

export const platformProductsStore = createStore({}).on(
  getPlatformProductsFx.doneData,
  (s, p) => ({
    ...s,
    ...p,
  }),
);

export const platformProductsStoreLocalized = combine(
  platformProductsStore,
  website$,
  (products, webSite) => {
    return products[webSite.country] || [];
  },
);

const platformProductsSearcher = state =>
  new FuzzySearch(state, ['sku', 'name', 'slug'], { sort: true });

export const changePlatformProductsSearchStr = createEvent();

export const platformProductsSearchStr = restore(
  changePlatformProductsSearchStr,
  '',
);

const changePlatformProductsSearchStrDebounced = createDebounce(
  changePlatformProductsSearchStr,
  100,
);
const platformProductsSearchStrDebounced = restore(
  changePlatformProductsSearchStrDebounced,
  '',
);

export const platformProductsFilterStore = createStore(null);
export const platformProductsSetCategoryFilter = createEvent();

platformProductsFilterStore.on(
  platformProductsSetCategoryFilter,
  (_, value) => value,
);

export const platformProductsFilteredStore = combine(
  platformProductsStoreLocalized,
  platformProductsSearchStrDebounced,
  platformProductsFilterStore,
  (e, s, c) => {
    const e2 = c && c > 0 ? e?.filter(i => i.category_id === c) : e || [];
    return s.length < 3 ? e2 : platformProductsSearcher(e2).search(s);
  },
);
