import React from 'react';
import { Lang } from 'lang';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const ProductEditorTitle = ({ values }) => {
  const {
    product: { titles },
    common,
  } = Lang();

  return (
    <Row align="center">
      <ST.ProductEditorTitle color="#1D415C" fontSize="24px" bold>
        {titles.productProfile}{' '}
      </ST.ProductEditorTitle>
      {values.hidden && (
        <T8y color="#EF3D46" fontSize="24px">
          &bull; {common.draft}
        </T8y>
      )}
    </Row>
  );
};

export default ProductEditorTitle;
