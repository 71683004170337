export const users = {
  title: 'Usuarios',
  subTitle:
    'Revisar usuarios del portal de cliente y gestionar sus descuentos especiales',
  hideTestUsers: 'Ocultar usuarios de “test”',

  filterSelect: {
    all: 'Todos',
    company: 'Compañía',
    private: 'Privado',
    verified: 'TA Activo - Si',
    unverified: 'TA Activo - No',
    archived: 'Archivado',
  },

  tableHeaders: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    businessName: 'Nombre de la compañía',
    segment: 'Sector',
    discount: 'Descuento',
    emailAddress: 'Email',
    phoneNumber: 'Teléfono',
    idVerification: 'ID verification',
    status: 'TA Activo',
  },

  types: {
    private: 'Privado',
    company: 'Compañía',
  },

  statuses: {
    verified: 'Si',
    unverified: 'No',
    archived: 'Archivado',
  },

  viewProfile: 'Ver perfil',
  foundStr: 'Se han encontrado {0}  usuarios',
  searchPlaceholder: 'Comienza a escribir para filtrar usuarios',
  backToPreviousScreen: 'Regresa a la pantalla previa',
  backToListUsers: 'Regreso a lista de usuarios',

  info: {
    title: 'Perfil de usuario',
    titleNewUser: 'Perfil de usuario (Nuevo)',
    fiscalCodeFieldTitle: 'Identificación fiscal',
    officeAddressFieldTitle: 'Dirección registrada de las oficinas',
    postalCodeFieldTitle: 'Código postal',
    provinceFieldTitle: 'Provincia',
    sectorOfActivity: 'Sector de actividad',
    uniqueCodeFieldTitle: 'Código único',
    PECFieldTitle: 'PEC',
    deliveryAddressTitle: 'Dirección de entrega',
    howDidYouKnowAboutUs: '¿Cómo te has enterado de nosotros?',
    generalInformation: 'Información general',
    contactInformation: 'Información de contacto',
    contactPhoneNumber: 'Número de teléfono de contacto',
    contactEmailAddress: 'Correo electrónico de contacto',
    backToLogin: 'Volver al inicio de sesión',
    businessName: 'Nombre de la compañía',
    fullName: 'Nombre completo',
    emailAddress: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    firstName: 'Nombre',
    lastName: 'Apellido',
    dateOfBirth: 'Fecha de nacimiento',
    birthplace: 'Lugar de nacimiento',
    address: 'Dirección',
    companyName: 'Nombre de la compañía',
    country: 'País',
    vatNumber: 'Número de VAT',
    sortCode: 'Código de clasificación',
    userType: 'Tipo de usuario',
    accountNumber: 'Número de cuenta',
    taxNumber: 'NIF',
    city: 'Ciudad',
    newEmail: 'Nuevo correo electrónico',
    noType: 'Sin tipo',
    noDataAboutType: 'Sin datos del tipo',
    idVerificationStatus: 'ID Verification status',
  },

  sectorOfActivity: {
    business: 'Compañía',
    constructions: 'Construcciones',
    public_authority: 'Autoridad pública',
    training_courses: 'Cursos de formación',
    gardening: 'Jardinería',
    industry: 'Industria',
    services: 'Servicios',
    transport: 'Transporte',
    tourism: 'Turismo',
    other: 'Otro',
  },

  aboutUs: {
    search_on_the_internet: 'Búsqueda en internet',
    television: 'Televisión',
    word_of_mouth: 'Boca a boca',
    headquarter_visibility: 'Visualización de la sede',
    construction_warehouse: 'Almacén de construcción',
    gardening_dealer: ' Distribuidor de jardinería',
    magazines_and_newspapers: 'Revistas y/o periódicos',
    other: 'Otro',
  },

  discount: {
    title: 'Descuento de usuario',
    discount: 'Descuento de membresía',
    option: 'Habilitar descuento',
    description:
      'El descuento seleccionado se aplicará a todos los productos del catálogo si el usuario ha iniciado sesión en el Portal de Cliente',
  },

  discountOptions: {
    platinum: 'Platino',
    gold: 'Oro',
    silver: 'Plata',
  },

  deposit: {
    title: 'Depósito reembolsable',
    option: 'Habilitar depósito',
    description:
      'Si no se marca, el depósito reembolsable no se agregará a los pedidos realizados por el usuario',
  },

  sureToLogIn: '¿Estás seguro de querer acceder como usuario?',
  membershipLevel: 'Nivel de miembro',
  findPostcode: 'Escribe tu código postal',
  noItems: 'No hay elementos para mostrar',
  backToResults: 'Regresa a los resultados',
  newUser: 'Añadir nuevo usuario',
  imCompany: 'Soy una empresa',
};
