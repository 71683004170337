import React, { useCallback } from 'react';

import ExpandableSection from 'components/atoms/ExpandableSection';

import * as ST from './styles';

const GeneralExpandableSection = ({
  index,
  header,
  errors,
  listOfFields = [],
  additionalHeaderComponent,
  initialIsOpen,
  children,
  withSubSections,
  handleRemove,
  subSection,
  isNoForm,
}) => {
  const getIsErrors = useCallback(() => {
    if (!errors || !errors.form) {
      return false;
    }

    return !!Object.keys(errors.form).filter(e => listOfFields.includes(e))
      .length;
  }, [errors, listOfFields]);

  const getIsErrorsNoForm = useCallback(() => {
    if (!errors) {
      return false;
    }

    return !!Object.keys(errors).filter(e => listOfFields.includes(e)).length;
  }, [errors, listOfFields]);

  return (
    <ExpandableSection
      index={index}
      header={header}
      isErrors={isNoForm ? getIsErrorsNoForm() : getIsErrors()}
      additionalHeaderComponent={additionalHeaderComponent}
      subSection={subSection}
      handleRemove={handleRemove}
      initialIsOpen={initialIsOpen}
    >
      {withSubSections ? (
        children
      ) : (
        <ST.SectionContentWrapper subSection={subSection}>
          {children}
        </ST.SectionContentWrapper>
      )}
    </ExpandableSection>
  );
};

export default GeneralExpandableSection;
