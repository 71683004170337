import { transparentize } from 'polished';

import styled from 'styled-components';

import { colors } from 'styleGuide';
import { T8y } from 'ui';

export const AvatarStyled = styled(T8y)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: ${transparentize(0.85, colors.primary)};
  border-radius: 50%;
  line-height: 1;
  font-size: 16px;
`;
