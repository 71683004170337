import styled from 'styled-components';

export const AddMoreLink = styled.div`
  display: flex;
  align-items: center;
  color: #ff6600;
  text-decoration: underline;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

export const TableImage = styled.img`
  max-width: 3.7rem;
  max-height: 3.7rem;
`;
