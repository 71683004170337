import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  settings$,
  settingsLoading$,
  updatePartOfSetting,
} from 'models/settings';

import CustomButton from 'components/atoms/CustomButton';
import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import CalendarGeneral from 'components/molecules/CalendarGeneral';
import CalendarHolidays from 'components/molecules/CalendarHolidays';
import CalendarNextDays from 'components/molecules/CalendarNextDays';
import CalendarWeekends from 'components/molecules/CalendarWeekends';
import { SETTINGS } from 'api';

import * as ST from './styles';

import { Row } from 'ui';

const DatesSetup = () => {
  const { header, form, analytics } = Lang();

  const settings = useStore(settings$);
  const settingsLoading = useStore(settingsLoading$);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    watch,
    errors,
    clearErrors,
    getValues,
    reset,
    control,
    setValue,
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
    defaultValues: {
      defaultHiringPeriod: 1,
      blockedDatesList: [
        {
          day: '',
          month: '',
          comment: '',
        },
      ],
      deliveryWeekends: [],
      blockedWeekDays: [],
      isSameDayHire: false,
      sameDayMaxHireTime: null,
    },
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const handleReset = useCallback(
    data => {
      if (!data.defaultHiringPeriod) {
        return;
      }

      const allMonths = Object.keys(analytics.months);

      reset({
        defaultHiringPeriod: data.defaultHiringPeriod || 1,
        deliveryWeekends: data.deliveryWeekends || [],
        blockedWeekDays: data.blockedWeekDays || [],
        blockedDatesList: data.blockedDatesList.map(el => ({
          day: parseInt(el.date.split('-')[1]),
          month: allMonths[parseInt(el.date.split('-')[0]) - 1],
          comment: el.comment,
        })),
        isSameDayHire: data.isSameDayHire,
        sameDayMaxHireTime: data.sameDayMaxHireTime,
      });
    },
    [analytics.months, reset],
  );

  useEffect(() => {
    handleReset(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    register('sameDayMaxHireTime');
  }, [register]);

  const handleCancel = useCallback(() => {
    handleReset(settings);
  }, [handleReset, settings]);

  const onSubmit = useCallback(
    async data => {
      setIsLoading(true);

      try {
        const allMonths = Object.keys(analytics.months);

        const { data: result } = await SETTINGS.updateDates({
          ...data,
          sameDayMaxHireTime: data.sameDayMaxHireTime || null,
          defaultHiringPeriod: parseInt(data.defaultHiringPeriod) || 1,
          blockedDatesList: data.blockedDatesList.map(el => {
            return {
              date: `${(
                '0' +
                (allMonths.findIndex(month => month === el.month) + 1)
              ).slice(-2)}-${('0' + el.day).slice(-2)}`,
              comment: el.comment || null,
            };
          }),
        });

        handleReset(result);
        updatePartOfSetting(result);
        toast.success(form.toastTitle.successfullyUpdated);
      } catch (e) {
        toast.error(form.toastTitle.wentWrong);
      } finally {
        setIsLoading(false);
      }
    },
    [analytics.months, form.toastTitle, handleReset],
  );

  return (
    <>
      {(settingsLoading || isLoading) && <SpinnerOverlay />}
      <CalendarGeneral
        errors={errors}
        register={register}
        setValue={setValue}
        values={values}
        setError={setError}
        clearErrors={clearErrors}
      />
      <CalendarHolidays
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        values={values}
      />
      <CalendarWeekends
        errors={errors}
        register={register}
        setValue={setValue}
        values={values}
      />
      <CalendarNextDays
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        values={values}
        clearErrors={clearErrors}
      />
      <Row justify="center" fullWidth>
        <ST.CustomButtonStyled
          text={header.table.cancel}
          backgroundColor="positive"
          width="160px"
          color="gray3"
          border="1px solid #D0D0D0"
          colorHovered="gray"
          onClick={handleCancel}
        />
        <CustomButton
          text={header.table.save}
          width="160px"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          disabled={Object.keys(errors).length > 0}
        />
      </Row>
    </>
  );
};

export default DatesSetup;
