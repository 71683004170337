import React, { useCallback, useEffect } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { Lang } from 'lang';

import * as ST from './styles';

import { Radio, T8y } from 'ui';

const ShowSection = ({ handleChange, defaultValue = false, fields = null }) => {
  const { trainings } = Lang();

  const radio = useRadioState({ state: defaultValue });

  const handleRadioClick = useCallback(
    (e, value) => {
      e.preventDefault();
      e.stopPropagation();
      if (value !== undefined) {
        radio.setState(value);
        handleChange(!!value);
      }
    },
    [handleChange, radio],
  );

  useEffect(() => {
    radio.setState(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (!fields) {
      return;
    }

    if (!fields.length) {
      radio.setState(false);
      if (defaultValue !== false) {
        handleChange(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <ST.ShowSectionWrapper>
      <T8y fontSize="14px" lineHeight="19px" marginRight="22px">
        {trainings.showSection}
      </T8y>
      <RadioGroup as={ST.RadioWrapper} {...radio} onClick={handleRadioClick}>
        <Radio
          id="radio-show-introductory"
          {...radio}
          text={trainings.yes}
          onClick={handleRadioClick}
          value
        />
        <Radio
          id="radio-hide-introductory"
          {...radio}
          text={trainings.no}
          value={false}
          onClick={handleRadioClick}
        />
      </RadioGroup>
    </ST.ShowSectionWrapper>
  );
};

export default ShowSection;
