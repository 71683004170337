import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { clearLead, getLead, lead$ } from 'models/lead';
import { getQueryParam } from 'utils/getQueryParam';

import { useGetProductsWithExtra } from './useGetProductsWithExtra';

export const useOrderProfile = () => {
  const lead = useStore(lead$);

  const history = useHistory();
  const { orderId } = useParams();

  const orderType = useMemo(
    () => getQueryParam(history.location.search, 'type'),
    [history.location.search],
  );

  useEffect(() => {
    getLead({ id: orderId, type: orderType });
    return clearLead;
  }, [orderId, orderType]);

  const { isQuote, isTraining, isCallback, isColdLead } = useMemo(() => {
    if (!lead) {
      return {};
    }
    return {
      isQuote: lead.type === 'quote',
      isTraining: lead.type === 'training',
      isCallback: lead.type === 'callback',
      isColdLead: !['quote', 'training', 'product'].includes(lead.type),
    };
  }, [lead]);

  const productsWithExtra = useGetProductsWithExtra(lead?.products);

  return useMemo(
    () => ({
      lead,
      isQuote,
      isTraining,
      isCallback,
      isColdLead,
      productsWithExtra,
    }),
    [isCallback, isColdLead, isQuote, isTraining, lead, productsWithExtra],
  );
};
