import React, { useCallback, useMemo } from 'react';
import { isProdEnv } from 'consts';
import { useStore } from 'effector-react';
import { useOrder } from 'hooks';
import { Lang } from 'lang';
import { currentLang$ } from 'layout/Header/SelectLanguage/languageModel';
import { branding$ } from 'models/branding';
import { getLead, setLeadDocs } from 'models/lead';
import { settings$ } from 'models/settings';
import { getUser, setActualDocsToOrderUser } from 'models/users';
import { website$ } from 'models/website';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

import LeadInfoCRMLinks from 'components/atoms/LeadInfoCRMLinks';
import OrderContactSection from 'components/atoms/OrderContactSection';
import { OrderInfoBlock } from 'components/atoms/OrderInfoBlock/styles';
import { OrderInfoRow } from 'components/atoms/OrderInfoRow/styles';
import { OrderInfoWrapper } from 'components/atoms/OrderInfoWrapper/styles';
import CancelOrderModal from 'components/molecules/CancelOrderModal';
import OrderDocuments from 'components/molecules/OrderDocuments';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const LeadOrderInfo = ({ order, userId, isQuote, isTraining, isUserPage }) => {
  const { orders, leads } = Lang();

  const settings = useStore(settings$);
  const branding = useStore(branding$);
  const website = useStore(website$);

  const currentLangState = useStore(currentLang$);

  const onUpdateCallback = useCallback(() => {
    if (isUserPage) {
      getUser(userId);
    }
    getLead({ id: order.orderId, type: order.type });
  }, [isUserPage, order, userId]);

  const onAddDocumentCallback = useCallback(
    (newDocs, type) => {
      if (isUserPage) {
        setActualDocsToOrderUser({
          id: order.orderId,
          newDocs,
          type,
        });
      }
      setLeadDocs({
        id: order.orderId,
        newDocs,
        type,
      });
    },
    [isUserPage, order],
  );

  const {
    cancelRequest,
    isCancelOrderLoading,
    setIsCancelOrderLoading,
    addDocumentToOrder,
    deleteDocumentFromOrder,
    isAddFilesBlocked,
    isAddInvoiceBlocked,
  } = useOrder(order, onAddDocumentCallback, onUpdateCallback);

  const leadSource = useMemo(() => {
    if (!order.products) {
      return null;
    }

    if (isTraining) {
      return `${branding.webSiteUrl}/trainings/${order.products[0]?.slug}`;
    }

    if (order.origin === 'wilkotoolhire.com') {
      return isProdEnv
        ? `https://wilkotoolhire.com/${website.regionSlug}/${order.products[0]?.slug}`
        : `https://w-stage.easytoolhire.com/${website.regionSlug}/${order.products[0]?.slug}`;
    }

    return `${branding.webSiteUrl}/${order.products[0]?.slug}`;
  }, [branding.webSiteUrl, isTraining, order, website]);

  const addressName =
    order.deliveryMethod === 'selfCollection'
      ? order.warehouse?.name
      : order.deliveryAddress;

  const showCancelOrderBtn =
    isUserPage && order.status === 'new' && !cancelRequest;

  return (
    <>
      <OrderInfoWrapper>
        <ST.OrderInfoHeader justify="stretch">
          <T8y color="darkBlue" fontSize="20px" bold>
            {orders.order} #{order.orderNumber || order.orderId} (
            {leads.types[order.type]})
          </T8y>
          {showCancelOrderBtn &&
            (isCancelOrderLoading ? (
              <T8y color="gray2" fontSize="18px" cursor="not-allowed">
                {orders.cancelOrder}
              </T8y>
            ) : (
              <CancelOrderModal
                userId={userId}
                orderId={order.orderId}
                orderType={order.type}
                setIsCancelOrderLoading={setIsCancelOrderLoading}
              />
            ))}
        </ST.OrderInfoHeader>
        <OrderInfoRow>
          <OrderInfoBlock>
            {!isQuote && !isTraining && (
              <T8y fontSize="18px" color="darkBlue" marginBottom="6px" bold>
                {orders.item.orderStatus}:{' '}
                {orders.statusType[order.status] || '-'}
              </T8y>
            )}

            <ST.OrderInfoFeature fontSize="16px">
              {orders.item.date}: {order.created_at}
            </ST.OrderInfoFeature>
          </OrderInfoBlock>
        </OrderInfoRow>
        <OrderInfoRow>
          <OrderInfoBlock short={order.type !== 'product'} withTopBorder>
            <OrderContactSection order={order} isTraining={isTraining} />
          </OrderInfoBlock>
          {order.type !== 'product' && (
            <OrderInfoBlock short withTopBorder>
              <T8y fontSize="18px" color="darkBlue" marginBottom="6px" bold>
                {leads.tableHeaders.leadSource}
              </T8y>
              <Row
                as="a"
                href={leadSource}
                target="_blank"
                rel="noopener noreferrer"
                align="center"
                noWrap
              >
                <T8y fontSize="16px" color="primary" marginRight="8px">
                  {getTranslatedFieldValue(
                    order.products[0]?.names,
                    currentLangState,
                    settings.defaultLanguage,
                  )}
                </T8y>
                <ST.LinkIconStyled />
              </Row>
            </OrderInfoBlock>
          )}
        </OrderInfoRow>
        {order.message && (
          <OrderInfoRow>
            <OrderInfoBlock withTopBorder>
              <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                {leads.message}
              </T8y>
              <ST.OrderInfoFeature fontSize="16px">
                {order.message}
              </ST.OrderInfoFeature>
            </OrderInfoBlock>
          </OrderInfoRow>
        )}
        <OrderInfoRow $wrap>
          {!isTraining && (
            <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
              <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                {orders.orderTableHeaders.deliveryMethod}
              </T8y>
              <ST.OrderInfoFeature>
                {orders.deliveryMethod[order.deliveryMethod]}
              </ST.OrderInfoFeature>
              <ST.OrderInfoFeature>{addressName}</ST.OrderInfoFeature>
            </OrderInfoBlock>
          )}
          {!isQuote && (
            <>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {orders.orderTableHeaders.paymentMethod}
                </T8y>
                <ST.OrderInfoFeature>
                  {orders.paymentType[order.paymentMethod] ||
                    order.paymentMethod}
                </ST.OrderInfoFeature>
                {!!order.lastFourDigits && (
                  <ST.OrderInfoFeature>
                    {orders.item.cardEndingIn} *{order.lastFourDigits}
                  </ST.OrderInfoFeature>
                )}
              </OrderInfoBlock>
              {order.paymentMethod === 'card' && (
                <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                  <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                    {orders.paymentStatus}
                  </T8y>
                  <ST.OrderInfoFeature>
                    {orders.statusType[order.isPaid ? 'paid' : 'not_paid']}
                  </ST.OrderInfoFeature>
                </OrderInfoBlock>
              )}
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.tableHeaders.CRMDealStage}
                </T8y>
                <ST.OrderInfoFeature>
                  {order.crmStageStatus || '-'}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
              <OrderInfoBlock withTopBorder short $width="auto" $mb="24px">
                <T8y color="darkBlue" fontSize="18px" marginBottom="6px" bold>
                  {leads.shuftiVerificationStatus}
                </T8y>
                <ST.OrderInfoFeature>
                  {order.verificationStatus ? leads.yes : leads.no}
                </ST.OrderInfoFeature>
              </OrderInfoBlock>
            </>
          )}
        </OrderInfoRow>
        {!isQuote && !isTraining && (
          <OrderDocuments
            order={order}
            addDocument={addDocumentToOrder}
            deleteDocument={deleteDocumentFromOrder}
            isAddFilesBlocked={isAddFilesBlocked}
            isAddInvoiceBlocked={isAddInvoiceBlocked}
          />
        )}
      </OrderInfoWrapper>
      <LeadInfoCRMLinks lead={order} />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadOrderInfo;
