import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { memberships$ } from 'models/memberships';

import { FieldStyle } from 'components/Product/shared';

import ProductEditorBlockWrapper from '../ProductEditorBlockWrapper';
import { InputsWrapperFields } from './styles';

import { inputStyle, Select } from 'ui';

const TradeAccountMembership = ({
  userMembership,
  control,
  isLoading,
  setValue,
}) => {
  const { users } = Lang();

  const memberships = useStore(memberships$);

  useEffect(
    () => {
      const membershipLevelId =
        userMembership &&
        memberships.find(el => el.slug === userMembership?.slug)?.id;

      setValue('account.membershipLevelId', membershipLevelId || 1);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [memberships],
  );

  return (
    <ProductEditorBlockWrapper blockTitle={users.membershipLevel}>
      <FieldStyle as={InputsWrapperFields} withBorder>
        <Controller
          name="account.membershipLevelId"
          render={({ onChange, value }) => (
            <Select
              options={memberships}
              selected={
                memberships.find(el => el.id === value)?.name ||
                memberships[0]?.name
              }
              onClickOption={value => {
                const membershipId = memberships.find(el => el.slug === value)
                  .id;
                onChange(membershipId);
              }}
              className={inputStyle}
              disabled={isLoading}
              isSelectDisable={isLoading}
            />
          )}
          control={control}
        />
      </FieldStyle>
    </ProductEditorBlockWrapper>
  );
};

export default TradeAccountMembership;
