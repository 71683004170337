import { FieldStyle2, HalfField } from 'components/Product/shared';
import styled from 'styled-components';

export const InputsWrapperFields = styled(FieldStyle2)`
  width: 20rem;
  margin: 1.5rem 0;
  color: #424242;
  span {
    color: #aaaaaa;
  }
  & button {
    color: #424242;
  }
`;

export const StyledHalfField = styled(HalfField)`
  margin-bottom: 0;
`;
