import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Lang } from 'lang';

import { POPULAR_PRODUCTS } from 'api';

export const useDndPopularProducts = popularProducts => {
  const { form } = Lang();

  const [popularDndProducts, setPopularDndProducts] = useState([]);

  useEffect(() => {
    setPopularDndProducts(popularProducts);
  }, [popularProducts]);

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) {
        return;
      }

      const oldItems = [...popularDndProducts];

      try {
        const newItems = [...popularDndProducts];
        const removedIndex = newItems.findIndex(
          el => el.orderId === result.source.index,
        );
        const newIndex = newItems.findIndex(
          el => el.orderId === result.destination.index,
        );
        const [removed] = newItems.splice(removedIndex, 1);
        newItems.splice(newIndex, 0, removed);
        setPopularDndProducts(newItems);

        const { data } = await POPULAR_PRODUCTS.reorderPopularProduct(
          result.draggableId,
          {
            orderId: result.destination.index,
          },
        );
        if (data?.data) {
          setPopularDndProducts(data.data);
        }
      } catch (e) {
        console.warn(e);
        setPopularDndProducts(oldItems);
        toast.error(form.toastTitle.wentWrong);
      }
    },
    [form.toastTitle.wentWrong, popularDndProducts],
  );

  return useMemo(
    () => ({
      popularDndProducts,
      onDragEnd,
    }),
    [onDragEnd, popularDndProducts],
  );
};
