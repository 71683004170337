import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  clearJobType,
  getJobType,
  jobTypeExpanded$,
  jobTypeLoading$,
} from 'models/jobTypes';

import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import JobTypeCategories from 'components/molecules/JobTypeCategories';
import JobTypeEditorHeader from 'components/molecules/JobTypeEditorHeader';
import JobTypeGeneralInformation from 'components/molecules/JobTypeGeneralInformation';
import JobTypePrerender from 'components/molecules/JobTypePrerender';
import JobTypeProducts from 'components/molecules/JobTypeProducts';
import JobTypeSeo from 'components/molecules/JobTypeSeo';
import JobTypeText from 'components/molecules/JobTypeText';
import { history } from 'libs/history';
import { JOB_TYPES } from 'api';

import { generateURL } from '../../Product/shared';

const JobTypeEditor = ({ match }) => {
  const { marketing, form } = Lang();

  const jobTypeExpanded = useStore(jobTypeExpanded$);
  const jobTypeLoading = useStore(jobTypeLoading$);

  const [isJobTypeLoading, setIsJobTypeLoading] = useState(false);

  const {
    getValues,
    errors,
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    trigger,
    control,
    clearErrors,
    unregister,
  } = useForm({
    defaultValues: {
      title: null,
      slug: null,
      seoTitle: null,
      metaDescription: null,
      seoH1: null,
      seoRobots: null,
      hidden: false,
      marketingText: null,
      prerenderStatusCode: null,
      prerenderHeaderLocation: null,
      products: [],
      categories: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const idFromUrl = match?.params?.id;
  const isNew = idFromUrl === 'new';

  useEffect(() => {
    if (idFromUrl && idFromUrl !== 'new') {
      getJobType(idFromUrl);
    }

    return () => clearJobType();
  }, [idFromUrl]);

  useEffect(() => {
    if (!!jobTypeExpanded) {
      reset({
        ...jobTypeExpanded,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTypeExpanded]);

  const getIsError = useCallback(
    field => {
      return errors && errors[field] && errors[field].message;
    },
    [errors],
  );

  const onSubmit = useCallback(
    async data => {
      try {
        if (data.products.length < 4) {
          toast.error(form.toastTitle.minProducts);
          return;
        }

        setIsJobTypeLoading(true);

        const cleanedData = {
          ...data,
          slug: isNew ? generateURL(data.slug) : jobTypeExpanded.slug,
          products: data.products.map(el => ({
            id: el.id,
            orderId: el.orderId,
          })),
          categories: data.categories.map(el => ({
            id: el.id,
            description: el.description,
          })),
        };
        delete cleanedData[''];

        isNew
          ? await JOB_TYPES.createJobType(cleanedData)
          : await JOB_TYPES.updateJobType(data.id, cleanedData);

        toast.success(
          form.toastTitle[
            isNew ? 'successfullyCreated' : 'successfullyUpdated'
          ],
        );
        history.push(PATHS.JOB_TYPES);
      } catch (e) {
        const errData = e.response?.data?.data;
        console.warn(e);
        toast.error(
          errData || form.toastTitle[isNew ? 'createError' : 'updateError'],
        );
      } finally {
        setIsJobTypeLoading(false);
      }
    },
    [form.toastTitle, isNew, jobTypeExpanded],
  );

  const onError = useCallback(() => {
    toast.error(form.toastTitle.invalidForm);
  }, [form.toastTitle.invalidForm]);

  if (!idFromUrl) {
    return null;
  }

  return (
    <JobTypeEditorHeader
      handleSubmit={handleSubmit(onSubmit, onError)}
      pageHeader={
        idFromUrl === 'new'
          ? marketing.jobTypes.addNewJobType || ' '
          : `${marketing.jobTypes.jobType}: ${values.title ||
              marketing.jobTypes.jobTypeTitle}`
      }
      linkText={marketing.jobTypes.backToJobs}
      loading={isJobTypeLoading}
    >
      {(isJobTypeLoading || jobTypeLoading) && <SpinnerOverlay />}
      <JobTypeGeneralInformation
        watch={watch}
        register={register}
        values={values}
        setValue={setValue}
        getIsError={getIsError}
        errors={errors}
        trigger={trigger}
        isNew={isNew}
      />
      <JobTypeSeo
        errors={errors}
        register={register}
        values={values}
        getIsError={getIsError}
      />
      <JobTypePrerender
        register={register}
        values={values}
        setValue={setValue}
        errors={errors}
        clearErrors={clearErrors}
      />
      <JobTypeProducts
        errors={errors}
        control={control}
        values={values}
        setValue={setValue}
      />
      <JobTypeCategories
        errors={errors}
        control={control}
        values={values}
        setValue={setValue}
        register={register}
        getIsError={getIsError}
        watch={watch}
        unregister={unregister}
      />
      <JobTypeText setValue={setValue} errors={errors} />
    </JobTypeEditorHeader>
  );
};

export default withRouter(JobTypeEditor);
