export const categories = {
  titles: {
    manageCategories: 'Gestione Categorie',
    categoriesFound: 'categorie in catalogo',
    startTyping:
      'Inizia a scrivere per attivare i filtri delle categorie per nome',
    allCategories: 'Tutte le categorie',
    archievedCategories: 'Archieved categories',
    visual: 'Immagine',
    categoryTitle: 'Titolo Categoria',
    products: 'Prodotti',
    createCategory: 'Creare categoria',
    editCategory: 'Modificare categoria',
    categoryVisual: 'Categoria Visual',
    updating: 'Sto aggiornando',
    creating: 'Sto creando',
    show: 'Mostra',
    selectFilter: 'Seleziona Filtro',
    deleteSelected: 'Cancella selezione',
    deletionConfirmation: 'Conferma cancellazione',
    prerenderInfo: 'Informazioni sul Pre-rendering',
    hiddenCategories: 'Categorie nascoste',
    visibleCategories: 'Categorie visibili',
  },
  buttons: {
    cancel: 'Annulla',
    confirm: 'Conferma',
  },
  areYouSureYouWantToDeleteCategories:
    'Sei sicuro di voler eliminare le seguenti categorie:',

  hideCategory: 'Nascondi categoria',
  prerenderStatusCode: 'Codice stato Pre-rendering',
  prerenderHeader: 'Intestazione Pre-rendering - Posizione:',
  prerenderStatusNote: `Codice "301" per reindirizzamento  permanente. Codice "302" per reindirizzamento temporaneo`,
  contactToCreateCategory:
    'Contatta il team degli sviluppatori per creare una nuova categoria',
};
