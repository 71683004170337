import React from 'react';
import { useStore } from 'effector-react';
import { branding$ } from 'models/branding';
import { tradeAccount$ } from 'models/tradeAccounts';

import { CreditInfo, CreditLineWrapper } from './styles';

import { T8y } from 'ui';

const CreditLine = () => {
  const branding = useStore(branding$);

  const tradeAccount = useStore(tradeAccount$);

  return (
    <CreditLineWrapper>
      <CreditInfo>
        <T8y bold>Remaining credit limit</T8y>
        <T8y bold mb>
          {branding.currencySymbol}
          {tradeAccount.credit.limitBalance}
        </T8y>
        <T8y variant="t3" as="p">
          This is remaining credit limit for two orders
        </T8y>
      </CreditInfo>
      <CreditInfo>
        <T8y bold>Max order value</T8y>
        <T8y bold mb>
          {branding.currencySymbol}
          {tradeAccount.credit.limit}
        </T8y>
        <T8y variant="t3" as="p">
          This is remaining credit limit for two orders
        </T8y>
      </CreditInfo>
    </CreditLineWrapper>
  );
};

export default CreditLine;
