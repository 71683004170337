import { createEffect, createStore, guard, restore } from 'effector';
import { createGate } from 'effector-react';

import { INTEGRATIONS } from 'api';

export const IntegrationsGate = createGate('warehouses');

export const getIntegrationsFx = createEffect({
  handler: async () => {
    const { data } = await INTEGRATIONS.getAllIntegrations();
    return data;
  },
});

export const integrations$ = restore(getIntegrationsFx, []);

const integrationsWasCalledTimes$ = createStore(0).on(
  getIntegrationsFx.finally,
  () => 1,
);

guard({
  source: IntegrationsGate.open,
  filter: integrationsWasCalledTimes$.map(el => !el),
  target: getIntegrationsFx,
});
