import React from 'react';
import { useDialogState } from 'reakit';
import { Lang } from 'lang';

import { AttributeEditForm } from 'components/atoms/AttributeEditForm';

import { Icon } from '../Icon';
import * as ST from './styles';

import { colors } from 'styleGuide';
import { Modal } from 'ui';

export const AttributeModal = ({
  isEdit = false,
  title,
  item = null,
  append,
  isCheckbox,
  type,
  typeForActiveCheckbox,
  reload,
  isDescription,
}) => {
  const dialog = useDialogState();

  const {
    form: { links, buttons },
  } = Lang();

  const createDisclosure = (
    <ST.OpenModalLink>
      <Icon type="edit" color={colors.primary} marginRight="10px" />
      {links.createNew}
    </ST.OpenModalLink>
  );

  const editDisclosure = (
    <Icon type="edit" color={colors.primary} marginRight="10px" />
  );

  return (
    <Modal
      disclosure={isEdit ? editDisclosure : createDisclosure}
      title={title}
      dialogState={dialog}
      width={620}
      backgroundColor={colors.primary}
      lazyRender
    >
      <AttributeEditForm
        dialog={dialog}
        btnText={isEdit ? buttons.edit : buttons.add}
        item={item}
        append={append}
        isCheckbox={isCheckbox}
        type={type}
        typeForActiveCheckbox={typeForActiveCheckbox}
        reload={reload}
        isDescription={isDescription}
      />
    </Modal>
  );
};
