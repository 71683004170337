import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { DEFAULT_SELECTOR_STATE } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { owners$ } from 'models/owners';
import {
  filteredTrainingsLocalized$,
  reorderTrainingsFx,
  selectedOwner$,
  setSelectedOwner,
  trainingIndexLoading$,
  trainingIndexLocalized$,
  trainingsLoading$,
} from 'models/trainings';
import { getItemStyle, getListStyle } from 'utils/dndStyles';

import BigAddMore from 'components/atoms/BigAddMore';
import OptionSelector from 'components/atoms/OptionSelector';
import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import TrainingItem from 'components/atoms/TrainingItem';

import * as ST from './styles';

import { Row, T8y } from 'ui';

const TrainingsList = ({ history }) => {
  const { trainings, form, users, product } = Lang();

  const filteredTrainingsLocalized = useStore(filteredTrainingsLocalized$);
  const trainingIndexLocalized = useStore(trainingIndexLocalized$);
  const trainingsLoading = useStore(trainingsLoading$);
  const trainingIndexLoading = useStore(trainingIndexLoading$);
  const owners = useStore(owners$);
  const selectedOwner = useStore(selectedOwner$);

  const [dndTrainings, setDndTraining] = useState([]);

  const formattedOwnersList = useMemo(
    () => [
      { name: users.filterSelect.all, slug: DEFAULT_SELECTOR_STATE },
      ...owners.map(el => ({ ...el, slug: el.id })),
    ],
    [owners, users.filterSelect.all],
  );

  const ownerDisplayName = useMemo(() => {
    return formattedOwnersList.find(({ slug }) => slug === selectedOwner)?.name;
  }, [formattedOwnersList, selectedOwner]);

  useEffect(() => {
    setDndTraining(filteredTrainingsLocalized);
  }, [filteredTrainingsLocalized]);

  const handleAdd = useCallback(() => {
    history.push(PATHS.TRAINING('new'));
  }, [history]);

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) {
        return;
      }

      const oldItems = [...dndTrainings];

      try {
        const newItems = [...dndTrainings];
        const removedIndex = newItems.findIndex(
          el => el.orderId === result.source.index,
        );
        const newIndex = newItems.findIndex(
          el => el.orderId === result.destination.index,
        );
        const [removed] = newItems.splice(removedIndex, 1);
        newItems.splice(newIndex, 0, removed);
        setDndTraining(newItems);

        await reorderTrainingsFx({
          id: result.draggableId,
          orderId: result.destination.index,
        });
      } catch (e) {
        console.warn(e);
        setDndTraining(oldItems);
        toast.error(form.toastTitle.wentWrong);
      }
    },
    [dndTrainings, form.toastTitle.wentWrong],
  );

  return (
    <>
      {(trainingsLoading || trainingIndexLoading) && <SpinnerOverlay />}
      <ST.IndexPageWrapper>
        <T8y color="darkBlue" variant="t1" marginBottom="30px" bold>
          {trainings.trainingIndexPage}
        </T8y>
        <TrainingItem
          item={trainingIndexLocalized}
          link={PATHS.TRAININGS_INDEX}
          inIndexPage
        />
      </ST.IndexPageWrapper>
      <Row>
        <T8y
          color="darkBlue"
          variant="t1"
          marginBottom="30px"
          marginRight="40px"
          bold
        >
          {trainings.trainingsCourses}
        </T8y>
        <OptionSelector
          options={formattedOwnersList}
          handleOptionChoose={setSelectedOwner}
          displayName={ownerDisplayName}
          selectorTitle={`${product.owner}:`}
        />
      </Row>
      <ST.BigButtonWrapper>
        <BigAddMore text={trainings.addNewCourse} onClick={handleAdd} />
      </ST.BigButtonWrapper>
      {!!dndTrainings.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            isDropDisabled={selectedOwner !== DEFAULT_SELECTOR_STATE}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {dndTrainings.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      isDragDisabled={selectedOwner !== DEFAULT_SELECTOR_STATE}
                      index={item.orderId}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps?.style,
                      )}
                    >
                      {provided => (
                        <TrainingItem
                          item={item}
                          link={PATHS.TRAINING(item.id)}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default withRouter(TrainingsList);
