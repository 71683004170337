import styled from 'styled-components';

import { colors } from 'styleGuide';

export const UserPageWrapper = styled.div`
  max-width: 1350px;
  margin: 0 auto;
`;

export const HeadersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  a:active,
  a:visited,
  a:link {
    font-weight: 600;
    font-size: 1.6rem;
    color: ${colors.primary};
  }
`;
