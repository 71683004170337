import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  availableCategoriesLocalized$,
  categoriesTreeStoreLocalized$,
  isLoadingCategories$,
} from 'models/categories';
import { settings$, settingsLoading$ } from 'models/settings';

import SpinnerOverlay from 'components/atoms/SpinnerOverlay';
import AddNewAndSaveCancelFooter from 'components/molecules/AddNewAndSaveCancelFooter';
import ProductEditorBlockWrapper from 'components/molecules/ProductEditorBlockWrapper';
import { FieldStyle, FieldStyle2 } from 'components/Product/shared';
import { SETTINGS } from 'api';

import { inputStyle, Row, Select, T8y } from 'ui';

const EMPTY_VALUE = new Array(5).fill({});

const PopularCategories = () => {
  const { marketing, form } = Lang();

  const settings = useStore(settings$);
  const settingsLoading = useStore(settingsLoading$);
  const isLoadingCategories = useStore(isLoadingCategories$);

  const allLocalizedCategories = useStore(categoriesTreeStoreLocalized$);
  const availableCategoriesLocalized = useStore(availableCategoriesLocalized$);

  const [isUpdating, setIsUpdating] = useState(false);

  const { setValue, handleSubmit, control, watch, getValues, reset } = useForm({
    defaultValues: {
      popularCategories: EMPTY_VALUE,
    },
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  });

  const { fields } = useFieldArray({
    control,
    name: 'popularCategories',
    keyName: 'formId',
  });

  const watchAll = watch();

  const values = useMemo(() => {
    return getValues({ nest: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll, getValues]);

  const getSelectedCategoryName = useCallback(
    id => {
      const currCategory = allLocalizedCategories.find(
        el => el.id === Number(id),
      );
      return currCategory ? currCategory.name : null;
    },
    [allLocalizedCategories],
  );

  const optionsForDisplaying = useMemo(
    () => [
      { name: form.defaultText.selectCategory, id: null },
      ...availableCategoriesLocalized,
    ],
    [availableCategoriesLocalized, form],
  );

  const handleReset = useCallback(() => {
    if (!settings.popularCategories || !allLocalizedCategories?.length) {
      reset({ popularCategories: EMPTY_VALUE });
      return;
    }

    const newPopularCategories =
      settings.popularCategories.length > 4
        ? settings.popularCategories
        : settings.popularCategories.concat(
            EMPTY_VALUE.slice(0, 5 - settings.popularCategories.length),
          );

    reset({
      popularCategories: newPopularCategories.map(
        id => allLocalizedCategories.find(el => id === el.id) || {},
      ),
    });
  }, [allLocalizedCategories, reset, settings.popularCategories]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, allLocalizedCategories]);

  const onSubmit = useCallback(
    async formData => {
      setIsUpdating(true);
      try {
        const { data } = await SETTINGS.updateGeneral({
          name: 'popularCategories',
          value: formData.popularCategories
            .filter(el => el.id)
            .map(el => el.id),
        });

        if (!data.popularCategories) {
          reset({ popularCategories: EMPTY_VALUE });
        } else {
          const newPopularCategories =
            data.popularCategories.length > 4
              ? data.popularCategories
              : data.popularCategories.concat(
                  EMPTY_VALUE.slice(0, 5 - data.popularCategories.length),
                );

          reset({
            popularCategories: newPopularCategories.map(
              id => allLocalizedCategories.find(el => id === el.id) || {},
            ),
          });
        }

        toast.success(form.toastTitle.successfullyUpdated);
      } catch (e) {
        toast.error(form.toastTitle.updateError);
      } finally {
        setIsUpdating(false);
      }
    },
    [
      allLocalizedCategories,
      form.toastTitle.successfullyUpdated,
      form.toastTitle.updateError,
      reset,
    ],
  );

  return (
    <>
      {(settingsLoading || isUpdating || isLoadingCategories) && (
        <SpinnerOverlay />
      )}
      <T8y color="darkBlue" fontSize="24px" marginBottom="30px" bold>
        {marketing.titles.popularCategories}
      </T8y>
      <ProductEditorBlockWrapper>
        <Row>
          {fields.map((el, index) => (
            <Fragment key={el.formId}>
              <FieldStyle
                as={FieldStyle2}
                legend={`${form.fields.category} ${index + 1}`}
                width="200px"
                withBorder={true}
                marginRight={index !== fields.length - 1 && '20px'}
                marginBottom="20px"
              >
                <Select
                  options={optionsForDisplaying}
                  selected={getSelectedCategoryName(
                    values.popularCategories[index].id,
                  )}
                  defaultText={form.defaultText.selectCategory}
                  aLabel={form.fields.category}
                  onClickOption={value => {
                    setValue(
                      `popularCategories[${index}]`,
                      allLocalizedCategories.find(item => item.id === value) ||
                        {},
                    );
                  }}
                  className={inputStyle}
                  maxHeight="300px"
                />
              </FieldStyle>
            </Fragment>
          ))}
        </Row>
      </ProductEditorBlockWrapper>
      <AddNewAndSaveCancelFooter
        onSubmit={onSubmit}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default PopularCategories;
