import React, { useCallback, useEffect, useLayoutEffect } from 'react';

import InputWithCurrencySign from 'components/atoms/InputWithCurrencySign';

import * as ST from './styles';

const DeliveryAreaItem = ({
  deliveryIndex,
  areaIndex,
  fieldIndex,
  register,
  setValue,
  watch,
  checkboxName,
  inputName,
  isChecked,
  defaultInputValue = null,
  setIsDeliveryAreaFields,
  deliveryMethodName,
}) => {
  const inputWatcher = watch(
    `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${inputName}`,
  );

  const checkboxWatcher = watch(
    `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${checkboxName}`,
  );

  useEffect(() => {
    register({
      name: `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${checkboxName}`,
    });
    setValue(
      `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${checkboxName}`,
      isChecked,
    );
    register({
      name: `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${inputName}`,
      valueAsNumber: true,
    });
    setValue(
      `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${inputName}`,
      defaultInputValue,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setIsDeliveryAreaFields(fields => {
      const allFields = [...fields];
      allFields[fieldIndex] = checkboxWatcher;
      return allFields;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxWatcher, setIsDeliveryAreaFields]);

  useEffect(() => {
    if (inputWatcher === '') {
      setValue(
        `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${checkboxName}`,
        false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputWatcher]);

  const handleInputChange = useCallback(
    e => {
      let currValue = e.target.value;

      if (currValue === '') {
        currValue = null;
      }

      setValue(
        `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${inputName}`,
        currValue,
      );
    },
    [areaIndex, deliveryIndex, deliveryMethodName, inputName, setValue],
  );

  // const handleCheckboxChange = useCallback(
  //   e => {
  //     setValue(
  //       `deliveries[${deliveryIndex}][${deliveryMethodName}][${areaIndex}].${checkboxName}`,
  //       e.target.checked,
  //     );
  //   },
  //   [areaIndex, checkboxName, deliveryIndex, deliveryMethodName, setValue],
  // );

  return (
    <ST.DeliveryAreaFieldsWrapper>
      {/*<ST.AreaCheckbox*/}
      {/*  onChange={handleCheckboxChange}*/}
      {/*  checked={isChecked}*/}
      {/*  disabled*/}
      {/*/>*/}
      <InputWithCurrencySign
        type="number"
        width="110px"
        step="0.01"
        paddingLeft="3rem"
        onChange={handleInputChange}
        defaultValue={defaultInputValue}
        disabled={inputName !== 'bothWaysDelivery'}
      />
    </ST.DeliveryAreaFieldsWrapper>
  );
};

export default DeliveryAreaItem;
