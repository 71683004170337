import styled from 'styled-components';

import { Checkbox, Grid } from 'ui';

import checkIcon from 'assets/images/icon/checkWhite.svg';

export const StyledGrid = styled(Grid)`
  & > div {
    height: 100%;
  }
`;

export const ProductCheckboxWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const PrerenderFieldsWrapper = styled.div`
  margin-bottom: 20px;
`;

export const IdVerificationCheckbox = styled(Checkbox)`
  & > div > div {
    border-radius: 5px;
  }

  &[aria-checked='true'] > div > div {
    width: 20px;
    height: 20px;
    background-color: #79d331;
    border-color: #79d331;
    background-image: url(${checkIcon});
  }
`;
