import styled from 'styled-components';

import { colors } from 'styleGuide';

export const CustomTable = styled.table`
  ${p => p.isFixedLayout && 'table-layout: fixed'};
  border-collapse: collapse;
  background-color: ${p => p.bgColor || colors.positive};
  td {
    padding: 15px 10px;
  }
  th {
    padding: 5px 10px;
  }
`;

export const TableHeaders = styled.tr`
  color: ${colors.gray};
  height: 34px;
  font-weight: 600;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: ${colors.positive};
`;

export const TableRow = styled.tr`
  font-size: 16px;
  color: ${colors.negative};
  border-bottom: 1px solid #dddddd;
  a {
    color: ${colors.primary};
  }
  ${p =>
    p.isActive &&
    `font-weight:bold;
      background-color: #F7F1EC;
  `}
`;

export const TableCell = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${p => `-webkit-line-clamp: ${p.lineClamp || '2'};`}
  ${p => p.cellType === 'amount' && 'text-align: right;'}
  ${p => p.cellType === 'qty' && 'text-align: center;'}
  ${p => p.cellType === 'link' && 'cursor: pointer;'}
  ${p => !!p.maxWidth && `max-width: ${p.maxWidth};`}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
`;
