import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useFieldWatchWithMaxLength } from 'hooks';
import { Lang } from 'lang';
import { branding$ } from 'models/branding';

import Error from 'components/atoms/Error';
import GeneralExpandableSection from 'components/molecules/GeneralExpandableSection';

import { FieldStyle2, generateURL, InputStyled } from '../../Product/shared';

import { T8y } from 'ui';

const TITLE_MAX_LENGTH = 80;

const JobTypeGeneralInformation = ({
  errors,
  register,
  isNew,
  values,
  getIsError,
  watch,
  setValue,
  trigger,
}) => {
  const { formatString, form, trainings, marketing } = Lang();

  const branding = useStore(branding$);

  const titleRemainingSymbols = useFieldWatchWithMaxLength(
    watch,
    'title',
    TITLE_MAX_LENGTH,
  );

  const actualUrl = useMemo(() => {
    return generateURL(values.slug);
  }, [values.slug]);

  const displayedSlug = useMemo(() => {
    return `${branding?.webSiteUrl}/partners/${actualUrl || ''}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualUrl, branding]);

  return (
    <GeneralExpandableSection
      index={0}
      header={trainings.indexPage.generalInformation}
      listOfFields={['title', 'slug']}
      errors={errors}
      initialIsOpen
      isNoForm
    >
      <FieldStyle2 legend={marketing.jobTypes.jobTypeTitle}>
        <InputStyled
          name="title"
          width="700px"
          maxLength={TITLE_MAX_LENGTH}
          ref={register({
            maxLength: TITLE_MAX_LENGTH,
            validate: value =>
              !!value?.trim() || form.notification.theFieldIsRequired,
          })}
          onChange={e => {
            if (!isNew) {
              return;
            }

            if (e.target.value) {
              setValue('slug', generateURL(e.target.value));

              if (values.slug) {
                trigger('slug');
              }
            } else {
              setValue('slug', null);
              trigger('slug');
            }
          }}
          defaultValue={values.title}
        />
      </FieldStyle2>
      <Error message={getIsError('title')} />
      <T8y color="gray" fontSize="12px">
        {formatString(trainings.remainingCharacters, titleRemainingSymbols)}
      </T8y>
      <FieldStyle2 legend={marketing.jobTypes.jobURL} marginTop="20px">
        <InputStyled
          name="slug"
          width="700px"
          ref={register({
            validate: value =>
              !!value?.trim() || form.notification.theFieldIsRequired,
          })}
          defaultValue={values.slug}
          readOnly={!isNew}
        />
      </FieldStyle2>
      <Error message={getIsError('slug')} />
      {isNew ? (
        <T8y color="gray" fontSize="12px">
          {displayedSlug}
        </T8y>
      ) : (
        <T8y color="gray" fontSize="12px" href={displayedSlug} target="_blank">
          {displayedSlug}
        </T8y>
      )}
    </GeneralExpandableSection>
  );
};

export default JobTypeGeneralInformation;
