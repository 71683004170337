import { useCallback, useMemo, useState } from 'react';

export const useManageHitsListVisibility = ({ inputRef }) => {
  const [isPostcodesListVisible, setIsPostcodesListVisible] = useState(false);

  const scrollToList = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      top:
        inputRef.current.getBoundingClientRect().top + window.pageYOffset - 100,
    });
  }, [inputRef]);

  const makePostcodeListVisible = useCallback(() => {
    if (!isPostcodesListVisible) {
      setIsPostcodesListVisible(true);
      scrollToList();
    }
  }, [isPostcodesListVisible, scrollToList]);

  return useMemo(
    () => ({
      makePostcodeListVisible,
      setIsPostcodesListVisible,
      isPostcodesListVisible,
    }),
    [
      makePostcodeListVisible,
      isPostcodesListVisible,
      setIsPostcodesListVisible,
    ],
  );
};
